import React from "react";
import PropTypes from "prop-types";
import cookie from 'react-cookies';
import cx from "classnames";
import axios from 'axios';
import delay from "delay";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Icon from "@material-ui/core/Icon";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { API, IMG_LOAD, ADDRESS } from 'constants/index.js';
import { LINK_API } from 'constants/API';
import List from "@material-ui/core/List";
import { withRouter } from 'react-router-dom'
import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import { connect } from 'react-redux';
const ethers = require('ethers');
const CryptoJS = require("crypto-js");


//BlockChain
const EthereumTx = require('ethereumjs-tx').Transaction;


// const genieTJSON = require("../../../../build/contracts/GenieToken.json");
// const genieTABI = genieTJSON['abi'];
class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      pass_user: "",
      pathCount_user: 0,
      abi_data: "",
    }
    this.handleActive = this.handleActive.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.getPathemploy = this.getPathemploy.bind(this);
    this.activeAccount = this.activeAccount.bind(this);
    this.handleinput = this.handleinput.bind(this);
  }
  componentDidMount() {
    this.setState({
      pass_user: this.props.userInfo.password
    })
  }
  componentWillReceiveProps() {

  }
  handleinput = () => async (event) => {
    await this.setState({
      pass_user: event.target.value
    });
  }
  activeAccount = async () => {

    var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privatekey = bytes.toString(CryptoJS.enc.Utf8);

    const privatekey_slice = privatekey.substring(2, privatekey.length)
    const Buffer_privatekey = await Buffer.from(privatekey_slice.toString(), 'hex');
    await this.setState({
      Buffer_privatekey: Buffer_privatekey
    })
    const tx = new EthereumTx(this.state.tx, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    const raw_to_send = {
      "raw": [],
      "sign": "none",
      "date": "none",
      "bcAddress": this.props.userInfo.data.bcAddress,
      "uuid": this.props.userInfo.data.uuid,
      "nonce": "none"
    }
    await raw_to_send.raw.push(rawTx);
    axios.post(API.LINK_API.SEND_RAW_VERIFY, raw_to_send)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: 'block' }}
                title="Thành công"
                closeOnClickOutside={false}
                onCancel={() => this.hideAlert_claimed(response.data.data.raw)}
                onConfirm={() => this.hideAlert_claimed(response.data.data.raw)}
                showCancel={false}
              />
            )
          });
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: 'block' }}
                title="Không thành công"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
              />
            )
          });
        }
      })

  }
  hideAlert_claimed = async (data) => {
    document.getElementById("kichhoat").innerHTML = "Tài khoản của bạn đang chờ phê duyệt";
    await this.setState({
      alert: null,
    })
  }
  verifyAccount = () => {
    this.props.history.push("/admin/register/plan")
   
  }
  getPathemploy() {
    axios
      .get(LINK_API.GET_PATH_EMPLOY)
      .then(async (response) => {
        if (response.data.errorCode === 1) {
          this.setState({
            pathCount_employ: response.data.data.pathEmployee.toString(),
            pathCount_user: response.data.data.pathUser.toString()
          });
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }
  hideAlert = () => {
    this.setState({
      alert: null
    })
  }
  handleActive = () => {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Vui lòng nhập mật khẩu tài khoản"
          onConfirm={() => this.verifyAccount()}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
          confirmBtnText="Kích hoạt"
          cancelBtnText="Hủy"
          showCancel
        >
          <CustomInput
            success={this.state.pass_userState === 'success'}
            error={this.state.pass_userState === 'error'}
            helpText={this.state.pass_userHelp}
            variant="outlined"
            labelProps={{
              variant: 'outlined'
            }}
            formControlProps={{
              variant: 'outlined',
              fullWidth: true,
              className: classes.customFormControlClasses,
              onChange: this.handleinput('pass_user')
            }}
            value={this.state.pass_user}
            inputProps={{
              //value: this.state.pass_user,
              type: 'password',
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start" className={classes.inputAdornment}>
                  <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                </InputAdornment>
              ),
              placeholder: 'Mật khẩu'
            }}
          />
          <div>
            <p
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              id="noti_active"
              style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: 'red',
                fontFamily: 'Muli',
                marginTop: 5,
                marginBottom: 5
              }}
            />
          </div>
        </SweetAlert>
      )
    });
  }
  render() {
    const { classes, fluid, white, rtlActive } = this.props;
    var container = cx({
      [classes.container]: !fluid,
      [classes.containerFluid]: fluid,
      [classes.whiteColor]: white
    });
    var anchor =
      classes.a +
      cx({
        [" " + classes.whiteColor]: white
      });
    var block = cx({
      [classes.block]: true,
      [classes.whiteColor]: white
    });
    return (
      <footer className={classes.footer}>
        <div className={container}>
          <div className={classes.left}>
            {this.props.userInfo.data.active ==  false? (
              <p id="kichhoat">Tài khoản của bạn chưa được kích hoạt. Vui lòng bấm <a onClick={this.verifyAccount} className="activeAccount">vào đây</a> để kích hoạt</p>
            ) : null} 
            {/* <Button color="warning" onClick ={this.verifyAccount} size="sm">Kích hoạt tài khoản</Button> */}
          </div>
          <div className={classes.right}>
            <a style={{color: "black"}}href="http://vietnamblockchain.asia/">© 2019, Vietnam Blockchain Corporation</a>
          </div>
        </div>
        {this.state.alert}
      </footer>
    );
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo }
}
Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(withRouter( connect(mapStateToProps, null)(Footer)));
