import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import Gallery from '../../components/Forms/Gallery/Gallery';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Add from "@material-ui/icons/Add";
import Key from "@material-ui/icons/VpnKey";
import GetApp from "@material-ui/icons/GetApp"

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CreateFarm from "../EmployeePage/CreateFarm";

import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Tooltip from '@material-ui/core/Tooltip';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import defaultAvatar from "assets/img/default-avatar.png"
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';
import Edit from "@material-ui/icons/Search";
import ReactExport from "react-export-excel";
import strings from "../../../../constants/strings";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props);

   
    this.getFarms = this.getFarms.bind(this)
    this.checkPermission = this.checkPermission.bind(this);
    this.genTable = this.genTable.bind(this)
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.state = {
     // openCreateFarm: openCreateFarm,
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
    };
    this.token = cookie.load('token');

  }
  handleSearch(uuid){
    cookie.save("uuidPartner", uuid,{ path: "/" });
    this.props.history.push("/admin/cattle/list");
  }

  genTable = async (farmList) => {

    //-----------------------
    let exportData = [];
    exportData = farmList.map(item => {
      return ({
        name: item.name,
        address: item.address,
        phone: item.phone,
      })
    })

    await this.setState({ export: exportData })
    //-----------------------

    
    await farmList.reverse();
    this.setState({
      data: farmList.map((prop, key) => {
        return {
          id: prop.uuid,
          name: 
          <div style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              {
                prop.image !== undefined && prop.image !== "" && prop.image?<Gallery
                images={[{
                  src: prop.image.secure_url,
                  thumbnail: prop.image.secure_url,
                  caption: ""
                }]}
                rowHeight= {37}
                enableLightbox={true}
                enableImageSelection={false}
              />: 
              <Gallery
                images={[{
                  src: defaultAvatar,
                  thumbnail: defaultAvatar,
                  caption: ""
                }]}
                rowHeight= {37}
                enableLightbox={true}
                enableImageSelection={false}
              />
              }
              
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <div><p style={{ marginLeft: "-24px", marginTop: 12, textAlign: "left" }}>{prop.name}</p></div>
            </GridItem>
          </GridContainer>
        </div>,
          address: prop.address,
          phone: prop.phone ? prop.phone : "",
          actions: (
            <Button
            justIcon
            round
            //title="Xem chi tiết"
            simple
            onClick={() => this.handleSearch(prop.uuid)}
            color="success"
            className="like"
          >
            <Tooltip
              id="tooltip-search"
              title= {strings.edit}
              placement="top"
              disableHoverListener={false}
              classes={{ tooltip: this.props.classes.tooltip }}>
              <Edit />
            </Tooltip>
          </Button>
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  }

  async checkPermission() {
      await axios.get(LINK_API.VERIFY_TOKEN,  { headers: {"Authorization" : `Bearer ${this.props.userInfo.data.token}`} })
      .then( async response => {
        if (response.data.errorCode != 1 || !response.data.data.valid ){
          await cookie.remove('token')
          return this.props.history.push("/");
        }
        else {
          axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
        }
      })
    .catch(async error => {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }) 
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.GET_EMPLOYEE_LIST);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      this.props.getFarms();
      // let farms = await this.getFarms();
      // this.setState({ farmList: farms });
      // this.genTable(farms);
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  componentWillReceiveProps(preProps){
    if(preProps.employeeList !== this.props.employeeList){
      if(preProps.employeeList.data){
        this.genTable(Array.from(preProps.employeeList.data));
      }
    }
  }


  handleNewClick(){
    this.props.history.push("/admin/customers/create")
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer style={{display: "flex", flexFlow: "center"}}>
                    <h4 style={{marginRight: 19}}className="cardtableLabel">{strings.distributorList}</h4>
                    <div className="">
                      <ExcelFile element={<Button justIcon color="success" className="like" simple round > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> </Button>}>
                        <ExcelSheet data={this.state.export} name="Nhà phân phối">
                          <ExcelColumn label="Tên chi nhánh" value="name" />
                          <ExcelColumn label="Địa chỉ" value="address" />
                          <ExcelColumn label="Số điện thoại" value="phone" />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                    </GridContainer>
                  </GridItem>
                  
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      {(this.props.userInfo.data.active  === true) ? <Button color="success" size="sm" onClick={this.handleNewClick}> <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button> : null}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  data={this.state.data}
                  filterable
                  // defaultPageSize={5}
                  previousText={strings.prevPage}
                    nextText={strings.nextPage}
                    rowsText={strings.row}
                    ofText="/"
                    pageText={strings.page}
                    noDataText={strings.noData}
                  columns={[
                    {
                      Header: "#",
                      accessor: "id",
                      filterable: false,
                      maxWidth: 70,
                      className: "center",
                      Header: () => (
                        <div style={{
                          textAlign: "center"
                        }}>
                          #
                        </div>
                      )
                    },
                    {
                      Header: strings.nameBranch,
                      accessor: "name",
                      filterable: false
                    },
                    {
                      Header: strings.address,
                      accessor: "address",
                      filterable: false
                    },
                    {
                      Header: strings.phone,
                      accessor: "phone",
                      filterable: false
                    },
                    {
                      Header: strings.actionHeader,
                      accessor: "actions",
                      filterable: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          open={this.state.openCreateFarm}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <CreateFarm onConfirm={this.showConfirmAlert} />
        </Dialog>

        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFarms: () => dispatch({ type: "GET_DISTRIBUTOR_LIST"}),
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
           employeeList: state.distributorList}
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));