import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import QRCode from 'qrcode.react';
import moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LogoIBL from "assets/img/logo_ibl_black.png"
import LogoVBC from "assets/img/logo_vbc_black.png"
const style = {
  frame: {
    marginTop: "30px",
    border: "solid 1px #000",
    marginLeft: "30px",
    padding: "20px",
    // height:"95%",
    width:"93%"
  },
  boldTextLarge: {
    fontWeight: "700",
    fontSize: "35px"
  },
  boldText: {
    fontWeight: "700",
  },
  contentText: {
    fontWeight: "400",
    fontSize: "15px"
  }
};

class TreePrintTemplate extends React.Component {

  render() {

    const { classes } = this.props;
    const { data } = this.props;
    const datetime = moment().format("DD/MM/YYYY");

    return (
      <GridContainer className = {classes.frame}>
        <GridContainer >
          <GridItem lg = {3}>
            <img src={LogoIBL} border="0" alt="test" />
          </GridItem>
          <GridItem lg = {3}>
            <img src={LogoVBC} border="0" alt="test" />
          </GridItem>
          <GridItem lg = {6} md = {6} sm = {6} xs = {6} style = {{textAlign:"center"}}>
            Giải pháp cấp chứng nhận Truy xuất nguồn gốc trên Blockchain phù hợp tiêu chuẩn GS1 
          </GridItem>
        </GridContainer>
        <GridContainer justify = "center" style = {{marginTop: "20px",marginBottom: "40px"}}>
          <GridItem>
            <h5 className = {classes.boldText}> PHIẾU THÔNG TIN SẢN PHẨM </h5>
            <h5 className = {classes.contentText}> Đăng ký ngày: {datetime}</h5>
          </GridItem>
        </GridContainer>
        <GridContainer >
          <GridItem lg = {12} md = {12} sm = {12} xs = {12} style = {{marginBottom: "20px"}}>
            <h5 className = {classes.boldText}> Thông tin sản phẩm: </h5>
            {/* <h5 className = {classes.contentText}> Mã chủ hộ: {data.farm_name}</h5> */}
            {/* <h5 className = {classes.contentText}> Loại nông sản {data.scope}</h5> */}
            {/* <h5 className = {classes.contentText}> Tình trạng: {data.typeProduct}</h5> */}
            <h5 className = {classes.boldText} style = {{marginBottom: "20px"}}> Mã QR Sản Phẩm </h5>
          </GridItem>
          <GridItem lg = {12} md ={12} sm = {12} xs = {12} style = {{textAlign: "center"}}>
            <QRCode value={"https://portal.agridential.vn/lookup/"+ data} size ={128*3+32}/>
          </GridItem>
          <GridItem lg = {12} md = {12} sm = {12} xs = {12} style = {{textAlign: "center", marginTop: "20px"}}>
            <h5 className = {classes.boldTextLarge}> {data} </h5>
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  }
}

export default withStyles(style)(TreePrintTemplate);
