import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "./input/CustomInput.jsx";
import ImageUpload from "./ImageUpload";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";


const style = {
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    marginLeft: "15px"
  },
  alignLeft: {
    textAlign: "left"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  },
  nopadding:{
    padding: "0px 0px !important"
  }
}

class InfoProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      image: null,
     
      productNameState:"",
      productNameHelp:"",
     
      productDescState:"",
      productDescHelp:"",
    
      productExpiredState:"",
      productExpiredHelp:"",
      destime:["sau khi đóng gói", "sau khi thu hoạch"]
    };
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount(){
    this.setState({
      name: this.props.productInfo.name || '',
      image: this.props.productInfo.image || '',
      description: this.props.productInfo.description || '',
    })
  }

  handleChange = name => event => {
    if(name==="name"){
      document.getElementById("noti").innerHTML = "";
      this.setState({
       productNameHelp:"",
        productNameState:"success",
      })
    }
    if(name==="expired_date"){
      document.getElementById("noti").innerHTML = "";
      this.setState({
        productExpiredHelp: "",
        productExpiredState:"success",
      })
    }
    if(name==="description"){
      document.getElementById("noti").innerHTML = "";
      this.setState({
        productDescHelp: "",
        productDescState:"success",
      })
    }
      this.setState({ [name]: event.target.value});
      this.props.getData(name, event.target.value);
  };

  handleChecked = name => event => {
      this.setState({ [name]: event.target.checked })
  };

  async onImageSubmit(file) {
    if (file != null) {
     await this.setState({
        image: file
      })
      this.props.getData('image',file);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
            <GridItem xs={12} style={{ margin: "auto", }}>
                  <GridContainer style={{textAlign: 'center', display:"flex", justifyContent: 'center'}} spacing={2}>
                    <GridItem sm={12}  style={{padding: 0}}>
                      <ImageUpload
                        // onImageSubmit={(file)=>this.props.onImageSubmit(file)}
                        onImageSubmit={this.onImageSubmit.bind(this)}
                        addButtonProps={{
                          color: "success"
                        }}
                        changeButtonProps={{
                          color: "success"
                        }}
                        removeButtonProps={{
                          color: "danger"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <form>
                      <div style={{flexFlow:"row", display: "flex"}} className="nopadding">
                      <GridItem xs={6} className={classes.nopadding}>
                        <CustomInput
                          labelText="Tên sản phẩm"
                          success={this.state.productNameState === "success"}
                          error={this.state.productNameState === "error"}
                          id="productName"
                          formControlProps={{
                            fullWidth: true,
                            // onChange: this.handleChange('productName')
                            onChange: this.handleChange('name'),
                            // onChange: this.props.getData('image','123'),
                          }}
                          inputProps={{
                            
                          }}
                          helpText={this.state.productNameHelp}
                        />
                      </GridItem>
                      <GridItem xs={6} className={classes.nopadding} style={{marginLeft: "8px"}}>
                        <CustomInput
                          success={this.state.productExpiredState === "success"}
                          error={this.state.productExpiredState === "error"}
                          labelText="Hạn sử dụng"
                          id="productExpired"
                          // inputType="number"
                          formControlProps={{
                            fullWidth: true,
                            onChange: this.handleChange('expired_date'),
                            // type: "number",
                            // step: "any",
                            // min: "0",
                            // inputType:"number"

                          }}
                          className={classes.textField}
                          inputProps={{
                          }}
                          helpText={this.state.productExpiredHelp}
                        />
                      </GridItem>
                      </div>
                        <CustomInput
                         success={this.state.productDescState === "success"}
                         error={this.state.productDescState === "error"}
                          labelText="Miêu tả"
                          id="productDesc"
                          formControlProps={{
                            fullWidth: true,
                            onChange: this.handleChange('description'),
                          }}
                          inputProps={{
                            multiline: true,
                          }}
                          helpText={this.state.productDescHelp}
                        />

                      </form>
                    </GridItem>
                  </GridContainer>
            </GridItem>
      </div>
    );
  }
}

export default withStyles(style)(InfoProduct);
