import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import QRCode from 'qrcode.react';
import moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import stamp_embryo from "assets/img/stamp_embryo/temthung.png"

const style = {
  frame: {
    marginLeft:"1.35cm",
    padding: "0.3cm",
    marginTop:"0.9cm",
    marginBottom:"1.69cm",
    position:"relative"
  },
  paddingStamp:
  {
    marginRight:"0.3cm !important",
    marginTop:"0.5cm !important"
  },
  stampFrame:{
    paddingLeft:"0.125cm",
    // textAlign: "center",
    // marginTop: "30px",
    // border: "solid 1px #000",
    width:"10cm",
    height:"5cm",
    backgroundSize:"contain",
    backgroundRepeat:"no-repeat",
    backgroundImage:`url(${stamp_embryo})`,
    marginRight:"0.6cm !important",
    marginTop:"0.3cm !important",
    marginLeft: "-0.4cm !important",
    imageRendering: "-webkit-optimize-contrast",
    // height:"95%",
    // width:"93%"
  },
  productText: {
    fontSize: "0.2cm",
    color:"#258af2",
    fontWeight: "700",
    marginTop: "0.25cm",
    marginBottom: "0.1cm",
    fontFamily: "Muli Black",
    position: "absolute",
  },
  subText: {
    fontSize: "0.3cm",
    letterSpacing:"0.0355cm",
    color:"#000",
    paddingBottom: "0cm",
    marginTop: "2.06cm",
    marginLeft:"1.7cm",
    fontFamily: "Muli",
    position: "absolute",
    fontWeight: "700"
  },
  subTextname:{
    fontSize: "0.3cm",
    letterSpacing:"0.0355cm",
    color:"#000",
    paddingBottom: "0cm",
    marginTop: "2.2cm",
    marginLeft:"2.2cm",
    fontFamily: "Muli",
    position: "absolute",
    fontWeight: "700"
  },
  subTextBold: {
    fontSize: "0.4cm",
    letterSpacing:"0.0355cm",
    color:"#000",
    paddingBottom: "0cm",
    marginTop: "0.6cm",
    marginLeft:"6.5cm",
    fontFamily: "Muli",
    position: "absolute",
    fontWeight: "700"
  },
  qrCode:{
    maxWidth:"2.8cm",maxHeight:"2.8cm",marginTop:"1.2cm",marginBottom:"0px",marginLeft:"6.5cm", position: "absolute",
  }
};



const STAMP_WIDTH = 48;
const STAMP_HEIGHT = 32;
const PAPER_WIDTH = 210;//A4
const PAPER_SIZE = 297;//A4
const STAMP_PER_LINE = 2;
const ROW_PER_PAGE = 5;
const OFFSET_HEIGHT = 0;
const OFFSET_WIDTH = 0;
const OFFSET_HEIGHT_BETWEEN = 0;
const OFFSET_WIDTH_BETWEEN = 0;

class StampPrintTemplate extends React.Component {

  generateSingleStamp(id,data) {
    const { classes, start } = this.props;
    const id_pad = (id.toString()).padStart(10,"0");
    const valueqr = start.substr(0,12) + id_pad;
    return (
      // <GridItem lg = {1} md = {1} xs = {1} sm = {1} className = {classes.paddingStamp}>
        <div  className = {classes.stampFrame}>
          {/* <img src={stamp_embryo} border="0" alt="embryo" /> */}
          {/* <h5 className = {classes.subText}>TP Cao Lãnh, Đồng Tháp</h5> */}
           <h5 className = {classes.subText}> {data.cooporativeName} </h5>
          {/* <h5 className = {classes.productText}> 
          {(data.productName==="All")?("Tem thùng"
          ):(
          "Tem sản phẩm"
          )}
          </h5> */}
          <QRCode  className = {classes.qrCode} value={"https://portal.agridential.vn/lookup/" + valueqr} />
          <h5 className = {classes.subTextBold}> {id_pad} </h5>
        </div>
      // </GridItem>
    );
  }

  generateRowStamp(start_id,end_id,data) {
    const stamp_list = []

    for (let i = start_id; i <= end_id; i++) {
      //console.log(i)
      stamp_list.push(this.generateSingleStamp(i,data));
    }
    
    let row = null;
    
    if (stamp_list.length < STAMP_PER_LINE) {
      row = (
        <GridContainer>
          {stamp_list}
        </GridContainer>
      )
    }
    else {
      row = (
        <GridContainer>
        {stamp_list}
        </GridContainer>
      )
    }
    return row;
  }

  render() {

    const { classes } = this.props;
    const { data } = this.props;
    const { start } = this.props;
    const { end } = this.props;
    const {amount} = this.props;
    const datetime = moment().format("DD/MM/YYYY");

    // console.log(start)
    // console.log(end)
    // console.log(amount)
    var numOfRow = Math.ceil((amount) / STAMP_PER_LINE);
    //console.log("Row",numOfRow);
    var x = parseInt(start)
    var y = parseInt(end)
    let row_list = [];
    // console.log("x", start);
    // console.log("y", y);
    for (let i = 0; i < numOfRow; i++) {
      let start_id = x + i * STAMP_PER_LINE;
      let end_id = x +  (i + 1) * STAMP_PER_LINE-1;
      if (end_id > y) {
        end_id = y;
      }
      row_list.push(this.generateRowStamp(start_id,end_id,data));
    }
   
    var numOfPage = Math.floor((amount) / (STAMP_PER_LINE*ROW_PER_PAGE));
      if ((amount % (STAMP_PER_LINE*ROW_PER_PAGE) > 0)){
      numOfPage = numOfPage + 1;
    }
    //console.log("page",numOfPage)
    let pages_list = []

    for (let i = 1; i <= numOfPage; i++) {
      const page = [];
      for (let r = 0 + (i-1)*ROW_PER_PAGE; r < ROW_PER_PAGE*i; r++) {
        if(r < numOfRow){
          page.push(row_list[r]);
        }else{
          
          break;
        }
      }
      pages_list.push(
        <GridContainer className = {classes.frame}>
          {page}
        </GridContainer>
      )
    }
    //console.log(pages_list)
    

    return (
      <GridContainer style={{position:'static'}}>
        {pages_list}
      </GridContainer>
    );
  }
}

export default withStyles(style)(StampPrintTemplate);
