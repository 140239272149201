import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageUpload from "./ImageUpload";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";


const style = {
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    marginLeft: "15px"
  },
  alignLeft: {
    textAlign: "left"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  }
}

class Certificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
    };
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.onImageSubmit1 = this.onImageSubmit1.bind(this);
  }

 async  onImageSubmit(file) {
    if (file != null) {
      await this.setState({
        image: file
      })
    }
    this.props.getData('certificate0',file);
  }

  async onImageSubmit1(file) {
    if (file != null) {
     await this.setState({
        image1: file
      })
    this.props.getData('certificate1',file);
    }
  }

  componentDidMount(){
    this.setState({
      image: this.props.certificateFile.certificate0 || '', 
      image1: this.props.certificateFile.certificate1 || '',
    })
  }

  componentWillUnmount(){

  }

  render() {
    return (
        <GridContainer spacing={24} style={{textAlign: 'center'}}>
        <GridItem xs={6}>
            <ImageUpload
                value={this.state.image}
                onImageSubmit={this.onImageSubmit.bind(this)}
                addButtonProps={{
                color: "success"
                }}
                changeButtonProps={{
                color: "success"
                }}
                removeButtonProps={{
                color: "danger"
                }}
            />
        </GridItem>
        <GridItem xs={6}>
            <ImageUpload
                value={this.state.image1}
                onImageSubmit={this.onImageSubmit1.bind(this)}
                addButtonProps={{
                color: "success"
                }}
                changeButtonProps={{
                color: "success"
                }}
                removeButtonProps={{
                color: "danger"
                }}
            />
        </GridItem>
        </GridContainer>
    );
  }
}

export default withStyles(style)(Certificate);
