import React from "react";
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';
import { connect } from 'react-redux';
// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

// @material-ui/icons
// import MailOutline from "@material-ui/icons/MailOutline";
import People from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";
import FindInPage from "@material-ui/icons/Search";
import GetApp from "@material-ui/icons/GetApp"
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CreateProduction from "./CreateProductionArea"
import Gallery from '../../components/Forms/Gallery/Gallery';
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Tooltip from '@material-ui/core/Tooltip';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css";
import profileagd from "assets/img/agdavatar.gif"
import Edit from "@material-ui/icons/Edit";
import ReactExport from "react-export-excel";
import Truncate from 'react-truncate';
import strings from "constants/strings.jsx"
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  tooltip: {
    padding: "10px 15px",
    minWidth: "130px",
    color: "#FFFFFF",
    lineHeight: "1.7em",
    background: "rgba(85,85,85,0.9)",
    border: "none",
    borderRadius: "3px",
    opacity: "1!important",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: "200px",
    textAlign: "center",
    fontFamily: 'Muli',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props)

    this.hideAlert = this.hideAlert.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this)
    this.onSuccess = this.onSuccess.bind(this);
    this.handleEditArea = this.handleEditArea.bind(this)

    this.state = {
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
    };

  }
  handleEditArea(uuid) {
    cookie.save("uuidArea", uuid, { path: "/" });
    this.props.history.push("/admin/areas/edit")
  }
  isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  async handleClick(gln) {
    this.props.getObjectInArea(gln);
    this.props.history.push("/admin/areas/objects/listinarea")
  }
  genTable = async (farmList) => {
    // console.log(farmList);

    //-----------------------
    let exportData = [];
    exportData = farmList.map(item => {
      return ({
        uuid: item.uuid,
        name: item.name,
        time: moment(item.createdAt).format("DD/MM/YYYY"),
        description: item.description,
        city: item.address ? item.address.city : "",
        number: item.objectRegistered,
        type: (item.type === 0) ? "Vườn" : (item.type === 1) ? "Nhà kính" : (item.type === 2) ? "Khu chế biến" : (item.type === 3) ? "Trang trại" : (item.type === 4) ? "Lò mổ" : (item.type === 5) ? "Khu đóng gói" : (item.type === 6) ? "Khu đóng gói" : "",
      })
    })

    await this.setState({ export: exportData })
    //-----------------------

    await farmList.reverse();
    this.setState({
      data: farmList.map((prop, key) => {
        return {
          id: prop.uuid,
          name:
            <div style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                  {
                    this.isEmptyObject(prop.image) ? <img src={profileagd} style={{ height: "37px", width: "37px" }} /> :
                      <Gallery
                        images={[{
                          src: prop.image.secure_url,
                          thumbnail: prop.image.secure_url,
                          caption: ""
                        }]}
                        rowHeight={37}
                        enableLightbox={true}
                        enableImageSelection={false}
                      />
                  }

                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <div><p style={{ marginLeft: "-24px", marginTop: 12, textAlign: "left" }}>{prop.name}</p></div>
                </GridItem>
              </GridContainer>
            </div>,
          time: moment(prop.createdAt).format("DD/MM/YYYY"),
          description: <Tooltip
            id="tooltip-top"
            title={prop.description}
            placement="top"
            classes={{ tooltip: this.props.classes.tooltip }}>
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {prop.description}
            </Truncate>
          </Tooltip>,
          city: prop.address ? (
            <Tooltip
              id="tooltip-top1"
              title={prop.address.city}
              placement="top"
              classes={{ tooltip: this.props.classes.tooltip }}>
              <Truncate lines={2} ellipsis={<span>...</span>}>
                {prop.address.city}
              </Truncate>
            </Tooltip>
          ) : "",
          number: <div>{prop.objectRegistered}
            <Button justIcon color="success" className="like" simple round onClick={() => this.handleClick(prop.uuid)}>
              <Tooltip
                // id="tooltip-viewdetail"
                title= {strings.detail}
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <FindInPage />
              </Tooltip>

            </Button>
          </div>,
          type: (prop.type === 0) ? "Vườn" : (prop.type === 1) ? "Nhà kính" : (prop.type === 2) ? "Khu chế biến" : (prop.type === 3) ? "Trang trại" : (prop.type === 4) ? "Lò mổ" : (prop.type === 5) ? "Khu đóng gói" : (prop.type === 6) ? "Kho chứa" : "",
          // view_detail: <div><Button justIcon color="transparent" onClick={() => this.handleClick(prop.uuid, prop.name)}>
          //   <FindInPage style={{ color: "#0f7343", width: 47 }} />
          // </Button>
          // </div>,
          actions: (
            <div className="actions-center">

              <Button
                justIcon
                round
                simple
                onClick={() => this.handleEditArea(prop.uuid)}
                color="warning"
                className="like"
              >
                <Tooltip
                  // id="tooltip-edit"
                  title={strings.edit}
                  placement="top"
                  classes={{ tooltip: this.props.classes.tooltip }}
                >
                  <Edit />
                </Tooltip>
              </Button>
            </div>
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  }

  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.GET_AREA_LIST);
      if (response.data.errorCode == 1) {
        //console.log(response.data.data)
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      await this.props.getArea();
    }
    catch (error) {
      console.log(error)
    }
  }
  async componentWillReceiveProps(preProps) {
    if (this.props.productArea !== preProps.productArea) {
      await this.setState({ farmList: preProps.productArea, showTable: "none" });
      if (preProps.productArea) {
        await this.genTable(Array.from([]))
        await this.genTable(Array.from(preProps.productArea))
      }
    }

  }
  async onSuccess(x) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });

    // let that=this;

    axios.post(LINK_API.CREATE_PRODUCTION_AREA, x)
      .then(async response => {
        const farmList = await this.getFarms();
        this.genTable(farmList);

        await this.setState({
          farmList: farmList,
          alert: (
            <SweetAlert
              success
              style={{ display: "block" }}
              title="Thành công"
              onConfirm={() => this.hideAlert_success()}
              showCancel={false}
            >
            </SweetAlert>
          )
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  handleNewClick() {
    // document.getElementById("info1").style = "display: none";
    // document.getElementById("info2").style = "display: block; width: 100%;"
    // document.getElementById("info3").style = "display: block; width: 100%"
    this.props.history.push("/admin/areas/create")
  }
  handleNewClick_Sub() {
    document.getElementById("info1").style = "display: block; width: 100%";
    document.getElementById("info2").style = "display: none";
    document.getElementById("info3").style = "display: none";
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        <div id="info1">
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                    <GridItem lg={6} md={6} sm={6} xs={12}>
                      <GridContainer style={{ display: "flex", flexFlow: "center" }}>
                        <h4 style={{ marginRight: 19 }} className="cardtableLabel">{strings.productionAreaList}</h4>
                        <div className="">
                          <ExcelFile element={<Button justIcon color="success" className="like" simple round > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> </Button>}>
                            <ExcelSheet data={this.state.export} name="Vùng sản xuất">
                              <ExcelColumn label="Mã vùng" value="uuid" />
                              <ExcelColumn label="Tên" value="name" />
                              <ExcelColumn label="Thời gian tạo" value="time" />
                              <ExcelColumn label="Mô tả" value="description" />
                              <ExcelColumn label="Loại" value="type" />
                              <ExcelColumn label="Địa chỉ" value="city" />
                              <ExcelColumn label="Tổng đối tượng" value="number" />
                              {/* <ExcelColumn label="Status" value="lastAction" /> */}
                            </ExcelSheet>
                          </ExcelFile>
                        </div>
                      </GridContainer>
                    </GridItem>

                    <GridItem lg={6} md={6} sm={6} xs={12}>
                      <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                        {(this.props.userInfo.data.active === true) ? <Button size="sm" color="success" onClick={this.handleNewClick}> <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add}</Button> : null}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <div style={{ textAlign: "center", display: this.state.load }}>
                    <img
                      alt="{name}"
                      src={IMG_LOAD}
                      style={{ height: "100px" }}
                    />
                  </div>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                      filter.id === "name" ? (String(row[filter.id].props.children.props.children[1].props.children.props.children.props.children).toLowerCase().includes(filter.value.toLowerCase())) : String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                    // defaultPageSize={5}
                    previousText={strings.prevPage}
                    nextText={strings.nextPage}
                    rowsText={strings.row}
                    ofText="/"
                    pageText={strings.page}
                    noDataText={strings.noData}
                    columns={[
                      {
                        Header: "#",
                        accessor: "id",
                        filterable: false,
                        className: "center",
                        Header: () => (
                          <div style={{
                            textAlign: "center"
                          }}>
                            {strings.idArea}
                        </div>
                        )
                      },
                      {
                        Header: strings.nameArea,
                        accessor: "name",
                        sortable: false
                      },
                      {
                        Header: strings.createTime,
                        accessor: "time",
                        filterable: false
                      },
                      {
                        Header: strings.description,
                        accessor: "description",
                        filterable: false
                      },
                      {
                        Header: strings.type,
                        accessor: "type",
                        filterable: false
                      },
                      {
                        Header: strings.address,
                        accessor: "city",
                        filterable: false
                      },
                      {
                        Header: strings.totalobject,
                        accessor: "number",
                        filterable: false
                      },
                      {
                        Header: strings.actionHeader,
                        accessor: "actions",
                        filterable: false
                      },

                    ]}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    sortable={false}
                    resizable={false}
                    className="-striped -highlight"
                    style={{ display: this.state.showTable }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <div id="info3" style={{ display: "none" }}>
          <GridItem lg={12} md={12} sm={12} xs={12} style={{ display: "flex", flexFlow: "row" }}>
            <ArrowLeft style={{ margin: "0px", marginRight: "5px" }} onClick={this.handleNewClick_Sub} />
            <p className="HeaderBack" onClick={this.handleNewClick_Sub}>Quay lại danh sách Vùng Sản Xuất</p>
          </GridItem>
        </div>
        <div id="info2" style={{ display: "none" }}>
          <CreateProduction onConfirm={this.onSuccess} />
        </div>
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getArea: () => dispatch({ type: "GET_AREA_LIST" }),
    getObjectInArea: (index) => dispatch({ type: "GET_OBJECT_IN_AREA", index: index }),
  }
}
const mapStateToProps = (state) => {
  return {
    productArea: state.productArea.data,
    userInfo: state.userInfo
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
