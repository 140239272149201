import React from "react";
import cx from "classnames";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx"
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import FormControl from "@material-ui/core/FormControl";
import ImageActions from "./ComponentEdit/ImageActions"
import timelineStyle from "./ComponentEdit/timelineStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import IconUpload from "./WizardForm/IconUpload.jsx"
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/Notifications";
import cookie from 'react-cookies';
import strings from "../../../../constants/strings";

const delay = (ms) => new Promise(res => setTimeout(res, ms))
class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            stories: [],
            authority: [],
            SM: true,
            SP: false,
            processStep: [],
            isHarvest: "",
            idCheck: "",
            activeNoti: false,
            messageNoti: "",
            inputType: ["Dạng chữ", "Dạng số"],
            addImages: [],
            deleteImages: [],
        }
        this.idOfProcess = cookie.load("idProcess");
        this.role = this.props.userInfo.data.type;
        this.handleAddOverviews = this.handleAddOverviews.bind(this);
        this.handle_change_story = this.handle_change_story.bind(this);
        this.onImageSubmit = this.onImageSubmit.bind(this);
        this.handle_overview_label = this.handle_overview_label.bind(this);
        this.handleToggle_more = this.handleToggle_more.bind(this);
        this.handle_overview_description = this.handle_overview_description.bind(this)
        this.handleToggle_default = this.handleToggle_default.bind(this);
        this.handle_overview_default = this.handle_overview_default.bind(this);
        this.handleRemoveInput = this.handleRemoveInput.bind(this);
        this.handleAddStories = this.handleAddStories.bind(this);
        this.onImageSubmitPortal = this.onImageSubmitPortal.bind(this);
        this.onImageSubmitMobile = this.onImageSubmitMobile.bind(this)
        this.handleFinal = this.handleFinal.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCreateProcess = this.handleCreateProcess.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.handleCheckedPublic = this.handleCheckedPublic.bind(this);
        this.handleCheckedPublic_more = this.handleCheckedPublic_more.bind(this);
        this.handleCreateFinal = this.handleCreateFinal.bind(this)
    }
    componentDidMount() {
        this.setState({
            stories: this.props.stories
        })
        if (this.props.setClick) {
            this.props.setClick(this.handleAddStories)
        }
        if (this.props.finalClick) {
            this.props.finalClick(this.handleFinal)
        }
    }
    componentWillReceiveProps(preProps) {
        if (preProps.stories !== this.props.stories) {
            this.setState({
                stories: preProps.stories
            })
        }
    }
    async showNotiValidated(message) {
        await this.setState(
            {
                activeNoti: true,
                messageNoti: message
            }
        )
        await delay(3000)
        this.setState(
            {
                activeNoti: false,
                messageNoti: ""
            }
        )
    }
    async handleSelectHarvest(event) {
        await this.setState({
            isHarvest: event.target.value,
            idCheck: event.target.value.idx
        })
    }
    isValidated() {
        // this.state.stories.map((prop, key)=>{
        //   if(!prop.name || !prop.description || prop.actions_image===0 || !prop.mobileIcon || !prop.icon){
        //     this.showNotiValidated("Vui lòng điền đủ thông tin tại bước số: #" + key)
        //     return;
        //   }
        // })
        for (let i = 0; i < this.state.stories.length; i++) {
            if (!this.state.stories[i].name || !this.state.stories[i].description || !this.state.stories[i].mobileIcon) {
                this.showNotiValidated("Vui lòng điền đủ thông tin tại bước số: #" + i + " (Tên bước, mô tả và icon)")
                return false;
            }
        }
        return true;
    }

    handleCheckedPublic = idx => evt => {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            return { ...overview, isPublic: evt.target.checked }
        })
        this.setState({
            stories: newOverviews
        })
    }
    handleCheckedPublic_more = (idx, idxx) => evt => {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            const newData = overview.mobile.method.map((prop, sidxx) => {
                if (idxx !== sidxx) return prop;
               
                return {
                    ...prop,
                   isPublic: evt.target.checked
                }
            })
            overview.mobile.method = newData;
            return overview
        })
        this.setState({
            stories: newOverviews
        })
    }
    async handleFinal(temp) {
        const check = this.isValidated();
        if (check) {
            let list = this.state.stories;
            let listSteps = []
            for (let i = 0; i < list.length; i++) {
                listSteps.push({
                    name: list[i].name,
                    idx: i,
                })
            }
            this.setState({
               // openCreateFarm: true,
                processStep: listSteps,
                mainData: temp
            })
            this.handleCreateProcess();
        }
    }
    async handleCreateProcess() {
       const newStories = await this.handleEditProcess();
    }
    handleCreateFinal(x){
         const data = {
            "process_name": this.state.mainData.process_name,
           // "role": this.state.mainData.role,
           "ownedBy": this.props.userInfo.data.uuid,
           "process_code": this.idOfProcess,
            "type": this.state.mainData.type,
            "specialStep": this.state.mainData.specialStep,
           // "packed": this.state.mainData.packed,
            "step": x.reverse()
        }
        this.props.createProcess(data);
        this.props.history.push("/admin/process/list")
    }
    handleEditProcess(){
        const newStep =  this.state.stories.map(async (prop, key) => {
            if (key !== this.state.idCheck) {
                delete prop.actions_image_src;
                delete prop.iconSrc;
                delete prop.mobileSrc;
                const newMethod =  await prop.mobile.method.map((temp, idxx)=>{
                   
                    delete temp.value;
                    delete temp.key;
                    return temp;
                })
                prop.mobile.method = newMethod;
                return prop;
            }
            if (key === this.state.idCheck) {
                let tempList = prop.mobile.method;
                tempList.push({
                    "label": "Sản lượng:",
                    "placeholder": "",
                    "type": "InputBox",
                    "required": "false",
                    "typeOfValue": "numeric"
                })
                delete prop.actions_image_src;
                delete prop.iconSrc;
                delete prop.mobileSrc;
                prop.mobile.method =  tempList;
                const newMethod =  await prop.mobile.method.map((temp, idxx)=>{
                    delete temp.value;
                    delete temp.key;
                    return temp;
                })
                prop.mobile.method = newMethod;
                return {
                    ...prop,
                    isHarvest: true,
                }
            }
        })
        const that = this;
        Promise.all(newStep).then(function(results) {
            console.log(results)
            that.handleCreateFinal(results)
        })
        return newStep;
    }
    handleClose() {
        this.setState({
            openCreateFarm: false
        })
    }
    async handleAddStories() {
        const temp = this.state.stories;
        await temp.unshift({
            inverted: true,
            badgeColor: "success",
            name: "",
            icon: "",
            iconSrc: "",
            mobileIcon: "",
            mobileSrc: "",
            publicName: "",
            attribute: "",
            isHarvest: false,
            isPublic: true,
            description: "",
            authority: "",
            actions_image: [],
            actions_image_src: [],
            checked: [],
            checked_default: [],
            checked_more: [],
            mobile: {
                icon: "",
                method: []
            }
        })
        await this.setState({
            stories: temp
        })
        
    }
    async handleRemoveInput(idx, idxx) {
        const newOverviews = await this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            const final = overview.mobile.method.filter((s, sidx) => idxx !== sidx)
            overview.mobile.method = final;
            return { ...overview }
        })
        await this.setState({
            stories: []
        })
        await this.setState({
            stories: newOverviews
        })
    }
    handleAddOverviews = async (idx) => {
        const index = 2;
        const temp = await this.state.stories.map((story, sidx) => {
            if (idx !== sidx) return story;
            let x = story.mobile.method;
            const element = {
                key: "", value: "",
                type: "InputBox",
                typeOfValue: "",
                label: "",
                required: "false",
                placeholder: "",
                defaultValue: "",
                isPublic: false
            };
            x.splice(index, 0, element);
            story.mobile.method = x;
            return {
                ...story,
            }
        })
        await this.setState({
            stories: []
        })
        await this.setState({
            stories: temp
        })
    }
    hideAlert() {
        this.setState({
            alert: null
        })
    }
    onImageSubmit = async (idx, file, src, checked) => {
        if(checked){
            this.state.deleteImages.push({
                "index": idx +1,
                "image": file
            })
        }else{
            let temp = [];
            for(let i=0;i< file.length;i++){
                temp.push({
                    "index": idx +1,
                    "image": file[i]
                })
            }
            await this.setState({
                addImages: temp
            })
        }
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            return { ...overview, actions_image: file, actions_image_src: src }
        })
        this.setState({
            stories: newOverviews
        })
    }
    async handleToggle_more(value, idx, idxx) {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            const newData = overview.listElement.map((prop, sidxx) => {
                if (idxx !== sidxx) return prop;
                const checked_more = prop.checked_more;
                const currentIndex = checked_more.indexOf(value);
                const newChecked = [...checked_more];
                let bool = false;
                if (currentIndex === -1) {
                    newChecked.push(value);
                    bool = true;
                } else {
                    newChecked.splice(currentIndex, 1);
                    bool = false;
                    // document.getElementById("default_value" + idx).style = "display: none; width: 100%"
                }
                return {
                    ...prop,
                    checked_more: newChecked, isPublic: bool
                }
            })
            return {
                ...overview,
                listElement: newData
            }
        })
        this.setState({
            stories: newOverviews
        })
    }
    async handleToggle_default(value, idx, idxx) {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            const newData = overview.listElement.map((prop, sidxx) => {
                if (idxx !== sidxx) return prop;
                const checked_default = prop.checked_default;
                const currentIndex = checked_default.indexOf(value);
                const newChecked = [...checked_default];
                if (currentIndex === -1) {
                    newChecked.push(value);
                    document.getElementById("default_value" + idxx).style = "display: block; width: 100%"
                } else {
                    newChecked.splice(currentIndex, 1);
                    document.getElementById("default_value" + idxx).style = "display: none; width: 100%"
                }
                return {
                    ...prop,
                    checked_default: newChecked
                }
            })
            return {
                ...overview,
                listElement: newData
            }
        })
        this.setState({
            stories: newOverviews
        })
    }
    handle_change_story = (idx, name) => evt => {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            if (name === "SP" || name === "SM") {
                let NV = name;
                let authority = [];
                if (overview.authority) authority = overview.authority;

                if (evt.target.checked === true) {
                    authority.push(name);
                    NV = true;
                }
                else {
                    NV = false;
                    let index = authority.indexOf(name)
                    if (index != -1) {
                        authority.splice(index, 1);
                    }

                }
                return { ...overview, authority: authority, [name]: NV }
            }
            return { ...overview, [name]: evt.target.value }
        })
        this.setState({
            stories: newOverviews
        })

        // console.log(this.stories)
    }
    handle_overview_default = (idx, idxx) => evt => {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            const newData = overview.listElement.map((prop, sidxx) => {
                if (idxx !== sidxx) return prop;
                return {
                    ...prop,
                    defaultValue: evt.target.value
                }
            })
            return {
                ...overview,
                listElement: newData
            }
        })
        this.setState({
            stories: newOverviews
        })
    }
    handle_overview_label = (idx, idxx) => evt => {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            const newData = overview.mobile.method.map((prop, sidxx) => {
                if (idxx !== sidxx) return prop;
                return {
                    ...prop,
                    label: evt.target.value
                }
            })
            overview.mobile.method = newData;
            return {
                ...overview,
            }
        })
        this.setState({
            stories: newOverviews
        })
    }
    async onImageSubmitPortal(idx, file, src) {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            return { ...overview, icon: file, iconSrc: src }
        })
        this.setState({
            stories: newOverviews
        })
    }
    async onImageSubmitMobile(idx, file, src) {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            if (file === null) {
                return { ...overview, mobileIcon: file, mobileSrc: src, icon: "", iconSrc: src }
            } else {
                return { ...overview, mobileIcon: file, mobileSrc: src, icon: "", iconSrc: src }
            }


        })
        this.setState({
            stories: newOverviews
        })
    }
    handle_overview_description = (idx, idxx) => evt => {
        const newOverviews = this.state.stories.map((overview, sidx) => {
            if (idx !== sidx) return overview;
            const newData = overview.mobile.method.map((prop, sidxx) => {
                if (idxx !== sidxx) return prop;
                if(evt.target.value === "Dạng chữ"){
                    return {
                        ...prop,
                        value: evt.target.value, typeOfValue: ""
                    }
                }
                else{
                    return {
                        ...prop,
                        value: evt.target.value, typeOfValue: "numeric"
                    }
                }
                
            })
            overview.mobile.method = newData;
            return overview
        })
        this.setState({
            stories: newOverviews
        })
    }
    render() {
        const { simple, classes } = this.props;
        const timelineClass =
            classes.timeline +
            " " +
            cx({
                [classes.timelineSimple]: simple
            });
        return (
            <ul className={timelineClass}>
                <Snackbar
                    place="br"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.messageNoti}
                    open={this.state.activeNoti}
                    closeNotification={() => this.setState({ activeNoti: false })}
                    close
                />
                {this.state.stories.map((prop, idx) => {
                    const panelClasses =
                        classes.timelinePanel +
                        " " +
                        cx({
                            [classes.timelinePanelInverted]: true || simple,
                            [classes.timelineSimplePanel]: simple
                        });
                    const timelineBadgeClasses =
                        classes.timelineBadge +
                        " " +
                        classes["success"] +
                        " " +
                        cx({
                            [classes.timelineSimpleBadge]: simple
                        });
                    return (
                        <li className={classes.item} key={idx}>
                            <div className={timelineBadgeClasses}>
                                {prop.icon ?
                                    <IconUpload
                                        src={require("../../../../assets/icons/" + prop.icon)}
                                        icon={true}
                                        portal={false}
                                        onImageSubmit={this.onImageSubmitMobile.bind(this)}
                                        idx={idx}
                                    /> :
                                    <IconUpload
                                        //src={prop.mobileSrc}
                                        icon={true}
                                        portal={false}
                                        onImageSubmit={this.onImageSubmitMobile.bind(this)}
                                        idx={idx}
                                    />}

                            </div>

                            <div className={panelClasses}>
                                <div className={classes.timelineBody}>
                                    <p className="TitleBold">{strings.nameStep}</p>
                                    <div style={{ marginTop: "-40px" }}>
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses,
                                                onChange: this.handle_change_story(idx, "name"),
                                            }}
                                            inputProps={{
                                                value: prop.name,
                                                autoComplete: "off",
                                                placeholder: strings.nameStep + " #" + idx
                                            }}
                                        />
                                    </div>
                                    <p className="TitleBold">{strings.description}</p>
                                    <div style={{ marginTop: "-40px" }}>
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses,
                                                onChange: this.handle_change_story(idx, "description"),
                                            }}
                                            inputProps={{
                                                value: prop.description,
                                                autoComplete: "off",
                                                placeholder: strings.description + " #" + idx
                                            }}
                                        />
                                    </div>

                                    <div style={{ marginTop: "-20px" }}>
                                        <div
                                            className={
                                                classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        // onClick={() => this.handle_change_story(idx, "SM")}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                        //value={prop.SM}
                                                        checked={prop.isPublic}
                                                        onChange={this.handleCheckedPublic(idx)}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label={strings.isPublic}
                                            />
                                        </div>
                                    </div>
                                    <p className="TitleBold">{strings.showPicture}</p>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        {prop.action_image ? <ImageActions
                                            onImageSubmit={this.onImageSubmit}
                                            idx={idx}
                                            icon={false}
                                            portal={false}
                                            src={prop.action_image}
                                        /> :
                                            <ImageActions
                                                onImageSubmit={this.onImageSubmit}
                                                idx={idx}
                                                icon={false}
                                                portal={false}
                                                src={[]}
                                            />}
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <Button color="success" size="sm"
                                            onClick={() => this.handleAddOverviews(idx)}
                                        >{strings.addFields}</Button>
                                    </GridItem>
                                  
                                    {prop.mobile.method ? prop.mobile.method.map((overview, idxx) => (
                                        idxx > 0 && idxx < prop.mobile.method.length - 2 ?
                                            <GridContainer key={idxx} style={{marginTop: "10px"}}>
                                                <GridItem xs={3} md={3} sm={3} lg={3}>
                                                    <p className="TitleBold">{strings.fieldName}</p>
                                                    <div style={{ marginTop: "-35px" }}>
                                                        <CustomInput
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                className: classes.customFormControlClasses,
                                                                onChange: this.handle_overview_label(idx, idxx),
                                                            }}
                                                            inputProps={{
                                                                value: overview.label,
                                                                autoComplete: "off",
                                                                placeholder: strings.fieldName + " #" + idxx
                                                            }}
                                                        />
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={4} md={4} sm={4} lg={4}>
                                                    <p className="TitleBold">{strings.typeData}</p>
                                                    <FormControl
                                                         style={{ marginTop: "-16px" }}
                                                        fullWidth
                                                        className={classes.selectFormControl}
                                                    >
                                                        <Select
                                                            MenuProps={{
                                                                className: classes.selectMenu
                                                            }}
                                                            id='select'
                                                            classes={{
                                                                select: classes.select
                                                            }}
                                                            value={overview.value}
                                                            onChange={this.handle_overview_description(idx, idxx)}
                                                            inputProps={{
                                                                name: "type",
                                                                id: "type",
                                                            }}
                                                        >
                                                            <MenuItem
                                                                disabled
                                                                classes={{
                                                                    root: classes.selectMenuItem
                                                                }}
                                                            >
                                                                {strings.chooseData}
                        </MenuItem>
                                                            {this.state.inputType.map((x, idxx) => (
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value={x}
                                                                    key={idxx}
                                                                >
                                                                    {x}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                   
                                                    {/* <div id={"default_value" + idxx} style={{ display: "none" }}>
                          <p className="TitleBold">Giá trị mặc định</p>
                          <div style={{ marginTop: "-40px" }}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handle_overview_default(idx, idxx),
                              }}
                              inputProps={{
                                //value: overview.defaultValue,
                                autoComplete: "off",
                                placeholder: "Giá trị mặc định #" + idxx
                              }}
                            />
                          </div>
                        </div> */}
                                                </GridItem>
                                                <GridItem xs={4} md={4} sm={4} lg={4} >
                                                    <p className="TitleBold">{strings.defaultValue}</p>
                                                    <div style={{ marginTop: "-35px" }}>
                                                        <CustomInput
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                className: classes.customFormControlClasses,
                                                                onChange: this.handle_overview_default(idx, idxx),
                                                            }}
                                                            inputProps={{
                                                                value: overview.defaultValue,
                                                                autoComplete: "off",
                                                                placeholder: strings.defaultValue + " #" + idxx
                                                            }}
                                                        />
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={1} md={1} sm={1} lg={1} className={classes.fixCardRemove} style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
                                                <div>
                                                    <Button color="danger" size="sm"
                                                        onClick={() => this.handleRemoveInput(idx, idxx)}
                                                    >X</Button>
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={12}>
                                                <div style={{ width: "100%" }}>
                                                        <FormControlLabel
                                                            classes={{
                                                                root: classes.checkboxLabelControl,
                                                                label: classes.checkboxLabel
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    tabIndex={-1}
                                                                    // onClick={() => this.handleToggle_more(1, idx, idxx)}
                                                                    checkedIcon={
                                                                        <Check className={classes.checkedIcon} />
                                                                    }
                                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                                    classes={{
                                                                        checked: classes.checked,
                                                                        root: classes.checkRoot
                                                                    }}
                                                                    checked={overview.isPublic}
                                                                    onChange={this.handleCheckedPublic_more(idx, idxx)}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            label= {strings.isPublic_sub}
                                                        />
                                                    </div>
                                                </GridItem>
                                            </GridContainer> : null
                                    )) : null}
                                </div>
                             
                                {prop.footerTitle ? (
                                    <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
                                ) : null}
                                {prop.footer ? <hr className={classes.footerLine} /> : null}
                                {prop.footer ? (
                                    <div className={classes.timelineFooter}>{prop.footer}</div>
                                ) : null}
                            </div>
                        </li>
                    );
                })}
                <Dialog
                    open={this.state.openCreateFarm}
                    onClose={this.handleClose}
                    PaperProps={{
                        classes: {
                            root: classes.paper
                        }
                    }}
                >
                    <Card style={{ width: 500 }}>
                        <CardHeader color="success" icon>
                            <h4 className="cardtableLabelProcess">Bạn thu hoạch tại bước nào?</h4>
                        </CardHeader>
                        <CardBody>
                            <FormControl
                                style={{ marginTop: "-20px" }}
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="product-expired-type"
                                    className={classes.selectLabel}
                                >
                                    Chọn bước thu hoạch
                              </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    id='select'
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={this.state.isHarvest}
                                    onChange={this.handleSelectHarvest.bind(this)}
                                    inputProps={{
                                        name: "type",
                                        id: "type",
                                    }}
                                >
                                    {this.state.processStep.map((x, idx) => (
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={x}
                                            key={idx}
                                        >
                                            {x.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </CardBody>
                        <GridItem xs={1}>
                            <Button color="success" size="sm" onClick={this.handleCreateProcess}>Xác nhận</Button>
                        </GridItem>
                    </Card>
                </Dialog>
            </ul>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getFarms: () => dispatch({ type: "GET_EMPLOYEE_LIST" }),
        createProcess: (data) => dispatch({ type: "EDIT_PROCESS", data: data })
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        employeeList: state.employeeList
    }
}
export default withStyles(timelineStyle)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Timeline)));
