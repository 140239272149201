import React from "react";
import Resizer from 'react-image-file-resizer';
// used for making the prop types of this component
import PropTypes from "prop-types";

import defaultImage from "assets/icons/selectImage.png"
import defaultAvatar from "assets/img/pictureicon.png";
import removeIcon from "assets/icons/deleteButton.png"
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import Gallery from "./Gallery/Gallery.js"
import GalleryPortal from "./GalleryPortal/Gallery.js"
import IconList from "../MobileIcon.jsx"
import PortalList from "../PortalIcon.jsx"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const LZUTF8 = require('lzutf8');
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: false,
      portal: false,
      alert: null,
      file: null,
      name: "",
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSelectImage = this.handleSelectImage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleImageSelected = this.handleImageSelected.bind(this);
    this.handleSelectPortal = this.handleSelectPortal.bind(this);
    this.handlePortalSelected = this.handlePortalSelected.bind(this);
    this.handleChoose = this.handleChoose.bind(this);
    this.findIconMobile = this.findIconMobile.bind(this);
  }
  componentDidMount(){
      this.setState({
          icon: this.props.icon ? this.props.icon : false,
          portal: this.props.portal ? this.props.portal: false,
          name: this.props.name,
          file: this.props.src ? this.props.src: null,
          imagePreviewUrl: this.props.src ? this.props.src : defaultImage
      })
      
  }
  componentWillReceiveProps(preProps){
    if(preProps.src !== this.props.src){
        if(preProps.src){
            this.setState({
                file: preProps.src,
                imagePreviewUrl:preProps.src, 
            })
        }else{
            this.setState({
                file: null,
                imagePreviewUrl:defaultImage, 
            })
        }
    }
  }
  findIconMobile(){
      
  }
  handleImageChange(e) {
    
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      this.props.onImageSubmit(LZUTF8.compress(this.state.imagePreviewUrl, { outputEncoding: 'Base64' }));
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  
  hideAlert(){
      this.setState({
          alert: null
      })
  }
  handleImageSelected(e){
    this.setState({
        imagePreviewUrl: IconList[`${e}`].src,
        alert: null,
        file: IconList[`${e}`].src,
    })
    this.props.onImageSubmit(IconList[`${e}`].name)
  }
  handlePortalSelected(e){
      //console.log( PortalList[`${e}`].src)
    this.setState({
        imagePreviewUrl: PortalList[`${e}`].src,
       alert: null,
        file: PortalList[`${e}`].src,
    })
    this.props.onImageSubmit(this.props.idx,PortalList[`${e}`].name,PortalList[`${e}`].src )
    
  }
  handleChoose(){
      if(this.state.icon === true){
          this.handleSelectImage();
          return;
      }
      if(this.state.portal === true){
          this.handleSelectPortal();
          return;
      }

  }
  handleSelectImage(){
      this.setState({
        alert: (
            <SweetAlert
              title=""
              onConfirm={() => this.hideAlert()}
              showCancel={false}
              showConfirm={false}
              style={{top: "35%"}}
            >
            <p className="iconPicker">Chọn icon</p>
            <div style={{width: "100%", marginTop: "-30px", marginLeft: "13px"}}>
            <Gallery 
           enableLightbox={false}
            onSelectImage={this.handleImageSelected}
            onClickThumbnail={this.handleImageSelected}
             rowHeight={50}
             images={IconList}/>
            </div>
            </SweetAlert>
          )
      })
  }
  handleSelectPortal(){
    this.setState({
      alert: (
          <SweetAlert
            title=""
            onConfirm={() => this.hideAlert()}
            showCancel={false}
            showConfirm={false}
            style={{top: "35%"}}
          >
          <p className="iconPicker">Chọn icon</p>
          <div style={{width: "100%", marginTop: "-30px",marginLeft: "13px"}}>
          <GalleryPortal
         enableLightbox={false}
          onSelectImage={this.handlePortalSelected}
          onClickThumbnail={this.handlePortalSelected}
           rowHeight={50}
           images={PortalList}/>
          </div>
          </SweetAlert>
        )
    })
}
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
  }
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div>
        {this.state.file === null ? 
         (this.state.icon=== false && this.state.portal===false ? <img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl} onClick={() => this.handleClick()}alt="..." />:<img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleChoose()} alt="..." />): (this.state.icon === true?
            <div className="addImageProcessIcon">
            <img src={this.state.imagePreviewUrl} style={{height:"60px", marginTop: "16px"}}></img>
            <img src={removeIcon} onClick={() => this.handleRemove()} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-173px", cursor: "pointer"}}/>
          </div>:(this.state.portal===true? <div className="addImageProcessPortal" style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
            <img src={this.state.imagePreviewUrl} style={{height:"50px", marginLeft: "32px"}}></img>
            <img src={removeIcon} onClick={() => this.handleRemove()} style={{width: "20px", height:"20px", marginLeft: "12px", marginTop: "-100px", cursor: "pointer"}}/>
          </div>: <div className="addImageProcess" style={{backgroundImage:`URL(${this.state.imagePreviewUrl})`}}>
            <img src={removeIcon} onClick={() => this.handleRemove()} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
          </div>)) 
         }
        </div>
        <div>
        </div>
        {this.state.alert}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default withStyles(sweetAlertStyle)(ImageUpload)