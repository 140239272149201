const initialState = { data: "", errorCode: "", totalItem: 0 , totalPage: 0, fullData: {} };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LIST_COW':
            return {
                ...state,
            };
        case 'GET_LIST_COW_SUCCESS':
            return {
                ...state,
                data: action.data,
                fullData: action.fullData,
                errorCode: action.errorCode,
                totalItem: action.totalItem,
                totalPage: action.totalPage
            };
        case 'GET_LIST_COW_FALURE':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        default:
            return state;
    }
}
export default reducer;