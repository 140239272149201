import React from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';

// @material-ui/core components
import { withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { FormControl, FormControlLabel, Checkbox } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import green from '@material-ui/core/colors/green';

import { LINK_API } from '../../constants/API';
import { IMG_LOAD, ERROR_CODE } from 'constants/index.js';
import responseCode from "constants/errorCode.js"
import loginFormStyle from "./loginFormStyle.jsx";
import { connect } from 'react-redux';
import strings from "../../constants/strings";
const ethers = require('ethers');
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: { useNextVariants: true },
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      user_name: null,
      password: null,
      load: false,
      checkInputUser: false,
      checkInputPass: false,
      data: '',
      checked: [],
      captcha: '',
      input_captcha: '',
      cur_captcha: '1',
      height: 100,
      width: 100,
      textColor: false,
      fontFamily: 'Verdana',
      fontSize: '30',
      paddingLeft: '20',
      paddingTop: '60',
      lenght: '5',
      background: 'none',
      number: 0
    };
    this.isLogin = cookie.load("isLogin")
    this.text = []
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.check = this.check.bind(this)
    this.result = this.result.bind(this)
    this.newCaptcha = this.newCaptcha.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.setData = this.setData.bind(this)

  }


  setData() {
    this.text = [];
    this.setState({
      height: this.props.height ? this.props.height : 100,
      width: this.props.width ? this.props.width : 100,
      textColor: this.props.textColor ? this.props.textColor : false,
      fontFamily: this.props.fontFamily ? this.props.fontFamily : 'Verdana',
      fontSize: this.props.fontSize ? this.props.fontSize : '30',
      paddingLeft: this.props.paddingLeft ? this.props.paddingLeft : '20',
      paddingTop: this.props.paddingTop ? this.props.paddingTop : '60',
      lenght: this.props.lenght ? this.props.lenght : '5',
      background: this.props.background ? this.props.background : 'none',
    })


    this.originText = []
    this.possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < this.state.lenght; i++) {
      let char = this.possible.charAt(Math.floor(Math.random() * this.possible.length))
      this.text.push(
        `<text 
                font-family="${this.state.fontFamily}" 
                font-size="${this.state.fontSize}" 
                x="${this.state.paddingLeft * i}" 
                y="${this.state.paddingTop}"
                fill="${ this.props.textColor ? this.props.textColor : "#" + ((1 << 24) * Math.random() | 0).toString(16)}"
                transform="rotate(${Math.random() * (5 - 0) + 0})"
            >${char}</text>`
      )
      this.originText.push(
        char
      )
    }
    this.result(this.originText.join(''))


  }
  componentDidMount() {
    if(!this.isLogin){
      this.props.cleanStore();
      return;
    }
    this.setData()
    const token = this.props.userInfo.data ? this.props.userInfo.data.token : "";
    this.setState({
      user_name: this.props.datalogin.email,
      password: this.props.datalogin.pw_hash
    })
    if (token) {
      this.props.history.push("/admin/farms/list");
      axios.defaults.headers.common['Authorization'] = token;
      if (this.props.userInfo.data.role === "Z") {
        this.props.history.push("/admin/adminonly/subscription")
      } else {
        if (this.props.userInfo.data.role === "R") {
          this.props.history.push("/admin/retailer/sale")
        }
        else {
          if (this.props.userInfo.data.role === "F") {
            this.props.history.push("/admin/distributor/list")
          } else {
            this.props.history.push("/admin/farms/list")
          }
        }
      }
    }
    else {
      this.timeOutFunction = setTimeout(
        function () {
          this.setState({ cardAnimaton: "" });
        }.bind(this),
        100
      );
    }

  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  async componentDidUpdate(preProps) {
    if (preProps.datalogin !== this.props.datalogin) {
      this.setState({
        user_name: this.props.datalogin.email,
        password: this.props.datalogin.pw_hash
      })
    }
  }
  // handleChange = name => async event => {
  //   await this.setState({
  //     [name]: event.target.value,
  //   });
  //   switch (name) {
  //     case 'user_name':
  //       let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  //       if (!filter.test(this.state.user_name)) {
  //         document.getElementById("user_name").style = "border-color:red"
  //         document.getElementById("noti").innerHTML = "Vui lòng điền địa chỉ email!";
  //         this.setState({ checkInputUser: false });
  //       } else {
  //         document.getElementById("user_name").style = ""
  //         document.getElementById("noti").innerHTML = "";
  //         this.setState({ checkInputUser: true });
  //       }
  //       break;
  //     case 'password':
  //       if (this.state.password === '') {
  //         document.getElementById("password").style = "border-color:red"
  //         document.getElementById("noti").innerHTML = "Vui lòng nhập mật khẩu tài khoản";
  //         this.setState({ checkInputPass: false });
  //       } else {
  //         document.getElementById("password").style = ""
  //         document.getElementById("noti").innerHTML = "";
  //         this.setState({ checkInputPass: true });
  //       }
  //       break;
  //   }
  // };
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  handleChange = name => async event => {
    await this.setState({
      [name]: event.target.value,
    });
    //var n = this.state.email.indexOf('@');
    // if(n !== -1){
    //   switch (name) {
    //     case 'email':
    //       let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    //       if (!filter.test(this.state.mail)) {
    //         this.setState({ checkEmail: false });
    //       } else {
    //         this.setState({ checkEmail: true });
    //       }
    //       break;
    //   }
    // }
    if (name === "user_name") {
      if (this.state.user_name) {
        this.setState({
          usernameHelp: "",
          usernameState: "success"
        })
        document.getElementById("noti").innerHTML = ""
      } else {
        this.setState({
          usernameHelp: "",
          usernameState: "error"
        })
        document.getElementById("noti").innerHTML = "";
      }
    }
    if (name === "password") {
      if (this.state.pw_hash) {
        this.setState({
          pwhashHelp: "",
          pwhashState: "success"
        })
      } else {
        this.setState({
          pwhashHelp: "",
          pwhashState: "error"
        })
      }
    }

  };


  async handleSignIn() {
    //check Tên đăng nhập và mật khẩu có hợp lệ trước khi gửi

    if (!this.state.user_name) {
      document.getElementById("noti").innerHTML = "Vui lòng nhập tên đăng nhập!";
      return;
    }
    if (!this.state.password) {
      document.getElementById("noti").innerHTML = "Vui lòng nhập mật khẩu!";
      return;
    }

    var n = this.state.user_name.indexOf('@');
    if (n !== -1) {
      var check = this.verifyEmail(this.state.user_name)
      if (check === false) {
        document.getElementById("noti").innerHTML = "Email bạn vừa nhập không hợp lệ";
        return;
      }
    }
    var temp = new Buffer(this.state.password).toString('hex')
    const encryptedPass = await ethers.utils.keccak256('0x' + temp)
    //await cookie.save('passhash', encryptedPass, { path: '/' });
    const data = {
      email: this.state.user_name,
      pw_hash: encryptedPass
    }
    this.setState({ load: true });

    axios.post(LINK_API.SIGN_IN, data)
      .then(async response => {
        if (response.data.errorCode === ERROR_CODE.SUCCESSFUL.errorCode) {
          if (this.state.number > 2) {
            if (this.state.input_captcha !== this.state.captcha) {
              document.getElementById("noti").innerHTML = "Nhập sai mã captcha!";
              return;
            }
          }
          cookie.save('isLogin',true,{path:'/'});
          cookie.save('network', response.data.data.network)
          this.props.saveUserInfo(response.data.data, this.state.password);
          if (this.state.checkedG) { await cookie.save('save', 'true', { path: '/' }); } else await cookie.save('save', 'false', { path: '/' });
          axios.defaults.headers.common['Authorization'] = response.data.data.token;
          if (response.data.data.role === "Z") {
            this.props.history.push("/admin/adminonly/subscription")
          } else {
            if (response.data.data.role === "R") {
              this.props.history.push("/admin/retailer/sale")
            }
            else {
              if (response.data.data.role === "F") {
                this.props.history.push("/admin/distributor/list")
              } else {
                this.props.history.push("/admin/farms/list")
              }
            }
          }
        } else {
          this.setState({ load: false });
          switch (response.data.errorCode) {
            case ERROR_CODE.MISSING_EMAIL.errorCode:
              document.getElementById("noti").innerHTML = "Vui lòng nhập tên đăng nhập!";
              break;
            case ERROR_CODE.EMAIL_OR_PASSWORD_IS_INCORRECT.errorCode:
              this.setState({ number: this.state.number + 1 });
              document.getElementById("noti").innerHTML = "Tên đăng nhập hoặc mật khẩu không chính xác!";
              break;
            case ERROR_CODE.MISSING_PASSWORD.errorCode:
              document.getElementById("noti").innerHTML = "Vui lòng điền mật khẩu!";
              break;
            default:
              document.getElementById("noti").innerHTML = `Lỗi, vui lòng thử lại!`;
          }
          //document.getElementById("noti").innerHTML = `${responseCode[response.data.name].vi}`;
        }
      }).catch((error) => {
        this.setState({ load: false });
        this.setState({ number: this.state.number + 1 });
        console.log("error", error);
        if (this.state.number > 2) {
          if (this.state.input_captcha !== this.state.captcha) {
            document.getElementById("noti").innerHTML = "Nhập sai mã captcha!";
            return;
          }
        }
        //if(error.response){
        //   document.getElementById("noti").innerHTML = `${responseCode[error.response.data.name].vi}`;
        // }else{
        document.getElementById("noti").innerHTML = "Không thành công! Vui lòng thử lại sau!";
        // }

        // console.log("Auth login", error);
      });
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
  }

  handleClick(e) {
    e.preventDefault();
    this.check()
  }

  result(text) {
    this.setState({
      captcha: text
    })
  }

  newCaptcha() {
    this.setData();
  }
  check() {
    //console.log(this.state.captcha, this.captchaEnter.value, this.state.captcha === this.captchaEnter.value)
  }
  render() {
    const { classes } = this.props;
    // console.log(this.state.number);
    return (
      <div style={{ padding: "20px", marginTop: "-14px" }}>
        {(!this.state.load) ? (<div>
          <MuiThemeProvider theme={theme}>
            <p
              id="noti"
              style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: 'red',
                fontFamily: '"Muli',
                marginTop: 5,
                marginBottom: 5,
              }}>

            </p>
            <FormControl style={{ width: '100%', marginTop: 5, marginBottom: 5 }}>
              <CustomInput
                success={this.state.usernameState === "success"}
                error={this.state.usernameState === "error"}
                style={{ paddingTop: "0px !important" }}
                //  helpText={this.state.usernameHelp}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('user_name'),
                }}
                value={this.state.user_name}
                inputProps={{
                  value: this.state.user_name,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Face className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: strings.usernameoremail
                }}
              />

              <CustomInput
                success={this.state.pwhashState === "success"}
                error={this.state.pwhashState === "error"}
                //  helpText={this.state.pwhashHelp}
                variant="outlined"
                labelProps={{
                  variant: "outlined",
                }}
                formControlProps={{
                  variant: 'outlined',
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('password'),
                }}
                value={this.state.password}
                inputProps={{
                  value: this.state.password,
                  type: "password",
                  autoComplete: "off",
                  variant: "outlined",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                              </Icon>
                    </InputAdornment>
                  ),
                  placeholder: strings.passWord
                }}
              />
            </FormControl>
            {
              (this.state.number > 2) ? (
                <div style={{ marginTop: "-14px" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        //  helpText={this.state.pwhashHelp}
                        variant="outlined"
                        labelProps={{
                          variant: "outlined",
                        }}
                        formControlProps={{
                          variant: 'outlined',
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('input_captcha'),
                        }}
                        value={this.state.input_captcha}
                        inputProps={{
                          value: this.state.input_captcha,
                          type: "capcha",
                          autoComplete: "off",
                          variant: "outlined",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                check_circle_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Captcha"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <img
                        style={{ background: this.state.background }}
                        src={
                          'data:image/svg+xml;base64,' +
                          btoa('<svg ' +
                            'xmlns="http://www.w3.org/2000/svg" ' +
                            'height="' + this.state.height + '" ' +
                            'width="' + this.state.width + '">' +
                            this.text.join() +
                            '</svg>')
                        }
                        alt="" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <div style={{ marginTop: 25 }}> <Button justIcon color="transparent" onClick={() => this.newCaptcha()}><Icon>loop</Icon></Button></div>
                    </GridItem>
                  </GridContainer>


                </div>
              ) : null}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', justifyContent: 'space-between' }}>
              <FormControlLabel
                style={{ marginLeft: "-2px", marginRight: "0px" }}
                control={
                  <Checkbox
                    checked={this.state.checkedG}
                    onClick={() => this.handleToggle(1)}
                    value="checkedG"
                    classes={{
                      root: classes.checkgreen,
                      checked: classes.checked,
                    }}
                  />
                }
                label={
                  <span>
                    {" "}
                    <a href="#" style={{ color: "#3C4858", fontWeight: 500, fontSize: "15px" }}>{strings.rememberPass}</a>.
                    </span>
                }
              />
              {/* <p style={{marginLeft: "-160px", fontWeight: "500", fontSize: "15px"}}>Nhớ mật khẩu</p> */}
              <p
                style={{
                  fontSize: '15px',
                  fontWeight: '500',
                }}>
                {strings.forgotPass}?
          </p>
            </div>
            <Button fullWidth variant="contained" color="success" className={classes.button} onClick={this.handleSignIn}>
              {strings.logIn}
          </Button>
            {/* <span style={{ fontSize: "15px", fontWeight: "500", marginTop: "2px" }}>Chưa có tài khoản? <a onClick={this.props.onProcess} className="registertext">Đăng ký</a></span> */}
          </MuiThemeProvider>
        </div>) : (<div>
          <img src={IMG_LOAD} style={{ height: '100px', marginTop: '40px' }} />
        </div>)}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
    cleanStore: () => dispatch({type: "CLEAN_STORE"}),
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo }
}
LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginFormStyle)(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage)));
