const MobileIcon = [
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437288/Icons/Mobile/2-_%C4%90%C3%93NG_G%C3%93I_3x_v19lnp.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437288/Icons/Mobile/2-_%C4%90%C3%93NG_G%C3%93I_3x_v19lnp.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Đóng gói",
        name: "DongGoi",
        vi: "Đóng gói",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570439644/Icons/Mobile/defaultIcon2_ytdast.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570439644/Icons/Mobile/defaultIcon2_ytdast.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Icon mặc định",
        name: "defaultIcon",
        vi: "Icon mặc định",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570439640/Icons/Mobile/sprinkler_ab0dfu.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570439640/Icons/Mobile/sprinkler_ab0dfu.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Tưới nước",
        name: "sprinkler",
        vi: "Tưới nước",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/5_-_PHUN_X%E1%BB%8AT_zx8zkk.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/5_-_PHUN_X%E1%BB%8AT_zx8zkk.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Phun xịt",
        name: "spray",
        vi: "Phun xịt",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437271/Icons/Mobile/06_-_B%C3%93N_PH%C3%82N_jbnlt2.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437271/Icons/Mobile/06_-_B%C3%93N_PH%C3%82N_jbnlt2.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Bón phân",
        name: "fertilizer",
        vi: "Bón phân",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/04_-_BAO_TR%C3%81I_3x_juhibk.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/04_-_BAO_TR%C3%81I_3x_juhibk.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Bao trái",
        name: "bag",
        vi: "Bao trái",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437288/Icons/Mobile/1_-_S%C6%A0_CH%E1%BA%BE_3x_xmofik.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437288/Icons/Mobile/1_-_S%C6%A0_CH%E1%BA%BE_3x_xmofik.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Sơ chế",
        name: "soChe1",
        vi: "Sơ chế",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437287/Icons/Mobile/01_-_S%C6%A0_CH%E1%BA%BE_3x_pg7lmm.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437287/Icons/Mobile/01_-_S%C6%A0_CH%E1%BA%BE_3x_pg7lmm.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Sơ chế",
        name: "soChe2",
        vi: "Sơ chế",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/03_-_THU_HO%E1%BA%A0CH_3x_dxjldh.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/03_-_THU_HO%E1%BA%A0CH_3x_dxjldh.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Thu hoạch",
        name: "thuHoach",
        vi: "Thu hoạch",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437272/Icons/Mobile/10_-_RA_L%C3%92_X%E1%BB%AC_L%C3%9D_B%C3%81N_TH%C3%80NH_PH%E1%BA%A8M_3x_tjwapc.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437272/Icons/Mobile/10_-_RA_L%C3%92_X%E1%BB%AC_L%C3%9D_B%C3%81N_TH%C3%80NH_PH%E1%BA%A8M_3x_tjwapc.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Đăng ký cây",
        name: "dangKyCay",
        vi: "Đăng ký cây",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/8_-_K%C3%8DCH_HO%E1%BA%A0T_TEM_2x_kjpk59.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/8_-_K%C3%8DCH_HO%E1%BA%A0T_TEM_2x_kjpk59.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Kích hoạt tem",
        name: "KichHoatTem",
        vi: "Kích hoạt tem",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/9_-_B%E1%BA%A2O_QU%E1%BA%A2N_kzbfsz.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437270/Icons/Mobile/9_-_B%E1%BA%A2O_QU%E1%BA%A2N_kzbfsz.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Bảo quản",
        name: "baoQuan",
        vi: "Bảo quản",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437272/Icons/Mobile/10_-_RA_L%C3%92_X%E1%BB%AC_L%C3%9D_B%C3%81N_TH%C3%80NH_PH%E1%BA%A8M_3x_tjwapc.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437272/Icons/Mobile/10_-_RA_L%C3%92_X%E1%BB%AC_L%C3%9D_B%C3%81N_TH%C3%80NH_PH%E1%BA%A8M_3x_tjwapc.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Xử lý bán thành phẩm",
        name: "raLoXuLyBanThanhPham",
        vi: "Xử lý bán thành phẩm",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437273/Icons/Mobile/11_-_CH%E1%BB%88NH_TH%C3%94NG_S%E1%BB%90_3x_tl756x.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437273/Icons/Mobile/11_-_CH%E1%BB%88NH_TH%C3%94NG_S%E1%BB%90_3x_tl756x.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Chỉnh thông số",
        name: "chinhThongSo",
        vi: "Chỉnh thông số",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437273/Icons/Mobile/12_-_CHU%E1%BA%A8N_B%E1%BB%8A_V%C3%80O_L%C3%92_L%C3%8AN_MEN_3x_oo5xfj.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437273/Icons/Mobile/12_-_CHU%E1%BA%A8N_B%E1%BB%8A_V%C3%80O_L%C3%92_L%C3%8AN_MEN_3x_oo5xfj.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Lên men",
        name: "chuanBiVaoLoLenMen",
        vi: "Lên men",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437274/Icons/Mobile/13_-_NGUY%C3%8AN_LI%E1%BB%86U_gvq7at.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437274/Icons/Mobile/13_-_NGUY%C3%8AN_LI%E1%BB%86U_gvq7at.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Nguyên liệu",
        name: "nguyenLieu",
        vi: "Nguyên liệu",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570440675/Icons/Mobile/chonNguyenLieu_js5knj.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570440675/Icons/Mobile/chonNguyenLieu_js5knj.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Chọn nguyên liệu",
        name: "chonNguyenLieu",
        vi: "Chọn nguyên liệu",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437275/Icons/Mobile/15_-_KI%E1%BB%82M_TRA_CH%E1%BA%A4T_L%C6%AF%E1%BB%A2NG_kdm3vu.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437275/Icons/Mobile/15_-_KI%E1%BB%82M_TRA_CH%E1%BA%A4T_L%C6%AF%E1%BB%A2NG_kdm3vu.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Kiểm tra chất lượng",
        name: "kiemTraChatLuong",
        vi: "Kiểm tra chất lượng",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437275/Icons/Mobile/16_-_PH%C6%A0I_KH%C3%94_3x_oyqcks.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437275/Icons/Mobile/16_-_PH%C6%A0I_KH%C3%94_3x_oyqcks.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Phơi khô",
        name: "phoiKho",
        vi: "Phơi khô",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437275/Icons/Mobile/17_-_TR%E1%BB%98N_B%E1%BB%98T_i16kmp.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437275/Icons/Mobile/17_-_TR%E1%BB%98N_B%E1%BB%98T_i16kmp.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Trộn bột",
        name: "tronBot",
        vi: "Trộn bột",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437277/Icons/Mobile/18_-_VI%C3%8AN_TH%C3%80NH_N%E1%BB%A4_3x_p7uwky.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437277/Icons/Mobile/18_-_VI%C3%8AN_TH%C3%80NH_N%E1%BB%A4_3x_p7uwky.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Viên thành nụ",
        name: "vienThanhNu",
        vi: "Viên thành nụ",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437277/Icons/Mobile/19_-_XAY_TH%C3%80NH_B%E1%BB%98T_3x_ajyrmo.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437277/Icons/Mobile/19_-_XAY_TH%C3%80NH_B%E1%BB%98T_3x_ajyrmo.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Xay thành bột",
        name: "xayThanhBot",
        vi: "Xay thành bột",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437278/Icons/Mobile/21_-_PH%C3%82N_LO%E1%BA%A0I_fm5ogp.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437278/Icons/Mobile/21_-_PH%C3%82N_LO%E1%BA%A0I_fm5ogp.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Phân loại",
        name: "phanLoai",
        vi: "Phân loại",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437279/Icons/Mobile/22_-_CH%C4%82M_S%C3%93C_%C4%90%E1%BA%B6C_BI%E1%BB%86T_3x_wf2mca.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437279/Icons/Mobile/22_-_CH%C4%82M_S%C3%93C_%C4%90%E1%BA%B6C_BI%E1%BB%86T_3x_wf2mca.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Chăm sóc đặc biệt",
        name: "chamSocDacBiet",
        vi: "Chăm sóc đặc biệt",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437279/Icons/Mobile/23_-_GH%C3%89P_C%C3%82Y_ctgbcd.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437279/Icons/Mobile/23_-_GH%C3%89P_C%C3%82Y_ctgbcd.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Ghép cây",
        name: "ghepCay",
        vi: "Ghép cây",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437279/Icons/Mobile/24_-_GIEO_GI%E1%BB%90NG_diuuzv.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437279/Icons/Mobile/24_-_GIEO_GI%E1%BB%90NG_diuuzv.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Gieo giống",
        name: "gieoGiong",
        vi: "Gieo giống",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437280/Icons/Mobile/25_-_TH%C3%80NH_PH%E1%BA%A8M_llsjh3.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437280/Icons/Mobile/25_-_TH%C3%80NH_PH%E1%BA%A8M_llsjh3.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Thành phẩm",
        name: "thanhPham",
        vi: "Thành phẩm",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437280/Icons/Mobile/26_-_L%C3%8AN_MEN_ubj6te.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437280/Icons/Mobile/26_-_L%C3%8AN_MEN_ubj6te.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Lên men",
        name: "lenMen",
        vi: "Lên men",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437281/Icons/Mobile/27_-_R%E1%BB%ACA_3x_zbakts.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437281/Icons/Mobile/27_-_R%E1%BB%ACA_3x_zbakts.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Rửa",
        name: "rua",
        vi: "Rửa",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437282/Icons/Mobile/28_-_NG%C3%82M_N%C6%AF%E1%BB%9AC_3x_ws9xme.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437282/Icons/Mobile/28_-_NG%C3%82M_N%C6%AF%E1%BB%9AC_3x_ws9xme.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Ngâm nước",
        name: "ngamNuoc",
        vi: "Ngâm nước",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437282/Icons/Mobile/29_-_L%C3%80M_S%E1%BA%A0CH_3x_izycd6.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437282/Icons/Mobile/29_-_L%C3%80M_S%E1%BA%A0CH_3x_izycd6.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Làm sạch",
        name: "lamSach",
        vi: "Làm sạch",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437282/Icons/Mobile/30_-_CH%E1%BB%8CN_L%E1%BB%8CC_z4ajts.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437282/Icons/Mobile/30_-_CH%E1%BB%8CN_L%E1%BB%8CC_z4ajts.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Chọn lọc",
        name: "chonLoc",
        vi: "Chọn lọc",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437283/Icons/Mobile/31_-_%E1%BB%A6_H%E1%BA%A0T_3x_qzq7fp.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437283/Icons/Mobile/31_-_%E1%BB%A6_H%E1%BA%A0T_3x_qzq7fp.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Ủ hạt",
        name: "uHat",
        vi: "Ủ hạt",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437283/Icons/Mobile/32_-_RANG_H%E1%BA%A0T_3x_yaxdh0.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437283/Icons/Mobile/32_-_RANG_H%E1%BA%A0T_3x_yaxdh0.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Rang hạt",
        name: "rangHat",
        vi: "Rang hạt",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437284/Icons/Mobile/33_-_PH%C6%A0I_CH%E1%BA%ACM_TR%C3%8AN_GI%C3%80N_3x_v5tkfl.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437284/Icons/Mobile/33_-_PH%C6%A0I_CH%E1%BA%ACM_TR%C3%8AN_GI%C3%80N_3x_v5tkfl.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Phơi chậm trên giàn",
        name: "phoiChamTrenGian",
        vi: "Phơi chậm trên giàn",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437285/Icons/Mobile/34_-_THU_HO%E1%BA%A0CH_3x_sdxqrw.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437285/Icons/Mobile/34_-_THU_HO%E1%BA%A0CH_3x_sdxqrw.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Thu hoạch cà phê",
        name: "thuHoachCF",
        vi: "Thu hoạch cà phê",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437284/Icons/Mobile/35_-_V%E1%BA%ACN_CHUY%E1%BB%82N_uals4n.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437284/Icons/Mobile/35_-_V%E1%BA%ACN_CHUY%E1%BB%82N_uals4n.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Vận chuyển",
        name: "delivery",
        vi: "Vận chuyển",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437285/Icons/Mobile/36_-_NH%E1%BA%ACT_K%C3%9D_fbcet2.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437285/Icons/Mobile/36_-_NH%E1%BA%ACT_K%C3%9D_fbcet2.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Nhật ký",
        name: "NhatKy",
        vi: "Nhật ký",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437285/Icons/Mobile/37_-_GIAO_H%C3%80NG_mdrfa0.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437285/Icons/Mobile/37_-_GIAO_H%C3%80NG_mdrfa0.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Giao hàng",
        name: "giaohang",
        vi: "Giao hàng",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437286/Icons/Mobile/38_-_NH%E1%BA%ACN_H%C3%80NG_3x_sjohpa.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437286/Icons/Mobile/38_-_NH%E1%BA%ACN_H%C3%80NG_3x_sjohpa.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Nhận hàng",
        name: "nhanhang",
        vi: "Nhận hàng",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437287/Icons/Mobile/TUOI_NUOC_3x_hv93a6.svg",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570437287/Icons/Mobile/TUOI_NUOC_3x_hv93a6.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Tưới nước",
        name: "tuoiNuoc",
        vi: "Tưới nước",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570444085/Icons/Mobile/finish_dpsua1.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570444085/Icons/Mobile/finish_dpsua1.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Kết thúc",
        name: "finish",
        vi: "Kết thúc",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570444112/Icons/Mobile/invoice_eiu5t2.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570444112/Icons/Mobile/invoice_eiu5t2.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Hóa đơn",
        name: "invoice",
        vi: "Hóa đơn",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570444067/Icons/Mobile/speak_mxr4xa.png",
        thumbnail: "https://res.cloudinary.com/agridential/image/upload/v1570444067/Icons/Mobile/speak_mxr4xa.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Speak",
        name: "speak",
        vi: "Speak",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570444134/Icons/Mobile/verified-text-paper_ronzlk.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570444134/Icons/Mobile/verified-text-paper_ronzlk.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Xác thực",
        name: "verifiedTextPaper",
        vi: "Xác thực",
    },
]
export default MobileIcon;