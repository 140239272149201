const initialState = { data: "", errorCode: "" };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_LIST':
            return {
                ...state,
            };
        case 'GET_PRODUCT_LIST_SUCCESS':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        case 'GET_PRODUCT_LIST_FALURE':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        case 'CREATE_PRODUCT':
            return {
                ...state,
            };
        default:
            return state;
    }
}
export default reducer;