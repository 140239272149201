const customTabsStyle = {
    cardTitle: {
      float: "left",
      padding: "10px 10px 10px 0px",
      lineHeight: "24px"
    },
    cardHeaderColor: {
      background: "#4caf50 !important",
      boxShadow:
      "0 12px 0px -10px rgba(140, 140, 140, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(70, 70, 70, 0.2) !important",
      padding: "10px 15px !important"
    },
    cardTitleRTL: {
      float: "right",
      padding: "10px 0px 10px 10px !important"
    },
    displayNone: {
      display: "none !important"
    },
    tabsRoot: {
      minHeight: "unset !important",
      minWidth: "50px !important",
      "& $tabRootButton": {
        fontSize: "0.875rem"
      }
    },
    tabRootButton: {
      padding: '6px 12px !important',
      fontSize: '0.6875rem !important',
      lineHeight: '1.5 !important',
      borderRadius: '0.2rem',
      outline:"0 !important",
      width: "auto",
      border: "1px solid #d1d8e0 !important",
      color: "#000",
      display:"inline-block",
      marginLeft: "4px",
      "&:hover,&:focus": {
        outline: 0,
        background:'#4caf50',
        color:'#ffffff',
        boxShadow: "none!important",
      },
      minHeight:'auto',
      minWidth: '50px!important',
      "&:first-child": {
        marginLeft: 0,
      }
    },
    tabLabelContainer: {
      padding: "0px"
    },
    tabSelected: {
      // backgroundColor: "rgba(255, 255, 255, 0.2)",
      outline:"0 !important",
      border: "1px solid #4caf50 !important",
      borderRadius: "3px  !important",
      borderBottom: "1px solid #4caf50 !important",
      transition: "0.2s background-color 0.1s",
      background: '#4caf50',
      color : "#ffffff"
    },
    tabWrapper: {
      display: "inline-block",
      minHeight: "unset !important",
      minWidth: "unset !important",
      width: "unset !important",
      height: "unset !important",
      maxWidth: "unset !important",
      maxHeight: "unset !important",
      "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
        verticalAlign: "middle",
        margin: "-1px 5px 0 0",
        color:"#5a5a5a"
      }
    }
  };
  
  export default customTabsStyle;
  