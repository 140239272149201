import { combineReducers } from 'redux';
import pushNoti from "./pushNoti"
import productType from "./productType"
import userInfo from "./saveInfoUser"
import userProfile from "./getUserProfile"
import productArea from "./productionArea"
import employeeList from "./employee"
import objectList from "./objectProduction"
import cropList from "./crop"
import notification from "./notification"
import processList from "./process"
import objectDetail from "./getObjectDetail"
import cropObject from "./cropObject"
import tooltip from "./tooltip"
import objectProccessDetail from "./getObjectProcessingDetail"
import objectProcessList from "./objectProcess"
import cropObjectProcessing from "./cropObjectProcessing"
import branchList from "./branch.js"
import harvestList from "./harvestList"
import harvestStatistic from "./harvestStatistic"
import branchStatistic from "./branchStatistic"
import cropStatistic from "./cropStatistic"
import subscription from "./subscription"
import distributorList from "./distributor"
import banner from "./banner"
import logo from "./logo"
import sidebarStatus from "./sidebarstatus"
import storage from 'redux-persist/lib/storage';
import planList from "./plan"
import listCustomer from "./listCustomer"
import importList from "./importList"
import unPaidList from "./unPaidList"
import numberAccesses from "./numberAccesses"
import numProductToBranch from "./numProductToBranch"
import numProductToDistributor from "./numProductToDistributor"
import approveList from "./approveList"
import activeStampProductType from "./activeStampProductType"
import numberCropProductType from "./numberCropProductType"
import generalStampActive from "./generalStampActive"
import renderEmp from "./renderEmpList"
import renderArea from "./renderAreaList"
import infoActiveStamp from "./infoActiveStamp"
import checkStamp from "./checkStamp"
import objectArea from "./objectArea"
import ownList from "./ownlist"
import renderUnit from  "./renderUnit"
import stampInCase from "./stampInCase"
import cowList from "./cowList"
const appReducer = combineReducers({
  pushNoti,
  notification,
  tooltip,

  productType,
  productArea,
  employeeList,
  objectList,
  objectProcessList,
  cropList,
  processList,
  cropObject,
  cropObjectProcessing,
  branchList,
  harvestList,
  distributorList,
  planList,
  listCustomer,
  importList,
  unPaidList,
  approveList,
  renderArea,
  renderEmp,
  renderUnit,
  stampInCase,
  cowList,

  banner,
  logo,
  sidebarStatus,
  checkStamp,

  objectDetail,
  objectProccessDetail,
  objectArea,
  ownList,

  userInfo,
  userProfile,
  
  harvestStatistic,
  branchStatistic,
  cropStatistic,

  
  subscription,
  numberAccesses,

  numProductToBranch,
  numProductToDistributor,
  activeStampProductType,
  numberCropProductType,
  generalStampActive,
  infoActiveStamp
});


const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    state = undefined;
    storage.removeItem('persist:root')
  }

  return appReducer(state, action);
};

export default rootReducer;