import React from "react";
import PropTypes from "prop-types";
import cookie from 'react-cookies';
import cx from "classnames";
import axios from "axios";
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import SweetAlert from "react-bootstrap-sweetalert";
import { IMG_LOAD } from "constants/index.js";
import { LINK_API } from "constants/API";
import ViewList from "@material-ui/icons/ViewList";
import CustomDropdown from './Button.jsx';
// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";
import headerStyle from "./HeaderStyle.jsx";
import AGD from "assets/img/bg-img/agd.png";
import profile from "assets/img/default-avatar.png"
import Snackbar from "components/Snackbar/Snackbar.jsx";
import {store} from "store/store.js";
import AddAlert from "@material-ui/icons/Notifications";
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import Menu from "@material-ui/icons/Menu";
import strings from "../../../../constants/strings.jsx";

class Header extends React.Component{
  constructor(props){
    super(props)
    this.state={
      alert: null,
      name: "",
      avatar: "",
      collapse: ""
    }
    this.isLogin = cookie.load("isLogin")
    this.handleSelect= this.handleSelect.bind(this);
    this.handleSignOut=  this.handleSignOut.bind(this);
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.isEmptyObject = this.isEmptyObject.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
  }
  async componentDidMount(){

   if(!this.isLogin){
    this.props.cleanStore();
    return;
   };
    this.setState({
      collapse: this.props.expandAble
    })
    await this.props.getUserProfile();
    await this.props.deactiveTooltip();
    if(!this.props.productArea){
      this.props.getArea();
    }
  }
  componentWillReceiveProps(preProps){
    if(this.props.userProfile !== preProps.userProfile){
      if(preProps.userProfile.data){
        this.setState({
          name: preProps.userProfile.data.name,
          avatar: preProps.userProfile.data.avatar ? (preProps.userProfile.data.avatar.length > 0 ? preProps.userProfile.data.avatar[0].secure_url : "" ): "",
        })
      }
    }
    if(preProps.expandAble !== this.props.expandAble){
      this.setState({
        collapse: preProps.expandAble
      })
    }
    // if(this.props.productArea !== preProps.productArea){
    //   if(preProps.productArea.length === 0){
    //     const data ={
    //       "name": "Vùng sản xuất 1",
    //       "gln": "",
    //       "image": "",
    //       "type": 0,
    //       "city": this.props.userInfo.data.address,
    //       "district": "",
    //       "glnCheckbox": false,
    //       "description": "Chưa có"
    //     }
    //    // this.props.createArea(data)
    //   }
    // }
  }
  isEmptyObject = (obj) => {
    if(!obj) return true
    if(obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  handleSignOut = () => {
    //loading
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          // confirmBtnCssClass={
          //   this.props.classes.button + " " + this.props.classes.success
          // }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    let that = this;
    axios
      .get(LINK_API.SIGN_OUT, {})
      .then(async function(response) {
        that.setState({
          alert: null
        });

        if (response.data.errorCode === 1) {
          //await that.props.saveUserInfo(" "," ")
          //await that.props.saveNoti(" ")
          await that.props.cleanStore();
          
          that.props.history.push("/");
        } else {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Đăng xuất thành công
              </SweetAlert>
            )
          });
        }
      })
      .catch(function(error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng đăng xuất lại
            </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  };

  openConfirmDialog = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn muốn đăng xuất?"
          onConfirm={() => this.handleSignOut()}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Đăng xuất"
          cancelBtnText="Hủy"
          showCancel
        />
      )
    });
  };
  handleSelect = async (event) => {
   const temp = await event.props.children[1].props.children.props.children;
   if(temp === strings.logOut){
    this.openConfirmDialog();
   }
   if(temp=== strings.profile ){
     this.props.history.push("/admin/profile")
   }
  }
  hideAlert = () => {
    this.setState({
      alert: null
    })
  }
  handleCollapse = () => {
    if(this.props.miniActive ===  true){
      this.props.sidebarMinimize();
      this.props.deactiveTooltip();
    }else{
      this.props.sidebarMinimize();
      this.props.activeTooltip(this.state.collapse)
    }
  }
  render(){
  const { classes, color, rtlActive } = this.props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
       <Snackbar
                        place="br"
                        color={store.getState().pushNoti.color}
                        icon={AddAlert}
                        //message={this.state.textNoti}
                        message={store.getState().pushNoti.message}
                        //open={this.state.tr}
                        open={store.getState().pushNoti.tr}
                        closeNotification={() => this.setState({ tr: false })}
                        close
                      />
      {this.state.alert}
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {this.props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={this.handleCollapse}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={this.handleCollapse}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
       
        
        {/* <div className={classes.flex}> */}
        <div className={classes.flex}>
        <img src={AGD} alt="AGD" style={{height: "40px", marginLeft: "12px"}} />
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks />
        </Hidden>
        <Hidden smDown implementation="css">
        <CustomDropdown
          onClick={(menuItem) => this.handleSelect(menuItem)}
          buttonProps={{
            round: false,
            color: "transparent"
          }}
          buttonText={<span>{this.state.avatar?<img src={this.state.avatar} className="profileimageheader" alt="..."/>:<img src={profile} className="profileimageheader" alt="..."/>}{this.state.name}</span>}
          className="dropdownProfile"
          dropdownList={[
          // <span><img src={avatar} className="iconprofile" alt="..."/><a className="labelProfile">Thông tin tài khoản</a></span>,
          <div style={{display: "flex", flexFlow: "row", marginTop: "10px"}}><div><Icon fontSize="small">person</Icon></div><div><a className="labelProfile">{strings.profile}</a></div></div>,
          {divider: true},
          <div style={{display: "flex", flexFlow: "row", marginTop: "10px"}}><div><Icon fontSize="small">lock</Icon></div><div><a className="labelProfile">{strings.logOut}</a></div></div>,
        ]}
      />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={this.props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
      }
}

const mapStateToProps = (state) => {
  return { userProfile: state.userProfile,
          tooltip: state.tooltip,
          productArea: state.productArea.data,
          userInfo: state.userInfo}
}
const mapDispatchToProps = (dispatch) => {
  return {
    createArea: (data) => dispatch({ type: "CREATE_AREA", data: data }),
    saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
    saveNoti: () => dispatch({ type: "CLEAR_NOTIFICATION"}),
    getUserProfile: () => dispatch({type: "GET_USER_PROFILE"}),
    activeTooltip: (data) => dispatch({ type: "ACTIVE_TOOLTIP", data: data}),
    deactiveTooltip: () => dispatch({type: "DEACTIVE_TOOLTIP"}),
    cleanStore: () => dispatch({type: "CLEAN_STORE"}),
    getArea: () => dispatch({type: "GET_AREA_LIST"}),
  }
}
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

export default withStyles(headerStyle)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));
