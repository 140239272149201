import React from 'react';
// import './index.css';
import ReactDOM from 'react-dom';
import {store} from 'store/store.js';
import App from './App'

import * as serviceWorker from './serviceWorker';

window.addEventListener("load", (ev) => 
{  
    ev.preventDefault();
});

const render = () => ReactDOM.render(<App store={store}/>, document.getElementById('root'));
render();
store.subscribe(render)

serviceWorker.unregister();