import React from "react";
import axios from "axios";
import cookie from "react-cookies";
import moment from "moment";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { IMG_LOAD } from "constants/index.js";
import { LINK_API } from "constants/API";
import { connect } from 'react-redux';
import Add from "@material-ui/icons/Add";
import Table from "components/Table/Table.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import Search from "@material-ui/icons/Search";
import "../css/CustomeReactTable.css";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { cps } from "@redux-saga/core/effects";
import Shipment from "@material-ui/icons/LocalShipping";
import strings from "constants/strings.jsx"
const lan = cookie.load("language");
if (lan) {
  strings.setLanguage(lan);
}
else {
 strings.setLanguage("vi");
}

const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx').Transaction;

const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...extendedTablesStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      checked: false,
      value: {
        gln_extend: "",
        passphrase: "",
        data: ""
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none"
    };
    this.handleImportBox = this.handleImportBox.bind(this);
    this.hideAlert = this.hideAlert.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }
  handleImport () {
    if(!this.state.idBox){
      document.getElementById("importBox").innerHTML ="Vui lòng nhập mã thùng muốn nhập!"
    }
    if(!this.state.detailBox){
      document.getElementById("importBox").innerHTML ="Vui lòng nhập miêu tả cho thùng hàng!"
    }
    this.setState({
      alert: (
        <SweetAlert
        warning
        style={{ display: "block"}}
        title="Đang xử lý!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          sweetAlertStyle.button + " " + sweetAlertStyle.success
        }
        showConfirm={false}
        showCancel={false}
      >
        <div style={{textAlign:"center"}}>
          <img
            alt="{name}"
            src={IMG_LOAD}
            style={{height:"100px"}}
          />
        </div>
      </SweetAlert>
      )
    })
    const data = {
      "toId": this.state.idBox,
      "detail": this.state.detailBox
    }
    axios.post(LINK_API.GET_INFO_BOX, data)
    .then(res=> {
      if(res.data.errorCode === 1){
        var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        const privatekey_slice = privateKey.substring(2,privateKey.length);
        const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
        const tx = new EthereumTx(res.data.data.contractRaw, { chain: 4 });
        tx.sign(Buffer_privatekey);
        const rawTx = '0x' + tx.serialize().toString('hex');
        const data = {
          "toId": this.state.idBox,
          "detail": this.state.detailBox,
          "raw": rawTx
        }
        axios.post(LINK_API.RECEIVE_BOX, data)
        .then(res => {
            if(res.data.errorCode === 1){
              this.props.getlist();
              this.setState({
                alert: (
                  <SweetAlert
                success
                style={{ display: 'block' }}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                showCancel={false}
              />
                )
              })
            }else{
              this.setState({
                alert: (
                  <SweetAlert
                    error
                    style={{ display: "block"}}
                    title="Không thành công"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                      this.props.classes.button + " " + this.props.classes.success
                    }
                  >
                  </SweetAlert>
                )
              });
            }
        })
        .catch(err => {
          console.log(err)
        })
      }
    })
    .catch(err=> {
      console.log(err)
    })
  }
  handleChecked = name => event => {
    //document.getElementById("noti").innerHTML = "";
    this.setState({ [name]: event.target.checked });
  };
  handleImportBox(){
    this.setState({
      alert: (
        <SweetAlert
        warning
        style={{ display: "block" }}
        title="Nhập mã thùng"
        onConfirm={() => this.handleImport()}
        onCancel={() => this.hideAlert()}
        showConfirm={true}
        showCancel={true}
        confirmBtnText="Nhập hàng"
        cancelBtnText="Hủy"
        confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
        cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
      >
        <div>
        <CustomInput
										labelText="Mã thùng"
										success={this.state.usernameState === 'success'}
										error={this.state.usernameState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: this.props.classes.customFormControlClasses,
											onChange: this.handleChange('idBox')
										}}
										inputProps={{
											value: this.state.idBox,
											autoComplete: 'off',
											placeholder: "Mã thùng"
										}}
									/>
                   <CustomInput
										labelText="Miêu tả"
										success={this.state.usernameState === 'success'}
										error={this.state.usernameState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: this.props.classes.customFormControlClasses,
											onChange: this.handleChange('detailBox')
										}}
										inputProps={{
											value: this.state.detailBox,
											autoComplete: 'off',
											placeholder: "Thông tin thùng hàng"
										}}
									/>
                  	<p
							style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
							id="importBox"
							style={{
								fontSize: '0.875rem',
								fontWeight: '400',
								color: 'red',
								fontFamily: 'Muli',
								marginTop: 5,
								marginBottom: 5
							}}
						/>
        </div>
      
      </SweetAlert>
      )
    })
  }
  handleChange = name => evt => {
    this.setState({
      [name]: evt.target.value
    })
    document.getElementById("importBox").innerHTML=""
  }
  hideAlert(){
    this.setState({
      alert: null,
      idBox: "",
      detailBox: ""
    })
  }
  renderTrigger() {
    return <Button color="success">In Mã</Button>;
  }

  renderContent = () => {
    // eslint-disable-line arrow-body-style
    return this.componentRef;
  };

  setRef = ref => {
    this.componentRef = ref;
  };

  genTable = farmList => {
    this.setState({
      data: farmList.map((prop, key) => {
        const productList = [];
        for(let i=0;i<prop.product.length;i++){
          productList.push([
            i+1,
            prop.product[i].uuid,
            prop.product[i].id,
            prop.product[i].status === 0 ? "Chưa bán": "Đã bán"
          ])
        }
        return {
          id: key + 1,
          uuid: prop.id,
          createAt: moment(prop.date).format("DD/MM/YYYY hh:mm:ss"),
          status: prop.status === 0 ? "Đóng thùng" : prop.status === 1 ? "Đã bán":prop.status === 2? "Đã mở thùng":prop.status === 3?"Đã chuyển hàng":prop.status === 4?"Đã nhận hàng":"",
          idproduct_Start: prop.product.length > 0 ? prop.product[0].id : "",
          idproduct_End: prop.product.length > 0 ? prop.product[prop.product.length - 1].id : "",
          countProduct: prop.product.length,
          product: productList,
          actions: (
            <div>
               <Tooltip
            id="tooltip-top"
            title="Tra cứu Blockchain"
            placement="top"
            classes={{ tooltip: this.props.classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => {
                window.open("https://portal.agridential.vn/lookup/" + prop.id);
              }}
              color="warning"
              className="like"
            >
              <Search />
            </Button>
          </Tooltip>
              
            </div>
           
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  };

  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, {
        headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
      });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        return this.props.history.push("/");
      } else {
        axios.defaults.headers.common["Authorization"] = this.props.userInfo.data.token;
      }
    } catch (error) {
      console.log(error);
      return this.props.history.push("/");
    }
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.GET_STAMP_LIST_RETAILER);
      if (response.data.errorCode == 1) {
        return response.data.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  async componentDidMount() {
    try {
      await this.checkPermission();
      this.props.getlist();
    } catch (error) {
      console.log(error);
    }
  }
  async componentWillReceiveProps(preProps){
    if(preProps.importList !== this.props.importList){
      if(preProps.importList.data){
        await this.genTable(Array.from([]))
        await this.genTable(Array.from(preProps.importList.data))
      }
    }
  }
  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input
        type="text"
        placeholder={placeholder}
        style={{
          width: "100%"
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ""}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <h4 className="cardtableLabel">{strings.historyExport}</h4>
                  </GridItem>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                  <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                  <Button color="success" size="sm" onClick={this.handleImportBox}><Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button>
                  </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  data={this.state.data}
                  filterable
                  // defaultPageSize={5}
                  previousText="Trang Trước"
                  nextText="Trang Sau"
                  rowsText="hàng"
                  ofText="/"
                  pageText="Trang"
                  noDataText="Nhấn nút TẠO MỚI để đăng ký loại sản phẩm!"
                  columns={[
                    {
                      Header: "#",
                      accessor: "id",
                      filterable: false,
                      maxWidth: 70,
                      className: "center",
                      Header: () => (
                        <div
                          style={{
                            textAlign: "center"
                          }}
                        >
                          #
                        </div>
                      )
                    },
                    {
                      Header: "Mã thùng",
                      accessor: "uuid",
                      filterable: false
                    },
                    {
                      Header: "Số sản phẩm",
                      accessor: "countProduct",
                      filterable: false
                    },
                    {
                      Header: "Mã SP bắt đầu",
                      accessor: "idproduct_Start",
                      filterable: false
                    },
                    {
                      Header: "Mã SP kết thúc",
                      accessor: "idproduct_End",
                      filterable: false
                    },
                    // {
                    //   Header: "Thời gian nhập",
                    //   accessor: "date",
                    //   sortable: false,
                    //   filterable: false
                    // },
                    {
                      Header: "Trạng thái",
                      accessor: "status",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Hành động",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                  SubComponent={row => {
                    return (
                      <div style={{ padding: "20px" }}>
                        <em>
                        Danh sách sản phẩm
                        </em>
                        <br />
                        <br />
                        <Table
                tableHead={[
                  "#",
                  "Mã định danh",
                  "Mã truy xuất",
                  "Trạng thái",
                 
                ]}
                tableData={row.original.product}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
                <br />
                        <br />
                      </div>
                    );
                }}
              />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.alert}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
    importList: state.importList}
}
const mapDispatchToProps = (dispatch) => {
  return {
    getlist: () => dispatch({ type: "GET_BOX_UNIT" }),
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps,mapDispatchToProps )(TableFarmList)));
