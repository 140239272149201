import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import "./retailpage.css";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import List from "@material-ui/icons/List";
import Button from "components/CustomButtons/Button.jsx";
import { IMG_LOAD } from 'constants/index.js';
import SweetAlert from "react-bootstrap-sweetalert";
import Add from "@material-ui/icons/Add";
import { LINK_API } from 'constants/API';
import Select from 'react-select';
import axios from 'axios';
import cookie from 'react-cookies';
import TextField from '@material-ui/core/TextField';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import qrIcon from 'assets/img/qrcodesarch.svg';
import ScanPage from './Scan.jsx'
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import AddAlert from "@material-ui/icons/Clear";
import { connect } from 'react-redux';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import strings from "../../../../constants/strings";


const ethers = require('ethers');
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx').Transaction;


const options = [
  { value: 'chocolate', label: 'Khách hàng 1' },
  { value: 'strawberry', label: 'Khách hàng 2' },
  { value: 'vanilla', label: 'Khách hàng 3' },
];
const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }, state) => {
    return {
      ...styles,
      backgroundColor: isDisabled,
      cursor: isDisabled ? 'not-allowed' : 'default',
      color: isSelected ? 'green' : 'black',
      padding: 20,
      fontWeight: 400,
      fontFamily: "Muli",
      '&:hover': { borderColor: 'green' },

    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};
const styles = {
  ...notificationsStyle,
  ...CheckStyles,
  cardIconTitle: {
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  retailpageproduct: {
    marginTop: "31px",
    paddingBottom: "10px !important",
    border: "solid 0.5px rgba(199, 189, 189, 0.46)",
    boxShadow: "0px 0px 5px 0px rgba(194,194,194,1)",
    padding: "10px 10px !important",
    borderRadius: 5
  },
  SelectDrop: {
    width: "400px",
    marginTop: "5px",
    marginRight: "5px"
  },
  SelectDropx: {
    width: "100%",
    marginTop: "5px",
    marginRight: "5px"
  },
  qrButton: {
    padding: "0px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "35px",
    width: "35px",
    marginTop: "3px",
    marginLeft: "0px",
    "&:hover,&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  },
};

class RetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrStatus: false,
      data: [],
      selectedOption: null,
      allProducts: [],
      paidProducts: [],
      finalList: [],
      showList: [],
      totalPrice: 0,
      buyProducts: [],
      alert: null,
      value_text: "",
      paidProducts_2: [],
      status: false,
      stamp: "",
      checked: [1],
      checkedState: true,
      disabledPay: true,
      tr: false,
      stampnonGS1: ""
    }
    this.routeScan = this.routeScan.bind(this);
    this.handleResult = this.handleResult.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.showNotification = this.showNotification.bind(this);

  }
  async componentDidMount() {
    await this.checkPermission();
    this.props.getlist();
  }
  async componentWillReceiveProps(preProps) {
    if (this.props.unPaidList !== preProps.unPaidList) {
      this.getdata(preProps.unPaidList.data)
    }
  }
  showNotification(place) {
    if (!this.state[place]) {
      var x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(
        function () {
          x[place] = false;
          this.setState(x);
        }.bind(this),
        6000
      );
    }
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    if (this.state.checked[0] !== 1) {
      this.setState({
        checkedState: false
      })
    } else {
      this.setState({
        checkedState: true
      })
    }
  }
  getdata = async (final) => {
    await this.setState({
      showList: final
    })
  }
  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.token;
      }
    }
    catch (error) {
      return this.props.history.push("/");
    }
  }
  hideAlert() {
    this.setState({
      alert: null,
    })
  }
  onPayment = async () => {
    if (this.state.status === true) {
      this.setState({
        alert: (
          <SweetAlert
            style={{ display: "block" }}
            title="Đang xử lý"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
          >
            <div style={{ textAlign: "center" }}>
              <img
                alt="{name}"
                src={IMG_LOAD}
                style={{ height: "100px" }}
              />
            </div>
          </SweetAlert>
        )
      });
      const temp = this.state.buyProducts;

      const getInfo = {
        "items": []
      }
      let result = {
        "rawTxs": []
      }
      const x = temp.length;
      var i = 0;
      for (i = 0; i < x; i++) {
       await getInfo.items.push(temp[i].id);
      }
      await axios.post(LINK_API.GET_INFO_SELL_PRODUCT, getInfo)
        .then(async response => {
          if (response.data.errorCode === 1) {
            const listRaw = response.data.data.rawTxs;
            var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
            const privateKey = bytes.toString(CryptoJS.enc.Utf8);
            const privatekey_slice = privateKey.substring(2, privateKey.length);
            const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
            for (let j = 0; j < listRaw.length; j++) {
              let tx = new EthereumTx(listRaw[j].paramTxs, { chain: 4 });
              tx.sign(Buffer_privatekey);
              let rawTx = '0x' + tx.serialize().toString('hex');
              result.rawTxs.push({
                "index": 0,
                "uuids": [
                  `${temp[j].uuid}`
                ],
                "itemIds": [
                  `${temp[j].id}`
                ],
                "raw": rawTx
              })
            }
            await axios.post(LINK_API.SELL_PRODUCT, result)
                          .then(async response_sell => {
                            if (response_sell.data.errorCode === 1) {
                              this.setState({
                                alert: (
                                  <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title="Thành công"
                                    onConfirm={() => this.hideAlert()}
                                    onCancel={() => this.hideAlert()}
                                    showCancel={false}
                                    confirmBtnText="Đóng"
                                    confirmBtnCssClass={
                                      this.props.classes.button + " " + this.props.classes.success
                                    }
                                  >
                                  </SweetAlert>
                                )
                              });
                            } else {
                              this.setState({
                                alert: (
                                  <SweetAlert
                                    error
                                    style={{ display: "block" }}
                                    title="Không thành công"
                                    onConfirm={() => this.hideAlert()}
                                    onCancel={() => this.hideAlert()}
                                    showCancel={false}
                                    confirmBtnText="Đóng"
                                    confirmBtnCssClass={
                                      this.props.classes.button + " " + this.props.classes.success
                                    }
                                  >
                                  </SweetAlert>
                                )
                              });
                            }
                          })
                          .catch(async err => {
                            this.setState({
                              alert: (
                                <SweetAlert
                                  error
                                  style={{ display: "block" }}
                                  title="Không thành công"
                                  onConfirm={() => this.hideAlert()}
                                  onCancel={() => this.hideAlert()}
                                  showCancel={false}
                                  confirmBtnText="Đóng"
                                  confirmBtnCssClass={
                                    this.props.classes.button + " " + this.props.classes.success
                                  }
                                >
                                </SweetAlert>
                              )
                            });
                          })
          }
        })
        .catch(err => {
          console.log(err)
        })

    }
  }
    handleChange_x = async selectedOption => {
      console.log(selectedOption)
    }
    handleChange_textfield = name => async event => {
      //setValues({ ...values, [name]: event.target.value });
      this.setState({
        value_text: event.target.value
      })
      var x = 0;
      if (event.target.value.length === 13) {
        x = event.target.value;
      }
      else {
        x = event.target.value.substr(37, event.target.value.length);
      }
      if (x.length === 20) {
        var z = x.substr(0, 13);
        x = z;
      }
      if (x.length === 13) {
        axios.get(LINK_API.GET_PRODUCT_STAMP + x + "&nestedgroup=true")
          .then(async response => {
            if (response.errorCode === 500) {
              // this.setState({
              //   value_text:"",
              //   alert: (
              //     <SweetAlert
              //       error
              //       style={{ display: "block"}}
              //       title= "Không tìm thấy sản phẩm!"
              //       onConfirm={() => this.hideAlert()}
              //       onCancel={() => this.hideAlert()}
              //       confirmBtnCssClass={
              //         this.props.classes.button + " " + this.props.classes.success
              //       }
              //     >
              //     </SweetAlert>
              //   )
              // });
            }
            else {

              await this.setState({
                stamp: response.data.data.mappingInfo.gtin
              })
              var check = false;
              var o = 0;
              console.log(this.state.stamp)
              for (o = 0; o < this.state.showList.length; o++) {
                if (this.state.stamp === this.state.showList[o].uuid) {
                  check = true;
                  console.log(check)
                }
              }
              if (check === true) {
                this.removeitem(this.state.stamp)
                this.setState({
                  status: true,
                  stampnonGS1: x,
                })
                let temp = {
                  image: "",
                  searchCode: x,
                  productCode: this.state.stamp,
                  productName: response.data.data.productInfo.name,
                  quantity: 1,
                  unit: "chai",
                  price: "200$",
                  unitprice: "0%",
                  tax: "0%",
                  total: 200,
                  status: "Chưa bán"
                }
                if (this.state.checkedState === true) {
                  var datasend = {
                    "items": []
                  };
                  datasend.items.push(x);
                  console.log(datasend)
                  await axios.post(LINK_API.GET_INFO_SELL_PRODUCT, datasend)
                    .then(async response => {
                      if (response.data.errorCode === 1) {
                        const getTxParam = response.data.data.rawTxs[0];

                        var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
                        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
                        const privatekey_slice = privateKey.substring(2, privateKey.length);
                        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
                        const tx = new EthereumTx(getTxParam.paramTxs, { chain: 4 });
                        tx.sign(Buffer_privatekey);
                        const rawTx = '0x' + tx.serialize().toString('hex');

                        const signedData = {
                          "index": 0,
                          "images": this.state.employee,
                          "uuids": [
                            `${this.state.stamp}`
                          ],
                          "itemIds": [
                            `${x}`
                          ],
                          "raw": rawTx
                        }
                        let result = {
                          "rawTxs": []
                        }
                        result.rawTxs.push(signedData)
                        //console.log("Signed data: ", result)
                        await axios.post(LINK_API.SELL_PRODUCT, result)
                          .then(async response_sell => {
                            if (response_sell.data.errorCode === 1) {
                              //console.log("Đã bán rồi mà :))) sao ")
                              temp.status = strings.sold
                              //console.log("Change", temp.status)
                            } else {
                              temp.status = strings.failed;
                              await this.setState({
                                message: "Bán không thành công sản phẩm: " + this.state.stampnonGS1
                              })
                              this.showNotification("tr")
                            }
                          })
                          .catch(async err => {
                            temp.status = strings.failed;
                            await this.setState({
                              message: "Bán không thành công sản phẩm: " + this.state.stampnonGS1
                            })
                            this.showNotification("tr")
                          })
                      } else {
                        temp.status = strings.failed;
                        await this.setState({
                          message: "Bán không thành công sản phẩm: " + this.state.stampnonGS1
                        })
                        this.showNotification("tr")
                      }
                    })
                    .catch(async err => {
                      temp.status = "Thất bại"
                      await this.setState({
                        message: "Bán không thành công sản phẩm: " + this.state.stampnonGS1
                      })
                      this.showNotification("tr")
                    })

                } else {
                  this.state.buyProducts.push({
                    uuid: this.state.stamp,
                    id: x
                  });
                  this.setState({
                    disabledPay: false
                  })
                }
                var pricetotal = this.state.totalPrice + 1;
                await this.setState({
                  totalPrice: pricetotal
                })
                await this.state.finalList.push(temp);
                this.genTable(this.state.finalList)
                this.setState({
                  value_text: ""
                })
              } else {
                await this.setState({
                  value_text: "",
                  message: "Sản phẩm đã bán hoặc không tồn tại!"
                })
                this.showNotification("tr")
              }
            }

          })
          .catch(error => {
            this.setState({
              value_text: "",
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block" }}
                  title="Không tìm thấy sản phẩm!"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                >
                </SweetAlert>
              )
            });
          })

      }


    };
    handleChange = async (selectedOption, event) => {
      this.removeitem(selectedOption)
      this.state.buyProducts.push(selectedOption.value)
      var temp = {
        productCode: selectedOption.value,
        productName: selectedOption.name,
        quantity: 1,
        unit: "chai",
        price: "200$",
        unitprice: "0%",
        tax: "0%",
        total: 200,
      }
      var pricetotal = this.state.totalPrice + 1;
      await this.setState({
        totalPrice: pricetotal
      })
      await this.state.finalList.push(temp);
      this.genTable(this.state.finalList)
    };


    async removeitem(e) {
      await this.setState({
        showList: this.state.showList.filter(function (list) {
          return list.uuid !== e
        })
      });
    }
    async removeitem_table(e) {
      await this.setState({
        finalList: this.state.finalList.filter(function (list) {
          return list.productCode !== e.productCode
        })
      });
    }
    genTable = (farmList) => {
      this.setState({
        data: farmList.map((prop, key) => {
          return {
            id: key + 1,
            searchCode: prop.searchCode,
            productCode: prop.productCode,
            productName: prop.productName,
            unit: prop.unit,
            quantity: prop.quantity,
            price: prop.price,
            unitprice: prop.unitprice,
            tax: prop.tax,
            statuspay: prop.status,
            total: prop.total,
            image: (
              <div>
                <img src={prop.image} style={{ height: "45px" }}></img>
              </div>
            ),
            actions: (
              <div>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    var data = this.state.data;
                    data.find(async (o, i) => {
                     // console.log(o);
                      if (o.id === key + 1) {
                        this.state.showList.push(o.productCode)
                        this.removeitem_table(o)
                        //console.log(this.state.showList)
                        data.splice(i, 1);
                        return true;
                      }
                      return false;
                    });
                    this.setState({ data: data });
                  }}
                  color="danger"
                  className="remove"
                >
                  <Close />
                </Button>{" "}
                <Tooltip
                  id="tooltip-top"
                  title="Tra cứu Blockchain"
                  placement="top"
                  classes={{ tooltip: this.props.classes.tooltip }}
                >
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      window.open("https://portal.agridential.vn/lookup/" + prop.searchCode);
                    }}
                    color="warning"
                    className="like"
                  >
                    <Search />
                  </Button>
                </Tooltip>
              </div>
            )
          };
        }),
        load: "none",
        showTable: ""
      });
    }

    makePlaceholderFilter(placeholder) {
      return ({ filter, onChange }) => (
        <input type='text'
          placeholder={placeholder}
          style={{
            width: '100%'
          }}
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
        />
      )
    }
    async routeScan() {
      await this.setState({
        qrStatus: true,
      })
      document.getElementById("retailtable").style = "display: none"
    }
    async handleResult(data) {
      await this.setState({
        qrStatus: false,
      })
      document.getElementById('retailtable').style = "display: block; width: 100%";
      this.setState({
        value_text: data
      })
      var x = 0;
      if (data.length === 13) {
        x = data;
      }
      else {
        x = data.substr(37, data.length);
      }
      //console.log(x.length)
      if (x.length === 13) {

        axios.get(LINK_API.GET_PRODUCT_STAMP + x)
          .then(async response => {
            console.log(response)
            if (response.errorCode === 500) {
              this.setState({
                value_text: "",
                alert: (
                  <SweetAlert
                    error
                    style={{ display: "block" }}
                    title="Không tìm thấy sản phẩm!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                      this.props.classes.button + " " + this.props.classes.success
                    }
                  >
                  </SweetAlert>
                )
              });
            }
            else {
              const tempstamp = (parseInt(x, 10) - parseInt(response.data.data.mappingInfo.startId, 10));
              //console.log("tempsadasd", tempstamp)
              var stamp_0 = (parseInt(response.data.data.mappingInfo.startGtin.substr(13, 23), 10) + tempstamp).toString()
              //console.log(stamp_0);
              const stamp = response.data.data.mappingInfo.startGtin.substr(0, 13) + stamp_0.padStart(10, "0");
              await this.setState({
                stamp: stamp
              })
              //console.log(this.state.stamp)
              var check = false;
              var o = 0;
              for (o = 0; o < this.state.showList.length; o++) {
                // console.log(this.state.showList[o])
                if (this.state.stamp === this.state.showList[o]) {
                  //console.log("Yes")
                  check = true;
                }
              }
              if (check === true) {
                this.removeitem(this.state.stamp)
                this.setState({
                  status: true,
                })
                this.state.buyProducts.push(this.state.stamp);
                var temp = {
                  image: response.data.data.productInfo.image,
                  searchCode: x,
                  productCode: this.state.stamp,
                  productName: "Rượu Đẳng Sâm",
                  quantity: 1,
                  unit: "chai",
                  price: "200$",
                  unitprice: "0%",
                  tax: "0%",
                  total: 200,
                }
                var pricetotal = this.state.totalPrice + temp.total;
                await this.setState({
                  totalPrice: pricetotal
                })
                await this.state.finalList.push(temp);
                this.genTable(this.state.finalList)
                this.setState({
                  value_text: ""
                })
              } else {
                this.setState({
                  value_text: "",
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: "block" }}
                      title="Sản phẩm đã bán hoặc không tồn tại!"
                      onConfirm={() => this.hideAlert()}
                      onCancel={() => this.hideAlert()}
                      confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                      }
                    >
                    </SweetAlert>
                  )
                });
              }
            }

          })
          .catch(error => {
            this.setState({
              value_text: "",
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block" }}
                  title="Không tìm thấy sản phẩm!"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                >
                </SweetAlert>
              )
            });
          })
      }
    }
    setValues = selectValues => this.setState({ selectValues });
    render() {
      const { selectedOption } = this.state;
      const { classes } = this.props;
      return (
        <div>
          <Snackbar
            place="tr"
            color="danger"
            icon={AddAlert}
            message={this.state.message}
            open={this.state.tr}
            closeNotification={() => this.setState({ tl: false })}
            close
          />
          {(this.state.qrStatus) ? <ScanPage onResult={this.handleResult} /> : null}
          <div id="retailtable">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={9}>
                <Card>
                  <CardHeader color="success" icon>
                    <GridContainer>
                      <GridItem lg={6} md={6} sm={6} xs={12}>
                        <h4 className="cardtableLabel">Đơn hàng</h4>
                      </GridItem>
                      <GridItem lg={6} md={6} sm={6} xs={12}>
                        <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px", display: "flex", flexFlow: "row" }}>
                          <div style={{ marginRight: 10 }}>
                            <TextField
                              id="outlined-bare"
                              label=""
                              value={this.state.value_text}
                              className="inputproduts"
                              defaultValue=""
                              placeholder="Mã sản phẩm"
                              margin="normal"
                              autoFocus={true}
                              variant="outlined"
                              inputProps={{ 'aria-label': 'Nhập mã sản phẩm' }}
                              onChange={this.handleChange_textfield('name')}
                            />
                          </div>
                          <div className="qrButtonretail">
                            <Button className={classes.qrButton} color="success">
                              <img
                                src={qrIcon}
                                alt="wonder"
                                className="qrImage"
                                onClick={this.routeScan}
                              />
                            </Button>
                          </div>
                        </GridContainer>
                        <GridItem xs={12} md={12} sm={12} lg={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                          <FormControlLabel
                            classes={{
                              root: classes.checkboxLabelControl,
                              label: classes.checkboxLabel
                            }}
                            control={
                              <Checkbox
                                tabIndex={-1}
                                checked={this.state.checkedState}
                                onClick={() => this.handleToggle(1)}
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                              />
                            }
                            label={
                              <div style={{ textAlign: "justify", marginTop: 10 }}>
                                <p style={{ fontSize: "14px", fontWeight: 700, fontFamily: "Muli", color: "#3C4858", marginTop: "20px" }}>
                                  Tự động bán
                          </p>
                              </div>
                            }
                          />
                        </GridItem>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={this.state.data}
                      filterable
                      defaultPageSize={10}
                      previousText="Trang Trước"
                      nextText="Trang Sau"
                      rowsText="hàng"
                      ofText="của"
                      pageText="Trang"
                      noDataText="Nhấn nút THÊM để thêm sản phẩm đầu tiên vào đơn hàng!"
                      columns={[
                        {
                          Header: "#",
                          accessor: "id",
                          filterable: false,
                          maxWidth: 70,
                          className: "center",
                          Header: () => (
                            <div style={{
                              textAlign: "center"
                            }}>
                              #
                        </div>
                          )
                        },
                        {
                          Header: "Mã sản phẩm",
                          accessor: "searchCode",
                          filterable: false
                        },
                        // {
                        //   Header: "Mã sản phẩm",
                        //   accessor: "productCode",
                        //   filterable: false
                        // },
                        {
                          Header: "Tên sản phẩm",
                          accessor: "productName",
                          filterable: false
                        },
                        {
                          Header: "Hình ảnh",
                          accessor: "image",
                          filterable: false
                        },
                        {
                          Header: "Số lượng",
                          accessor: "quantity",
                          filterable: false
                        },
                        {
                          Header: "Trạng thái",
                          accessor: "statuspay",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: "Hành động",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                        }
                      ]}
                      defaultPageSize={5}
                      // showPaginationBottom={true}
                      sortable={false}
                      resizable={false}
                      className="-striped -highlight"
                      style={{ display: this.state.showTable }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={3}
              // className={classes.retailpageproduct}
              >
                {/* <RetailSideBar price={this.state.totalPrice} onConfirm={this.onPayment} /> */}
                <Card>
                  <CardHeader color="success" icon>
                    <GridContainer>
                      <GridItem lg={8} md={8} sm={8} xs={8}>
                        <h4 className="cardtableLabel">Thanh toán</h4>
                      </GridItem>
                      <GridItem lg={4} md={4} sm={4} xs={4}>
                        <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                          <Tooltip title="In hóa đơn">
                            <Icon style={{ color: "#4caf50", marginRight: 10 }}>print</Icon>
                          </Tooltip>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Select
                        value={selectedOption}
                        onChange={this.handleChange_x}
                        options={options}
                        className={classes.SelectDropx}
                        placeholder="Khách hàng"
                        styles={colourStyles}
                      />
                      <div style={{ marginTop: "8px" }}>
                        <p>Số điện thoại: <a>0342788099</a></p>
                        <p>Địa chỉ: <a>Thủ Đức, TP. Hồ Chí Minh</a></p>
                        <Button color="success" style={{ width: "-webkit-fill-available" }}> <Add style={{ margin: "0px", marginRight: "5px" }} /> <span style={{ fontSize: 13 }}>Thêm khách hàng</span></Button>
                      </div>
                    </div>

                    <hr className="linebreakDash" />
                    <GridContainer>
                      <GridItem lg={12} md={12} sm={12} xs={12}>
                        <p className="tagretail">Tổng số sản phẩm:</p>
                      </GridItem>
                      <GridItem lg={6} md={6} sm={6} xs={6}>
                        <p>{this.state.totalPrice}</p>
                      </GridItem>
                      <GridItem lg={6} md={6} sm={6} xs={6} style={{ textAlign: "end" }}>
                        <p>sản phẩm</p>
                      </GridItem>
                    </GridContainer>
                    {
                      this.state.disabledPay === false ? <Button color="success" onClick={this.onPayment.bind(this)}> Thanh toán</Button> :
                        <Button disabled color="success" onClick={this.onPayment.bind(this)}> Thanh toán</Button>
                    }
                    <GridItem >
                    </GridItem>
                  </CardBody>
                </Card>
                {/* <RetailTable />;  */}
              </GridItem>
            </GridContainer>
          </div>
          {this.state.alert}
        </div>
      );

      // return <div />;
    }
  }
  const mapStateToProps = (state) => {
    return {
      userInfo: state.userInfo,
      unPaidList: state.unPaidList
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      getlist: () => dispatch({ type: "GET_UNPAID_LIST" }),
    }
  }
  // export default RetailPage;
  export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(RetailPage)));
