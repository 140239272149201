/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from "components/CustomInput/CustomInput.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx"
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import FormControl from "@material-ui/core/FormControl";
// core components
import Badge from "components/Badge/Badge.jsx";
import ImageActions from "./ActionsImage"
import timelineStyle from "./timelineStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Check from "@material-ui/icons/Check";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import IconUpload from "./IconUpload.jsx"
import PortalUpload from "./PortalUpload.jsx"
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RadioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/Notifications";
import {ROLE, MANAGE_ROLE, MAPPING_STAFF_MANAGER} from "./constants.jsx";
import strings from "../../../../../constants/strings";


const styles = makeStyles({
  ...timelineStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...RadioStyles
})
const delay = (ms) => new Promise(res => setTimeout(res, ms))
class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      stories: [],
      authority: [],
      SM: true,
      SP: false,
      processStep: [],
      isHarvest: "",
      idCheck: "",
      activeNoti: false,
      messageNoti: "",
      inputType: [
        {
          title: "Dạng chữ",
          value: ""
        },
        {
          title: "Dạng số",
          value: "numeric"
        },
        {
          title: "Tùy chọn",
          value: ""
        }
      ]
    }
    this.role = this.props.userInfo.data.type;
    this.handleAddOverviews = this.handleAddOverviews.bind(this);
    this.handle_change_story = this.handle_change_story.bind(this);
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.handle_overview_label = this.handle_overview_label.bind(this);
    this.handleToggle_more = this.handleToggle_more.bind(this);
    this.handle_overview_description = this.handle_overview_description.bind(this)
    this.handleToggle_default = this.handleToggle_default.bind(this);
    this.handle_overview_default = this.handle_overview_default.bind(this);
    this.handleRemoveInput = this.handleRemoveInput.bind(this);
    this.handleAddStories = this.handleAddStories.bind(this);
    this.onImageSubmitPortal = this.onImageSubmitPortal.bind(this);
    this.onImageSubmitMobile = this.onImageSubmitMobile.bind(this)
    this.handleFinal = this.handleFinal.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCreateProcess = this.handleCreateProcess.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.handleCheckedPublic = this.handleCheckedPublic.bind(this);
  }
  componentDidMount() {
    this.setState({
      stories: this.props.stories
    })
    if (this.props.setClick) {
      this.props.setClick(this.handleAddStories)
    }
    if (this.props.finalClick) {
      this.props.finalClick(this.handleFinal)
    }
  }
  componentWillReceiveProps(preProps) {
    if (preProps.stories !== this.props.stories) {
      this.setState({
        stories: preProps.stories
      })
    }
  }
  async showNotiValidated(message){
    await this.setState(
     {
      activeNoti: true,
      messageNoti: message
     }
    )
    await delay(3000)
    this.setState(
      {
       activeNoti: false,
       messageNoti: ""
      }
     )
  }
  async handleSelectHarvest(event){
    await this.setState({
      isHarvest: event.target.value,
      idCheck: event.target.value.idx
    })
  }
  isValidated(){
    for(let i=0;i<this.state.stories.length;i++){
        if(!this.state.stories[i].name || !this.state.stories[i].description  || !this.state.stories[i].mobileIcon){
        this.showNotiValidated("Vui lòng điền đủ thông tin tại bước số: #" + i +" (Tên bước, mô tả và icon)")
        return false;
    }
  }
    return true;
}
  async handleFinal(temp) {
    const { classes } = this.props;
    const check = this.isValidated();
    if(check){
      let list = this.state.stories;
      let listSteps = []
      for (let i = 0; i < list.length; i++) {
        listSteps.push({
          name: list[i].name,
          idx: i,
        })
      }
      this.setState({
        openCreateFarm: true,
        processStep: listSteps,
        mainData: temp
      })
    }
  }
  async handleCreateProcess(){
    this.setState({
      openCreateFarm: false
    })
    const newStories = await this.state.stories.map((prop, key) => {
      if(key !== this.state.idCheck){
        return {
          ...prop,
          actions_image_src: [],
          iconSrc: "",
          mobileSrc: ""
        }
      }
     
      if(key=== this.state.idCheck){
        let tempList = prop.listElement;
        tempList.push(	{
          "label": "Sản lượng:",
          "placeholder": "",
          "type": "InputBox",
          "required": "false",
          "typeOfValue": "numeric"
        })
        return{
          ...prop,
          actions_image_src: [],
          iconSrc: "",
          mobileSrc: "",
          isHarvest: true,
          listElement: tempList
        }
      }
    })
    const data = {
      "process_name": this.state.mainData.process_name,
      "role": this.state.mainData.role,
      "type": this.state.mainData.type,
      "specialStep": this.state.mainData.specialStep,
      "packed": this.state.mainData.packed,
      "step": newStories.reverse()
    }
    this.props.createProcess(data);
    this.props.history.push("/admin/process/list")
  }
  handleClose() {
    this.setState({
      openCreateFarm: false
    })
  }
  async handleAddStories() {
    const temp = this.state.stories;
    await temp.unshift({
      inverted: true,
      badgeColor: "success",
      badgeIcon: <img src={require("../../../../../assets/icons/" + "sun.svg")} style={{ width: "32px" }}></img>,
      name: "",
      icon: "",
      iconSrc: "",
      mobileIcon: "",
      mobileSrc: "",
      publicName: "",
      attribute: "",
      isHarvest: false,
      isPublic: true,
      description: "",
      authority: ['SM', 'SP'],
      actions_image: [],
      actions_image_src: [],
      checked: [],
      checked_default: [],
      checked_more: [],
      listElement: []
    })
    await this.setState({
      stories: temp
    })
  }
  async handleRemoveInput(idx, idxx) {
    const newOverviews = await this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const final = overview.listElement.filter((s, sidx) => idxx !== sidx)
      return { ...overview, listElement: final }
    })
    await this.setState({
      stories: []
    })
    await this.setState({
      stories: newOverviews
    })
  }
  handleAddOverviews = async (idx) => {
    const temp = await this.state.stories.map((story, sidx) => {
      if (idx !== sidx) return story;
      let x = story.listElement;
      x.unshift({
        key: "", value: "",
        type: "InputBox",
        typeOfValue: "",
        label: "",
        required: "false",
        placeholder: "",
        defaultValue: "",
        checked_more: [],
        checked_default: [],
        isPublic: false,
      })
      return {
        ...story,
        listElement: x
      }
    })
    this.setState({
      stories: temp
    })
  }
  hideAlert() {
    this.setState({
      alert: null
    })
  }
  onImageSubmit = async (idx, file, src) => {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, actions_image: file, actions_image_src: src }
    })
    this.setState({
      stories: newOverviews
    })
  }
  handleCheckedPublic = idx => evt => {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, isPublic: evt.target.checked }
    })
    this.setState({
      stories: newOverviews
    })
  }
  async handleToggle_more(value, idx, idxx) {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const newData = overview.listElement.map((prop, sidxx) => {
        if (idxx !== sidxx) return prop;
        const checked_more = prop.checked_more;
        const currentIndex = checked_more.indexOf(value);
        const newChecked = [...checked_more];
        let bool = false;
        if (currentIndex === -1) {
          newChecked.push(value);
          bool = true;
        } else {
          newChecked.splice(currentIndex, 1);
          bool = false;
          // document.getElementById("default_value" + idx).style = "display: none; width: 100%"
        }
        return {
          ...prop,
          checked_more: newChecked, isPublic: bool
        }
      })
      return {
        ...overview,
        listElement: newData
      }
    })
    this.setState({
      stories: newOverviews
    })
  }
  async handleToggle_default(value, idx, idxx) {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const newData = overview.listElement.map((prop, sidxx) => {
        if (idxx !== sidxx) return prop;
        const checked_default = prop.checked_default;
        const currentIndex = checked_default.indexOf(value);
        const newChecked = [...checked_default];
        if (currentIndex === -1) {
          newChecked.push(value);
          document.getElementById("default_value" + idxx).style = "display: block; width: 100%"
        } else {
          newChecked.splice(currentIndex, 1);
          document.getElementById("default_value" + idxx).style = "display: none; width: 100%"
        }
        return {
          ...prop,
          checked_default: newChecked
        }
      })
      return {
        ...overview,
        listElement: newData
      }
    })
    this.setState({
      stories: newOverviews
    })
  }
  handle_change_story = (idx, name) => evt => {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      if( name === "SP" || name === "SM") {
        let NV = name;
        let authority = [];
        if(overview.authority) authority = overview.authority;
        
        if(evt.target.checked === true) {
          authority.push(name);
          NV =  true;
        }
        else{
          NV = false;
          let index = authority.indexOf(name)
          if (index!= -1) {
            authority.splice(index,1);
          }
          
        }
        return {...overview, authority:authority, [name]: NV}
      }
      return { ...overview, [name]: evt.target.value }
    })
    this.setState({
      stories: newOverviews
    })

    // console.log(this.stories)
  }
  handle_overview_default = (idx, idxx) => evt => {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const newData = overview.listElement.map((prop, sidxx) => {
        if (idxx !== sidxx) return prop;
        return {
          ...prop,
          defaultValue: evt.target.value
        }
      })
      return {
        ...overview,
        listElement: newData
      }
    })
    this.setState({
      stories: newOverviews
    })
  }
  handle_overview_label = (idx, idxx) => evt => {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const newData = overview.listElement.map((prop, sidxx) => {
        if (idxx !== sidxx) return prop;
        return {
          ...prop,
          label: evt.target.value
        }
      })
      return {
        ...overview,
        listElement: newData
      }
    })
    this.setState({
      stories: newOverviews
    })
  }
  async onImageSubmitPortal(idx, file, src) {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, icon: file, iconSrc: src }
    })
    this.setState({
      stories: newOverviews
    })
  }
  async onImageSubmitMobile(idx, file, src) {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, mobileIcon: file, mobileSrc: src,  icon: file + ".svg", iconSrc: src  }
    })
    this.setState({
      stories: newOverviews
    })
  }
  handle_overview_description = (idx, idxx) => evt => {
    const newOverviews = this.state.stories.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const newData = overview.listElement.map((prop, sidxx) => {
        if (idxx !== sidxx) return prop;
        return {
          ...prop,
          value: evt.target.value, typeOfValue: evt.target.value.value
        }
      })
      return {
        ...overview,
        listElement: newData
      }
    })
    this.setState({
      stories: newOverviews
    })
  }
  render() {
    const { simple, classes } = this.props;
    const timelineClass =
      classes.timeline +
      " " +
      cx({
        [classes.timelineSimple]: simple
      });
    return (
      <ul className={timelineClass}>
         <Snackbar
                        place="br"
                        color="danger"
                        icon={AddAlert}
                        message={this.state.messageNoti}
                        open={this.state.activeNoti}
                        closeNotification={() => this.setState({ activeNoti : false })}
                        close
                      />
        {this.state.stories.map((prop, idx) => {
           if(!prop.SP) prop.SP = false;
           if(!prop.SM) prop.SM = false;
          const panelClasses =
            classes.timelinePanel +
            " " +
            cx({
              [classes.timelinePanelInverted]: prop.inverted || simple,
              [classes.timelineSimplePanel]: simple
            });
          const timelineBadgeClasses =
            classes.timelineBadge +
            " " +
            classes[prop.badgeColor] +
            " " +
            cx({
              [classes.timelineSimpleBadge]: simple
            });
          return (
            <li className={classes.item} key={idx}>

              <div className={timelineBadgeClasses}>
                <IconUpload
                  src={prop.mobileSrc}
                  icon={true}
                  portal={false}
                  onImageSubmit={this.onImageSubmitMobile.bind(this)}
                  idx={idx}
                />
              </div>

              <div className={panelClasses}>
                {/* {prop.nametitle ? (
                <div>
                    {prop.nametitle}
                </div>
              ) : null}
              {prop.title ? (
                <div style={{marginTop: "-35px"}}>
                    {prop.title}
                </div>
              ) : null}  */}
                <div className={classes.timelineBody}>
                  <p className="TitleBold">{strings.nameStep}</p>
                  <div style={{ marginTop: "-40px" }}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                        onChange: this.handle_change_story(idx, "name"),
                      }}
                      inputProps={{
                        value: prop.name,
                        autoComplete: "off",
                        placeholder: strings.nameStep +" #" + idx
                      }}
                    />
                  </div>
                  <p className="TitleBold">{strings.description}</p>
                  <div style={{ marginTop: "-40px" }}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                        onChange: this.handle_change_story(idx, "description"),
                      }}
                      inputProps={{
                        value: prop.description,
                        autoComplete: "off",
                        placeholder: strings.description + " #" + idx
                      }}
                    />
                  </div>
                  <div style={{marginTop: "-20px"}}>
                        <div
                          className={
                            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                               // onClick={() => this.handle_change_story(idx, "SM")}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                                //value={prop.SM}
                                checked={prop.isPublic}
                               onChange={this.handleCheckedPublic(idx)}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label= {strings.isPublic}
                          />
                        </div>
                      </div>
                  {/* <p className="TitleBold" style={{marginBottom: "0px !important"}}>Nhân viên thực hiện</p>
                  <div style={{marginTop: "-20px"}}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <div
                          className={
                            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.handle_change_story(idx, "SM")}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                                value={prop.SM}
                                checked={prop.SM}
                                onChange={this.handle_change_story(idx, "SM")}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Nhân viên nuôi trồng"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <div
                          className={
                            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.handle_change_story(idx, "SP")}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                                value={prop.SP}
                                checked={prop.SP}
                                onChange={this.handle_change_story(idx, "SP")}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Nhân viên chế biến"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  </div> */}
                  <p className="TitleBold">{strings.showPicture}</p>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <ImageActions
                      onImageSubmit={this.onImageSubmit}
                      idx={idx}
                      icon={false}
                      portal={false}
                      src={prop.actions_image_src}
                    />
                  </GridItem>
                  <GridItem xs= {12}>
                  <Button color="success" size="sm"
                  onClick={() => this.handleAddOverviews(idx)}
                >{strings.addFields}</Button>
                  </GridItem>
                  {/* <p className="TitleBold">Icon hiển thị trên trang web truy xuất nguồn gốc</p>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <PortalUpload
                      icon={false}
                      src={prop.iconSrc}
                      portal={true}
                      idx={idx}
                      onImageSubmit={this.onImageSubmitPortal.bind(this)}
                    />
                  </GridItem>
                  </GridItem> */}
                  {prop.listElement ? prop.listElement.map((overview, idxx) => (
                    <GridContainer key={idxx} style={{marginTop: "10px"}}>
                      <GridItem xs={3} md={3} sm={3} lg={3}>
                        <p className="TitleBold" >{strings.fieldName}</p>
                        <div style={{ marginTop: "-35px" }}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                              onChange: this.handle_overview_label(idx, idxx),
                            }}
                            inputProps={{
                              value: overview.label,
                              autoComplete: "off",
                              placeholder: strings.fieldName + " #" + idxx
                            }}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={4} md={4} sm={4} lg={4}>
                        <p className="TitleBold">{strings.typeData}</p>
                        <FormControl
                          style={{ marginTop: "-16px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            id='select'
                            classes={{
                              select: classes.select
                            }}
                            value={overview.value}
                            onChange={this.handle_overview_description(idx, idxx)}
                            inputProps={{
                              name: "type",
                              id: "type",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                             {strings.chooseData}
                        </MenuItem>
                            {this.state.inputType.map((x, idxx) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={x}
                                key={idxx}
                              >
                                {x.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={4} md={4} sm={4} lg={4}>
                      <p className="TitleBold">{strings.defaultValue}</p>
                          <div style={{ marginTop: "-35px" }}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handle_overview_default(idx, idxx),
                              }}
                              inputProps={{
                                value: overview.defaultValue,
                                autoComplete: "off",
                                placeholder: strings.defaultValue + " #" + idxx
                              }}
                            />
                          </div>
                      </GridItem>
                      <GridItem xs={1} md={1} sm={1} lg={1} className={classes.fixCardRemove}>
                        <div style={{marginTop: "19px"}}>
                        <Button color="danger" size="sm"
                          onClick={() => this.handleRemoveInput(idx, idxx)}
                        >X</Button>
                        </div>
                       
                      </GridItem>
                      <GridItem xs={12}>
                      <div style={{ width: "100%" }}>
                          <FormControlLabel
                            classes={{
                              root: classes.checkboxLabelControl,
                              label: classes.checkboxLabel
                            }}
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.handleToggle_more(1, idx, idxx)}
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={strings.isPublic_sub}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  )) : null}
                </div>
                {prop.footerTitle ? (
                  <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
                ) : null}
                {prop.footer ? <hr className={classes.footerLine} /> : null}
                {prop.footer ? (
                  <div className={classes.timelineFooter}>{prop.footer}</div>
                ) : null}
              </div>
            </li>
          );
        })}
        <Dialog
          open={this.state.openCreateFarm}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <Card style={{ width: 500 }}>
            <CardHeader color="success" icon>
              <h4 className="cardtableLabelProcess">Bạn thu hoạch tại bước nào?</h4>
            </CardHeader>
            <CardBody>
              <FormControl
                style={{ marginTop: "-20px" }}
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="product-expired-type"
                  className={classes.selectLabel}
                >
                  Chọn bước thu hoạch
                              </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  id='select'
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.isHarvest}
                  onChange={this.handleSelectHarvest.bind(this)}
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}
                >
                  {this.state.processStep.map((x, idx) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={x}
                      key={idx}
                    >
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardBody>
            <GridItem xs={1}>
              <Button color="success" size="sm" onClick={this.handleCreateProcess}>Xác nhận</Button>
            </GridItem>
          </Card>
        </Dialog>
      </ul>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFarms: () => dispatch({ type: "GET_EMPLOYEE_LIST" }),
    createProcess: (data) => dispatch({ type: "CREATE_PROCESS", data: data })
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    employeeList: state.employeeList
  }
}
export default withStyles(timelineStyle)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Timeline)));
