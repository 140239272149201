import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class InputImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: this.props.value ? this.props.value : this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidUpdate(preProps){
    if (preProps.value !== this.props.value && this.props.value){
      this.setState({
        imagePreviewUrl: this.props.value,
        file: ' ',
      })
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      this.props.onImageSubmit(this.state.imagePreviewUrl);
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
    this.props.onImageSubmit('');
  }
  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div style={{display: 'flex'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={{margin: 0}}>{this.state.file.name || 'Hình ảnh của bước này...'}</p>
            </div>
          {this.state.file === '' ? (
            <Button size="sm" {...addButtonProps} onClick={() => this.handleClick()}>
              {avatar ? "Add Photo" : "Chọn Hình Ảnh"}
            </Button>
          ) : (
            <span>
              <Button size="sm"  {...changeButtonProps} onClick={() => this.handleClick()}>
                Chọn Lại
              </Button>
              {avatar ? <br /> : null}
              {/* <Button
                round={false}
                size="sm"
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <RemoveCircle/> Xóa
              </Button> */}
            </span>
          )}
        </div>
      </div>
    );
  }
}

InputImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default InputImageUpload;
