import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
// import Gallery from 'react-grid-gallery';
// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem";
import { LINK_API } from 'constants/API';
import Gallery from '../../components/Forms/Gallery/Gallery';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Search from "@material-ui/icons/Edit";
import Truncate from 'react-truncate';
import "../css/CustomeReactTable.css";
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import profileagd from "assets/img/agdavatar.gif"
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { IMG_LOAD } from 'constants/index.js';
import strings from "../../../../constants/strings";
const styles = {
  //...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...customSelectStyle,
  tooltip: {
    padding: "10px 15px",
    minWidth: "130px",
    color: "#FFFFFF",
    lineHeight: "1.7em",
    background: "rgba(85,85,85,0.9)",
    border: "none",
    borderRadius: "3px",
    opacity: "1!important",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: "200px",
    textAlign: "center",
    fontFamily: 'Muli',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      farmList: [],
      data: [],
      alert: null,
      load: "",
      showTable: "none",
      activeState: true,
      openEdit: false,

      nameEdit: "",
      descriptionEdit: "",
      uuid: "",
      unitEdit: "",
      statusEdit: "",

      unit: ["Kg", "Sản phẩm"],
      status: ["Sản xuất", "Ngưng sản xuất"],
      unitTemp: "",

      uuidEdit: ""

    };
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleUpdateProduct = this.handleUpdateProduct.bind(this)
  }
  handleSelect(evt) {
    if (evt.target.value === "Sản phẩm") {
      this.setState({
        unitTemp: "Sản phẩm",
        unitEdit: 2,
      })
    }
    if (evt.target.value === "Kg") {
      this.setState({
        unitTemp: "Kg",
        unitEdit: 1,
      })
    }
    if (evt.target.value === "Sản xuất") {
      this.setState({
        statusEdit: 1,
        statusTemp: "Sản xuất"
      })
    }
    if (evt.target.value === "Ngưng sản xuất") {
      this.setState({
        statusEdit: 0,
        statusTemp: "Ngưng sản xuất"
      })
    }
  }
  handleUpdateProduct() {
    const data = {
      uuid: this.state.uuidEdit,
      name: {
        vi: this.state.nameEdit,
      },
      description: {
        vi: this.state.descriptionEdit,
      },
      status: this.state.statusEdit,
      unit: this.state.unitEdit,
    }
    this.setState({
      openEdit: false,
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });
    axios.post(LINK_API.UPDATE_PRODUCT, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          this.props.getProductTypeList();
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-200px" }}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Đăng xuất"
                cancelBtnText="Đóng"
                showCancel
                showConfirm={false}
              ></SweetAlert>
            )
          });
        }
      })
      .catch(() => {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-200px" }}
              title="Không thành công!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Đăng nhập"
              cancelBtnText="Đóng"
              showConfirm={false}
              showCancel
            ></SweetAlert>
          )
        });
      })
  }
  hideAlert() {
    this.setState({
      alert: null
    })
  }
  handleEdit(uuid) {
    // this.setState({
    //   openEdit: true,
    //   nameEdit: name,
    //   descriptionEdit: description,
    //   statusEdit: status,
    //   unitEdit: unit,
    //   unitTemp: unit === 1 ? "Kg" : "Sản phẩm",
    //   uuidEdit: uuid,
    //   statusTemp: status == 1 ? "Sản xuất" : "Ngừng sản xuất"
    // })
    cookie.save("uuidProducts", uuid, { path: "/" });
    this.props.history.push("/admin/products/edit")
  }
  handleClose() {
    this.setState({
      openEdit: false
    })
  }
  handleChange = name => evt => {
    this.setState({
      [name]: evt.target.value
    })
  }
  genTable = async (farmList) => {
    this.props.handleDataExport(farmList);
    await farmList.reverse();
    this.setState({
      data: farmList.map((prop) => {
        const CERTIFICATES_LIST = [];
        if (prop.certificates.length !== 0) {
          for (var i = 0; i < prop.certificates.length; i++) {
            CERTIFICATES_LIST.push({
              src: prop.certificates[i].secure_url,
              thumbnail: prop.certificates[i].secure_url,
              caption: prop.name
            })
          }
        }
        return {
          id: prop.uuid,
          name:
            <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
              <GridItem xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                {(prop.image && prop.image.length !== 0) ? (
                  <Gallery
                    images={[{
                      src: prop.image[0].secure_url, thumbnail: prop.image[0].secure_url,
                      caption: ""
                    }]}
                    rowHeight={45}
                    enableLightbox={true}
                    enableImageSelection={false}
                  />
                ) : <img src={profileagd} style={{ height: "37px", width: "37px" }} />}
              </GridItem>
              <GridItem xs={12} sm={12} md={7} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>     
                  <Tooltip
                  id="tooltip-top"
                  title={prop.name}
                  placement="top"
                  classes={{ tooltip: this.props.classes.tooltip }}>
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                  {prop.name}
                  </Truncate>
                </Tooltip>
              </GridItem>
            </div>,
          status: prop.status === 1 ? strings.inProduction : prop.status === 0 ? strings.stopProduction : "",
          certificates: <Gallery images={CERTIFICATES_LIST} rowHeight={50} enableLightbox={true} enableImageSelection={false} />,
          gtin: prop.gtin ? prop.gtin : strings.none,
          description:
            <Tooltip
              id="tooltip-top"
              title={prop.description}
              placement="top"
              classes={{ tooltip: this.props.classes.tooltip }}>
              <Truncate lines={2} ellipsis={<span>...</span>}>
                {prop.description}
              </Truncate>
            </Tooltip>,
          actions: <div>

            <Button
              justIcon
              round
              //title="Xem chi tiết"
              simple
              onClick={() => this.handleEdit(prop.uuid)}
              color="warning"
              className="like"
            >
              <Tooltip
                id="tooltip-search"
                title= {strings.edit}
                placement="top"
                disableHoverListener={false}
                classes={{ tooltip: this.props.classes.tooltip }}>
                <Search />
              </Tooltip>
            </Button>

            {/* <Tooltip
          id="tooltip-x"
          title="Ngừng sản xuất"
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}>
            <Button
              justIcon
              round
              //title="Ngừng sản xuất"
              simple
              onClick={() => {
                console.log("")
              }}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>
              </Tooltip> */}
          </div>
        };
      }),
      showTable: ""
    });
    this.props.onupdate()
  }

  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      return this.props.history.push("/");
    }
  }


  async componentDidMount() {
    try {
      await this.checkPermission();
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }
      await this.props.getProductTypeList();
    }
    catch (error) {
      console.log(error)
    }
  }
  async componentWillReceiveProps(preProps) {
    if (this.props.productList !== preProps.productList) {
      await this.setState({ farmList: preProps.productList, showTable: "none" });
      if (preProps.productList) {
        await this.genTable(Array.from([]))
        await this.genTable(Array.from(preProps.productList))
      }
    }
  }




  render() {
    const { classes } = this.props;
    return (
      <div>
        <ReactTable
          data={this.state.data}
          filterable
          // defaultPageSize={5}
          previousText= {strings.prevPage}
          nextText= {strings.nextPage}
          rowsText= {strings.row}
          ofText="/"
          pageText= {strings.page}
          noDataText= {strings.noData}
          columns={[
            {
              Header: strings.productId,
              accessor: "id",
              filterable: false,
              className: "center",
              maxWidth: 150,
              Header: () => (
                <div style={{
                  textAlign: "center"
                }}>
                   {strings.productId}
                        </div>
              )
            },
            {
              Header:  strings.nameProductType,
              minWidth: 107,
              accessor: "name",
              filterable: false
            },
            {
              Header: strings.certificate,
              accessor: "certificates",
              filterable: false,
              className: "center",
            },
            {
              Header:  strings.gtin,
              accessor: "gtin",
              filterable: false,
              resizable: true,
            },
            {
              Header: strings.description,
              accessor: "description",
              filterable: false,
              resizable: true,
            },
            {
              Header: strings.status,
              accessor: "status",
              filterable: false
            },
            {
              Header: strings.actionHeader,
              accessor: "actions",
              filterable: false
            },
            // {
            //   Header: "Thông tin Blockchain",
            //   accessor: "blockchain",
            //   filterable: false
            // }
          ]}
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          className="-striped -highlight"
          style={{ display: this.state.showTable }}
        />
        <Dialog
          open={this.state.openEdit}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <Card style={{ width: 500 }}>
            <CardHeader color="success" icon>
              <h4 className="cardEditProduct">CẬP NHẬT THÔNG TIN</h4>
            </CardHeader>
            <CardBody>
              <div style={{ marginTop: "-20px" }}>
                <CustomInput
                  labelText="Tên loại sản phẩm (*)"
                  success={this.state.nameState === "success"}
                  error={this.state.nameState === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    onChange: this.handleChange('nameEdit'),
                  }}
                  inputProps={{
                    value: this.state.nameEdit,
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Icon className={classes.inputAdornmentIcon}>
                          home
                              </Icon>
                      </InputAdornment>
                    ),
                    //placeholder: "Tên loại sản phẩm"
                  }}
                />
              </div>
              <div style={{ marginTop: "-10px" }}>
                <CustomInput
                  labelText="Mô tả (*)"
                  success={this.state.descriptionState === "success"}
                  error={this.state.descriptionState === "error"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    onChange: this.handleChange('descriptionEdit'),
                  }}
                  inputProps={{
                    multiline: true,
                    // rowsMax: "3",
                    // rows: "3",
                    value: this.state.descriptionEdit,
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Icon className={classes.inputAdornmentIcon}>
                          list
                              </Icon>
                      </InputAdornment>
                    ),
                    // placeholder: "Mô tả"
                  }}
                />
              </div>
              <div style={{ marginTop: "-20px", width: "100%" }}>
                <FormControl

                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="product-expired-type"
                    className={classes.selectLabel}
                  >
                    Đơn vị
                              </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    id='select'
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.unitTemp}
                    onChange={this.handleSelect.bind(this)}
                    inputProps={{
                      name: "type",
                      id: "type",
                    }}
                  >
                    {this.state.unit.map((x, idx) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={x}
                        key={idx}
                      >
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginTop: "-15px", width: "100%" }}>
                <FormControl

                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="product-expired-type"
                    className={classes.selectLabel}
                  >
                    Trạng thái
                              </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    id='select'
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.statusTemp}
                    onChange={this.handleSelect.bind(this)}
                    inputProps={{
                      name: "type",
                      id: "type",
                    }}
                  >
                    {this.state.status.map((x, idx) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={x}
                        key={idx}
                      >
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </CardBody>
            <GridItem xs={1} style={{ marginBottom: "20px" }}>
              <Button color="success" size="sm" onClick={this.handleUpdateProduct}>{strings.confirm}</Button>
            </GridItem>
          </Card>
        </Dialog>
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getProductTypeList: () => dispatch({ type: "GET_PRODUCT_LIST" }),
  }
}
const mapStateToProps = (state) => {
  return {
    productList: state.productType.data,
    userInfo: state.userInfo
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
