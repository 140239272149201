import React from "react";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";
import { withRouter } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AGRHEADER from "assets/img/bg-img/logo_original.png"
import VBC from "assets/img/bg-img/vbcblack.png"
import strings from "constants/strings";
import cookie from 'react-cookies';

import VN from "assets/img/flags/VN.png";
import EN from "assets/img/flags/GB.png";
import HeaderLookupStyle from "./HeaderLookupStyle.jsx";

const language_img = {
  "vi": VN,
  "en": EN
}


class HeaderLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openDialog: false,
      gln: null,
      language: "vi",
      openLanguageMenu: false,
      anchorEl: null,
    };
    this.routeChange = this.routeChange.bind(this);
    this.routeScan = this.routeScan.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleLanguageItemClick = this.handleLanguageItemClick.bind(this);
    this.handleLanguageMenu = this.handleLanguageMenu.bind(this);
    this.handleLanguageMenuClose = this.handleLanguageMenuClose.bind(this);
  }

  componentDidMount() {
    const lan = cookie.load("language");
    if (lan) {
      // strings.setLanguage(lan);
      this.setState({ language: lan })
    }
    else {
      this.setState({ language: strings.getLanguage() })
    }
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleLanguageMenu = event => {
    this.setState({ openLanguageMenu: !this.state.openLanguageMenu, anchorEl: event.currentTarget });
  };

  handleLanguageMenuClose = () => {
    this.setState({ openLanguageMenu: false });
  };

  handleLanguageItemClick = async (event, index) => {
    this.setState({ language: index });
    this.setState({ openLanguageMenu: false });
    cookie.save('language', index, { path: '/' });
    window.location.reload();
  };

  onChange(e) {
    this.setState({
      gln: e.target.value
    })
  }

  routeChange() {
    if (!this.state.gln) return;
    let path = '/lookup/' + this.state.gln;
    this.props.history.push(path);
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  routeScan() {
    let path = '/scan';
    this.props.history.push(path);
  }
  render() {
    const { classes } = this.props;

    strings.setLanguage(this.state.language);

    return (
      <div>
        <GridContainer xs={12} sm={12} md={12} className="HeaderPage">
        
            <img className="logoVBCHeader" alt="VBC" src={VBC} />
         
            <img className="logoAgriHeader" alt = "AGD"src={AGRHEADER} />
         
       
            <Button className={classes.languageButton}>
              <img
                src={language_img[this.state.language]}
                height="24"
                alt="Language"
                className="languageImg"
                onClick={this.handleLanguageMenu}
              />
            </Button>
          
        </GridContainer>
        {/* <div className="headerBar">
        <GridItem xs={12} sm={12} md={8} style={{display:"flex", padding:"0px 0px !important"}}>
          <div className="inputForm">
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search
            }}
            inputProps={{
              placeholder: strings.input_lookup_code,
              disableUnderline: true,
              inputProps: {
                "aria-label": strings.input_lookup_code,
                className: classes.searchInput,
              },
              classes:{
                input: classes.placeholdersearch,
                underline: classes.underlinesearch,
              },
              onChange: this.onChange
            }}
          />
          </div>
          <div className="qrButton">
          <Button className={classes.qrButton}>
            <img
              src={qrIcon}
              alt="wonder"
              className="qrImage"
              onClick={this.routeScan}
            />
          </Button>
          </div> 
          <div className="searchButton">
          <Button
            color="transparent"
            onClick={this.routeChange}
            className="searchButtonInside"
            // round
          >
            Tra cứu
          </Button>
          </div> 
        </GridItem>
        {renderLanguageMenu}
        </div> */}
        {/* <div className={classes.headerBg}>
          <Button
            color="success"
            aria-label="edit"
            justIcon
            className={classes.headerButton}
            onClick={this.handleOpenDialog}
          >
            <Message
              className={classes.headerLinksSvg + " " + classes.searchIcon}
            />
          </Button>
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search
            }}
            inputProps={{
              placeholder: strings.input_lookup_code,
              inputProps: {
                "aria-label": strings.input_lookup_code,
                className: classes.searchInput,
                underline: null
              },
              onChange: this.onChange
            }}
          />
          <Button className={classes.qrButton}>
            <img
              src={qrIcon}
              alt="wonder"
              height="100%"
              width="100%"
              className={classes.qrImg}
              onClick={this.routeScan}
              style={{
                color:"#ffffff"
            }}
            />
          </Button>
          <Button className="languageButton">
            <img
              src={language_img[this.state.language]}
              height="24"
              className={classes.languageImg}
              onClick={this.handleLanguageMenu}
            />
          </Button>
          <Button
            color="success"
            aria-label="edit"
            justIcon
            onClick={this.routeChange}
            // round
            className={classes.searchButton}
          >



            <Search
              className={classes.headerLinksSvg + " " + classes.searchIcon}
            />
          </Button>
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              classes: {
                root: classes.paper
              }
            }}
          >
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Feedback />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{strings.product_feedback}</h4>
              </CardHeader>
              <CardBody style={{ textAlign: "center" }}>
                <h6 className={classes.textField}> {strings.product_feedback_description}</h6>
                <TextField
                  id="standard-multiline-flexible"
                  label={strings.product_feedback_content}
                  multiline
                  rowsMax="4"
                  value={this.state.multiline}
                  onChange={this.handleChange('multiline')}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                />
                <Button color="success" onClick={this.handleClose}>{strings.send}</Button>
              </CardBody>
            </Card>
          </Dialog>
          {renderLanguageMenu}
        </div> */}
      </div>
    );
  }
}

HeaderLookup.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(HeaderLookupStyle)(withRouter(HeaderLookup));
