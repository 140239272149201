import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import Timeline from "components/Timeline/Timeline.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { connect } from 'react-redux';
import { stories } from "variables/general.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import cookie from 'react-cookies';
import CardHeader from "components/Card/CardHeader.jsx";
import moment from "moment";
import Accordion from 'components/Accordion/Accordion.jsx';
import GalleryModal from "layouts/DashBoard/components/GridImage/index.jsx"
import "assets/scss/material-dashboard-pro-react/gridImage.scss"
import inputnotqr from 'assets/img/inputnotqr.png'
import axios from 'axios';
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from 'constants/API';
import strings from "../../../../constants/strings";
const styles = {

}
const imgUrls = [
  "https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/b11nam_fow1bg.jpg",
  "https://res.cloudinary.com/agridential/image/upload/v1569300548/Portal/b3xalach_m64wcv.png",
  "https://res.cloudinary.com/agridential/image/upload/v1569299812/Portal/thuhoach_eopjd2.png"
];
class TimelinePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bizProcess: [],
      showTimeLine: [],
      listProcess: [],
      isOpen: false,
      currentIndex: null
    }
    this.idOfProcess = cookie.load("idProcess");
    this.handleTimeLine = this.handleTimeLine.bind(this)
    this.handleData = this.handleData.bind(this)
    this.closeModal = this.closeModal.bind(this);
    this.findNext = this.findNext.bind(this);
    this.findPrev = this.findPrev.bind(this);
    this.renderImageContent = this.renderImageContent.bind(this);
  }
  async componentDidMount() {
   // await this.getInfoProccess();
    //this.handleData(this.state.listProcess)
    if(this.props.dataTimeLine){
        this.handleData(this.props.dataTimeLine)
    }
  }
  componentWillReceiveProps(PreProps){
      if(PreProps.dataTimeLine !== this.props.dataTimeLine){
          if(PreProps.dataTimeLine){
            this.handleData(PreProps.dataTimeLine) 
          }
      }
  }
  async handleData(listarray) {
      console.log(listarray)
    //await listarray.reverse();
    // await this.setState({
    //   bizProcess: listarray.step,
    //   name: listarray.process_name,
    //   date: moment(listarray.createdAt).format("DD/MM/YY"),
    //   countStep: listarray.step.length + " bước",
    // })
    this.handleTimeLine(listarray)
  }

  async getInfoProccess() {
    await axios.get(LINK_API.GET_INFO_PROCESS + this.idOfProcess)
      .then(async res => {
        if (res.data.errorCode === 1) {
          await this.setState({
            listProcess: res.data.data
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  //Grid function
  renderImageContent(src, index) {
    //console.log("Src",src)
    return (
      <div onClick={(e) => this.openModal(src, index)}>
        <img src={src} key={src} />
      </div>
    )
  }
  openModal(e, index) {
    this.setState({ currentIndex: index, currentSource: e, isOpen: true });
  }
  async closeModal(e) {
    if (e != undefined) {
      this.setState({
        isOpen: false
      })
      e.preventDefault();
    }
    await this.setState({ currentIndex: null, isOpen: false });
  }
  async findPrev(e) {
    const temp = this.state.currentIndex;
    await this.setState({
      currentIndex: temp - 1,
      currentSource: imgUrls[temp - 1]
    })
  }
  async findNext(e) {
    const temp = this.state.currentIndex;
    await this.setState({
      currentIndex: temp + 1,
      currentSource: imgUrls[temp + 1]
    })
  }



  async handleTimeLine(data) {
    let length = data.length;
    let temp = [];

    for (let i = 0; i < length; i++) {
      let actions_image = [];
      console.log(data[i][0])
      if (data[i][0].images) {
        for (let j = 0; j < data[i][0].images.length; j++) {
          await actions_image.push(data[i][0].images[j].url)
        }
      }
      temp.push({
        inverted: true,
        badgeColor: "success",
        authority: [],
        SP: false,
        SM: false,
        badgeIcon: <img src={require("../../../../assets/icons/" + data[i][0].step_icon)} style={{ width: "32px" }}></img>,
        title: data[i][0].step,
        titleColor: "success",
        body: (
          <div>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">{strings.description}:</p>
              </GridItem>
              <GridItem>
                <p>
                  {data[i][0].step_desc}
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">{strings.who}:</p>
              </GridItem>
              <GridItem>
                <p>
                  {data[i][0].from_name + " - " + data[i][0].from_id}
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">{strings.Where}:</p>
              </GridItem>
              <GridItem>
                <p>
                  {data[i][0].from_name}
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">{strings.when}:</p>
              </GridItem>
              <GridItem>
                <p>
                  {moment(data[i][0].date).format('DD/MM/YYYY hh:ss')}
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">{strings.note}:</p>
              </GridItem>
              <GridItem>
                <p>
                  {data[i][0].step_attribute}
                </p>
              </GridItem>
            </GridContainer>
            <div style={{ width: "100%", marginTop: "-16px" }}>
                        {actions_image.length > 0 ? <GridContainer style={{ marginTop: "10px" }}>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <p className="TitleBold">{strings.trackingImage}:</p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <GalleryModal list={actions_image} />
                          </GridItem>
                        </GridContainer> : null}
                      </div>
          </div>
        ),
        footerTitle: ""
      },
      )
    }

    this.setState({
      showTimeLine: temp
    })
  }
  render() {
    return (
      <div>
        <GridContainer style={{ marginTop: "-50px" }}>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <Card plain>
              <CardBody plain>
                <GridContainer style={{ marginTop: "-50px" }}>
                  <GridItem xs={12} md={12} sm={12} lg={12}>
                    <Timeline stories={this.state.showTimeLine} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    processList: state.processList.data
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getProcessList: () => dispatch({ type: "GET_PROCESS_LIST" }),
  }
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TimelinePage));
