const initialState = { data: "", errorCode: "" };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_LOGO':
            return {
                ...state,
            };
        case 'UPDATE_LOGO_SUCCESS':
            return {
                ...state,
            };
        case 'UPDATE_LOGO_FAILURE':
            return {
                ...state,
            };
        default:
            return state;
    }
}
export default reducer;