import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import ReactToPrint from 'react-to-print';
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import cookie from 'react-cookies';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import Panorama from "@material-ui/icons/PanoramaHorizontalOutlined";
import Print from "@material-ui/icons/Print";
import Detail from "@material-ui/icons/ListAlt";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Shipment from "@material-ui/icons/LocalShipping";
// core components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CreateStamp from "./CreateStamp.jsx";
import StampPrintTemplate from "./StampPrintTemplate.jsx";
import StampBoxPrintTemplate from "./StampBoxPrintTemplate.jsx"

//mock data
import { IMG_LOAD } from 'constants/index.js';

//styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css"

import { LINK_API } from 'constants/API';
import strings from "../../../../constants/strings.jsx";

const axios = require('axios');

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
};

class TableStampList extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNewTree = this.handleNewTree.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);

    this.hideAlert = this.hideAlert.bind(this);
    this.onSuccess = this.onSuccess.bind(this);

    const queryParams = new URLSearchParams(this.props.location.search);
    const openCreateFarm = (queryParams.get('showCreateDialog') === "true") ? true : false;
    this.state = {
      openCreateFarm: openCreateFarm,
      alert: null,
      data: [],
      dataSubmit: [],
      load: "",
      showTable: "none",
      activeState: true
    };
  }

  genStampList = (arr) => {
    let newArr = [];
    newArr = arr.map((value) => ({
      type: value.type,
      date: value.date,
      endID: value.endId,
      startID: value.startId,
      used: value.used.reduce((newArr, { productName, type, ...other }) => {
        let currentName = newArr.find((x) => x.productName == productName);
        if (currentName === undefined) {
          // new item
          currentName = {
            productName,
            type,
            value: [other]
          }
          return [...newArr, currentName];
        }
        // has current item, update
        currentName = {
          ...currentName,
          value: [...currentName.value, other]
        }
        return newArr.map((x) => x.productName == productName ? currentName : x);
      }, [] /* default at [] */)
    }));

    this.setState({
      preData: arr,
      data: newArr.map((prop, key) => {
        let tem_use_list = [];
        let tem_use_list_child = [];
        let sum_tem_used = 0;
        let sumUsedAll = 0;
        let sum_tem = parseInt(prop.endID) - parseInt(prop.startID) + 1;
        for (let i = 0; i < prop.used.length; i++) {
          let dateTem = "";
          let startTem = "";
          let sumTemUse = 0;
          let ret = prop.used[i].value.map(rets => {
            let Tem = [];
            sumTemUse = sumTemUse + parseInt(rets.endId) - parseInt(rets.startId) + 1;
            Tem.push({
              date: moment(rets.date).format("DD/MM/YYYY"),
              end: rets.endId,
              start: rets.startId,
            })
            return Tem;
          }
          )
          sum_tem_used = sumTemUse;
          sumUsedAll = sumUsedAll + sum_tem_used
          startTem = ret.map(res => {
            if (res[0].start == res[0].end) {
              return <div>{res[0].start}</div>;
            }
            return <div>{res[0].start} - {res[0].end}</div>
          })
          dateTem = ret.map(res => {
            return <div>{res[0].date}</div>
          })
          tem_use_list.push([
            i + 1,
            prop.used[i].productName,
            sumTemUse + " tem",
            startTem,
            dateTem
          ])
          tem_use_list_child.push([
            i + 1,
            prop.used[i].productName,
            sumTemUse + " tem",
            startTem,
            dateTem
          ])
        }

        let sum_tem_remain = sum_tem - sumUsedAll;
        return {
          id: key + 1,
          LNC: "LNC" + key + 1,
          stampType: (prop.type == 'UNIT') ? "Tem sản phẩm" : "Tem lô hàng",
          amount: sum_tem,
          date: moment(prop.date).format("DD/MM/YYYY"),
          tem_use: tem_use_list,
          start_id: prop.startID ? (prop.startID.length > 13 ? prop.startID.substr(13, 23) : prop.startID) : '',
          end_id: prop.endID ? (prop.endID.length > 13 ? prop.endID.substr(13, 23) : prop.endID) : '',
          transfer: prop.transfer === "1" ? strings.isTransfer : (
            <Tooltip
                id="tooltip-top"
                title={strings.transfer}
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                  }}
                  color="success"
                  className="like"
                >
               <Shipment/>
                </Button>
              </Tooltip>
          ),
          actions: (
            // we've added some custom button actions
            <div className="actions-center">
              <Tooltip
                id="tooltip-top"
                title="In tem"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    this.setState({
                      alert: (
                        <SweetAlert
                          warning
                          style={{ display: "block", marginTop: "-200px" }}
                          title="In tem"
                          showConfirm={false}
                          showCancel={false}
                          closeOnClickOutside={true}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            Loại nông sản: {"Tất cả"}
                          </div>
                          <div style={{ marginBottom: "20px" }}>
                            Thời điểm tạo: {moment(prop.date).format("DD/MM/YYYY")}
                          </div>
                          <div style={{ marginBottom: "20px" }}>
                            Số lượng: {parseInt(prop.endID) - parseInt(prop.startID) + 1}
                          </div>
                          <GridContainer justify="space-around">
                            <GridItem lg={12}>
                              <Button color="danger" onClick={() => this.hideAlert()}> Đóng </Button>
                              <ReactToPrint
                                trigger={this.renderTrigger}
                                content={this.renderContent}
                              />
                              <div style={{ display: "none" }}>
                                {(prop.type !== "UNIT") ?
                                  <StampBoxPrintTemplate data={{ cooporativeName: this.props.userInfo.data.name, productName: prop.productType }} amount={parseInt(prop.endID) - parseInt(prop.startID) + 1} start={prop.startID} end={prop.endID} ref={this.setRef} />
                                  : <StampPrintTemplate data={{ cooporativeName: this.props.userInfo.data.name, productName: prop.productType }} amount={parseInt(prop.endID) - parseInt(prop.startID) + 1} start={prop.startID} end={prop.endID} ref={this.setRef} />
                                }
                              </div>
                            </GridItem>
                          </GridContainer>
                        </SweetAlert>
                      )
                    })
                  }}
                  color="success"
                  className="like"
                >
               <Print/>
                </Button>
              </Tooltip>
            </div>
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  }


  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error)
      return this.props.history.push("/");
    }
  }
  async handleCrop_List(id, rfid, boxid, status) {
    await cookie.save("IDCattle", id, { path: "/" });
    await cookie.save("RFIDCattle", rfid, { path: "/" });
    await cookie.save("shipmentCattle", boxid, { path: "/" });
    await cookie.save("statusCalle", status, { path: "/" });
    this.props.history.push("/admin/cattle/list/detail")
  }

  async componentDidMount() {
    let dataCattle = [];
    try {
      await this.checkPermission();
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }

      await axios.get(LINK_API.GET_STAMP_IMPORT)
      .then(async res=>{
        let data = res.data.data;
        let dataMap = await data.map(item=>{
          let list = [];
          for(let j = item.ProductList.length - 1; j > -1; j--){
            
            if(item.ProductList[j].status === 1){
              item.ProductList[j].status = strings.slaughter;
            }
            else{
              item.ProductList[j].status = strings.alive;
            }

            if(!item.ProductList[j].NON_GS1){
              item.ProductList[j].NON_GS1 = "0000000000000";
              item.ProductList[j].RFID = "0000000000000";
            }
            item.ProductList[j].location = item.ProductList[j].placeName + " - " + item.ProductList[j].placeUuid
            list.push([
              item.ProductList[j].NON_GS1,
              item.ProductList[j].RFID,
              item.ProductList[j].status,
              item.ProductList[j].location,
              <Button style={{ backgroundColor: "white", color: "#212b36" }} size="sm" onClick={() => this.handleCrop_List(item.ProductList[j].NON_GS1, item.ProductList[j].RFID, item.ProductList[j].id, item.ProductList[j].status)}> <Detail style={{ margin: "0px", marginRight: "5px" }} /> <a style={{ fontSize: "12px", textTransform: "none" }}>{strings.watchDetail}</a> </Button>
            ])
          }

          return{
            id: item.id,
            amount: item.ProductList.length,
            productList: list,
            time: moment(item.date).format("DD/MM/YYYY")
          }
        })


        this.setState({data: dataMap.reverse()})
        await this.setState({load:"none",
        showTable:""})
      })
      .catch(err=>{
        console.log(err)
      })
    }
    catch (error) {
      console.log(error)
    }

    // console.log("daa", dataCattle)
    let path = await cookie.load("slaughter", { path: "/" })
  }
  componentWillReceiveProps(preProps){
    if(this.props.infoActiveStamp !== preProps.infoActiveStamp){
      if(preProps.infoActiveStamp.data){
        this.genStampList(preProps.infoActiveStamp.data)
      }
    }
  }
  refresh = () => {
    window.location.reload();
  }

  renderTrigger() {
    return <Button color="success">In Mã</Button>;
  }

  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }

  setRef = (ref) => {
    this.componentRef = ref;
  }
  generateStamp(data) {

  }
  onSuccess() {

    let data = {
      stampType: this.state.dataSubmit.stampType,
      productType: this.state.dataSubmit.productType,
      productTypeGln: this.state.dataSubmit.productTypeGln,
      createAt: this.state.dataSubmit.createAt,
      from_gln: this.user_gln,
      amount: this.state.dataSubmit.numOfStamp,
      detail: "Tem mang thông tin thương hiệu"
    }

    this.setState({
      alert: (
        <SweetAlert
          custom
          style={{ display: "block" }}
          title="Đang xử lý!"
          customIcon={IMG_LOAD}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
        </SweetAlert>
      )
    });

    axios.post(LINK_API.CREATE_STAMP, data)
      .then(response => {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-200px" }}
              title="Thành Công!"
              showCancel={false}
              showConfirm={false}
            >
              <GridContainer justify="space-around">
                <GridItem lg={12}>
                  <Button color="danger" onClick={() => this.hideAlert()}> Đóng </Button>
                  <ReactToPrint
                    trigger={this.renderTrigger}
                    content={this.renderContent}
                  />
                  <div style={{ display: "none" }}>
                    {(data.productType = "All") ?
                      <StampBoxPrintTemplate data={{ cooporativeName: this.props.userInfo.data.name, productName: data.productType }} amount={data.amount} start={response.data.data.startId} end={response.data.data.endId} ref={this.setRef} />
                      : <StampPrintTemplate data={{ cooporativeName: this.props.userInfo.data.name, productName: data.productType }} amount={data.amount} start={response.data.data.startId} end={response.data.data.endId} ref={this.setRef} />
                    }

                  </div>
                </GridItem>check
              </GridContainer>
            </SweetAlert>
          )
        });
      })
      .catch(err => {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              Tạo tem không thành công
          </SweetAlert>
          )
        });
      });



  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showConfirmAlert(data) {
    this.handleClose();
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          onConfirm={() => this.onSuccess()}
          onCancel={() => {
            this.hideAlert();
            this.setState({ openCreateFarm: true });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          {/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
        </SweetAlert>,
      dataSubmit: data
    })
  }

  handleNewTree() {
    this.props.history.push("/admin/farms/tree?showCreateDialog=true")
  }

  handleNewClick() {
    this.setState({ openCreateFarm: true });
  }

  handleClose() {
    this.setState({ openCreateFarm: false });
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    const { classes } = this.props;
    const column_sub = [
      {
        Header: '#',
        accessor: 'id_use',
        maxWidth: 70,
        className: "center", // String-based value accessors!
      },
      {
        Header: 'Loại sản phẩm',
        accessor: 'type_use' // String-based value accessors!
      },
      {
        Header: 'Số lượng kích hoạt',
        accessor: 'amount_usr' // String-based value accessors!
      },
      {
        Header: 'Tem kích hoạt',
        accessor: 'start_use'
      },
      {
        Header: 'Thời điểm kích hoạt',
        accessor: 'date_use'
      },
    ]
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <h4 className="cardtableLabel">{strings.historyStamp}</h4>
                  </GridItem>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      <Button color="success" size="sm" onClick={()=>{}}><Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  data={this.state.data}
                  // filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                  previousText={strings.prevPage}
                    nextText={strings.nextPage}
                    rowsText={strings.row}
                    ofText="/"
                    pageText={strings.page}
                    noDataText={strings.noData}
                  columns={[
                    {
                      Header: "ID",
                      accessor: "id",
                      Filter: this.makePlaceholderFilter("Nhập Loại Tem"),
                      filterable: false
                    },
                    {
                      Header: strings.numOfCattles,
                      accessor: "amount",
                      Filter: this.makePlaceholderFilter("Nhập Số Lượng"),
                      filterable: false
                    },
                    // {
                    //   Header: strings.startId,
                    //   accessor: "start_id",
                    //   Filter: this.makePlaceholderFilter("Nhập tem bắt đầu"),
                    //   filterable: false
                    // },
                    // {
                    //   Header: strings.endId,
                    //   accessor: "end_id",
                    //   Filter: this.makePlaceholderFilter("Nhập tem kết thúc"),
                    //   filterable: false
                    // },
                    {
                      Header: strings.dateCreated,
                      accessor: "time",
                      sortable: false
                    },
                    // {
                    //   Header: strings.transfer,
                    //   accessor: "transfer",
                    //   filterable: false
                    // },
                    // {
                    //   Header: strings.actionHeader,
                    //   accessor: "actions",
                    //   sortable: false,
                    //   filterable: false
                    // },
                    {
                      Header: "Tem dùng",
                      accessor: "tem_use",
                      filterable: false,
                      show: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                  SubComponent={row => {
                    return (
                      
                      <div style={{ padding: "20px" }}>
                        <em>
                          {strings.numOfCattles}
                        </em>
                        <br />
                        <br />
                        <Table
                          tableHead={[
                            "ID",
                            "RFID",
                            strings.status,
                            strings.location,
                            strings.actionHeader,
                           
                          ]}
                          tableData={row.original.productList}
                          customCellClasses={[
                            classes.center,
                            classes.center,
                            classes.right
                          ]}
                          customClassesForCells={[0, 4, 5]}
                          customHeadCellClasses={[
                            classes.center,
                            classes.center,
                            classes.right
                          ]}
                          customHeadClassesForCells={[0, 4, 5]}
                        />
                      </div>
                    );
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          open={this.state.openCreateFarm}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <CreateStamp onConfirm={this.showConfirmAlert} />
        </Dialog>

        {this.state.alert}
      </div>
    );
  }
}

// export default withStyles(styles)(withRouter(TableStampList));
const mapDispatchToProps = (dispatch) => {
  return {
    getProductTypeList: () => dispatch({ type: "GET_PRODUCT_LIST" }),
    getStampList: (uuid) => dispatch({type: "GET_STAMP_LIST", data: uuid }),
    checkStamp: (data) => dispatch({type: "CHECK_STATUS_STAMP", data: data})
  }
}
const mapStateToProps = (state) => {
  return {
    productList: state.productType.data,
    userInfo: state.userInfo,
    infoActiveStamp: state.infoActiveStamp,
    checkStampStatus: state.checkStamp
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableStampList)));
