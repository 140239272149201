import React from 'react';
import moment from 'moment';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

import Chip from '@material-ui/core/Chip';
import Timeline from "../../components/Timeline/TimelineforSamle.jsx"
import strings from 'constants/strings';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
class Journey extends React.Component {
  constructor(props) {
    
    super(props);
    this.state={
      open: false,
      tx:"",
      diary: this.props.data,
    }
  }
  componentDidUpdate(preProps){
    if(preProps.data!==this.props.data){
      this.setState({
        diary: this.props.data,
      })
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  showBlockhainAlert = (tx) => {
    this.setState({ open: true ,tx :tx});
  }

render() {
  let that = this;
  // if(this.state.diary !== []){
  //   if(this.state.diary[0].step === undefined){
  //     this.setState({
  //       diary: [],
  //     })
  //     return(
  //       <div></div>
  //     )
  //   }
  // }
  // else{
    
    const x = this.state.diary.reverse();
    const stories = x.map(function(x,i){
      if (x.step_attribute==null || x.step_attribute =="undefined") x.step_attribute = ""
      let detail = x.step_attribute.split('\n').map((item, i) => {
        return <div key={i}>{item}</div>;
       });
       if(x.actions_image){
        // const imgsrc = {
        //   srcimg: require('../../../../assets/img/' + x.actions_image),
        // }
        return({
          inverted: true,
          badgeColor: 'success',
          badgeIcon: require('assets/icons/agri.png'),
          time: moment(x.date).format('DD/MM/YYYY HH:mm:ss'),
          tx: x.txID,
          key: i,
          titleColor: 'success',
          action: x.step ? x.step.toLowerCase(): "",
          who: x.from_name ? x.from_name: "",
          body: (
            <div>
              {x.step_desc}
            </div>),
          detail: (
            <div style={{display:"flex", flexFlow:"column"}} >
               <p className= "tagmanufact">Hình ảnh: </p>
               <img style={{width: "50px !important"}} src={x.actions_image}></img>
            </div>),
    
        footer: (<Chip
            icon={<FingerprintIcon />}
            label="Xác minh Blockchain"
            color={'success'}
            onDelete={that.handleClickOpen}
            deleteIcon={<DoneAllIcon />}
            onClick={that.handleClickOpen}
          />)
    })
       }else{
        return({
          inverted: true,
          badgeColor: 'success',
          badgeIcon: require('../../../../../assets/icons/' + x.step_icon),
          time: moment(x.date).format('DD/MM/YYYY'),
          tx: x.txID,
          key: i,
          titleColor: 'success',
          action: x.step ? x.step.toLowerCase() : '',
          who: x.from_name,
          body: (
            <div>
              {x.step_desc}
            </div>),
          detail: (
            <div>
               
            </div>),
    
        footer: (<Chip
            icon={<FingerprintIcon />}
            label="Xác minh Blockchain"
            color={'success'}
            onDelete={that.handleClickOpen}
            deleteIcon={<DoneAllIcon />}
            onClick={that.handleClickOpen}
          />)
    })
       }
     
     
  });
    return (
      <div style={{marginTop: "0px"}}>
        <Timeline stories={stories} tag={strings.coffee_diary} tagvideo={strings.video_coffee_diary} onConfirm = {this.showBlockhainAlert}/>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{padding:'10px', paddingBottom:'70px', paddingTop:'70px', zIndex:3000}}
          fullScreen={true}
        >
        <DialogContent style={{padding:'0px'}}>
          <iframe style={{height:'100%', width:'100%'}} src={'https://blockchain.agridential.vn/tx/'+this.state.tx}/>
        </DialogContent>
        <DialogActions style={{justifyContent:'center'}}>
          <Button style={{color:'white', background:'black'}} onClick={this.handleClose} color="primary" autoFocus>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
  }
  
// }

export default Journey;