import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';

import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CreateFarm from "./CreateauthorizeStamp.jsx";
//import WizardForm from "../Forms/WizardForm.jsx";
import { connect } from 'react-redux';
import {IMG_LOAD} from 'constants/index.js';
import {LINK_API} from '../../../../constants/API'; 
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import {tooltip} from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  paperwizard: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
    fullWidth:"true",
    maxWidth : 'md'
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class ActiveStamp extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.renderTrigger = this.renderTrigger.bind(this);

    const queryParams = new URLSearchParams(this.props.location.search); 
    const openCreateFarm = (queryParams.get('showCreateDialog') === "true")? true:false;
    const openWizardForm = (queryParams.get('showCreateDialog') === "true")? true:false;
    this.user_gln = cookie.load("gln");

    this.state = {
      openCreateFarm:openCreateFarm,
      openWizardForm:openWizardForm,
      alert:null,
      checked:false,
      value:{
        gln_extend:'',
        passphrase:'',
        data:'',
      },
      stampList: [],
      data: [],
      load:"",
      stepswizard:[],
      showTable:"none",
      processdata:"",
      processrawdata:"",
      processtitle:"",
    };

    this.token = cookie.load('token');
  }

  handleToggle() {
    const { checked } = this.state;

    if (checked) {
      this.setState({
        checked: false
      });
    }
    else {
      this.setState({
        checked: true
      });
    }

  }

  getCooperatives = async ()  => {
    try {
      let response = await axios.get(LINK_API.GET_COOPERATIVES);
      if (response.data.errorCode === 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  renderTrigger() {
    return <Button color = "success">In Mã</Button>;
  }
  
  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }

  setRef = (ref) => {
    this.componentRef = ref;
  }

  genTable = (stampList) => {
      this.setState({data: stampList.map((prop, key) => {
        const createAt = moment(parseInt(Date.parse(prop.createdAt))).format('DD/MM/YYYY')
        let state = parseInt(prop.startId) + parseInt(prop.state)
        return {
          id: key+1,
          cooperativeName: <div>
          {prop.name} <br></br> {"(Mã: " + prop.ownedBy + ")"}
        </div>,
          createAt:createAt,
          stampType:prop.stampType === 'BOX' ? 'Tem thùng' : 'Tem trái',
          startId: prop.startId,
          endId: prop.endId,
          state: state.toString().padStart((prop.startId || '').length, '0'),
          actions: (
            // we've added some custom button actions
            <div className="actions-center">
              <Tooltip
                id="tooltip-top"
                title="Tra cứu Blockchain"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    window.open("https://blockchain.agridential.vn/tx/" + prop.tx_id);
                  }}
                  color="warning"
                  className="like"
                >
                  <Search />
                </Button>
              </Tooltip>
              {/* <Tooltip
                id="tooltip-top"
                title="Khóa nông hộ"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {}}
                  color="danger"
                  className="like"
                >
                  <Delete />
                </Button>
              </Tooltip> */}
            </div>
          )
        };
      }),
      load:"none",
      showTable:""
    });
  }

  async checkPermission() {
		try {
			let response = await axios.get(LINK_API.VERIFY_TOKEN, {
				headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
			});
			if (response.data.errorCode !== 1 || !response.data.data.valid) {
				await cookie.remove('token');
				return this.props.history.push('/');
			} else {
				axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
			}
		} catch (error) {
			console.log(error);
			await cookie.remove('token');
			return this.props.history.push('/');
		}
	}

  gethistoryAuthorizeStamp = async ()  => {
    try {
      let response = await axios.get(LINK_API.GET_HISTORY_STAMP_AUTHORIZE, {params: { gln: this.user_gln } });
      if (response.data.errorCode === 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      let stampList = await this.gethistoryAuthorizeStamp();
      this.setState({stampList: stampList});
      this.genTable(stampList);
      let cooperatives = await this.getCooperatives() || []
      if (cooperatives && cooperatives.length > 0) {
        cooperatives = cooperatives.map(item => {
          return { label: `${item.name} (Mã: ${item.uuid})` }
        })
      }
      this.setState({ cooperatives })
    }
    catch (error) {
      console.log(error)
    }
  }

  async onSuccess(data) {
   
    
    // const ethers = require('ethers');
    // let url = "http://206.189.82.191:8000";
    // let customHttpProvider = new ethers.providers.JsonRpcProvider(url);
    // const mnemonic = await ethers.utils.HDNode.entropyToMnemonic(ethers.utils.randomBytes(16));
    // const wallet = ethers.Wallet.fromMnemonic(mnemonic);
    // data.address = wallet.address;
    // data.public_key = await ethers.utils.computePublicKey(wallet.privateKey);

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block"}}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{textAlign:"center"}}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{height:"100px"}}
            />
          </div>
        </SweetAlert>
      )
    });
    
    // let that=this;

    axios.post(LINK_API.STAMP_AUTHORIZE, data)
    .then( async response => {
      if (response.data.errorCode === 1) {

      let stampList = await this.gethistoryAuthorizeStamp();
      this.setState({stampList: stampList});
      this.genTable(stampList);

        await this.setState({
          //farmList: farmList,
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-270px" }}
              title="Thành Công!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              Cấp tem thành công
            </SweetAlert>
          )
        });
        return
      } else {
        throw response
      }
    })
    .catch(err => {
      console.log(err);
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            Cấp tem thất bại
          </SweetAlert>
        )
      });
    });
    
  }
  
  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showConfirmAlert(data){
    this.handleClose();
    this.setState({
      alert:
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Bạn đã kiểm tra kỹ các thông tin?"
            onConfirm={() => this.onSuccess(data)}
            onCancel={() => {
              this.hideAlert();
              this.setState({openCreateFarm:true});
            }}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Tạo mới"
            cancelBtnText="Làm lại"
            showCancel
          >
        </SweetAlert>,
    })
  }

  handleNewClick() {
    this.setState({openCreateFarm: true});
  }

  handleClose() {
    this.setState({openCreateFarm: false});
  }
  makePlaceholderFilter(placeholder) {
    return ({filter, onChange}) => (
        <input type='text'
          placeholder={placeholder}
          style={{
            width: '100%'
          }}
          onChange={event => onChange(event.target.value)} 
          value={filter ? filter.value : ''}
        />
      )
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {6} md = {6} sm = {6} xs = {12}>
                  {/* <CardIcon color="success">
                    <People />
                  </CardIcon> */}
                  <h4 className="cardtableLabel">Danh sách cấp quyền sử dụng tem</h4>
                </GridItem>
                <GridItem lg = {6} md = {6} sm = {6} xs = {12}>
                  <GridContainer justify = "flex-end" style = {{paddingRight:"25px", paddingTop: "10px" }}>
                      <Button color = "success" onClick = {this.handleNewClick}> <Add style = {{margin:"0px",marginRight:"5px"}}/> Thêm Mới </Button>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <div style={{textAlign:"center",display:this.state.load}}>
                <img
                  alt="{name}"
                  src={IMG_LOAD}
                  style={{height:"100px"}}
                />
              </div>
              <ReactTable
                data={this.state.data}
                filterable
                // defaultPageSize={5}
                previousText = "Trang Trước"
                nextText = "Trang Sau"
                rowsText = "hàng"
                ofText = "/"
                pageText = "Trang"
                noDataText = "Nhấn nút TẠO MỚI để cấp quyền sử dụng tem mới!"
                columns={[
                  {
                    Header: "#",
                    accessor: "id",
                    filterable:false,
                    maxWidth:70,
                    className:"center",
                    Header: () => (
                      <div style={{
                          textAlign: "center"
                      }}>
                          #
                        </div>
                  )
                  },
                  {
                    Header: "Tên doanh nghiệp",
                    accessor: "cooperativeName",
                    Filter: this.makePlaceholderFilter("Nhập quy trình"),
                    filterable: false
                  },
                  {
                    Header: "Thời gian cấp",
                    accessor: "createAt",
                    Filter: this.makePlaceholderFilter("Nhập thời gian"),
                    filterable: false
                  },
                  {
                    Header: "Loại tem",
                    accessor: "stampType",
                    Filter: this.makePlaceholderFilter("Nhập loại quy trình"),
                    filterable: false
                  },
                  {
                    Header: "Tem bắt đầu",
                    accessor: "startId",
                    Filter: this.makePlaceholderFilter("Nhập loại quy trình"),
                    filterable: false
                  },
                  {
                    Header: "Tem kết thúc",
                    accessor: "endId",
                    Filter: this.makePlaceholderFilter("Nhập số bước tối đa của quy trình"),
                    filterable: false
                  },
                  // {
                  //   Header: "Đã dùng",
                  //   accessor: "state",
                  //   Filter: this.makePlaceholderFilter("Nhập số bước tối đa của quy trình"),
                  //   filterable: false
                  // },
                  // {
                  //   Header: "Process_code",
                  //   accessor: "productType",
                  //   Filter: this.makePlaceholderFilter("Nhập productype"),
                //   {
                //     Header: "Số Cây Đã Đăng Ký",
                //     accessor: "numOfTree",
                //     filterable: false
                //   },
                //   {
                //     Header: "Tác Vụ",
                //     accessor: "actions",
                //     sortable: false,
                //     filterable: false
                //   }
              ]}
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={false}
                className="-striped -highlight"
                style={{display:this.state.showTable}}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Dialog
        open={this.state.openCreateFarm}
        onClose={this.handleClose}
        PaperProps ={{
          classes: {
          root: classes.paper
          }
        }}
      >
      <CreateFarm cooperatives={this.state.cooperatives || []} onConfirm = {this.showConfirmAlert}/>
      </Dialog>
      {this.state.alert}
      </div>
    );
  }
}

// export default withStyles(style)(withRouter(ActiveStamp));
const mapStateToProps = (state) => {
	return { userInfo: state.userInfo,
			 userProfile: state.userProfile}
  }
const  mapDispatchToProps = (dispatch) => {
	return {
	  createEmployee: (data, image) => dispatch({type: "CREATE_EMPLOYEE", data: data, avatar: image})
	}
  }
export default withStyles(style)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveStamp)));
