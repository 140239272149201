import React, { Component } from 'react';
import Rectangle from 'react-rectangle';
import SelectModern from 'react-select'

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import { connect } from 'react-redux';
import {
    PieChart, Pie, Sector, Cell,
} from 'recharts';

import axios from "axios";
import { LINK_API } from "../../../../constants/API";
import strings from "../../../../constants/strings";

const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 500 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        percent > 0 ?
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text> : null
    );
};

class Example extends Component {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/';

    constructor(props) {
        super(props);
        this.state = {
            defaultSelect: [],
            options: [{ value: "Shipment1", label: "Shipment1" }, { value: "Shipment2", label: "Shipment2" }],
            shipment: [],
            deleteMangeZone: [],
            dataGraph: [],
            optionsSelect:[],
        }

        this.options = this.props.options;
        this.handleChange_Select = this.handleChange_Select.bind(this);
    }

    async componentDidMount() {
        await this.props.getStampList();
        if(this.props.infoActiveStamp.list.length > 0){
            this.setState({
                optionsSelect: this.props.infoActiveStamp.list
            })
        }
    }
    componentWillReceiveProps(preProps){
        if(this.props.infoActiveStamp !== preProps.infoActiveStamp){
            if(preProps.infoActiveStamp.list.length > 0){
                this.setState({
                    optionsSelect: preProps.infoActiveStamp.list
                })
            }
        }
    }
    handleChange_Select = async (selectedOption, event) => {
        console.log(selectedOption);
        axios.get(LINK_API.GET_SHIPMENT_STATISTIC + selectedOption.value)
            .then(async res => {
                if (res.data.errorCode === 1) {
                    let data = []
                    if (res.data.data.FEEDLOT === 0 &&
                        res.data.data.ABATTOIRS === 0 &&
                        res.data.data.DEPOT === 0 &&
                        res.data.data.SLAUGHTER === 0) {
                        await data.push({
                            name: "Feedlot",
                            value: res.data.data.total
                        });
                    }else{
                        await data.push({
                            name: "Feedlot",
                            value: res.data.data.FEEDLOT
                        });
                        await data.push({
                            name: "Abattoirs",
                            value: res.data.data.ABATTOIRS
                        });
                        await data.push({
                            name: "Depot",
                            value: res.data.data.DEPOT
                        });
                        await data.push({
                            name: "Slaughter",
                            value: res.data.data.SLAUGHTER
                        });
                    }
                    this.setState({
                        dataGraph: data
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <Card>
                <div style={{ zIndex: "0 !important" }}>
                    <CardHeader >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <h4 className="cardtableLabel">{strings.shipmentAnalyst}</h4>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <div style={{ border: "1px solid #0f7343", borderRadius: "3px", width: "100%", display: "flex", justifyContent: "flex-start", flexFlow: "column", textAlign: "start" }}>
                                    <SelectModern
                                        defaultValue={this.state.defaultSelect}
                                        options={this.state.optionsSelect}
                                        onChange={this.handleChange_Select}
                                        placeholder={strings.chooseShipment}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </div>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                            <PieChart width={400} height={400} style={{ marginTop: "-40px", marginLeft: "-40px" }}>
                                <Pie
                                    data={this.state.dataGraph}
                                    cx={200}
                                    cy={200}
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={130}
                                 
                                    dataKey="value"
                                >
                                    {
                                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>
                            </PieChart>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} style={{ marginTop: "10%" }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4} style={{ marginBottom: 10 }}>
                                    <Rectangle aspectRatio={[5, 3]}>
                                        <div style={{ background: '#0088FE', width: '52px', height: '21px' }} />
                                    </Rectangle>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                    <div>{strings.feedlot}</div>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} style={{ marginBottom: 10 }}>
                                    <Rectangle aspectRatio={[5, 3]}>
                                        <div style={{ background: '#00C49F', width: '52px', height: '21px' }} />
                                    </Rectangle>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                    <div>{strings.abattoirs}</div>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} style={{ marginBottom: 10 }}>
                                    <Rectangle aspectRatio={[5, 3]}>
                                        <div style={{ background: '#FFBB28', width: '52px', height: '21px' }} />
                                    </Rectangle>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                    <div>{strings.depot}</div>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} style={{ marginBottom: 10 }}>
                                    <Rectangle aspectRatio={[5, 3]}>
                                        <div style={{ background: '#FF8042', width: '52px', height: '21px' }} />
                                    </Rectangle>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                    <div>{strings.slaughter}</div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>

        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
      getStampList: (uuid) => dispatch({type: "GET_STAMP_LIST", data: uuid })
    }
  }
  const mapStateToProps = (state) => {
    return {
      infoActiveStamp: state.infoActiveStamp,
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(Example)
