import React from "react";
import Resizer from 'react-image-file-resizer';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ImageUpload from "../../components/Forms/ImageUpload.jsx";
import Gallery from "../../components/Forms/Gallery/Gallery.js";
import Accordion from 'components/Accordion/Accordion.jsx';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { LINK_API } from '../../../../constants/API';
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import axios from 'axios';
import cookie from 'react-cookies';
import pictureframe from "assets/img/pictureicon.png";
import {store} from "store/store.js";
import { connect } from 'react-redux';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import strings from "../../../../constants/strings.jsx";
const LZUTF8 = require('lzutf8');
const style = {
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    marginLeft: "15px"
  },
  alignLeft: {
    textAlign: "left"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  },
  fixCardRemove:{
    display: "flex",
    justifyContent:"center",
    padding: "22px 5px !important"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  fixCardRemove:{
    display: "flex",
    justifyContent:"center",
    padding: "22px 5px !important"
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
 
}

class CreateProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tr: false,
      result: false,
      checked: false,
      alert: null,
      name: "",
      nameState: "",

      description: "",
      descriptionState: "",

      gtin: "",
      gtinState: "",

      imagelist: [],
      imagelist_cert: [],
      process: [],
      list: [],
      list_cert: [],
      currentImage: 0,
      countImage: 0,
      currentImage_cert: 0,
      countImage_cert: 0,
      destime: ["Kilogram","Sản phẩm"],
      deleteImage: {
        "uuid": "",
        "image": []
      },
      addImage: {
        "image": [],
        "uuid": "",
      },
      deleteImage_cert: {
        "uuid": "",
        "certificates": []
      },
      addImage_cert: {
        "certificates": [],
        "uuid": "",
      },
      deleteState: false,
      checked: [],
      loadthumbail: false,
      loadthumbail_cert: false,
      overviews_1: [],
      overviews_2: [],
      overviews_3: [],
      moreinfo: "",
      name_info: "",
      description_info: "",
      unit: 0,
      status: ["Sản xuất", "Ngưng sản xuất"],
    };
    this.uuidOfProduct =  cookie.load("uuidProducts")
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.onImageSubmit_cert = this.onImageSubmit_cert.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this)
    this.onCurrentImageChange_cert = this.onCurrentImageChange_cert.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deleteImage_cert = this.deleteImage_cert.bind(this)
    this.hideAlert = this.hideAlert.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.submitData = this.submitData.bind(this);
    this.hideAlert_success = this.hideAlert_success.bind(this);
    this.handleAddOverviews_1 = this.handleAddOverviews_1.bind(this);
    this.handleAddOverviews_2 = this.handleAddOverviews_2.bind(this);
    this.handleAddOverviews_3 = this.handleAddOverviews_3.bind(this);
    this.handle_log = this.handle_log.bind(this);
    this.handleRemoveInput_1 = this.handleRemoveInput_1.bind(this);
    this.handleRemoveInput_2 = this.handleRemoveInput_2.bind(this);
    this.handleRemoveInput_3 = this.handleRemoveInput_3.bind(this);
    this.procecssData = this.procecssData.bind(this);
    this.processImage = this.processImage.bind(this);
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  async componentDidMount() {
    await this.setState({
      list: [],
    })
    await this.checkPermission();
    await this.clickChild();
    this.procecssData();
  }
  async procecssData(){
      const list = this.props.productList;
      for(let i =0; i < list.length;i++){
        if(list[i].uuid === this.uuidOfProduct){
          await this.setState({
            infoProduct: list[i],
          })
        }
      }
      this.setState({
        name: this.state.infoProduct.name,
        description: this.state.infoProduct.description,
        unit_temp: this.state.infoProduct.unit === 1 ? "Kilogram" : "Sản phẩm",
        unit: this.state.infoProduct.unit,
        statusTemp: this.state.infoProduct.status == 1 ? "Sản xuất" : "Ngừng sản xuất",
        statusEdit: this.state.infoProduct.status,
        overviews_1: this.state.infoProduct.options ? (this.state.infoProduct.options.length > 0 ? this.state.infoProduct.options[0].value : []) : [],
        overviews_2: this.state.infoProduct.options ? (this.state.infoProduct.options.length > 0 ? this.state.infoProduct.options[1].value : []) : [],
        overviews_3: this.state.infoProduct.options ? (this.state.infoProduct.options.length > 0 ? this.state.infoProduct.options[2].value : []) : [],
      })
      this.processImage(this.state.infoProduct.image)
      this.processCert(this.state.infoProduct.certificates)
  }
  processImage(data){
    let temp = [];
    for(let i=0; i< data.length;i++){
        temp.push({
          src: data[i].secure_url,
          thumbnail: data[i].secure_url,
          isSelected: false,
          caption: "",
          url: data[i].secure_url,
        })   
    }
    this.setState({
      list: temp,
      countImage: temp.length
    })
  }
  processCert(data){
    let temp = [];
    for(let i=0; i< data.length;i++){
        temp.push({
          src: data[i].secure_url,
          thumbnail: data[i].secure_url,
          isSelected: false,
          caption: "",
          url: data[i].secure_url,
        })   
    }
    this.setState({
      list_cert: temp,
      countImage_cert: temp.length
    })
  }
  async  handleSelect(event) {
    if(event.target.value === "Kilogram"){
     await this.setState({ unit_temp: "Kilogram", unit: 1});
    }
    if(event.target.value === "Sản phẩm"){
     await this.setState({ unit_temp: "Sản phẩm", unit: 2});
    }
    if(event.target.value === "Sản xuất"){
      this.setState({
        statusEdit: 1,
        statusTemp: "Sản xuất"
      })
    }
    if(event.target.value === "Ngưng sản xuất"){
      this.setState({
        statusEdit: 0,
        statusTemp: "Ngưng sản xuất"
      })
    }
     document.getElementById("noti").innerHTML = "";
   }
  handleChange = name => async event => {
    if (name === "name") {
      this.setState({
        nameState: "success",
      })
      document.getElementById("noti").innerHTML = ""
    }
    if (name === "description") {
      this.setState({
        descriptionState: "success",
      })
      document.getElementById("noti").innerHTML = ""
    }
    if (name === "gtin") {
      this.setState({
        gtinState: "success",
      })
      document.getElementById("noti").innerHTML = ""
    }
    await this.setState({ [name]: event.target.value })
  };

  handle_overview_name_1 = idx => evt => {
    const newOverviews = this.state.overviews_1.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, key: evt.target.value }
    })
    this.setState({
      overviews_1: newOverviews
    })
  }
  handle_overview_name_2 = idx => evt => {
    const newOverviews = this.state.overviews_2.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, key: evt.target.value }
    })
    this.setState({
      overviews_2: newOverviews
    })
  }
  handle_overview_name_3 = idx => evt => {
    const newOverviews = this.state.overviews_3.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, key: evt.target.value }
    })
    this.setState({
      overviews_3: newOverviews
    })
  }
  handle_overview_description_1 = idx => evt => {
    const newOverviews = this.state.overviews_1.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, value: evt.target.value }
    })
    this.setState({
      overviews_1: newOverviews
    })
  }
  handle_overview_description_2 = idx => evt => {
    const newOverviews = this.state.overviews_2.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, value: evt.target.value }
    })
    this.setState({
      overviews_2: newOverviews
    })
  }
  handle_overview_description_3 = idx => evt => {
    const newOverviews = this.state.overviews_3.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, value: evt.target.value }
    })
    this.setState({
      overviews_3: newOverviews
    })
  }
  handleAddOverviews_1 = () => {
    this.setState({
      overviews_1: this.state.overviews_1.concat([{ key: "", value: "" }])
    })
  }
  handleAddOverviews_2 = () => {
    this.setState({
      overviews_2: this.state.overviews_2.concat([{ key: "", value: "" }])
    })
  }
  handleAddOverviews_3 = () => {
    this.setState({
      overviews_3: this.state.overviews_3.concat([{ key: "", value: "" }])
    })
  }
  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  async deleteImage() {
    if (this.state.list.length === 1) {
      await this.setState({
        currentImage: 0,
      })
    }
    if (!this.state.list[this.state.currentImage]) {
      await this.setState({
        currentImage: this.state.currentImage - 1,
      })
    }
    if (window.confirm("Bạn chắc chắn xóa ảnh này chứ?")) {
      this.setState({
        countImage: this.state.countImage - 1,
      })
      if(this.state.list[this.state.currentImage].url){
        this.state.deleteImage.image.push(this.state.list[this.state.currentImage].url)
      }
      var images = this.state.list.slice();
      images.splice(this.state.currentImage, 1)
      this.setState({
        list: images,
        deleteState: true
      });
    }
  }
  async deleteImage_cert() {
    if (this.state.list_cert.length === 1) {
      await this.setState({
        currentImage_cert: 0,
      })
    }
    if (!this.state.list_cert[this.state.currentImage_cert]) {
      await this.setState({
        currentImage_cert: this.state.currentImage_cert - 1,
      })
    }
    if (window.confirm("Bạn chắc chắn xóa ảnh này chứ?")) {
      this.setState({
        countImage_cert: this.state.countImage_cert - 1,
      })
      if(this.state.list_cert[this.state.currentImage_cert].url){
        this.state.deleteImage_cert.certificates.push(this.state.list_cert[this.state.currentImage_cert].url)
      }
      var images = this.state.list_cert.slice();
      images.splice(this.state.currentImage_cert, 1)
      this.setState({
        list_cert: images,
        deleteState_cert: true
      });
    }
  }
  handle_log = () =>{
    //console.log(this.state.overviews)
  }

  async onImageSubmit(file) {
    if (file != null) {

      // console.log("image", file)
      await this.setState({
        image: file
      })
      var data = this.state.list;
      var temp = {
        src: this.state.image,
        thumbnail: this.state.image,
        // thumbnailWidth: 160,
        // thumbnailHeight: 200,
        isSelected: false,
        caption: "",
        url: "",
      }
      await data.push(temp);
      await this.setState({
        countImage: this.state.countImage + 1,
        loadthumbail: true,
        list: data
      })
      document.getElementById("noti").innerHTML = "";
    }
    this.clickChild();

  }

  async onImageSubmit_cert(file) {
    if (file != null) {
      // console.log(file)
      await this.setState({
        image_cert: file
      })
      var data = this.state.list_cert;
      var temp = {
        src: this.state.image_cert,
        thumbnail: this.state.image_cert,
        isSelected: false,
        caption: "",
        url: "",
      }
      await data.push(temp);
      await this.setState({
        countImage_cert: this.state.countImage_cert + 1,
        loadthumbail_cert: true,
        list_cert: data
      })
    }
    this.clickChild_cert();

  }


  submitData() {
    var x = this.state.list.length;
    var i = 0;
    for (i = 0; i < x; i++) {
      if(!this.state.list[i].url){
        this.state.addImage.image.push(LZUTF8.compress(this.state.list[i].src, { outputEncoding: 'Base64' }))
      }
    }
    var y = this.state.list_cert.length;
    var j = 0;
    for (j = 0; j < y; j++) {
      if(!this.state.list_cert[j].url){
        this.state.addImage_cert.certificates.push(LZUTF8.compress(this.state.list_cert[j].src, { outputEncoding: 'Base64' }))
      }
     
    }
    if (!this.state.name) {
      this.setState({
        nameState: "error"
      })
      document.getElementById("noti").innerHTML = "Vui nhập tên loại sản phẩm";
      return;
    }
    if (!this.state.description) {
      this.setState({
        descriptionState: "error"
      })
      document.getElementById("noti").innerHTML = "Vui nhập miêu tả loại sản phẩm";
      return;
    }
    if(this.state.unit === 0){
      document.getElementById("noti").innerHTML = "Vui lòng nhập đơn vị";
      return;
    }
    // if (this.state.checked[0] === 1) {
    //   if (!this.state.gtin) {
    //     document.getElementById("noti").innerHTML = "Vui lòng nhập mã GTIN";
    //     this.setState({
    //       gtinState: "error"
    //     })
    //     return;
    //   }
    // }
    // const data = {
    //   "name": this.state.name,
    //   "description": this.state.description,
    //   "type": "",
    //   "image": this.state.imagelist,
    //   "glnCheckbox": (this.state.checked[0] === 1) ? true : false,
    //   "gtin": (this.state.gtin) ? this.state.gtin : "",
    //   "certificates": this.state.imagelist_cert,
    //   "options": [
    //    { key: "Mô tả sản phẩm", value: this.state.overviews_1},
    //     {key: "Quy cách sản phẩm", value: this.state.overviews_2},
    //     {key: "Quy cách đóng gói", value: this.state.overviews_3}
    //   ],
    //   "unit": this.state.unit,
    // }
    const datainfo ={ 
      uuid: this.uuidOfProduct,
      name: {
           vi: this.state.name,
      },
      description:  {
           vi: this.state.description,
      },
      status: this.state.statusEdit,
      unit: this.state.unit,
      "options": [
        { key: "Mô tả sản phẩm", value: this.state.overviews_1},
         {key: "Quy cách sản phẩm", value: this.state.overviews_2},
         {key: "Quy cách đóng gói", value: this.state.overviews_3}
       ],
    }
    const dataimage_add = this.state.addImage;
    dataimage_add.uuid = this.uuidOfProduct;
    const dataimage_delete = this.state.deleteImage;
    dataimage_delete.uuid = this.uuidOfProduct;
    const datacert_add=this.state.addImage_cert;
    datacert_add.uuid = this.uuidOfProduct;
    const datacert_delete=this.state.deleteImage_cert;
    datacert_delete.uuid = this.uuidOfProduct;
     this.props.updateInfo(datainfo, dataimage_delete, dataimage_add, datacert_delete, datacert_add);
     this.props.history.push("/admin/products/list")
  }
  handleRemoveInput_1 = (idx) => {
    this.setState({
      overviews_1: this.state.overviews_1.filter((s, sidx) => idx !== sidx)
    })
  }
  handleRemoveInput_2 = (idx) => {
    this.setState({
      overviews_2: this.state.overviews_2.filter((s, sidx) => idx !== sidx)
    })
  }
  handleRemoveInput_3 = (idx) => {
    this.setState({
      overviews_3: this.state.overviews_3.filter((s, sidx) => idx !== sidx)
    })
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    if (this.state.checked[0] === 1) {
      document.getElementById("glnchecked").style = "display: block; width: 100%; margin-top: -27px;"
    } else {
      document.getElementById("glnchecked").style = "display: none"
    }
  }
  async onConfirm(data) {
    this.props.createProduct(data);
    this.props.history.push("/admin/products/list");
  }
  hideAlert_success() {
    this.setState({
      alert: null
    })
    this.props.history.push("/admin/products/list")
  }
  onCurrentImageChange(index) {
    this.setState({ currentImage: index });
  }
  onCurrentImageChange_cert(index) {
    this.setState({ currentImage_cert: index });
  }
  showNotification(place) {
    if (!this.state[place]) {
      var x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(
        function() {
          x[place] = false;
          this.setState(x);
         
        }.bind(this),
        6000
      );
    }
  }
 async showNotification_x() {
  await this.props.getProductTypeList();
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={8} sm={8} md={8} lg={8}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <h4 className="cardtableLabel">{strings.productypeInfo}</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <form>
                      <CustomInput
                        labelText={strings.nameProductType + " (*)"}
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('name'),
                        }}
                        inputProps={{
                          value: this.state.name,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                home
                              </Icon>
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        labelText={strings.description + " (*)"}
                        success={this.state.descriptionState === "success"}
                        error={this.state.descriptionState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('description'),
                        }}
                        inputProps={{
                          multiline: true,
                          rowsMax: "3",
                          rows: "3",
                          value: this.state.description,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                list
                              </Icon>
                            </InputAdornment>
                          ),
                          // placeholder: "Mô tả"
                        }}
                      />
                     <div style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <p className="titleCreate">{strings.unit}</p>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        id='select'
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.unit_temp}
                        onChange={this.handleSelect}
                        inputProps={{
                          name: "type",
                          id: "type"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {strings.chooseUnit}
                        </MenuItem>
                        {this.state.destime.map((x, idx) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={x}
                            key={idx}
                          >
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div  style={{ width: "100%", marginTop: "10px" }}>
                  <FormControl              
                fullWidth
                className={classes.selectFormControl}
              >
               <p className="titleCreate">{strings.status}</p>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  id='select'
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.statusTemp}
                  onChange={this.handleSelect.bind(this)}
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}
                >
                  {this.state.status.map((x, idx) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={x}
                      key={idx}
                    >
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                  </div>
                      {/* <div style={{ width: "100%", marginTop: "10px" }}>
                        <FormControlLabel
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel
                          }}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => this.handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          label="Đã đăng ký mã GTIN"
                        />
                      </div>
                      <div style={{ marginTop: "-35px", display: "none" }} id="glnchecked">
                        <CustomInput
                          success={this.state.gtinState === "success"}
                          error={this.state.gtinState === "error"}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                            onChange: this.handleChange('gtin'),
                          }}
                          inputProps={{
                            value: this.state.gtin,
                            autoComplete: "off",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  code
                              </Icon>
                              </InputAdornment>
                            ),
                            placeholder: "Mã GTIN"
                          }}
                        />
                      </div> */}
                    </form>
                    <div style={{ width: '100%'}}>
										<Accordion
											collapses={[
												{
													title: strings.moreInfoProduct,
													content: (
                            <div style={{marginTop:"-40px",width: "100%"}}>
                            <CustomTabs
                            className=""
                            tabs={[
                              {
                                tabName: strings.productDes,
                                tabContent: (
                                  <form style={{width: "100%"}}>
                                  {this.state.overviews_1.map((overview, idx) => (
                                    <GridContainer key={idx}>
                                      <GridItem xs={4} md={4} sm={4} lg={4}>
                                      <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses,
                                        onChange: this.handle_overview_name_1(idx),
                                      }}
                                      inputProps={{
                                        value: overview.key,
                                        autoComplete: "off",
                                        placeholder: "Tên trường #" + idx 
                                      }}
                                    />
                                      </GridItem>
                                      <GridItem xs={7} md={7} sm={7} lg={7}>
                                      <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses,
                                        onChange: this.handle_overview_description_1(idx),
                                      }}
                                      inputProps={{
                                        value: overview.value,
                                        autoComplete: "off",
                                        placeholder: "Nội dung #" + idx 
                                      }}
                                    />
                                      </GridItem>
                                      <GridItem xs={1} md={1} sm={1} lg={1} className={classes.fixCardRemove}>
                                      <Button color="danger" size="sm" onClick={() => this.handleRemoveInput_1(idx)}>X</Button>
                                      </GridItem>
                                    </GridContainer>
                                  ))}
                                   <Button color="success" size="sm" onClick={this.handleAddOverviews_1} style={{ marginLeft: 13 }}>{strings.addFields}</Button>
                                </form>
                                )
                              },
                              {
                                tabName: strings.productSpec,
                                tabContent: (
                                  <form style={{width: "100%"}}>
                                  {this.state.overviews_2.map((overview, idx) => (
                                    <GridContainer key={idx}>
                                      <GridItem xs={4} md={4} sm={4} lg={4}>
                                      <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses,
                                        onChange: this.handle_overview_name_2(idx),
                                      }}
                                      inputProps={{
                                        value: overview.key,
                                        autoComplete: "off",
                                        placeholder: "Tên trường #" + idx 
                                      }}
                                    />
                                      </GridItem>
                                      <GridItem xs={7} md={7} sm={7} lg={7}>
                                      <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses,
                                        onChange: this.handle_overview_description_2(idx),
                                      }}
                                      inputProps={{
                                        value: overview.value,
                                        autoComplete: "off",
                                        placeholder: "Nội dung #" + idx 
                                      }}
                                    />
                                      </GridItem>
                                      <GridItem xs={1} md={1} sm={1} lg={1} className={classes.fixCardRemove}>
                                      <Button color="danger" size="sm" onClick={() => this.handleRemoveInput_2(idx)}>X</Button>
                                      </GridItem>
                                    </GridContainer>
                                  ))}
                                   <Button color="success" size="sm" onClick={this.handleAddOverviews_2} style={{ marginLeft: 13 }}>{strings.addFields}</Button>
                                </form>
                                )
                              },
                              {
                                tabName: strings.packing,
                                tabContent: (
                                  <form style={{width: "100%"}}>
                                  {this.state.overviews_3.map((overview, idx) => (
                                    <GridContainer key={idx}>
                                      <GridItem xs={4} md={4} sm={4} lg={4}>
                                      <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses,
                                        onChange: this.handle_overview_name_3(idx),
                                      }}
                                      inputProps={{
                                        value: overview.key,
                                        autoComplete: "off",
                                        placeholder: "Tên trường #" + idx 
                                      }}
                                    />
                                      </GridItem>
                                      <GridItem xs={7} md={7} sm={7} lg={7}>
                                      <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses,
                                        onChange: this.handle_overview_description_3(idx),
                                      }}
                                      inputProps={{
                                        value: overview.value,
                                        autoComplete: "off",
                                        placeholder: "Nội dung #" + idx 
                                      }}
                                    />
                                      </GridItem>
                                      <GridItem xs={1} md={1} sm={1} lg={1} className={classes.fixCardRemove}>
                                      <Button color="danger" size="sm" onClick={() => this.handleRemoveInput_3(idx)}>X</Button>
                                      </GridItem>
                                    </GridContainer>
                                  ))}
                                   <Button color="success" size="sm" onClick={this.handleAddOverviews_3} style={{ marginLeft: 13 }}>{strings.addFields}</Button>
                                   </form>
                                )
                              }
                            ]}
                            />
                            </div>
                          )
                        }
											]}
										/>
									</div>
                  </GridItem>
                  <div style={{ display: "flex", justifyContent: "center", width: " 100%" }}>
                    <p
                      id="noti"
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '400',
                        color: 'red',
                        fontFamily: 'Muli',
                        marginTop: 5,
                        marginBottom: 5,
                      }}>
                    </p>
                  </div>
                  <Button color="success" size="sm" onClick={this.submitData.bind(this)} style={{ marginLeft: 13 }}>{strings.update}</Button>
                  {/* <Button color="danger" size="sm" onClick={this.showNotification_x.bind(this)}>Push Noti</Button>
                  <Button color="info" size="sm" onClick={this.showNotification_xx.bind(this)}>Deactive Noti</Button> */}
                </GridContainer>
                
              </CardBody>
                <p style={{marginLeft: 20, color: "#828282", fontSize: 12}}>{"(*) " + strings.fieldRequire}</p>
              <p>{store.getState().message}</p>
            </Card>
          </GridItem>
          <GridItem xs={4} sm={4} md={4} lg={4} >
            <GridItem xs={12} sm={12} md={12} lg={12} >
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      {/* <CardIcon color="success">
                        <Spa />
                      </CardIcon> */}
                      <h4 className="cardtableLabel">{strings.image}</h4>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody style={{ justifyContent: "center" }}>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                  <Gallery
                    setClick={click => this.clickChild = click}
                    images={this.state.list}
                    enableLightbox={true}
                    enableImageSelection={false}
                    currentImageWillChange={this.onCurrentImageChange}
                    rowHeight= {150}
                    customControls={[
                      <button onClick={this.deleteImage}> Xóa ảnh</button>
                    ]}
                  />
                  </GridItem>
                  <GridItem xs={12} md={12} sm={12} lg={12}>
                  <ImageUpload
                    onImageSubmit={this.onImageSubmit.bind(this)}
                    // SubmitData={this.handleSaveImage}
                    Count={this.state.countImage}
                    delete={this.state.deleteState}
                    Flag="true" />
                  </GridItem>
                </CardBody>
                {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  {
                    (this.state.loadthumbail === false) ? <img src={pictureframe} style={{ maxHeight: 150 }} /> : null
                  }
                </div> */}
                <GridContainer style={{ marginLeft: "6%" }}>
                
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} >
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      {/* <CardIcon color="success">
                        <Spa />
                      </CardIcon> */}
                      <h4 className="cardtableLabel">{strings.certificate}</h4>
                    </GridItem>

                  </GridContainer>
                </CardHeader>
                <CardBody style={{ justifyContent: "center" }}>
                  <GridItem xs={12} md={12} sm={12} lg={12}>
                  <Gallery
                    setClick={click => this.clickChild_cert = click}
                    images={this.state.list_cert}
                    enableLightbox={true}
                    rowHeight= {150}
                    enableImageSelection={false}
                    currentImageWillChange={this.onCurrentImageChange_cert}
                    customControls={[
                    <button onClick={this.deleteImage_cert}>{strings.deleteImage}</button>
                    ]}
                  />
                  </GridItem>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                <ImageUpload
                    onImageSubmit={this.onImageSubmit_cert.bind(this)}
                    // SubmitData={this.handleSaveImage}
                    Count={this.state.countImage_cert}
                    delete={this.state.deleteState_cert}
                    Flag="true" />
                </GridItem>
                </CardBody>
              
                <GridContainer style={{ marginLeft: "6%" }}>
                
                </GridContainer>
              </Card>
            </GridItem>
          </GridItem>
        </GridContainer>
        {this.state.alert}
      </div>
    );
  }
}


const  mapDispatchToProps = (dispatch) => {
  return {
    getProductTypeList: () => dispatch({type: "GET_PRODUCT_LIST"}),
    createProduct: (data) => dispatch({type: "CREATE_PRODUCT", data: data}),
    updateInfo: (datainfo, dataimage_delete, dataimage_add, datacert_delete, datacert_add) => 
              dispatch({type: "UPDATE_INFO_PRODUCT", datainfo: datainfo, 
              dataimage_delete: dataimage_delete, 
              dataimage_add: dataimage_add,
              datacert_delete: datacert_delete,
              datacert_add: datacert_add})
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
           productList: state.productType.data,}
}
export default withStyles(style)(connect(mapStateToProps,mapDispatchToProps)(CreateProduct));
