import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import QRCode from 'qrcode.react';
import moment from 'moment';
import VBC from "assets/img/bg-img/vbcblack.png";
import AGD from "assets/img/bg-img/agd.png";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  frame: {
    marginLeft:"2cm",
    padding: "0.3cm",
    marginTop:"1.2cm",
    marginBottom:"1.5cm",
    position:"relative"
  },
  paddingStamp:
  {
    marginRight:"0.3cm !important",
    marginTop:"0.5cm !important"
  },
  stampFrame:{
    paddingLeft:"0.15cm",
    // textAlign: "center",
    // marginTop: "30px",
    // border: "solid 1px #000",
    width:"1.7cm",
    height:"1.5cm",
    backgroundSize:"contain",
    backgroundRepeat:"no-repeat",
    // backgroundImage:`url(${stamp_embryo})`,
    marginRight:"0.3cm !important",
    marginTop:"0.3cm",
    imageRendering: "-webkit-optimize-contrast",
    // height:"95%",
    // width:"93%"
  },
  productText: {
    fontSize: "0.2cm",
    color:"#258af2",
    fontWeight: "700",
    marginTop: "0.25cm",
    marginBottom: "0.1cm",
    fontFamily: "Muli Black",
    position: "absolute",
  },
  subText: {
    fontSize: "0.16cm",
    color:"#fff",
    fontWeight: "700",
    paddingTop:"0.05cm",
    paddingBottom: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    fontFamily: "Muli Black",
    letterSpacing:"0.005cm",
    position: "absolute",
  },
  subTextBold: {
    fontSize: "0.15cm",
    letterSpacing:"0.0355cm",
    color:"#000",
    fontWeight: "700",
    paddingBottom: "0cm",
    marginTop: "0.35cm",
    marginLeft:"0.75cm",
    fontFamily: "Muli",
    position: "absolute",
    // transform: `${rotate(90)}`
    
  },
  qrCode:{
    maxWidth:"1.2cm",maxHeight:"1.2cm",marginTop:"0.0cm",marginBottom:"0px",marginLeft:"0.08cm", position: "absolute",
  },
  logoPrint: {
    display: "flex",
    justifyItems:  "end",
    marginBottom: "0.5cm"
  },
};



const STAMP_PER_LINE = 9;
const ROW_PER_PAGE = 14;

class StampPrintTemplate extends React.Component {

  generateSingleStamp(id) {
    const { classes } = this.props;
    const id_pad = (id.toString()).padStart(13,"0");
    // const valueqr = start.substr(0,12) + id_pad;
    const valueqr = id_pad;
    return (
      // <GridItem lg = {1} md = {1} xs = {1} sm = {1} className = {classes.paddingStamp}>
        <div  className = {classes.stampFrame}>
          {/* <img src={stamp_embryo} border="0" alt="embryo" /> */}
          {/* <h5 className = {classes.subText}>TP Cao Lãnh, Đồng Tháp</h5> */}
          {/* <h5 className = {classes.subText}> {data.cooporativeName} </h5>
          <h5 className = {classes.productText}> 
          {(data.productName==="All")?("Tem thùng"
          ):(
          "Tem sản phẩm"
          )}
          </h5> */}
          <h5 className = "rotateRight"> BLOCKCHAIN </h5>
          <QRCode  className = {classes.qrCode} value={"https://portal.agridential.vn/lookup/" + valueqr} />
          <h5 className = "rotate"> {id_pad} </h5>
        </div>
      // </GridItem>
    );
  }

  generateRowStamp(start_id,end_id,data) {
    // console.log(start_id);
    // console.log(end_id);
    // console.log("    ")
    const stamp_list = []

    for (let i = start_id; i <= end_id; i++) {
      //console.log(i)
      stamp_list.push(this.generateSingleStamp(i));
    }
    
    let row = null;
    
    if (stamp_list.length < STAMP_PER_LINE) {
      row = (
        <GridContainer>
          {stamp_list}
        </GridContainer>
      )
    }
    else {
      row = (
        <GridContainer>
        {stamp_list}
        </GridContainer>
      )
    }
    return row;
  }

  generateLogo() {
    const { classes } = this.props;
    return (
      <GridContainer className={classes.logoPrint}>
        <GridItem lg = {3} md = {3} xs = {3} sm = {3} >
          <img src= {VBC}/>
        </GridItem>
        <GridItem lg = {3} md = {3} xs = {3} sm = {3} >
        <img src= {AGD}/>
        </GridItem>
      </GridContainer>
    );
  }

  render() {

    const { classes } = this.props;
    const { data } = this.props;
    const { start } = this.props;
    const { end } = this.props;
    const {amount} = this.props;

    var numOfRow = Math.ceil((amount) / STAMP_PER_LINE);
    //console.log("Row",numOfRow);
    // console.log(start)
    // const x = parseInt(start.substr(13, 23))
    // var z = x.toString();
    // var l= z.length;
    // z =  z.padStart(10,'0')
    // console.log(z)
    // console.log(x)
    var x = parseInt(start)
    var y = parseInt(end)
    let row_list = [];
    for (let i = 0; i < numOfRow; i++) {
      let start_id = x + i * STAMP_PER_LINE;
      let end_id = x +  (i + 1) * STAMP_PER_LINE-1;
      if (end_id > y) {
        end_id = y;
      }
      row_list.push(this.generateRowStamp(start_id,end_id,data));
    }
   
    var numOfPage = Math.floor((amount) / (STAMP_PER_LINE*ROW_PER_PAGE));
      if ((amount % (STAMP_PER_LINE*ROW_PER_PAGE) > 0)){
      numOfPage = numOfPage + 1;
    }
    //console.log("page",numOfPage)
    let pages_list = []

    for (let i = 1; i <= numOfPage; i++) {
      let start_id = x + (i-1) * STAMP_PER_LINE*ROW_PER_PAGE;
      let end_id = x +  i * STAMP_PER_LINE*ROW_PER_PAGE -1;
      if (end_id > y) {
        end_id = y;
      }
      let headerString = <GridContainer className={classes.logoPrint}>
                            <GridItem lg = {6} md = {6} xs = {6} sm = {6} >
                              <div style={{fontSize: 12, verticalAlign: "middle"}}>{"Tem từ " + (start_id.toString()).padStart(13,"0") + " đến " + (end_id.toString()).padStart(13,"0")} </div>
                            </GridItem>
                            {/* <GridItem lg = {3} md = {3} xs = {3} sm = {3} >
                            </GridItem> */}
                            <GridItem lg = {2} md = {2} xs = {2} sm = {2} >
                              <img src= {VBC}/>
                            </GridItem>
                            <GridItem lg = {2} md = {2} xs = {2} sm = {2} >
                              <img width="85%" src= {AGD}/>
                            </GridItem>
                          </GridContainer>
       
      const page = [headerString];
      for (let r = 0 + (i-1)*ROW_PER_PAGE; r < ROW_PER_PAGE*i; r++) {
        if(r < numOfRow){
          page.push(row_list[r]);
        }else{
          
          break;
        }
      }
      pages_list.push(
        <GridContainer className = {classes.frame}>
          {page}
        </GridContainer>
      )
    }
    //console.log(pages_list)
    

    return (
      <GridContainer style={{position:'static'}}>
        {pages_list}
      </GridContainer>
    );
  }
}

export default withStyles(style)(StampPrintTemplate);
