//Create
export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const CREATE_AREA = "CREATE_AREA"
export const CREATE_OBJECT = "CREATE_OBJECT"
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE"
export const CREATE_PROCESS = "CREATE_PROCESS"
export const CREATE_OBJECT_PROCESS = "CREATE_OBJECT_PROCESS"
export const CREATE_BRANCH = "CREATE_BRANCH"
export const CREATE_DISTRIBUTOR = "CREATE_DISTRIBUTOR"

//Update banner 
export const UPDATE_BANNER = "UPDATE_BANNER"
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS"
export const UPDATE_BANNER_FAILURE = "UPDATE_BANNER_FAILURE"

//Update logo
export const UPDATE_LOGO = "UPDATE_LOGO"
export const UPDATE_LOGO_SUCCESS = "UPDATE_LOGO_SUCCESS"
export const UPDATE_LOGO_FAILURE = "UPDATE_LOGO_FAILURE"

//Update avatar
export const UPDATE_AVATAR = "UPDATE_AVATAR"
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS"
export const UPDATE_AVATAR_FAILURE = "UPDATE_AVATAR_FAILURE"

//Update certificate
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";
export const UPDATE_CERTIFICATE_SUCCESS = "UPDATE_CERTIFICATE_SUCCESS";
export const UPDATE_CERTIFICATE_FAILURE = "UPDATE_CERTIFICATE_FAILURE";

export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";
export const DELETE_CERTIFICATE_SUCCESS = "DELETE_CERTIFICATE_SUCCESS";
export const DELETE_CERTIFICATE_FAILURE = "SUCCESS_CERTIFICATE_FAILURE";

//Notification
export const PUSH_NOTI_PRODUCT_SUCCESS = "PUSH_NOTI_PRODUCT_SUCCESS"
export const DEACTIVE_NOTI_PRODUCT = "PUSH_NOTI_PRODUCT"
export const PUSH_NOTI_PRODUCT_FAILURE = "PUSH_NOTI_PRODUCT_FAILURE"
export const PUSH_NOTI_PRODUCT_PROCESSING = "PUSH_NOTI_PRODUCT_PROCESSING"

//Branch List
export const GET_BRANCH_LIST = "GET_BRANCH_LIST";
export const GET_BRANCH_LIST_SUCCESS = "GET_BRANCH_LIST_SUCCESS";
export const GET_BRANCH_LIST_FAILURE = "GET_BRANCH_LIST_FAILURE";

//Distributor List
export const GET_DISTRIBUTOR_LIST = "GET_DISTRIBUTOR_LIST";
export const GET_DISTRIBUTOR_LIST_SUCCESS = "GET_DISTRIBUTOR_LIST_SUCCESS";
export const GET_DISTRIBUTOR_LIST_FAILURE = "GET_DISTRIBUTOR_LIST_FAILURE";

//Product List
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

//Area List
export const GET_AREA_LIST = "GET_AREA_LIST";
export const GET_AREA_LIST_SUCCESS = "GET_AREA_LIST_SUCCESS";
export const GET_AREA_LIST_FAILURE = "GET_AREA_LIST_FAILURE";

//Object List
export const GET_OBJECT_LIST = "GET_OBJECT_LIST";
export const GET_OBJECT_LIST_SUCCESS = "GET_OBJECT_LIST_SUCCESS";
export const GET_OBJECT_LIST_FAILURE = "GET_OBJECT_LIST_FAILURE";

//Object Processing List
export const GET_OBJECT_PROCESSING_LIST = "GET_OBJECT_PROCESSING_LIST";
export const GET_OBJECT_PROCESSING_LIST_SUCCESS = "GET_OBJECT_PROCESSING_LIST_SUCCESS";
export const GET_OBJECT_PROCESSING_LIST_FAILURE = "GET_OBJECT_PROCESSING_LIST_FAILURE";

//Crop Object List
export const GET_CROP_OBJECT = "GET_CROP_OBJECT";
export const GET_CROP_OBJECT_SUCCESS = "GET_CROP_OBJECT_SUCCESS";
export const GET_CROP_OBJECT_FAILURE = "GET_CROP_OBJECT_FAILURE";

//Crop Object List
export const GET_CROP_OBJECT_PROCESSING = "GET_CROP_OBJECT_PROCESSING";
export const GET_CROP_OBJECT_PROCESSING_SUCCESS = "GET_CROP_OBJECT_PROCESSING_SUCCESS";
export const GET_CROP_OBJECT_PROCESSING_FAILURE = "GET_CROP_OBJECT_PROCESSING_FAILURE";

//Crop List
export const GET_CROP_LIST = "GET_CROP_LIST";
export const GET_CROP_LIST_SUCCESS = "GET_CROP_LIST_SUCCESS";
export const GET_CROP_LIST_FAILURE = "GET_CROP_LIST_FAILURE";

//Harvest List
export const GET_HARVEST_LIST = "GET_HARVEST_LIST";
export const GET_HARVEST_LIST_SUCCESS = "GET_HARVEST_LIST_SUCCESS";
export const GET_HARVEST_LIST_FAILURE = "GET_HARVEST_LIST_FAILURE";

//Employee List
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS";
export const GET_EMPLOYEE_LIST_FAILURE = "GET_EMPLOYEE_LIST_FAILURE";

//Process List
export const GET_PROCESS_LIST = "GET_PROCESS_LIST";
export const GET_PROCESS_LIST_SUCCESS = "GET_PROCESS_LIST_SUCCESS";
export const GET_PROCESS_LIST_FAILURE = "GET_PROCESS_LIST_FAILURE";

//Get Object detail
export const GET_OBJECT_DETAIL = "GET_OBJECT_DETAIL";
export const GET_OBJECT_DETAIL_SUCCESS = "GET_OBJECT_DETAIL_SUCCESS";
export const GET_OBJECT_DETAIL_FAILURE = "GET_OBJECT_DETAIL_FAILURE";

//Get Object Processing detail
export const GET_OBJECT_PROCESSING_DETAIL = "GET_OBJECT_PROCESSING_DETAIL";
export const GET_OBJECT_PROCESSING_DETAIL_SUCCESS = "GET_OBJECT_PROCESSING_DETAIL_SUCCESS";
export const GET_OBJECT_PROCESSING_DETAIL_FAILURE = "GET_OBJECT_PROCESSING_DETAIL_FAILURE";

//Save User data when login
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const SAVE_USER_INFO_SUCCESS = "SAVE_USER_INFO_SUCCESS";
export const SAVE_USER_INFO_FAILURE = "SAVE_USER_INFO_FAILURE";

//Get user profile
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

//Manage Notifications
export const SAVE_NOTIFICATION = "SAVE_NOTIFICATION";
export const SAVE_NOTIFICATION_SUCCESS = "SAVE_NOTIFICATION_SUCCESS";
export const SAVE_NOTIFICATION_FAILED = "SAVE_NOTIFICATION_FAILED";

//Clear Notifications
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const CLEAR_NOTIFICATION_SUCCESS = "CLEAR_NOTIFICAITON_SUCCESS";
export const CLEAR_NOTIFICATION_FAILURE = "CLEAR_NOTIFICAITONF_FAILURE";

//Set tooltip
export const ACTIVE_TOOLTIP = "ACTIVE_TOOLTIP";
export const ACTIVE_TOOLTIP_SUCCESS = "ACTIVE_TOOLTIP_SUCCESS";
export const ACTIVE_TOOLTIP_FAILURE = "ACTIVE_TOOLTIP_FAILURE";
export const DEACTIVE_TOOLTIP = "DEACTIVE_TOOLTIP";
export const DEACTIVE_TOOLTIP_SUCCESS = "DEACTIVE_TOOLTIP_SUCCESS";
export const DEACTIVE_TOOLTIP_FAILURE = "DEACTIVE_TOOLTIP_FAILURE";


//Statistic
export const GET_HARVEST_STATISTIC = "GET_HARVEST_STATISTIC";
export const HARVEST_STATISTIC_SUCCESS = "HARVEST_STATISTIC_SUCCESS";
export const HARVEST_STATISTIC_FAILURE = "HARVEST_STATISTIC_FAILURE";

export const GET_BRANCH_STATISTIC = "GET_HARVEST_STATISTIC";
export const BRANCH_STATISTIC_SUCCESS = "BRANCH_STATISTIC_SUCCESS";
export const BRANCH_STATISTIC_FAILURE = "BRANCH_STATISTIC_FAILURE";

//Get list subscription
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_FAILURE = "SUBSCRIPTION_FAILURE";

//Get list plan
export const GET_PLAN_LIST = "GET_PLAN_LIST";
export const GET_PLAN_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS";
export const GET_PLAN_LIST_FAILURE = "GET_PLAN_LIST_FAILURE";
export const CREATE_PLAN = "CREATE_PLAN";

//Sidebar
export const CHECK_STATUS_EXPAND = "CHECK_STATUS_EXPAND"
export const CHECK_STATUS_EXPAND_SUCCESS = "CHECK_STATUS_EXPAND_SUCCESS"
export const CHECK_STATUS_EXPAND_FAILURE = "CHECK_STATUS_EXPAND_FAILURE"

//Render Select Customer at Plan Page
export const RENDER_SELECT_CUSTOMER = "RENDER_SELECT_CUSTOMER"
export const RENDER_SELECT_CUSTOMER_SUCCESSFULL = "RENDER_SELECT_CUSTOMER_SUCCESSFULL"
export const RENDER_SELECT_CUSTOMER_FAILURE = "RENDER_SELECT_CUSTOMER_FAILURE"

//Get box and unit stamp for retail
export const GET_BOX_UNIT = "GET_BOX_UNIT";
export const GET_BOX_AND_UNIT_FAILURE = "GET_BOX_AND_UNIT_FAILURE";
export const GET_BOX_AND_UNIT_SUCCESS = "GET_BOX_AND_UNIT_SUCCESS";

//Un paid list
export const GET_UNPAID_LIST = "GET_UNPAID_LIST";
export const GET_UNPAID_LIST_SUCCESS = "GET_UNPAID_LIST_SUCCESS";
export const GET_UNPAID_LIST_FAILURE = "GET_UNPAID_LIST_FAILURE";

//crop Of Product Object Statistic
export const GET_CROP_STATISTIC = "GET_CROP_STATISTIC";
export const GET_CROP_STATISTIC_SUCCESS = "GET_CROP_STATISTIC_SUCCESS";
export const GET_CROP_STATISTIC_FAILURE = "GET_CROP_STATISTIC_FAILURE";

//get number of accesses
export const GET_NUMBER_ACCESSES = "GET_NUMBER_ACCESSES";
export const GET_NUMBER_ACCESSES_SUCCESS ="GET_NUMBER_ACCESSES_SUCCESS";
export const GET_NUMBER_ACCESSES_FAILURE = "GET_NUMBER_ACCESSES_FAILURE";

//get statis branchs
export const GET_PRODUCT_TO_BRANCHS = "GET_PRODUCT_TO_BRANCHS";
export const GET_PRODUCT_TO_BRANCHS_SUCCESS ="GET_PRODUCT_TO_BRANCHS_SUCCESS";
export const GET_PRODUCT_TO_BRANCHS_FAILURE = "GET_PRODUCT_TO_BRANCHS_FAILURE";

//get statis phanphoi
export const GET_PRODUCT_TO_DISTRIBUTOR = "GET_PRODUCT_TO_DISTRIBUTOR";
export const GET_PRODUCT_TO_DISTRIBUTOR_SUCCESS ="GET_PRODUCT_TO_DISTRIBUTOR_SUCCESS";
export const GET_PRODUCT_TO_DISTRIBUTOR_FAILURE = "GET_PRODUCT_TO_DISTRIBUTOR_FAILURE";

//Get list approve
export const GET_LIST_APPROVE = "GET_LIST_APPROVE"
export const GET_LIST_APPROVE_SUCCESS = "GET_LIST_APPROVE_SUCCESS"
export const GET_LIST_APPROVE_FAILURE = "GET_LIST_APPROVE_FAILURE"

//Get active stamp of product type
export const GET_ACTIVE_STAMP_PRODUCT_TYPE = "GET_ACTIVE_STAMP_PRODUCT_TYPE"
export const GET_ACTIVE_STAMP_PRODUCT_TYPE_SUCCESS = "GET_ACTIVE_STAMP_PRODUCT_TYPE_SUCCESS"
export const GET_ACTIVE_STAMP_PRODUCT_TYPE_FAILURE = "GET_ACTIVE_STAMP_PRODUCT_TYPE_FAILURE"

//Get number crop of product type
export const GET_NUMBER_CROP_PRODUCT_TYPE = "GET_NUMBER_CROP_PRODUCT_TYPE"
export const GET_NUMBER_CROP_PRODUCT_TYPE_SUCCESS = "GET_NUMBER_CROP_PRODUCT_TYPE_SUCCESS"
export const GET_NUMBER_CROP_PRODUCT_TYPE_FAILURE = "GET_NUMBER_CROP_PRODUCT_TYPE_FAILURE"

//Get number stamp active general
export const GET_STAMP_ACTIVE_GENERAL = "GET_STAMP_ACTIVE_GENERAL"
export const GET_STAMP_ACTIVE_GENERAL_SUCCESS = "GET_STAMP_ACTIVE_GENERAL_SUCCESS"
export const GET_STAMP_ACTIVE_GENERAL_FAILURE = "GET_NUMBER_CROP_PRODUCT_TYPE_FAILURE"

//Render employee list
export const RENDER_EMPLOYEE_LIST = "RENDER_EMPLOYEE_LIST"
export const RENDER_EMPLOYEE_LIST_SUCCESS = "RENDER_EMPLOYEE_LIST_SUCCESS"
export const RENDER_EMPLOYEE_LIST_FAILURE = "RENDER_EMPLOYEE_LIST_FAILURE"

//Render production area list
export const RENDER_AREA_LIST = "RENDER_AREA_LIST"
export const RENDER_AREA_LIST_SUCCESS = "RENDER_AREA_LIST_SUCCESS"
export const RENDER_AREA_LIST_FAILURE = "RENDER_AREA_LIST_FAILURE"

//update info of area
export const UPDATE_INFO_AREA = "UPDATE_INFO_AREA"
export const UPDATE_INFO_AREA_SUCCESS = "UPDATE_INFO_AREA_SUCCESS"
export const UPDATE_INFO_AREA_FAILURE = "UPDATE_INFO_AREA_FAILURE"

//Update info product
export const UPDATE_INFO_PRODUCT = "UPDATE_INFO_PRODUCT"
export const UPDATE_INFO_PRODUCT_SUCCESS = "UPDATE_INFO_PRODUCT_SUCCESS"
export const UPDATE_INFO_PRODUCT_FAILURE = "UPDATE_INFO_PRODUCT_FAILURE"

//Update info employee
export const UPDATE_INFO_EMPLOYEE = "UPDATE_INFO_EMPLOYEE"
export const UPDATE_INFO_EMPLOYEE_SUCCESS = "UPDATE_INFO_EMPLOYEE_SUCCESS"
export const UPDATE_INFO_EMPLOYEE_FAILED = "UPDATE_INFO_EMPLOYEE_FAILED"

//Get info about active stamp
export const GET_INFO_ACTIVE_STAMP = "GET_INFO_ACTIVE_STAMP"
export const GET_INFO_ACTIVE_STAMP_SUCCESS = "GET_INFO_ACTIVE_STAMP_SUCCESS"
export const GET_INFO_ACTIVE_STAMP_FAILURE = "GET_INFO_ACTIVE_STAMP_FAILURE"

//Get Stamp list Success
export const GET_STAMP_LIST = "GET_STAMP_LIST"
export const GET_STAMP_LIST_SUCCESS = "GET_STAMP_LIST_SUCCESS"
export const GET_STAMP_LIST_FAILURE = "GET_STAMP_LIST_FAILURE"

//Check status Stamp
export const CHECK_STATUS_STAMP = "CHECK_STATUS_STAMP"
export const CHECK_STATUS_STAMP_SUCCESS = "CHECK_STATUS_STAMP_SUCCESS"
export const CHECK_STATUS_STAMP_FAILURE = "CHECK_STATUS_STAMP_FAILURE"

//Edit Process
export const EDIT_PROCESS = "EDIT_PROCESS";

//Get Object in area
export const GET_OBJECT_IN_AREA = "GET_OBJECT_IN_AREA";
export const GET_OBJECT_IN_AREA_SUCCESS = "GET_OBJECT_IN_AREA_SUCCESS";
export const GET_OBJECT_IN_AREA_FAILURE = "GET_OBJECT_IN_AREA_FAILURE";

//GET_LIST_BOX_OWN
export const GET_LIST_BOX_OWN= "GET_LIST_BOX_OWN";
export const GET_LIST_BOX_OWN_SUCCESS = "GET_LIST_BOX_OWN_SUCCESS";
export const GET_LIST_BOX_OWN_FAILURE ="GET_LIST_BOX_OWN_FAILURE";

//Render select unit stamp
export const RENDER_UNIT_STAMP = "RENDER_UNIT_STAMP";
export const RENDER_UNIT_STAMP_SUCCESS = "RENDER_UNIT_STAMP_SUCCESS";
export const RENDER_UNIT_STAMP_FAILURE = "RENDER_UNIT_STAMP_FAILURE";

//Get stamp in case
export const GET_STAMP_INCASE = "GET_STAMP_INCASE";
export const GET_STAMP_INCASE_SUCCESS = "GET_STAMP_INCASE_SUCCESS";
export const GET_STAMP_INCASE_FAILURE = "GET_STAMP_INCASE_FAILURE";

//Get list cow
export const GET_LIST_COW = "GET_LIST_COW";
export const GET_LIST_COW_SUCCESS = "GET_LIST_COW_SUCCESS";
export const GET_LIST_COW_FAILURE = "GET_LIST_COW_FAILURE";

//Filter list cow
export const GET_FILTER_LIST_CATTLE = "GET_FILTER_LIST_CATTLE";
export const GET_FILTER_LIST_CATTLE_SUCCESS = "GET_FILTER_LIST_CATTLE_SUCCESS";
export const GET_FILTER_LIST_CATTLE_FAILURE = "GET_FILTER_LIST_CATTLE_FAILURE";
export const CLEAN_DATA_TABLE_COW = "CLEAN_DATA_TABLE_COW"