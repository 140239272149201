import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// react component for creating dynamic tables

import { withRouter } from 'react-router-dom';
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";



// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Accordion from 'components/Accordion/Accordion.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { LINK_API } from '../../../../constants/API';
import ImageUpload from "../RetailPage/ImageCustorm/ImageUpload.jsx"
import SweetAlert from "react-bootstrap-sweetalert";
import { IMG_LOAD } from 'constants/index.js';

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import SelectModern from 'react-select'
import strings from "../../../../constants/strings";
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";
const LZUTF8 = require('lzutf8');
const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,

      name: "",
      nameState: "",

      description: "",
      descriptionState: "",

      city: "",
      cityState: "",

      district: "",
      districtState: "",

      gln: "",
      glnState: "",

      image: "",

      checked: [],

      destime: ["Vườn", "Nhà kính", "Khu chế biến", "Khu đóng gói","Trang trại", "Lò mổ", "Kho chứa"],
      type: "",
      type_temp: "",

      search: "",
      value: "",
      options:[],
      employee: []
    };
    this.handleChange = this.handleChange.bind(this)
    this.onImageSubmit = this.onImageSubmit.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.hideAlert_success = this.hideAlert_success.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.checkPermission = this.checkPermission.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
    this.handleNoResult = this.handleNoResult.bind(this);
    this.handleStatusUpdate = this.handleStatusUpdate.bind(this);
    this.handleChange_Select = this.handleChange_Select.bind(this);
  }
  async componentDidMount() {
    await this.checkPermission()
    this.props.renderEmp();
  }
  componentWillReceiveProps(preProps){
    if(this.props.empList !== preProps.empList){
      if(preProps.empList.data){
        this.setState({
          options: preProps.empList.data
        })
      }
    }
  }
  handleChange_Select = async (selectedOption, event) => {
    let temp = this.state.employee;
    if(selectedOption.length > 0){
      temp.push(selectedOption[selectedOption.length - 1].value)
    }
    await this.setState({
      employee: temp
    })
  }
  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      return this.props.history.push("/");
    }
  }


  handleCooperativeGlnChange(value) {
    if (!this.verifyLength(value, 1)) {
      this.setState({
        cooperativeGlnState: "error",
        cooperativeGln:"",
        cooperativeGlnhelp: "Thiếu mã htx"
      })
      return;
    }
    else {
      this.setState({
        cooperativeGlnState: "success",
        cooperativeGlnhelp: "",
        cooperativeGln: value
      })
    }
  }


  handleInputChange = e => {
    this.setState({ search: e.target.value, value: e.target.value })
  }

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    //console.log(geocodedPrediction.formatted_address, originalPrediction) // eslint-disable-line
    this.setState({
      city: geocodedPrediction.formatted_address,
      search: "",
    })
    //this.setState({ search: "", value: geocodedPrediction.formatted_address })
  }
  handleSelectSuggest_district = (geocodedPrediction, originalPrediction) => {
    //console.log(geocodedPrediction.formatted_address, originalPrediction) // eslint-disable-line
    this.setState({
      district: geocodedPrediction.formatted_address,
      search_district: ""
    })
    //this.setState({ search: "", value: geocodedPrediction.formatted_address })
  }
  handleNoResult = () => {
    console.log('Không có kết quả', this.state.search)
  }

  handleStatusUpdate = (status) => {
    console.log(status)
  }
  async onImageSubmit(file) {
    // console.log(file);
    if (file != null) {
      await this.setState({
        image: file
      })
    }
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    if (this.state.checked[0] === 1) {
      //document.getElementById("noti").innerHTML = "";
      document.getElementById("glnchecked").style = "display: block; width: 100%; margin-top: -27px;"
    } else {
      document.getElementById("glnchecked").style = "display: none"
    }
  }
  handleChange = name => async event => {
    if(name==="city"){
      this.setState({ search: event.target.value, })
    }
    if(name==="district"){
      this.setState({ search_district: event.target.value, })
    }
  
    await this.setState({
      [name]: event.target.value,
    });
    if (name === "name") {
      if (this.state.name) {
        document.getElementById("noti").innerHTML = "";
        this.setState({
          nameState: "success"
        })
      } else {
        this.setState({
          nameState: "error"
        })
      }
    }
    if (name === "description") {
      if (this.state.description) {
        document.getElementById("noti").innerHTML = "";
        this.setState({
          descriptionState: "success"
        })
      } else {
        this.setState({
          descriptionState: "error"
        })
      }
    }
    if (name === "city") {
      if (this.state.city) {
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cityState: "success"
        })
      } else {
        this.setState({
          cityState: "error"
        })
      }
    }
    if (name === "district") {
      if (this.state.district) {
        document.getElementById("noti").innerHTML = "";
        this.setState({
          districtState: "success"
        })
      } else {
        this.setState({
          districtState: "error"
        })
      }
    }
    if (this.state.checked[0] === 1) {
      //document.getElementById("noti").innerHTML = "";
      if (name === "gln") {
        if (this.state.gln) {
          document.getElementById("noti").innerHTML = "";
          this.setState({
            glnState: "success"
          })
        } else {
          this.setState({
            glnState: "error"
          })
        }
      }
    }
  };
  async  handleSelect(event) {
    if (event.target.value === "Vườn") {
      await this.setState({ type: "Vườn", type_temp: 0 });
    }
    if (event.target.value === "Nhà kính") {
      await this.setState({ type: "Nhà kính", type_temp: 1 });
    }
    if (event.target.value === "Khu chế biến") {
      await this.setState({ type: "Khu chế biến", type_temp: 2 });
    }
    if (event.target.value === "Trang trại") {
      await this.setState({ type: "Trang trại", type_temp: 3 });
    }
    if (event.target.value === "Lò mổ") {
      await this.setState({ type: "Lò mổ", type_temp: 4 });
    }
    if (event.target.value === "Khu đóng gói") {
      await this.setState({ type: "Khu đóng gói", type_temp: 5 });
    }
    if (event.target.value === "Kho chứa") {
      await this.setState({ type: "Kho chứa", type_temp: 6 });
    }
    document.getElementById("noti").innerHTML = "";
  }
  
  submitData() {
    if (!this.state.name) {
      this.setState({
        nameState: "error"
      })
      document.getElementById("noti").innerHTML = "Vui nhập tên gợi nhớ";
      return;
    }
    if (!this.state.city) {
      this.setState({
        cityState: "error"
      })
      document.getElementById("noti").innerHTML = "Vui lòng nhập địa chỉ";
      return;
    }
    // if (!this.state.district) {
    //   this.setState({
    //     districtState: "error"
    //   })
    //   document.getElementById("noti").innerHTML = "Vui nhập Quận/Huyện";
    //   return;
    // }
    if (this.state.checked[0] === 1) {
      if (!this.state.gln) {
        document.getElementById("noti").innerHTML = "Vui lòng nhập mã GLN";
        this.setState({
          glnState: "error"
        })
        return;
      }
    }
    if (!this.state.type) {
      document.getElementById("noti").innerHTML = "Vui lòng chọn loại vùng sản xuất"; return;
    }
    // if(this.state.description===""){
    //   this.setState({
    //     description: "Chưa có"
    //   })
    // }
    const data = {
      "name": this.state.name,
      "gln": this.state.gln,
      "image": LZUTF8.compress(this.state.image, { outputEncoding: 'Base64' }),
      "type": this.state.type_temp,
      "city": this.state.city,
      "managerId": this.state.employee,
      "district": "",
      "glnCheckbox": (this.state.checked[0] === 1) ? true : false,
      "description": (this.state.description === "") ? "Chưa có" : this.state.description
    }
    // this.onSuccess(data)
    this.props.createArea(data);
    this.props.history.push("/admin/areas/list");
  }
 
  hideAlert_success() {
    this.setState({
      alert: null
    })
    this.props.history.push("/admin/areas/list")
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }



  render() {
    const { classes } = this.props;
    return (
      <div>
        <div id="info1">
          <GridContainer>
            <GridItem xs={8} lg={8} md={8} sm={8}>
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      {/* <CardIcon color="success">
                        <People />
                      </CardIcon> */}
                      <h4 className="cardtableLabel">{strings.areaInfo}</h4>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText={strings.nameArea}
                    success={this.state.nameState === "success"}
                    error={this.state.nameState === "error"}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      onChange: this.handleChange('name'),
                    }}
                    inputProps={{
                      value: this.state.name,
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Icon className={classes.inputAdornmentIcon}>
                            home
                              </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ReactGoogleMapLoader
                    params={{
                      key: MY_API_KEY,
                      libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                      googleMaps && (
                        <div>
                          <ReactGooglePlacesSuggest
                            autocompletionRequest={{ input: this.state.search }}
                            googleMaps={googleMaps}
                            onSelectSuggest={this.handleSelectSuggest.bind(this)}
                            displayPoweredByGoogle={false}
                          >
                            <CustomInput
                              labelText={strings.address}
                              success={this.state.cityState === "success"}
                              error={this.state.cityState === "error"}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handleChange('city'),
                              }}
                              inputProps={{
                                value: this.state.city,
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon className={classes.inputAdornmentIcon}>
                                    location_on
                              </Icon>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </ReactGooglePlacesSuggest>
                        </div>
                      )
                    }
                  />
                   {/* <ReactGoogleMapLoader
                    params={{
                      key: MY_API_KEY,
                      libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                      googleMaps && (
                        <div>
                          <ReactGooglePlacesSuggest
                            autocompletionRequest={{ input: this.state.search_district }}
                            googleMaps={googleMaps}
                            onSelectSuggest={this.handleSelectSuggest_district.bind(this)}
                            displayPoweredByGoogle={false}
                          >
                            <CustomInput
                              labelText="Địa chỉ"
                              success={this.state.districtState === "success"}
                              error={this.state.districtState === "error"}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handleChange('district'),
                              }}
                              inputProps={{
                                value: this.state.district,
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon className={classes.inputAdornmentIcon}>
                                      location_on
                              </Icon>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </ReactGooglePlacesSuggest> 
                        </div>
                      )
                    }
                  />*/}
                <div style={{width: "100%"}}>
                  <p className="titleCreate">{strings.empInCharge}</p>
                <SelectModern
                options={this.state.options}
                isMulti
               // value={this.state.clientId_label}
                onChange={this.handleChange_Select}
                // className={classes.SelectDrop}
                placeholder={strings.enterEmpId}
              />
                </div>
                  <div style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="product-expired-type"
                        className={classes.selectLabel}
                      >
                        {strings.typeArea}
                              </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        id='select'
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.type}
                        onChange={this.handleSelect}
                        inputProps={{
                          name: "type",
                          id: "type"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {strings.chooseType}
                        </MenuItem>
                        {this.state.destime.map((x, idx) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={x}
                            key={idx}
                          >
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ width: '100%'}}>
                    <Accordion
                      // active={1}
                      collapses={[
                        {
                          title: strings.moreInfoArea,
                          content: (
                            <form style={{ width: "100%" }}>
                              <CustomInput
                                labelText= {strings.description}
                                success={this.state.descriptionState === "success"}
                                error={this.state.descriptionState === "error"}
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                  onChange: this.handleChange('description'),
                                }}
                                inputProps={{
                                  value: this.state.description,
                                  multiline: true,
                                  rowsMax: "3",
                                  rows: 3,
                                  autoComplete: "off",
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <Icon className={classes.inputAdornmentIcon}>
                                        list
                              </Icon>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </form>
                          )
                        }
                      ]}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label={strings.regisGLN}
                    />
                  </div>
                  <div style={{ marginTop: "-35px", display: "none" }} id="glnchecked">
                    <CustomInput
                      success={this.state.glnState === "success"}
                      error={this.state.glnState === "error"}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                        onChange: this.handleChange('gln'),
                      }}
                      inputProps={{
                        value: this.state.gln,
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              code
                              </Icon>
                          </InputAdornment>
                        ),
                        placeholder: strings.gln
                      }}
                    />

                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <p
                      id="noti"
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '400',
                        color: 'red',
                        fontFamily: '"Muli',
                        marginTop: 5,
                        marginBottom: 5,
                      }}>
                    </p>
                  </div>
                  <Button color="success" size="sm" onClick={this.submitData.bind(this)}>{strings.create}</Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={4} lg={4} md={4} sm={4}>
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      {/* <CardIcon color="success">
                        <People />
                      </CardIcon> */}
                      <h4 className="cardtableLabel">{strings.image}</h4>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody style={{ display: "flex", justifyContent: "center" }}>
                  <ImageUpload onImageSubmit={this.onImageSubmit} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    createArea: (data) => dispatch({ type: "CREATE_AREA", data: data }),
    renderEmp: () => dispatch({type: "RENDER_EMPLOYEE_LIST"})
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
           empList: state.renderEmp }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
