import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    // alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop:"200px",
  },
  qrFrame : {
    border: "none"
  }
});

class Scan extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 500,
      result: 'Đưa camera hướng về phía mã QR của sản phẩm',
    }

    this.handleScan = this.handleScan.bind(this)
  }
  handleScan(result){
    if(result){
      //console.log(result);
    this.props.onResult(result)
     }
  }

  handleError(err){
    console.error(err)
  }
  render(){
    const { classes } = this.props;
    const previewStyle = {
      height: 'auto',
      width: "320px",
      margin: 'auto',
      border: "none",
    }

    return(
      <div className = {classes.root} >
        <QrReader
            facingMode='environment'
            delay={this.state.delay}
            style={previewStyle}
            onError={this.handleError}
            onScan={this.handleScan}
            className = {classes.qrFrame}
        />
        <p style={{textAlign:'center', fontFamily: "Muli",color:"black", fontSize:"15px"}}>{this.state.result}</p>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(Scan));