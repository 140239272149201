const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px"
  },
  cardHeaderColor: {
    background: "#20201A !important",
    boxShadow:
    "0 12px 0px -10px rgba(140, 140, 140, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(70, 70, 70, 0.2) !important",
    padding: "0px 15px !important"
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important"
  },
  displayNone: {
    display: "none !important"
  },
  tabsRoot: {
    minHeight: "unset !important",
    "& $tabRootButton": {
      fontSize: "0.875rem"
    }
  },
  tabRootButton: {
    outline:"0 !important",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 15px",
    borderRadius: "3px",
    lineHeight: "24px",
    border: "0 !important",
    color: "#5a5a5a !important",
    marginLeft: "4px",
    "&:last-child": {
      marginLeft: "0px"
    },
    "&:hover,&:focus": {
      outline: 0,
      boxShadow: "none!important",
    },
  },
  tabLabelContainer: {
    padding: "0px"
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "14px",
    textAlign: "center",
    color: "#5a5a5a",
  },
  tabSelected: {
    // backgroundColor: "rgba(255, 255, 255, 0.2)",
    outline:"0 !important",
    border: "0px  !important",
    borderRadius: "1px  !important",
    borderBottom: "2px solid #000 !important",
    transition: "0.2s background-color 0.1s",
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0",
      color:"#5a5a5a"
    }
  }
};

export default customTabsStyle;

