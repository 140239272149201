import React from "react";
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
// import MailOutline from "@material-ui/icons/MailOutline";
import People from "@material-ui/icons/People";
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/DoneAll";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CreateFarm from "./CreateAccount";
import { connect } from 'react-redux';
import {API, IMG_LOAD} from 'constants/index.js';
import {LINK_API} from '../../../../constants/API'; 

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import {tooltip} from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Register from "../../../AuthPage/Register"
import responseCode from "constants/errorCode"
const ethers = require('ethers');
const CryptoJS = require("crypto-js");
//BlockChain
const EthereumTx = require('ethereumjs-tx').Transaction;



const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  paperwizard: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
    fullWidth:"true",
    maxWidth : 'md'
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class AccountManager extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlert1 = this.hideAlert1.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.renderTrigger = this.renderTrigger.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.handleprocess = this.handleprocess.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    const queryParams = new URLSearchParams(this.props.location.search); 
    const openCreateAcount = (queryParams.get('showCreateDialog') === "true")? true:false;

    this.state = {
      openCreateAcount:openCreateAcount,
      alert:null,
      checked:false,
      value:{
        gln_extend:'',
        passphrase:'',
        data:'',
      },
      cooperatives: [],
      data: [],
      load:"",
      stepswizard:[],
      showTable:"none",
      processdata:"",
      processrawdata:"",
      processtitle:"",
      userBcAddress: "",
      userUuid: ""
    };

 
  }
  async handleSubscribe(data) {
    await this.setState({
      pass_save : data.pw_hash
    })
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    const path_get = await axios.get(API.LINK_API.GET_PATH_USER);
    await this.setState({
      pathCount: path_get.data.data.countMasterAccount.toString()
    })
    let wallet = ethers.Wallet.createRandom();
    let randomMnemonic = wallet.mnemonic;
    let walletPath = {
      "standard": "m/44'/60'/0'/" + this.state.pathCount +"/0",
    };
    let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
    let node = hdnode.derivePath(walletPath.standard);

    const accountAddress = node.address;
    const privatekey = node.privateKey;

    const privatekey_slice = privatekey.substring(2,privatekey.length);
    const encryptedSeed = await CryptoJS.AES.encrypt(randomMnemonic, data.pw_hash);
    const encryptedPrivateKey = await CryptoJS.AES.encrypt(privatekey, data.pw_hash);
    var temp = new Buffer(data.pw_hash).toString('hex')
    const encryptedPass = await ethers.utils.keccak256('0x' + temp);
    data.pw_hash = encryptedPass; //pass hash
    data.privateEncrypted = encryptedPrivateKey.toString(); // encrypted privatekey
    data.seedEncrypted = encryptedSeed.toString(); // encrypted seed
    data.bcAddress = accountAddress; // address of account on bc
    const login_data = data;
    data.currentIndexPath = this.state.pathCount; // index path of account
    axios.post(API.LINK_API.REGISTER, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-200px" }}
                title="Thành công"
                onConfirm={() => this.handleSignIn(login_data)}
                onCancel={() => this.hideAlert1()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Đăng nhập"
                cancelBtnText="Đóng"
                showCancel
                showConfirm={false}
              ></SweetAlert>
            )
          });
           const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
          const tx = new EthereumTx(response.data.data.raw, { chain: 4 });
        await tx.sign(Buffer_privatekey);
         const rawTx = '0x' + tx.serialize().toString('hex');
         const raw_to_send = {
          "raw": rawTx,
          "sign": "none",
          "date": "none",
          "bcAddress": accountAddress,
          "uuid": response.data.data.uuid,
          "nonce": "none"
         }
         axios.post(API.LINK_API.SEND_RAW, raw_to_send)
         .then(response => {
         })
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block", marginTop: "-200px" }}
                title="Không thành công!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Đăng nhập"
                cancelBtnText="Đóng"
                showConfirm={false}
                showCancel
              ></SweetAlert>
            )
          });
        }
      }).catch(async (error) => {
        console.log(error)
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-200px" }}
              title={`${responseCode[error.response.data.name].vi}`}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Đăng nhập"
              cancelBtnText="Đóng"
              showConfirm={false}
              showCancel
            ></SweetAlert>
          )
        });
      });
  }
  handleprocess = (data) => {
    this.setState({
      openCreateAcount: false,
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          onConfirm={() => this.handleSubscribe(data)}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          {/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
        </SweetAlert>,
    })


  }
  handleToggle() {
    const { checked } = this.state;

    if (checked) {
      this.setState({
        checked: false
      });
    }
    else {
      this.setState({
        checked: true
      });
    }

  }

  renderTrigger() {
    return <Button color = "success">In Mã</Button>;
  }
  
  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }

  setRef = (ref) => {
    this.componentRef = ref;
  }

  hideAlert_claimed = async () => {
    await this.setState({
      alert: null,
    })
    var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privatekey = bytes.toString(CryptoJS.enc.Utf8);
  
    const privatekey_slice = privatekey.substring(2,privatekey.length)
    const Buffer_privatekey =   await Buffer.from(privatekey_slice.toString(), 'hex');
    // await this.setState({
    //   Buffer_privatekey: Buffer_privatekey
    // })

    const tx_claim = new EthereumTx(this.state.tx, { chain: 4 });
    tx_claim.sign(Buffer_privatekey);
    const rawTx_claim = '0x' + tx_claim.serialize().toString('hex');
    const data_send = {
      "raw": rawTx_claim,
      // "bcAddress": this.props.userInfo.data.bcAddress,
      // "uuid": this.props.userInfo.data.uuid
      "bcAddress": this.state.userBcAddress,
      "uuid": this.state.userUuid
    }
    axios.post(LINK_API.SEND_RAW_CLAIM, data_send)
    .then( response => {
      if (response.data.errorCode === 1) {
        this.setState({
         alert: (
           <SweetAlert
             success
             style={{ display: 'block' }}
             title="Thành công"
             closeOnClickOutside= {false}
             onCancel={() => this.hideAlert1()}
             onConfirm={() => this.hideAlert1()}
             showCancel={false}
           />
         )
       });
     }else{
       this.setState({
         alert: (
           <SweetAlert
             error
             style={{ display: 'block' }}
             title="Không thành công"
             onConfirm={() => this.hideAlert()}
             onCancel={() => this.hideAlert()}
             confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
           />
         )
       });
     }

    })
    .catch(err => {
      console.log(err)
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block' }}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          />
        )
      });
    })
  }

  verifyAccount = (uuid, bcAddress) => {
    this.setState({
      alert:  <SweetAlert
      warning
      style={{ display: "block" }}
      title="Đang xử lý!"
      onConfirm={() => this.hideAlert()}
      onCancel={() => this.hideAlert()}
      confirmBtnCssClass={
        this.props.classes.button + " " + this.props.classes.success
      }
      showConfirm={false}
    >
      <div style={{ textAlign: "center" }}>
        <img
          alt="{name}"
          src={IMG_LOAD}
          style={{ height: "100px" }}
        />
      </div>
    </SweetAlert>
    })
    const data = {
      "uuid": uuid
    }
    axios.post(LINK_API.GET_RAW, data)
    .then( async response => {
      if(response.data.errorCode === 1){
        await this.setState({
          tx: response.data.data.raw,
          userBcAddress: bcAddress,
          userUuid: uuid
        })
        // await this.activeAccount()
        await this.hideAlert_claimed()
      }
    })
    .catch(err => {
      console.log(err)
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block' }}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          />
        )
      });
    })
  }



  genTable = (cooperatives) => {
      
      this.setState({data: cooperatives.map((prop, key) => {
        let loai = ""
        if(prop.role === "M"){
          loai = "Nhà sản xuất";
        }
        else if(prop.role === "F"){
          loai = "Nhà phân phối";
        }
        else if(prop.role === "R"){
          loai = "Nhà bán lẻ";
        }
        else if(prop.role === "D"){
          loai = "Nhà vận chuyển";
        }
        return {
          id: key+1,
          user_name: prop.name,
          type: loai,
          createAt: moment(prop.createAt).format('DD/MM/YYYY'),
          gln:prop.uuid,
          email: prop.email,
          website: prop.website,
          phone: prop.phone,
          tx_id: prop.tx_id,
          actions: (
            // we've added some custom button actions
            <div className="actions-center">
              {/* <Tooltip
                id="tooltip-top"
                title="Xem chi tiết"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    //Show detail dialog
                  }}
                  color="info"
                  className="like"
                >
                  <Detail />
                </Button>
              </Tooltip> */}
              <Tooltip
                id="tooltip-top"
                title="Tra cứu Blockchain"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    window.open("https://blockchain.agridential.vn/tx/" + prop.txId);
                  }}
                  color="warning"
                  // className="like"
                >
                  <Search />
                </Button>
              </Tooltip>
              {(prop.verifiedTxId!=="" && !prop.active)?(
                <Tooltip
                id="tooltip-top1"
                title="Kích hoạt tài khoản"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={()=> this.verifyAccount(prop.uuid, prop.bcAddress)}
                  color="success"
                  className="like"
                >
                  <Check />
                </Button>
              </Tooltip>
              ):(
                <Tooltip
                id="tooltip-top1"
                title=""
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  // onClick={()=> {}}
                  color="success"
                  className="like"
                >
                  {/* <Check /> */}
                </Button>
              </Tooltip>
              )}
              
              {/* <Tooltip
                id="tooltip-top"
                title="Khóa nông hộ"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {}}
                  color="danger"
                  className="like"
                >
                  <Delete />
                </Button>
              </Tooltip> */}
            </div>
          )
        };
      }),
      load:"none",
      showTable:""
    });
  }

  async checkPermission() {
		try {
			let response = await axios.get(LINK_API.VERIFY_TOKEN, {
				headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
			});
			if (response.data.errorCode !== 1 || !response.data.data.valid) {
				await cookie.remove('token');
				return this.props.history.push('/');
			} else {
				axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
			}
		} catch (error) {
			console.log(error);
			await cookie.remove('token');
			return this.props.history.push('/');
		}
	}

  getCooperatives = async ()  => {
    try {
      let response = await axios.get(LINK_API.GET_COOPERATIVES);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      let cooperatives = await this.getCooperatives();
      this.setState({cooperatives: cooperatives});
      this.genTable(cooperatives);
    }
    catch (error) {
      console.log(error)
    }
  }

  async onSuccess(data) {

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block"}}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{textAlign:"center"}}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{height:"100px"}}
            />
          </div>
        </SweetAlert>
      )
    });
    
    // let that=this;

    axios.post(LINK_API.REGISTER, data)
    .then( async response => {
      if (response && response.data && response.data.errorCode === 1) {
      let  data = response.data.data
      let cooperatives = this.state.cooperatives
      cooperatives.push(data)
      this.genTable(cooperatives)

      await this.setState({
        //cooperatives: cooperatives,
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-270px" }}
            title="Thành Công!"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            Thành công
          </SweetAlert>
        )
      });
      return
    }
    throw response
    })
    .catch(err => {
      console.log(err);
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            Tạo tài khoản thất bại
          </SweetAlert>
        )
      });
    });
    
  }

  async hideAlert1(){
    this.setState({
      alert: null
    })
    let cooperatives = await this.getCooperatives();
    this.setState({cooperatives: cooperatives, alert: null});
    this.genTable(cooperatives);
  }
  
  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showConfirmAlert(data){
    this.handleClose(); 
    this.setState({
      openCreateAcount: false,
      alert:
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Bạn đã kiểm tra kỹ các thông tin?"
            onConfirm={() => this.onSuccess(data)}
            onCancel={() => {
              this.hideAlert();
              this.setState({openCreateAcount:true});
            }}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Tạo mới"
            cancelBtnText="Làm lại"
            showCancel
          >
        </SweetAlert>,
    })
  }

  handleNewClick() {
    this.setState({openCreateAcount: true});
  }

  handleClose() {
    this.setState({openCreateAcount: false});
  }
  makePlaceholderFilter(placeholder) {
    return ({filter, onChange}) => (
        <input type='text'
          placeholder={placeholder}
          style={{
            width: '100%'
          }}
          onChange={event => onChange(event.target.value)} 
          value={filter ? filter.value : ''}
        />
      )
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {6} md = {6} sm = {6} xs = {12}>
                  {/* <CardIcon color="success">
                    <People />
                  </CardIcon> */}
                  <h4 className="cardtableLabel">Danh sách tài khoản đã tạo</h4>
                </GridItem>
                <GridItem lg = {6} md = {6} sm = {6} xs = {12}>
                  <GridContainer justify = "flex-end" style = {{paddingRight:"25px", paddingTop: "10px" }}>
                      <Button color = "success" size="sm" onClick = {this.handleNewClick}> <Add style = {{margin:"0px",marginRight:"5px"}}/> Tạo Mới </Button>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <div style={{textAlign:"center",display:this.state.load}}>
                <img
                  alt="{name}"
                  src={IMG_LOAD}
                  style={{height:"100px"}}
                />
              </div>
              <ReactTable
                data={this.state.data}
                filterable
                // defaultPageSize={5}
                previousText = "Trang Trước"
                nextText = "Trang Sau"
                rowsText = "hàng"
                ofText = "/"
                pageText = "Trang"
                noDataText = "Nhấn nút TẠO MỚI để tạo tài khoản mới!"
                columns={[
                  {
                    Header: "#",
                    accessor: "id",
                    filterable:false,
                    maxWidth:70,
                    className:"center",
                    Header: () => (
                      <div style={{
                          textAlign: "center"
                      }}>
                          #
                        </div>
                  )
                  },
                  {
                    Header: "Tên doanh nghiệp",
                    accessor: "user_name",
                    Filter: this.makePlaceholderFilter("Nhập tên tài khoản"),
                    filterable: false
                  },
                  {
                    Header: "Mã doanh nghiệp",
                    accessor: "gln",
                    Filter: this.makePlaceholderFilter("Nhập tên tài khoản"),
                    filterable: false
                  },
                  {
                    Header: "Loại doanh nghiệp",
                    accessor: "type",
                    Filter: this.makePlaceholderFilter("Nhập tên tài khoản"),
                    filterable: false
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                    Filter: this.makePlaceholderFilter("Nhập loại quy trình"),
                    filterable: false
                  },
                  {
                    Header: "Số điện thoại",
                    accessor: "phone",
                    Filter: this.makePlaceholderFilter("Nhập loại quy trình"),
                    filterable: false
                  },
                  {
                    Header: "Thời gian tạo",
                    accessor: "createAt",
                    Filter: this.makePlaceholderFilter("Nhập thời gian"),
                    filterable: false
                  },
                  {
                    Header: "Tác Vụ",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={false}
                className="-striped -highlight"
                style={{display:this.state.showTable}}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Dialog
        open={this.state.openCreateAcount}
        onClose={this.handleClose}
        PaperProps ={{
          classes: {
          root: classes.paper
          }
        }}
      >
      {/* <CreateFarm onConfirm = {this.showConfirmAlert}/> */}
        <Register onRegister={this.handleprocess} />
      </Dialog>
      {this.state.alert}
      </div>
    );
  }
}

// export default withStyles(style)(withRouter(AccountManager));
const mapStateToProps = (state) => {
	return { userInfo: state.userInfo,
			 userProfile: state.userProfile}
  }
const  mapDispatchToProps = (dispatch) => {
	return {
	  createEmployee: (data, image) => dispatch({type: "CREATE_EMPLOYEE", data: data, avatar: image})
	}
  }
export default withStyles(style)(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountManager)));
