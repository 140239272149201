import React from 'react';

import { createStore } from "redux"; //an import from the redux library
// an incomplete solution - for now.

import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import DashBoard from 'layouts/DashBoard';
import ScanPage from 'layouts/ScanPage';
import LoginPage from "./layouts/LoginPage/loginpage.jsx"
import RegisterTrial from './layouts/Trial';
import Trial from './layouts/Trial/demo';
import Introduction from './layouts/Trial/Introduction';
import ProfilePage from './layouts/DashBoard/components/Forms/EditProfileForm';
import ChangePw from './layouts/DashBoard/components/Forms/ChangePwdForm';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from 'store/store.js';
import PricingPage from "./layouts/PricingPage";
import RegisterPlan from "./layouts/ActivePage";
class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      access: true
    }
    let url = window.location.href
    let n = url.search("credential.vn");
    if(n>0){
      this.setState({
        access: false
      })
    }
  }
  render() {
    return (
      <Provider store={store}>
        {/* <PersistGate loading={<LoadingView />} persistor={persistor}> */}
        <PersistGate persistor={persistor}>
           <Router>
           <Switch>
           {this.state.access === true ?
             <Route exact path="/" component={LoginPage} />: null}
               {this.state.access === true ?
             <Route exact path="/auth" component={LoginPage} />:null}
             <Route path="/admin/dashboard" component={DashBoard} />
             <Route path="/admin/farms" component={DashBoard} />
             <Route path="/admin" component={DashBoard} />
             <Route path="/admin/products" component={DashBoard} />
             <Route path="/admin/diaries" component={DashBoard} />
             <Route path="/admin/adminonly" component={DashBoard} />
             <Route path="/admin/retailer" component={DashBoard} />
             <Route path="/admin/distributor" component={DashBoard} />
             <Route path="/admin/provider" component={DashBoard} />
             <Route path="/admin/order" component={DashBoard} />
             <Route path="/admin/profile" component={ProfilePage} />
             <Route path="/admin/changepassword" component={ChangePw} />
             <Route path="/login" component={LoginPage} />
             <Route path="/admin/retailer/profile" component={ProfilePage} />
             <Route path="/admin/provider/profile" component={ProfilePage} />
             <Route path="/admin/products/profile" component={ProfilePage} />
             <Route path="/admin/diaries/profile" component={ProfilePage} />
             <Route path="/admin/dashboard/profile" component={ProfilePage} />
             <Route path="/admin/farms/profile" component={ProfilePage} />
             <Route path="/admin/farms/changepassword" component={ChangePw} />
             <Route path="/admin/retailer/changepassword" component={ChangePw} />
             <Route path="/admin/provider/changepassword" component={ChangePw} />
             <Route exact path="/scan" component={ScanPage} />
             <Route exact path="/trial/register" component={RegisterTrial} />
             <Route exact path="/trial/create" component={Trial} />
             <Route exact path="/trial/intro" component={Introduction} />
             <Route exact path="/trial" component={Introduction} />
             <Route exact path="/demo" component={Introduction} />
             <Route exact path="/choose/package" component={PricingPage} />
             <Route path='*' exact={true} component={LoginPage} />
           </Switch>
         </Router>
       
        </PersistGate>
      </Provider>
    )
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired
}

export default App