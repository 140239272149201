import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// react component for creating dynamic tables

import { withRouter } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Accordion from 'components/Accordion/Accordion.jsx';
import InputAdornment from "@material-ui/core/InputAdornment";
import {LINK_API} from '../../../../constants/API'; 
import ImageUpload from "../RetailPage/ImageCustorm/ImageUpload.jsx"
import SweetAlert from "react-bootstrap-sweetalert";
import {IMG_LOAD} from 'constants/index.js';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import {tooltip} from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import deburr from 'lodash/deburr';
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import strings from "../../../../constants/strings";
const LZUTF8 = require('lzutf8');
const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  fixCardRemove:{
    display: "flex",
    justifyContent:"center",
    padding: "22px 5px !important"
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  },
  paddingbody:{
    padding: "10px 27px !important"
  }
};

class TableFarmList extends React.Component {
   constructor(props) {
    super(props)
    this.user_gln = cookie.load("gln");
    this.role = cookie.load("checkrole");
    this.state = {
        name: "",
        nameState: "",

        description: "",
        descriptionState: "",

        city: "",
        cityState: "",

        district: "",
        districtState: "",

        gln: "",
        glnState: "",

        image: "",

        checked: [],
        overviews: [],
        destime: [ "Lô/mẻ sản xuất"],
        type: "Lô/mẻ sản xuất",
        type_temp: 4,

        area: [],
        areagln: "",
        areagln_temp: "",

        product_type: [],
        productgtin: "",
        productgtin_temp: "",

        processlist: [],
        processcode: "",
        processcode_temp: "",
    };
    this.token = cookie.load('token');
    this.user_gln = cookie.load("gln");
   this.handleChange = this.handleChange.bind(this);
   this.onImageSubmit = this.onImageSubmit.bind(this);
   this.handleToggle = this.handleToggle.bind(this);
   this.handleSelect = this.handleSelect.bind(this);
   this.getArea = this.getArea.bind(this);
   this.getProcess = this.getProcess.bind(this);
   this.getProductype = this.getProductype.bind(this);
   this.handleSelect_area = this.handleSelect_area.bind(this);
   this.handleSelect_process = this.handleSelect_process.bind(this);
   this.handleSelect_product = this.handleSelect_product.bind(this);
   this.handleAddOverviews = this.handleAddOverviews.bind(this);
   this.hideAlert = this.hideAlert.bind(this)
   this.handleRemoveInput = this.handleRemoveInput.bind(this)
   this.handleSelect_material = this.handleSelect_material.bind(this)
  }
  
  async getArea(){
    axios.get(LINK_API.GET_AREA_LIST)
    .then( async response => {
      if(response.data.errorCode === 1){
        const data = response.data.data;
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].name + " - " + data[i].uuid;
          result.push(temp)
        }
        this.setState({
          area: result
        })
      }
    })
    .catch(err => {
      console.log(err);
    });
  }
  async getProductype(){
    axios.get(LINK_API.CREATE_PRODUCT)
    .then( async response => {
      if(response.data.errorCode === 1){
        const data = response.data.data;
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].name + " - " + data[i].uuid;
          result.push(temp)
        }
        this.setState({
          product_type: result
        })
      }
    })
    .catch(err => {
      console.log(err);
    });
  }
  async getProcess(){
    await axios.get(LINK_API.GET_PROCESS_LIST)
    .then( async response => {
      if(response.data.errorCode === 1){
        const data = response.data.data;
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].processName + " - " + data[i].bizProcessCode;
          result.push(temp)
        }
        this.setState({
          processlist: result
        })
      }
    })
    .catch(err => {
      console.log(err);
    });
  }
  async componentDidMount(){
    await this.checkPermission();
    this.getArea();
    this.getProductype();
    this.getProcess();
  }
  async checkPermission(){
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN,  { headers: {"Authorization" : `Bearer ${this.props.userInfo.data.token}`} });
      if (response.data.errorCode != 1 || !response.data.data.valid ){
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch(error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }
  async onImageSubmit(file) {
    //console.log("Hello")
    if (file != null) {
      await this.setState({
        image: file
      })
  }
}
async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    if (this.state.checked[0] === 1) {
      //document.getElementById("noti").innerHTML = "";
      document.getElementById("alreadyExist").style = "display: block; width: 100%; margin-top: -27px;"
      document.getElementById("notExist").style = "display: none"
    }else{
      document.getElementById("alreadyExist").style = "display: none"
      document.getElementById("notExist").style = "display: block; width: 100%; margin-top: 5px;"
    }
  }
  handleChange = name => async event => {

    await this.setState({
      [name]: event.target.value,
    });
    if (name === "name") {
      if (this.state.name) {
        document.getElementById("noti").innerHTML = "";
        this.setState({
          nameState: "success"
        })
      } else {
        this.setState({
          nameState: "error"
        })
      }
    }
    if (name === "description") {
      if (this.state.description) {
        document.getElementById("noti").innerHTML = "";
        this.setState({
          descriptionState: "success"
        })
      } else {
        this.setState({
        descriptionState: "error"
        })
      }
    }
    if (name === "city") {
      if (this.state.city) {
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cityState: "success"
        })
      } else {
        this.setState({
          cityState: "error"
        })
      }      
    }
    if (name === "district") {
        if (this.state.district) {
          document.getElementById("noti").innerHTML = "";
          this.setState({
            districtState: "success"
          })
        } else {
          this.setState({
            districtState: "error"
          })
        }
      }
      if (this.state.checked[0] === 1) {
        //document.getElementById("noti").innerHTML = "";
        if (name === "gln") {
            if (this.state.gln) {
              document.getElementById("noti").innerHTML = "";
              this.setState({
                glnState: "success"
              })
            } else {
              this.setState({
                glnState: "error"
              })
            }
          }
      }
  };
  async  handleSelect(event) {
    if(event.target.value === "Luống"){
     await this.setState({ type: "Luống", type_temp: 0});
    }
    if(event.target.value === "Cây"){
     await this.setState({ type: "Cây", type_temp: 1});
    }
    if(event.target.value === "Toàn bộ vùng sản xuất"){
     await this.setState({ type: "Toàn bộ vùng sản xuất", type_temp: 2}); 
    }
    if(event.target.value === "Lô nguyên liệu thô"){
      await this.setState({ type: "Lô nguyên liệu thô", type_temp: 3}); 
     }
     if(event.target.value === "Lô/mẻ sản xuất"){
      await this.setState({ type: "Lô/mẻ sản xuất", type_temp: 4}); 
     }
     document.getElementById("noti").innerHTML = "";
   }
   async  handleSelect_area(event) {
    var temp = event.target.value.split(" ");
    //console.log(temp)
    await this.setState({
      areagln: event.target.value,
      areagln_temp: temp[temp.length -1],
    })
    //console.log(this.state.areagln_temp)
     document.getElementById("noti").innerHTML = "";
   }
   async  handleSelect_product(event) {
    var temp = event.target.value.split(" ");
    //console.log(temp)
    await this.setState({
      productgtin: event.target.value,
      productgtin_temp: temp[temp.length -1],
    })
     document.getElementById("noti").innerHTML = "";
   }
   async  handleSelect_material(event) {
    var temp = event.target.value.split(" ");
    //console.log(temp)
    await this.setState({
      material: event.target.value,
      material_temp: temp[temp.length -1],
    })
     document.getElementById("noti").innerHTML = "";
   }
   async  handleSelect_process(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      processcode: event.target.value,
      processcode_temp: temp[temp.length - 1],
    })
    //console.log(this.state.processcode_temp)
     document.getElementById("noti").innerHTML = "";
   }

   handleAddOverviews = () => {
    this.setState({
      overviews: this.state.overviews.concat([{ key: "", value: "" }])
    })
  }
  handleRemoveInput = (idx) => {
    this.setState({
      overviews: this.state.overviews.filter((s, sidx) => idx !== sidx)
    })
  }
  handle_overview_description = idx => evt => {
    const newOverviews = this.state.overviews.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, value: evt.target.value }
    })
    this.setState({
      overviews: newOverviews
    })
  }

  handle_overview_name = idx => evt => {
    const newOverviews = this.state.overviews.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, key: evt.target.value }
    })
    this.setState({
      overviews: newOverviews
    })
  }
  
  submitData(){
      if(!this.state.name){
          this.setState({
              nameState: "error"
          })
          document.getElementById("noti").innerHTML = "Vui lòng nhập tên gợi nhớ của đối tượng";
          return;
      }
      if(!this.state.areagln_temp){
        document.getElementById("noti").innerHTML = "Vui lòng chọn vùng sản xuất"; return;
    }
    if(!this.state.productgtin_temp){
      document.getElementById("noti").innerHTML = "Vui lòng chọn loại sản phẩm sản xuất mặc định"; return;
  }
    if(this.state.type_temp === ""){
        document.getElementById("noti").innerHTML = "Vui lòng chọn loại đối tượng sản xuất"; return;
    }

    if(!this.state.processcode_temp){
      document.getElementById("noti").innerHTML = "Vui lòng chọn loại quy trình sản xuất"; return;
  }
  if(!this.state.material_temp){
    document.getElementById("noti").innerHTML = "Vui lòng chọn nguyên liệu chính"; return;
}
    const data = {
        "name": this.state.name,
        "image": LZUTF8.compress(this.state.image, { outputEncoding: 'Base64' }),
        "zoneUuid": this.state.areagln_temp,
        "type": this.state.type_temp,
        "productType": this.state.productgtin_temp,
        "processCode": this.state.processcode_temp,
        "options": this.state.overviews,
        "material": this.state.material_temp,
        "RFID": this.state.rfid
    }
    this.onSuccess(data)
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  async onSuccess(x) {
    this.props.createObject(x)
    this.props.history.push("/admin/areas/objectsp/list");
  }
  hideAlert_success(){
    this.setState(
      {
        alert: null
      }
    )
    this.props.history.push("/admin/objects/list")
  }
  handleCooperativeGlnChange(value) {
    //console.log('handleCooperativeGlnChange', value)
  
      this.setState({
        cooperativeGlnState: "success",
        cooperativeGlnhelp: "",
        cooperativeGln: value
      })
    
  }
  renderSuggestion = (suggestionProps) => {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const { classes } = this.props
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.label}
        selected={isHighlighted}
        component="div"
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        title={suggestion.label}
      >
          {suggestion.label}
      </MenuItem>
    );
  }
  getSuggestions = (value, { showEmpty = false } = {}) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    const newValue = inputLength === 0 ? '' : inputValue.toLowerCase()
    return inputLength === 0 && !showEmpty
      ? []
      : (this.state.cooperatives || []).filter(suggestion => {
          const keep =
            count < 7 && suggestion.label.toLowerCase().includes(newValue);
  
          if (keep) {
            count += 1;
          }
  
          return keep;
        });
  }
  renderInput = (inputProps) => {
    let { InputProps, ref, clearSelection, selectedItem, onChange, ...other } = inputProps;
    if (selectedItem) {
      const  arrayValue = selectedItem.split('(Mã: ')
      let value = arrayValue && arrayValue.length > 0 ? arrayValue[arrayValue.length -1 ] : ''
      value = value.slice(0, value.length - 1)
      if (value && this.state.cooperativeGln !== value) {
        this.handleCooperativeGlnChange(value)
      }
    }
    return (
      <CustomInput
        success={this.state.cooperativeGlnState === "success"}
        error={this.state.cooperativeGlnState === "error"}
        labelText="Tên doanh nghiệp"
        id="cooperativeGln_id"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => {
            if (event && event.target && event.target.value === '') {
              clearSelection()
            }
            this.handleCooperativeGlnChange(event.target.value)
          }
        }}
        inputProps={{
          inputRef: ref,
          ...InputProps
        }}
        helpText={this.state.cooperativeGlnhelp}
      />
    );
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
        <GridItem xs={7}  lg = {7} md = {7} sm = {7}>
          <Card>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {12} md = {12} sm = {12} xs = {12}>
                <h4 className="cardtableLabel">{strings.infoProcessingObject}</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className={classes.paddingbody}>
            <CustomInput
            labelText = {strings.nameObject}
                    success={this.state.nameState === "success"}
                    error={this.state.nameState === "error"}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      onChange: this.handleChange('name'),
                    }}
                    inputProps={{
                      value: this.state.name,
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          {/* <Icon className={classes.inputAdornmentIcon}>
                            spa
                              </Icon> */}
                        </InputAdornment>
                      ),
                      placeholder: ""
                    }}
                  />
                  <CustomInput
                    labelText ="RFID"
                    success={this.state.nameState === "success"}
                    error={this.state.nameState === "error"}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      onChange: this.handleChange('rfid'),
                    }}
                    inputProps={{
                      value: this.state.rfid,
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          {/* <Icon className={classes.inputAdornmentIcon}>
                            spa
                              </Icon> */}
                        </InputAdornment>
                      ),
                      placeholder: ""
                    }}
                  />
                <div id="notExist" style={{width: "100%"}}>
                <CustomInput
            labelText ={strings.mainMaterial}
                    success={this.state.materialState === "success"}
                    error={this.state.materialState === "error"}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      onChange: this.handleChange('material_temp'),
                    }}
                    inputProps={{
                      value: this.state.material_temp,
                      autoComplete: "off",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                        </InputAdornment>
                      ),
                      placeholder: ""
                    }}
                  />
                </div>
              
                  <div id="alreadyExist" style={{width: "100%", display: "none"}}>
                  <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          {strings.mainMaterialType}
                              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.material}
                          onChange={this.handleSelect_material}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {strings.chooseMainMaterial}
                        </MenuItem>
                          {this.state.product_type.map(x => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  </div>
                  <div style={{ width: "100%" }}>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label={strings.checkMaterial}
                    />
                  </div>
                  <div style={{width: "100%", marginTop: "-16px"}}>
                  <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          {strings.productionArea}
                              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.areagln}
                          onChange={this.handleSelect_area}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {strings.chooseArea}
                        </MenuItem>
                          {this.state.area.map(x => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  </div>
                  <div style={{width: "100%"}}>
                  {/* <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          {strings.defaultProduct}
                              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.productgtin}
                          onChange={this.handleSelect_product}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {strings.chooseTypeObject}
                        </MenuItem>
                          {this.state.product_type.map(x => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                  </div>
                  <div style={{width: "100%"}}>
                  <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          {strings.typeObjectProcessing}
                              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.type}
                          onChange={this.handleSelect}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {strings.chooseTypeObject}
                        </MenuItem>
                          {this.state.destime.map(x => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  </div>
                  <div style={{width: "100%"}}>
                  <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          {strings.defaultProcessing}
                              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.processcode}
                          onChange={this.handleSelect_process}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Chọn quy trình nuôi trồng
                        </MenuItem>
                          {this.state.processlist.map(x => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  </div>
                  <div style={{ width: '100%' }}>
										<Accordion
											// active={1}
											collapses={[
												{
													title: strings.moreInfoObjetProcessing,
													content: (
                            <form style={{width: "100%"}}>
                            {this.state.overviews.map((overview, idx) => (
                              <GridContainer key={idx}>
                                <GridItem xs={4} md={4} sm={4} lg={4}>
                                <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                  onChange: this.handle_overview_name(idx),
                                }}
                                inputProps={{
                                  value: overview.key,
                                  autoComplete: "off",
                                  placeholder: "Tên trường #" + idx 
                                }}
                              />
                                </GridItem>
                                <GridItem xs={7} md={7} sm={7} lg={7}>
                                <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                  onChange: this.handle_overview_description(idx),
                                }}
                                inputProps={{
                                  value: overview.value,
                                  autoComplete: "off",
                                  placeholder: "Nội dung #" + idx 
                                }}
                              />
                                </GridItem>
                                <GridItem xs={1} md={1} sm={1} lg={1} className={classes.fixCardRemove}>
                                <Button color="danger" size="sm" onClick={() => this.handleRemoveInput(idx)}>X</Button>
                                </GridItem>
                              </GridContainer>
                            ))}
                             <Button color="success" size="sm" onClick={this.handleAddOverviews} style={{ marginLeft: 13 }}>{strings.addFields}</Button>
                             {/* <Button color="danger" size="sm" style={{ marginLeft: 13 }} onClick={this.handleRemoveInput}>Loại bỏ</Button> */}
                          </form>
													)
												}
											]}
										/>
									</div>
                  <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                  <p
                    id="noti"
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      color: 'red',
                      fontFamily: '"Muli',
                      marginTop: 5,
                      marginBottom: 5,
                    }}>
                  </p>
                  </div>
                  <Button color="success" size="sm" onClick={this.submitData.bind(this)}>{strings.create}</Button> 
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={5}  lg = {5} md = {5} sm = {5}>
          <Card>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {12} md = {12} sm = {12} xs = {12}>
                  {/* <CardIcon color="success">
                    <People />
                  </CardIcon> */}
                   <h4 className="cardtableLabel">{strings.image}</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody style={{display: "flex", justifyContent: "center"}}>
                <ImageUpload    onImageSubmit={this.onImageSubmit}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {this.state.alert}
          </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo}
}
const  mapDispatchToProps = (dispatch) => {
  return {
    createObject: (data) => dispatch({type: "CREATE_OBJECT_PROCESS", data: data})
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps,mapDispatchToProps)(TableFarmList)));
