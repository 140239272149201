import React from "react";
class GalleryModal extends React.Component {
    constructor(props) {
      super(props);
      this.state={
          src: "",
          isOpen: false,
          currentIndex: null,
          currentSrc: "",
          list: [],
          isPre: false,
          isNext: true
      }
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.renderImageContent = this.renderImageContent.bind(this)
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.hasNext = this.hasNext.bind(this);
      this.hasPrev = this.hasPrev.bind(this);
      this.findPrev = this.findPrev.bind(this);
      this.findNext = this.findNext.bind(this);
    }
    async componentDidMount() {
      this.setState({
          list: this.props.list,
      })

    }  
    componentWillReceiveProps(preProps){
        if(this.props.list !== preProps.list){
            this.setState({
                list: preProps.list
            })
        }
    }
    
    closeModal(){
        this.setState({
            currentIndex: null,
            isOpen: false,
        })
    }
    async openModal(src, index){
        await this.setState({
            currentIndex: index,
            src: src,
            isOpen: true
        })
        this.hasNext();
        this.hasPrev();
    }
    hasNext(){
        if(this.state.currentIndex + 1 < this.state.list.length){
            this.setState({
                isNext: true,
            })
        }else{
            this.setState({
                isNext: false,
            })
        }
    }
    async findNext(){
        const temp = this.state.currentIndex;
        await this.setState({
            currentIndex: temp + 1 ,
            src: this.state.list[temp + 1]
        })
        this.hasNext();
        this.hasPrev();
       
    }
    async findPrev(){
        const temp = this.state.currentIndex;
        await this.setState({
            currentIndex: temp - 1 ,
            src: this.state.list[temp - 1]
        })
        this.hasPrev();
        this.hasNext();
      
    }
    hasPrev(){
        if(this.state.currentIndex > 0){
            this.setState({
                isPre: true
            })
        }else{
            this.setState({
                isPre: false
            })
        }
    }
    renderImageContent(src, index) {
        return (
          <div onClick={(e) => this.openModal(src, index)}>
            <img src={src} key={src} />
          </div>
        )
      }
    handleKeyDown(e) {
      if (e.keyCode === 27)
        this.props.closeModal();
      if (e.keyCode === 37 && this.props.hasPrev)
        this.props.findPrev();
      if (e.keyCode === 39 && this.props.hasNext)
        this.props.findNext();
    }
    render () {
     // const { closeModal, hasNext, hasPrev, findNext, findPrev, src } = this.props;
      return (
        <div className="gallery-container">
        <div className="gallery-grid">
          {this.state.list.map(this.renderImageContent)}
        </div>
            {this.state.isOpen === true ? <div>
                <div className="modal-overlay" onClick={this.closeModal}></div>
          <div  className="modal">
            <div className='modal-body'>
              <a href="#" className='modal-close' onClick={this.closeModal} onKeyDown={this.handleKeyDown}>&times;</a>
              {this.state.isPre ? <a href="#" className='modal-prev' onClick={this.findPrev} onKeyDown={this.handleKeyDown}>&lsaquo;</a> : null}
              {this.state.isNext ? <a href="#" className='modal-next' onClick={this.findNext} onKeyDown={this.handleKeyDown}>&rsaquo;</a> : null}
              <div style={{backgroundImage: `URL(${this.state.src})`}} className="imageModal" />
            </div> </div>
          </div> :""}
        </div>
      )
    }
  }
  
export default GalleryModal;