import React from "react";
import cookie from 'react-cookies';
import axios from 'axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from 'react-redux';
// @material-ui/icons
import Person from "@material-ui/icons/PowerInput";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import {LINK_API} from 'constants/API'; 
import { IMG_LOAD } from "constants/index.js";

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
const ethers = require('ethers');
const CryptoJS = require("crypto-js");
const style = {
  ...sweetAlertStyle,
  ...regularFormsStyle,
  ...customSelectStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    textAlign: "left"
  },
}


class ChangePwdForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPwd: null,
      newPwd: null,
      newPwdConfirm: null,
      showPassword:false,
      alert: null,
      reload: false
    };

    this.token = cookie.load('token');
    this.handleToggle = this.handleToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitData = this.submitData.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.routeToAuth.bind(this);
  }

  handleToggle() {
    const { showPassword } = this.state;

    if (showPassword) {
      this.setState({
        showPassword: false
      });
    }
    else {
      this.setState({
        showPassword: true
      });
    }

  }
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  handleChange = name => event => {
    document.getElementById("noti_passWord").innerHTML = "";
    this.setState({ [name]: event.target.value });
  }

  handleClose = () => {
    this.props.onConfirm(null);
  }

  submitData = async () => {
    if(!this.state.currentPwd){
      document.getElementById("noti_passWord").innerHTML = "Vui lòng nhập mật khẩu hiện tại";
      return;
    }
    if(!this.state.newPwd){
      document.getElementById("noti_passWord").innerHTML = "Vui lòng nhập mật khẩu mới";
      return;
    }
    if(!this.state.newPwdConfirm){
      document.getElementById("noti_passWord").innerHTML = "Vui lòng nhập lại mật khẩu mới";
      return;
    }
    if(this.state.currentPwd !== this.props.userInfo.password){
      document.getElementById("noti_passWord").innerHTML = "Mật khẩu hiện tại không chính xác";
      return;
    }
    if(this.state.newPwd !== this.state.newPwdConfirm){
      document.getElementById("noti_passWord").innerHTML = "Mật khẩu nhập lại không trùng khớp";
      return;
    }
    var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.seedEncrypted.toString(), this.props.userInfo.password);
    const randomMnemonic = bytes.toString(CryptoJS.enc.Utf8);
    
    var bytes_private  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privateKey = bytes_private.toString(CryptoJS.enc.Utf8);
    const enPrivateKey_emp = await CryptoJS.AES.encrypt(privateKey, this.state.newPwd.toString());
    const enRandomMnemonic = await CryptoJS.AES.encrypt(randomMnemonic, this.state.newPwd.toString());
    //
    var temp_oldPass = new Buffer(this.props.userInfo.password).toString('hex');
    const pass_en_old = await ethers.utils.keccak256('0x' + temp_oldPass);
    //hash new pass
    var temp_newPass = new Buffer(this.state.newPwd).toString('hex');
		const pass_en_new = await ethers.utils.keccak256('0x' + temp_newPass);
    const data = {
      currentPassword: pass_en_old,
      newPassword: pass_en_new,
      newSeedEcrypted: enRandomMnemonic.toString(),
      newPrivatedEncrypted: enPrivateKey_emp.toString(),
    }
    this.props.onConfirm(data)
  }

  handleCloseForm = () => {
    this.props.onCancelPw();
  }


  routeToAuth = () => {
    // this.props.history.push("/auth");
    this.setState({reload: true})
  };

  async componentDidMount() {
    await this.checkPermission();
  }

  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, {
        headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
      });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove("token");
        return this.props.history.push("/");
      } else {
        axios.defaults.headers.common["Authorization"] = this.props.userInfo.data.token;
      }
    } catch (error) {
      console.log(error);
      await cookie.remove("token");
      return this.props.history.push("/");
    }
  }
  handleChangePwd = data => {
    // this.closeChangePwdDialog();

    if (!data) return; // <=> Close dialog

    //loading
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    let that = this;
    axios
      .post(LINK_API.CHANGE_PWD, {
        currentPassword: data.currentPwd,
        newPassword: data.newPwd
      })
      .then(function(response) {
        that.setState({
          alert: null
        });

        if (response.data.errorCode == 1) {
          cookie.remove("gln");
          cookie.remove("token");
          cookie.remove("role");
          cookie.remove("user_name");
          that.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => that.routeToAuth()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đăng nhập lại"
                closeOnClickOutside={false}
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Bạn cần phải đăng nhập lại
              </SweetAlert>
            )
          });
        } else if (response.data.errorCode == 11) {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Mật khẩu không chính xác
              </SweetAlert>
            )
          });
        } else {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
              </SweetAlert>
            )
          });
        }
      })
      .catch(function(error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng thử lại
            </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ marginTop: 32 }}>
      <GridContainer >
        <GridItem xs={12} sm={12} md={12} lg={12} style = {{margin:"auto"}}>
          <Card style={{marginTop: "0px"}}>
            <CardHeader color="success" icon>
              {/* <CardIcon color="success">
                <Person />
              </CardIcon> */}
             <h4 className="cardtableLabel">Đổi mật khẩu</h4>
            </CardHeader>
            <CardBody style = {{textAlign:"center", marginTop: "-30px", marginLeft: "10px"}}> 
              <form>
                <div style={{marginBottom: "-20px"}}>
                <CustomInput
                  labelText="Mật khẩu hiện tại"
                  id="currentPwd"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: this.handleChange('currentPwd'),
                    type:(this.state.showPassword)?"text":"password"
                  }}
                  value={this.state.currentPwd}
                  // margin={0}
                  // style={{margin: "0 !important"}}
                />
                </div>
                <div style={{marginBottom: "-20px"}}>
                  <CustomInput
                  labelText="Mật khẩu mới"
                  id="newPwd"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: this.handleChange('newPwd'),
                    type:(this.state.showPassword)?"text":"password"
                  }}
                  value={this.state.newPwd}
                />
                </div>
                  <CustomInput
                  labelText="Nhập lại mật khẩu mới"
                  id="newPwdConfirm"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: this.handleChange('newPwdConfirm'),
                    type:(this.state.showPassword)?"text":"password"
                  }}
                  value={this.state.newPwdConfirm}
                />
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={this.handleToggle}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="Hiển thị mật khẩu"
                  />
                </div>
                <p
                      id="noti_passWord"
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '400',
                        color: 'red',
                        fontFamily: 'Muli',
                        marginTop: 5,
                        marginBottom: 5,
                        marginLeft: 16
                      }}>
                    </p>
                <Button color="success" size="sm" onClick={this.submitData}>Cập nhật</Button>
                <Button color="danger" size="sm" onClick={this.handleCloseForm}>Hủy bỏ</Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {this.state.alert}
      {this.state.reload?<Redirect to='/auth' />:null}
    </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo}
}
export default withStyles(style)(withRouter(connect(mapStateToProps, null)(ChangePwdForm)));
