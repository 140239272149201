import React from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";

//material core components
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

// icon
import Spa from "@material-ui/icons/Spa";
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ProductList from "./ProductList";
import GetApp from "@material-ui/icons/GetApp"

//style
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";


import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from 'constants/API';
import strings from "constants/strings.jsx"
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const styles = () => ({
  ...sweetAlertStyle,
  ...extendedTablesStyle,
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
    margin: "0px !important",
    maxWidth: "750px"
  },
});

class CatagoryProduct extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(this.props.location.search);
    const openCreate = (queryParams.get('showCreateDialog') === "true") ? true : false;

    this.state = {
      page: -1,
      pages: [],
      openDialog: openCreate,
      ProductList: [],
      ProductShowing: [],
      showingItemStart: -1,
      showingItemEnd: -1,
      maxPage: 5,
      itemOfPage: 4,
      load: "",
      waitSubmit: "none",
      activeState: true
    };
    this.handleNewClick = this.handleNewClick.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.handleDataExport = this.handleDataExport.bind(this);
  }


  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  async componentDidMount() {
    try {
      //await this.checkPermission();
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  onUpdate = () => {
    this.setState({
      load: "none"
    })
  }


  showConfirmAlert(data) {
    this.handleClose();

    data.productExpired = data.productExpired + " ngày " + data.productExpiredType;
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          onConfirm={() => { this.successDelete(data) }}
          onCancel={() => {
            this.hideAlert();
            this.setState({ openCreateTree: true });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          <div style={{ textAlign: "center", display: this.state.waitSubmit }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
    })
  }

  handleNewClick() {
    this.props.history.push("/admin/products/create")
  }
  async handleNewClick_Sub() {
    document.getElementById("addProducts_header").style = "display: none";
    document.getElementById("processpage").style = "display: none";
    document.getElementById("product1").style = "display: block; width: 100%";
  }


  async handleDataExport(data){
    let exportData = [];
    exportData = data.map(item => {
      return ({
        uuid: item.uuid,
        name: item.name,
        gtin: item.gtin ? item.gtin : "Chưa có",
        description: item.description,
        status: item.status === 1 ? "Đang sản xuất" : item.status === 0 ? "Ngừng sản xuất" : "",
      })
    })

    await this.setState({ export: exportData })
    //-----------------------

  }

  render() {
    const { classes } = this.props;

    return (
      <div >
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer style={{display: "flex", flexFlow: "center"}}>
    <h4 style={{marginRight: 19}}className="cardtableLabel">{strings.productTypeList}</h4>
                    <div className="">
                      <ExcelFile element={<Button justIcon color="success" className="like" simple round > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> </Button>}>
                        <ExcelSheet data={this.state.export} name="Danh sách loại sản phẩm">
                          <ExcelColumn label="Mã sản phẩm" value="uuid" />
                          <ExcelColumn label="Tên loại sản phẩm" value="name" />
                          <ExcelColumn label="Mã GTIN" value="gtin" />
                          <ExcelColumn label="Miêu tả" value="description" />
                          <ExcelColumn label="Trạng thái" value="status" />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                    </GridContainer>
                  </GridItem>
                    
                    <GridItem lg={6} md={6} sm={6} xs={12}>
                      <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                        {(this.props.userInfo.data.active === true) ? <Button size="sm" color="success" onClick={this.handleNewClick}> <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button> : null}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <div style={{ textAlign: "center", display: this.state.load }}>
                    <img
                      alt="{name}"
                      src={IMG_LOAD}
                      style={{ height: "100px" }}
                    />
                  </div>
                  <ProductList  handleDataExport = {this.handleDataExport} onupdate={this.onUpdate} load={this.state.load} onNewClick={this.handleNewClick} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

CatagoryProduct.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo}
}

export default withStyles(styles)(connect(mapStateToProps, null)(CatagoryProduct));