import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import ReactToPrint from 'react-to-print';
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import cookie from 'react-cookies';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import Add from "@material-ui/icons/Add";

// core components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CreateStamp from "./CreateStamp.jsx";
// import StampPrintTemplate from "./StampPrintTemplate.jsx";
// import StampBoxPrintTemplate from "./StampBoxPrintTemplate.jsx"

//mock data
import { IMG_LOAD } from 'constants/index.js';

//styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css"

import { LINK_API } from 'constants/API';

const axios = require('axios');

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
};

class TableSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNewTree = this.handleNewTree.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);

    this.hideAlert = this.hideAlert.bind(this);
    this.onSuccess = this.onSuccess.bind(this);

    const queryParams = new URLSearchParams(this.props.location.search);
    const openCreateFarm = (queryParams.get('showCreateDialog') === "true") ? true : false;


    this.state = {
      openCreateFarm: openCreateFarm,
      alert: null,
      data: [],
      dataSubmit: [],
      load: "",
      showTable: "none",
      activeState: true
    };
  }

  genStampList = (x) => {
    this.setState({
      data: x.map((prop, key) => {
        return {
          id: key + 1,
          name: prop.name,
          uuid: prop.uuid,
          date: moment(prop.createdAt).format("DD/MM/YYYY hh:mm:ss"),
          period: prop.periodTime + " " + (prop.typePeriodTime === "Y" ? "Năm" : (prop.typePeriodTime === "M" ? "Tháng" : (prop.typePeriodTime === "D" ? "Ngày" : "Giờ"))),
          currency: prop.unit,
          end: moment(prop.end).format("DD/MM/YYYY HH:mm:ss"),
          plan: {
            name: prop.name,
            maxAction: prop.maxAction,
            maxBizProcess: prop.maxBizProcess,
            maxCrop: prop.maxCrop,
            maxEmployee: prop.maxEmployee,
            maxProcessingObject: prop.maxProcessingObject,
            maxProductType: prop.maxProductType,
            maxProductionObject: prop.maxProductionObject,

          }
        };
      }),
      load: "none",
      showTable: ""
    });
  }


  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }


  async componentDidMount() {
    try {
      await this.checkPermission();
      await this.props.getSubscription();
    }
    catch (error) {
      console.log(error)
    }
  }

  async componentWillReceiveProps(preProps) {
    if (this.props.subscription !== preProps.subscription) {
      await this.setState({ load: "none" });
      if (preProps.subscription) {
        await this.genStampList(Array.from([]))
        await this.genStampList(Array.from(preProps.subscription.data))
      }
    }
  }

  refresh = () => {
    window.location.reload();
  }

  renderTrigger() {
    return <Button color="success">In Mã</Button>;
  }

  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }

  setRef = (ref) => {
    this.componentRef = ref;
  }
  generateStamp(data) {

  }
  onSuccess() {

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  showConfirmAlert(data) {
    this.handleClose();
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          onConfirm={() => this.onSuccess()}
          onCancel={() => {
            this.hideAlert();
            this.setState({ openCreateFarm: true });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          {/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
        </SweetAlert>,
      dataSubmit: data
    })
  }

  handleNewTree() {
    this.props.history.push("/admin/farms/tree?showCreateDialog=true")
  }

  handleNewClick() {
    this.props.history.push("/admin/adminonly/createPlan")
  }

  handleClose() {
    this.setState({ openCreateFarm: false });
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <h4 className="cardtableLabel">Danh sách gói</h4>
                  </GridItem>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      {(this.props.userInfo.data.active === true) ? <Button color="success" size="sm" onClick={() => this.handleNewClick()}> <Add style={{ margin: "0px", marginRight: "5px" }} /> Tạo mới </Button> : null}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  data={this.state.data}
                  filterable
                  // defaultPageSize={5}
                  previousText="Trang Trước"
                  nextText="Trang Sau"
                  rowsText="hàng"
                  ofText="/"
                  pageText="Trang"
                  noDataText="Nhấn nút TẠO MỚI để tạo một lô tem nhãn hiệu đầu tiên!"
                  columns={[
                    {
                      Header: "#",
                      accessor: "id",
                      filterable: false,
                      maxWidth: 70,
                      className: "center",
                      Header: () => (
                        <div style={{
                          textAlign: "center"
                        }}>
                          #
                        </div>
                      )
                    },
                    {
                      Header: "Tên gói",
                      accessor: "name",
                      filterable: false
                    },
                    {
                      Header: "Mã số",
                      accessor: "uuid",
                      filterable: false
                    },
                    {
                      Header: "Ngày tạo",
                      accessor: "date",
                      filterable: false
                    },
                    {
                      Header: "Thời hạn",
                      accessor: "period",
                      filterable: false
                    },
                    {
                      Header: "Đơn vị tiền tệ",
                      accessor: "currency",
                      filterable: false
                    }
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                  SubComponent={row => {
                    const planData = row.original.plan;
                    return (
                      <div>
                        <p className="textHeaderSup">Thông tin gói:</p>
                        <GridContainer>
                          <GridItem xs={4} md={4} sm={4} lg={4}>
                            <p className="tagTitleSub">Loại gói: </p>
                            <p className="tagTitleSub">Số lượng hành động: </p>
                            <p className="tagTitleSub">Số lượng quy trình: </p>
                            <p className="tagTitleSub">Số lượng mùa vụ: </p>
                            <p className="tagTitleSub">Số lượng nhân viên: </p>
                            <p className="tagTitleSub">Số lượng đối tượng chế biến: </p>
                            <p className="tagTitleSub">Số lượng sản phẩm: </p>
                            <p className="tagTitleSub">Số lượng đối tượng nuôi trồng: </p>
                          </GridItem>
                          <GridItem xs={8} md={8} sm={8} lg={8}>
                            <p>{planData.name}</p>
                            {planData.maxAction === -1 ? <p>Không giới hạn</p> : <p>{planData.maxAction}</p>}
                            {planData.maxBizProcess === -1 ?  <p>Không giới hạn</p>: <p>{planData.maxBizProcess}</p>}
                            {planData.maxCrop === -1 ?  <p>Không giới hạn</p> : <p>{planData.maxCrop}</p>}
                            {planData.maxEmployee === -1 ?  <p>Không giới hạn</p>: <p>{planData.maxEmployee}</p>}
                            {planData.maxProcessingObject === -1 ?  <p>Không giới hạn</p> : <p>{planData.maxProcessingObject}</p>}
                            {planData.maxProductType === -1 ?  <p>Không giới hạn</p>: <p>{planData.maxProductType}</p>}
                            {planData.maxProductionObject === -1 ?  <p>Không giới hạn</p>: <p>{planData.maxProductionObject}</p>}
                          </GridItem>
                        </GridContainer>
                      </div>
                    );
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          open={this.state.openCreateFarm}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >

        </Dialog>

        {this.state.alert}
      </div>
    );
  }
}

// export default withStyles(styles)(withRouter(TableSubscription));
const mapDispatchToProps = (dispatch) => {
  return {
    getSubscription: () => dispatch({ type: "GET_PLAN_LIST" }),
  }
}
const mapStateToProps = (state) => {
  return {
    subscription: state.planList,
    userInfo: state.userInfo
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableSubscription)));
