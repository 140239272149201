const initialState = { data: [], };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SAVE_NOTIFICATION':
            return {
                ...state,
            };
        case 'SAVE_NOTIFICATION_SUCCESS':
            return {
                ...state,
                data: action.data
            };
        case 'SAVE_NOTIFICATION_FAILURE':
            return {
                ...state,
            };
        case 'CLEAR_NOTIFICATION':
            return {
                ...state,
            };
        case 'CLEAR_NOTIFICATION_SUCCESS':
            return {
                ...state,
                data: []
            };
        case 'CLEAR_NOTIFICATION_FAILURE':
            return {
                ...state,
            };
        default:
            return state;
    }
}
export default reducer;