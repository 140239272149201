import {DEACTIVE_NOTI_PRODUCT, PUSH_NOTI_PRODUCT_FAILURE, PUSH_NOTI_PRODUCT_PROCESSING, PUSH_NOTI_PRODUCT_SUCCESS} from "actions/types.js"
const initialState = {tr: false, message: "", color: "info"};
const reducer = (state = initialState, action) =>{
  // console.log("State", state)
  // console.log("Actions", action)
  switch (action.type){
    case DEACTIVE_NOTI_PRODUCT:
      return {
          ...state,
        tr: action.tr};
      case PUSH_NOTI_PRODUCT_SUCCESS:
          
          return {
              ...state,
            tr: action.tr,
            color: action.color,
            message: action.message};
      case PUSH_NOTI_PRODUCT_FAILURE:
          return {  
            ...state,
            tr: action.tr,
            color: action.color,
            message: action.message};
      case PUSH_NOTI_PRODUCT_PROCESSING:
          return {
            ...state,
            tr: action.tr,
            color: action.color,
            message: action.message};
      default:
      return state;
  }
}
export default reducer;