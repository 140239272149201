/*eslint-disable*/
import React from "react";
import cookie from 'react-cookies';
import axios from 'axios';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";

// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import { withStyles } from "@material-ui/styles";
import Detail from "@material-ui/icons/Info";
import { style } from "@material-ui/system";
import { connect } from 'react-redux';

import UnixStamp from "@material-ui/icons/Receipt";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import Assessment from "@material-ui/icons/Assessment";
import Dashboard from "@material-ui/icons/Dashboard";
import Dns from "@material-ui/icons/Dns";
import FindInPage from "@material-ui/icons/Search";

import {
    AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend
} from 'recharts';
import { LINK_API } from 'constants/API';
import strings from "../../../../constants/strings";


class GeneralStatistic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataBranchs: [],
            dataDistributor: []
        }
        this.checkPermission = this.checkPermission.bind(this);
    }

    async componentDidMount() {
        await this.checkPermission;
        this.props.getNumberProductToBranch();
        this.props.getNumberProductToDistributor();

    }

    componentWillReceiveProps(preProps){
        if(this.props.numProductToBranch !== preProps.numProductToBranch){               
            let data = preProps.numProductToBranch.data;

            
            
        }

        if(this.props.numProductToDistributor !== preProps.numProductToDistributor){               
            let data = preProps.numProductToDistributor.data;
            if(data[0]){
                let dataBranchs = data.map((item, index) => {
                    return {
                        id: index + 1,
                        nameDistributor: item.distributorName,
                        numProduct: item.numberStamp,
                        view_detail: <div><Button justIcon color="transparent" onClick={()=>{this.props.history.push("/admin/distributorStatistic/" + item.distributorName)}}>
                                                <FindInPage style={{ color: "#0f7343", width: 47 }} />
                                             </Button>        
                                    </div>
                    }
                })
    
                this.setState({ dataBranchs })
            }
            
            
        }
      }

    async checkPermission() {
        try {
          let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
          if (response.data.errorCode !== 1 || !response.data.data.valid) {
            await cookie.remove('token')
            return this.props.history.push("/");
          }
          else {
            axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
          }
        }
        catch (error) {
          console.log(error);
          await cookie.remove('token');
          return this.props.history.push("/");
        }
      }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {/* <GridContainer>
                    <GridItem xs={12} sm={12} md={6}> */}
                        <Card>
                            <CardHeader>
                                <h4 className="cardtableLabel">{strings.distributor}</h4>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={this.state.dataBranchs}
                                    filterable
                                    // defaultPageSize={5}
                                    previousText="Trang Trước"
                                    nextText="Trang Sau"
                                    rowsText="hàng"
                                    ofText="/"
                                    pageText="Trang"
                                    noDataText="Chưa có thông tin!"
                                    columns={[
                                        {
                                            Header: "#",
                                            accessor: "id",
                                            filterable: false,
                                            maxWidth: 70,
                                            className: "center",
                                            Header: () => (
                                                <div style={{
                                                    textAlign: "center"
                                                }}>
                                                    #
                                                </div>
                                            )
                                        },
                                        {
                                            Header: strings.nameDistributor,
                                            accessor: "nameDistributor",
                                            filterable: false
                                        },
                                        // {
                                        //     Header: "Tên chi nhánh phân phối đến",
                                        //     accessor: "nameBranch",
                                        //     filterable: false
                                        // },
                                        {
                                            Header: strings.shipmentNumber,
                                            accessor: "numProduct",
                                            filterable: false
                                        },
                                        {
                                            Header: strings.watchDetail,
                                            accessor: "view_detail",
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationBottom={true}
                                    sortable={false}
                                    resizable={false}
                                    className="-striped -highlight"
                                    style={{ display: this.state.showTable }}
                                />
                            </CardBody>
                        </Card>
                    {/* </GridItem> */}

                    {/* <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader>
                                <h4 className="cardtableLabel">Nhà Phân Phối</h4>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={this.state.dataDistributor}
                                    filterable
                                    // defaultPageSize={5}
                                    previousText="Trang Trước"
                                    nextText="Trang Sau"
                                    rowsText="hàng"
                                    ofText="/"
                                    pageText="Trang"
                                    noDataText="Chưa có thông tin!"
                                    columns={[
                                        {
                                            Header: "#",
                                            accessor: "id",
                                            filterable: false,
                                            maxWidth: 70,
                                            className: "center",
                                            Header: () => (
                                                <div style={{
                                                    textAlign: "center"
                                                }}>
                                                    #
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Tên nhà phân phối",
                                            accessor: "nameDistributor",
                                            filterable: false
                                        },
                                        {
                                            Header: "Số lô hàng đến nhà phân phối",
                                            accessor: "numProduct",
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationBottom={true}
                                    sortable={false}
                                    resizable={false}
                                    className="-striped -highlight"
                                    style={{ display: this.state.showTable }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem> */}
                {/* </GridContainer> */}
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getNumberProductToBranch: () => dispatch({type: "GET_PRODUCT_TO_BRANCHS"}),
        getNumberProductToDistributor: () => dispatch({type: "GET_PRODUCT_TO_DISTRIBUTOR"})
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        numProductToBranch: state.numProductToBranch,
        numProductToDistributor: state.numProductToDistributor
    }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralStatistic)));