import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import Timeline from "components/Timeline/Timeline.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { connect } from 'react-redux';
import { stories } from "variables/general.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import cookie from 'react-cookies';
import CardHeader from "components/Card/CardHeader.jsx";
import moment from "moment";
import Accordion from 'components/Accordion/Accordion.jsx';
import GalleryModal from "layouts/DashBoard/components/GridImage/index.jsx"
import "assets/scss/material-dashboard-pro-react/gridImage.scss"
import inputnotqr from 'assets/img/inputnotqr.png'
import axios from 'axios';
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from 'constants/API';
const styles = {

}
const imgUrls = [
  "https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/b11nam_fow1bg.jpg",
  "https://res.cloudinary.com/agridential/image/upload/v1569300548/Portal/b3xalach_m64wcv.png",
  "https://res.cloudinary.com/agridential/image/upload/v1569299812/Portal/thuhoach_eopjd2.png"
];
class TimelinePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bizProcess: [],
      showTimeLine: [],
      listProcess: [],
      isOpen: false,
      currentIndex: null
    }
    this.idOfProcess = cookie.load("idProcess");
    this.handleTimeLine = this.handleTimeLine.bind(this)
    this.handleData = this.handleData.bind(this)
    this.closeModal = this.closeModal.bind(this);
    this.findNext = this.findNext.bind(this);
    this.findPrev = this.findPrev.bind(this);
    this.renderImageContent = this.renderImageContent.bind(this);
  }
  async componentDidMount() {
    await this.getInfoProccess();
    this.handleData(this.state.listProcess)
  }
  async handleData(listarray) {
    //await listarray.reverse();
    await this.setState({
      bizProcess: listarray.step,
      name: listarray.process_name,
      date: moment(listarray.createdAt).format("DD/MM/YY"),
      countStep: listarray.step.length + " bước",
    })
    this.handleTimeLine(this.state.bizProcess)
  }

  async getInfoProccess() {
    await axios.get(LINK_API.GET_INFO_PROCESS + this.idOfProcess)
      .then(async res => {
        if (res.data.errorCode === 1) {
          await this.setState({
            listProcess: res.data.data
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  //Grid function
  renderImageContent(src, index) {
    //console.log("Src",src)
    return (
      <div onClick={(e) => this.openModal(src, index)}>
        <img src={src} key={src} />
      </div>
    )
  }
  openModal(e, index) {
    this.setState({ currentIndex: index, currentSource: e, isOpen: true });
  }
  async closeModal(e) {
    if (e != undefined) {
      this.setState({
        isOpen: false
      })
      e.preventDefault();
    }
    await this.setState({ currentIndex: null, isOpen: false });
  }
  async findPrev(e) {
    const temp = this.state.currentIndex;
    await this.setState({
      currentIndex: temp - 1,
      currentSource: imgUrls[temp - 1]
    })
  }
  async findNext(e) {
    const temp = this.state.currentIndex;
    await this.setState({
      currentIndex: temp + 1,
      currentSource: imgUrls[temp + 1]
    })
  }



  async handleTimeLine(data) {
    let length = data.length;
    let temp = [];

    for (let i = 0; i < length; i++) {
      let actions_image = [];
      let mobileComponent = [];
      const lengthMethod = data[i].mobile.method ? data[i].mobile.method.length : 0;
      if (data[i].actions_image) {
        for (let j = 0; j < data[i].actions_image.length; j++) {
          await actions_image.push(data[i].actions_image[j].secure_url)
        }
      }
      for (let g = 0; g < lengthMethod; g++) {
        if (g !== 0 && g !== lengthMethod && g !== lengthMethod - 1 && data[i].mobile.method[g].label) {
          mobileComponent.push(data[i].mobile.method[g])
        }
      }

      let authorityitem = [];
      if (data[i].authority.length === 1) {
        if (data[i].authority[0] === "SM") {
          authorityitem = <div>
            <GridItem>
              <p className="TitleBold">Nhân viên thực hiện:</p>
            </GridItem>
            <GridItem>
              <p>
                Nhân viên nuôi trồng
                          </p>
            </GridItem>
          </div>
        }
        else if (data[i].authority[0] === "SP") {
          authorityitem = <div>
            <GridItem>
              <p className="TitleBold">Nhân viên thực hiện:</p>
            </GridItem>
            <GridItem>
              <p>
                Nhân viên chế biến
                          </p>
            </GridItem>
          </div>
        }
      }
      else if (data[i].authority.length === 2) {
        authorityitem = <div>
          <GridItem>
            <p className="TitleBold">Nhân viên thực hiện:</p>
          </GridItem>
          <GridItem>
            <p>
              Nhân viên nuôi trồng , Nhân viên chế biến
                          </p>
          </GridItem>
        </div>

      }
      temp.push({
        inverted: true,
        badgeColor: "success",
        authority: [],
        SP: false,
        SM: false,
        badgeIcon: <img src={require("../../../../assets/icons/" + data[i].icon)} style={{ width: "32px" }}></img>,
        title: data[i].name,
        titleColor: "success",
        body: (
          <div>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">Mô tả:</p>
              </GridItem>
              <GridItem>
                <p>
                  {data[i].description}
                </p>
              </GridItem>
            </GridContainer>
            <div style={{ width: "100%", marginTop: "-16px" }}>
              <Accordion
                color="success"
                collapses={[
                  {
                    title: 'Chi tiết',
                    content: (
                      <div style={{ width: "100%" }}>
                        <GridContainer>
                          {authorityitem}
                          <GridItem xs={12}>
                            <p className="TitleBold">Icon hiển thị trên điện thoại:</p>
                          </GridItem>
                          <GridItem xs={12} >
                            <div className="backGroundIconMobile">
                              <img src={require("../../../../assets/icons/mobile/" + data[i].mobileIcon + ".png")} style={{ width: "35px" }}></img>
                            </div>
                          </GridItem>
                        </GridContainer>
                        <GridContainer style={{ marginTop: "10px" }}>
                          <GridItem xs={12}>
                            {mobileComponent.length > 0 ? <p className="TitleBold">Tùy chỉnh nhập thêm thông tin trên điện thoại:</p> : null}
                          </GridItem>
                          <GridItem xs={12} >
                            {mobileComponent.length > 0 ?
                              <div style={{ width: "367px", border: "2px solid grey", borderRadius: "10px", padding: "10px" }}>
                                {
                                  mobileComponent.map((prop, index) => {
                                    return (
                                      <GridContainer key={index} >
                                        <GridItem xs={12}>
                                          <p style={{ fontWeight: "600", marginBottom: "4px" }}>{prop.label}</p>
                                        </GridItem>
                                        <GridItem xs={12}>
                                          <img style={{ height: "50px" }} src={inputnotqr}></img>
                                        </GridItem>
                                      </GridContainer>
                                    )
                                  })
                                }
                              </div> : null}
                          </GridItem>
                        </GridContainer>
                        {actions_image.length > 0 ? <GridContainer style={{ marginTop: "10px" }}>
                          <GridItem xs={12} sm={12} md={12} lg={8}>
                            <p className="TitleBold">Hình ảnh mô tả:</p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={8}>
                            <GalleryModal list={actions_image} />
                          </GridItem>
                        </GridContainer> : null}
                      </div>
                    )
                  }
                ]}
              />
            </div>
          </div>
        ),
        footerTitle: ""
      },
      )
    }
    if (this.state.listProcess.specialStep.activateStamp.show === true) {
      temp.push({
        inverted: true,
        badgeColor: "success",
        authority: [],
        SP: false,
        SM: false,
        badgeIcon: <img src={require("../../../../assets/icons/" + "raLoXuLyBanThanhPham.svg")} style={{ width: "32px" }}></img>,
        title: this.state.listProcess.specialStep.activateStamp.name,
        titleColor: "success",
        body: (
          <div>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">Mô tả:</p>
              </GridItem>
              <GridItem>
                <p>
                  {this.state.listProcess.specialStep.activateStamp.description}
                </p>
              </GridItem>
            </GridContainer>
            <div style={{ width: "100%", marginTop: "-16px" }}>
              <Accordion
                color="success"
                collapses={[
                  {
                    title: 'Chi tiết',
                    content: (
                      <div style={{ width: "100%" }}>
                        <GridContainer>
                          <GridItem xs={12}>
                            <p className="TitleBold">Icon hiển thị trên điện thoại:</p>
                          </GridItem>
                          <GridItem xs={12} >
                            <div className="backGroundIconMobile">
                              <img src={require("../../../../assets/icons/mobile/" + "raLoXuLyBanThanhPham" + ".png")} style={{ width: "35px" }}></img>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )
                  }
                ]}
              />
            </div>
          </div>
        )
      })
    }
    if (this.state.listProcess.specialStep.packed.show === true) {
      temp.push({
        inverted: true,
        badgeColor: "success",
        authority: [],
        SP: false,
        SM: false,
        badgeIcon: <img src={require("../../../../assets/icons/" + "DongGoi.svg")} style={{ width: "32px" }}></img>,
        title: this.state.listProcess.specialStep.packed.name,
        titleColor: "success",
        body: (
          <div>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">Mô tả:</p>
              </GridItem>
              <GridItem>
                <p>
                  {this.state.listProcess.specialStep.packed.description}
                </p>
              </GridItem>
            </GridContainer>
            <div style={{ width: "100%", marginTop: "-16px" }}>
              <Accordion
                color="success"
                collapses={[
                  {
                    title: 'Chi tiết',
                    content: (
                      <div style={{ width: "100%" }}>
                        <GridContainer>
                          <GridItem xs={12}>
                            <p className="TitleBold">Icon hiển thị trên điện thoại:</p>
                          </GridItem>
                          <GridItem xs={12} >
                            <div className="backGroundIconMobile">
                              <img src={require("../../../../assets/icons/mobile/" + "DongGoi" + ".png")} style={{ width: "35px" }}></img>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )
                  }
                ]}
              />
            </div>
          </div>
        )
      })
    }
    this.setState({
      showTimeLine: temp
    })
  }
  render() {
    return (
      <div>
        <Card>
          <CardHeader>
            <p className="HeaderProcess">Thông tin quy trình</p>
          </CardHeader>
          <CardBody style={{ marginTop: "-20px" }}>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">Tên quy trình: </p>
              </GridItem>
              <GridItem>
                <p>{this.state.name}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">Ngày tạo: </p>
              </GridItem>
              <GridItem>
                <p>{this.state.date}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem>
                <p className="TitleBold">Số bước: </p>
              </GridItem>
              <GridItem>
                <p>{this.state.countStep}</p>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <GridContainer style={{ marginTop: "-50px" }}>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <Card plain>
              <CardHeader>
                <div className="BackgroundHeaderProcess">
                  <span>Chi tiết quy trình</span>
                </div>
              </CardHeader>
              <CardBody plain>
                <GridContainer style={{ marginTop: "-50px" }}>
                  <GridItem xs={12} md={12} sm={12} lg={12}>
                    <Timeline stories={this.state.showTimeLine} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    processList: state.processList.data
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getProcessList: () => dispatch({ type: "GET_PROCESS_LIST" }),
  }
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TimelinePage));
