import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography} from '@material-ui/core';
import strings from 'constants/strings';
import GridItem from "components/Grid/GridItem.jsx";
// core components
import CertMX1 from "assets/img/certs/vbc.png";
import CertMX2 from "assets/img/certs/fda.jpg";

import styles from './infoStyles';

class Certificates extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      open: false,
      tx:"",
      data: this.props.data,
    }
  }
  componentDidUpdate(preProps){
    if(preProps.data!==this.props.data){
      this.setState({
        data: this.props.data,
      })
    }
  }
render() {
  const { classes } = this.props;
  return (
    <div style = {{marginTop:"0px", display:"flex", justifyContent:"center", flexFlow:"column", alignItems:"center"}}>
    <GridItem xs={12} sm={12} md={10}>
    <div>
      <Typography className="xuatxutag" style={{marginTop:"50px"}}>Chứng nhận 1</Typography>
      <hr className="LineBreakDes"></hr>
    </div>
    <div className={classes.textLight}  style = {{textAlign:"center"}}>
      <img style={{width:"400px" }} src={this.state.data.certificates[0]} alt='SanPhamNongNghiepTieuBieu'/>
    </div>
    </GridItem>
    <GridItem xs={12} sm={12} md={10}>
    <div>
      <Typography className="xuatxutag" style={{marginTop:"50px"}}>Chứng nhận 2</Typography>
      <hr className="LineBreakDes"></hr>
    </div>
    <div className={classes.textLight}  style = {{textAlign:"center"}}>
      <img style={{width:"400px" }} src={this.state.data.certificates[1]} alt='SanPhamNongNghiepTieuBieu'/>
    </div>
    </GridItem>
  </div>
  );
}
}

export default withStyles(styles)(Certificates);