import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import RegisterTrial from "./RegisterTrial"
import loginFormStyle from "./loginFormStyle.jsx";
import logoVBC from "../../assets/img/logo_vbc.png"
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    return (
      <div className="backgroundLogin">
        <GridContainer justify="center" style={{display: 'flex', alignItems: 'center'}}>
          <GridItem xs={12} sm={6} md={4} className="vertical-center">
            <RegisterTrial/>
          </GridItem>
          {/* <div className="vertical-logo">
            <img src={logoVBC}/>
          </div> */}
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginFormStyle)(LoginPage);
