
import reducer from "reducers";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "saga/index.js";
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
let redux = require('redux');
const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    timeout: null,
   };
const pReducer = persistReducer(persistConfig, reducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ || redux.compose;
const sagaMiddleware = createSagaMiddleware()
export const store = redux.createStore(pReducer, composeEnhancers(redux.applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga)
export const persistor = persistStore(store);

