import React from "react";
import {withRouter} from "react-router-dom";
import cookie from 'react-cookies';
import axios from 'axios';
import moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import {LINK_API} from '../../../../constants/API'; 

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const style = {
  ...sweetAlertStyle,
  ...regularFormsStyle,
  ...customSelectStyle,
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom:"-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  alignLeft: {
    textAlign: "left"
  },
  selectFormDateControl: {
    ...regularFormsStyle.selectFormControl,
    marginTop:"8px",
    marginBottom:"8px"
  },
}

class CreateStamp extends React.Component {
  constructor(props) {
    // var moment = require('moment');
    // require('moment/locale/vn');
    super(props);
    this.state = {
      stampType: "",
      productType: "",
      productTypeGln: "",
      stampTypeState: "",
      productTypeState: "",
      numOfStamp: 0, 
      numOfStampState:"",
      numOfStampHelp: "",
      ProductGln: {},
      ProductList:[],
      numOfStampState: "",
      numOfStampHelp: "",
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleCreateNewProduct = this.handleCreateNewProduct.bind(this);
    this.user_gln = cookie.load("gln");
    this.stamp_type = {
      "BOX": "Tem thùng",
      "UNIT": "Tem sản phẩm"
    }
  }

  componentDidMount(){
    let that=this;
    axios.get(LINK_API.GET_ALL_PRODUCTS, {
      params: {
        gln: that.user_gln
      }
    })
    .then(function (response) {
      if (response.data.errorCode == 1) {
        let productGln = {};
        response.data.data.map((x) => {productGln[x.name]=x.info.gtin});

        that.setState({
          ProductList: response.data.data,
          ProductGln: productGln
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  handleSelect(event) {
    if (event.target.value === "-1") {
      this.handleCreateNewProduct();
    }
    else {
      this.setState({ 
        [event.target.name]: event.target.value
      });
      document.getElementById("noti").innerHTML = "";
    }
  }

  handleCreateNewProduct() {
    this.props.history.push("/admin/products/list?showCreateDialog=true")
  }

  handleAmountChange(event) {
    if (!this.verifyLength(event.target.value,1)) {
      this.setState({
        numOfStampState:"error",
        numOfStampHelp:"Chưa nhập số lượng tem"
      })
      return;
    }
    else if (!this.verifyNumber(event.target.value)) {
      this.setState({
        numOfStampState:"error",
        numOfStampHelp:"Số lượng tem không hợp lệ"
      })
      return;
    }
    else {
      this.setState({
        numOfStampState:"success",
        numOfStampHelp:"",
        numOfStamp:  event.target.value
      })
    }
  }

  submitData() {
    let data = {
      stampType: this.state.stampType,
      productTypeGln: this.state.ProductGln[this.state.productType],
      productType: this.state.productType,
      createAt: moment().format("DD/MM/YYYY"),
      numOfStamp:this.state.numOfStamp, 
    }

    if (!this.state.stampType) {
      document.getElementById("noti").innerHTML = "Vui lòng chọn loại tem!";
      return;
    } else {
      if (!this.state.productType) {
        document.getElementById("noti").innerHTML = "Vui lòng chọn loại nông sản!";
        return;
      } else {
        if (this.state.numOfStamp === 0) {
          //document.getElementById("noti").innerHTML = "Vui lòng nhập số lượng tem!";
          this.setState({
            numOfStampState:"error",
            numOfStampHelp:"Chưa nhập số lượng tem"
          })
          return;
          
        } else {
          if (data.stampType === "BOX") data.productType = "All";
          this.props.onConfirm(data);
        }
      }
    }


    this.props.onConfirm(data);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} style = {{margin:"auto"}}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Tạo - In tem</h4>
            </CardHeader>
            <CardBody style = {{textAlign:"center"}}> 
              <form>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="product-type"
                    className={classes.selectLabel}
                  >
                    Loại tem
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.stampType}
                    onChange={this.handleSelect}
                    inputProps={{
                      name: "stampType",
                      id: "stamp-type"
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Chọn Loại Tem
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value = {'BOX'}
                    >
                      Tem Thùng
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value = {'UNIT'}
                    >
                      Tem Sản Phẩm
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="farm-Product"
                    className={classes.selectLabel}>
                    Loại nông sản
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value = {this.state.productType}
                    onChange={this.handleSelect}
                    inputProps={{
                      name: "productType",
                      id: "product-type"
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Chọn Nông Sản
                    </MenuItem>
                    {this.state.ProductList.map(x=>(
                      <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={x.name}
                      >
                      {x.name}
                      </MenuItem>
                    ))}
                    <Button 
                      color = "rose" 
                      style = {{marginLeft:"15px"}} 
                      onClick = {this.handleCreateNewProduct}
                      value = "-1"> 
                      Thêm Nông Sản Mới
                    </Button> 
                  </Select>
                </FormControl>

                <CustomInput
                  success={this.state.numOfStampState === "success"}
                  error={this.state.numOfStampState === "error"}
                  labelText="Số lượng tem"
                  id="numOfStamp"
                  formControlProps={{
                    fullWidth: true,
                    onChange: this.handleAmountChange
                  }}
                  inputProps={{
                    type:"number"
                  }}
                  helpText={this.state.numOfStampHelp}
                />
                 <p
                    id="noti"
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      color: 'red',
                      fontFamily: 'Muli',
                      marginTop: 5,
                      marginBottom: 5,
                    }}>

                  </p>
                <Button color="success" onClick={this.submitData.bind(this)}>In Tem</Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    );
  }
}

export default withStyles(style)(withRouter(CreateStamp));
