import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Resizer from 'react-image-file-resizer';
// core components
import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/icons/selectImage.png"
import defaultAvatar from "assets/img/pictureicon.png";
import removeIcon from "assets/icons/deleteButton.png"
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: false,
      portal: false,
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      this.props.onImageSubmit(this.state.imagePreviewUrl);
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
  }
  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div>
        {this.state.file === null ? 
          <img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." />: (this.state.icon === true?
            <div className="addImageProcessIcon">
            <img src={this.state.imagePreviewUrl} style={{height:"60px", marginTop: "16px"}}></img>
            <img src={removeIcon} onClick={() => this.handleRemove()} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-173px", cursor: "pointer"}}/>
          </div>:(this.state.portal===true?<div className="addImageProcess" style={{backgroundImage:`URL(${this.state.imagePreviewUrl})`}}>
            <img src={removeIcon} onClick={() => this.handleRemove()} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
          </div>: <div className="addImageProcess" style={{backgroundImage:`URL(${this.state.imagePreviewUrl})`}}>
            <img src={removeIcon} onClick={() => this.handleRemove()} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
          </div>)) 
         }
        </div>
        <div>
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
