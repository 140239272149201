import React from "react";
import {withRouter} from "react-router-dom";
import cookie from 'react-cookies';
import axios from 'axios';
import moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/People";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import {LINK_API} from '../../../../constants/API'; 

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const style = {
  ...sweetAlertStyle,
  ...regularFormsStyle,
  ...customSelectStyle,
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom:"-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  alignLeft: {
    textAlign: "left"
  },
  selectFormDateControl: {
    ...regularFormsStyle.selectFormControl,
    marginTop:"8px",
    marginBottom:"8px"
  },
}

class CreateStamp extends React.Component {
  constructor(props) {
    // var moment = require('moment');
    // require('moment/locale/vn');
    super(props);
    this.state = {
      email: '',
      user_name: '',
      pw_hash: '',
      type: '',
      gln: '',
      address: '',
      phone: '',
      website: '',
      location_gps: '',
      standard: ''
    };
    this.handleSimple = this.handleSimple.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.verifyLength = this.verifyLength.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.user_gln = cookie.load("gln");
    this.stamp_type = {
      "ADMIN": "ADMIN",
      "FARM": "FARM",
      "MANUFACTURER": "MANUFACTURER"
    }
  }

  componentDidMount(){
    let that=this;
    axios.get(LINK_API.GET_ALL_PRODUCTS, {
      params: {
        gln: that.user_gln
      }
    })
    .then(function (response) {
      if (response.data.errorCode == 1) {
        let productGln = {};
        response.data.data.map((x) => {productGln[x.name]=x.info.gtin});

        that.setState({
          ProductList: response.data.data,
          ProductGln: productGln
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  handleSelect(event) {
    if (event.target.value === "-1") {
      this.handleCreateNewProduct();
    }
    else {
      this.setState({ 
        [event.target.name]: event.target.value
      });
      document.getElementById("noti").innerHTML = "";
    }
  }

  handleCreateNewProduct() {
    this.props.history.push("/admin/adminonly/manage")
  }

  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  submitData() {
    let { email, user_name, password, type, gln, address, phone, website, location_gps, standard } = this.state
    let data = {
      email,
      user_name,
      pw_hash: password,
      type,
      gln,
      address,
      phone,
      website,
      location_gps,
      standard
    }

    if (!email || !this.verifyEmail(email)) {
      this.setState({
        emailState: "error",
        emailHelp: "Email không hợp lệ"
      })
      return;
    }
    if (!user_name) {
      this.setState({
        userNameState: "error",
        userNameHelp: "Thiếu tên hợp tác xã"
      })
      return;
    }
    if (!password || password.length < 6) {
      this.setState({
        passState: "error",
        passHelp: "Mật khẩu tối thiểu 6 kí tự"
      })
      return;
    }
    if (!gln || gln.length !== 13 || isNaN(gln)) {
      this.setState({
        glnState: "error",
        glnHelp: "Gln không hợp lệ"
      })
      return;
    }
    this.props.onConfirm(data);
  }

  handleChange (name, event) {
    if (name === 'user_name') {
      if (!this.verifyLength(event.target.value, 1)) {
        this.setState({
          userNameState: "error",
          userNameHelp: "Thiếu tên hợp tác xã"
        })
        return;
      }
      else {
        this.setState({
          userNameState: "success",
          userNameHelp: "",
          user_name: event.target.value
        })
      }
    }
    if (name === 'gln') {
      if (!event.target.value || isNaN(event.target.value) || event.target.value.length !== 13) {
        this.setState({
          glnState: "error",
          glnHelp: "Thiếu mã gln"
        })
        return;
      }
      else {
        this.setState({
          glnState: "success",
          glnHelp: "",
          gln: event.target.value
        })
      }
    }
    if (name === 'email') {
      if (!event.target.value || !this.verifyEmail(event.target.value)) {
        this.setState({
          emailState: "error",
          emailHelp: "Email không hợp lệ"
        })
        return;
      }
      if (!this.verifyLength(event.target.value, 1)) {
        this.setState({
          emailState: "error",
          emailHelp: "Thiếu email"
        })
        return;
      }
      else {
        this.setState({
          emailState: "success",
          emailHelp: "",
          email: event.target.value
        })
      }
    }
    if (name === 'password') {
      if (!event.target.value || event.target.value.length < 6) {
        this.setState({
          passState: "error",
          passHelp: "Thiếu mật khẩu tối thiểu 6 kí tự"
        })
        return;
      }
      else {
        this.setState({
          passState: "success",
          passHelp: "",
          password: event.target.value
        })
      }
    }
    if (name === 'phone') {
      if (!event.target.value) {
        this.setState({
          phoneState: "error",
          phoneHelp: "Số điện thoại không hợp lệ"
        })
        return;
      }
      if (!this.verifyLength(event.target.value, 1)) {
        this.setState({
          phoneState: "error",
          phoneHelp: "Thiếu số điện thoại"
        })
        return;
      }
      else {
        this.setState({
          phoneState: "success",
          phoneHelp: "",
          phone: event.target.value
        })
      }
    }
    if (name === 'address') {
      if (!this.verifyLength(event.target.value, 1)) {
        this.setState({
          addressState: "error",
          addressHelp: "Thiếu địa chỉ"
        })
        return;
      }
      else {
        this.setState({
          addressState: "success",
          addressHelp: "",
          address: event.target.value
        })
      }
    }
    if (name === 'website') {
        this.setState({
          websiteState: "success",
          websiteHelp: "",
          website: event.target.value || ''
        })
    }
    if (name === 'standard') {
      this.setState({
        standardState: "success",
        standardHelp: "",
        standard: event.target.value || ''
      })
    }
  }
  handleSimple = event => {
    this.setState({ type : event.target.value });
    document.getElementById("noti").innerHTML = "";
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} style = {{margin:"auto"}}>
          <Card>
            <CardHeader color="success" icon>
              {/* <CardIcon color="success">
                <MailOutline />
              </CardIcon> */}
              <h4 className="cardtableLabel">Tạo tài khoản</h4>
            </CardHeader>
            <CardBody style = {{textAlign:"center", padding: '10px 40px'}}>
              <GridContainer>
                <GridContainer>
                <GridItem xs={6}>
                  <CustomInput
                  success={this.state.userNameState === "success"}
                  error={this.state.userNameState === "error"}
                  labelText="Tên hợp tác xã"
                  id="user_name"
                  className={classes.textField}
                  formControlProps={{
                    fullWidth: true,
                    onChange: (event) => this.handleChange('user_name', event)
                  }}
                  inputProps={{
                  }}
                  helpText={this.state.userNameHelp}
                />
                </GridItem>
                <GridItem xs={6}>
                <CustomInput
                  success={this.state.glnState === "success"}
                  error={this.state.glnState === "error"}
                  labelText="Mã gln:"
                  id="gln"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (event) => this.handleChange('gln', event)
                  }}
                  inputProps={{
                  }}
                  helpText={this.state.glnHelp}
                />
                </GridItem>
                </GridContainer>
                <GridContainer>
                <GridItem xs={6}>
                <CustomInput
                  success={this.state.emailState === "success"}
                  error={this.state.emailState === "error"}
                  labelText="Email"
                  id="account-email"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (event) => this.handleChange('email', event)
                  }}
                  inputProps={{
                  }}
                  helpText={this.state.emailHelp}
                />
                
                </GridItem>
                <GridItem xs={6}>
                <CustomInput
                  success={this.state.phoneState === "success"}
                  error={this.state.phoneState === "error"}
                  labelText="Số điện thoại:"
                  id="phone"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (event) => this.handleChange('phone', event)
                  }}
                  inputProps={{
                  }}
                  helpText={this.state.phoneHelp}
                />
                
                </GridItem>
                </GridContainer>
                <GridContainer>
                <GridItem xs={6}>
                <CustomInput
                  labelText="Mật khẩu"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.handleChange('password', event),
                    type:(this.state.showPassword)?"text":"password"
                  }}
                  value={this.state.password}
                />
                </GridItem>
                <GridItem xs={6}>
                <CustomInput
                  success={this.state.addressState === "success"}
                  error={this.state.addressState === "error"}
                  labelText="Địa chỉ:"
                  id="address"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (event) => this.handleChange('address', event)
                  }}
                  inputProps={{
                  }}
                  helpText={this.state.addressHelp}
                />
                </GridItem>
                </GridContainer>
                <GridContainer>
                <GridItem xs={6}>
                <CustomInput
                  success={this.state.websiteState === "success"}
                  error={this.state.websiteState === "error"}
                  labelText="Website:"
                  id="website"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (event) => this.handleChange('website', event)
                  }}
                  inputProps={{
                  }}
                  helpText={this.state.websiteHelp}
                />
                </GridItem>
                <GridItem xs={6}>
                <CustomInput
                  success={this.state.standardState === "success"}
                  error={this.state.standardState === "error"}
                  labelText="Tiêu chuẩn:"
                  id="standard"
                  formControlProps={{
                    fullWidth: true,
                    onChange: (event) => this.handleChange('standard', event)
                  }}
                  inputProps={{
                  }}
                  helpText={this.state.standardHelp}
                />
                </GridItem>
                <GridItem xs={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Chọn vai trò
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.type}
              onChange={this.handleSimple}
              inputProps={{
                name: "authority",
                id: "authority-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Choose City
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="M"
              >
                MANUFACTURER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="SM"
              >
                STAFFMANUFACTURER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="A"
              >
                AUTHORITY
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="D"
              >
                DELIVERIER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="SD"
              >
                STAFFDELIVERIER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="C"
              >
                CARRIER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="SC"
              >
                STAFFCARRIER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="R"
              >
                RETAILER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="SR"
              >
                STAFFRETAILER
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="P"
              >
                PROVIDER
              </MenuItem>
            </Select>
          </FormControl>
                </GridItem>
                </GridContainer>
                  {/* </FormControl> */}
                </GridContainer>
                 <p
                    id="noti"
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      color: 'red',
                      fontFamily: 'Muli',
                      marginTop: 5,
                      marginBottom: 5,
                    }}>

                  </p>
                <Button color="success" onClick={this.submitData.bind(this)}>Tạo</Button>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    );
  }
}

export default withStyles(style)(withRouter(CreateStamp));
