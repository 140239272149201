import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects'
import axios from "axios"
import { LINK_API } from "constants/API.js";
import responseCode from "constants/errorCode.js"
import Button from "components/CustomButtons/Button.jsx";
import {
    CREATE_PRODUCT,
    CREATE_AREA,
    CREATE_EMPLOYEE,
    CREATE_OBJECT,
    CREATE_OBJECT_PROCESS,
    CREATE_BRANCH,
    CREATE_DISTRIBUTOR,
    CREATE_PROCESS,
    CREATE_PLAN,

    UPDATE_BANNER,
    UPDATE_LOGO,
    GET_BRANCH_LIST,
    GET_BRANCH_LIST_FAILURE,
    GET_BRANCH_LIST_SUCCESS,

    GET_DISTRIBUTOR_LIST,
    GET_DISTRIBUTOR_LIST_FAILURE,
    GET_DISTRIBUTOR_LIST_SUCCESS,

    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_FAILURE,
    GET_PRODUCT_LIST_SUCCESS,

    GET_AREA_LIST,
    GET_AREA_LIST_FAILURE,
    GET_AREA_LIST_SUCCESS,

    GET_OBJECT_LIST,
    GET_OBJECT_LIST_FAILURE,
    GET_OBJECT_LIST_SUCCESS,

    GET_OBJECT_PROCESSING_LIST,
    GET_OBJECT_PROCESSING_LIST_FAILURE,
    GET_OBJECT_PROCESSING_LIST_SUCCESS,

    GET_OBJECT_DETAIL,
    GET_OBJECT_DETAIL_FAILURE,
    GET_OBJECT_DETAIL_SUCCESS,

    GET_OBJECT_PROCESSING_DETAIL,
    GET_OBJECT_PROCESSING_DETAIL_FAILURE,
    GET_OBJECT_PROCESSING_DETAIL_SUCCESS,

    GET_CROP_LIST,
    GET_CROP_LIST_FAILURE,
    GET_CROP_LIST_SUCCESS,

    GET_CROP_OBJECT,
    GET_CROP_OBJECT_FAILURE,
    GET_CROP_OBJECT_SUCCESS,

    GET_HARVEST_LIST,
    GET_HARVEST_LIST_FAILURE,
    GET_HARVEST_LIST_SUCCESS,

    GET_CROP_OBJECT_PROCESSING,
    GET_CROP_OBJECT_PROCESSING_FAILURE,
    GET_CROP_OBJECT_PROCESSING_SUCCESS,

    GET_EMPLOYEE_LIST,
    GET_EMPLOYEE_LIST_FAILURE,
    GET_EMPLOYEE_LIST_SUCCESS,

    GET_PROCESS_LIST,
    GET_PROCESS_LIST_FAILURE,
    GET_PROCESS_LIST_SUCCESS,

    PUSH_NOTI_PRODUCT_SUCCESS,
    PUSH_NOTI_PRODUCT_PROCESSING,
    DEACTIVE_NOTI_PRODUCT,

    SAVE_USER_INFO,
    SAVE_USER_INFO_SUCCESS,

    SAVE_NOTIFICATION,
    SAVE_NOTIFICATION_SUCCESS,
    CLEAR_NOTIFICATION,
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,

    ACTIVE_TOOLTIP,
    ACTIVE_TOOLTIP_SUCCESS,
    DEACTIVE_TOOLTIP,
    DEACTIVE_TOOLTIP_SUCCESS,
    GET_HARVEST_STATISTIC,
    HARVEST_STATISTIC_SUCCESS,
    HARVEST_STATISTIC_FAILURE,

    GET_BRANCH_STATISTIC,
    BRANCH_STATISTIC_SUCCESS,
    BRANCH_STATISTIC_FAILURE,

    GET_SUBSCRIPTION,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAILURE,

    CHECK_STATUS_EXPAND,
    CHECK_STATUS_EXPAND_SUCCESS,
    GET_PLAN_LIST,
    GET_PLAN_LIST_SUCCESS,
    GET_PLAN_LIST_FAILURE,

    RENDER_SELECT_CUSTOMER,
    RENDER_SELECT_CUSTOMER_SUCCESSFULL,
    RENDER_SELECT_CUSTOMER_FAILURE,

    GET_BOX_UNIT,
    GET_BOX_AND_UNIT_FAILURE,
    GET_BOX_AND_UNIT_SUCCESS,

    GET_UNPAID_LIST,
    GET_UNPAID_LIST_SUCCESS,
    GET_UNPAID_LIST_FAILURE,

    GET_CROP_STATISTIC,
    GET_CROP_STATISTIC_SUCCESS,
    GET_CROP_STATISTIC_FAILURE,

    GET_NUMBER_ACCESSES,
    GET_NUMBER_ACCESSES_SUCCESS,
    GET_NUMBER_ACCESSES_FAILURE,

    GET_LIST_APPROVE,
    GET_LIST_APPROVE_SUCCESS,
    GET_LIST_APPROVE_FAILURE,

    GET_PRODUCT_TO_BRANCHS,
    GET_PRODUCT_TO_BRANCHS_SUCCESS,
    GET_PRODUCT_TO_BRANCHS_FAILURE,

    GET_PRODUCT_TO_DISTRIBUTOR,
    GET_PRODUCT_TO_DISTRIBUTOR_SUCCESS,
    GET_PRODUCT_TO_DISTRIBUTOR_FAILURE,

    GET_ACTIVE_STAMP_PRODUCT_TYPE_SUCCESS,
    GET_ACTIVE_STAMP_PRODUCT_TYPE_FAILURE,
    GET_ACTIVE_STAMP_PRODUCT_TYPE,

    GET_NUMBER_CROP_PRODUCT_TYPE_SUCCESS,
    GET_NUMBER_CROP_PRODUCT_TYPE_FAILURE,
    GET_NUMBER_CROP_PRODUCT_TYPE,

    GET_STAMP_ACTIVE_GENERAL,
    GET_STAMP_ACTIVE_GENERAL_SUCCESS,
    GET_STAMP_ACTIVE_GENERAL_FAILURE,

    RENDER_EMPLOYEE_LIST,
    RENDER_EMPLOYEE_LIST_FAILURE,
    RENDER_EMPLOYEE_LIST_SUCCESS,

    RENDER_AREA_LIST,
    RENDER_AREA_LIST_SUCCESS,
    RENDER_AREA_LIST_FAILURE,

    UPDATE_INFO_AREA,
    UPDATE_INFO_PRODUCT,
    UPDATE_INFO_EMPLOYEE,

    GET_INFO_ACTIVE_STAMP,
    GET_INFO_ACTIVE_STAMP_SUCCESS,
    GET_INFO_ACTIVE_STAMP_FAILURE,

    GET_STAMP_LIST,
    CHECK_STATUS_STAMP_SUCCESS, CHECK_STATUS_STAMP,

    EDIT_PROCESS,

    GET_OBJECT_IN_AREA,
    GET_OBJECT_IN_AREA_SUCCESS, 
    GET_OBJECT_IN_AREA_FAILURE,

    GET_LIST_BOX_OWN,
    GET_LIST_BOX_OWN_SUCCESS,
    GET_LIST_BOX_OWN_FAILURE,

    GET_STAMP_INCASE,
    GET_STAMP_INCASE_SUCCESS,
    GET_STAMP_INCASE_FAILURE,

    GET_LIST_COW,
    GET_LIST_COW_SUCCESS,
    GET_LIST_COW_FAILURE,

    GET_FILTER_LIST_CATTLE,
    GET_FILTER_LIST_CATTLE_SUCCESS,
    GET_FILTER_LIST_CATTLE_FAILURE,

    RENDER_UNIT_STAMP_SUCCESS, 
    RENDER_UNIT_STAMP, 
    RENDER_UNIT_STAMP_FAILURE,

    CLEAN_DATA_TABLE_COW


} from "actions/types.js"
import strings from '../constants/strings';

const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx').Transaction;
const delay = (ms) => new Promise(res => setTimeout(res, ms))

//Get data from redux
const getToken = (state) => state.userInfo
const getListObject = (state) => state.objectList
const getNotification = (state) => state.notification
const getFullCowList = (state) => state.cowList.fullData
const getToTalItem = (state) => state.cowList.totalItem

//Save User Info
function* saveUserInfo(data) {
    yield put({ type: SAVE_USER_INFO_SUCCESS, data: data.data, password: data.password })
}

//Check Status Sidebar
function* checkStatusSidebar(data) {
    yield put({ type: CHECK_STATUS_EXPAND_SUCCESS, active: data.data })
}
//Save notification
function* saveNotification(data) {
    const notification = yield select(getNotification)
    const response = yield call(manageNotification, notification, data.data);
    yield put({ type: SAVE_NOTIFICATION_SUCCESS, data: response })
}

function manageNotification(noti, data) {
    let temp_noti = noti.data;
    const x = noti.data.length;
    if (x < 5) {
        temp_noti.unshift(data);
        return temp_noti;
    } else {
        temp_noti.pop();
        temp_noti.unshift(data);
        return temp_noti;
    }
}

//Clear notification
function* clearNotification() {
    yield put({ type: SAVE_NOTIFICATION_SUCCESS, data: [] })
}

//Update Banner
function* watchUpdateBanner(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(updateBanner, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật banner thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Cập nhật banner thành công" } })
            yield put({ type: GET_USER_PROFILE });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`]) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } });
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật banner không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật banner không thành công" } });
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật banner không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật banner không thành công" } });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }
}
function updateBanner(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_BANNER, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Update logo
function* watchUpdateLogo(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(updateLogo, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật logo thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Cập nhật banner thành công" } })
            yield put({ type: GET_USER_PROFILE });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`].vi) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } });
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật logo không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật logo không thành công" } });
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }
        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
        else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật logo không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật logo không thành công" } });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
    }
}

function updateLogo(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_LOGO, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Get user profile
function* getUserProfile() {
    try {
        const token = yield select(getToken);
        const response = yield call(fetchProfile, token.data.token);
        const data = response.data;
        yield put({ type: GET_USER_PROFILE_SUCCESS, data: data.data });
    } catch (error) {
        yield put({ type: GET_USER_PROFILE_FAILURE });
    }

}
function fetchProfile(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_PROFILE
    })
}
//Get product list
function* getProductTypeList() {
    try {
        const token = yield select(getToken);
        const response = yield call(fetchProduct, token.data.token);
        const data = response.data;
        yield put({ type: GET_PRODUCT_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_PRODUCT_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
async function fetchProduct(checktoken) {
    axios.defaults.headers.common['Authorization'] = await checktoken;
    return axios({
        method: "GET",
        url: LINK_API.CREATE_PRODUCT
    })
}

//Create Product
function* watchCreateProduct(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createProduct, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo sản phẩm thành công" } })
            yield put({ type: GET_PRODUCT_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`].vi) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo sản phẩm không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo sản phẩm không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }

}
function createProduct(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_PRODUCT, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Update product info
function* watchUpdateProductInfo(data) {
    let check = true;
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response1 } = yield call(unpdateInfoProduct, data.datainfo, token.data.token);
    if (response1.data.errorCode !== 1) {
        check = false;
    }
    //Image of product (avatar)
    if (data.dataimage_delete.image.length > 0) {
        const { response2 } = yield call(deleteImageProduct, data.dataimage_delete, token.data.token);
        if (response2.data.errorCode === 1) {
            if (data.dataimage_add.image.length > 0) {
                const { response3 } = yield call(addImageProduct, data.dataimage_add, token.data.token);
                if (response3.data.errorCode !== 1) {
                    check = false
                }
            }
        } else {
            check = false;
        }
    } else {
        if (data.dataimage_add.image.length > 0) {
            const { response3 } = yield call(addImageProduct, data.dataimage_add, token.data.token);
            if (response3.data.errorCode !== 1) {
                check = false;
            }
        }
    }

    //Certificates
    if (data.datacert_delete.certificates.length > 0) {
        const { response4 } = yield call(deleteCertificateProduct, data.datacert_delete, token.data.token);
        if (response4.data.errorCode === 1) {
            if (data.datacert_add.certificates.length > 0) {
                const { response5 } = yield call(addCertificateProduct, data.datacert_add, token.data.token);
                if (response5.data.errorCode !== 1) {
                    check = false
                }
            }
        } else {
            check = false;
        }
    } else {
        if (data.datacert_add.certificates.length > 0) {
            const { response5 } = yield call(addCertificateProduct, data.datacert_add, token.data.token);
            if (response5.data.errorCode !== 1) {
                check = false;
            }
        }
    }


    if (check === true) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin sản phẩm thành công", color: "success" });
        yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Cập nhật thông tin sản phẩm thành công" } })
        yield put({ type: GET_PRODUCT_LIST });
        yield delay(3000);
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin sản phẩm không thành công", color: "danger" });
        yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật thông tin sản phẩm không thành công" } })
        yield delay(3000);
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    }
}

function unpdateInfoProduct(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_PRODUCT, data)
        .then(response1 => ({ response1 }))
        .catch(error1 => ({ error1 }))
}
function deleteImageProduct(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_IMAGE_PRODUCT_DELETE, data)
        .then(response2 => ({ response2 }))
        .catch(error2 => ({ error2 }))
}
function addImageProduct(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_IMAGE_PRODUCT_ADD, data)
        .then(response3 => ({ response3 }))
        .catch(error3 => ({ error3 }))
}
function deleteCertificateProduct(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_IMAGE_PRODUCT_DELETE_CERTIFICATES, data)
        .then(response4 => ({ response4 }))
        .catch(error4 => ({ error4 }))
}
function addCertificateProduct(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_IMAGE_PRODUCT_ADD_CERTIFICATES, data)
        .then(response5 => ({ response5 }))
        .catch(error5 => ({ error5 }))
}
//Create Branch
function* watchCreateBranch(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createBranch, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo chi nhánh thành công" } })
            yield put({ type: GET_BRANCH_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`].vi) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo chi nhánh không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
        else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo chi nhánh không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
    }
}
function createBranch(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_BRANCH, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Get area list
function* getBranchList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchBranch, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_BRANCH_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_BRANCH_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchBranch(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_BRANCH_LIST
    })
}
//Create Production Area
function* watchCreateArea(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createArea, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo vùng sản xuất thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo vùng sản xuất thành công" } })
            yield put({ type: GET_AREA_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`].vi) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo vùng sản xuất không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo vùng sản xuất không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo vùng sản xuất không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo vùng sản xuất không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }

}
function createArea(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_PRODUCTION_AREA, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Update Info Area
function* watchUpdateInfoArea(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(updateInfoArea, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin vùng sản xuất thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Cập nhật thông tin vùng sản xuất thành công" } })
            yield put({ type: GET_AREA_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`].vi) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin vùng sản xuất không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật thông tin vùng sản xuất không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin vùng sản xuất không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật thông tin vùng sản xuất không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
    }

}
function updateInfoArea(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.put(LINK_API.CREATE_PRODUCTION_AREA, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Get area list
function* renderArea() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchArea, tokenInfo.data.token);
        const data = response.data.data;
        let temp = [];
        let label = {};
        for (let i = 0; i < data.length; i++) {
            temp.push({
                value: data[i].uuid,
                label: data[i].name + " - " + data[i].uuid
            })
            label[`${data[i].uuid}`] = {
                label: data[i].name + " - " + data[i].uuid
            }
        }
        yield put({ type: RENDER_AREA_LIST_SUCCESS, data: temp, label: label, errorCode: 1 });
    } catch (error) {
        yield put({ type: RENDER_AREA_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}

function* getAreaList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchArea, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_AREA_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_AREA_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchArea(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_AREA_LIST
    })
}

//Get own list
function* getOwnList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchOwnList, tokenInfo.data.token);
        const data = response.data;
        console.log("data", data)
        yield put({ type: GET_LIST_BOX_OWN_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_LIST_BOX_OWN_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchOwnList(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_LIST_COW
    })
}

//Render select stamp unit when shipment a new box
function* renderSelectUnitStamp() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchOwnList, tokenInfo.data.token);
        const data = response.data.data;
        let listStamp = [];
        for(let i=0;i<data.length;i++){
            let check = data[i].product;
            for(let j=0; j<check.length;j++){
                listStamp.push({
                    value: check[j].id,
                    label: check[j].id
                })
            }
        }
        yield put({ type: RENDER_UNIT_STAMP_SUCCESS, data: listStamp, errorCode: 1 });
    } catch (error) {
        yield put({ type: RENDER_UNIT_STAMP_FAILURE, payload: "", errorCode: 0 });
    }
}

//Get crop object list
function* getCropObjectList(uuid) {
    let tokenInfo = yield select(getToken);
    const response = yield call(fetchCropObject, uuid.uuid, tokenInfo.data.token);
    const data = response.data;
    if (data.errorCode === 1) {
        yield put({ type: GET_CROP_OBJECT_SUCCESS, data: data.data, errorCode: data.errorCode });
    } else {
        yield put({ type: GET_CROP_OBJECT_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchCropObject(uuid, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_CROP_LIST + uuid,
    })
}

//Get crop object process list
function* getCropObjectProcessList(uuid) {
    let tokenInfo = yield select(getToken);
    const response = yield call(fetchCropObjectProcess, uuid.uuid, tokenInfo.data.token);
    const data = response.data;
    if (data.errorCode === 1) {
        yield put({ type: GET_CROP_OBJECT_PROCESSING_SUCCESS, data: data.data, errorCode: data.errorCode });
    } else {
        yield put({ type: GET_CROP_OBJECT_PROCESSING_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchCropObjectProcess(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_CROP_OBJECT_PROCESS,
    })
}

//Get crop list
function* getCropList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchCrop, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_CROP_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_CROP_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchCrop(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.CURRENT_CROP
    })
}

//Get harvest list
function* getHarvestList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchHarvest, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_HARVEST_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_HARVEST_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchHarvest(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_HARVEST_LIST
    })
}

//Get distributor list
function* getDistributorList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchDistributor, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_DISTRIBUTOR_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_DISTRIBUTOR_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchDistributor(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_DISTRIBUTOR_LIST
    })
}

//Create Distributor
function* watchCreateDistributor(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createDistributor, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhà phân phối thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo nhà phân phối thành công" } })
            yield put({ type: GET_DISTRIBUTOR_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`]) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhà phân phối không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo nhà phân phối không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    }
    else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhà phân phối không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo nhà phân phối không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }
}
function createDistributor(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_DISTRIBUTOR, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Create Process
function* watchCreateProcess(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response } = yield call(createProcess, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo quy trình thành công" } })
            yield put({ type: GET_PROCESS_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo quy trình không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
    } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình không thành công", color: "danger" });
        yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo quy trình không thành công" } })
        yield delay(3000);
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    }

}
function createProcess(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.REGISTER_PROCESS, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}


//Edit Process
function* watchEditProcess(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response } = yield call(editProcess, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật quy trình thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Cập nhật quy trình thành công" } })
            yield put({ type: GET_PROCESS_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật quy trình không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật quy trình không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
    } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật quy trình không thành công", color: "danger" });
        yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật quy trình không thành công" } })
        yield delay(3000);
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    }

}
function editProcess(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.put(LINK_API.EDIT_PROCESS, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Get process list
function* getProcessList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchProcess, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_PROCESS_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_PROCESS_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchProcess(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_PROCESS_LIST
    })
}


//Get object process list
function* getObjectProcessList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchObjectProcess, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_OBJECT_PROCESSING_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_OBJECT_PROCESSING_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchObjectProcess(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.CREATE_OBJECT_PROCESS
    })
}

//Get object list
function* getObjectList(index) {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchObject, tokenInfo.data.token, index.index);
        let list = yield select(getListObject)
        let temp = list.data;
        let data = [];

        if(temp.length > 0) {
            data = response.data.data.concat(temp);
            data.reverse();
        }else{
            data = response.data.data
            data.reverse();
        }
        yield put({ type: GET_OBJECT_LIST_SUCCESS, data: data, errorCode: response.data.errorCode, count: response.data.totalPage });
    } catch (error) {
        yield put({ type: GET_OBJECT_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchObject(checktoken, index) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.CREATE_OBJECT_PRODUCTION + index + "&size=5"
    })
}

//Get object area
function* getObjectAreaList(index) {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchObjectArea, tokenInfo.data.token, index.index);
        const data = response.data.data;
        yield put({ type: GET_OBJECT_IN_AREA_SUCCESS, data: data, errorCode: response.data.errorCode });
    } catch (error) {
        yield put({ type: GET_OBJECT_IN_AREA_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchObjectArea(checktoken, index) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_OBJECT_IN_AREA + index
    })
}

//Create Object Production
function* watchCreateObject(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createObject, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tượng sản xuất thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo đối tượng sản xuất thành công" } })
            yield put({ type: GET_OBJECT_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`]) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tượng sản xuất không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo đối tượng sản xuất không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tượng sản xuất không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo đối tượng sản xuất không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }

}
function createObject(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_OBJECT_PRODUCTION, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Create Object Process
function* watchCreateObjectProcess(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createObjectProcess, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tượng chế biến thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo đối tượng chế biến thành công" } })
            yield put({ type: GET_OBJECT_PROCESSING_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`]) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tượng chế biến không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo đối tượng chế biến không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tượng chế biến không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo đối tượng chế biến không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }

}
function createObjectProcess(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_OBJECT_PROCESS, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}


//Get object detail
function* getObjectDetail(uuid) {
    let tokenInfo = yield select(getToken);
    const response = yield call(fetchObjectDetail, uuid.uuid, tokenInfo.data.token);
    const data = response.data;
    if (data.errorCode === 1) {
        yield put({ type: GET_OBJECT_DETAIL_SUCCESS, data: data.data, errorCode: data.errorCode });
    } else {
        yield put({ type: GET_OBJECT_DETAIL_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchObjectDetail(uuid, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_A_PRODUCTION + uuid,
    })
}


//Get object process detail
function* getObjectProcessDetail(uuid) {
    let tokenInfo = yield select(getToken);
    const response = yield call(fetchObjectProcessDetail, uuid.uuid, tokenInfo.data.token);
    const data = response.data;
    if (data.errorCode === 1) {
        yield put({ type: GET_OBJECT_PROCESSING_DETAIL_SUCCESS, data: data.data, errorCode: data.errorCode });
    } else {
        yield put({ type: GET_OBJECT_PROCESSING_DETAIL_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchObjectProcessDetail(uuid, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_OBJECT_PROCESS_DETAIL + uuid,
    })
}

//Get employee list
function* renderEmployee() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchEmployee, tokenInfo.data.token);
        const data = response.data.data;
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                value: data[i].uuid,
                label: data[i].name + " - " + data[i].uuid,
            })
        }
        yield put({ type: RENDER_EMPLOYEE_LIST_SUCCESS, data: options, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: RENDER_EMPLOYEE_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}

function* getEmployeeList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchEmployee, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_EMPLOYEE_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_EMPLOYEE_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchEmployee(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_EMPLOYEE_LIST
    })
}
//Update info Employee
function* watchUpdateEmployee(data) {

    try {
        let tokenInfo = yield select(getToken);
        const {response} = yield call(updateInfoEmployee, data.data, tokenInfo.data.token);
        const result = response.data;
        if(result.errorCode === 1){
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhân viên thành công", color: "success" });
                    yield put({ type: GET_EMPLOYEE_LIST });
                    yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Cập nhật thông tin nhân viên thành công" } })
                    yield delay(3000);
                    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }else{
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhân viên không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật thông tin nhân viên không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }
    } catch (error) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhân viên không thành công", color: "danger" });
        yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Cập nhật thông tin nhân viên không thành công" } })
        yield delay(3000);
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    }
}
function updateInfoEmployee(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.UPDATE_INFO_EMPLOYEE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
//Create Employee
function* watchCreateEmployee(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createEmployee, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            //Sign Raw transaction
            const dataraw = data.data;
            var bytes = CryptoJS.AES.decrypt(token.data.privateEncrypted.toString(), token.password);
            const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8);
            const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
            const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
            const tx = new EthereumTx(result.data.raw, { chain: 4 });
            tx.sign(Buffer_privatekey);
            const rawTx = '0x' + tx.serialize().toString('hex');
            dataraw.raw = rawTx;
            dataraw.uuid = result.data.uuid;
            dataraw.avatar = data.avatar;
            //Send raw and data to backend
            const { final_result, error_raw } = yield call(sendRawEmployee, dataraw, token.data.token);
            if (final_result) {
                if (final_result.data.errorCode === 1) {
                    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên thành công", color: "success" });
                    yield put({ type: GET_EMPLOYEE_LIST });
                    yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo nhân viên thành công" } })
                    yield delay(3000);
                    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
                } else {
                    if (responseCode[`${final_result.data.data.name}`]) {
                        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${final_result.data.data.name}`].vi, color: "danger" });
                        yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${final_result.data.data.name}`].vi } })
                        yield delay(3000);
                        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
                    } else {
                        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên không thành công", color: "danger" });
                        yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo nhân viên không thành công" } })
                        yield delay(3000);
                        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
                    }

                }
            } else {
                if (error_raw.response.data.name) {
                    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error_raw.response.data.name}`].vi, color: "danger" });
                    yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error_raw.response.data.name}`].vi } })
                    yield delay(3000);
                    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
                } else {
                    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên không thành công", color: "danger" });
                    yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo nhân viên không thành công" } })
                    yield delay(3000);
                    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
                }

            }
        } else {
            if (responseCode[`${result.name}`]) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }
            else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo nhân viên không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }
        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo nhân viên không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }

}
function createEmployee(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_EMPLOYEE, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}
function sendRawEmployee(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.SEND_RAW_EMPLOYEE, data)
        .then(final_result => ({ final_result }))
        .catch(error_raw => ({ error_raw }))
}

//Active Tooltip
function* activeTooltip() {
    yield put({ type: ACTIVE_TOOLTIP_SUCCESS, parent: false, child: true, top: false });
}

//Deactive Tooltip
function* deactiveTooltip() {
    yield put({ type: DEACTIVE_TOOLTIP_SUCCESS, parent: true, child: true, top: true });
}

//Get harvest list
function* getHarvestStatistic() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchHarvestStatistic, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: HARVEST_STATISTIC_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: HARVEST_STATISTIC_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchHarvestStatistic(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.HARVEST_STATISTIC
    })
}

//Get harvest list
function* getCropStatistic(productObjectId) {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchCropStatistic, productObjectId, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_CROP_STATISTIC_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_CROP_STATISTIC_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchCropStatistic(productObjectId, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_CROP_STATISTIC + productObjectId
    })
}


//Get branch list
function* getBranchStatistic() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchBranchStatistic, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: BRANCH_STATISTIC_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: BRANCH_STATISTIC_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchBranchStatistic(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.BRANCH_STATISTIC
    })
}


//Get subcription list
function* getSubscription() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchSubscription, tokenInfo.data.token);
        const data = response.data;
        console.log("data", data)
        yield put({ type: SUBSCRIPTION_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: SUBSCRIPTION_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchSubscription(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_SUBSCRIPTION
    })
}

//Get plan list
function* getPlanList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchPlan, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_PLAN_LIST_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_PLAN_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchPlan(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_PLAN_LIST
    })
}

//Create Plan
function* watchCreatePlan(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: "warning" });
    yield delay(2000);
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
    const token = yield select(getToken);
    const { response, error } = yield call(createPlan, data.data, token.data.token);
    if (response) {
        const result = response.data;
        if (result.errorCode === 1) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo gói thành công", color: "success" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "success", message: "Tạo gói thành công" } })
            yield put({ type: GET_PLAN_LIST });
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            if (responseCode[`${result.name}`]) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${result.name}`].vi } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo gói không thành công", color: "danger" });
                yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo gói không thành công" } })
                yield delay(3000);
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
            }

        }
    } else {
        if (error.response.data.name) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo gói không thành công", color: "danger" });
            yield put({ type: SAVE_NOTIFICATION, data: { type: "error", message: "Tạo gói không thành công" } })
            yield delay(3000);
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false });
        }

    }

}
function createPlan(data, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios.post(LINK_API.CREATE_PLAN, data)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//Get plan list
function* getCustomerList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchCustomer, tokenInfo.data.token);
        const data = response.data.data;
        let length = data.length;
        let options = [];
        for (let i = 0; i < length; i++) {
            if (data[i].active === true) {
                options.push({
                    value: data[i].uuid,
                    label: data[i].uuid + " - " + data[i].name,
                })
            }
        }
        console.log(options)
        yield put({ type: RENDER_SELECT_CUSTOMER_SUCCESSFULL, data: options, });
    } catch (error) {
        yield put({ type: RENDER_SELECT_CUSTOMER_FAILURE, data: "" });
    }
}
function fetchCustomer(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_COMPANY
    })
}

//Get box Stamp and Unit Stamp list
function* getStampBoxandUnit() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchStampandUnit, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_BOX_AND_UNIT_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_BOX_AND_UNIT_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchStampandUnit(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_BOX_UNIT
    })
}

//Filter Unpaid list
function* getUnpaidList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchStampandUnit, tokenInfo.data.token);
        const data = response.data;
        let unPaidList = [];
        for (let i = 0; i < data.data.length; i++) {
            for (let j = 0; j < data.data[i].product.length; j++) {
                if (data.data[i].product[j].status !== 1) {
                    unPaidList.push(data.data[i].product[j]);
                }
            }
        }
        yield put({ type: GET_UNPAID_LIST_SUCCESS, data: unPaidList, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_UNPAID_LIST_FAILURE, payload: "", errorCode: 0 });
    }
}

//Get number accesses
function* getNumberAccesses() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchNumberAccesses, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_NUMBER_ACCESSES_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_NUMBER_ACCESSES_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchNumberAccesses(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_NUMBER_ACCESSES
    })
}

//Get number product to branchs
function* getNumberProductToBranch() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchNumberProductToBranch, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_PRODUCT_TO_BRANCHS_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_PRODUCT_TO_BRANCHS_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchNumberProductToBranch(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_PRODUCT_TO_BRANCH
    })
}

//Get number product to distributor
function* getNumberProductToDistributor() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchNumberProductToDistributor, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_PRODUCT_TO_DISTRIBUTOR_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_PRODUCT_TO_DISTRIBUTOR_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchNumberProductToDistributor(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_PRODUCT_TO_DISTRIBUTOR
    })
}

//Get approve list
function* getApproveList() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchApproveList, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_LIST_APPROVE_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_LIST_APPROVE_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchApproveList(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_APPORVE_LIST
    })
}


//Get active Stamp Product Type
function* getActiveStampProductType() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchActiveStampProductType, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_ACTIVE_STAMP_PRODUCT_TYPE_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_ACTIVE_STAMP_PRODUCT_TYPE_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchActiveStampProductType(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_ACTIVE_STAMP_PRODUCT_TYPE
    })
}

//Get Number Crop Product Type
function* getNumberCropProductType() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchNumberCropProductType, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_NUMBER_CROP_PRODUCT_TYPE_SUCCESS, data: data.data, errorCode: data.errorCode, check: true });
    } catch (error) {
        yield put({ type: GET_NUMBER_CROP_PRODUCT_TYPE_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchNumberCropProductType(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_NUMBER_CROP_PRODUCT_TYPE
    })
}


//Get general stamp active
function* getGeneralStampActive() {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchGeneralStampActive, tokenInfo.data.token);
        const data = response.data;
        yield put({ type: GET_STAMP_ACTIVE_GENERAL_SUCCESS, data: data.data, errorCode: data.errorCode});
    } catch (error) {
        yield put({ type: GET_STAMP_ACTIVE_GENERAL_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchGeneralStampActive(checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_STAMP_ACTIVE_GENERAL
    })
}


//Render active stamp filter - Thong ke chung
function* getInfoActiveStamp(data) {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchInfoActiveStamp, data.startdate, data.enddate, tokenInfo.data.token);
        const check  = response.data;
        yield put({ type: GET_INFO_ACTIVE_STAMP_SUCCESS, data: data.data, errorCode: check.errorCode });
        yield put({ type: CHECK_STATUS_STAMP_SUCCESS, data: false});
    } catch (error) {
        yield put({ type: GET_INFO_ACTIVE_STAMP_FAILURE, payload: "", errorCode: 0 });
    }
}

function fetchInfoActiveStamp(startdate, enddate, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_STAMP_WITH_DATE + "?startTime=" + startdate + "&endTime=" + enddate
    })
}

//Get Stamp list
function* getStampList(data) {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchStampList, data.data, tokenInfo.data.token);
        const check  = response.data;
        var x = check.data;
        let temp = [];
        for(let i = 0 ; i < x.length;i++){
            temp.push({
                value: x[i].id,
                label: x[i].id,
            })
        }
        console.log(temp)
        yield put({ type: GET_INFO_ACTIVE_STAMP_SUCCESS, data: check.data, list: temp , errorCode: check.errorCode});
        yield put({ type: CHECK_STATUS_STAMP_SUCCESS, data: false});
    } catch (error) {
        yield put({ type: GET_INFO_ACTIVE_STAMP_FAILURE, payload: "", errorCode: 0 });
    }
}

function fetchStampList(uuid, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_STAMP_IMPORT
    })
}

//Get Stamp list incase
function* getStampIncase(data) {
    try {
        let tokenInfo = yield select(getToken);
        const response = yield call(fetchStampInCaseList, data.data, tokenInfo.data.token);
        const check  = response.data;
        yield put({ type: GET_STAMP_INCASE_SUCCESS, data: check.data, errorCode: check.errorCode});
    } catch (error) {
        yield put({ type: GET_STAMP_INCASE_FAILURE, payload: "", errorCode: 0 });
    }
}

function fetchStampInCaseList(uuid, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_STAMP_IMPORT
    })
}

//Set Status Stamp
function* setStatusStamp(data) {
    yield put({ type: CHECK_STATUS_STAMP_SUCCESS, data: data.data});
}

//Get list cow
function* getListCow(page) {
    try {
        let tokenInfo = yield select(getToken);
        const fullData = yield select(getFullCowList)
        const totalItem = yield select(getToTalItem)
        if(fullData[`${page.page}`]){
            yield put({
                type: GET_LIST_COW_SUCCESS,
                data: fullData[`${page.page}`],
                fullData: fullData,
            });
        }
        const response = yield call(fetchListCow, page.page, tokenInfo.data.token);
        const data  = response.data.data;
        if(totalItem !== response.data.totalItem){
            const newList = data.map((product, idx)=> {
                return{
                    ...product,
                    LNC: product.LNC ? product.LNC : "",
                    boxid: product.id,
                    location: product.placeName + " - " + product.placeUuid,
                    NON_GS1: product.NON_GS1 ? product.NON_GS1: "0000000000000",
                    RFID: product.RFID ? product.RFID :"0000000000000",
                    status: product.status === 1 ? strings.slaughter : strings.alive
                }
            })
            fullData[`${page.page}`] = newList.reverse();
            yield put({
                type: GET_LIST_COW_SUCCESS,
                data: newList.reverse(),
                errorCode: response.data.errorCode,
                totalItem: response.data.totalItem,
                totalPage: response.data.totalPage,
                fullData: fullData
            });
        } else {
            if (!fullData[`${page.page}`]) {
                const newList = data.map((product, idx)=> {
                    return{
                        ...product,
                        LNC: product.LNC ? product.LNC : "",
                        boxid: product.id,
                        location: product.placeName + " - " + product.placeUuid,
                        NON_GS1: product.NON_GS1 ? product.NON_GS1: "0000000000000",
                        RFID: product.RFID ? product.RFID :"0000000000000",
                        status: product.status === 1 ? strings.slaughter : strings.alive
                    }
                })
                fullData[`${page.page}`] = newList.reverse();
                yield put({
                    type: GET_LIST_COW_SUCCESS,
                    data: newList.reverse(),
                    errorCode: response.data.errorCode,
                    totalItem: response.data.totalItem,
                    totalPage: response.data.totalPage,
                    fullData: fullData
                });
            }
        }
    } catch (error) {
        yield put({ type: GET_LIST_COW_FAILURE, payload: "", errorCode: 0 });
    }
}

function fetchListCow(page, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.GET_CATTLE + "?" + "page=" + page +  "&size=5"
    })
}

//Get list cattle filter
function* cleanDataTableCow(){
    yield put({
        type: GET_LIST_COW_SUCCESS,
        data: [],
        errorCode: 0,
        totalItem: 0,
        totalPage: 0,
        fullData: {}
    });
}
function* getListCowFilter(page) {
    try {
        let tokenInfo = yield select(getToken);
        const fullData = yield select(getFullCowList)
        const totalItem = yield select(getToTalItem)
        if(fullData[`${page.page}`]){
            yield put({
                type: GET_LIST_COW_SUCCESS,
                data: fullData[`${page.page}`],
                fullData: fullData,
            });
        }
        const response = yield call(fetchListCowFilter, page.page, page.name, page.value, tokenInfo.data.token);
        const data  = response.data.data;
        if(totalItem !== response.data.totalItem){
            const newList = data.map((product, idx)=> {
                return{
                    ...product,
                    LNC: product.LNC ? product.LNC : "",
                    boxid: product.id,
                    location: product.placeName + " - " + product.placeUuid,
                    NON_GS1: product.NON_GS1 ? product.NON_GS1: "0000000000000",
                    RFID: product.RFID ? product.RFID :"0000000000000",
                    status: product.status === 1 ? strings.slaughter : strings.alive
                }
            })
            fullData[`${page.page}`] = newList.reverse();
            yield put({
                type: GET_LIST_COW_SUCCESS,
                data: newList.reverse(),
                errorCode: response.data.errorCode,
                totalItem: response.data.totalItem,
                totalPage: response.data.totalPage,
                fullData: fullData
            });
        } else {
            if (!fullData[`${page.page}`]) {
                const newList = data.map((product, idx)=> {
                    return{
                        ...product,
                        LNC: product.LNC ? product.LNC : "",
                        boxid: product.id,
                        location: product.placeName + " - " + product.placeUuid,
                        NON_GS1: product.NON_GS1 ? product.NON_GS1: "0000000000000",
                        RFID: product.RFID ? product.RFID :"0000000000000",
                        status: product.status === 1 ? strings.slaughter : strings.alive
                    }
                })
                fullData[`${page.page}`] = newList.reverse();
                yield put({
                    type: GET_LIST_COW_SUCCESS,
                    data: newList.reverse(),
                    errorCode: response.data.errorCode,
                    totalItem: response.data.totalItem,
                    totalPage: response.data.totalPage,
                    fullData: fullData
                });
            }
        }
    } catch (error) {
        yield put({ type: GET_LIST_COW_FAILURE, payload: "", errorCode: 0 });
    }
}

function fetchListCowFilter(page, name, value, checktoken) {
    axios.defaults.headers.common['Authorization'] = checktoken;
    return axios({
        method: "GET",
        url: LINK_API.FILTER_LIST_CATTLE   + "?" + "page=" + page +  "&size=5&"+ name + "="+ value
    })
}

//root saga
export default function* rootSaga() {
    yield takeLatest(GET_PRODUCT_LIST, getProductTypeList)
    yield takeLatest(GET_AREA_LIST, getAreaList)
    yield takeLatest(GET_BRANCH_LIST, getBranchList)
    yield takeLatest(GET_EMPLOYEE_LIST, getEmployeeList)
    yield takeLatest(GET_OBJECT_LIST, getObjectList)
    yield takeLatest(GET_DISTRIBUTOR_LIST, getDistributorList)
    yield takeLatest(GET_CROP_LIST, getCropList)
    yield takeLatest(GET_HARVEST_LIST, getHarvestList)
    yield takeLatest(GET_PROCESS_LIST, getProcessList)
    yield takeLatest(GET_OBJECT_PROCESSING_LIST, getObjectProcessList)
    yield takeLatest(GET_OBJECT_DETAIL, getObjectDetail)
    yield takeLatest(GET_OBJECT_PROCESSING_DETAIL, getObjectProcessDetail)
    yield takeLatest(GET_CROP_OBJECT, getCropObjectList)
    yield takeLatest(GET_CROP_OBJECT_PROCESSING, getCropObjectProcessList)
    yield takeLatest(GET_PLAN_LIST, getPlanList)
    yield takeLatest(GET_BOX_UNIT, getStampBoxandUnit)
    yield takeLatest(GET_UNPAID_LIST, getUnpaidList)
    yield takeLatest(GET_NUMBER_ACCESSES, getNumberAccesses)
    yield takeLatest(GET_LIST_APPROVE, getApproveList)
    yield takeLatest(RENDER_EMPLOYEE_LIST, renderEmployee)
    yield takeLatest(RENDER_AREA_LIST, renderArea)
    yield takeLatest(GET_INFO_ACTIVE_STAMP, getInfoActiveStamp)
    yield takeLatest(GET_STAMP_LIST, getStampList)
    yield takeLatest(GET_OBJECT_IN_AREA, getObjectAreaList)
    yield takeLatest(GET_LIST_BOX_OWN, getOwnList)
    yield takeLatest(RENDER_UNIT_STAMP, renderSelectUnitStamp)
    yield takeLatest(GET_STAMP_INCASE, getStampIncase)
    yield takeEvery(GET_LIST_COW, getListCow)
    yield takeLatest(GET_FILTER_LIST_CATTLE, getListCowFilter)
    yield takeLatest(CLEAN_DATA_TABLE_COW, cleanDataTableCow)
    

    yield takeLatest(SAVE_USER_INFO, saveUserInfo)
    yield takeLatest(SAVE_NOTIFICATION, saveNotification)
    yield takeLatest(CLEAR_NOTIFICATION, clearNotification)
    yield takeLatest(GET_USER_PROFILE, getUserProfile)
    yield takeLatest(CHECK_STATUS_EXPAND, checkStatusSidebar)
    yield takeLatest(CHECK_STATUS_STAMP, setStatusStamp)

    yield takeLatest(CREATE_PRODUCT, watchCreateProduct)
    yield takeLatest(CREATE_DISTRIBUTOR, watchCreateDistributor)
    yield takeLatest(CREATE_AREA, watchCreateArea)
    yield takeLatest(CREATE_BRANCH, watchCreateBranch)
    yield takeLatest(CREATE_OBJECT, watchCreateObject)
    yield takeLatest(CREATE_OBJECT_PROCESS, watchCreateObjectProcess)
    yield takeLatest(CREATE_EMPLOYEE, watchCreateEmployee)
    yield takeLatest(CREATE_PROCESS, watchCreateProcess)
    yield takeLatest(CREATE_PLAN, watchCreatePlan)
    yield takeLatest(EDIT_PROCESS, watchEditProcess)

    yield takeLatest(ACTIVE_TOOLTIP, activeTooltip)
    yield takeLatest(DEACTIVE_TOOLTIP, deactiveTooltip)

    yield takeLatest(UPDATE_BANNER, watchUpdateBanner)
    yield takeLatest(UPDATE_LOGO, watchUpdateLogo)
    yield takeLatest(UPDATE_INFO_AREA, watchUpdateInfoArea)
    yield takeLatest(UPDATE_INFO_PRODUCT, watchUpdateProductInfo)
    yield takeLatest(UPDATE_INFO_EMPLOYEE, watchUpdateEmployee)

    yield takeLatest(GET_HARVEST_STATISTIC, getHarvestStatistic)
    yield takeLatest(GET_BRANCH_STATISTIC, getBranchStatistic)

    yield takeLatest(GET_SUBSCRIPTION, getSubscription)
    yield takeLatest(RENDER_SELECT_CUSTOMER, getCustomerList)


    yield takeLatest(GET_CROP_STATISTIC, getCropStatistic)

    yield takeLatest(GET_PRODUCT_TO_BRANCHS, getNumberProductToBranch)
    yield takeLatest(GET_PRODUCT_TO_DISTRIBUTOR, getNumberProductToDistributor)

    yield takeLatest(GET_ACTIVE_STAMP_PRODUCT_TYPE, getActiveStampProductType)
    yield takeLatest(GET_NUMBER_CROP_PRODUCT_TYPE, getNumberCropProductType)
    yield takeLatest(GET_STAMP_ACTIVE_GENERAL, getGeneralStampActive)
}