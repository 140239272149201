import React from "react";
import { withRouter } from "react-router-dom";
import cookie from 'react-cookies';
import axios from 'axios';
import { LINK_API } from '../../../../constants/API';
import moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from 'react-redux';
// @material-ui/icons
import MailOutline from "@material-ui/icons/Subtitles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { throws } from "assert";

import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
const styles = {
  ...regularFormsStyle,
  ...customSelectStyle,
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  }
}

class ActiveStamp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cooperativeGln: "",
      cooperativeGlnState: "",
      cooperativeGlnhelp: "",
      amount: 0,

      cooperatives: this.props.cooperatives || [],
      type: "",
      typeState: "",
      typeHelp: "",


      startId: "",
      startIdHelp: "",
      startIdState: "",



      endId: "",
      endIdState: "",
      endIdHelp: "",

      startTem: "",
      startTemState: "",
      startTemHelp: "",

      endTem: "",
      endTemState: "",
      endTemHelp: "",


      numberStamp: 0,
      numberStampHelp: "",
      numberStampState: "",


    };
    this.handleCooperativeGlnChange = this.handleCooperativeGlnChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.handlestartIDChange = this.handlestartIDChange.bind(this);
    this.handleendIDChange = this.handleendIDChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this)
    this.user_gln = cookie.load("gln");
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange = name => async event => {
    await this.setState({
      [name]: event.target.value,
    });
  }
  getCooperatives = async () => {
    try {
      let response = await axios.get(LINK_API.GET_COOPERATIVES);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  handleCooperativeGlnChange(value) {
    if (!this.verifyLength(value, 1)) {
      this.setState({
        cooperativeGlnState: "error",
        cooperativeGln: "",
        cooperativeGlnhelp: "Thiếu mã htx"
      })
      return;
    }
    else {
      this.setState({
        cooperativeGlnState: "success",
        cooperativeGlnhelp: "",
        cooperativeGln: value
      })
    }
  }
  handleAmountChange = event => {
    if (!this.verifyLength(event.target.value, 1) || isNaN(event.target.value)) {
      this.setState({
        amountState: "error",
        amountHelp: "Thiếu số lượng"
      })
      return;
    }
    else {
      if (isNaN(event.target.value) || event.target.value <= 0) {
        this.setState({
          amountState: "error",
          amountHelp: "Số lượng không hợp lệ"
        })
        return;
      }
      this.setState({
        amountState: "success",
        amountHelp: "",
        amount: event.target.value
      })
    }
  };
  handlestartIDChange = event => {
    if (!this.verifyLength(event.target.value, 1)) {
      this.setState({
        startIdState: "error",
        startIdHelp: "Thiếu ID tem bắt đầu"
      })
      return;
    }
    else {
      this.setState({
        startIdState: "success",
        startIdHelp: "",
        startId: event.target.value
      })
    }
  };
  handleendIDChange = event => {
    if (!this.verifyLength(event.target.value, 1)) {
      this.setState({
        endIdState: "error",
        endId: "",
        endIdHelp: "Thiếu ID tem kết thúc"
      })
      return;
    }
    else {

      this.setState({
        endIdState: "success",
        endIdHelp: "",
        endId: event.target.value
      })

    }
  }

  submitDataNumber(){
    if(this.state.numberStamp > 0){
      const data = {
        ownedBy: this.state.cooperativeGln,
        type: this.state.type,
        amount: this.state.numberStamp,
        startId: -1,
        endId: -1
      }
      if (!this.verifyLength(data.ownedBy, 1)) {
        this.setState({
          cooperativeGlnState: "error",
          cooperativeGlnhelp: "Thiếu mã htx"
        })
        return;
      }
      else {
        this.setState({
          cooperativeGlnState: "success",
          cooperativeGlnhelp: ""
        })
      }
  
      if (!this.verifyLength(this.state.type, 1)) {
        document.getElementById("noti").innerHTML = "Thiếu loại tem";
        return;
      }
      else {
        document.getElementById("noti").innerHTML = "";
      }
      this.props.onConfirm(data);
    }
    else{
      document.getElementById("noti").innerHTML = "Số lượng tem không được đúng!";
    }
    
  }

  submitData() {
    const data = {
      ownedBy: this.state.cooperativeGln,
      type: this.state.type,
      amount: -1,
      startId: this.state.startTem,
      endId: this.state.endTem
    }
    if (!this.verifyLength(data.ownedBy, 1)) {
      this.setState({
        cooperativeGlnState: "error",
        cooperativeGlnhelp: "Thiếu mã htx"
      })
      return;
    }
    else {
      this.setState({
        cooperativeGlnState: "success",
        cooperativeGlnhelp: ""
      })
    }

    if (!this.verifyLength(this.state.type, 1)) {
      document.getElementById("noti").innerHTML = "Thiếu loại tem";
      return;
    }
    else {
      document.getElementById("noti").innerHTML = "";
    }
    // if (!this.verifyLength(this.state.amount, 1)) {
    //     this.setState({
    //       amountState: "error",
    //       amountHelp: "Thiếu sô lượng"
    //     })
    //     return;
    //   }
    //   else {
    //     if (isNaN(this.state.amount) || this.state.amount <= 0) {
    //       this.setState({
    //         amountState: "error",
    //         amountHelp: "Số lượng không hợp lệ"
    //       })
    //       return;
    //     }
    //     this.setState({
    //       amountState: "success",
    //       amountHelp:""
    //     })
    //   }

    // document.getElementById("noti").innerHTML = "";
    this.props.onConfirm(data);
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById("noti").innerHTML = "";
  };
  renderInput = (inputProps) => {
    let { InputProps, ref, clearSelection, selectedItem, onChange, ...other } = inputProps;
    if (selectedItem) {
      const arrayValue = selectedItem.split('(Mã: ')
      let value = arrayValue && arrayValue.length > 0 ? arrayValue[arrayValue.length - 1] : ''
      value = value.slice(0, value.length - 1)
      if (value && this.state.cooperativeGln !== value) {
        this.handleCooperativeGlnChange(value)
      }
    }
    return (
      <CustomInput
        success={this.state.cooperativeGlnState === "success"}
        error={this.state.cooperativeGlnState === "error"}
        labelText="Tên doanh nghiệp"
        id="cooperativeGln_id"
        formControlProps={{
          fullWidth: true,
          onChange: (event) => {
            if (event && event.target && event.target.value === '') {
              clearSelection()
            }
            this.handleCooperativeGlnChange(event.target.value)
          }
        }}
        inputProps={{
          inputRef: ref,
          ...InputProps
        }}
        helpText={this.state.cooperativeGlnhelp}
      />
    );
  }

  renderSuggestion = (suggestionProps) => {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const { classes } = this.props
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.label}
        selected={isHighlighted}
        component="div"
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        title={suggestion.label}
      >
        {suggestion.label}
      </MenuItem>
    );
  }

  getSuggestions = (value, { showEmpty = false } = {}) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    const newValue = inputLength === 0 ? '' : inputValue.toLowerCase()
    return inputLength === 0 && !showEmpty
      ? []
      : (this.state.cooperatives || []).filter(suggestion => {
        const keep =
          count < 7 && suggestion.label.toLowerCase().includes(newValue);

        if (keep) {
          count += 1;
        }

        return keep;
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          {this.state.alert}
        </div>

        <GridContainer >
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ margin: "auto", minWidth: "400px" }}>
            <Card>
              <CardHeader color="success" icon>
                {/* <CardIcon color="success">
                  <MailOutline />
                </CardIcon> */}
                <h4 className="cardtableLabel">Cấp quyền sử dụng tem</h4>
              </CardHeader>
              <CardBody style={{ textAlign: "center" }}>
                {/* <form> */}
                <CustomTabs
                  className=""
                  tabs={[
                    {
                      tabName: "Cấp theo số lượng",
                      tabContent: (
                        <div style={{ width: "100%" }}>
                          <Downshift id="downshift-simple">
                            {({
                              clearSelection,
                              getInputProps,
                              getItemProps,
                              getMenuProps,
                              highlightedIndex,
                              inputValue,
                              isOpen,
                              selectedItem,
                              onChange
                            }) => (
                                <div>
                                  {this.renderInput({
                                    onChange,
                                    fullWidth: true,
                                    classes,
                                    InputProps: getInputProps({
                                      placeholder: ''
                                    }),
                                    clearSelection,
                                    selectedItem
                                  })}

                                  <div {...getMenuProps()}>
                                    {isOpen ? (
                                      <Paper className={classes.select} style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        top: 90, left: 20, right: 20
                                      }}>
                                        {this.getSuggestions(inputValue).map((suggestion, index) =>
                                          this.renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion.label }),
                                            highlightedIndex,
                                            selectedItem,
                                          }),
                                        )}
                                      </Paper>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                          </Downshift>
                          <CustomInput
                            success={this.state.numberStampState === "success"}
                            error={this.state.numberStampState === "error"}
                            labelText="Số lượng"
                            // id="farmScope"
                            formControlProps={{
                              fullWidth: true,
                              onChange: this.handleChange("numberStamp"),

                            }}
                            style={{
                              marginTop: '-10px'
                            }}
                            inputProps={{
                              type: 'number',
                              value: this.state.numberStamp
                            }}
                            helpText={this.state.numberStampHelp}
                          />
                          <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Loại tem
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.type}
                              onChange={this.handleSimple}
                              inputProps={{
                                name: "type",
                                id: "type-select"
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Chọn loại tem
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value="UNIT"
                              >
                                Tem trái
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value="BOX"
                              >
                                Tem thùng
                            </MenuItem>
                            </Select>
                          </FormControl>
                          <p
                            id="noti"
                            style={{
                              fontSize: '0.875rem',
                              fontWeight: '400',
                              color: 'red',
                              fontFamily: 'Muli',
                              marginTop: 5,
                              marginBottom: 5,
                            }}>
                          </p>
                          <Button color="success" onClick={this.submitDataNumber.bind(this)} style={{ marginTop: "30px" }}>Tiếp tục</Button>
                        </div>
                      )
                    },
                    {
                      tabName: "Cấp theo khoảng",
                      tabContent: (
                        <div style={{ width: "100%" }}>
                          <Downshift id="downshift-simple">
                            {({
                              clearSelection,
                              getInputProps,
                              getItemProps,
                              getMenuProps,
                              highlightedIndex,
                              inputValue,
                              isOpen,
                              selectedItem,
                              onChange
                            }) => (
                                <div>
                                  {this.renderInput({
                                    onChange,
                                    fullWidth: true,
                                    classes,
                                    InputProps: getInputProps({
                                      placeholder: ''
                                    }),
                                    clearSelection,
                                    selectedItem
                                  })}

                                  <div {...getMenuProps()}>
                                    {isOpen ? (
                                      <Paper className={classes.select} style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        top: 90, left: 20, right: 20
                                      }}>
                                        {this.getSuggestions(inputValue).map((suggestion, index) =>
                                          this.renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion.label }),
                                            highlightedIndex,
                                            selectedItem,
                                          }),
                                        )}
                                      </Paper>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                          </Downshift>
                          <CustomInput
                            success={this.state.startTemState === "success"}
                            error={this.state.startTemState === "error"}
                            labelText="Tem bắt đầu"
                            // id="farmScope"
                            formControlProps={{
                              fullWidth: true,
                              onChange: this.handleChange("startTem"),

                            }}
                            style={{
                              marginTop: '-10px'
                            }}
                            inputProps={{
                              type: 'number',
                              value: this.state.startTem
                            }}
                            helpText={this.state.startTemHelp}
                          />
                          <CustomInput
                            success={this.state.endTemState === "success"}
                            error={this.state.endTemState === "error"}
                            labelText="Tem kết thúc"
                            // id="farmScope"
                            formControlProps={{
                              fullWidth: true,
                              onChange: this.handleChange("endTem"),

                            }}
                            style={{
                              marginTop: '-10px'
                            }}
                            inputProps={{
                              type: 'number',
                              value: this.state.endTem
                            }}
                            helpText={this.state.endTemHelp}
                          />
                          <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Loại tem
            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.type}
                              onChange={this.handleSimple}
                              inputProps={{
                                name: "type",
                                id: "type-select"
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Chọn loại tem
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value="UNIT"
                              >
                                Tem trái
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value="BOX"
                              >
                                Tem thùng
                            </MenuItem>
                            </Select>
                          </FormControl>
                          <p
                            id="noti"
                            style={{
                              fontSize: '0.875rem',
                              fontWeight: '400',
                              color: 'red',
                              fontFamily: 'Muli',
                              marginTop: 5,
                              marginBottom: 5,
                            }}>
                          </p>
                          <Button color="success" onClick={this.submitData.bind(this)} style={{ marginTop: "30px" }}>Tiếp tục</Button>
                        </div>
                      )
                    }
                  ]}
                />

                {/* </form> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// export default withStyles(style)(withRouter(ActiveStamp));
const mapDispatchToProps = (dispatch) => {
  return {
    getProductTypeList: () => dispatch({ type: "GET_PRODUCT_LIST" }),
  }
}
const mapStateToProps = (state) => {
  return {
    productList: state.productType.data,
    userInfo: state.userInfo
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveStamp)));