const customTabsStyle = {
    cardTitle: {
      float: "left",
      padding: "0px 0px 0px 0px",
      lineHeight: "24px"
    },
    cardHeaderColor: {
      background: "#27ae60 !important",
      boxShadow:
      "0 12px 0px -10px rgba(140, 140, 140, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(70, 70, 70, 0.2) !important",
      padding: "0px 0px !important"
    },
    cardHeader: {
      padding: "0px 0px !important"
    },
    cardTitleRTL: {
      float: "right",
      padding: "0px 0px !important"
    },
    displayNone: {
      display: "none !important"
    },
    tabsRoot: {
      minHeight: "unset !important",
      "& $tabRootButton": {
        fontSize: "10px",
        minWidth: "84px !important",
      }
    },
    tabRootButton: {
      marginTop: "0px",
      outline:"0 !important",
      width: "70px",
      minHeight: "35px !important",
      padding: "0px 0px",
      borderRadius: "0px",
      border: "1px solid #d1d8e0 !important",
      color: "#000",
      display:"inline-block",
      marginLeft: "0px",
      fontSize: "13px",
      "&:hover,&:focus": {
        outline: 0,
        background:'#27ae60',
        color:'#ffffff',
        boxShadow: "none!important",
      },
    },
    tabLabelContainer: {
      padding: "0px"
    },
    cardBody: {
      padding: "0.9375rem 0px",
      flex: "1 1 auto",
      WebkitBoxFlex: "1",
      position: "relative"
    },
    nopaddding:{
      padding: "0px 0px !important"
    },
    tabSelected: {
      // backgroundColor: "rgba(255, 255, 255, 0.2)",
      outline:"0 !important",
      border: "1px solid #27ae60 !important",
      borderBottom: "1px solid #27ae60 !important",
      transition: "0.2s background-color 0.1s",
      background: '#27ae60',
      color : "#ffffff",
      width: "50px !important"
    },
    tabWrapper: {
      display: "inline-block",
      minHeight: "unset !important",
      minWidth: "unset !important",
      width: "84px !important",
      height: "unset !important",
      maxWidth: "unset !important",
      maxHeight: "unset !important",
      "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
        verticalAlign: "middle",
        margin: "-1px 5px 0 0",
        color:"#5a5a5a"
      }
    }
  };
  
  export default customTabsStyle;
  