import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, TextField} from '@material-ui/core';
import { Feedback } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import strings from 'constants/strings';
import CardIcon from "components/Card/CardIcon.jsx";

import logo from "assets/img/logo_vbc.png";
import gdt from "assets/img/products/GI.png";

import styles from './infoStyles';
import harvest from 'assets/icons/location.svg'

import VBC from 'assets/img/logo_vbc_black.png';
import AGR from 'assets/img/bg-img/logo_original.png'
import VN from "assets/img/flags/VN.png";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import qrIcon from 'assets/img/qrcodesarch.svg';
import $ from 'jquery';
class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      open: false,
      tx:"",
      data: this.props.data,
    }
  }
  componentDidUpdate(preProps) {
    if(preProps.data !== this.props.data){
      this.setState({
        data: this.props.data,
      })
    }
  }
  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };
  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  handleBtCt = () =>{
    if(this.state.statusCT){
      document.getElementById("btCt").innerHTML = "Chi tiết";
      //document.getElementById("imgProduct").style = "display: block; width:100%";
      document.getElementById("detailProduct").style = "display: none; width:100%";
      this.setState({
        statusCT:0,
      });
    } else {
      document.getElementById("btCt").innerHTML = strings.collapse;
      document.getElementById("detailProduct").style = "display: flex;  justify-content: center ; width: 100%; margin-top: 30px; display: table";
      // document.getElementById("imgProduct").style = "display: none;";
      this.setState({
        statusCT:1,
      });
    }
  }

  handleRentOpen = () => {
    this.setState({ rent: true });
  };

  handleRentClose = () => {
    this.setState({ rent: false });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  showBlockhainAlert = (tx) => {
    this.setState({ open: true ,tx :tx});
  }
  onclick() {
    $("html, body").animate({ scrollTop: 0 }, 1000);
  }

render() {
  const { classes } = this.props;
  const overviewData = {
    [strings.expiried_date]: this.state.data.productInfo.expired_date,
    [strings.production_standard]: this.state.data.productInfo.standard,
  }

  const info = Object.keys(overviewData).map((key,index) => {
    if (overviewData[key] != null) {
      return (
        <div>
            <p className="detailtitle">
              {key}
            </p>
            <Typography className="textdes">
              {overviewData[key]}
            </Typography>
          
          </div>
        )
    }
    else {
      return (<div></div>)
    }
  });
  
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
        <Card style={{ padding: "0px 0px", boxShadow:"none" }}>
      {/* <CardHeader className={classes.textCenter} color="success">
        <img className={classes.logo} src={logo} alt='logo'/>
        <h3 className={classes.textCenter} style={{fontSize: "19px",fontWeight:"bold",color:"white"}} >{strings.traceability_solution}</h3>
        <p className={classes.textCenter}  style={{fontSize: "16px"}}>{strings.applied_blockchain}<br></br>{strings.accordance_with_gs1_standard}</p>
      </CardHeader> */}
      <CardBody>
      <GridContainer style={{ paddingTop: "10px", display: "flex", justifyContent: "center", paddingLeft:"10px", paddingRight:"10px"}}>
          {/* <GridItem xs={12} id="text" className={classes.textCenter}>
            <Typography gutterBottom variant="h1"  className={classes.textLight} >
              {strings.product_name}
            </Typography>
            <Typography gutterBottom variant="h1"  className={classes.productName} >
            MaYaCa GOOD INSIDE GI 
            </Typography>
            <Typography gutterBottom variant="h1"  className={classes.textLight}>
            {this.data.cooperativeInfo.user_name}
            </Typography>
          </GridItem> */}
          <GridItem xs={12} id="mobileImg" className={classes.imgHeaderx} style={{flexFlow: "column !important", padding: "0px 0px !important"}}>
              <img id="scrolltoelement" ref={1111} style={{width:"100px", marginLeft: "10px" }} src={VBC} alt='product'/>
              <img style={{width:"100px", marginLeft: "20px" }} src={AGR} alt='product'/>
              <img
              src={VN}
              width="23"
              height="17"
              className ="imagelangx"
            />
          </GridItem>
          <div className="headerBarx">
        <GridItem xs={12} sm={12} style={{display:"flex", padding:"0px 0px !important"}}>
          <div className="inputFormx">
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search
            }}
            inputProps={{
              placeholder: strings.input_lookup_code,
              disableUnderline: true,
              inputProps: {
                "aria-label": strings.input_lookup_code,
                className: classes.searchInput,
              },
              classes:{
                input: classes.placeholdersearch,
                underline: classes.underlinesearch,
              },
              onChange: this.onChange
            }}
          />
          </div>
          <div className="qrButtonx">
          <Button className={classes.qrButton}>
            <img
              src={qrIcon}
              alt="wonder"
              className="qrImage"
              onClick={this.routeScan}
            />
          </Button>
          </div> 
          <div className="searchButtonx">
          <Button
            color="transparent"
            onClick={this.routeChange}
            className="searchButtonInsidex"
            // round
          >
            Tra cứu
          </Button>
          </div> 
        </GridItem>
        </div>

          <GridItem xs={12} id="mobileImg" className={classes.imgProduct}>
              <img style={{width:"200px" }} src={this.state.data.productInfo.image} alt='product'/>
          </GridItem>
          <GridItem xs={12}  id="text" className={classes.textCenter}>
                  <Typography gutterBottom variant="h1" className="pructName" >
                     {this.state.data.productInfo.name} 
            </Typography>
                </GridItem>
                <GridItem xs={12}  style={{ textAlign: "justify", marginTop: "10px" }}>
                  <GridContainer className="flexrowharvest">
                    <div className="iconhv">
                      <img className={classes.badgeIcon} src={harvest} />
                    </div>
                    <GridItem xs={10} md={10} sm={10} className="cooInfo">
                      <p className="cooName">{this.state.data.cooperativeInfo.user_name}</p>
                      <p className="cooAdd">{this.state.data.cooperativeInfo.address}</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
         
            <GridItem xs={12} id="text" className={classes.textCenter} style={{marginTop:"10px "}}>
              <Typography variant="h1"  className={classes.textLight} >
              Mã số sản phẩm - GTIN
              </Typography>
              <Typography variant="h1"  className={classes.textCode} >
                Sẽ được thêm sau khi tạo tem
              </Typography>
              {/* <Typography variant="h1"  className={classes.textLight} style={{marginTop: "10px"}} >
                Ngày kích hoạt
              </Typography>
              <Typography variant="h1"  className={classes.textCode} >
                25/05/2019
              </Typography> */}
            </GridItem>
      
            <div id="detailProduct" style={{ display: 'none' }}>
                  <GridItem xs={12}style={{ textAlign: "justify" }}>
                    <p className="detailtitle">
                      Giới thiệu
                  </p>
                    <Typography className="textdes">
                       {this.state.data.productInfo.description} 
                      </Typography>
                    <hr className="LineBreakDes" />
            <div style = {{marginTop:"20px"}}>
              {info}
            </div>
            {/* <Taste /> */}
            </GridItem>
          </div>
        </GridContainer>
          <GridContainer justify="center" style={{marginTop:"20px"}}>
          {/* <Button id="btfb" size="sm" color="success" onClick={this.handleOpenDialog} className="btnfb"  style={{ color:"#ffffff", marginRight: 30,fontSize:"15px" }}>Góp ý</Button> */}
          <Button id="btCt" size="sm" color="success" onClick={this.handleBtCt} className="btnfb">Chi tiết</Button>
          {/* <Button id="btCt" size="sm" color="success" onClick={this.onclick} className="btnfb">Chi tiết</Button> */}
        </GridContainer>
        <hr className="lineBreakdes"/>
      </CardBody>
    </Card>
{/*     
    <Dialog
      open={this.state.rent}
      onClose={this.handleRentClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{strings.rent}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {strings.rent_content}
        </DialogContentText>
        <TextField
          autoFocus
          id="phone"
          label={strings.phone}
          type="phone"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleRentClose} color="success">
          {strings.cancel}
        </Button>
        <Button onClick={this.handleRentClose} color="success">
        {strings.rent}
        </Button>
      </DialogActions>
    </Dialog> */}

<Dialog
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              classes: {
                root: classes.paper
              }
            }}
          >
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Feedback />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{strings.product_feedback}</h4>
              </CardHeader>
              <CardBody style={{ textAlign: "center" }}>
                <h4 className={classes.textField}> {strings.product_feedback_description}</h4>
                <TextField
                  id="standard-multiline-flexible"
                  label={strings.product_feedback_content}
                  multiline
                  rowsMax="4"
                  value={this.state.multiline}
                  onChange={this.handleChange('multiline')}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                />
                <Button color="success" onClick={this.handleClose}>{strings.send}</Button>
              </CardBody>
            </Card>
          </Dialog>
      </div>
  );
}
}

export default withStyles(styles)(Overview);