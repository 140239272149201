const ROLE = {
    ADMIN: 'Z',
    MANUFACTURER: 'M',
    STAFFMANUFACTURER: 'SM',
    STAFFPROCESSING: 'SP',
    AUTHORITY: 'A',
    DELIVERIER: 'D',
    STAFFDELIVERIER: 'SD',
    CARRIER: 'C',
    STAFFCARRIER: 'SC',
    RETAILER: 'R',
    STAFFRETAILER: 'SR',
    TRIAL: 'TRIAL',
    PROVIDER: 'P',
    INACTIVE: 'INACTIVE',
    EMPLOYEE: 'EE',
    DISTRIBUTOR: 'F',
    STAFFDISTRIBUTOR: 'SF'
  }
  // mapping vs role nào sẽ có các role nhân viên tương ứng. Cách dùng: MANAGE_ROLE[M] -> hiển thị list nhân viên SM, SP
  const MANAGE_ROLE = {
    [ROLE.MANUFACTURER]: [ROLE.STAFFMANUFACTURER, ROLE.STAFFPROCESSING],
    [ROLE.DISTRIBUTOR]: [ROLE.STAFFDISTRIBUTOR],
    [ROLE.RETAILER]: [ROLE.STAFFRETAILER],
    [ROLE.DELIVERIER]: [ROLE.STAFFDELIVERIER]
  }
  
  // mapping vs role của nhân viên vs role của người quản lí. Cách dùng: MAPPING_STAFF_MANAGER[SM] -> M
  const MAPPING_STAFF_MANAGER = {
    [ROLE.STAFFMANUFACTURER]: ROLE.MANUFACTURER,
    [ROLE.STAFFPROCESSING]: ROLE.MANUFACTURER,
    [ROLE.STAFFDISTRIBUTOR]: ROLE.DISTRIBUTOR,
    [ROLE.STAFFRETAILER]: ROLE.RETAILER,
    [ROLE.STAFFDELIVERIER]: ROLE.DELIVERIER
  }

  export default ROLE;
  export {ROLE, MANAGE_ROLE, MAPPING_STAFF_MANAGER};