import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GetApp from "@material-ui/icons/GetApp"

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';

import ReactExport from "react-export-excel";
import strings from "../../../../constants/strings";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class CustomerFeedBack extends React.Component {
  constructor(props) {
    super(props);


    this.getFarms = this.getFarms.bind(this)
    this.checkPermission = this.checkPermission.bind(this);
    this.genTable = this.genTable.bind(this)
    this.handleNewClick = this.handleNewClick.bind(this)
    this.state = {
      // openCreateFarm: openCreateFarm,
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
    };
    this.token = cookie.load('token');

  }


  genTable = async (farmList) => {
    //-----------------------
    let exportData = [];
    exportData = farmList.map(item => {
      return ({
        uuid: item.uuidOfProduct,
        name: item.productName,
        time: moment(item.createdAt).format("HH:mm:ss DD/MM/YYYY "),
        content: item.content,
        type: (item.type) === "BOX" ? "Tem lô hàng" : "Tem sản phẩm",
        // nameOfProcess: item.nameOfProcess,
        // lastAction: item.lastAction.nameOfLastAction
      })
    })

    await this.setState({ export: exportData })
    //-----------------------

    if (farmList) {
      await farmList.reverse();
      this.setState({
        data: farmList.map((prop, key) => {
          return {
            id: key + 1,
            name: <div><div>{prop.productName}</div><div>( {prop.uuidOfProduct} )</div></div>,
            feedback: prop.content,
            type: (prop.type) === "BOX" ? "Tem lô hàng" : "Tem sản phẩm",
            time: moment(prop.createdAt).format("HH:mm:ss DD/MM/YYYY ")
          };
        }),
        load: "none",
        showTable: ""
      });
    }

  }

  async checkPermission() {
    await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } })
      .then(async response => {
        if (response.data.errorCode !== 1 || !response.data.data.valid) {
          await cookie.remove('token')
          return this.props.history.push("/");
        }
        else {
          axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
        }
      })
      .catch(async error => {
        console.log(error);
        await cookie.remove('token');
        return this.props.history.push("/");
      })
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.CUSTOMER_FEEDBACK + "&cooperativeUuid=" + this.props.userInfo.data.uuid);
      if (response.data.errorCode === 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      // this.getFarms();
      let farms = await this.getFarms();
      this.setState({ farmList: farms });
      this.genTable(farms);
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  componentWillReceiveProps(preProps) {
    if (preProps.employeeList !== this.props.employeeList) {
      if (preProps.employeeList.data) {
        this.genTable(Array.from(preProps.employeeList.data));
      }
    }
  }


  handleNewClick() {
    // this.props.history.push("/admin/customer/create")
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer style={{display: "flex", flexFlow: "center"}}>
                    <h4 style={{marginRight: 19}}className="cardtableLabel">{strings.feedbackList}</h4>
                    <div className="">
                      <ExcelFile element={<Button justIcon color="success" className="like" simple round > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> </Button>}>
                        <ExcelSheet data={this.state.export} name="Phản hồi từ khách hàng">
                          <ExcelColumn label="Tên sản phẩm" value="name" />
                          <ExcelColumn label="Loại sản phẩm" value="type" />
                          <ExcelColumn label="Thời gian" value="time" />
                          <ExcelColumn label="Phản hồi" value="content" />
                          {/* <ExcelColumn label="Status" value="lastAction" /> */}
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                    </GridContainer>
                  </GridItem>
                  
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  data={this.state.data}
                  filterable
                  // defaultPageSize={5}
                  previousText={strings.prevPage}
                  nextText={strings.nextPage}
                  rowsText={strings.row}
                  ofText="/"
                  pageText={strings.page}
                  noDataText={strings.noData}
                  columns={[
                    {
                      Header: "#",
                      accessor: "id",
                      filterable: false,
                      maxWidth: 30,
                      className: "center",
                      Header: () => (
                        <div style={{
                          textAlign: "center"
                        }}>
                          #
                        </div>
                      )
                    },
                    {
                      Header: strings.nameProduct,
                      accessor: "name",
                      filterable: false,
                      maxWidth: 250
                    },
                    {
                      Header: strings.productType,
                      accessor: "type",
                      filterable: false,
                      maxWidth: 170
                    },
                    {
                      Header: strings.time,
                      accessor: "time",
                      filterable: false,
                      maxWidth: 170
                    },
                    {
                      Header: strings.feedback,
                      accessor: "feedback",
                      filterable: false
                    },

                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>


      </div>
    );
  }
}
const mapDispatchToProps = () => {
  return {
    // getFarms: () => dispatch({ type: "GET_DISTRIBUTOR_LIST"}),
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    //    employeeList: state.distributorList
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerFeedBack)));