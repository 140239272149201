import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import { FONT_SIZES, COLORS, FONTS } from 'constants/index.js';


  const styles = theme => ({
    ...dashboardStyle,
    ...loginPageStyle,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "500",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: "13px",
        textAlign: 'center',
        marginBottom: "3px",
        textDecoration: "none",
        whiteSpace: "nowrap",
    },
    textCenter: {
        textAlign: 'center'
    },
    productName: {
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.LARGE,
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: "nowrap",
        color: COLORS.BLACK,
    },
    
    manufacName: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: "18px",
        fontWeight: "400",
        textAlign: 'center',
        whiteSpace: "nowrap",
        backgroundColor: "#e5f3e5",  
        borderRadius: "6px",
        color:"#4caf50",
        display: "inline-block",
        padding:"10px",
    },
    productAtt: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: "17px",
        fontWeight: "300",
        textAlign: 'center',
        whiteSpace: "nowrap",
        paddingTop:"10px"
        // color: "#00bcd4",
    },
    content: {
        padding: "8px",
        backgroundColor: "#F9F9F9",
        
    },
    contentfooter: {
        padding: "30px",
        backgroundColor: "#ffffff",
        fontSize: "14px",
        fontFamily:FONTS.PRIMARY
    },
    logo: {
        width: "140px",
    },
    textLight:{
        color: COLORS.GRAY,
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
    },
    textCode: {
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: COLORS.BLACK,
        marginTop: '8px',
        // marginBottom: '5px',
    },
    textSample: {
        display:"inline-block",
        padding:"5px",
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.NORMAL,
        fontStyle: "normal",
        letterSpacing: 0,
        color: COLORS.RED,
        background: COLORS.RED_SOFT,
        marginBottom: '10px',
    },
    imgProduct: {
        textAlign: 'center',
        marginBottom: '25px',
        marginTop: '25px',
    },
    imgHeader: {
      marginBottom: '25px',
      marginTop: '25px',
  },
  imgHeaderx: {
    marginBottom: '25px',
    marginTop: '25px',
    padding: "0px 0px !important"
},
    btStatus: {
        backgroundColor: COLORS.RED_SOFT,
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: COLORS.RED,
        textTransform: 'none',
        marginRight: '30px',
    },
    btTrack: {
        backgroundColor: COLORS.GREEN_SOFT,
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        textTransform: 'none',
        color: COLORS.GREEN,
        "&:hover,&:focus": {
            outline: 0,
            boxShadow: "none!important",
        },
    },
    tag: {
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.LARGE,
      fontStyle: "normal",
      letterSpacing: 0,
      color: "#5a5a5a",
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    barLight: {
      width: '30%',
      borderTop: "2px solid #b7b7b7",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    barDark: {
      width: '25%',
      marginTop:"0px",
      borderTop: "2px solid #000",
    },
    barGreen: {
      width: '26vw',
      marginTop:"0px",
      borderTop: "2px solid #5cb360",
    },
    center: {
      textAlign: 'center',
    },
    noPadding:{
        padding:"0px 0px !important",
    },
    noPaddingCard:{
      padding:"0px 0px !important",
      alignItems:"flex-start"
      // position: "absolute"
  },
    textTx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.SMALL,
      fontWeight: "400",
      fontStyle: "normal",
      letterSpacing: 0,
      color: "#007bff",
    },
    textBlock: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.SMALL,
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: 0,
    },
    footerText:{
  
        color: "#000000",
        fontWeight: "600",
    },
    icon: {
        display:"inline", width:"40px", height:"40px"
      },
    contactstyle:{
        display:"inline",height:"40px", marginLeft:"10px", margin: "0 auto"
    },
    linebreak:{
        marginBottom: "5px",
        borderTop: "1px solid #b7b7b7"
    },
    textTx: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontWeight: "400",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#007bff",
      },
      txTitle: {
        color: "#333333 !important",
        fontWeight: "400",
        margin: "10px 0px 0px"
      },
      badgeIcon:{
        width: "30px",
        height: "30px"
      },
      headerBg: {
        backgroundColor:"white",
        position: "fixed",
        width:"100%",
        boxShadow:"0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        zIndex:"1000",
        height:"50px"
      },
      search: {
        margin: "0",
        paddingTop: "7px",
        paddingBottom: "7px",
        marginRight:"20px",
        width: "95%",
        left:"15px",
        [theme.breakpoints.down("sm")]: {
          // margin: "10px 0px",
          // marginRight:"10px !important",
          // float: "none !important",
          // paddingTop: "1px",
          // paddingBottom: "1px",
          // padding: "10px 15px",
          // width: "auto"
        }
      },
      searchInput: {
        paddingTop: "5px",
        marginRight:"25px",
        fontWeight:"700",
        fontFamily:"Muli",
        color: "#ffffff",
        fontSize: "13px"
      },
      searchButton: {
        [theme.breakpoints.down("sm")]: {
          top: "-50px !important",
          marginRight: "38px",
          float: "right"
        }
      },
      headerButton: {
        height: "35px",
        width :"35px",
        marginLeft:"8px",
        paddingLeft:"10px",
        paddingRight:"10px",
        paddingTop:"7px",
        marginTop:"7px",
        minWidth:"35px !important"
      },
      searchButton: {
        float:'right',
        marginTop:"7px",
        marginRight:"10px",
        height: "35px",
        width :"35px",
        marginLeft:"8px",
        paddingLeft:"10px",
        paddingRight:"10px",
        paddingTop:"7px",
        minWidth:"35px !important"
      },
      top: {
        zIndex: "4"
      },
      searchIcon: {
        width: "15px",
        zIndex: "4"
      },
      managerClasses: {
        [theme.breakpoints.up("md")]: {
          display: "inline-block"
        }
      },
      headerLinksSvg: {
        // color: "#4caf50",
        width: "20px !important",
        height: "20px !important"
      },
      qrButton: {
        padding:"0px",
        backgroundColor:"transparent",
        boxShadow: "none",
        height: "35px",
        width :"35px",
        marginTop:"3px",
        marginLeft:"0px",
        "&:hover,&:focus": {
          color: "transparent",
          backgroundColor: "transparent",
          boxShadow: "none"
        },
      },
      languageButton: {
        padding:"0px",
        backgroundColor:"transparent",
        boxShadow: "none",
        float:'right',
        marginRight:"8px",
        "&:hover,&:focus": {
          color: "transparent",
          backgroundColor: "transparent",
          boxShadow: "none"
        },
      },
      
      qrImg: {
        maxHeight:"30px",
      },  
      paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
      },
      placeholdersearch:{
        marginTop:"6px",
        color: "#ffffff !important",
      },
      underlinesearch:{
        borderBottom: '2px solid white',
        '&:after': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: '2px solid white',
        },
      }
    
    
});
export default styles;