import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Grid from '@material-ui/core/Grid';
import Card from "components/Card/Card.jsx";
import Tabs from './components/CustomTabs/LookupTabs';
import Heading from "./lookupPage/components/Header/HeaderLookup";
//components
import InfoProduct from './components/InfoProduct';
import Origin from './components/Origin';
import Process from './components/Process/Process';
import Certificate from './components/Certificate';
import ReactToPrint from 'react-to-print';
import LookupPage from './lookupPage/pages/Fruit';
//material
import logoVBC from "../../assets/img/logo_vbc.png";
import Iframe from 'react-iframe';
import AutoScale from 'react-auto-scale';
import data from './data';
import Button from "components/CustomButtons/Button.jsx";
import IMG_LOAD from 'assets/img/Infinity-1s-200px.gif';
import moment from 'moment';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import stamp_embryo from "assets/img/frame.png"
import {LINK_API} from 'constants/API';
import PrintTemplate from './PrintTemplate.jsx'
import htmlToImage from 'html-to-image';
import AGD from  "assets/img/bg-img/logo_original.png"
import { saveAs } from 'file-saver';
const QRCode = require('qrcode.react');
const axios = require('axios');
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      data: '',
      file: require('assets/img/products/xoaicatchu.png'),
      updateProductInfo: false,
      processData: [],
      // alert: (
      //   <SweetAlert
      //     input
      //     required
      //     style={{ display: "block" }}
      //     title="Đăng ký dùng thử"
      //     placeHolder=""
      //     validationMsg="Bạn cần nhập email!"
      //     onConfirm={(x) => this.getEmail(x)}
      //     confirmBtnCssClass={
      //       this.props.classes.button + " " + this.props.classes.success
      //     }
      //   >
      //     Bạn vui nhập địa chỉ email để bắt đầu trải nghiệm giải pháp của chúng tôi:
      //   </SweetAlert>
      // ),
    };
    this.createStamp = this.createStamp.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.getEmail=this.getEmail.bind(this);
    this.renderTrigger = this.renderTrigger.bind(this);
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  renderTrigger() {
    return <Button color = "success">In Mã</Button>;
  }
  
  handleChange = name => async event => {
    await this.setState({ [name]: event.target.value});
  };

  getEmail(x) {
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(x)){
      this.setState({
        email: x,
        alert: null,
      });
      this.createStamp();
    } else {

    }
  }
  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }

  setRef = (ref) => {
    this.componentRef = ref;
  }

  getData = (name, value, key) => {
    if(!key && key !== 0){
      this.setState({[name]: value});
    } else {
      this.state.processData[key]={
        ...this.state.processData[key],
        [name]:value,
      };
      this.setState({a:''});
    }
  }
  downloadimage(){
    htmlToImage.toBlob(document.getElementById('downloadImage'))
    .then(function (blob) {
        window.saveAs(blob, 'qrstamp.png');
    });
    // domtoimage.toJpeg(document.getElementById('downloadImage'), { quality: 0.95 })
    // .then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = 'qrstamp.jpeg';
    //     link.href = dataUrl;
    //     link.click();
    // });
  }
  async createStamp(){
    const that = this.state;
    this.setState({
      load: true,
      alert: (
        <SweetAlert
          info
          style={{ display: "block" }}
          title="Đang tạo tem"
          onConfirm={() => () => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          <img alt="{name}" src={IMG_LOAD} style={{height:"100px"}} />
        </SweetAlert>
      ),
    });
    const dataStamp = {
      type: 'TRIAL',
      treeInfo: data.treeInfo,
      cooperativeInfo: {
        phone: this.state.phone || '',
        website: this.state.website || '',
        location_gps: '',
        email: this.state.email || '',
        user_name: this.state.user_name || '',
        gln: this.state.gln || '',
        tx_id: '',
        address: this.state.address || '',
      },
      productInfo: {
        name: this.state.name || '',
        image: this.state.image || '',
        description: this.state.description || '',
        trace_cycle: '',
        expired_date: this.state.expired_date || '',
        createAt: 1558885531569,
        updateAt: 1558885531569,
      },
      diary: this.state.processData.map(x=>{
        return {
          business_steps: "Truy xuất nguồn gốc",
          step: x.step,
          "from_id": "89385221500140000",
          "date": moment(),
          step_desc: x.step_desc,
          "detail": "",
          "step_icon": "register.svg",
          "actions_image": x.actions_image,
          "authority": "COO",
          "from_name": this.state.user_name || '',
        }
      }),
      certificates: [this.state.certificate0, this.state.certificate1],
      email: this.state.email,
    }
    axios.post(LINK_API.TRIAL, dataStamp)
    .then(response => {
      //console.log('dataStamp',dataStamp);
      //console.log(response);
      this.setState({
        qrcode: response.data.data.stampId,
        load: false,
        alert: (
          <SweetAlert
            success
            style={{ display: "block" }}
            title="Thông tin tem"
            showCancel = {false}
            showConfirm = {false}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          <div id="downloadImage" style={{justifyContent:"center", alignItems:"center"}}>
          <div  className = "stampFrame">
          <QRCode className = "qrCode" value={`http://138.68.245.109:3000/lookup/${response.data.data.stampId}`} />
          <h5 className = "subTextBold"> {response.data.data.stampId} </h5>
          </div>
        </div>
        {/* <ReactToPrint
                    trigger={this.renderTrigger}
                    content = {this.renderContent}
                  /> */}
         <Button color = "success" onClick={() => this.downloadimage()}>Tải về</Button>
                  <div style = {{display:"none"}}>
                    <PrintTemplate data = {response.data.data.stampId} ref={this.setRef}/>
                  </div>
        <Button color = "success" onClick={() => this.hideAlert()}> Gửi qua mail</Button>
        <Button color = "danger" onClick={() => this.hideAlert()}> Đóng </Button>
          </SweetAlert>
        ),
      });
    })
    .catch(err => {
    this.setState({
      load: false,
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Thông tin tem"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Đã có lỗi xảy ra trong quá trình tạo tem, vui lòng thử lại sau.
        </SweetAlert>
      ),
    });
    console.log(err);
    });
  }

  render() {
    return (
      <div className="backgroundLogintrial" style={{ paddingLeft: 20, paddingRight: 20 }}>
        {this.state.alert}
        {1 ? (<GridContainer justify="center" className="trialview">
          
          <GridItem xs={12} sm={12} md={6} className="inputtabtrial">
            <Card className="cardtrial">
            <div className="centerimage">
                <img src={AGD} className ="imageintro"></img>
            </div>
            <div style={{textAlign:"center"}}>
            <p className= "headerintro">TẠO TEM BLOCKCHAIN MIỄN PHÍ ĐỂ TRUY XUẤT NGUỒN GỐC</p>
            </div>
            <div style={{textAlign:"justify"}}>
            <p>Agridential là giải pháp được nghiên cứu và phát triển bởi công ty VBC. Hướng tới việc truy xuất nguồn gốc áp dụng công nghệ Blockchain phù hợp tiêu chuẩn toàn cầu GS1, cho phép truy xuất thông tin theo chuỗi giá trị, từ quy trình sản xuất tới khâu tiêu thụ, giúp nhà nông, hợp tác xã, người tiêu dùng và thị trường có thông tin minh bạch về sản phẩm, góp phần tăng giá trị nông sản. 
</p>
            {/* <p>Agridential là trình tạo QR code về thông tin nguồn gốc sản phẩm duy nhất tại Việt Nam do TraceVerified cung cấp hoàn toàn miễn phí.</p> */}
            <p>Agridential giúp truyền tải tâm huyết của người nông dân bằng giải pháp truy xuất nguồn gốc áp dụng Blockchain phù hợp tiêu chuẩn toàn cầu GS1. Từ đó, mang tới những lợi ích sau:</p>
            <p>1. Minh bạch thông tin</p>
            <p>2. Phạm vi toàn cầu</p>
            <p>3. Gia tăng giá trị</p>
            <p>Nay bạn có thể dễ dàng tạo QR code để đưa thông tin sản phẩm của mình đến với khách hàng một các thông minh và tối ưu nhất trên Agridential.</p>
            </div>
            <div style={{justifyContent:"center", display:"flex"}}>
            <Button color="success" 
                size="lg"
                onClick={()=>this.props.history.push("/trial/create")}
                style={{marginTop: "15px"}}>
                    Tạo tem mẫu
                </Button>
            </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className="mobiletrialview" style={{maxWidth:"23%"}}>
            <LookupPage 
              lookup_data={{
                cooperativeInfo: {
                  phone: this.state.phone || '',
                  website: this.state.website || '',
                  location_gps: '',
                  email: this.state.email || '',
                  user_name: this.state.user_name || 'VietNam Blockchain Coporation',
                  gln: this.state.gln || '',
                  tx_id: '',
                  address: this.state.address || 'Tầng 5, tòa nhà Flemington, 182 Lê Đại Hành, Phường 15, Quận 11, Tp.HCM',
                },
                productInfo: {
                  name: this.state.name || '',
                  image: this.state.image || require('assets/img/image_placeholder.jpg'),
                  description: this.state.description || '',
                  trace_cycle: 'y',
                  expired_date: this.state.expired_date || '',
                  createAt: 1558885531569,
                  updateAt: 1558885531569,
                },
                diary: this.state.processData.map(x=>{
                  return {
                    business_steps: "Truy xuất nguồn gốc",
                    step: x.step,
                    "from_id": "89385221500140000",
                    "date": moment(),
                    step_desc: x.step_desc,
                    "detail": "",
                    "step_icon": "register.svg",
                    "actions_image": x.actions_image,
                    "authority": "COO",
                    "from_name": this.state.user_name || '',
                  }
                }),
                certificates: [this.state.certificate0 || require('assets/img/certs/vbc.png'), this.state.certificate1 ||require('assets/img/image_placeholder.jpg')],
              }}
            />
          </GridItem>
        </GridContainer>):null}
        <div style={{paddingBottom: 10, paddingTop: 10, textAlign: 'center', height: '7vh', }}>
            <img src={logoVBC}/>
        </div>
      </div>
    );
  }
}

export default withStyles(sweetAlertStyle)(LoginPage);
