/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import axios from 'axios';
import delay from "delay";
import strings from "constants/strings";
import VN from "assets/img/flags/vnflag.svg";
import EN from "assets/img/flags/usaflag.svg";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { connect } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Success from "assets/icons/success.svg"
import Error from "assets/icons/cancel.svg"
import { API, IMG_LOAD, ADDRESS } from 'constants/index.js';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { LINK_API } from 'constants/API';
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import cookie from 'react-cookies';
import adminNavbarLinksStyle from "./headerLinksStyle";
import Notifications from "@material-ui/icons/Notifications";
import loadingdot from "assets/img/loadingdot.gif";;

//Language


const language = ["vi", "en"];
const language_img = {
  "vi": VN,
  "en": EN
}


const ethers = require('ethers');
const CryptoJS = require("crypto-js");

//BlockChain
const EthereumTx = require('ethereumjs-tx').Transaction;
const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider(`http://206.189.82.191:8000`));
//ABI
const genieIJSON = require("../../../../build/contracts/GenieIdentity.json");
const genieIABI = genieIJSON['abi'];
const genieIDeployed = new web3.eth.Contract(genieIABI, ADDRESS.GENIEIDENTITY_CONTRACT_ADDRESS);

const genieTJSON = require("../../../../build/contracts/GenieToken.json");

const styles = {
  ...sweetAlertStyle,
  ...adminNavbarLinksStyle,
  root: {
    width: '100%',
    maxWidth: 290,
  },
  inline: {
    display: 'inline',
  },
}
class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNotification: false,
      openProfile: false,
      alert: null,
      pass_user: "",
      pathCount_user: 0,
      abi_data: "",
      checkNoti: false,
      language: cookie.load("language")?cookie.load("language"):'vi',
      openLanguageMenu: false,
      anchorEl: null,
    };



    this.activeAccount = this.activeAccount.bind(this);
    this.handleinput = this.handleinput.bind(this);
    this.getPathemploy = this.getPathemploy.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.getTxStatus = this.getTxStatus.bind(this);
    this.genNoti = this.genNoti.bind(this);
    this.handleLanguageItemClick = this.handleLanguageItemClick.bind(this);
    this.handleLanguageMenu = this.handleLanguageMenu.bind(this);
    this.handleLanguageMenuClose = this.handleLanguageMenuClose.bind(this);

  }
  async componentDidMount() {
    if (this.props.notification) {
      this.genNoti(Array.from(this.props.notification.data));
    }
    const lan = cookie.load("language");
   
    //await this.setState({ language: lan })
    if (lan) {
      await strings.setLanguage(lan);
      this.setState({ language: lan })
    }
    else {
      this.setState({ language: strings.getLanguage() })
      await strings.setLanguage("vi");
    }

  }
  componentWillReceiveProps(preProps) {
    if (this.props.notification !== preProps.notification) {
      if (preProps.notification) {
        this.setState({
          checkNoti: true
        })
        this.genNoti(Array.from(preProps.notification.data));
      }
    }
  }
  handleLanguageItemClick = async (event, index) => {
    await this.setState({ language: index });
    this.setState({ openLanguageMenu: false });
    await cookie.save('language', index, { path: '/' });
   // await strings.setLanguage(index);
   window.location.reload();
  };
  handleLanguageMenu = event => {
    this.setState({ openLanguageMenu: !this.state.openLanguageMenu, anchorEl: event.currentTarget });
  };

  handleLanguageMenuClose = event => {
    this.setState({ openLanguageMenu: false });
  };


  async genNoti(data) {
    const length = data.length;
    const notification = await data.map((prop, key) => {
      return (
        <div key={key}>
          <ListItem alignItems="flex-start">
          <ListItemAvatar>
            {prop.type === "success" ? <Avatar alt="Travis Howard" src={Success} /> : <Avatar alt="Travis Howard" src={Error} />}
          </ListItemAvatar>
          <ListItemText
            primary= {prop.type === "success"? "Thành công" : "Thất bại"}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={this.props.classes.inline}
                  color="textPrimary"
                >
                 {prop.message}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
          {key===length - 1? null: <Divider variant="inset" component="li" />}
        </div>
        
      )
    })
    await this.setState({
      notiList: notification
    })
  }
  getTxStatus = async ({ tx_id } = {}) => {
    try {
      const receipt = await web3.eth.getTransactionReceipt(tx_id);
      return receipt && (receipt.status === '0x01' || receipt.status === true);
    } catch (error) {
      //Logger.error('getTxStatus', error);
      return false;
    }
  };
  estimateGas = async (from, nonce, to, data) => {
    const gasLimit = await web3.eth.estimateGas({
      "from": from,
      "nonce": nonce,
      "to": to,
      "data": data
    })
    return gasLimit;
  }
  handleinput = () => async (event) => {
    await this.setState({
      pass_user: event.target.value
    });
    document.getElementById('noti_active').innerHTML = '';
  }
  activeAccount = async () => {
    this.setState({
      alert: <SweetAlert
        warning
        style={{ display: "block" }}
        title="Đang xử lý!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        showConfirm={false}
      >
        <div style={{ textAlign: "center" }}>
          <img
            alt="{name}"
            src={IMG_LOAD}
            style={{ height: "100px" }}
          />
        </div>
      </SweetAlert>
    })
    var temp = new Buffer(this.state.pass_user).toString('hex');
    const encryptedPass = await ethers.utils.keccak256('0x' + temp);
    if (encryptedPass !== this.passHash) {
      this.setState({
        pass_userState: 'error',
        pass_userHelp: 'Sai mật khẩu'
      });
      document.getElementById('noti_active').innerHTML = 'Sai mật khẩu';
      return;
    }

    var bytes = CryptoJS.AES.decrypt(this.privateKeyEn.toString(), this.state.pass_user);
    const privatekey = bytes.toString(CryptoJS.enc.Utf8);
    const privatekey_slice = privatekey.substring(2, privatekey.length)
    const to = this.identifierAddress
    const contractAddress = this.identifierAddress
    //blockchain verify account
    const data = await genieIDeployed.methods.Execute(to, 0, this.state.abi_data, contractAddress).encodeABI();
    const txParam = {
      from: this.bcAddress,
      nonce: this.state.nonce,
      gasPrice: 0, // web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
      gasLimit: 8000000,
      to: ADDRESS.GENIEIDENTITY_CONTRACT_ADDRESS,
      data: data,
    };
    const Buffer_privatekey = await Buffer.from(privatekey_slice.toString(), 'hex');
    await this.setState({
      Buffer_privatekey: Buffer_privatekey
    })
    const tx = new EthereumTx(txParam, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');


    //execute function claim

    const raw_to_send = {
      "raw": [],
      "sign": "none",
      "date": "none",
      "bcAddress": this.bcAddress,
      "uuid": this.uuid,
      "nonce": "none"
    }
    await raw_to_send.raw.push(rawTx);
    //await raw_to_send.raw.push(rawTx_claim);
    axios.post(API.LINK_API.SEND_RAW_VERIFY, raw_to_send)
      .then(async response => {
        if (response.data.errorCode === 1) {
          while (!this.getTxStatus(response.data.data.verifiedTxId)) {
            await delay(100)
          }
          await this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: 'block' }}
                title="Thành công"
                closeOnClickOutside={false}
                onCancel={() => this.hideAlert_claimed()}
                onConfirm={() => this.hideAlert_claimed()}
                showCancel={false}
              />
            )
          });
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: 'block' }}
                title="Không thành công"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
              />
            )
          });
        }
      })

  }
  hideAlert_claimed = async () => {
    await this.setState({
      alert: null,
    })
    const contractAddress_claim = ADDRESS.VERIFY_CONTRACT_ADDRESS;
    const data_claim = genieIDeployed.methods.CheckClaim(this.identifierAddress, 0, contractAddress_claim).encodeABI();
    //const gasLimit_claim = await this.estimateGas(this.bcAddress, 0,  ADDRESS.GENIEIDENTITY_CONTRACT_ADDRESS, data_claim)
    const txParam_claim = {
      from: this.bcAddress,
      nonce: this.state.nonce + 1,
      gasPrice: 0, // web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
      gasLimit: 8000000,
      to: ADDRESS.GENIEIDENTITY_CONTRACT_ADDRESS,
      data: data_claim,
    };
    const tx_claim = new EthereumTx(txParam_claim, { chain: 4 });
    tx_claim.sign(this.state.Buffer_privatekey);
    const rawTx_claim = '0x' + tx_claim.serialize().toString('hex');
    const data = {
      "raw": rawTx_claim,
      "bcAddress": this.bcAddress,
      "uuid": this.uuid
    }
    axios.post(LINK_API.SEND_RAW_CLAIM, data)
      .then(response => {
      })
      .catch(err => {
        console.log(err)
      })
  }
  verifyAccount = () => {
    const data = {
      "email": this.email,
      "bcAddress": this.bcAddress,
      "uuid": this.uuid
    }
    axios.post(LINK_API.VERIFY_ACCOUNT, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            abi_data: response.data.data.abi,
            nonce: response.data.data.nonce
          })
          await this.activeAccount()
        }
      })
  }
  getPathemploy() {
    axios
      .get(LINK_API.GET_PATH_EMPLOY)
      .then(async (response) => {
        if (response.data.errorCode === 1) {
          this.setState({
            pathCount_employ: response.data.data.pathEmployee.toString(),
            pathCount_user: response.data.data.pathUser.toString()
          });
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }
  hideAlert = () => {
    this.setState({
      alert: null
    })
  }
  handleActive = () => {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Vui lòng nhập mật khẩu tài khoản"
          onConfirm={() => this.verifyAccount()}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
          confirmBtnText="Kích hoạt"
          cancelBtnText="Hủy"
          showCancel
        >
          <CustomInput
            success={this.state.pass_userState === 'success'}
            error={this.state.pass_userState === 'error'}
            helpText={this.state.pass_userHelp}
            variant="outlined"
            labelProps={{
              variant: 'outlined'
            }}
            formControlProps={{
              variant: 'outlined',
              fullWidth: true,
              className: classes.customFormControlClasses,
              onChange: this.handleinput('pass_user')
            }}
            value={this.state.pass_user}
            inputProps={{
              //value: this.state.pass_user,
              type: 'password',
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start" className={classes.inputAdornment}>
                  <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                </InputAdornment>
              ),
              placeholder: 'Mật khẩu'
            }}
          />
          <div>
            <p
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              id="noti_active"
              style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: 'red',
                fontFamily: 'Muli',
                marginTop: 5,
                marginBottom: 5
              }}
            />
          </div>
        </SweetAlert>
      )
    });
  }
  handleClickNotification = () => {
    this.setState({ openNotification: !this.state.openNotification, checkNoti: false });
  };
  handleCloseNotification = () => {
    this.setState({ openNotification: false, checkNoti: false });
  };
  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };
  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };
  render() {
    const { classes, rtlActive } = this.props;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const { openNotification } = this.state;
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    strings.setLanguage(this.state.language);
    const renderLanguageMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.openLanguageMenu}
        onClose={this.handleLanguageMenuClose}
        style={{ marginLeft: "16px", marginTop: "33px" }}
      >
        {language.map((option, index) => (
          <MenuItem
            key={option}
            //   disabled={index === }
            selected={index === this.state.selectedIndex}
            onClick={event => this.handleLanguageItemClick(event, option)}
          >
            <img
              src={language_img[option]}
              width="23"
              height="17"
              alt=""
            />
          </MenuItem>
        ))}

      </Menu>
    );

    return (
      <div className={wrapper}>
        {this.state.alert}
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorNotification = node;
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            {/* <span className="notificationnumber">5</span> */}
            {this.state.checkNoti === true?<img src={loadingdot} className="notificationnumber"></img>: null}
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickNotification}
                className={classes.linkText}
              >
                {"Notification"}
              </span>
            </Hidden>
          </Button>
          <Button className={classes.languageButton}>
              <img
                src={language_img[this.state.language]}
                height="24"
                className="languageImg"
                onClick={this.handleLanguageMenu}
                alt=""
              />
            </Button>
          <Popper
            open={openNotification}
            anchorEl={this.anchorNotification}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openNotification,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseNotification}>
                    <List className={classes.root}>
                      {/* <Divider variant="inset" component="li" /> */}
                      {this.state.notiList}
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {renderLanguageMenu}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { notification: state.notification }
}
HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(styles)(connect(mapStateToProps, null)(HeaderLinks));
