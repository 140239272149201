import React from "react";
import Resizer from 'react-image-file-resizer';
// used for making the prop types of this component
import PropTypes from "prop-types";
import readXlsxFile from 'read-excel-file'
// core components
import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/icons/selectImage.png"
import defaultAvatar from "assets/img/pictureicon.png";
import removeIcon from "assets/icons/deleteButton.png"
const LZUTF8 = require('lzutf8');
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: false,
      portal: false,
      file: null,
      list:[],
      listview:[],
      listId: [],
      count:0,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  componentDidMount(){
    this.setState({
      count: this.props.count
    })
  }
  async handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
     let file = e.target.files[0];
     console.log(file)
    let temp = this.state.listview;
    let compress = this.state.list;
    let temp_count = this.state.count;
    console.log(e)
    readXlsxFile(file).then(async (rows) => {
        //console.log(rows)
        for(let i=0;i<rows.length;i++){
          if(i>0){
            let temp = rows[i][2].replace(/\s/g, '');
            await this.state.listId.push(temp)
          }
        }
      })
    this.props.onImageSubmit(this.state.listId, file.name);
    reader.readAsDataURL(file);
    this.refs.fileInput.value = null;
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove(idx) {
    let temp = this.state.count - 1;
    this.setState({
      count: temp,
      list: this.state.list.filter((s, sidx) => idx !== sidx),
      listview: this.state.listview.filter((s, sidx) => idx !== sidx)
    });
    this.refs.fileInput.value = null;
  }
  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div style={{display: "flex", flexFlow: "row"}}>          
          {
            this.state.listview.map((prop, key) => (
            key<1?
          <div key={key} className="addImageProcess" style={{backgroundImage:`URL(${prop})`}}>
            <img src={removeIcon} onClick={() => this.handleRemove(key)} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
          </div>:
          <div key={key} className="addImageProcess" style={{backgroundImage:`URL(${prop})`, marginLeft: "20px"}}>
          <img src={removeIcon} onClick={() => this.handleRemove(key)} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
        </div>
            ))
          }
          {this.state.count < 1 ? <img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." /> : this.state.count < 2 ? 
          <img style={{ height: "100px" , cursor: "pointer", marginLeft: "20px"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." /> : null}
        </div>
        <div>
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
