import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Resizer from 'react-image-file-resizer';
// core components
import Button from "components/CustomButtons/Button.jsx";

import defaultImage from "assets/icons/selectImage.png"
import defaultAvatar from "assets/img/placeholder.jpg";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import Jimp from 'jimp';
import strings from "../../../../constants/strings";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      status: false,
      countImage: 0,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  componentDidMount() {
    this.setState({
      countImage: this.props.Count,
      status: this.props.delete
    })
  }
  componentDidUpdate(preProps) {
    if (preProps.Count !== this.props.Count) {
      this.setState({
        countImage: this.props.Count
      })
    }
    if (preProps.delete !== this.props.delete) {
      this.setState({
        status: this.props.delete
      })
    }
  }
  async handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = async () => {
      await this.setState({
        file: file,
       // imagePreviewUrl: reader.result,
        status: true,
      });

      this.props.onImageSubmit(reader.result);
    };
    await reader.readAsDataURL(file);
    await this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
  }
  render() {
    var {
      avatar,
      addButtonProps,
      Flag
    } = this.props;
    return (
      <div className="fileinput text-center" style={{width: "100%"}}>
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        {this.state.countImage === 0 ? 
         <img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." />: ""}
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div>
          {(this.state.countImage === 3) ? <Button {...addButtonProps} disabled color="success" size="sm" onClick={() => this.handleClick()}>
            <Add /> {avatar ? "Add Photo" : strings.addImage}
          </Button> : <Button {...addButtonProps} color="success" size="sm" onClick={() => this.handleClick()}>
              <Add /> {avatar ? "Add Photo" : strings.addImage}
            </Button>}
          {(Flag !== "true") ? (
            (this.state.status === true) ?
              <Button
                {...addButtonProps}
                color="success"
                onClick={this.props.SubmitData}
                size="sm"
              >
                <Check />
                {strings.update}
                  </Button> : null
          ) : null}

        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
