import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// react component for creating dynamic tables

import { withRouter } from 'react-router-dom';
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";



// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Accordion from 'components/Accordion/Accordion.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { LINK_API } from '../../../../constants/API';
import ImageUpload from "../RetailPage/ImageCustorm/ImageUpload.jsx"
import SweetAlert from "react-bootstrap-sweetalert";
import { IMG_LOAD } from 'constants/index.js';

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import strings from "../../../../constants/strings";
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";
const LZUTF8 = require('lzutf8');
const styles = {
    ...tooltip,
    ...regularFormsStyle,
    ...sweetAlertStyle,
    ...customSelectStyle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    contentText: {
        fontSize: "14px",
        marginTop: "-20px",
        marginBottom: "20px"
    },
    boldContentText: {
        fontSize: "14px",
        marginBottom: "20px",
        fontWeight: "700"
    }
};

class TableFarmList extends React.Component {
    constructor(props) {
        super(props)
        this.user_gln = cookie.load("gln");
        this.role = cookie.load("checkrole");
        this.state = {
            alert: null,

            name: "",
            nameState: "",

            description: "",
            descriptionState: "",

            city: "",
            cityState: "",

            district: "",
            districtState: "",

            gln: "",
            glnState: "",

            image: "",

            checked: [],

            destime: ["Vườn", "Nhà kính", "Khu chế biến"],
            type: "",
            type_temp: "",
            active: false,
            activeuuid: false,

            search: "",
            value: "",
        };
        this.token = cookie.load('token');
        this.handleChange = this.handleChange.bind(this);
        this.onImageSubmit = this.onImageSubmit.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.hideAlert_success = this.hideAlert_success.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.checkPermission = this.checkPermission.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectSuggest = this.handleSelectSuggest.bind(this);
        this.handleNoResult = this.handleNoResult.bind(this);
        this.handleStatusUpdate = this.handleStatusUpdate.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    async componentDidMount() {
        await this.checkPermission()
    }
    async checkPermission() {
        try {
            let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
            if (response.data.errorCode !== 1 || !response.data.data.valid) {
                await cookie.remove('token')
                return this.props.history.push("/");
            }
            else {
                axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
            }
        }
        catch (error) {
            console.log(error);
            await cookie.remove('token');
            return this.props.history.push("/");
        }
    }
    handleSearch(){
        axios.get(LINK_API.GET_INFO_DISTRIBUTOR + this.state.uuid)
        .then( response => {
            if(response.data.errorCode === 1){
                this.setState({
                    name:  response.data.data.name,
                    email: response.data.data.email,
                    website: response.data.data.website,
                    phone: response.data.data.phone,
                    description: response.data.data.description,
                    city: response.data.data.address,
                    activeuuid: false,
                    active: true
                })
            }
        })
        .catch(err => {
            this.setState({
                activeuuid: true,
                uuidHelp: "Không tìm thấy trong hệ thống",
                uuidState: "error"
            })
        })
    }
    handleInputChange = e => {
        this.setState({ search: e.target.value, value: e.target.value })
    }

    handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        //console.log(geocodedPrediction.formatted_address, originalPrediction) // eslint-disable-line
        this.setState({
            city: geocodedPrediction.formatted_address,
            search: "",
        })
        //this.setState({ search: "", value: geocodedPrediction.formatted_address })
    }
    handleSelectSuggest_district = (geocodedPrediction, originalPrediction) => {
        //console.log(geocodedPrediction.formatted_address, originalPrediction) // eslint-disable-line
        this.setState({
            district: geocodedPrediction.formatted_address,
            search_district: ""
        })
        //this.setState({ search: "", value: geocodedPrediction.formatted_address })
    }
    handleNoResult = () => {
        console.log('Không có kết quả', this.state.search)
    }

    handleStatusUpdate = (status) => {
        console.log(status)
    }
    async onImageSubmit(file) {
        if (file != null) {
            await this.setState({
                image: file
            })
        }
    }
    async handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        await this.setState({
            checked: newChecked
        });
        if (this.state.checked[0] === 1) {
            //document.getElementById("noti").innerHTML = "";
            document.getElementById("glnchecked").style = "display: block; width: 100%; margin-top: -27px;"
        } else {
            document.getElementById("glnchecked").style = "display: none"
        }
    }
    handleChange = name => async event => {
        if(name !== "uuid"){
            this.setState({
                uuid: "",
                uuidState: "",
                uuidHelp: ""
            })
        }
        if (name === "city") {
            this.setState({ search: event.target.value, })
        }
        if (name === "district") {
            this.setState({ search_district: event.target.value, })
        }

        await this.setState({
            [name]: event.target.value,
        });
        if (name === "name") {
            if (this.state.name) {
                document.getElementById("noti").innerHTML = "";
                this.setState({
                    nameState: "success"
                })
            } else {
                this.setState({
                    nameState: "error"
                })
            }
        }
        if (name === "description") {
            if (this.state.description) {
                document.getElementById("noti").innerHTML = "";
                this.setState({
                    descriptionState: "success"
                })
            } else {
                this.setState({
                    descriptionState: "error"
                })
            }
        }
        if (name === "city") {
            if (this.state.city) {
                document.getElementById("noti").innerHTML = "";
                this.setState({
                    cityState: "success"
                })
            } else {
                this.setState({
                    cityState: "error"
                })
            }
        }
        if (name === "phone") {
            if (this.state.phone) {
                document.getElementById("noti").innerHTML = "";
                this.setState({
                    phoneState: "success"
                })
            } else {
                this.setState({
                    phoneState: "error"
                })
            }
        }
        if (name === "email") {
            if (this.state.email) {
                document.getElementById("noti").innerHTML = "";
                this.setState({
                    emailState: "success"
                })
            } else {
                this.setState({
                    emailState: "error"
                })
            }
        }
        if (name === "website") {
            if (this.state.website) {
                document.getElementById("noti").innerHTML = "";
                this.setState({
                    websiteState: "success"
                })
            } else {
                this.setState({
                    websiteState: "error"
                })
            }
        }
        if (this.state.checked[0] === 1) {
            //document.getElementById("noti").innerHTML = "";
            if (name === "gln") {
                if (this.state.gln) {
                    document.getElementById("noti").innerHTML = "";
                    this.setState({
                        glnState: "success"
                    })
                } else {
                    this.setState({
                        glnState: "error"
                    })
                }
            }
        }
    };
    async  handleSelect(event) {
        if (event.target.value === "Vườn") {
            await this.setState({ type: "Vườn", type_temp: 0 });
        }
        if (event.target.value === "Nhà kính") {
            await this.setState({ type: "Nhà kính", type_temp: 1 });
        }
        if (event.target.value === "Khu chế biến") {
            await this.setState({ type: "Khu chế biến", type_temp: 2 });
        }
        document.getElementById("noti").innerHTML = "";
    }
    submitData() {
        if (!this.state.uuid) {
            if (!this.state.name) {
                this.setState({
                    nameState: "error"
                })
                document.getElementById("noti").innerHTML = "Vui lòng nhập tên nhà phân phối ";
                return;
            }
            if (!this.state.city) {
                this.setState({
                    cityState: "error"
                })
                document.getElementById("noti").innerHTML = "Vui lòng nhập địa chỉ";
                return;
            }
            if (!this.state.phone) {
                this.setState({
                    phoneState: "error"
                })
                document.getElementById("noti").innerHTML = "Vui nhập số điện thoại nhà phân phối";
                return;
            }
            if (!this.state.email) {
                this.setState({
                    emailState: "error"
                })
                document.getElementById("noti").innerHTML = "Vui nhập email nhà phân phối";
                return;
            }
            if (!this.state.website) {
                this.setState({
                    websiteState: "error"
                })
                document.getElementById("noti").innerHTML = "Vui nhập website nhà phân phối";
                return;
            }
        }
        const data = {
            "name": this.state.uuid ? "" : this.state.name,
            "uuid": this.state.uuid ? this.state.uuid : "",
            "image": this.state.uuid ? "" : LZUTF8.compress(this.state.image, { outputEncoding: 'Base64' }),
            "address": this.state.uuid ? "" : this.state.city,
            "phone": this.state.uuid ? "" : this.state.phone,
            "wesite": this.state.uuid ? "" : this.state.website,
            "description": this.state.uuid ? "" : this.state.description
        }
        this.props.createArea(data);
        this.props.history.push("/admin/customer/list");
    }
    async onSuccess(x) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title="Đang xử lý!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showConfirm={false}
                >
                    <div style={{ textAlign: "center" }}>
                        <img
                            alt="{name}"
                            src={IMG_LOAD}
                            style={{ height: "100px" }}
                        />
                    </div>
                </SweetAlert>
            )
        });

        // let that=this;

        axios.post(LINK_API.CREATE_PRODUCTION_AREA, x)
            .then(async () => {
                // const farmList = await this.getFarms();
                // this.genTable(farmList);

                await this.setState({
                    //farmList: farmList,
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Thành công"
                            onConfirm={() => this.hideAlert_success()}
                            showCancel={false}
                        >
                        </SweetAlert>
                    )
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    alert: (
                        <SweetAlert
                            error
                            style={{ display: "block" }}
                            title="Không thành công"
                            onConfirm={() => this.hideAlert()}
                            onCancel={() => this.hideAlert()}
                            confirmBtnCssClass={
                                this.props.classes.button + " " + this.props.classes.success
                            }
                        >
                        </SweetAlert>
                    )
                });
            });

    }
    hideAlert_success() {
        this.setState({
            alert: null
        })
        this.props.history.push("/admin/areas/list")
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div id="info1">
                    <GridContainer>
                        <GridItem xs={8} lg={8} md={8} sm={8}>
                            <Card>
                                <CardHeader color="success" icon>
                                    <GridContainer>
                                        <GridItem lg={12} md={12} sm={12} xs={12}>
                                            {/* <CardIcon color="success">
                        <People />
                      </CardIcon> */}
                                            <h4 className="cardtableLabel">{strings.disInfo}</h4>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <div style={{ width: "100%", display: 'flex', flexFlow: "row" }}>
                                        <div style={{width: "100%"}}>
                                            <CustomInput
                                                labelText={strings.idDist}
                                                success={this.state.uuidState === "success"}
                                                error={this.state.uuidState === "error"}
                                                helpText={this.state.uuidHelp}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses,
                                                    onChange: this.handleChange('uuid'),
                                                }}
                                                inputProps={{
                                                    value: this.state.uuid,
                                                    autoComplete: "off",
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                code
                              </Icon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <div style={{marginTop: "23px"}}>
                                            <Button color="success" size="sm" onClick={this.handleSearch}>{strings.search}</Button>
                                        </div>
                                    </div>

                                    <CustomInput
                                        labelText={strings.nameBranch}
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('name'),
                                        }}
                                        inputProps={{
                                            value: this.state.name,
                                            disabled: this.state.active,
                                            autoComplete: "off",
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <Icon className={classes.inputAdornmentIcon}>
                                                        home
                              </Icon>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <div style={{ width: "100%" }}>
                                        <ReactGoogleMapLoader
                                            params={{
                                                key: MY_API_KEY,
                                                libraries: "places,geocode",
                                            }}
                                            render={googleMaps =>
                                                googleMaps && (
                                                    <div>
                                                        <ReactGooglePlacesSuggest
                                                            autocompletionRequest={{ input: this.state.search }}
                                                            googleMaps={googleMaps}
                                                            onSelectSuggest={this.handleSelectSuggest.bind(this)}
                                                            displayPoweredByGoogle={false}
                                                        >
                                                            <CustomInput
                                                                labelText={strings.address}
                                                                success={this.state.cityState === "success"}
                                                                error={this.state.cityState === "error"}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: classes.customFormControlClasses,
                                                                    onChange: this.handleChange('city'),
                                                                }}
                                                                inputProps={{
                                                                    disabled: this.state.active,
                                                                    value: this.state.city,
                                                                    autoComplete: "off",
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            className={classes.inputAdornment}
                                                                        >
                                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                                location_on
                              </Icon>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </ReactGooglePlacesSuggest>
                                                    </div>
                                                )
                                            }
                                        />
                                        <CustomInput
                                            success={this.state.phoneState === "success"}
                                            error={this.state.phoneState === "error"}
                                            labelText={strings.phone}
                                            helpText={this.state.phoneHelp}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses,
                                                onChange: this.handleChange('phone'),
                                            }}
                                            inputProps={{
                                                disabled: this.state.active,
                                                value: this.state.phone,
                                                type: "number",
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className={classes.inputAdornment}
                                                    >
                                                        <Icon className={classes.inputAdornmentIcon}>
                                                            phone
                              </Icon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <CustomInput
                                            success={this.state.emailState === "success"}
                                            error={this.state.emailState === "error"}
                                            labelText="Email"
                                            helpText={this.state.emailHelp}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses,
                                                onChange: this.handleChange('email'),
                                            }}
                                            inputProps={{
                                                disabled: this.state.active,
                                                value: this.state.email,
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className={classes.inputAdornment}
                                                    >
                                                        <Icon className={classes.inputAdornmentIcon}>
                                                            mail
                              </Icon>
                                                    </InputAdornment>
                                                ),
                                                //placeholder: "Số điện thoại"
                                            }}
                                        />
                                        <CustomInput
                                            success={this.state.websiteState === "success"}
                                            error={this.state.websiteState === "error"}
                                            labelText="Website"
                                            helpText={this.state.websiteHelp}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses,
                                                onChange: this.handleChange('website'),
                                            }}
                                            inputProps={{
                                                disabled: this.state.active,
                                                value: this.state.website,
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className={classes.inputAdornment}
                                                    >
                                                        <Icon className={classes.inputAdornmentIcon}>
                                                            public
                              </Icon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Accordion
                                            collapses={[
                                                {
                                                    title: strings.moreInfoDis,
                                                    content: (
                                                        <form style={{ width: "100%" }}>
                                                            <CustomInput
                                                                labelText={strings.description}
                                                                success={this.state.descriptionState === "success"}
                                                                error={this.state.descriptionState === "error"}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: classes.customFormControlClasses,
                                                                    onChange: this.handleChange('description'),
                                                                }}
                                                                inputProps={{
                                                                    disabled: this.state.active,
                                                                    value: this.state.description,
                                                                    multiline: true,
                                                                    rowsMax: "3",
                                                                    rows: 3,
                                                                    autoComplete: "off",
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            className={classes.inputAdornment}
                                                                        >
                                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                                list
                              </Icon>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </form>
                                                    )
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div style={{ marginTop: "-35px", display: "none" }} id="glnchecked">
                                        <CustomInput
                                            success={this.state.glnState === "success"}
                                            error={this.state.glnState === "error"}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses,
                                                onChange: this.handleChange('gln'),
                                            }}
                                            inputProps={{
                                                disabled: this.state.active,
                                                value: this.state.gln,
                                                autoComplete: "off",
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className={classes.inputAdornment}
                                                    >
                                                        <Icon className={classes.inputAdornmentIcon}>
                                                            code
                              </Icon>
                                                    </InputAdornment>
                                                ),
                                                placeholder: "Mã GLN"
                                            }}
                                        />

                                    </div>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <p
                                            id="noti"
                                            style={{
                                                fontSize: '0.875rem',
                                                fontWeight: '400',
                                                color: 'red',
                                                fontFamily: '"Muli',
                                                marginTop: 5,
                                                marginBottom: 5,
                                            }}>
                                        </p>
                                    </div>
                                    <Button color="success" size="sm" onClick={this.submitData.bind(this)}>{strings.create}</Button>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={4} lg={4} md={4} sm={4}>
                            <Card>
                                <CardHeader color="success" icon>
                                    <GridContainer>
                                        <GridItem lg={12} md={12} sm={12} xs={12}>
                                            <h4 className="cardtableLabel">{strings.image}</h4>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody style={{ display: "flex", justifyContent: "center" }}>
                                    <ImageUpload onImageSubmit={this.onImageSubmit} />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                {this.state.alert}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createArea: (data) => dispatch({ type: "CREATE_DISTRIBUTOR", data: data })
    }
}
const mapStateToProps = (state) => {
    return { userInfo: state.userInfo }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
