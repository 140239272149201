import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Print from "@material-ui/icons/Print"
import GetApp from "@material-ui/icons/GetApp"
import FindInPage from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import Gallery from '../../components/Forms/Gallery/Gallery';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import {API,IMG_LOAD, ADDRESS} from 'constants/index.js';
import {LINK_API} from '../../../../constants/API'; 
import QRCode from 'qrcode.react';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import picture from "assets/img/pictureicon.png";
import {tooltip} from "assets/jss/material-dashboard-pro-react.jsx";
import Add from "@material-ui/icons/Add";
import "../css/CustomeReactTable.css";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import TreePrintTemplate from "./TreePrintTemplate"
import { Keccak } from 'sha3';
import htmlToImage from 'html-to-image';
import { connect } from 'react-redux';
import LogoVBC from "assets/img/logo_vbc_black.png"
import LogoAGD from "assets/img/bg-img/agd.png"
import Table from "components/Table/Table.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import ImageActions from "../ProcessPage/ImageActions.jsx"
import strings from "../../../../constants/strings";

const RLP = require('rlp');
const CryptoJS = require("crypto-js");

const EthereumTx = require('ethereumjs-tx').Transaction;
const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider(`http://206.189.82.191:8000`));
//ABI
const genieIJSON = require("../../../../build/contracts/GenieIdentity.json");

const genieTJSON = require("../../../../build/contracts/GenieToken.json");
const genieTABI = genieTJSON['abi'];


const styles = {
  ...extendedTablesStyle,
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  }
};

class TableFarmList extends React.Component {
   constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.handleSelectProduct = this.handleSelectProduct.bind(this);
    this.handleEditProduct = this.handleEditProduct.bind(this);
    this.handleSelectProcess = this.handleSelectProcess.bind(this);
    this.handleEditProcess = this.handleEditProcess.bind(this);
    this.handleEditArea = this.handleEditArea.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);
    this.genTable = this.genTable.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.getArea = this.getArea.bind(this);
    this.getObjects = this.getObjects.bind(this)
    this.getProcess = this.getProcess.bind(this)
    this.getProductype = this.getProductype.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleContent = this.handleContent.bind(this);
    this.handleCloseedit = this.handleCloseedit.bind(this);
    this.submitData_crop = this.submitData_crop.bind(this);
    this.generateContractAddresswithNonce = this.generateContractAddresswithNonce.bind(this)
    this.downloadQR = this.downloadQR.bind(this);
    this.updateHarvestList = this.updateHarvestList.bind(this);
    this.handleSelectHarvest = this.handleSelectHarvest.bind(this);
    this.getArea = this.getArea.bind(this);
    this.handleEditObject = this.handleEditObject.bind(this);
    this.handleEditImage = this.handleEditImage.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmitImage = this.handleSubmitImage.bind(this);
    
    this.state = {
      alert:null,
      checked:false,
      valueHarvest_temp: "",
      value:{
        gln_extend:'',
        passphrase:'',
        data:'',
      },
      farmList: [],
      data: [],
      load:"",
      showTable:"none",
      activeState: true,
      data_detail: [],
      name: "",
      nameProduct: "",
      processcode: "",
      status: "",
      image: "",
      uuid: "...",
      statusList: ["Đang sản xuất", "Ngưng sản xuất"],
      productList: [],
      processList: [],
      areaList: [],
      typeProduct: "",
      typeProcess: "",
      crop_status: "",
      area: "",
      valueArea: "",
      valueProduct: "",
      valueProcess: "",
      harvestList: [],
      harvestId: "",
      manufacturerId: "",
      disable: true,
      startTime: new Date(),
      openCreateSeason: openCreateSeason,
      outsideSystem: false,
      editImage: false
      
    };
    const queryParams = new URLSearchParams(this.props.location.search);
    const openCreateSeason = (queryParams.get('showCreateDialog') === "true") ? true : false;
    this.objects_gln = cookie.load("glnobjects");
  }
  handleEditImage(){
    this.setState({
      editImage: true
    })
    document.getElementById("showImage").style="display: none;"
  }
  handleImageChange(file){
    this.setState({
      changeImageSrc: file
    })
  }
  showConfirmAlert(data) {
    this.handleClose();
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          // onConfirm={() => this.onCrop(tree,product,process)}
          onConfirm={() => {}}
          onCancel={() => {
            this.hideAlert();
            // this.setState({ openCreateFarm: true });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          {/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
        </SweetAlert>,
      dataSubmit: data
    })
  }
  handleSubmitImage(){
    const data ={
      uuid: this.state.uuid,
      image: this.state.changeImageSrc[0]
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block"}}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{textAlign:"center"}}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{height:"100px"}}
            />
          </div>
        </SweetAlert>
      )
     })
     axios.post(LINK_API.UPDATE_PICTURE_OBJECT_PROCESSING, data)
     .then (res => {
       if(res.data.errorCode === 1){
        this.setState(
          {
          editImage: false,
           alert: (
             <SweetAlert
               success
               style={{ display: 'block' }}
               title="Thành công"
               onConfirm={() => this.hideAlert()}
               showCancel={false}
             />
           )
          }
        )
        document.getElementById("showImage").style= "display: block; height: 216px;";
        this.props.getObjectDetail(this.objects_gln);
       }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
       }
     })
     .catch(err=> {
       console.log(err);
       this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
     })
  }
  handleClose() {
    this.setState({ openCreateSeason: false });
  }
  async handleSelectHarvest(event){
    await this.setState({
      valueHarvest_temp: event.target.value,
      manufacturerId: event.target.value.manufacturerId,
      harvestId: event.target.value.id,
    })
    //console.log(this.state.harvestId)
  }
  async  handleSelectProduct(event) {
    var temp = event.target.value.split(" ");
    //console.log(temp)
    await this.setState({
      valueProduct_temp: event.target.value,
      valueProduct: temp[temp.length - 1]
    })
   }

   async  handleEditProduct(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      product: event.target.value,
      valueProduct: temp[temp.length - 1]
    })
   }

   async  handleSelectProcess(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      valueProcess_temp: event.target.value,
      valueProcess: temp[temp.length - 1]
    })
   }

   async  handleEditProcess(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      valueProcess: temp[temp.length - 1],
      valueProcess_temp: event.target.value
    })
   }

   async  handleEditArea(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      area: event.target.value,
      valueArea: temp[temp.length - 1]
    })
   }

  handleChangetime = name => async moment => {

    await this.setState({
      [name]: moment._d,
    });
  };

  handleEdit = name => event => {
    this.setState({ [name]: event.target.value });
  }

  async handleContent() {
    if (document.getElementById("buttonedit").innerText === strings.update) {
      this.handleEditObject();
    } else {
      document.getElementById("info2").style = "display: none;"
      document.getElementById("info4").style = "display: none;"
      document.getElementById("info5").style = "display: none;"
      document.getElementById("info6").style = "display: none;"
      document.getElementById("infoedit2").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("infoedit4").style = "display: block; width: 100%; margin-top: -20px";
      document.getElementById("infoedit5").style = "display: block; width: 100%; margin-top: -20px";
      document.getElementById("infoedit6").style = "display: block; width: 100%; margin-top: -20px";
      document.getElementById("buttonedit").innerHTML = strings.update;
      document.getElementById("buttoncancle").style = "display: block; width: 100%;"
    }
  }
  handleCloseedit() {
    document.getElementById("info2").style = "display: block; width: 100%"
    document.getElementById("info4").style = "display: block; width: 100%"
    document.getElementById("info5").style = "display: block; width: 100%"
    document.getElementById("info6").style = "display: block; width: 100%"
    document.getElementById("infoedit2").style = "display: none;"
    document.getElementById("infoedit4").style = "display: none;"
    document.getElementById("infoedit5").style = "display: none;"
    document.getElementById("infoedit6").style = "display: none;";
    document.getElementById("buttonedit").innerHTML = strings.edit;
    document.getElementById("buttoncancle").style = "display:none"
   }

  renderTrigger() {
    return <Button size="sm" color="success" onClick={() => {
      document.getElementById("printobject").style = "display: block"
    }}> <Print />{strings.printQR}</Button>;
  }

  renderContent = () => {
    return this.componentRef
  }
  setRef = (ref) => {
    this.componentRef = ref;
  }
  generateContractAddresswithNonce = async (creator, creator_nonce) => {
    const response = await '0x' + Keccak(256).update(RLP.encode([creator, web3.utils.toHex(creator_nonce)])).digest('hex').substring(24); 
   // console.log(creator_nonce, '-------------------response--------------', response);
    return response;
  };
  handleDisable(){
    this.setState({
      alert: (
        <SweetAlert
        warning
        style={{ display: "block", marginTop: "-200px" }}
        title="Có mùa vụ đang sản xuất. Không thể tạo mùa vụ mới!"
        onCancel={() => this.hideAlert()}
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        cancelBtnText="Đóng"
        showCancel
      >
      </SweetAlert>
      )
    })
  }
  async handleCrop(){
    this.setState({openCreateSeason: true})
    this.hideAlert();
  }
  async getArea (data) {
    var i = 0;
    var result = [];
    for( i = 0 ; i < data.length ; i++) {
      var temp = data[i].name + " - " + data[i].uuid;
      result.push(temp)
    }
    this.setState({
      areaList: result
    })
}
  handleInputDate(tree,product,process){
    const {classes} = this.props
    this.setState({
      alert: (
        <SweetAlert
        //className= {this.props.classes.UnsetOverflow}
        warning
        style={{ display: "block", overflow: "unset" }}
        title="Nhập thông tin mùa vụ"
        onConfirm={() => this.onCrop(tree,product,process)}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        confirmBtnText="Tạo mới"
        cancelBtnText="Đóng"
        showCancel
      >
        <Card className="noShadow" style={{marginTop: "-8px"}}>
            <CardBody>
              {/* <InputLabel className={classes.label}>
                Datetime Picker
              </InputLabel> */}
              <br />
              <FormControl fullWidth className={classes.selectFormControl}>
                <Datetime
                  value= {this.state.startTime}
                  className="InputDatetime"
                  formControlProps={{
                    onChange: this.handleChangetime('startTime'),
                  }}
                  inputProps={{ placeholder: "Ngày bắt đầu", title: "Ngày bắt đầu",className: "InputDatetime" }}
                  onChange= {this.handleChangetime('startTime')}
                />
                <hr className="LineBreakDesDate"/>
                  <InputLabel
                  style={{zIndex:5600}}
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          Loại sản phẩm
                              </InputLabel>
                        <Select
                        
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.valueProduct_temp}
                          onChange={this.handleSelectProduct}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem>
                            Chọn loại sản phẩm
                        </MenuItem>
                          {this.state.productList.map( (x,idx) => (
                            <MenuItem
                           
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                  
              </FormControl>
            </CardBody>
          </Card>
        
      </SweetAlert>
      )
    })
  }



    NewKindOfNon_FungibleAssetCreated = async (name,symbol,address0) =>{
      const body = {
        functionName: "NewKindOfNon_FungibleAssetCreated",
        Option: {
        "name"      : name,       
        "symbol"    : symbol, 
        "address"  : address0
        }
      }
    
      let response = "";
      try{
        response = await axios.post(API.LINK_API.GET_INFO_WEB3, body)
        const data = response.data.data.data;
        return data;
      } catch {
        console.log("ERROR");
        return null;
      }
    
      
      }


      Non_FungibleAsset_NewNon_FungibleProductCreated = async (address_creator) =>{
        const body = {
          functionName: "Non_FungibleAsset_NewNon_FungibleProductCreated",
          Option: {
          "address_creator"      : address_creator,       
          }
        }
      
        let response = "";
        try{
          response = await axios.post(API.LINK_API.GET_INFO_WEB3, body)
          const data_creator = response.data.data.data_creator;
          return data_creator;
        } catch {
          console.log("ERROR");
          return null
        }
      
        
        }
        handleEditObject() {
          this.setState({
           alert: (
             <SweetAlert
               warning
               style={{ display: "block"}}
               title="Đang xử lý!"
               onConfirm={() => this.hideAlert()}
               onCancel={() => this.hideAlert()}
               confirmBtnCssClass={
                 this.props.classes.button + " " + this.props.classes.success
               }
               showConfirm={false}
             >
               <div style={{textAlign:"center"}}>
                 <img
                   alt="{name}"
                   src={IMG_LOAD}
                   style={{height:"100px"}}
                 />
               </div>
             </SweetAlert>
           )
          })
          let data_edit = {
           uuid: this.state.uuid, // id of production object/processing
           name: {
                vi: this.state.name // en: 'ten tieng anh'
           },
           processCode: this.state.valueProcess,
           productType: this.state.valueProduct,
           zoneId: this.state.valueArea,
          }
          axios.post(LINK_API.UPDATE_OBJECT_PROCESSING, data_edit)
          .then(response => {
            if(response.data.errorCode === 1){
             // this.handleCloseedit();
              this.setState(
                {
                 alert: (
                   <SweetAlert
                     success
                     style={{ display: 'block' }}
                     title="Thành công"
                     onConfirm={() => this.hideAlert_update()}
                     showCancel={false}
                   />
                 )
                }
              )
            }else{
             this.setState({
               alert: (
                 <SweetAlert
                   error
                   style={{ display: "block"}}
                   title="Không thành công"
                   onConfirm={() => this.hideAlert_update()}
                   onCancel={() => this.hideAlert_update()}
                   confirmBtnCssClass={
                     this.props.classes.button + " " + this.props.classes.success
                   }
                 >
                 </SweetAlert>
               )
             });
            }
          })
          .catch(err => {
           this.setState({
             alert: (
               <SweetAlert
                 error
                 style={{ display: "block"}}
                 title="Không thành công"
                 onConfirm={() => this.hideAlert_update()}
                 onCancel={() => this.hideAlert_update()}
                 confirmBtnCssClass={
                   this.props.classes.button + " " + this.props.classes.success
                 }
               >
               </SweetAlert>
             )
           });
          })
        }

        async hideAlert_update() {
          this.setState({
            alert: null
          });
          this.props.getObjectDetail(this.objects_gln);
          this.handleCloseedit();
        }
  async submitData_crop (process, product, uuid) {
  
   

    var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privateKey = bytes.toString(CryptoJS.enc.Utf8);
    const privatekey_slice = privateKey.substring(2,privateKey.length);
    const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
    const tx = new EthereumTx(this.state.rawtx1, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    const rawtosend  ={

      "uuidOfProductionObject": this.objects_gln,
      "processCode": process,
      "uuidOfProductType": product,
      "manufacturerId": this.state.manufacturerId,
      "uuid": uuid,
      "harvestId": this.state.harvestId,
      "raw": []
    }
    rawtosend.raw.push(rawTx)
            const tx_creator = new EthereumTx(this.state.rawtx2, { chain: 4 });
            tx_creator.sign(Buffer_privatekey);
            const rawTx_creator = '0x' + tx_creator.serialize().toString('hex');
            rawtosend.raw.push(rawTx_creator)


    axios.post(LINK_API.CREATE_BATCH, rawtosend)
    .then(async response => {
      if(response.data.errorCode === 1){
        await this.setState({
          statusCheck: 1,
					alert: (
						<SweetAlert
							success
							style={{ display: 'block' }}
							title="Thành công"
							onConfirm={() => this.hideAlert()}
							showCancel={false}
						/>
					)
				});
      }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    })
    .catch(err=> {
      console.log(err)
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
    })
  }
  handleChange = (name) => async (event) => {
		await this.setState({
			[name]: event.target.value
    });
  }
  onCrop(tree,product,process){
   // const temp = product.split(" ");
    this.setState({
      openCreateSeason: false,
      alert: (
        <SweetAlert
          warning
          style={{ display: "block"}}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{textAlign:"center"}}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{height:"100px"}}
            />
          </div>
        </SweetAlert>
      )
    });
  
  
    const data = {
      "startTime": tree,
      "uuidOfProductionObject": this.objects_gln,
      "processCode": process,
      "uuidOfProductType": product,
      "importDate": this.state.outsideSystem === true ? this.state.importDate: "",
      "uuidOfProductionObject": this.objects_gln,
      "manufacturerId": this.state.outsideSystem === true ? "" : this.state.manufacturerId,
      "harvestId": this.state.outsideSystem === true ? "" : this.state.harvestId,
    }
    axios.post(LINK_API.GET_RAW_BATCH, data)
    .then( async response => {
      if(response.data.errorCode === 1){
        await this.setState({
          rawtx1: response.data.data.rawNewKind ,
          rawtx2: response.data.data.rawFungibleAsset,
          nonce: response.data.data.nonce,
          creator_nonce: response.data.data.creator_nonce,
          uuid_response: response.data.data.uuid,
        });
        this.submitData_crop(process, product, response.data.data.uuid)
      }
     
    })
    .catch(() => {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
    });
    
  }
  updateinfo = async (object_result) => {
    if(object_result.image){
      this.setState({
        image: object_result.image.secure_url
      })
    }
    this.setState({data_detail: object_result})
   await this.setState({
      name: object_result.name,
      uuid: object_result.uuid,
      datecreate: moment(object_result.createAt).format('DD/MM/YYYY'),
      processcode:  object_result.nameOfProcess + " - " + object_result.processCode,
      product: object_result.nameOfProduct + " - " + object_result.productType,
      area: object_result.nameOfZone + " - " + object_result.zoneUuid,
      image: object_result.image !== undefined && object_result.image !== "" && object_result.image? object_result.image.secure_url : "",
      status: object_result.status === 1 ? "Đang sản xuất" : "Ngừng sản xuất",
      crop_status: object_result.statusCrop === 1 ? "Đang trong mùa vụ": "Không có mùa vụ",
      valueProcess: object_result.processCode,
      valueProduct: object_result.productType,
      valueProduct_temp: object_result.nameOfProduct + " - " + object_result.productType,
      valueProcess_temp: object_result.nameOfProcess + " - " + object_result.processCode,
      statusCheck: object_result.statusCurrentCrop,
      outsideSystem: object_result.material ? (object_result.material.uuidOfProductType? false : true):false,
      rfid: object_result.RFID ? object_result.RFID : "Chưa có"
    })
  }
  genTable = async (farmList) => {
    await farmList.reverse();
    let stepStatus = "";
    this.setState({data: farmList.map((prop, key) => {
      const length = prop.diary.length;
      const harvests_list = []
      const harvests_final = []
      var i = 0;
      for(i; i<length;i++){
        let certificate = [];
        //const temp = 
        for(let j=0; j< prop.diary[i].images.length; j++){
          certificate.push({
            src:prop.diary[i].images[j].secure_url,
            thumbnail:prop.diary[i].images[j].secure_url,
            caption: prop.nameOfStep[prop.diary[i].step],
          })
        }
        let k = 0;
        if(prop.nameOfStep[prop.diary[i].step] !== "THU HOẠCH"){
          stepStatus = prop.nameOfStep[prop.diary[i].step];
          harvests_list.push([
            prop.nameOfStep[prop.diary[i].step],
            moment(prop.diary[i].createAt).format("DD/MM/YYYY"),
            prop.diary[i].fromId + " - " +  prop.diary[i].nameOfActor,
            <GridContainer>
               {certificate.length === 0  ? <GridItem xs={12} md={12} sm={12} lg={12} style={{display: "flex", justifyContent: "center",alignItems: "center" }}>
              <p>{prop.diary[i].detail}</p> 
              </GridItem> : ""}
               {certificate.length > 0  ? <GridItem xs={6} md={6} sm={6} lg={6} style={{display: "flex", justifyContent: "center",alignItems: "center" }}>
              <p>{prop.diary[i].detail}</p> 
              </GridItem> : ""}
              {certificate.length > 0 ?  <GridItem xs={6} md={6} sm={6} lg={6} >
            <Gallery
              images={certificate}
              rowHeight= {45}
              enableLightbox={true}
              enableImageSelection={false}/>
              </GridItem> :"" } 
            </GridContainer>,
          ])
        }else{
          stepStatus = prop.nameOfStep[prop.diary[i].step];
          harvests_final.push([
            prop.nameOfStep[prop.diary[i].step],
            moment(prop.diary[i].createAt).format("DD/MM/YYYY"),
            prop.diary[i].fromId + " - " +  prop.diary[i].nameOfActor,
            <GridContainer>
               {certificate.length === 0  ? <GridItem xs={12} md={12} sm={12} lg={12} style={{display: "flex", justifyContent: "center",alignItems: "center" }}>
              <p>{prop.diary[i].detail}</p> 
              </GridItem> : ""}
               {certificate.length > 0  ? <GridItem xs={6} md={6} sm={6} lg={6} style={{display: "flex", justifyContent: "center",alignItems: "center" }}>
              <p>{prop.diary[i].detail}</p> 
              </GridItem> : ""}
              {certificate.length > 0 ?  <GridItem xs={6} md={6} sm={6} lg={6} >
            <Gallery
              images={certificate}
              rowHeight= {45}
              enableLightbox={true}
              enableImageSelection={false}/>
              </GridItem> :"" } 
            </GridContainer>,
          ])
          k =  k + 1;
        }
      }
      if(prop.status === 1){
        this.setState({disable: true})
      }
      return {
      id: key+1,
      stepStatus: stepStatus,
      time:  moment(prop.startTime).format("DD/MM/YYYY"),
      product: prop.nameOfProductionObject,
      process: prop.nameOfProcess,
      status: prop.status === 1 ? "Đang sản xuất" : prop.status === 0 ? "Ngưng sản xuất" : "",
      takecare: harvests_list,
      harvest: harvests_final,
      actions: prop.status === 1 ? <Button
      justIcon
      round
      simple
      onClick={() => {
       this.stopCrop(prop.uuidOfProductionObject)
      }}
      color="danger"
      className="remove"
    >
      <Close />
    </Button>:null,
      };
    }),
    load:"none",
    showTable:""
  });
}
  async hideAlert() {
    this.setState({
      alert: null
    });
    let farms = await this.getFarms();
    await this.setState({farmList: farms});
    this.genTable(farms);
  }
  getObjects = async () => {
    try {
      let response = await axios.get(LINK_API.GET_A_PRODUCTION + this.objects_gln);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async getProductype(data){
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].name + " - " + data[i].uuid;
          result.push(temp)
        }
        this.setState({
          productList: result
        })
  }
  async getProcess(data){
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].processName + " - " + data[i].bizProcessCode;
          result.push(temp)
        }
        this.setState({
          processList: result
        })
  }

  async getArea (data) {
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].name + " - " + data[i].uuid;
          result.push(temp)
        }
        this.setState({
          areaList: result
        })
  }

  stopCrop(gln){
    this.setState({
        alert: (
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Kết thúc mùa vụ"
          onConfirm={() => this.onStop(gln)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Kết thúc"
          cancelBtnText="Hủy bỏ"
          showCancel
        >
        </SweetAlert>
        )
      })
  }
  onStop(gln){
    this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block"}}
            title="Đang xử lý!"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
          >
            <div style={{textAlign:"center"}}>
              <img
                alt="{name}"
                src={IMG_LOAD}
                style={{height:"100px"}}
              />
            </div>
          </SweetAlert>
        )
      });
      const data =   {
        "uuidOfProductionObject": gln
      }
      axios.post(LINK_API.STOP_CROP, data)
      .then( async response => {
        if(response.data.errorCode === 1){
          await this.setState({
            statusCheck: 0,
            alert: (
              <SweetAlert
                success
                style={{ display: "block"}}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                showCancel = {false}
              >
              </SweetAlert>
            )
          });
          this.setState({disable: false})
        }
       
      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });
  }
  async checkPermission(){
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN,  { headers: {"Authorization" : `Bearer ${this.props.userInfo.data.token}`} });
      if (response.data.errorCode != 1 || !response.data.data.valid ){
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch(error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  getFarms = async ()  => {
    try {
      let response = await axios.get(LINK_API.GET_CROP_LIST + this.objects_gln);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
        this.props.getProductTypeList();
        this.props.getProcessList();
        this.props.getArea();
        this.props.getHarvestList();
      this.props.getObjectDetail(this.objects_gln)
      this.props.getObjectCrop(this.objects_gln)
    }
    catch (error) {
      console.log(error)
    }
  }
  componentWillReceiveProps(preProps){
    if(preProps.objectDetail !== this.props.objectDetail){
      if(preProps.objectDetail){
        this.updateinfo(preProps.objectDetail.data)
      }
    }
    if(preProps.cropObject !== this.props.cropObject){
      if(preProps.cropObject.data){
        this.genTable(Array.from(preProps.cropObject.data));
      }
    }
    if(preProps.processList !== this.props.processList){
      if(preProps.processList.data){
        this.getProcess(preProps.processList.data)
      }
    }
    if(preProps.productType !== this.props.productType){
      if(preProps.productType.data){
        this.getProductype(preProps.productType.data)
      }
    }
    if(preProps.harvestList !== this.props.harvestList){
      if(preProps.harvestList.data){
        this.updateHarvestList(preProps.harvestList.data)
      }
    }
    if(preProps.productArea !== this.props.productArea){
      if(preProps.productArea.data){
        this.getArea(preProps.productArea.data)
      }
    }
  }
  


  updateHarvestList(data){
    let list = [];
    for( let i = 0; i< data.length;i++){
      list.push(data[i]);
    }
    this.setState({
      harvestList: list,
    })
  }
  handleNewClick() {
    this.props.history.push("/admin/crops/create")
  }

  makePlaceholderFilter(placeholder) {
    return ({filter, onChange}) => (
        <input type='text'
          placeholder={placeholder}
          style={{
            width: '100%'
          }}
          onChange={event => onChange(event.target.value)} 
          value={filter ? filter.value : ''}
        />
      )
  }

  handleDownload() {
    this.setState({
      alert: <SweetAlert
        style={{ display: "block" }}
        title=""
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        showConfirm={false}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
      >
        <div>
          <h4  className="cardtableLabelprint">In mã</h4>
          <div id="downloadimageobject" style={{display: "flex", justifyContent: "center", flexFlow: "column", backgroundColor: "white", borderRadius: "5px", border: "3px solid grey"}}>
          <div style={{display: "flex", flexFlow: "row", justifyContent: "center", marginTop: "20px"}}>
          <img src={LogoVBC} border="0" style={{width: "135px",}}alt="test" />
          <img src={LogoAGD} border="0" style={{width: "120px",  marginLeft: '10px'}} alt="test" />
          </div>
          <div style={{marginTop: "10px"}}>
          <div style={{ display: "flex", flexFlow: "column"}}>
            <div style={{width: "100%"}}>
            <p className="cardtableLabel_content">Tên đối tượng chế biến: </p>
            </div>
            <div style={{width: "100%"}}>
           <p className="cardContentPrint">{this.state.name}</p>
           </div>
          </div>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <p className="cardtableLabel_content">Tên loại sản phẩm: </p>
            <p className="cardContentPrint">{this.state.product}</p>
          </div>
          </div>
          <div style={{ display: "flex", flexFlow: "column", justifyContent:"center", alignItems: "center" }}>
          <QRCode id="qrimage" value={"https://portal.agridential.vn/lookup/" + this.props.userInfo.data.uuid + this.state.uuid} size={150} />
          <p style={{marginTop: "10px"}} className="cardContentPrintcode">{this.props.userInfo.data.uuid + this.state.uuid}</p>
          </div>
          </div>
          <Button id="downloadiamge" onClick={this.downloadQR} color="success" size="sm">Tải xuống</Button>
        </div>

      </SweetAlert>
    })
  }
   downloadQR = async() => {
    document.getElementById("downloadiamge").innerHTML = "Đang tải...";
    htmlToImage.toBlob(document.getElementById('downloadimageobject'))
    .then(async (blob)=> {
        await window.saveAs(blob, 'qrstamp.png');
        this.setState({
          alert: null
        })
    });

  };

  render() {
    const { classes } = this.props;
    let dataDetail = this.state.data_detail;
    const column_sub = [
      {
        Header: '#',
        accessor: 'id_harvest',
        maxWidth:70,
        className:"center", // String-based value accessors!
     },
      {
        Header: 'Thời gian',
        accessor: 'time_harvest' // String-based value accessors!
     },
     {
      Header: 'Mã sản phẩm',
      accessor: 'product_harvest' // String-based value accessors!
      },
     {
      Header: 'Thông tin',
      accessor: 'weight_harvest'
     },
     {
      Header: 'Người thực hiện',
      accessor: 'user_harvest'
     }
    ]
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer >
        <GridItem xs={8}>
          <GridContainer>
            <GridItem xs={7} >
            </GridItem>
            <GridItem xs={5}>
              </GridItem>
          </GridContainer>
          <Card>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {12} md = {12} sm = {12} xs = {12}>
                  <h4 className="cardtableLabel">{strings.infoProcessingObject}</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className="paddingDetailObjects">
            <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.idObject + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info3">
                      <p className="contentProfile">{this.props.userInfo.data.uuid + this.state.uuid}</p>
                    </div>
                  </GridItem>
                  {/* (GLN: {this.objects_gln}) */}
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.rfid + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <p className="contentProfile">{this.state.rfid}</p>
                    </div>
                  </GridItem>
                  {/* (GLN: {this.objects_gln}) */}
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.dateCreated + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <p className="contentProfile">{this.state.datecreate}</p>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.nameOfObject} </p>
                    
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info2">
                      <p className="contentProfile">{this.state.name}</p>
                    </div>
                    <div id="infoedit2" style={{ marginTop: "-30px", display: "none" }}>
                        <CustomInput
                          labelText=""
                          id="user_name"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleChange('name'),
                            type: "text",
                            value: this.state.name,
                            // disabled: true
                          }}
                          value={this.state.name}
                        />
                      </div>
                  </GridItem>
                  {/* (GLN: {this.objects_gln}) */}
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.inArea + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info5">
                      <p className="contentProfile">{this.state.area}</p>
                    </div>
                    <div id="infoedit5"  style={{  display: "none" }}>
                    <form>
                  <FormControl fullWidth className={classes.selectFormControl}>
                        <Select
                          style={{marginBottom: 10}}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select_product'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.area}
                          onChange={this.handleEditArea}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Chọn vùng sản xuất
                            
                        </MenuItem>
                          {this.state.areaList.map( (x,idx) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                        
              </FormControl>
              </form>
              
                        </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.defaultProduct} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info4">
                      <p className="contentProfile">{this.state.product}</p>
                    </div>
                    <div id="infoedit4"  style={{  display: "none" }}>
                    <form>
                  <FormControl fullWidth className={classes.selectFormControl}>
                        <Select
                          style={{marginBottom: 10}}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select_product'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.product}
                          onChange={this.handleEditProduct}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                          
                            // disabled
                            // classes={{
                            //   root: classes.selectMenuItem
                            // }}
                          >
                            {strings.chooseProduct}
                        </MenuItem>
                          {this.state.productList.map( (x,idx) => (
                            <MenuItem
                           
                              // classes={{
                              //   root: classes.selectMenuItem,
                              //   selected: classes.selectMenuItemSelected
                              // }}
                              value={x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                        
              </FormControl>
              </form>
              
                        </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.defaultProcess + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info6">
                      <p className="contentProfile">{this.state.processcode}</p>
                    </div>
                    <div id="infoedit6"  style={{  display: "none" }}>
                    <form>
                  <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                          style={{marginBottom: 10}}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select_product'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.valueProcess_temp}
                          onChange={this.handleEditProcess}
                          inputProps={{
                            name: "type1",
                            id: "type1"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {strings.chooseProcess}
                            
                        </MenuItem>
                          {
                            
                            this.state.processList.map( (x,idx) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                              // value = {x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                          </FormControl>
                      </form>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.status}</p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info2">
                      <p className="contentProfile">{this.state.status}</p>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ display: "flex", flexFlow: "row" }}>
                  <div style= {{marginLeft: 15}}>
                    <Button color="success" size="sm" id="buttonedit" onClick={this.handleContent}>
                      {strings.edit}
                    </Button>
                  </div>
                 
                  <div id="buttoncancle" style={{ display: "none" }}>
                    <Button color="danger"  size="sm" onClick={this.handleCloseedit}>
                     {strings.cancle}
                    </Button>
                  </div>
                </GridContainer>
                {/* <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">Mùa vụ hiện tại: </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info2">
                      <p className="contentProfile">{this.state.crop_status}</p>
                    </div>
                  </GridItem>
                </GridContainer> */}
            </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={4} style={{height: "100%"}}>
          <Card style={{height: 326}}>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {12} md = {12} sm = {12} xs = {12}>
                <GridContainer className="flexRow">
                  
                  
                  <GridItem lg = {12} md = {12} sm = {12} xs = {12}> 
                  <GridContainer>
                      <h4 style={{marginRight: 10}}className="cardtableLabel">Mã truy xuất</h4>

                    </GridContainer>
                    </GridItem>
                  {/* <GridItem lg = {2} md = {6} sm = {6} xs = {6}>
                  <div style={{marginTop: "-6px"}}>
                </div>
                  </GridItem> */}
                  </GridContainer>
                </GridItem>
                
              </GridContainer>
            </CardHeader>
  
            <CardBody id =  "downloadImagefinal"style={{display: "flex", justifyContent: "center", flexFlow: "column"}}>
            <GridItem xs={12} md={12} lg={12} sm={12}  style={{display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column"}}>
            <QRCode id="qrimage" value={"https://portal.agridential.vn/lookup/"+ this.props.userInfo.data.uuid + this.state.uuid} size ={150}/>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column", marginLeft: 19}}>
              <GridContainer >
                <p style={{marginTop: "20px"}}>{ this.props.userInfo.data.uuid + this.state.uuid} </p>
                <Button style={{marginTop: 7}}href={"https://portal.agridential.vn/lookup/"+ this.props.userInfo.data.uuid + this.state.uuid} target="_blank" justIcon color="success" className="like" simple round onClick={() => {}}>
                  <FindInPage />
                </Button>
              </GridContainer>
            </div>
            </GridItem>
            <GridItem xs={12} md={12} lg={12} sm={12} style={{display: "flex", flexFlow: "row", justifyContent: "center"}}>
            <ReactToPrint
                                trigger={this.renderTrigger}
                                content={this.renderContent}
                              />
                               <div id ="printobject" style={{ display: "none" }}>
                                {/* {console.log(this.state.data)} */}
                                <TreePrintTemplate data={dataDetail} uuid = {this.props.userInfo.data.uuid + this.state.uuid} ref={this.setRef} />
                              </div>
                <Button
                  onClick={() => this.handleDownload()}
                  color="success"
                  className="like"
                  size="sm"
                >
                  <GetApp /> <a id = "downloadQRCODE">{strings.download}</a>
                </Button>
            </GridItem>
            </CardBody>
            </Card>
            <Card style={{height: 367}}>
            <CardHeader color="success" icon>
            <GridContainer>
            <GridItem lg = {6} md = {6} sm = {6} xs = {6}>
                  <h4 className="cardtableLabel">{strings.image}</h4>
                  </GridItem>
                  <GridItem lg = {6} md = {6} sm = {6} xs = {6} style={{textAlign: "end", marginTop: "10px"}}>
                   {this.state.editImage === false ? <Button color="success" size="sm"
                    onClick={this.handleEditImage}>{strings.edit}</Button> : 
                    <Button color="success" size="sm" onClick={this.handleSubmitImage}>{strings.update}</Button> }
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody style={{display: "flex", justifyContent: "center", height: "314px"}}>
            <div style={{width: "100%", display:"flex", justifyContent: "center", alignItems:"center", height: "285px"}} id="showImage">
            {(this.state.image!="" && this.state.image!=undefined)?(
                    <img src={this.state.image} />
                  ):(
                    <img src={picture} style={{width: 200, height: 200}}/>
                  )}
            </div>
            {
              this.state.editImage ?
              <ImageActions count={1} onImageSubmit={this.handleImageChange}/> : null
            }        
            </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {6} md = {6} sm = {6} xs = {6}>
                  {/* <CardIcon color="success">
                    <People />
                  </CardIcon> */}
                <h4 className="cardtableLabel">{strings.batchList}</h4>
                </GridItem>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                        {this.state.statusCheck === 1?(
                          <Button disabled color="success" size="sm" onClick={()=> this.handleCrop()}> <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add}</Button>
                        ):( 
                        <Button color="success" size="sm" onClick={()=> this.handleCrop()}> <Add style={{ margin: "0px", marginRight: "5px" }} />  {strings.add} </Button>
                         )}
                    </GridContainer>
                  </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <div style={{textAlign:"center",display:this.state.load}}>
                <img
                  alt="{name}"
                  src={IMG_LOAD}
                  style={{height:"100px"}}
                />
              </div>
              <ReactTable
                data={this.state.data}
                filterable
                // defaultPageSize={5}
                nextText={strings.nextPage}
                    rowsText={strings.row}
                    ofText="/"
                    pageText={strings.page}
                    noDataText={strings.noData}
                columns={[
                  {
                    Header: "#",
                    accessor: "id",
                    filterable:false,
                    maxWidth:70,
                    className:"center",
                    Header: () => (
                      <div style={{
                          textAlign: "center"
                      }}>
                          #
                        </div>
                  )
                  },
                  {
                    Header: strings.dateCreated,
                    accessor: "time",
                    filterable: false
                  },
                  {
                    Header: strings.productType,
                    accessor: "product",
                    filterable: false
                  },
                  {
                    Header: strings.procedure,
                    accessor: "process",
                    filterable: false
                  },
                  {
                    Header: strings.status,
                    accessor: "status",
                    filterable: false
                  },
                  {
                    Header: strings.stepUnderway,
                    accessor: "stepStatus",
                    filterable: false
                  },
                  {
                    Header: strings.actionHeader,
                    accessor: "actions",
                    filterable: false
                  },
                  {
                    Header: "Thu hoạch",
                    accessor: "harvests",
                    filterable: false,
                    show: false
                  },
                ]}
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={false}
                className="-striped -highlight"
                style={{display:this.state.showTable}}
                SubComponent={row => {
                  return (
                    <div style={{ padding: "20px" }}>
                      
                      <div style={{ marginTop: "-37px", width: "100%" }}>
                              <CustomTabs
                                className=""
                                tabs={[
                                  {
                                    tabName: strings.careDiary,
                                    tabContent: (
                                      <div style={{marginLeft: 19}}>
                                        {/* <GridContainer xs={12} md={12} sm={12} lg={12} style={{ marginLeft: "3%"}}> */}
                                        <Table
                                            tableHead={[
                                              strings.nameObject,
                                              strings.time,
                                              strings.empDoJob,
                                              strings.note,
                                            
                                            ]}
                                            tableData={row.original.takecare}
                                            customCellClasses={[
                                              classes.center,
                                              classes.center,
                                              classes.right
                                            ]}
                                            customClassesForCells={[0, 4, 5]}
                                            customHeadCellClasses={[
                                              classes.center,
                                              classes.center,
                                              classes.right,
                                              classes.center
                                            ]}
                                            customHeadClassesForCells={[0, 4, 5]}
                                        />
                                        {/* </GridContainer> */}
                                        
                                      </div>
                                    )
                                  },
                                  {
                                    tabName: strings.harvestDiary,

                                    tabContent: (
                                      <div style={{ marginLeft: 19 }}>
                                        {/* <GridContainer xs={12} md={12} sm={12} lg={12} style={{ marginLeft: "3%"}}> */}
                                          <Table
                                          tableHead={[
                                            strings.nameObject,
                                            strings.time,
                                            strings.empDoJob,
                                            strings.note,
                                          
                                          ]}
                                          tableData={row.original.harvest}
                                          customCellClasses={[
                                            classes.center,
                                            classes.center,
                                            classes.right
                                          ]}
                                          customClassesForCells={[0, 4, 5]}
                                          customHeadCellClasses={[
                                            classes.center,
                                            classes.center,
                                            classes.right,
                                            classes.center
                                          ]}
                                          customHeadClassesForCells={[0, 4, 5]}
                                        />
                                                      {/* </GridContainer> */}
                                      </div>
                                    )
                                  },
                                ]}></CustomTabs>
                                </div>
                                  </div>
                  );
              }}
              //   SubComponent={row => {
              //       return (
              //         <div style={{ padding: "20px" }}>
              //           <em>
              //            Nhật ký chăm sóc
              //           </em>
              //           <br />
              //           <br />
              //           <Table
              //   tableHead={[
              //     "#",
              //     "Tên",
              //     "Thời gian",
              //     "Người thực hiện",
              //     "Ghi chú",
                 
              //   ]}
              //   tableData={row.original.takecare}
              //   customCellClasses={[
              //     classes.center,
              //     classes.center,
              //     classes.right
              //   ]}
              //   customClassesForCells={[0, 4, 5]}
              //   customHeadCellClasses={[
              //     classes.center,
              //     classes.center,
              //     classes.right
              //   ]}
              //   customHeadClassesForCells={[0, 4, 5]}
              // />
              //   <br />
              //           <br />
              //    <em>
              //             Nhật ký thu hoạch
              //           </em>
              //           <br />
              //           <br />
              //           <Table
              //   tableHead={[
              //     "#",
              //     "Tên",
              //     "Thời gian",
              //     "Người thực hiện",
              //     "Ghi chú",
                 
              //   ]}
              //   tableData={row.original.harvest}
              //   customCellClasses={[
              //     classes.center,
              //     classes.center,
              //     classes.right
              //   ]}
              //   customClassesForCells={[0, 4, 5]}
              //   customHeadCellClasses={[
              //     classes.center,
              //     classes.center,
              //     classes.right
              //   ]}
              //   customHeadClassesForCells={[0, 4, 5]}
              // />
              //         </div>
              //       );
              //   }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
      
          open={this.state.openCreateSeason}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <Card style={{width: 500}}>
            <CardHeader color="success" icon>
              <h4 className="cardtableLabel">Thông tin lô chế biến</h4>
            </CardHeader>
            <CardBody> 
              <form>
              <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                          htmlFor="product"
                          className={classes.selectLabel}
                        >
                          Loại sản phẩm
                              </InputLabel>
                        <Select
                          style={{marginBottom: 10}}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select_product'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.valueProduct_temp}
                          onChange={this.handleSelectProduct}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                          >
                            Chọn loại sản phẩm
                            
                        </MenuItem>
                          {this.state.productList.map( (x,idx) => (
                            <MenuItem
                              value={x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel
                  
                          htmlFor="process"
                          className={classes.selectLabel}
                        >
                          Quy trình sản xuất
                              </InputLabel>
                        <Select
                        style={{marginBottom:38}}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select_process'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.valueProcess_temp}
                          onChange={this.handleSelectProcess}
                          inputProps={{
                            name: "type1",
                            id: "type1"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Chọn quy trình sản xuất
                            
                        </MenuItem>
                          {this.state.processList.map( (x,idx) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                        
                        <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: "-25px"}}>
                        {this.state.outsideSystem === false ? 
                          <InputLabel
                  htmlFor="process"
                  className={classes.selectLabel}
                >
                 Đợt thu hoạch
                      </InputLabel>
                      : ""}
                      {this.state.outsideSystem === false ? 
                       <Select
                      //  style={{marginBottom:38}}
                         MenuProps={{
                           className: classes.selectMenu
                         }}
                         id='select_process'
                         classes={{
                           select: classes.select
                         }}
                         value={this.state.valueHarvest_temp}
                         onChange={this.handleSelectHarvest}
                         inputProps={{
                           name: "type1",
                           id: "type1"
                         }}
                       >
                         <MenuItem
                         >
                           Chọn đợt thu hoạch
                           
                       </MenuItem>
                         {this.state.harvestList.map( (x,idx) => (
                           <MenuItem
                             value={x}
                             key={idx}
                           >
                             {x.id}
                           </MenuItem>
                         ))}
                       </Select>: ""}
                
                {this.state.outsideSystem === true ? <p className="StarttimeTitle" style={{marginTop: "10px"}}>Ngày nhập nguyên liệu</p> : ""}
                       {this.state.outsideSystem === true ? <Datetime
                  color="success"
                  value= {this.state.importDate}
                  className="InputDatetimex"
                  formControlProps={{
                    onChange: this.handleChangetime('importDate'),
                  }}
                  inputProps={{ placeholder: "Ngày nhập nguyên liệu", title: "Ngày nhập nguyên liệu",className: "InputDatetime" }}
                  onChange= {this.handleChangetime('importDate')}
                />:"" } 
                 {this.state.outsideSystem ? <hr className="LineBreakDesDate"/> :""}
                 <div style={{width: "100%", marginTop:"20px"}}>
                 <p className="StarttimeTitle">Ngày bắt đầu</p>
                        <Datetime
                  color="success"
                  value= {this.state.startTime}
                  className="InputDatetime"
                  formControlProps={{
                    onChange: this.handleChangetime('startTime'),
                  }}
                  inputProps={{ placeholder: "Ngày bắt đầu", title: "Ngày bắt đầu",className: "InputDatetime" }}
                  onChange= {this.handleChangetime('startTime')}
                />
                <hr className="LineBreakDesDate"/>
                 </div>    
              </FormControl>
                 <p
                    id="noti"
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      color: 'red',
                      fontFamily: 'Muli',
                      marginTop: 5,
                      marginBottom: 5,
                    }}>
                  </p>
                {/* <Button color="warning" onClick={()=>{this.setS}}>Hủy</Button> */}
                {/* {console.log(this.state.startTime) */}
                
                <Button  color="success" onClick={() => this.onCrop(this.state.startTime,this.state.valueProduct,this.state.valueProcess)}>Tạo mới</Button>
              </form>
            </CardBody>
          </Card>
        </Dialog>
      {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getObjectDetail: (data) => dispatch({ type: "GET_OBJECT_PROCESSING_DETAIL", uuid: data}),
    getObjectCrop: (data) => dispatch({type:"GET_CROP_OBJECT", uuid: data}),
    getProcessList: () => dispatch({ type: "GET_PROCESS_LIST"}),
    getProductTypeList: () => dispatch({type: "GET_PRODUCT_LIST"}),
    getHarvestList: () => dispatch({type: "GET_HARVEST_LIST"}),
    getArea: () => dispatch({type: "GET_AREA_LIST"}),
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
    objectDetail: state.objectProccessDetail,
    cropObject: state.cropObject,
    productType: state.productType,
    processList: state.processList,
    harvestList: state.harvestList,
    productArea: state.productArea,}
}
export default withStyles(styles)(withRouter(connect(mapStateToProps,mapDispatchToProps)(TableFarmList)));
