import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


// core components
import CustomInput from "./input/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const style = {
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    marginLeft: "15px"
  },
  alignLeft: {
    textAlign: "left"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  },
  nopadding:{
    padding: "0px 0px !important"
  }
}

class InfoProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      image: null,
      productName: "",
      productNameState:"",
      productNameHelp:"",
      productDesc: "",
      productDescState:"",
      productDescHelp:"",
      productExpired: "",
      productExpiredState:"",
      productExpiredHelp:"",
      productExpiredType: "",

      cooState: '',
      cooCodeState: '',
      cooPhoneState: '',
      cooMailState: '',
      cooAddState: '',
      cooWebState: '',
      destime:["sau khi đóng gói", "sau khi thu hoạch"]
    };
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount(){
    this.setState({
      user_name: this.props.cooperativeInfo.user_name || '',
      phone: this.props.cooperativeInfo.phone || '',
      website: this.props.cooperativeInfo.website || '',
      email1: this.props.cooperativeInfo.email1 || '',
      gln: this.props.cooperativeInfo.gln||'',
      address: this.props.cooperativeInfo.address||'',
    });
  }

  handleSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById("noti").innerHTML = "";
  }

  handleChange = name => event => {
      if(name === "user_name"){
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cooState: "success"
        })
      }
      if(name === "gln"){
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cooCodeState: "success"
        })
      }
      if(name === "phone"){
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cooPhoneState: "success"
        })
      }
      if(name === "email1"){
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cooMailState: "success"
        })
      }
      if(name === "website"){
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cooWebState: "success"
        })
      }
      if(name === "address"){
        document.getElementById("noti").innerHTML = "";
        this.setState({
          cooAddState: "success"
        })
      }
      this.setState({ [name]: event.target.value});
      this.props.getData(name, event.target.value);
  };

  onImageSubmit(file) {
    if (file != null) {
      this.setState({
        image: file
      })
    }
  }

  render() {
    const { classes } = this.props;
    return (
    <div style={{ textAlign: "center", marginTop: 10 }}>
        <CustomInput
            labelText="Doanh Nghiệp"
            success={this.state.cooState === "success"}
            error={this.state.cooState === "error"}
            id="user_name"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('user_name')
            }}
            inputProps={{
              value: this.state.user_name,
            }}
            helpText={this.state.productNameHelp}
        />
        <div style={{flexFlow:"row", display: "flex"}} className="nopadding">
        <GridItem xs={6} className={classes.nopadding}>
        <CustomInput
            labelText="Mã DN (GS1)"
            success={this.state.cooCodeState === "success"}
            error={this.state.cooCodeState === "error"}
            id="gln"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('gln')
            }}
            inputProps={{
              value: this.state.gln,
              type:"number"
            }}
            helpText={this.state.productNameHelp}
        />
        </GridItem>
        <GridItem xs={6} className={classes.nopadding} style={{marginLeft: "8px"}}>
        <CustomInput
            labelText="Số điện thoại"
            success={this.state.cooPhoneState === "success"}
            error={this.state.cooPhoneState === "error"}
            id="phone"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('phone')
            }}
            inputProps={{
              value: this.state.phone,
              type: "number"
            }}
            helpText={this.state.productNameHelp}
        />
        </GridItem>
        </div>
        <CustomInput
            labelText="Email"
            success={this.state.cooMailState === "success"}
            error={this.state.cooMailState === "error"}
            id="email1"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('email1')
            }}
            inputProps={{
              value: this.state.email1,
            }}
            helpText={this.state.productNameHelp}
        />
        <CustomInput
            labelText="Website"
            success={this.state.cooWebState === "success"}
            error={this.state.cooWebState === "error"}
            id="website"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('website')
            }}
            inputProps={{
              value: this.state.website,
            }}
            helpText={this.state.productNameHelp}
        />
        <CustomInput
            success={this.state.cooAddState === "success"}
            error={this.state.cooAddState === "error"}
            labelText="Địa chỉ"
            id="address"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('address'),
            }}
            inputProps={{
              multiline: true,
              value: this.state.address,
            }}
            helpText={this.state.productDescHelp}
        /> 
    </div>
    );
  }
}

export default withStyles(style)(InfoProduct);
