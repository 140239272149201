import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import SelectModern from 'react-select'
import moment from 'moment';
// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/Add";
import Gallery from '../../components/Forms/Gallery/Gallery';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Detail from "@material-ui/icons/Info";
import GetApp from "@material-ui/icons/GetApp"
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';

import ReactExport from "react-export-excel";
import strings from "../../../../constants/strings";
import Pagination from "../../components/Pagination/index.jsx"
import { RootRef } from "@material-ui/core";
import { isFulfilled } from "q";
import { string } from "postcss-selector-parser";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  ...extendedFormsStyle,
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  UnsetOverflow: {
    overflow: "unset"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.genTable = this.genTable.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.role = cookie.load("checkrole");

    this.state = {
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      selected: cookie.load("slaughter", { path: "/" })?cookie.load("slaughter", { path: "/" }):strings.all,
      defaultSelect: cookie.load("slaughter", { path: "/" })?[{value: cookie.load("slaughter", { path: "/" }), label: cookie.load("slaughter", { path: "/" })}]:[{value: strings.all, label: strings.all}],
      options:  [{value: strings.slaughter, label: strings.slaughter}, {value: strings.alive, label: strings.alive}, {value: strings.all, label: strings.all}],
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
      startTime: "",
      endTime: "",
      valueFilter: "",
      pages: 1,
      valueStatus: "",
      listdata: [],
      listdataFix: [],
      loading: false,
    };
    this.token = cookie.load('token');

  }

  handleChange_Select = async (selectedOption, event) => {

    cookie.save("slaughter",selectedOption.value, { path: "/" })

    if(selectedOption.value === strings.all){
        this.setState({listdata: this.state.listdataFix});
    }
    else{
        let dataFilter = this.state.listdataFix.filter(item=>{
            return item.status == selectedOption.value;
        })
        this.setState({listdata: dataFilter});
    }
    
}

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  renderTrigger() {
    return <Button color="success" onClick={() => {
      document.getElementById("printobject").style = "display: block"
    }}>In Mã</Button>;
  }

  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }
  setRef = (ref) => {
    this.componentRef = ref;
  }
  isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  genTable = async (farmList) => {

    
    let exportData = [];
    exportData = farmList.map(item => {
    //   console.log("item", item)
      return ({
        uuid: item.uuid,
        name: item.name,
        time: moment(item.createdAt).format("DD/MM/YYYY"),
        nameOfZone: item.nameOfZone,
        type: (item.type === 0) ? "Luống" : (item.type === 1) ? "Cây" : (item.type === 2) ? "Toàn bộ vùng sản xuất" : (item.type === 3) ? "Lô nguyên liệu thô" : "",
        // nameOfProcess: item.nameOfProcess,
        // lastAction: item.lastAction.nameOfLastAction
      })
    })

    await this.setState({ export: exportData })
    //-----------------------

    console.log("farm", farmList)
    
    await farmList.reverse();
    await this.setState({
        listdataFix: farmList.map((prop, key) => {
        console.log(prop.placeName)
        return {
          uuid: prop.uuid,
          name: <div style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}>


            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                {/* <img src={prop.image[0].url} style={{ height: 45 }} /> */}
                {/* <ModalImage
                small={prop.image[0].url}
                large={prop.image[0].url}
                alt=""
              /> */}
                {this.isEmptyObject(prop.image) ? "" : <Gallery
                  // {(!prop.image) ? "":<Gallery
                  images={[{
                    src: prop.image.secure_url, thumbnail: prop.image.secure_url,
                    caption: ""
                  }]}
                  rowHeight={50}
                  enableLightbox={true}
                  enableImageSelection={false}
                />}
              </GridItem>
              <GridItem xs={12} sm={12} md={7}>
                <div><p style={{ marginLeft: "-24px", marginTop: 17, textAlign: "left" }}>{prop.name}</p></div>
              </GridItem>
            </GridContainer>
          </div>,
          createAt: moment(prop.createdAt).format("DD/MM/YYYY"),
          status: prop.status ? strings.slaughter:strings.alive,
          location: prop.placeName + " - " + prop.placeUuid,
          // image: (prop.image) ? <img src={prop.image.secure_url} style={{ height: 50 }} /> : null,
          area: prop.nameOfZone,
          type: (prop.type === 0) ? "Luống" : (prop.type === 1) ? "Cây" : (prop.type === 2) ? "Toàn bộ vùng sản xuất" : (prop.type === 3) ? "Lô nguyên liệu thô" : "",
          // product: prop.nameOfProduct,
          // process: prop.processCode,
          actions:
            <Button style={{ backgroundColor: "white", color: "#212b36" }} size="sm" onClick={() => this.handleCrop_List(prop.uuid, key)}> <Detail style={{ margin: "0px", marginRight: "5px" }} /> <a style={{ fontSize: "12px", textTransform: "none" }}>{strings.watchDetail}</a> </Button>

        };
      }),
      load: "none",
      showTable: ""
    });

    let path = cookie.load("slaughter", { path: "/" });
    if(path && path != strings.all){
        let listFilter = this.state.listdataFix.filter(item=>{
            return path == item.status
        })

        this.setState({listdata: listFilter.reverse()})
    }
    else{
        this.setState({listdata: this.state.listdataFix.reverse()})
    }

    
  }
  stopCrop(gln) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Kết thúc mùa vụ"
          onConfirm={() => this.onStop(gln)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Kết thúc"
          cancelBtnText="Hủy bỏ"
          showCancel
        >
        </SweetAlert>
      )
    })
  }
  onStop(gln) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    const data = {
      "uuidOfProductionObject": gln
    }
    axios.post(LINK_API.STOP_CROP, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title={strings.success}
                onConfirm={() => this.hideAlert()}
                showCancel={false}
              >
              </SweetAlert>
            )
          });
          let farms = await this.getFarms();
          this.setState({ farmList: farms });
          this.genTable(farms);
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title={strings.failed}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });
  }
  async handleCrop_List(id, rfid, boxid, status) {
    await cookie.save("IDCattle", id, { path: "/" });
    await cookie.save("RFIDCattle", rfid, { path: "/" });
    await cookie.save("shipmentCattle", boxid, { path: "/" });
    await cookie.save("statusCalle", status, { path: "/" });
    this.props.history.push("/admin/cattle/list/detail")
  }
  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.CREATE_OBJECT_PRODUCTION);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    let dataCattle = [];
    try {
      await this.checkPermission();
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }

      await axios.get(LINK_API.GET_CATTLE)
      .then(async res=>{
        let data = res.data.data;
        
        
        for(let i = data.length - 1; i > -1; i--){
          for(let j = 0; j < data[i].ProductList.length; j++){
            if(data[i].ProductList[j].status === 1){
              data[i].ProductList[j].status = strings.slaughter;
            }
            else{
              data[i].ProductList[j].status = strings.alive;
            }

            if(!data[i].ProductList[j].NON_GS1){
              data[i].ProductList[j].NON_GS1 = "0000000000000";
              data[i].ProductList[j].RFID = "0000000000000";
            }

            if(!data[i].LNC){
              data[i].ProductList[j].LNC = "0000000000000";
            }
            else{
              data[i].ProductList[j].LNC = data[i].LNC;
            }

            data[i].ProductList[j].boxID = data[i].id;
            data[i].ProductList[j].actions = <Button style={{ backgroundColor: "white", color: "#212b36" }} size="sm" onClick={() => this.handleCrop_List(data[i].ProductList[j].NON_GS1, data[i].ProductList[j].RFID, data[i].ProductList[j].boxID, data[i].ProductList[j].status)}> <Detail style={{ margin: "0px", marginRight: "5px" }} /> <a style={{ fontSize: "12px", textTransform: "none" }}>{strings.watchDetail}</a> </Button>;
            data[i].ProductList[j].location = data[i].ProductList[j].placeName  + " - " + data[i].ProductList[j].placeUuid
            dataCattle.push(data[i].ProductList[j]);

          }
        }

        // await this.setState({listdataFix: dataCattle});

        let path = cookie.load("uuidPartner", { path: "/" });
        if (path) {
          this.setState({ valueFilter: path });
          cookie.remove("uuidPartner")
        }
        // if(path && path != strings.all){
        //     let listFilter = this.state.listdataFix.filter(item=>{
        //         return path == item.status
        //     })

        //     this.setState({listdata: listFilter})
        // }
        // else{
            this.setState({listdata: dataCattle})
        // }

        await this.setState({load:"none",
        showTable:""})
      })
      .catch(err=>{
        console.log(err)
      })
    }
    catch (error) {
      console.log(error)
    }

    // console.log("daa", dataCattle)
    let path = await cookie.load("slaughter", { path: "/" })
  }

  
  handleChange = name => async moment => {

    await this.setState({
      [name]: moment._d,
    });
  };
  handleCrop(tree, product, process) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Tạo mùa vụ cho đối tượng:"
          onConfirm={() => this.handleInputDate(tree, product, process)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Đóng"
          showCancel
        >
          {tree}
        </SweetAlert>
      )
    })
  }
  handleInputDate(tree, product, process) {
    //const {classes} = this.props
    this.setState({
      alert: (
        <SweetAlert
          //className= {this.props.classes.UnsetOverflow}
          warning
          style={{ display: "block", overflow: "unset" }}
          title="Nhập thông tin mùa vụ"
          onConfirm={() => this.onCrop(tree, product, process)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Đóng"
          showCancel
        >
          <Card className="noShadow" style={{ marginTop: "-8px" }}>
            <CardBody>
              {/* <InputLabel className={classes.label}>
                Datetime Picker
              </InputLabel> */}
              <br />
              <FormControl fullWidth>
                <Datetime
                  value={this.state.startTime}
                  className="InputDatetime"
                  formControlProps={{
                    onChange: this.handleChange('startTime'),
                  }}
                  inputProps={{ placeholder: "Ngày bắt đầu", className: "InputDatetime" }}
                  onChange={this.handleChange('startTime')}
                />
                <hr className="LineBreakDesDate" />
              </FormControl>
            </CardBody>
          </Card>
          <Card className="noShadow" style={{ marginTop: "-60px" }}>
            <CardBody>
              {/* <InputLabel className={classes.label}>
                Datetime Picker
              </InputLabel> */}
              <br />
              <FormControl fullWidth>
                <Datetime
                  className="InputDatetime"
                  inputProps={{
                    placeholder: "Ngày kết thúc",
                    className: "InputDatetime",
                  }}
                />
                <hr className="LineBreakDesDate" />
              </FormControl>
            </CardBody>
          </Card>
        </SweetAlert>
      )
    })
  }
  onCrop(tree, product, process) {
    //console.log(tree, area, process)
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    const data = {
      "uuidOfProductionObject": tree,
      "processCode": process,
      "uuidOfProductType": product,
    }
    axios.post(LINK_API.ASSIGN_CROP, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                showCancel={false}
              >
              </SweetAlert>
            )
          });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });

  }
  handleNewClick() {
    this.props.history.push("/admin/areas/objects/list/create")
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer style={{display: "flex", flexFlow: "center"}}>
                    <h4 style={{marginRight: 19}}className="cardtableLabel">{strings.farmingObjectList}</h4>

                    <div className="">
                      <ExcelFile element={<Button justIcon color="success" className="like" simple round > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> </Button>}>
                        <ExcelSheet data={this.state.export} name="Cattle Import">
                          <ExcelColumn label="ID" value="uuid" />
                          <ExcelColumn label="Name" value="name" />
                          <ExcelColumn label="Import Date" value="time" />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                    </GridContainer>
                  </GridItem>

                  
                  
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      {(this.props.userInfo.data.active === true) ? <Button color="success" size="sm" onClick={this.handleNewClick}> <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button> : null}
                    </GridContainer>
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                    <div style={{ border: "1px solid #0f7343" , borderRadius: "3px" , width: "100%", display: "flex", justifyContent: "flex-start", flexFlow: "column", textAlign: "start" }}>
                        <SelectModern
                            defaultValue={this.state.defaultSelect}
                            options={this.state.options}
                            // value={this.state.clientId_label}
                            onChange={this.handleChange_Select}
                            // className={classes.SelectDrop}
                            placeholder={strings.chooseArea}
                        />
                    </div>
                </GridItem> */}

                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  loading={this.state.loading}
                  data={this.state.listdata}
                  defaultFiltered={[{ id: "location", value: this.state.valueFilter }]}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                  previousText={strings.prevPage}
                  nextText={strings.nextPage}
                  rowsText={strings.row}
                  ofText="/"
                  pageText={strings.page}
                  noDataText={strings.noData}
                  columns={[
                    
                    {
                      Header: "ID",
                      accessor: "NON_GS1",
                      sortable: false,
                      style: { 'whiteSpace': 'unset' }
                    },
                    {
                      Header: "LNC",
                      accessor: "LNC",
                      sortable: false,
                      style: { 'whiteSpace': 'unset' }
                    },
                    {
                      Header: "RFID",
                      accessor: "RFID",
                      sortable: false
                    },
                    {
                      Header: strings.shipmentNumber,
                      accessor: "boxID",
                      sortable: false
                    },
                    {
                      Header: strings.status,
                      accessor: "status",
                      sortable: false
                    },
                    {
                      Header: strings.location,
                      accessor: "location",
                      sortable: false
                    },
                    {
                      Header: strings.actionHeader,
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={true}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                  // onPageChange={(pageIndex) => {console.log("Check",pageIndex + 1)}} 
                />
              </CardBody>
            </Card>

          </GridItem>
        </GridContainer>
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getObject: (index) => dispatch({ type: "GET_OBJECT_LIST", index: index }),
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    objectList: state.objectList
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
