const initialState = { data: "", errorCode: "", list: [] };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_INFO_ACTIVE_STAMP':
            return {
                ...state,
            };
        case 'GET_INFO_ACTIVE_STAMP_SUCCESS':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode,
                list: action.list
            };
        case 'GET_INFO_ACTIVE_STAMP_FAILURE':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        default:
            return state;
    }
}
export default reducer;