import LocalizedStrings from 'localized-strings';
import VN from "assets/img/flags/VN.png";
import EN from "assets/img/flags/GB.png";

const strings = new LocalizedStrings({

  'vi': {
    icon:VN,
    detail: "Chi tiết",
    total: "Tổng",
    box: "lô",
    times: "lần",
    actions: "hành động",
    watchDetail: "Xem chi tiết",
    add: "Thêm mới",
    confirm: "Xác nhận",
    nextPage: "Trang sau",
    prevPage: "Trang trước",
    inProduction: "Đang sản xuất",
    stopProduction : "Ngưng sản xuất",
    none: "Chưa có",
    edit: "Chỉnh sửa",
    create: "Tạo mới",
    finish: "Kết thúc",
    image: "Hình ảnh",
    addImage: "Thêm ảnh",
    row: "Hàng",
    page: "Trang",
    noData: "Không có dữ liệu",
    update: "Cập nhật",
    cancle: "Hủy bỏ",
    success: "Thành công",
    failed: "Thất bại",
    processing: "Đang xử lý",
    search: "Tìm kiếm",
    save: "Lưu",
    
    productType: "Loại sản phẩm",
    productTypeList: "Danh sách loại sản phẩm",
    productionArea: "Vùng sản xuất",
    productionAreaList: "Danh sách vùng sản xuất",
    list: "Danh sách",
    farmingObject: "Gia súc",
    processingObject: "Đối tượng chế biến",
    productionObject: "Đối tượng sản xuất",
    employee: "Nhân viên",
    procedure: "Quy trình",
    customer: "Đối tác",
   
    feedback: "Góp ý",
    stampManage: "Quản lý tem",

    //Thông kê chung
    statistical: "Thống kê chung",
    activatingStampsProduct: "Số lượng tem kích hoạt cho sản phẩm",
    totalActiveStampBox: "Tổng số tem lô hàng",
    totalActiveStampProduct: "Tổng số tem sản phẩm",
    totalHarvest: "Tổng số đợt thu hoạch",
    totalcropYields: "Tổng sản lượng thu hoạch",
    cropYieldsheader: "Sản lượng thu hoạch",
    activationStamp: "Tem kích hoạt",
    numberScanCode: "Số lượng quét mã truy xuất",
    activitiesRecorded: "Số hành động ghi nhận",
    viewList: "Xem danh sách",
    numOfCattles: "Tổng số gia súc",
    total: "Tổng",
    alive: "Còn sống",
    slaughter: "Đã mổ",
    all: "Tất cả",
    numOfShipment: "Số đợt vận chuyển",
    numOfPartner: "Tổng số nhà phân phối",
    feedlot: "Trang trại",
    depot: "Kho chứa",
    abattoirs: "Lò mổ",
    shipmentAnalyst: "Thống kê đợt nhập",
    chooseShipment: "Chọn đợt nhập",
    isSlaughter: "Thống kê đợt giết mổ",
    numOfSlaughter: "Số gia súc đã giết mổ",

    //distributors
    distributor: "Nhà phân phối",
    nameDistributor: "Tên nhà phân phối",
    shipmentNumber: "Số lô hàng phân phối",

    //Product Type
    productId: "Mã sản phẩm",
    nameProductType: "Tên loại sản phẩm",
    certificate: "Chứng nhận",
    gtin: "Mã GTIN",
    description: "Miêu tả",
    status: "Trạng thái",
    actionHeader: "Hành động",
    productypeInfo: "Thông tin loại sản phẩm",
    unit: "Đơn vị",
    chooseUnit: "Chọn đơn vị",
    resgisGTIN: "Đã đăng ký mã GTIN",
    moreInfoProduct: "Thêm thông tin sản phẩm",
    productDes: "Mô tả sản phẩm",
    productSpec: "Quy cách sản phẩm",
    packing: "Quy cách đóng gói",
    addFields: "Thêm trường thông tin",
    fieldRequire: "Trường thông tin bắt buộc",
    deleteImage: "Xóa ảnh",
    time: "Thời gian",

    //production area
    address: "Địa chỉ",
    createTime: "Thời gian tạo",
    nameArea: "Tên gợi nhớ",
    idArea: "Mã vùng",
    type: "Loại",
    totalobject: "Tổng đối tượng",
    areaInfo: "Thông tin vùng sản xuất",
    empInCharge: "Nhân viên phụ trách",
    moreInfoArea: "Thêm thông tin vùng sản xuất",
    regisGLN: "Đã đăng ký mã GLN",
    gln: "Mã GLN",
    chooseType: "Chọn loại vùng sản xuất",
    typeArea: "Loại vùng sản xuất",
    enterEmpId: "Nhập mã nhân viên",

     //Object
     farmingObjectList: "Danh sách gia súc                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
     processingObjectList: "Danh sách đối tượng chế biến",
     idObject: "Mã đối tượng",
     nameObject: "Tên",
     registerDate: "Ngày đăng ký",
     careDiary: "Nhật ký chăm sóc",
     harvestDiary: "Nhật ký thu hoạch",
     empDoJob: "Người thực hiện",
     note: "Ghi chú",
     cattleManagement: "Gia súc",
     cattleImport: "Danh sách nhập khẩu gia súc",

     //Create Object processing
     infoProcessingObject: "Thông tin đối tượng chế biến",
     infoFarmingObject: "Thông tin gia súc",
     mainMaterial: "Nguyên liệu chính",
     checkMaterial: "Nguyên liệu đã tồn tại trong hệ thống",
     defaultProduct: "Loại sản phẩm sản xuất mặc định",
     typeObjectProcessing: "Loại đối tượng chế biến",
     chooseTypeObject: "Chọn loại đối tượng",
     chooseArea: "Chọn vùng sản xuất",
    defaultProcessing: "Quy trình chế biến mặc định",
    moreInfoObjetProcessing: "Thêm thông tin đối tượng chế biến",
    mainMaterialType: "Loại nguyên liệu chính",
    chooseMainMaterial: "Chọn loại nguyên liệu chính",
    chooseProduct: "Chọn loại sản phẩm mặc định",
    chooseProcess: "Chọn quy trình mặc định",
    basicInfo: "Thông tin cơ bản",
    scanCode: "Mã truy xuất",
    dateCreated: "Ngày tạo",
    nameOfObject: "Tên đối tượng",
    inArea: "Thuộc vùng sản xuất",
    defaultProduct: "Loại sản phẩm sản xuất (mặc định)",
    defaultProcess: "Quy trình sản xuất (mặc định)",
    rfid: "Mã RFID",
    printQR: "In mã QR",
    download: "Tải xuống",
    cropList: "Danh sách nhập khẩu",
    stepUnderway: "Bước đang thực hiện",
    batchList: "Danh sách lô chế biến",
    timeLine:"Dòng thời gian",
    trackingImage: "Hình ảnh theo dõi",

   //Emp List
   empList: "Danh sách nhân viên",
   nameEmp: "Tên nhân viên",
   username: "Tên đăng nhập",
   empID: "Mã nhân viên",
   role: "Vai trò",
   pass: "Mật khẩu",
   inChargeArea: "Vùng sản xuất phụ trách",
   otherInfo: "Thông tin khác",
   inChargeObject: "Đối tượng sản xuất phục trách",
   idProductionArea: "Nhập mã vùng sản xuất",
   addNewEmp: "Thêm nhân viên mới",
   infoEmp: "Thông tin nhân viên",
   rePass: "Khôi phục mật khẩu",
   active: "Kích hoạt",
   deActive: "Vô hiệu hóa",

     //Procedure List
     processList: "Danh sách quy trình",
     namePro: "Tên quy trình",
     numSteps: "Số bước",
     nameStep: "Tên bước",
     chooseType: "Chọn loại quy trình",
     idPro: "Mã quy trình",
     typePro: "Loại quy trình",
     activeStampPro: "Hiển thị bước kích hoạt tem khi người dùng thực hiện truy xuất",
     packedPro: "Hiển thị bước đóng gói khi người dùng thực hiện truy xuất",
     detailProcess: "Chi tiết quy trình",
     isPublic:"Hiển thị bước này trên trang web truy xuất nguồn gốc",
     showPicture: "Hình ảnh minh họa",
     typeData: "Định dạng dữ liệu",
     chooseData: "Chọn loại dữ liệu",
     defaultValue: "Giá trị mặc định",
     processInfo: "Thông tin quy trình",
     fieldName: "Tên trường",
     isPublic_sub: "Hiển thị trường nên trang web truy xuất nguồn gốc",
     addStep: "Thêm bước",
     value: "Giá trị",

    //Feedback List
    feedbackList: "Phản hồi từ khách hàng",
    feedback: "Phản hồi",
    nameProduct: "Tên sản phẩm",

      //Distributor
      distributorList: "Danh sách nhà phân phối",
      nameBranch: "Tên chi nhánh",
      phone: "Số điện thoại",
      idDist: "Mã nhà phân phối (nếu có)",
      moreInfoDis: "Thêm thông tin nhà phân phối",
      disInfo: "Thông tin nhà phân phối",
      

         //History Stamp
    historyStamp: "Lịch sử chuyển hàng",
    stampType: "Loại tem",
    quantity: "Số lượng",
    startId: "Tem bắt đầu",
    endId: "Tem kết thúc",
    quantityActive: "Số lượng kích hoạt",
    activeStamp: "Tem kích hoạt",
    dateActive: "Thời điểm kích hoạt",
    stampActived: "Tem đã kích hoạt",
    transfer: "Vận chuyển",
    isTransfer: "Đã vận chuyển",
    
    //Profile
    profile: "Thông tin tài khoản",
    logOut: "Đăng xuất",
    nameCompany: "Tên doanh nghiệp",
    idCompany: "Mã doanh nghiệp",
    introCompany: 'Giới thiệu doanh nghiệp',
    changePass: "Đổi mật khẩu",
    certificate: "Chứng nhận",
    setting: "Thiết lập",
    brand: "Thương hiệu",
    contactInfo:"Thông tin liên hệ",
    showTime: "Hiển thị thời gian",
    showDetailProcess: "Hiển thị chi tiết quy trình",
    showName: "Hiển thị tên thay thế",
    inputName: "Nhập tên thay thế",
    banner: "Ảnh banner",
    logo: "Ảnh logo",
    agree: "Tôi hiểu rằng những thông tin trên sẽ xuất hiện trong phần Xuất xứ sản phẩm khi người tiêu dùng quét mã QR.",
  
    //Import List Cow
    peopleDo: "Người nhập",
    timeImport: "Thời gian nhập",
    quantityCow: "Số luợng",
    noteFile: "File dữ liệu",
    sold: "Đã bán",
    notSold: "Chưa bán",
    sell: "Bán hàng",
    idDis: "Mã nhà phân phối",
    selectIdUnit: "Chọn vật nuôi muốn chuyển",
    location: "Vị trí",
    shipping: "Chuyển hàng",


    //distributor
    import: "Nhập hàng",
    branch: "Chi nhánh",
    export: "Xuất hàng",
    historyImport: "Lịch sử nhập hàng",
    historyExport: "Lịch xử xuất hàng",
    LNC: "Mã LNC",
    CattleNo: "No. of Catlle",
    idType: "Loại ID",
    chooseidType: "Chọn loại ID",
    inputData: "Nhập dữ liệu",


    receivedDate: "Ngày nhập",
    selectField: "Chọn trường",



    //Cattle Detail
    who: "Người thực hiện",
    Where: "Vị trí",
    when: "Thời gian",


    //Login Page
    usernameoremail: "Tên đăng nhập hoặc email",
    passWord: "Mật khẩu",
    rememberPass: "Nhớ mật khẩu",
    forgotPass: "Quên mật khẩu",
    logIn: "Đăng nhập",
  },
  'en': {
    icon:EN,
    selectField: "Select field",
    detail: "Detail",
    value: "Value",
    total: "Total",
    box: "box",
    times: "times",
    actions: "Actions",
    watchDetail: "Detail",
    add: "Add",
    save: "Save",
    create: "Create",
    confirm: "Confirm",
    nextPage: "Next page",
    prevPage: "Previous page",
    inProduction: "In production",
    stopProduction : "Stop producing",
    none: "Not yet",
    edit: "Edit",
    finish: "Finish",
    image: "Image",
    addImage: "Add image",
    row: "Row",
    page: "Page",
    noData: "No Data",
    update: "Update",
    cancle: "Cancle",
    success: "Success",
    failed: "Failed",
    processing: "Processing",
    search: "Search",
    note: "Note",
   
    productType: "Product Type",
    productTypeList: "Product Type List",
    productionArea: "Production Area",
    productionAreaList: "Production Area List",
    list: "List",
    farmingObject: "Cattles List",
    processingObject: "Processing Objects",
    productionObject: "Production Objects",
    employee: "Employee's account",
    procedure: "Procedure",
    customer: "Partners",
    sell: "Sell",
    trackingImage: "Tracking Images",
   
    feedback: "Feedbacks",
    stampManage: "Shipment tracking",

     //Thông kê chung
    statistical: "General statistics",
    activatingStampsProduct: "NUMBER OF ACTIVATING STAMPS FOR PRODUCT",
    totalActiveStampBox: "Total number of box stamps",
    totalActiveStampProduct: "Total number of product stamps",
    totalHarvest: "Total harvest",
    totalcropYields: "Total crop yields",
    cropYieldsheader: "Crop Yields",
    activationStamp: "Activation Stamp",
    numberScanCode: "NUMBER OF SCAN CODES",
    activitiesRecorded: "Activities Recorded",
    viewList: "View list",
    numOfCattles: "Number of Cattles",
    total: "Total",
    alive: "Alive",
    slaughter: "Slaughter",
    all: "All",
    numOfShipment: "Number of Shipments",
    numOfPartner: "Number of Partners",
    feedlot: "Feedlot",
    depot: "Depot",
    abattoirs: "Abattoirs",
    shipmentAnalyst: "Shipment analyst",
    chooseShipment: "Choose a shipment",
    isSlaughter: "Slaughter Statistic",
    numOfSlaughter: "Number of Slaughter",

    //Nhà phân phối
    distributor: "Distributors",
    nameDistributor: "Name of distributor",
    shipmentNumber: "Shipment Number",

    //Product Type
    productId: "ID",
    nameProductType: "Name of product type",
    certificate: "Certificates",
    gtin: "GTIN Code",
    description: "Description",
    status: "Status",
    actionHeader: "Actions",
    productypeInfo: "Product type information",
    unit: "Unit",
    chooseUnit: "Choose unit",
    resgisGTIN: "Registered GTIN code",
    moreInfoProduct: "More product information",
    productDes: "Product Description",
    productSpec: "Product Specifications",
    packing: "Packing",
    addFields: "Add infomation fields",
    fieldRequire: "This field is required",
    deleteImage: "Delete image",
    time: "Time",

    //production area
    address: "Address",
    createTime: "Creation time",
    nameArea: "Name",
    idArea: "Id",
    type: "Type",
    totalobject: "Total Objects",
    areaInfo: "Production area infomation",
    empInCharge: "Employee in charge",
    moreInfoArea: "More area infomartion",
    regisGLN: "Registered GLN code",
    gln: "GLN code",
    chooseType: "Choose type of area",
    typeArea: "Production area type",
    enterEmpId: "Enter the employee code",

    //Object
    farmingObjectList: "Cattle List",
    processingObjectList: "Processing Object List",
    idObject: "ID",
    nameObject: "Name",
    registerDate: "Registration Date",
    careDiary: "Diary of care",
     harvestDiary: "Diary of harvest",
     empDoJob: "Implementer",
    cattleManagement: "Cattles Management",
    cattleImport: "Cattles Import",

 //Create Object processing
     infoProcessingObject: "Information processing object",
     infoFarmingObject: "Information Cattle",
     mainMaterial: "Main material",
     checkMaterial: "Raw materials already exist in the system",
     defaultProduct: "Default product type",
     typeObjectProcessing: "Type of object processed",
     typeObjectFarming: "Type of object farmed",
     chooseTypeObject: "Select the object type",
     chooseArea: "Select the production area",
     chooseProduct: "Select the default product type",
     chooseMainMaterial: "Select the main material",
     defaultProcessing: "Default processing",
     chooseProcess: "Select the default process",
     moreInfoObjetProcessing: "Add more processing object information",
     moreInfoObjetFarming: "Add more cattle object information",
     mainMaterialType: "Main material type",
     scanCode: "Scan code",
     dateCreated: "Date created",
    nameOfObject: "Name of object",
    inArea: "In production area",
    defaultProduct: "Default Product",
    defaultProcess: "Default Process",
    rfid: "RFID Code",
    printQR: "Print QR Code",
    download: "Download",
    cropList: "Import List",
    stepUnderway: "Steps are underway",
    batchList: "Batch list processing",
    timeLine: "Time line",

    //Emp List
    empList: "Employee List",
    nameEmp: "Name of emloyee",
    username: "Username",
    empID: "ID",
    role: "Role",
    chooseRole: "Select role",
    pass: "Password",
    inChargeArea: "Production area in charge",
    otherInfo: "Other information",
    inChargeObject: "Object in charge",
    idProductionArea: "ID of production area",
    addNewEmp: "Add new employee",
    infoEmp: "Employee Information",
    rePass: "Password recovery",
    active: "Active",
    deActive: "Deactive",

    //Procedure List
    processList: "Process List",
    namePro: "Process name",
    numSteps: "Number of steps",
    nameStep: "Name of step",
    idPro: "ID",
    typePro: "Type of process",
    chooseType: "Select type of process",
    activeStampPro: "Displays the step of activating the stamp when the user performs retrieval",
    packedPro: "Displays the packaging step when the user performs retrieval",
    detailProcess: "Detail of Process",
    isPublic:"Show this step on the website traceability",
    showPicture: "Illustrating images",
    typeData: "Type of data",
    chooseData: "Choose type of data",
    defaultValue: "Default value",
    processInfo: "Process information",
    fieldName: "Field name",
    isPublic_sub: "Show this field on the website traceability",
    addStep: "Add step",

    //Feedback List
    feedbackList: "Feedback from customer",
    feedback: "Feedback",
    nameProduct: "Name of Product",


    //Distributor
    distributorList: "Partner List",
    nameBranch: "Name of partner",
    phone: "Phone",
    idDist: "ID of branch",
    moreInfoDis: "More distributor information",
    disInfo: "Distributor Information",
    location: "Location",

    //History Stamp
    historyStamp: "HISTORY OF Shipment tracking",
    quantity: "Amount",
    stampType: "Type of Stamp",
    startId: "Start ID",
    endId: "End ID",
    quantityActive: "Number of activation stamps",
    activeStamp: "Stamp activated",
    dateActive: "Time of activation",
    stampActived: "Stamp activated",
    transfer: "Transfer",
    isTransfer: "Transfered",

    //Profile
    profile: "Profile",
    logOut: "Log Out",
    nameCompany: "Company's name",
    idCompany: "Business Code",
    introCompany: 'Enterprise introduction',
    changePass: "Change Password",
    certificate: "Certificates",
    setting: "Settings",
    brand: "Brand",
    contactInfo:"Contact Info",
    showTime: "Show the time",
    showDetailProcess: "Show process details",
    showName: "Show the alternate name",
    inputName: "Enter an alternate name",
    banner: "Banner",
    logo: "Logo",
    agree: "I understand that the above information will appear in the Product Origin section when consumers scan the QR code.",
  
  //Import List cow
    peopleDo: "Data entry person",
    timeImport: "Time import",
    quantityCow: "Quantity",
    noteFile: "Data file",
    sold: "Sold",
    notSold: "Not sold yet",
    idDis: "Id of Distributor",
    selectIdUnit: "Choose the pet you want to transfer",
    shipping: "Shipping",
    
    
    //distributor
    import: "Import",
    branch: "Branch",
    export: "Export",
    historyImport: "Import history",
    historyExport: "Export history",
    LNC: "LNC Number",
    CattleNo: "No. of Catlle",
    idType: "Type of ID",
    chooseidType: "Choose Type of ID",
    inputData: "Import data",
    receivedDate: "Received date",

    //Cattle Detail
    who: "Who",
    Where: "Where",
    when: "When",

    //Login Page
    usernameoremail: "Username or email",
    passWord: "Password",
    rememberPass: "Remember password",
    forgotPass: "Forgot password",
    logIn: "Log in",
  },
});

//strings.setLanguage('vi');

export default strings;
