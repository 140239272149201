import IMG_LOAD from 'assets/img/process.gif';
import API from './API.js';
import ERROR_CODE from './errorCode';
import Strings from './strings';

const ROLE = {

	MANUFACTURER: 'M',
	AUTHORITY: 'A',
	DISTRIBUTOR: 'F',
	DELIVERIER: 'D',
	CARRIER: 'C',
	RETAILER: 'R',
	TRIAL: 'T',
	PROVIDER: 'P',
	ADMIN: "Z"
};

const Language = {
	English: 'English',
	Vietnamese: 'Tiếng Việt',
};
  
const LanguageCode = {
	English: 'en',
	Vietnam: 'vi-VN',
};

const COLORS = {
	BG_GREEN: '#e2ffdafe',
	GRAY: '#8a8a8a',
	BLACK: '#000000',
	RED: '#ff3333',
	GREEN_SOFT: '#e2ffdb',
	RED_SOFT: '#ffd9da',
	GREEN: '#4ca750',
}

const FONTS = {
	PRIMARY: "Muli",
}

const FONT_SIZES = {
	SMALL: '14px',
	NORMAL: '16px',
	LARGE: '18px',
	BIG:'30px',
}
//contract Address


const ADDRESS = {
	GENIEIDENTITY_CONTRACT_ADDRESS : '0x48958e036abfdb931493b5d279b2f72ceb985f0d',
	GENIETOKEN_CONTRACT_ADDRESS : '0x0ec06b36b6447c72211d7244779262e138263153',
	VERIFY_CONTRACT_ADDRESS : '0x2e1D7e9778F8857c1E6638598ee28a2fF4240dEB',
	NF_PRODUCTFACTORY_CONTRACT_ADDRESS : '0xe33aaace02d47f2d811537937981a3471fde543c',
}

export {
	ADDRESS,
	ROLE,
	COLORS,
	FONTS,
	Strings,
	Language,
	LanguageCode,
	FONT_SIZES,
	IMG_LOAD,
	API,
	ERROR_CODE,
}

export default {
	ADDRESS,
	ROLE,
	COLORS,
	FONTS,
	FONT_SIZES,
	Strings,
	Language,
	LanguageCode,
	IMG_LOAD,
	API,
	ERROR_CODE,
}