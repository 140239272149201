import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Tabs from './components/CustomTabs/LookupTabs';
//components
import InfoProduct from './components/InfoProduct';
import Origin from './components/Origin';
import Process from './components/Process/Process';
import Certificate from './components/Certificate';
import LookupPage from './lookupPage/pages/Fruit';
//material
import logoVBC from "../../assets/img/logo_vbc.png";
import data from './data';
import Button from "components/CustomButtons/Button.jsx";
import IMG_LOAD from 'assets/img/Infinity-1s-200px.gif';
import moment from 'moment';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import {LINK_API} from 'constants/API';
import htmlToImage from 'html-to-image';
import { __esModule } from "react-cookies";
const QRCode = require('qrcode.react');
const axios = require('axios');


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      stamp: "iamge",
      data: '',
      file: require('assets/img/products/xoaicatchu.png'),
      updateProductInfo: false,
      processData: [],
      qrcode: "",
      email: "",
    };
   
    this.createStamp = this.createStamp.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.getEmail=this.getEmail.bind(this);
    this.renderTrigger = this.renderTrigger.bind(this);
  }

  async sendMail(){
    document.getElementById("sendviaMail").innerHTML = "Đang gửi Mail...";
    const canvas = document.getElementById("qrimage");
    const pngUrl = await canvas
      .toDataURL("image/png")
      .replace("image/png", "image/jpeg");
    await this.setState({
      stamp: pngUrl
    })
    const data ={
      "email": this.state.email,
      "stampId": this.state.qrcode,
      "stamp": this.state.stamp
    }
    axios.post(LINK_API.SEND_MAIL, data)
    .then( async () => {
      document.getElementById("sendviaMail").innerHTML = "Gửi qua Mail";
    })
    .catch(err => {
    this.setState({
      load: false,
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Lỗi"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Đã có lỗi xảy ra trong quá trình gửi mail, vui lòng thử lại sau.
        </SweetAlert>
      ),
    });
    console.log(err);
    });
  }
  async downloadQR(){
    document.getElementById("downloadQRCODE").innerHTML = "Đang tải...";
    const canvas = document.getElementById("qrimage");
    const pngUrl = await canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    // document.getElementById("qrimage").style ="display: none;"
    // document.getElementById("finalimg").src = await pngUrl;
    await this.setState({
      stamp: pngUrl
    })
    htmlToImage.toBlob(document.getElementById('downloadImagefinal'))
    .then(async function (blob) {
        await window.saveAs(blob, 'qrstamp.png');
        document.getElementById("downloadQRCODE").innerHTML = "Tải xuống";
    });
  };
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  renderTrigger() {
    return <Button color = "success">In Mã</Button>;
  }
  
  handleChange = name => async event => {
    await this.setState({ [name]: event.target.value});
  };

  async getEmail(x) {
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(x)){
      await this.setState({
        email: x,
        alert: null,
      });
      this.createStamp();
    } else {

    }
  }
  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }

  setRef = (ref) => {
    this.componentRef = ref;
  }

  getData = async (name, value, key) => {
    if(!key && key !== 0){
      if(name === "user_name" || name==="phone" || name==="gln" || name==="address" || name==="website" || name === "email1"){
        this.clickChild();
      }
      else{
        if(name !== "valuetab"){
          this.clickChild_start()
        }
      }
     await  this.setState({[name]: value});
    } else {
      this.state.processData[key]={
        ...this.state.processData[key],
        [name]:value,
      };
      this.setState({a:''});
    }
  }
  downloadimage(){
    htmlToImage.toBlob(document.getElementById('downloadImagefinal'))
    .then(function (blob) {
        window.saveAs(blob, 'qrstamp.png');
    });
    // domtoimage.toJpeg(document.getElementById('downloadImage'), { quality: 0.95 })
    // .then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = 'qrstamp.jpeg';
    //     link.href = dataUrl;
    //     link.click();
    // });
  }
  goContainer = () => {
    this.child.current.scrollToWithContainer();
  };
  goContainer_start = () => {
    this.child.current.scrollToWithContainer_start();
  };
  async createStamp(){
    this.setState({
      load: true,
      alert: (
        <SweetAlert
          info
          style={{ display: "block" }}
          title="Đang tạo tem"
          showConfirm = {false}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          <img alt="{name}" src={IMG_LOAD} style={{height:"100px"}} />
        </SweetAlert>
      ),
    });
  
    const dataStamp = {
      type: 'TRIAL',
      treeInfo: data.treeInfo,
      cooperativeInfo: {
        phone: this.state.phone || '',
        website: this.state.website || '',
        location_gps: '',
        email: this.state.email1 || '',
        user_name: this.state.user_name || '',
        gln: this.state.gln || '',
        tx_id: '',
        address: this.state.address || '',
      },
      productInfo: {
        name: this.state.name || '',
        image: this.state.image || '',
        description: this.state.description || '',
        trace_cycle: '',
        expired_date: this.state.expired_date || '',
        createAt: 1558885531569,
        updateAt: 1558885531569,
      },
      diary: this.state.processData.map(x=>{
        return {
          business_steps: "Truy xuất nguồn gốc",
          step: x.step,
          "from_id": "89385221500140000",
          "date": moment(),
          step_desc: x.step_desc,
          "detail": "",
          "step_icon": "register.svg",
          "actions_image": x.actions_image,
          "authority": "COO",
          "from_name": this.state.user_name || '',
        }
      }),
      certificates: [this.state.certificate0, this.state.certificate1],
      email: this.state.email,
    }
    axios.post(LINK_API.TRIAL, dataStamp)
    .then( async response => {
      this.setState({
        qrcode: response.data.data.stampId,
        load: false,
        alert: (
          <SweetAlert
            success
            style={{ display: "block" }}
            title="Thành công"
            showCancel = {false}
            showConfirm = {false}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          <div  style={{justifyContent:"center", alignItems:"center", display: "flex", flexFlow:"column"}}>
          <div id="downloadImagefinal" className = "stampFramedown">
          <QRCode id="qrimage" className="qrCode" value={`https://portal.agridential.vn/lookup/${response.data.data.stampId}`} />
          {/* <img id="finalimg" src={""} className="qrCodedown"/> */}
          </div>
          
          {/* <div id="downloadImagefinal" className = "stampFramedown">
          <img id="finalimg" src={""} className="qrCodedown"/>
          </div> */}
   
          
          </div>
         {/* <div id="downloadImage"
          style={{paddingLeft: "0.125cm", 
          width:"8cm", height:"4cm", 
          backgroundSize:"contain", 
          backgroundRepeat:"no-repeat", 
          backgroundImage: "url(assets/img/frame1.png)",
          marginLeft: "106px", marginTop:"0.3cm !important",
          imageRendering:"-webkit-optimize-contrast"}}>
        <QRCode id="downloadImage" className = "qrCode" value={`http://138.68.245.109:3000/lookup/${response.data.data.stampId}`} />;
         </div>
        </div> */}
        {/* <ReactToPrint
                    trigger={this.renderTrigger}
                    content = {this.renderContent}
                  /> */}
        <div className="contentButton">
        {/* <Button color = "success" style={{marginRight:"10px"}} onClick={() => this.downloadimage()}>Tải về</Button> */}
        <Button id="downloadQRCODE" color = "success" style={{marginRight:"10px"}} onClick={() => this.downloadQR()}>Tải về</Button>
                  {/* <div style = {{display:"none"}}>
                    <PrintTemplate data = {response.data.data.stampId} ref={this.setRef}/>
                  </div> */}
        <Button id="sendviaMail" color = "success" style={{marginRight:"10px"}}  onClick={() => this.sendMail()}> Gửi qua mail</Button>
        <Button color = "danger" onClick={() => this.hideAlert()}> Đóng </Button>
        </div>
          </SweetAlert>
        ),
      });
    })
    .catch(err => {
    this.setState({
      load: false,
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Thông tin tem"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Đã có lỗi xảy ra trong quá trình tạo tem, vui lòng thử lại sau.
        </SweetAlert>
      ),
    });
    console.log(err);
    });
  }
  Validate(){
    if(!this.state.image){
      document.getElementById("noti").innerHTML = "Thiếu ảnh sản phẩm";
    }
    else{
    if(!this.state.name){
      document.getElementById("noti").innerHTML = "Thiếu tên sản phẩm";
    }else{
      if(!this.state.expired_date){
        document.getElementById("noti").innerHTML = "Thiếu hạn sử dụng sản phẩm";
      }
      else{
        if(!this.state.description){
          document.getElementById("noti").innerHTML = "Thiếu miêu tả sản phẩm";
        }
        else{
          if(!this.state.user_name){
            document.getElementById("noti").innerHTML = "Thiếu tên doanh nghiệp";
          }
          else{
            if(!this.state.gln){
              document.getElementById("noti").innerHTML = "Thiếu mã doanh nghiệp";
            }
            else{
              if(!this.state.email1){
                document.getElementById("noti").innerHTML = "Thiếu email doanh nghiệp";
              }
              else{
                if(!this.state.website){
                  document.getElementById("noti").innerHTML = "Thiếu website doanh nghiệp";
                }
                else{
                  if(!this.state.address){
                    document.getElementById("noti").innerHTML = "Thiếu địa chỉ doanh nghiệp";
                  }else{
                    if(!this.state.phone){
                      document.getElementById("noti").innerHTML = "Thiếu số điện thoại doanh nghiệp";
                    }
                    else{
                      if(this.state.processData.length===0){
                        document.getElementById("noti").innerHTML = "Thiếu quy trình sản xuất";
                      }
                      else{
                        document.getElementById("noti").innerHTML = "";
                        this.setState({
                          load: true,
                          alert: (
                            <SweetAlert
                              input
                              required
                              style={{ display: "block" }}
                              title="Tạo tem mới"
                              placeHolder=""
                              validationMsg="Bạn cần nhập email!"
                              onConfirm={(x) => {this.getEmail(x)}}
                              confirmBtnCssClass={
                                this.props.classes.button + " " + this.props.classes.success
                              }
                              onCancel={() => this.hideAlert()}
                            >
                              Bạn vui nhập địa chỉ email để nhận tem.
                            </SweetAlert>
                          ),
                        })
                      }
                    }
                  }
                }
              }
            }
          } 
        }
      }
    }
  }
}

  render() {
    return (
      <div className="backgroundLogintrial" style={{ paddingLeft: 20, paddingRight: 20 }}>
        {this.state.alert}
        {1 ? (<GridContainer justify="center" className="trialview">
          
          <GridItem xs={12} sm={12} md={6} className="inputtabtrial">
            <Card className="cardtrial">
            <div style={{display: "flex", justifyContent:"center"}}>
            <p className="HeaderForm">TẠO TEM MẪU</p>
            </div>
            <hr className="LineBreakdes"/>
           <InfoProduct 
                  handleChange={(x)=>this.handleChange(x)} 
                  getData={(x,y)=>this.getData(x,y)}
                  productInfo = {{
                    name: this.state.name ? this.state.name : '',
                    image : this.state.image ? this.state.image : '',
                    description : this.state.description ? this.state.description : '',
                    trace_cycle : this.state.trace_cycle ? this.state.trace_cycle : data.productInfo.trace_cycle,
                    expired_date : this.state.expired_date ? this.state.expired_date : '',
                    createAt : this.state.createAt ? this.state.createAt : data.productInfo.createAt,
                    updateAt : this.state.updateAt ? this.state.updateAt : data.productInfo.updateAt,
                  }}
                />
               
                <Tabs
                value={this.state.valuetab} 
                getData={(x,y)=>this.getData(x,y)}
                title=""
                tabs={[{
                    tabName: 'Xuất xứ',
                    tabContent: (
                      <Origin 
                        handleChange={(x)=>this.handleChange(x)}
                        getData={(x,y)=>this.getData(x,y)}
                        cooperativeInfo={{
                          phone: this.state.phone || '',
                          website: this.state.website || '',
                          location_gps: '',
                          email1: this.state.email1 || '',
                          user_name: this.state.user_name || '',
                          gln: this.state.gln || '',
                          tx_id: '',
                          address: this.state.address || '',
                        }}
                      />
                    ),
                    },
                    {
                      tabName: 'Quy trình',
                      tabContent: (
                        <Process
                          getData={(x,y,z)=>this.getData(x,y,z)}
                          processData={this.state.processData}
                        />
                      ),
                    },
                    {
                      tabName: 'Chứng nhận',
                      tabContent: (
                        <Certificate 
                          getData={(x,y)=>this.getData(x,y)}
                          certificateFile = {{
                            certificate0: this.state.certificate0 || '',
                            certificate1: this.state.certificate1 || '',
                          }}
                        />
                      )
                    },
                ]}
                />
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <p
                  id="noti"
                  style={{
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    color: 'red',
                    fontFamily: 'Muli',
                    marginTop: 5,
                    marginBottom: 5,
                  }}>
                </p>
                </div>
                
              <Button color="success" 
                size="lg"
                style={{marginTop: "15px"}}
                goContainer
                onClick={this.Validate.bind(this) }>Tạo tem</Button>
                {/* onClick={() => this.clickChild()}>Go Container</Button> */}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className="mobiletrialview" style={{maxWidth:"23%"}}>
            <LookupPage 
              valuetab={this.state.valuetab}
              setClick={click => this.clickChild = click}
              setClick_start={click => this.clickChild_start = click}
              lookup_data={{
                cooperativeInfo: {
                  phone: this.state.phone || '',
                  website: this.state.website || '',
                  location_gps: '',
                  email: this.state.email1 || '',
                  user_name: this.state.user_name || 'VietNam Blockchain Coporation',
                  gln: this.state.gln || '',
                  tx_id: '',
                  address: this.state.address || 'Tầng 5, tòa nhà Flemington, 182 Lê Đại Hành, Phường 15, Quận 11, Tp.HCM',
                },
                productInfo: {
                  name: this.state.name || '',
                  image: this.state.image || require('assets/img/image_placeholder.jpg'),
                  description: this.state.description || '',
                  trace_cycle: 'y',
                  expired_date: this.state.expired_date || '',
                  createAt: 1558885531569,
                  updateAt: 1558885531569,
                },
                diary: this.state.processData.map(x=>{
                  return {
                    business_steps: "Truy xuất nguồn gốc",
                    step: x.step,
                    "from_id": "89385221500140000",
                    "date": moment(),
                    step_desc: x.step_desc,
                    "detail": "",
                    "step_icon": "register.svg",
                    "actions_image": x.actions_image,
                    "authority": "COO",
                    "from_name": this.state.user_name || '',
                  }
                }),
                certificates: [this.state.certificate0 || require('assets/img/certs/vbc.png'), this.state.certificate1 ||require('assets/img/image_placeholder.jpg')],
              }}
            />
          </GridItem>
        </GridContainer>):null}
        <div style={{paddingBottom: 10, paddingTop: 10, textAlign: 'center', height: '7vh', }}>
            <img src={logoVBC}/>
        </div>
      </div>
    );
  }
}

export default withStyles(sweetAlertStyle)(LoginPage);
