import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
// core components
import Badge from "components/Badge/Badge.jsx";
import strings from "constants/strings"
import GridItem from "components/Grid/GridItem.jsx";
import timelineStyle from "./TimelineStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
function Timeline({ ...props }) {
  const { classes, stories, simple, tag, tagvideo, onConfirm } = props;
  const event = [];
  //console.log("stories",stories)
  function onClickTx(tx) {
    onConfirm(tx);
  }

  const handleBtCT = (x) => {
    if (event[x]) {
      document.getElementById(x).innerHTML = strings.view_detail;
      document.getElementById(x + "detail").style = "display: none; width:100%";
      event[x] = 0;
    } else {
      document.getElementById(x).innerHTML = strings.collapse;
      document.getElementById(x + "detail").style = "display: block;";
      event[x] = 1;
    }
  };
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple
    });
  return (
      <ul className={timelineClass}>
        
          <GridItem xs={12} sm={12}>
            {(tag) ? (<Typography className="xuatxutag">{tag}</Typography>) : null}
            <hr className="LineBreakDes"></hr>
          </GridItem>
      
        {stories.map((prop, key) => {
          const panelClasses =
            classes.timelinePanel +
            " " +
            cx({
              [classes.timelinePanelInverted]: prop.inverted,
              [classes.timelineSimplePanel]: simple
            });

          const timelineBadgeClasses =
            classes.timelineBadge +
            " " +
            classes[prop.badgeColor] +
            " " +
            cx({
              [classes.timelineSimpleBadge]: simple
            });
          return (
            <li className={classes.item} key={key}>
              {prop.badgeIcon ? (
                <div className={timelineBadgeClasses}>
                  {/* <prop.badgeIcon className={classes.badgeIcon} /> */}
                  <img className={classes.badgeIcon} src={require('assets/icons/agri.png')} />
                </div>
              ) : null}
              <GridItem xs={12} sm={12} md={10} className="flexend">
                <GridItem xs={9} sm={9} md={11}>
                  {prop.action ? (
                    <div className="textAction">{prop.action}</div>
                  ) : null}
                  {prop.who ? (
                    <div className="textWho">{prop.who}</div>
                  ) : null}
                  <div className={classes.row2}>
                    <div className={classes.textTime} >{prop.time}</div>
                  </div>
                  {prop.title ? (
                    <div className={classes.timelineHeading}>
                      <Badge color={prop.titleColor}>{prop.title}</Badge>
                    </div>
                  ) : null}
                  <div className="timelineBody">{prop.body}</div>
                  <hr className="LineBreakDes"></hr>
                  <div id={prop.key + "detail"} className={classes.timelineBody} style={{ display: 'none' }}>{prop.detail}</div>
                  <div className="tagmanufact">{strings.blockchain_evidence} <br></br> <a className="tagmanufactdestimeline">Sẽ được thêm sau khi tạo tem</a></div>
                  {prop.footerTitle ? (
                    <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
                  ) : null}
                  <Button id={prop.key} color="success" size="sm" className="btnfb" onClick={() => handleBtCT(prop.key)}>{strings.view_detail}</Button>
                </GridItem>
              </GridItem>
            </li>
          );
        })}
      </ul>
  );
}

Timeline.propTypes = {
  classes: PropTypes.object.isRequired,
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool
};

export default withStyles(timelineStyle)(Timeline);
