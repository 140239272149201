import React from "react";
import axios from "axios";
import cookie from "react-cookies";
import moment from "moment";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { IMG_LOAD } from "constants/index.js";
import { LINK_API } from "constants/API";
import { connect } from 'react-redux';
import Add from "@material-ui/icons/Add";
import Table from "components/Table/Table.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import Search from "@material-ui/icons/Search";
import "../css/CustomeReactTable.css";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { cps } from "@redux-saga/core/effects";
import Shipment from "@material-ui/icons/LocalShipping";
import strings from "constants/strings.jsx"
import SelectModern from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import ImageUpload from "../ObjectProductionPage/components/ImageUpload"
const lan = cookie.load("language");
if (lan) {
  strings.setLanguage(lan);
}
else {
 strings.setLanguage("vi");
}

const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx').Transaction;
const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider(`http://206.189.82.191:8000`));

//ABI
const genieIJSON = require("../../../../build/contracts/GenieIdentity.json");
const genieIABI = genieIJSON['abi'];

const genieTJSON = require("../../../../build/contracts/GenieToken.json");
const genieTABI = genieTJSON['abi'];
const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...extendedTablesStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};
const delay = (ms) => new Promise(res => setTimeout(res, ms))
class TableFarmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      checked: false,
      value: {
        gln_extend: "",
        passphrase: "",
        data: ""
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      openCreateBox: false,
      options: [],
      employee: []
    };
    this.onImageChange = this.onImageChange.bind(this);
    this.hideAlert_update = this.hideAlert_update.bind(this)
    this.handleImportBox = this.handleImportBox.bind(this);
    this.hideAlert = this.hideAlert.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.handleShipBox = this.handleShipBox.bind(this);
    this.handleShipment = this.handleShipment.bind(this);
    this.dataBlockchain = this.dataBlockchain.bind(this);
    this.Execute = this.Execute.bind(this);
    this.handleChange_box = this.handleChange_box.bind(this);
    this.handleChange_Select = this.handleChange_Select.bind(this);
    this.handleImportData = this.handleImportData.bind(this);
    this.submitData_crop = this.submitData_crop.bind(this);
    this.onImageImportChange = this.onImageImportChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }
  handleCloseDialog(){
    this.setState({
      openCreateBox: false
    })
  }
  handleImportData() {
    if(this.state.employee.length < 0){
      document.getElementById("noti_import").innerHTML =" Vui lòng chọn mã cái vật nuôi muốn đóng thùng"
      return;
    }
    if(!this.state.idDistributor){
      document.getElementById("noti_import").innerHTML =" Vui lòng nhập mã nhà phân phối muốn chuyển đến"
      return;
    }
    this.setState({
      openCreateBox: false,
      alert: (
        <SweetAlert
        warning
        style={{ display: "block"}}
        title={strings.processing}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          sweetAlertStyle.button + " " + sweetAlertStyle.success
        }
        showConfirm={false}
        showCancel={false}
      >
        <div style={{textAlign:"center"}}>
          <img
            alt="{name}"
            src={IMG_LOAD}
            style={{height:"100px"}}
          />
        </div>
      </SweetAlert>
      )
    })
    const data = { 
      "listId": this.state.employee,
    }
    axios.post(LINK_API.COMBINE_BOX, data)
    .then(async response =>{
      if(response.data.errorCode === 1){
        await this.setState({
          rawtx1: response.data.data.rawNewKind,
          rawtx2: response.data.data.rawFungibleAsset,
          nonce: response.data.data.nonce,
          creator_nonce: response.data.data.creator_nonce,
          uuid_response: response.data.data.uuid,
        });
        this.submitData_crop()
      }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title={strings.failed}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    })
    .catch(err=>{
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block" }}
            title={strings.failed}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
    })
  }
  async submitData_crop() {
    var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privateKey = bytes.toString(CryptoJS.enc.Utf8);
    const privatekey_slice = privateKey.substring(2, privateKey.length);
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
    const tx = new EthereumTx(this.state.rawtx1, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    const rawtosend = {
      listId: this.state.employee,
      "raw": []
    }
    rawtosend.raw.push(rawTx)
    const tx_creator = new EthereumTx(this.state.rawtx2, { chain: 4 });
    tx_creator.sign(Buffer_privatekey);
    const rawTx_creator = '0x' + tx_creator.serialize().toString('hex');
    rawtosend.raw.push(rawTx_creator)


    axios.post(LINK_API.COMBINE_BOX_RAW, rawtosend)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            boxId: response.data.data.boxId
          })
          await delay(2000);
          this.handleShipment();
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title={strings.failed}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
              </SweetAlert>
            )
          });
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      })
  }
  handleChange_Select = async (selectedOption, event) => {
    let temp = this.state.employee;
    if(selectedOption !== null){
      if(selectedOption.length > 0){
        temp.push(selectedOption[selectedOption.length - 1].value)
      }
    }
    await this.setState({
      employee: temp
    })
  }
  onImageChange(file){
    this.setState({
      employee: file
    })
  }
  onImageImportChange(file){
    console.log(file)
    this.setState({
      employee_import: file
    })
  }
  handleImport () {
    if(!this.state.idBox){
      document.getElementById("importBox").innerHTML ="Vui lòng nhập mã thùng muốn nhập!"
    }
    if(!this.state.detailBox){
      document.getElementById("importBox").innerHTML ="Vui lòng nhập miêu tả cho thùng hàng!"
    }
    this.setState({
      alert: (
        <SweetAlert
        warning
        style={{ display: "block"}}
        title={strings.processing}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          sweetAlertStyle.button + " " + sweetAlertStyle.success
        }
        showConfirm={false}
        showCancel={false}
      >
        <div style={{textAlign:"center"}}>
          <img
            alt="{name}"
            src={IMG_LOAD}
            style={{height:"100px"}}
          />
        </div>
      </SweetAlert>
      )
    })
    const data = {
      "toId": this.state.idBox,
      "detail": this.state.detailBox,
      "processCode" : 'D2',
      "unpack": true,
      "images": this.state.employee
    }
    axios.post(LINK_API.GET_INFO_BOX, data)
    .then(res=> {
      if(res.data.errorCode === 1){
        var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        const privatekey_slice = privateKey.substring(2,privateKey.length);
        const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
        const tx = new EthereumTx(res.data.data.contractRaw, { chain: 4 });
        tx.sign(Buffer_privatekey);
        const rawTx = '0x' + tx.serialize().toString('hex');
        const data = {
          "toId": this.state.idBox,
          "detail": this.state.detailBox,
          "raw": rawTx,
          "processCode" : 'D2',
          "unpack": true,
          "images": this.state.employee
        }
        axios.post(LINK_API.RECEIVE_BOX, data)
        .then(res => {
            if(res.data.errorCode === 1){
              this.props.getlist();
              this.setState({
                alert: (
                  <SweetAlert
                success
                style={{ display: 'block' }}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                showCancel={false}
              />
                )
              })
            }else{
              this.setState({
                alert: (
                  <SweetAlert
                    error
                    style={{ display: "block"}}
                    title="Không thành công"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                      this.props.classes.button + " " + this.props.classes.success
                    }
                  >
                  </SweetAlert>
                )
              });
            }
        })
        .catch(err => {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title={strings.failed}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
              </SweetAlert>
            )
          });
        })
      }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title={strings.failed}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    })
    .catch(err=> {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block" }}
            title={strings.failed}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
    })
  }
  handleChecked = name => event => {
    //document.getElementById("noti").innerHTML = "";
    this.setState({ [name]: event.target.checked });
  };
  handleImportBox(){
    this.setState({
      alert: (
        <SweetAlert
        warning
        style={{ display: "block" }}
        title="Nhập mã thùng"
        onConfirm={() => this.handleImport()}
        onCancel={() => this.hideAlert()}
        showConfirm={true}
        showCancel={true}
        confirmBtnText="Nhập hàng"
        cancelBtnText="Hủy"
        confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
        cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
      >
        <div>
        <GridItem xs={12} style={{textAlign: "left"}}>
        <p className="TitleBold">BoxId:</p>
          <div style={{marginTop: "-40px"}}>
          <CustomInput
										labelText=""
										success={this.state.usernameState === 'success'}
										error={this.state.usernameState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: this.props.classes.customFormControlClasses,
											onChange: this.handleChange('idBox')
										}}
										inputProps={{
											value: this.state.idBox,
											autoComplete: 'off',
											placeholder: "Mã thùng"
										}}
									/>
                    <p className="TitleBold">{strings.description}:</p>
          <div style={{marginTop: "-40px"}}>
          <CustomInput
										labelText=""
										success={this.state.usernameState === 'success'}
										error={this.state.usernameState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: this.props.classes.customFormControlClasses,
											onChange: this.handleChange('detailBox')
										}}
										inputProps={{
											value: this.state.detailBox,
											autoComplete: 'off',
											placeholder: "Thông tin thùng hàng"
										}}
									/>
          </div> 
          </div>
        </GridItem>
        <GridItem xs={12} style={{textAlign:"left"}}>
            <p className="TitleBold">{strings.showPicture}:</p>
            <ImageUpload onImageSubmit={this.onImageChange}/>
          </GridItem>
                  	<p
							style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
							id="importBox"
							style={{
								fontSize: '0.875rem',
								fontWeight: '400',
								color: 'red',
								fontFamily: 'Muli',
								marginTop: 5,
								marginBottom: 5
							}}
						/>
        </div>
      
      </SweetAlert>
      )
    })
  }
  handleChange = name => evt => {
    this.setState({
      [name]: evt.target.value
    })
    document.getElementById("importBox").innerHTML=""
  }
  hideAlert(){
    this.setState({
      alert: null,
      idBox: "",
      detailBox: ""
    })
  }
  renderTrigger() {
    return <Button color="success">In Mã</Button>;
  }

  renderContent = () => {
    // eslint-disable-line arrow-body-style
    return this.componentRef;
  };

  setRef = ref => {
    this.componentRef = ref;
  };

  genTable = farmList => {
    
    this.setState({
      data: farmList.map((prop, key) => {
        const productList = [];
        for(let i=0;i<prop.product.length;i++){
          productList.push([
            i+1,
            prop.product[i].uuid,
            prop.product[i].id,
            prop.product[i].status === 1 ? "Đã bán": "Chưa bán"
          ])
        }
        return {
          id: key + 1,
          uuid: prop.id,
          createAt: moment(prop.date).format("DD/MM/YYYY hh:mm:ss"),
          status: prop.status === 0 ? "Đóng thùng" : prop.status === 1 ? "Đã bán":prop.status === 2? "Đã mở thùng":prop.status === 3?"Đã chuyển hàng":prop.status === 4?"Đã nhận hàng":"",
          idproduct_Start: prop.product.length > 0 ? prop.product[0].id : "",
          idproduct_End: prop.product.length > 0 ? prop.product[prop.product.length - 1].id : "",
          countProduct: prop.product.length,
          product: productList,
          actions: (
            <div>
                <Tooltip
            id="tooltip-top"
            title="Tra cứu Blockchain"
            placement="top"
            classes={{ tooltip: this.props.classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => {
                window.open("https://portal.agridential.vn/lookup/" + prop.id);
              }}
              color="warning"
              className="like"
            >
              <Search />
            </Button>
          </Tooltip>
               <Tooltip
                id="tooltip-top"
                title={strings.transfer}
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => this.handleShipBox(prop.id)}
                  color="success"
                  className="like"
                >
                  <Shipment />
                </Button>
              </Tooltip>
            </div>
          
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  };

  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, {
        headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
      });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        return this.props.history.push("/");
      } else {
        axios.defaults.headers.common["Authorization"] = this.props.userInfo.data.token;
      }
    } catch (error) {
      console.log(error);
      return this.props.history.push("/");
    }
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.GET_STAMP_LIST_RETAILER);
      if (response.data.errorCode == 1) {
        return response.data.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  handleChange_box = (name) => async (event) => {
    //document.getElementById("noti_import").innerHTML = "";
    await this.setState({
      [name]: event.target.value
    });
  }
  dataBlockchain = async (
    identitierAddress,
    productName,
    action,
    fromId,
    toId,
    detail,
    date,
    genieToken,
    contractAddress, // dia chi loai san pham
    tokenId,
  ) => {
    try {
      const _organization = identitierAddress; // địa chỉ identifier address của tổ chức của nhân viên đó
      const _productType = productName || ''; //Tên sản phẩm
      const _cetegories = 1; // loại sản phẩm tuwj theem
      // const action = action;
      const fromID = fromId;
      const toID = toId;
      const deion = detail; // không bắt buộc detail
      // const date = date;
      const hashedDataToSign = web3.utils.soliditySha3(
        _organization,
        _productType,
        _cetegories,
        action,
        fromID,
        toID,
        deion,
        date,
      );
      //await web3.eth.personal.unlockAccount("0xEd376914fFb2D7672c78080A3800cD74d1a7EDd4", "nam114111", 600)
      var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
      const privatekey = bytes.toString(CryptoJS.enc.Utf8);
      var signature = await web3.eth.accounts.sign(
        hashedDataToSign,
        privatekey, // privateKey of Employee
      );
      //console.log('signature', signature.signature);
  
      // const recover = await web3.eth.accounts.recover(signature)
      // //console.log('recover', recover)
      // var claimIssuer = '0x103cd9d78b561a7ad62051cfe2c2a8ccdf06600f'; // Danh tinh Issuer
      // //console.log('claimIssuer', claimIssuer)
      //const GenieTokenABI = GenieToken.abi;
      const GenieTokenDeployed = new web3.eth.Contract(
        genieTABI,
        genieToken,
      );
  
      const diaryABI = await GenieTokenDeployed.methods
        .Non_FungibleAsset_ActionToDiary(
          tokenId,
          _organization,
          _productType,
          _cetegories,
          signature.signature,
          hashedDataToSign,
          action,
          fromID,
          toID,
          deion,
          date,
          contractAddress,
        )
        .encodeABI();
      return diaryABI;
    } catch (err) {
      console.log('err_dataBlockchain', err);
    }
  };
  Execute = async (
    tokenId,
    action,
    fromId,
    toId,
    detail,
    date,
    nonce,
    genieToken,
    contractAddress,
    accountAddress,
    productName,
    identitierAddress,
  ) => {
  const genieIdentityDeployed = new web3.eth.Contract(
    genieIABI,
    identitierAddress,
  );
  console.log(genieIdentityDeployed)
    // const genieIdentityDeployed = {};
    const _data = await this.dataBlockchain(
      identitierAddress,
      productName,
      action,
      fromId,
      toId,
      detail,
      date,
      genieToken,
      contractAddress,
      tokenId,
    );
    console.log('_data', _data);
    const data = genieIdentityDeployed.methods.Execute(genieToken, 0, _data,contractAddress).encodeABI();
    console.log('dataAuthen', data);
    console.log('nonce', nonce);
    const txParam = {
      from: accountAddress,
      nonce,
      gasPrice: 0,
      gasLimit: 8000000,
      to: identitierAddress,
      data,
    };
    try {
      const tx = await new EthereumTx(txParam, {chain: 4});
      await tx.sign(this.state.Buffer_privatekey);
      const rawTx = await `0x${tx.serialize().toString('hex')}`;
      return rawTx;
    } catch (err) {
      console.log('err', err);
    }
  };
  async handleShipment(uuid){
    this.setState({
      openCreateBox: false,
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={strings.processing}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    })
    const data = {
      "processCode": "D2",
      "toId": this.state.boxId,
      //"receiver": "F000448",
      "receiver":  this.state.idDistributor,
      "step": 0,
      "moreInfo": {},
      "detail": "100kg",
      "images": this.state.employee_import
    }
   axios.post(LINK_API.GET_INFO_SHIPMENT, data)
   .then(async response => {
    if(response.data.errorCode === 1){
      const {
        tokenId,
        action,
        detail,
        date,
        nonce,
        genieToken,
        contractAddress,
        fromId,
        toId,
        accountAddress,
        productName,
        identitierAddress,
        contractRaw,
      } = response.data.data;

      const sign = 'dataSign';
      const raw = await this.Execute(
        tokenId,
        action,
        fromId,
        toId,
        detail,
        date,
        nonce,
        genieToken,
        contractAddress,
        accountAddress,
        productName,
        identitierAddress,
      );
      let arrayRaw = [];
              let contractRawTx = '';
              if (contractRaw) {
                const tx = await new EthereumTx(contractRaw, {chain: 4});
                // const tx = {};
               
                await tx.sign(this.state.Buffer_privatekey);
                contractRawTx = await `0x${tx.serialize().toString('hex')}`;
              }
              console.warn('raw', raw);
              if (contractRawTx) arrayRaw.push(contractRawTx);
              arrayRaw.push(raw);
              const dataX = await {
                ...response.data.data,
                raw: arrayRaw,
                sign: sign,
                images: this.state.employee_import,
                "processCode": "D2",
              };
              axios.post(LINK_API.SHIPMENT_BOX, dataX)
              .then(result=>{
                if(result.data.errorCode === 1){
                  this.setState({
                    alert: (
                      <SweetAlert
                        success
                        style={{ display: 'block' }}
                        title={strings.success}
                        onConfirm={() => this.hideAlert_update()}
                        showCancel={false}
                      />
                    )
                  })
                }
              })
    }
   })
  }
  hideAlert_update(){
    this.setState({alert: null})
  }
  handleShipBox(uuid){
    this.setState({
      openCreateBox: true
    })
  }
  async componentDidMount() {
    try {
      await this.checkPermission();
      await this.props.getlist();
      await this.props.renderListUnit();
      await this.setState({
        options: this.props.renderUnit.data ? this.props.renderUnit.data: []
      })
      var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
      const privatekey = bytes.toString(CryptoJS.enc.Utf8);
      const privatekey_slice = privatekey.substring(2, privatekey.length)
      const Buffer_privatekey = await Buffer.from(privatekey_slice.toString(), 'hex');
      await this.setState({
        Buffer_privatekey: Buffer_privatekey
      })
    } catch (error) {
      console.log(error);
    }
  }
  async componentWillReceiveProps(preProps){
    if(preProps.importList !== this.props.importList){
      if(preProps.importList.data){
        await this.genTable(Array.from([]))
        await this.genTable(Array.from(preProps.importList.data))
      }
    }
    if(preProps.renderUnit !== this.props.renderUnit){
      if(preProps.renderUnit.data){
        this.setState({
          options: preProps.renderUnit.data
        })
      }
    }
  }
  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input
        type="text"
        placeholder={placeholder}
        style={{
          width: "100%"
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ""}
      />
    );
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                  <h4 className="cardtableLabel">{strings.historyImport}</h4>
                  </GridItem>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                  <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                  <Button color="success" size="sm" onClick={this.handleImportBox}><Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button>
                  </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  data={this.state.data}
                  filterable
                  // defaultPageSize={5}
                  previousText="Trang Trước"
                  nextText="Trang Sau"
                  rowsText="hàng"
                  ofText="/"
                  pageText="Trang"
                  noDataText="Nhấn nút TẠO MỚI để đăng ký loại sản phẩm!"
                  columns={[
                    {
                      Header: "#",
                      accessor: "id",
                      filterable: false,
                      maxWidth: 70,
                      className: "center",
                      Header: () => (
                        <div
                          style={{
                            textAlign: "center"
                          }}
                        >
                          #
                        </div>
                      )
                    },
                    {
                      Header: "Mã thùng",
                      accessor: "uuid",
                      filterable: false
                    },
                    {
                      Header: "Số sản phẩm",
                      accessor: "countProduct",
                      filterable: false
                    },
                    {
                      Header: "Mã SP bắt đầu",
                      accessor: "idproduct_Start",
                      filterable: false
                    },
                    {
                      Header: "Mã SP kết thúc",
                      accessor: "idproduct_End",
                      filterable: false
                    },
                    // {
                    //   Header: "Thời gian nhập",
                    //   accessor: "date",
                    //   sortable: false,
                    //   filterable: false
                    // },
                    {
                      Header: "Trạng thái",
                      accessor: "status",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Hành động",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                  SubComponent={row => {
                    return (
                      <div style={{ padding: "20px" }}>
                        <em>
                        Danh sách sản phẩm
                        </em>
                        <br />
                        <br />
                        <Table
                tableHead={[
                  "#",
                  "Mã định danh",
                  "Mã truy xuất",
                  "Trạng thái",
                 
                ]}
                tableData={row.original.product}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
                <br />
                        <br />
                      </div>
                    );
                }}
              />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Dialog
          open={this.state.openCreateBox}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <Card style={{ width: 500 }}>
            <CardHeader color="success" icon>
              <h4 className="cardtableLabel">{strings.shipping}</h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} style={{marginTop: "-30px"}}>
              <CustomInput
										labelText= {strings.idDis}
										success={this.state.usernameState === 'success'}
										error={this.state.usernameState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: this.props.classes.customFormControlClasses,
											onChange: this.handleChange_box('idDistributor')
										}}
										inputProps={{
											value: this.state.idBox,
											autoComplete: 'off',
											placeholder: strings.idDis
										}}
									/>
                <SelectModern
                  options={this.state.options}
                  isMulti
                  // value={this.state.clientId_label}
                  onChange={this.handleChange_Select}
                  // className={classes.SelectDrop}
                  placeholder= {strings.selectIdUnit}
                /> 
              </GridItem>
              <GridItem xs={12} style={{textAlign:"left", marginTop:"15px"}}>
            <p className="TitleBoldChoosefile">{strings.showPicture}:</p>
            <ImageUpload onImageSubmit={this.onImageImportChange}/>
          </GridItem>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <p
                  id="noti_import"
                  style={{
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    color: 'red',
                    fontFamily: '"Muli',
                    marginTop: 5,
                    marginBottom: 5,
                  }}>
                </p>
              </div>
              <GridItem xs={12} >
                <GridContainer style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                  <Button color="danger" size="sm" onClick={() => this.handleCloseDialog()}>{strings.cancle}</Button>
                  </div>
                  <div>
                  <Button color="success" size="sm" onClick={() => this.handleImportData()}>{strings.shipping}</Button>
                  </div>
                </GridContainer>
              </GridItem>
            </CardBody>
          </Card>
        </Dialog>
        {this.state.alert}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
    importList: state.importList,
    renderUnit: state.renderUnit
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getlist: () => dispatch({ type: "GET_BOX_UNIT" }),
    renderListUnit: () => dispatch({type: "RENDER_UNIT_STAMP"})
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps,mapDispatchToProps )(TableFarmList)));
