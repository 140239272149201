import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { withStyles, createMuiTheme } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import green from '@material-ui/core/colors/green';

import { API, IMG_LOAD } from 'constants/index.js';
import axios from 'axios';

import loginFormStyle from "./loginFormStyle.jsx";


// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Location from "@material-ui/icons/LocationOn";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: { useNextVariants: true },
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      checkEmail: false,
      mail: '',
      load: false,
      checked: [],
      
      user_name: "",
      usernameState: "",
      usernameHelp: "",

      email:"",
      emailState: "",
      emailHelp: "",

      addressState: "",
      addressHelp: "",
      address: "",

      phoneState: "",
      phoneHelp: "",
      phone: "",

      pwhashState: "",
      pwhashHelp: "",
      pw_hash: "",

      passre: "",
      passreState: "",
      passreHelp: "",

    };
    this.handleToggle = this.handleToggle.bind(this)
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      100
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  handleChange = name => async event => {
    await this.setState({
      [name]: event.target.value,
    });
    // switch (name) {
    //   case 'email':
    //     let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    //     if (!filter.test(this.state.mail)) {
    //       this.setState({ checkEmail: false });
    //     } else {
    //       this.setState({ checkEmail: true });
    //     }
    //     break;
    // }
    if(name === "user_name"){
      if(this.state.user_name){
        this.setState({
          usernameHelp: "",
          usernameState: "success"
        })
      }else{
        this.setState({
          usernameHelp: "",
          usernameState: "error"
        })
      }
    }
    if(name === "email"){
      if(this.state.email){
        this.setState({
          emailHelp: "",
          emailState: "success"
        })
      }else{
        this.setState({
          emailHelp: "",
          emailState: "error"
        })
      }
      if(name === "pw_hash"){
        if(this.state.pw_hash){
          this.setState({
            pwhashHelp: "",
            pwhashState: "success"
          })
        }else{
          this.setState({
            pwhashHelp: "",
            pwhashState: "error"
          })
        } 
      }
      if(name === "address"){
        if(this.state.address){
          this.setState({
            addressHelp: "",
            addressState: "success"
          })
        }else{
          this.setState({
            addressHelp: "",
            addressState: "error"
          })
        }
      }
      if(name === "phone"){
        if(this.state.phone){
          this.setState({
            phoneHelp: "",
            phoneState: "success"
          })
        }else{
          this.setState({
            phoneHelp: "",
            phoneState: "error"
          })
        }
      }
      if(name === "passre"){
        if(this.state.passre){
          this.setState({
            passreHelp: "",
            passreState: "success"
          })
        }else{
          this.setState({
            passreHelp: "",
            passreState: "error"
          })
        }
      }
    }
  };

  handleSubscribe = () => {
    //check Tên đăng nhập và mật khẩu có hợp lệ trước khi gửi
    if(!this.state.user_name){
      this.setState({
        usernameState: "error",
        usernameHelp: "Vui lòng nhập tên doanh nghiệp"
      });
      return;
    }
    if(!this.state.email){
      this.setState({
        emailState: "error",
        emailHelp: "Vui lòng nhập email"
      });
      return;
    }else{
      var checkmail = this.verifyEmail(this.state.email);
      if(checkmail===false){
        this.setState({
          emailState: "error",
          emailHelp: "Email không hợp lệ"
        });
      }
    }
    if(this.state.pw_hash !== this.state.passre){
      this.setState({
        passreState: "error",
        passreHelp: "Không khớp với mật khẩu đã nhập"
      });
      return;
    }else{
      this.setState({
        passreState: "success",
        passreHelp: ""
      });
    }
    if(!this.state.pw_hash){
      this.setState({
        pwhashState: "error",
        pwhashHelp: "Vui lòng nhập mật khẩu"
      });
      return;
    }
    if(!this.state.passre){
      this.setState({
        passreState: "error",
        passreHelp: "Vui lòng nhập lại mật khẩu"
      });
      return;
    }
    if(!this.state.address){
      this.setState({
        addressState: "error",
        addressHelp: "Vui lòng nhập lại mật khẩu"
      });
      return;
    }
    if(!this.state.phone){
      this.setState({
        phoneState: "error",
        phoneHelp: "Vui lòng nhập lại mật khẩu"
      });
      return;
    }

    const data = {
      email: this.state.mail
    }
    this.setState({ load: true });

    axios.post(API.LINK_API.REGISTER, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({ load: false });
          document.getElementById("dk").innerHTML = `Thành công!`;
          document.getElementById("dk").style = "color:green; padding-top:30px;font-size:17px;";
        } else {
          await this.setState({ load: false });
          switch (response.data.errorCode) {
            default:
              document.getElementById("noti").innerHTML = `Email đã đăng ký! ${response.data.errorCode}`;
          }
        }
      }).catch(async (error) => {
        await this.setState({ load: false });
        document.getElementById("dk").innerHTML = `Không thành công!`;
        document.getElementById("dk").style = "color:red;padding-top:30px;font-size:17px;";
        //console.log(error.response);
      });
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    //console.log(this.state.checked)
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        {(!this.state.load) ? (
          <div id="dk">
            <form className={classes.form}>
              <CustomInput
               success={this.state.usernameState === "success"}
               error={this.state.usernameState === "error"}
              //  helpText={this.state.usernameHelp}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('user_name'),
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Face className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: "Tên doanh nghiệp"
                }}
              />
              <CustomInput
              success={this.state.emailState === "success"}
              error={this.state.emailState === "error"}
              // helpText={this.state.emailHelp}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('email'),
                }}
                inputProps={{
                  autoComplete: "off",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: "Email"
                }}
              />
              <CustomInput
               success={this.state.pwhashState === "success"}
               error={this.state.pwhashState === "error"}
              //  helpText={this.state.pwhashHelp}
                variant="outlined"
                labelProps={{
                  variant: "outlined",
                }}
                formControlProps={{
                  variant: 'outlined',
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('pw_hash'),
                }}
                inputProps={{
                  type: "password",
                  autoComplete: "off",
                  variant: "outlined",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                              </Icon>
                    </InputAdornment>
                  ),
                  placeholder: "Mật khẩu"
                }}
              />
              <CustomInput
               success={this.state.passreState === "success"}
               error={this.state.passreState === "error"}
              //  helpText={this.state.passreHelp}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('passre'),
                }}
                inputProps={{
                  type: "password",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                              </Icon>
                    </InputAdornment>
                  ),
                  placeholder: "Nhập lại mật khẩu"
                }}
              />
              <CustomInput
              success={this.state.addressState === "success"}
              error={this.state.addressState === "error"}
              // helpText={this.state.addressHelp}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('address'),
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Icon className={classes.inputAdornmentIcon}>
                        location_on
                              </Icon>
                    </InputAdornment>
                  ),
                  placeholder: "Địa chỉ"
                }}
              />
              <CustomInput
              success={this.state.phoneState === "success"}
              error={this.state.phoneState === "error"}
              // helpText={this.state.phoneHelp}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  onChange: this.handleChange('phone'),
                }}
                inputProps={{
                  type: "number",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Icon className={classes.inputAdornmentIcon}>
                        phone
                              </Icon>
                    </InputAdornment>
                  ),
                  placeholder: "Số điện thoại"
                }}
              />
              <FormControlLabel
                classes={{
                  root: classes.checkboxLabelControl,
                  label: classes.checkboxLabel
                }}
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => this.handleToggle(1)}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                label={
                  <div style={{textAlign: "justify", marginTop: 10}}>
                    <p style={{ fontSize: "13px", fontWeight: 700 }}>
                      Bạn đồng ý với {" "}
                      <a style={{ color: "#679847" }} href="#pablo">Điều khoản và Điều kiện Sử dụng của Agridential</a>.
                          </p>
                  </div>
                }
              />
            </form>
            <Button fullWidth variant="contained" color="success" className={classes.button} onClick={this.handleSubscribe} >
              Đăng ký
      </Button>
          </div>) : (<div>
            <img src={IMG_LOAD} style={{ height: '100px', marginTop: '40px' }} />
          </div>)}
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(LoginPage);
