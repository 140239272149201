import React from "react";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import qrcode from "assets/icons/qrcode.svg"
import CustomInput from "components/CustomInput/CustomInput.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import ImageUpload from "./ImageUpload.jsx"
import Button from "components/CustomButtons/Button.jsx";
import ImageActions from "../ImageActions"
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import phoneFrame from "assets/img/phone.png";
import inputnotqr from 'assets/img/inputnotqr.png'
import inputqr from "assets/img/inputqr.png"
import buttoncamera from "assets/img/buttoncamera.png"
import { SizeMe } from 'react-sizeme'
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  fixCardRemove: {
    padding: "22px 5px !important",
    marginLeft: "9px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};


class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      icon: "",
      mobileicon: "",
      publicName:"",
      attribute: "",
      isHarvest: false,
      isPublic: true,
      description: "",
      authority: "",
      actions_image: [],
      checked: [],
      checked_default: [],
      checked_more: [],
      listElement: [],
      alert: null,
      componentType: [{
        title: "Nhập chữ",
        value: "InputBox"
      },
      {
        title: "Nhập chữ kèm nút QR bên cạnh",
        value: "InputBoxWithQR"
      },
      {
        title: "Ô chọn",
        value: "RNSelect"
      },
      
    ],
    inputType: [{
      title: "Dạng chữ",
      value: ""
    },
    {
      title: "Dạng số",
      value: "numeric"
    }
  ]
    };
    this.myDiv = React.createRef()
    this.handleSimple = this.handleSimple.bind(this);
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.handle_overview_label = this.handle_overview_label.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.onImageSubmitMobile = this.onImageSubmitMobile.bind(this)
    this.isValidated = this.isValidated.bind(this);
    this.sendState = this.sendState.bind(this);
    this.handle_overview_default = this.handle_overview_default.bind(this)
  }
  componentDidMount(){
    this.setState({
      height: this.myDiv.current.offsetHeight,
      width: this.myDiv.current.offsetWidth,
    })
  }
  sendState() {
    return this.state;
  }
  async onImageSubmit(file) {
    await this.setState({
      actions_image: file
    })
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    if (this.state.checked[0] === 1) {
     this.setState({
       isPublic: false
     })
    } else {
      this.setState({
        isPublic: true
      })
    }
  }
  async handleToggle_more(value, idx) {
    const newOverviews = this.state.listElement.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const checked_more = overview.checked_more;
      const currentIndex = checked_more.indexOf(value);
      const newChecked = [...checked_more];
      let bool = false;
      if (currentIndex === -1) {
        newChecked.push(value);
       bool = true;
      } else {
        newChecked.splice(currentIndex, 1);
        bool = false;
       // document.getElementById("default_value" + idx).style = "display: none; width: 100%"
      }
     return { ...overview,  checked_more: newChecked, isPublic: bool}
    })
    await this.setState({
      listElement: newOverviews
    })
  }
  async handleToggle_default(value, idx){
    const newOverviews = this.state.listElement.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      const checked_default = overview.checked_default;
      const currentIndex = checked_default.indexOf(value);
      const newChecked = [...checked_default];
      if (currentIndex === -1) {
        newChecked.push(value);
        document.getElementById("default_value" + idx).style = "display: block; width: 100%"
      } else {
        newChecked.splice(currentIndex, 1);
        document.getElementById("default_value" + idx).style = "display: none; width: 100%"
      }
     return { ...overview,  checked_default: newChecked}
    })
    await this.setState({
      listElement: newOverviews
    })
  }
  async onImageSubmitPortal(file) {
    await this.setState({
      icon: file
    })
  }
  async onImageSubmitMobile(file) {
    await this.setState({
      mobileIcon: file
    })
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated = () => {
    if(!this.state.name){
      document.getElementById("noti").innerHTML = "Chưa nhập tên công đoạn";
      return false;
    }
    if(!this.state.description){
      document.getElementById("noti").innerHTML = "Chưa nhập mô tả công đoạn";
     return false;
    }
    return true;
  }
  handlenamechange = event => {
    if (event.target.value === "-1") {
      this.setState({
        name: "",
      });
    }
    else {
      this.setState({
        name: event.target.value
      });
      document.getElementById("noti").innerHTML = "";
    }
  };
  handleiconchange = event => {
    if (event.target.value === "-1") {
      this.setState({
        icon: "",
      });
    }
    else {
      this.setState({
        icon: event.target.value
      });
      document.getElementById("noti").innerHTML = "";
    }
  };
  handle_overview_name = idx => async evt => {
    const newOverviews = this.state.listElement.map((overview, sidx) => {
      if (idx !== sidx) return overview;
     return { ...overview, key: evt.target.value, type: evt.target.value.value }
    })
    await this.setState({
      listElement: newOverviews
    })
   
  }
  handle_overview_default = idx => async evt => {
    const newOverviews = this.state.listElement.map((overview, sidx) => {
      if (idx !== sidx) return overview;
     return { ...overview, defaultValue: evt.target.value}
    })
    await this.setState({
      listElement: newOverviews
    })
   
  }
  handle_overview_label = idx => async evt => {
    const newOverviews = this.state.listElement.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, label: evt.target.value }
    })
    await this.setState({
      listElement: newOverviews
    })
  }
  handleAddOverviews = () => {
    this.setState({
      listElement: this.state.listElement.concat([{ 
        key: "", value: "", 
        type: "InputBox", 
        typeOfValue: "", 
        label: "", 	
        required: "false", 
        placeholder: "", 
        defaultValue: "",
        checked_more: [],
        checked_default: [],
        isPublic: false,
      }])
    })
  }
  handleRemoveInput = (idx) => {
    this.setState({
      listElement: this.state.listElement.filter((s, sidx) => idx !== sidx)
    })
  }
  handle_overview_description = idx => evt => {
    const newOverviews = this.state.listElement.map((overview, sidx) => {
      if (idx !== sidx) return overview;
      return { ...overview, value: evt.target.value, typeOfValue: evt.target.value.value }
    })
    this.setState({
      listElement: newOverviews
    })
  }
  handleattchange = event => {
    if (event.target.value === "-1") {
      this.setState({
        attribute: "",
      });
    }
    else {
      this.setState({
        // attributesState: "success",
        attribute: event.target.value
      });
      document.getElementById("noti").innerHTML = "";
    }
  };
  handledeschange = event => {
    if (event.target.value === "-1") {
      this.setState({
        description: "",
      });
    }
    else {
      this.setState({
        description: event.target.value
      });
      document.getElementById("noti").innerHTML = "";
    }
  };
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById("noti").innerHTML = "";
  };
  render() {
    const { classes } = this.props;
  
    return (
      <GridContainer justify="center">
        <GridItem xs={8} sm={8} md={8} lg={8}>
          <CustomInput
            labelText="Tên công đoạn"
            id="step_name"
            formControlProps={{
              fullWidth: true,
              onChange: this.handlenamechange
            }}
            inputProps={{
            }}
            helpText={this.state.nameHelp}
          />
          <CustomInput
            labelText="Mô tả công đoạn"
            id="step_icon"
            formControlProps={{
              fullWidth: true,
              onChange: this.handledeschange
            }}
            inputProps={{
            }}
            helpText={this.state.descriptionHelp}></CustomInput>
          {/* <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Nhân viên phụ trách
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.authority}
              onChange={this.handleSimple}
              inputProps={{
                name: "authority",
                id: "authority-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Chọn loại nhân viên
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="M"
              >
                Quản lý
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="SM"
              >
                Nhân viên nuôi trồng
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="SP"
              >
                Nhân viên chế biến
              </MenuItem>
            </Select>
          </FormControl> */}
          <div style={{ width: "100%" }}>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label= "Ẩn bước này trên trang web truy xuất nguồn gốc"
                    />
                  </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <p className="textIconProcess">Hình ảnh miêu tả công đoạn:</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <ImageActions
                onImageSubmit={this.onImageSubmit.bind(this)}
                icon={false}
                portal={false}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <p className="textIconProcess">Icon hiển thị trên điện thoại:</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <ImageUpload
                icon={true}
                portal={false}
                name={this.state.name}
                onImageSubmit={this.onImageSubmitMobile.bind(this)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <p className="textIconProcess">Icon hiển thị tại trang web truy xuất nguồn gốc:</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <ImageUpload
                icon={false}
                portal={true}
                onImageSubmit={this.onImageSubmitPortal.bind(this)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
            <form style={{ width: "100%" }}>
                      {this.state.listElement.map((overview, idx) => (
                        <GridContainer key={idx} className="borderaddInfo">
                          <GridItem xs={3} md={3} sm={3} lg={3}>
                          <CustomInput
                             labelText={"Tên trường #" + idx}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handle_overview_label(idx),
                              }}
                              inputProps={{
                                value: overview.label,
                                autoComplete: "off",
                                placeholder: "Tên trường #" + idx
                              }}
                            /> 
                          </GridItem>
                          <GridItem xs={9} md={9} sm={9} lg={9}>
                            {/* <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handle_overview_description(idx),
                              }}
                              inputProps={{
                                value: overview.value,
                                autoComplete: "off",
                                placeholder: "Nội dung #" + idx
                              }}
                            /> */}
                             <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="product-expired-type"
                        className={classes.selectLabel}
                      >
                       Định dạng dữ liệu #{idx}
                              </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        id='select'
                        classes={{
                          select: classes.select
                        }}
                        value={overview.value}
                        onChange={this.handle_overview_description(idx)}
                        inputProps={{
                          name: "type",
                          id: "type"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Chọn định dạng dữ liệu
                        </MenuItem>
                        {this.state.inputType.map((x, idxx) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={x}
                            key={idxx}
                          >
                            {x.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div style={{ width: "100%" }}>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle_more(1, idx)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label= "Chỉ dùng trong nội bộ"
                    />
                     <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle_default(1, idx)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label= "Giá trị mặc định"
                    />
                  </div>
                  <div id={"default_value" + idx} style={{display: "none"}}>
                    <CustomInput
                             labelText={"Giá trị mặc định #"+ idx}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handle_overview_default(idx),
                              }}
                              inputProps={{
                                value: overview.defaultValue,
                                autoComplete: "off",
                                placeholder: "Giá trị mặc định #" + idx
                              }}
                            /> 
                    </div>
                          </GridItem>
                          <GridItem xs={1} md={1} sm={1} lg={1} className={classes.fixCardRemove}>
                            <Button color="danger" size="sm" onClick={() => this.handleRemoveInput(idx)}>Loại bỏ</Button>
                          </GridItem>
                        </GridContainer>
                      ))}
                      <Button color="success" size="sm" onClick={this.handleAddOverviews} >Thêm trường thông tin</Button>
                    </form>
                    
            </GridItem>
          </GridContainer>
          <p
            id="noti"
            style={{
              fontSize: '0.875rem',
              fontWeight: '400',
              color: 'red',
              fontFamily: 'Muli',
              marginTop: 5,
              marginBottom: 5,
            }}>
          </p>
        </GridItem>
        <GridItem lg={4} md={4} sm={4} xs={4}>
          <GridItem xs={12} md={12} sm={12} xs={12} style={{ textAlign: "start", width: "100%" }}>
            <p className="textUploadProcess">Giao diện nhập liệu trên điện thoại: </p>
          </GridItem>
          <GridItem xs={12} md={12} sm={12} xs={12}>
            <div ref={this.myDiv} id="phonetrial"className="phoneFrame" style={{backgroundImage:`URL(${phoneFrame})`}}>
            <SizeMe monitorHeight render={({ size }) =>
             ( <div className="scrollPhone" style={{marginTop: `${size.height/2.3}px`, padding: `0px ${size.width/8}px`, overflowX: "hidden", overflowY: "scroll",height: `${this.state.height - this.state.height/3.5 - this.state.height/10}px`}}>
             <GridItem xs={12} md={12} sm={12} xs={12} style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
             <p className="headerPhone">Bạn muốn {this.state.name} cho</p>
             </GridItem>
             <GridItem xs={12} md={12} sm={12} xs={12}>
                 <p style={{fontWeight: "600", marginBottom: "4px"}}>Đối tượng sản xuất</p>
                 <img style={{width: "100%"}} src={inputqr}/>
               </GridItem>
               {this.state.listElement.map((overview, key)=> (
                 <GridItem xs={12} md={12} sm={12} xs={12} key={key} style={{marginTop: "10px"}}>
                 <p style={{fontWeight: "600", marginBottom: "4px"}}>{overview.label}</p>
                 <img style={{width: "100%"}} src={inputnotqr}/>
               </GridItem>
               ))}
               <GridItem xs={12} md={12} sm={12} xs={12} style={{marginTop: "10px"}}>
               <p style={{fontWeight: "600", marginBottom: "4px"}}>Hình ảnh</p>
                 <img style={{width: "100%"}} src={buttoncamera}/>
               </GridItem>
             </div>)}
              />
            </div>
          </GridItem>
        </GridItem>
        {this.state.alert}
      </GridContainer>
    );
  }
}


export default withStyles(style)(Step);
