import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import strings from 'constants/strings';

import Location from "@material-ui/icons/LocationOn";
import Home from "@material-ui/icons/Home";
import styles from './infoStyles';

class Manufacturer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tx: ""
    }
    this.data = this.props.data;
    this.lookupTree = this.lookupTree.bind(this)
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  showBlockhainAlert = (tx) => {
    this.setState({ open: true, tx: tx });
  }

  lookupTree = () => {
    let path = "/lookup/" + this.data.treeInfo.idCode;
    this.props.history.push(path);
  };

  render() {
    const { classes } = this.props;
    this.data = this.props.data;

    const dataSection = {
      // [strings.tree_info]: {
      //   [strings.tree_code]: this.data.treeInfo.idCode,
      //   "Tra cứu": <Button color="success" onClick={this.lookupTree} className={classes.btTrack}>{strings.lookup_tree}</Button>
      //   // "Quy mô": this.data.farmInfo.scope,
      //   // "txID": this.data.farmInfo.txID,
      // },
      // [strings.farm_info]: {
      //   [strings.farm]: this.data.farmInfo.farm_name,
      //   [strings.farm_code]: this.data.farmInfo.gln,
      //   [strings.scale]: this.data.farmInfo.scope,
      //   "txID": this.data.farmInfo.txID,
      // },
      [strings.cooporative_info]: {
        "Doanh nghiệp": this.data.cooperativeInfo.user_name,
        [strings.cooporative_code]: this.data.cooperativeInfo.gln,
        [strings.phone]: this.data.cooperativeInfo.phone,
        [strings.email]: this.data.cooperativeInfo.email,
        [strings.website]: (this.data.cooperativeInfo.website) ? <a href={this.data.cooperativeInfo.website}>{this.data.cooperativeInfo.website}</a> : null,
        [strings.address]: this.data.cooperativeInfo.address,
        "txID": this.data.cooperativeInfo.tx_id,
        "location": ""
      }
    }

    let that = this;

    const info = Object.keys(dataSection).map(function (key, index) {
      // const header = (
      //   <div>
      //     <div style = {{display:"flex",marginLeft:"-5px"}}>
      //       <div style = {{marginTop:"auto",marginBottom:"auto",marginRight: "7px",color:"#5cb360"}}>
      //         <Location/>
      //       </div>
      //       <Typography className={classes.textCode} style={{color:"#5cb360", textTransform:"capitalize"}}>{key}</Typography>
      //     </div>
      //     <hr className={classes.barGreen}></hr>
      //   </div>
      // );

      const section_data = dataSection[key];
      const section = Object.keys(section_data).map(function (section_name, section_index) {
        if (section_data[section_name] != null) {
          if (section_name !== "txID" && section_name !== "location" && section_name !== "Tra cứu") {
            return (
              <GridContainer className="manufactsec">
                <GridItem xs={12} className="contentnopad">
                  <Typography className="tagmanufact"> {section_name} </Typography>
                </GridItem>
                <GridItem xs={12} className="desmanufact">
                  <Typography className="tagmanufactdes" >{section_data[section_name]}</Typography>
                </GridItem>
                <hr className="LineBreakDesManu"></hr>
              </GridContainer>
            )
          }
          else if (section_name === "txID" && section_data[section_name] !== null && section_data[section_name] !== "") {
            return (
              <div>
                <GridContainer className="manufactsec" style={{ marginTop: "10px", padding: "0px 0px !important" }}>
                  <hr className="LineBreakDesWide" />
                  <div className="sectionone" >
                    <Typography className="tagmanufact">{strings.blockchain_info}</Typography>
                  </div>
                  <div className="sectiontwo">
                    <Typography className="tagmanufactdesbc" onClick={() => that.showBlockhainAlert(section_data[section_name])}> {section_data[section_name]} </Typography>
                  </div>

                </GridContainer>
                <GridItem xs={12} className={classes.noPadding}>
                  <hr className="LineBreakDes" />
                </GridItem>
              </div>
            )
          }
          else if (section_name == "location") {
            return (
              <GridContainer style={{ marginTop: "10px" }}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6171538277135!2d106.65410231526026!3d10.76396026236589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752948138337ad%3A0xeb0ed9325dfa16eb!2sInfinity+Blockchain+Labs!5e0!3m2!1svi!2s!4v1560408722528!5m2!1svi!2s" width="100%" height="400" frameborder="0" style={{ border: "0", padding: "15px" }}></iframe>
              </GridContainer>
            )
          }
          else if (section_name == "Tra cứu") {
            return (
              <GridContainer style={{ marginTop: "10px" }}>
                <GridItem xs={12}>
                  {section_data[section_name]}
                </GridItem>
              </GridContainer>
            )
          }
        }
        else {
          return (<div></div>)
        }
      });
      return (
        <div style={{ marginTop: "10px" }}>
          {section}
        </div>
      )
    })


    return (
      <div>
        <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
          <div className="manufactlayout">
            <p className="xuatxutag">{strings.product_origin}</p>
            <div style={{ marginTop: "20px" }}>
              {info}
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: '10px', paddingBottom: '70px', paddingTop: '70px', zIndex: 3000 }}
          fullScreen={true}
        >
          <DialogContent style={{ padding: '0px' }}>
            <iframe style={{ height: '100%', width: '100%' }} src={'https://blockchain.agridential.vn/tx/' + this.state.tx} />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button style={{ color: 'white', background: 'black' }} onClick={this.handleClose} color="primary" autoFocus>
              {strings.close}
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Manufacturer));
