import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import SelectModern from 'react-select'
import moment from 'moment';
// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search"
import Gallery from '../../components/Forms/Gallery/Gallery';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Detail from "@material-ui/icons/Info";
import GetApp from "@material-ui/icons/GetApp"
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';
import InputLabel from "@material-ui/core/InputLabel";
import ReactExport from "react-export-excel";
import strings from "../../../../constants/strings";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "../../components/Pagination/index.jsx"
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  ...extendedFormsStyle,
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  UnsetOverflow: {
    overflow: "unset"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.genTable = this.genTable.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFilterData = this.handleFilterData.bind(this);
    this.handleSelectStatus = this.handleSelectStatus.bind(this);
    this.role = cookie.load("checkrole");

    this.state = {
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      selected: cookie.load("slaughter", { path: "/" }) ? cookie.load("slaughter", { path: "/" }) : strings.all,
      defaultSelect: cookie.load("slaughter", { path: "/" }) ? [{ value: cookie.load("slaughter", { path: "/" }), label: cookie.load("slaughter", { path: "/" }) }] : [{ value: strings.all, label: strings.all }],
      options: [{ value: strings.slaughter, label: strings.slaughter }, { value: strings.alive, label: strings.alive }, { value: strings.all, label: strings.all }],
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
      startTime: "",
      endTime: "",
      valueFilter: "",
      pages: 1,
      valueStatus: "",
      listdata: [],
      listdataFix: [],
      loading: false,
      filterStatus: false,
      totalPages: 0,
      //page=1&size=10&RFID=1&LNC=1&boxId=1&partnerUuid=M000476
      listFilter: [strings.rfid, strings.LNC, strings.shipmentNumber, strings.location, strings.status],
      // listFilter: [
      //   {
      //     value: "RFID",
      //     label: strings.rfid,
      //   },
      //   {
      //     value: "LNC",
      //     label: strings.LNC,
      //   },
      //   {
      //     value: "boxId",
      //     label: strings.shipmentNumber,
      //   },
      //   {
      //     value: "partnerUuid",
      //     label: strings.location
      //   },
      //   {
      //     value: "status",
      //     label: strings.status
      //   }
      // ],
      statusList: [ strings.slaughter, strings.alive
        // {
        //   value: "1",
        //   label: strings.slaughter
        // },
        // {
        //   value: "-1",
        //   label: strings.alive
        // }
      ]
    };
  }
  handleSelectStatus(evt) {
    if(evt.target.value === strings.slaughter){
      this.setState({
        valueField: "1",
        selectStatus: evt.target.value
      })
    }
    if(evt.target.value ===  strings.alive){
      this.setState({
        valueField: "-1",
        selectStatus: evt.target.value
      })
    }
    
  }
  handleChange = (name) => async (event) => {
    console.log(name)
    await this.setState({
      [name]: event.target.value
    });
  }
  async handleSelect(evt) {
    if (evt.target.value === strings.status) {
      document.getElementById("displayInput").style = "display: none;"
      document.getElementById("displaySelect").style = "display: block; width: 100%"
    } else {
      document.getElementById("displayInput").style = "display: block; width: 100%"
      document.getElementById("displaySelect").style = "display: none;"
      await this.setState({
        valueField: ""
      })
    }
    //listFiter: [strings.rfid, strings.LNC, strings.shipmentNumber, strings.location, strings.status],
    if(evt.target.value === strings.LNC){
      this.setState({
        selectField: evt.target.value,
        selectField_main: "LNC"
      })
    }
    if(evt.target.value === strings.shipmentNumber)
    {
      this.setState({
        selectField: evt.target.value,
        selectField_main: "boxId"
      })
    }
    if(evt.target.value === strings.rfid){
      this.setState({
        selectField: evt.target.value,
        selectField_main: "RFID"
      })
    }
    if(evt.target.value === strings.location){
      this.setState({
        selectField: evt.target.value,
        selectField_main: "partnerUuid"
      })
    }
    if(evt.target.value === strings.status){
      this.setState({
        selectField: evt.target.value,
        selectField_main: "status"
      })
    }
    
  }
  handleFilterData = async () => {
    // await this.props.cleanTable();
    if (!this.state.selectField_main) {
      return;
    }
    if (!this.state.valueField) {
      return;
    }
    await this.setState({
      load: "",
      filterStatus: true,
      showTable: "none"
    })
    await this.props.cleanTable()
    this.props.getCowListFilter(1, this.state.selectField_main, this.state.valueField)
  }
  handleChange_Select = async (selectedOption, event) => {
    cookie.save("slaughter", selectedOption.value, { path: "/" })

    if (selectedOption.value === strings.all) {
      this.setState({ listdata: this.state.listdataFix });
    }
    else {
      let dataFilter = this.state.listdataFix.filter(item => {
        return item.status == selectedOption.value;
      })
      this.setState({ listdata: dataFilter });
    }

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  renderTrigger() {
    return <Button color="success" onClick={() => {
      document.getElementById("printobject").style = "display: block"
    }}>In Mã</Button>;
  }

  renderContent = () => { // eslint-disable-line arrow-body-style
    return this.componentRef
  }
  setRef = (ref) => {
    this.componentRef = ref;
  }
  isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  genTable = async (data) => {
    this.setState({
      listdata: data.map((product, idx) => {
        return {
          ...product,
          actions: (
            <Button style={{ backgroundColor: "white", color: "#212b36" }} size="sm" onClick={() => this.handleCrop_List(product.NON_GS1, product.RFID, product.boxid, product.status)}>
              <Detail style={{ margin: "0px", marginRight: "5px" }} /> <a style={{ fontSize: "12px", textTransform: "none" }}>{strings.watchDetail}</a>
            </Button>
          )
        }
      }),
      loading: false
    })
  }
  stopCrop(gln) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Kết thúc mùa vụ"
          onConfirm={() => this.onStop(gln)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Kết thúc"
          cancelBtnText="Hủy bỏ"
          showCancel
        >
        </SweetAlert>
      )
    })
  }
  onStop(gln) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    const data = {
      "uuidOfProductionObject": gln
    }
    axios.post(LINK_API.STOP_CROP, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title={strings.success}
                onConfirm={() => this.hideAlert()}
                showCancel={false}
              >
              </SweetAlert>
            )
          });
          let farms = await this.getFarms();
          this.setState({ farmList: farms });
          this.genTable(farms);
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title={strings.failed}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });
  }
  async handleCrop_List(id, rfid, boxid, status) {
    await cookie.save("IDCattle", id, { path: "/" });
    await cookie.save("RFIDCattle", rfid, { path: "/" });
    await cookie.save("shipmentCattle", boxid, { path: "/" });
    await cookie.save("statusCalle", status, { path: "/" });
    this.props.history.push("/admin/cattle/list/detail")
  }
  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.CREATE_OBJECT_PRODUCTION);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    let dataCattle = [];
    try {
      await this.checkPermission();
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }

      let path = cookie.load("slaughter", { path: "/" });
      if (path) {
        document.getElementById("displayInput").style = "display: none;"
        document.getElementById("displaySelect").style = "display: block; width: 100%"
        await this.setState({
          valueField: 1, selectField_main: "status",
          selectField: strings.status,
          selectStatus: strings.slaughter,
          filterStatus: true
        });
        await  cookie.remove("slaughter", { path: '/' });
        await this.props.cleanTable();
        this.props.getCowListFilter(1, this.state.selectField_main, this.state.valueField)
      } else {
        let location = cookie.load("uuidPartner",{path: "/"})
        if(location){
          await this.setState({
            valueField: location, selectField_main: "partnerUuid",
            selectField: strings.location,
            filterStatus: true
          })
          await  cookie.remove("uuidPartner", { path: '/' })
          await this.props.cleanTable();
          await this.props.getCowListFilter(1, this.state.selectField_main, this.state.valueField)
        }else{
          this.props.getCowList(1);
        }
        
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  async componentWillReceiveProps(preProps) {
    if (preProps.cowList !== this.props.cowList) {
      if (preProps.cowList.data !== this.props.cowList.data) {
        if (preProps.cowList.data.length > 0) {
          await this.genTable(preProps.cowList.data)
          await this.setState({
            load: "none",
            totalPages: preProps.cowList.totalPage,
            showTable: ""
          })
        } else {
          await this.genTable([])
          await this.setState({
            load: "none",
            totalPages: 0,
            showTable: ""
          })
        }
      }
    }
  }

  handleCrop(tree, product, process) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Tạo mùa vụ cho đối tượng:"
          onConfirm={() => this.handleInputDate(tree, product, process)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Đóng"
          showCancel
        >
          {tree}
        </SweetAlert>
      )
    })
  }
  handleInputDate(tree, product, process) {
    //const {classes} = this.props
    this.setState({
      alert: (
        <SweetAlert
          //className= {this.props.classes.UnsetOverflow}
          warning
          style={{ display: "block", overflow: "unset" }}
          title="Nhập thông tin mùa vụ"
          onConfirm={() => this.onCrop(tree, product, process)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Đóng"
          showCancel
        >
          <Card className="noShadow" style={{ marginTop: "-8px" }}>
            <CardBody>
              <br />
              <FormControl fullWidth>
                <Datetime
                  value={this.state.startTime}
                  className="InputDatetime"
                  formControlProps={{
                    onChange: this.handleChange('startTime'),
                  }}
                  inputProps={{ placeholder: "Ngày bắt đầu", className: "InputDatetime" }}
                  onChange={this.handleChange('startTime')}
                />
                <hr className="LineBreakDesDate" />
              </FormControl>
            </CardBody>
          </Card>
          <Card className="noShadow" style={{ marginTop: "-60px" }}>
            <CardBody>
              <br />
              <FormControl fullWidth>
                <Datetime
                  className="InputDatetime"
                  inputProps={{
                    placeholder: "Ngày kết thúc",
                    className: "InputDatetime",
                  }}
                />
                <hr className="LineBreakDesDate" />
              </FormControl>
            </CardBody>
          </Card>
        </SweetAlert>
      )
    })
  }
  onCrop(tree, product, process) {
    //console.log(tree, area, process)
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    const data = {
      "uuidOfProductionObject": tree,
      "processCode": process,
      "uuidOfProductType": product,
    }
    axios.post(LINK_API.ASSIGN_CROP, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                showCancel={false}
              >
              </SweetAlert>
            )
          });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });

  }
  handleNewClick() {
    this.props.history.push("/admin/areas/objects/list/create")
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                  <GridItem lg={5} md={5} sm={5} xs={5}>
                    <GridContainer style={{ display: "flex", flexFlow: "center" }}>
                      <h4 style={{ marginRight: 19 }} className="cardtableLabel">{strings.farmingObjectList}</h4>
                      <div className="">
                        <ExcelFile element={<Button justIcon color="success" className="like" simple round > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> </Button>}>
                          <ExcelSheet data={this.state.export} name="Cattle Import">
                            <ExcelColumn label="ID" value="uuid" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Import Date" value="time" />
                          </ExcelSheet>
                        </ExcelFile>
                      </div>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={5} sm={5} md={5} lg={5}>
                    <GridContainer>
                      <GridItem xs={5}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="product-expired-type"
                            className={classes.selectLabel}
                          >
                            {strings.selectField}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            id='select'
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.selectField}
                            onChange={this.handleSelect}
                            inputProps={{
                              name: "type",
                              id: "type"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              {strings.chooseType}
                            </MenuItem>
                            {this.state.listFilter.map((x, idx) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={x}
                                key={idx}
                              >
                                {x}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={5}>
                        <div id="displayInput">
                          <CustomInput
                            labelText={strings.value}
                            success={this.state.nameState === "success"}
                            error={this.state.nameState === "error"}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                              onChange: this.handleChange('valueField'),
                            }}
                            inputProps={{
                              value: this.state.valueField,
                              autoComplete: "off",
                            }}
                          />
                        </div>
                        <div id="displaySelect" style={{ display: "none" }}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="product-expired-type"
                              className={classes.selectLabel}
                            >
                              {strings.status}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              id='select'
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.selectStatus}
                              onChange={this.handleSelectStatus}
                              inputProps={{
                                name: "type",
                                id: "type"
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                {strings.chooseType}
                              </MenuItem>
                              {this.state.statusList.map((x, idx) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={x}
                                  key={idx}
                                >
                                  {x}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                      </GridItem>
                      <GridItem xs={2}>
                        <GridContainer justify="flex-end" style={{ paddingRight: "10px", paddingTop: "15px" }}>
                          <Button justIcon color="success" size="sm" onClick={() => this.handleFilterData()}><Search onClick={this.handleFilterData} style={{ marginLeft: "11px", marginTop: "-2px" }} /></Button>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem lg={2} md={2} sm={2} xs={2}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      {(this.props.userInfo.data.active === true) ? <Button color="success" size="sm" onClick={this.handleNewClick}> <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button> : null}
                    </GridContainer>
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                    <div style={{ border: "1px solid #0f7343" , borderRadius: "3px" , width: "100%", display: "flex", justifyContent: "flex-start", flexFlow: "column", textAlign: "start" }}>
                        <SelectModern
                            defaultValue={this.state.defaultSelect}
                            options={this.state.options}
                            // value={this.state.clientId_label}
                            onChange={this.handleChange_Select}
                            // className={classes.SelectDrop}
                            placeholder={strings.chooseArea}
                        />
                    </div>
                </GridItem> */}

                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  loading={this.state.loading}
                  data={this.state.listdata}
                  // defaultFiltered={[{ id: "status", value: this.state.valueFilter }]}
                  // filterable
                  // defaultFilterMethod={(filter, row) =>
                  //   String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                  previousText="<"
                  nextText=">"
                  rowsText={strings.row}
                  ofText="/"
                  pageText={strings.page}
                  noDataText={strings.noData}
                  manual
                  columns={[

                    {
                      Header: "ID",
                      accessor: "NON_GS1",
                      sortable: false,
                      style: { 'whiteSpace': 'unset' }
                    },


                    {
                      Header: "LNC",
                      accessor: "LNC",
                      sortable: false,
                      style: { 'whiteSpace': 'unset' }
                    },
                    {
                      Header: "RFID",
                      accessor: "RFID",
                      sortable: false
                    },
                    {
                      Header: strings.shipmentNumber,
                      accessor: "boxid",
                      sortable: false
                    },
                    {
                      Header: strings.status,
                      accessor: "status",
                      sortable: false
                    },
                    {
                      Header: strings.location,
                      accessor: "location",
                      sortable: false
                    },
                    {
                      Header: strings.actionHeader,
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={true}
                  loading={this.state.loading}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                  PaginationComponent={Pagination}
                  pages={this.state.totalPages}
                  onFetchData={async (state, instance) => {
                    await this.setState({ loading: true })
                    let x = state.page + 1;
                    if (this.state.filterStatus === false) {
                      console.log("Check")
                      this.props.getCowList(x)
                    } else {
                      console.log("filter")
                      this.props.getCowListFilter(x, this.state.selectField_main, this.state.valueField)
                    }

                  }}
                // onPageChange={(pageIndex) => {console.log("Check",pageIndex + 1)}} 
                />
              </CardBody>
            </Card>

          </GridItem>
        </GridContainer>
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getObject: (index) => dispatch({ type: "GET_OBJECT_LIST", index: index }),
    getCowList: (page) => dispatch({ type: "GET_LIST_COW", page: page }),
    getCowListFilter: (page, name, value) => dispatch({ type: "GET_FILTER_LIST_CATTLE", page: page, name: name, value: value }),
    cleanTable: () => dispatch({ type: "CLEAN_DATA_TABLE_COW" })
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    objectList: state.objectList,
    cowList: state.cowList
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
