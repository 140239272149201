const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
    color: "#212b36"
  },
  cardHeaderColor: {
    background: "#20201A !important",
    boxShadow:
    "0 12px 0px -10px rgba(140, 140, 140, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(70, 70, 70, 0.2) !important",
    padding: "0px 15px !important"
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important"
  },
  displayNone: {
    display: "none !important"
  },
  tabsRoot: {
    minHeight: "unset !important",
    "& $tabRootButton": {
      fontSize: "0.875rem"
    }
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 15px",
    //borderRadius: "3px",
    lineHeight: "24px",
    // border: "1px !important",
    //borderColor: "black",
    borderBottom: "2px solid #ddd !important",
    color: "#212b36!important",
    //marginLeft: "4px",
    "&:last-child": {
      marginLeft: "0px"
    }
  },
  tabLabelContainer: {
    padding: "0px"
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "12px",
   
  },
  noPadding:{
    padding: "0px 0px !important",
  },
  tabSelected: {
    // backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderLeft: "2px solid #ddd !important",
    borderRight: "2px solid #ddd !important",
    borderTop: "2px solid #0f7343 !important",
    //borderRadius: "1px  !important",
    borderBottom: "3px solid white !important",
    transition: "0.2s background-color 0.1s",
    //borderTopRightRadius: "5px",
    //borderTopLeftRadius: "5px"
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    fontFamily: "Muli",
    fontWeight: 700,
    maxHeight: "unset !important",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0"
    }
  },
  tabScroller:{
    zIndex: "1000",
  }
};

export default customTabsStyle;
