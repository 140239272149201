import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import StepProcess from './stepProcess';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.jsx";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import Timeline from "./Timeline.jsx";
import { withRouter } from 'react-router-dom';
import timelineStyle from "assets/jss/material-dashboard-pro-react/components/timelineStyle.jsx";
import cx from "classnames";
import strings from "../../../../../constants/strings";
const style = {
  ...timelineStyle,
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    marginLeft: "15px"
  },
  bodyinfo: {
    padding: "10px 29px !important",
    marginTop: "-26px"
  },
  alignLeft: {
    textAlign: "left"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  },
  paddingbasicInfo: {
    padding: "10px 29px !important",
    marginTop: "-26px"
  }
}

class InfoProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      alert: null,
      image: null,
      process_name: '',
      process_name_state: "",
      process_name_help: "",
      steps: [{ stepName: "Bước 1", stepComponent: StepProcess, stepId: 0 }],
      destime: ["Nuôi trồng", "Chế biến"],
      emps: [],
      employee: "",
      typecheck: 0,
      count: 0,
      type: "",
      shawStamp: false,
      checked: [],
      checkedPacked: [],
      timeLineList: [],
      stateTimeline: [], //State chứa dữ liệu Timeline của từng bước
    };
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);
    this.handleUpdateEmpList = this.handleUpdateEmpList.bind(this);
    this.handleSelectEmp = this.handleSelectEmp.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.initialTimeLine = this.initialTimeLine.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleFinalClick = this.handleFinalClick.bind(this)
  }

  async componentDidMount() {
    this.props.getFarms();
    this.initialTimeLine();
  }
  async initialTimeLine() {
    let temp = this.state.timeLineList;
    await temp.push({
      inverted: true,
      badgeColor: "success",
      badgeIcon: <img src={require("../../../../../assets/icons/" + "sun.svg")} style={{ width: "32px" }}></img>,
      name: "",
      icon: "",
      iconSrc: "",
      mobileIcon: "",
      mobileSrc: "",
      publicName: "",
      attribute: "",
      isHarvest: false,
      isPublic: true,
      description: "",
      authority: ['SM', 'SP'],
      actions_image: [],
      checked: [],
      checked_default: [],
      checked_more: [],
      listElement: [],
    })
    this.setState({
      timeLineList: temp
    })
  }
  handleFinalClick(){
    if (!this.state.process_name) {
      document.getElementById("noti").innerHTML = "Thiếu tên quy trình";
      return false;
    }
    if (this.state.typecheck === 0) {
      document.getElementById("noti").innerHTML = "Chưa chọn loại quy trình";
      return false;
    }
    const data = {
      "process_name": this.state.process_name,
      "role": [{ "uuid": this.state.employee, "role": "C" }],
      "type": this.state.type_temp,
      "specialStep": {
        "activateStamp": {
          "show": this.state.showStamp,
          "name": this.state.stamp_name ? this.state.stamp_name : "Kích hoạt tem",
          "description": this.state.stamp_des ? this.state.packed_des : ""
        },
        "packed": {
          "show": this.state.showPacked,
          "name": this.state.packed_name ? this.state.packed_name : "Đóng thùng",
          "description": this.state.packed_des ? this.state.packed_des : ""
        }
      },
    }
    this.clickFinal(data);
  }
  handleNewClick() {
    this.clickChild();
  }
  componentWillReceiveProps(preProps) {
    if (this.props.employeeList !== preProps.employeeList) {
      if(preProps.employeeList.data){
        this.handleUpdateEmpList(Array.from(preProps.employeeList.data))
      }
      
    }
  }

  async handleSelectEmp(event) {
    await this.setState({
      employee: event.target.value.id,
      employeeTemp: event.target.value,
    })
  }

  handleUpdateEmpList(data) {
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      temp.push({
        name: data[i].name,
        id: data[i].uuid,
      })
    }
    this.setState({
      emps: temp
    })
  }

  showConfirmAlert(data) {
    this.onSuccess(data)

  }
  async onSuccess(data) {
    if (!this.state.process_name) {
      document.getElementById("noti").innerHTML = "Thiếu tên quy trình";
      return false;
    }
    if (this.state.typecheck === 0) {
      document.getElementById("noti").innerHTML = "Chưa chọn loại quy trình";
      return false;
    }
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          onConfirm={() => this.onSuccess(data)}
          onCancel={() => {
            this.hideAlert();
            this.setState({ openCreateFarm: true });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          {/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
        </SweetAlert>,
      dataSubmit: data
    })
    const dataArray = Object.values(data);
    var datatosend = {
      "process_name": this.state.process_name,
      "role": [{ "uuid": this.state.employee, "role": "C" }],
      "type": this.state.type_temp,
      "step": dataArray,
    }
    this.props.createProcess(datatosend);
    this.props.history.push("/admin/process/list")
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  handleSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById("noti").innerHTML = "";
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
    document.getElementById("noti").innerHTML = "";
  };

  onImageSubmit(file) {
    if (file != null) {
      this.setState({
        image: file
      })
    document.getElementById("noti").innerHTML = "";
    }
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    if (this.state.checked[0] === 1) {
      document.getElementById("isRight").style = "width: 100%; display: block; margin-top: -30px;";
      this.setState({
        showStamp: true
      })
    } else {
      document.getElementById("isRight").style = "display: none"
      this.setState({
        showStamp: false
      })
    }
  }

  async handleTogglePacked(value) {
    const { checkedPacked } = this.state;
    const currentIndex = checkedPacked.indexOf(value);
    const newChecked = [...checkedPacked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checkedPacked: newChecked
    });
    if (this.state.checkedPacked[0] === 1) {
      document.getElementById("isRight2").style = "width: 100%; display: block; margin-top: -30px;";
      this.setState({
        showPacked: true
      })
    } else {
      document.getElementById("isRight2").style = "display: none"
      this.setState({
        showPacked: false
      })
    }
  }

  handleClick() {
    let k = this.state.steps.length;
    this.state.steps.push({ stepName: `Bước ${k + 1}`, stepComponent: StepProcess, stepId: k });
    this.setState({
      steps: this.state.steps,
      count: k + 1,
    });
  }
  async  handleSelect(event) {
    if (event.target.value === "Nuôi trồng") {
      await this.setState({ type: "Nuôi trồng", type_temp: 1, typecheck: 1 });
    }
    if (event.target.value === "Chế biến") {
      await this.setState({ type: "Chế biến", type_temp: 2, typecheck: 1 });
    }
    if (event.target.value === "Vận chuyển") {
      await this.setState({ type: "Vận chuyển", type_temp: 3, typecheck: 1 });
    }
  }
  render() {
    const { classes, simple } = this.props;
    return (
      <div>
        <Card>
          <CardHeader color="success" icon>
            <GridContainer>
              <GridItem lg={6} md={6} sm={6} xs={12}>
                <h4 className="cardtableLabel">{strings.processInfo}</h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody className={classes.paddingbasicInfo}>
            <CustomInput
              labelText={strings.namePro}
              id="process_name"
              formControlProps={{
                fullWidth: true,
                onChange: this.handleChange('process_name')
              }}
              inputProps={{
                value: this.state.process_name
              }}
              helpText={this.state.process_name_help}
            />
            <div style={{ width: "100%", marginTop: "-37px", marginBottom: "21px" }}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="product-expired-type"
                  className={classes.selectLabel}
                >
                  {strings.typePro}
                              </InputLabel>
                <Select
                  native={false}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  id='select'
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.type}
                  onChange={this.handleSelect}
                  inputProps={{
                    name: "type",
                    id: "type",
                    value: this.state.type
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value="Chọn loại quy trình"
                  >
                    {strings.chooseType}
                        </MenuItem>
                  {this.state.destime.map((x, idx) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={x}
                      key={idx}
                    >
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* <div style={{ width: "100%", marginTop: "-20px", marginBottom: "21px" }}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="product-expired-type"
                        className={classes.selectLabel}
                      >
                        Nhân viên phụ trách
                              </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        id='select'
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.employeeTemp}
                        onChange={this.handleSelectEmp}
                        inputProps={{
                          name: "type",
                          id: "type"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Chọn nhân viên
                        </MenuItem>
                        {this.state.emps.map((x, idx) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={x}
                            key={idx}
                          >
                            {x.name + " - " + x.id}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}
            <div style={{ width: "100%" }}>
              <FormControlLabel
                classes={{
                  root: classes.checkboxLabelControl,
                  label: classes.checkboxLabel
                }}
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => this.handleToggle(1)}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                label={<p style={{fontSize: "14px", marginTop: "11px"}}>{strings.activeStampPro}</p>}
              />
            </div>
            <div style={{ width: "100%", display: "none" }} id="isRight">
              <CustomInput
                style={{ marginTop: "-20px" }}
                labelText={strings.nameStep}
                id="process_name"
                formControlProps={{
                  fullWidth: true,
                  onChange: this.handleChange('stamp_name')
                }}
                //value = {this.state.process_name}
                inputProps={{
                  value: this.state.stamp_name
                }}
                helpText={this.state.process_name_help}
              />
              <div style={{ marginTop: "-33px" }}>
                <CustomInput
                  labelText={strings.description}
                  id="process_name"
                  formControlProps={{
                    fullWidth: true,
                    onChange: this.handleChange('stamp_des')
                  }}
                  //value = {this.state.process_name}
                  inputProps={{
                    value: this.state.stamp_des
                  }}
                  helpText={this.state.process_name_help}
                />
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <FormControlLabel
                classes={{
                  root: classes.checkboxLabelControl,
                  label: classes.checkboxLabel
                }}
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => this.handleTogglePacked(1)}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                label={<p style={{fontSize: "14px", marginTop: "11px"}}>{strings.packedPro}</p>}
              />
            </div>
            <div style={{ width: "100%", display: "none" }} id="isRight2">
              <CustomInput
                style={{ marginTop: "-20px" }}
                labelText={strings.nameStep}
                id="process_name"
                formControlProps={{
                  fullWidth: true,
                  onChange: this.handleChange('packed_name')
                }}
                //value = {this.state.process_name}
                inputProps={{
                  value: this.state.packed_name
                }}
                helpText={this.state.process_name_help}
              />
              <div style={{ marginTop: "-33px" }}>
                <CustomInput
                  labelText={strings.description}
                  id="process_name"
                  formControlProps={{
                    fullWidth: true,
                    onChange: this.handleChange('packed_des')
                  }}
                  //value = {this.state.process_name}
                  inputProps={{
                    value: this.state.packed_des
                  }}
                  helpText={this.state.process_name_help}
                />
              </div>
            </div>
            <p
									id="noti"
									style={{
										fontSize: '0.875rem',
										fontWeight: '400',
										color: 'red',
										fontFamily: 'Muli',
										marginTop: 5,
										marginBottom: 5
									}}
								/>
          </CardBody>
        </Card>
        <Card plain>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12}>
                <GridContainer>
                  <div className="BackgroundHeaderCreateProcess">
                    <span>{strings.detailProcess}</span>
                  </div>
                  <Button color="success" size="sm" style={{ marginLeft: "20px" }}
                    onClick={this.handleNewClick}>
                    <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add}
                  </Button>
                  <Button color="info" size="sm" style={{ marginLeft: "20px" }}
                    onClick={this.handleFinalClick}>
                    <Check style={{ margin: "0px", marginRight: "5px" }} /> {strings.finish}
                  </Button>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody plain>
            <GridContainer style={{ marginTop: "-50px" }}>
              <GridItem xs={12} md={12} sm={12} lg={12}>
                <Timeline
                  stories={this.state.timeLineList}
                  setClick={click => this.clickChild = click}
                  finalClick ={click => this.clickFinal = click} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        {this.state.alert}
      </div>

    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFarms: () => dispatch({ type: "GET_EMPLOYEE_LIST" }),
    createProcess: (data) => dispatch({ type: "CREATE_PROCESS", data: data })
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    employeeList: state.employeeList
  }
}
export default withStyles(style)(withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoProduct)));
