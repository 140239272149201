/*eslint-disable*/
import React from "react";
import cookie from 'react-cookies';
import axios from 'axios';
import Select from 'react-select';
import Datetime from "react-datetime";
import moment from "moment";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { withRouter } from 'react-router-dom';
// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import { withStyles } from "@material-ui/styles";
import Detail from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { style } from "@material-ui/system";
import { connect } from 'react-redux';
import {
    AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend
} from 'recharts';
import { LINK_API } from 'constants/API';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';


class Charts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            harvestStatistic: "",
            branchStatistic: [],
            selectedObject: null,
            valueSelectObject: null,
            valueSelect_SLTH: "",
            valueSelect_DTH: "",
            valueSelect_MVTH: "",
            data_MVTH: [],
            data_DTH: [],
            data_SL_Stamp: [],
            data_HDTH: [],
            minMV: 0,
            maxMV: 5,
            sumMV: 0,
            minDTH: 0,
            maxDTH: 5,
            sumCrop: 0,
            minSL: 0,
            maxSL: 5,
            sumSL: 0,
            minHD: 0,
            maxHD: 5,
            sumHD: 0,
            options: [],
        }
        this.options = [
        ];
        this.options_ThuHoach = [{ value: "DAY", label: "Ngày" }, { value: "WEEK", label: "Tuần" }, { value: "MONTH", label: "Tháng" }, { value: "YEAR", label: "Năm" }]
        this.checkPermission = this.checkPermission.bind(this);
        this.token = cookie.load('token');
        this.handleChange_x = this.handleChange_x.bind(this);
        this.handleDataCrop = this.handleDataCrop.bind(this);
        this.handleDataSL = this.handleDataSL.bind(this);
        // this.handleDataMV = this.handleDataMV.bind(this);
        this.handleMinMax = this.handleMinMax.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);
        this.handleData = this.handleData.bind(this);

    }

    handleDateChange = (date) => {
        this.setState({ startDate: date })
        // console.log(moment(date).format("DD/MM/YYYY"));
    }
    handleDateEndChange = (date) => {
        this.setState({ endDate: date })
    }
    handleMinMax(valueMin, valueMax, data, attr) {

        const resMax = data.reduce((max, item) => {
            return max > item[attr] ? max : item[attr]
        }, 0)

        const resMin = data.reduce((min, item) => {
            return min < item[attr] ? min : item[attr]
        }, 999999999999)

        this.setState({
            [valueMax]: resMax + 1,
            [valueMin]: resMin - 1
        })

    }

    handleChange_x = (name) => async event => {
        await this.setState({ [name]: event.value })
    };

    async handleData(event) {

        let response = "";
        let body = "";
        // if (this.state.valueSelect_SLTH === "DAY")
        body = "&cycle=DAY&startTime=" + moment(this.state.startDate).format("MM/DD/YYYY") + "&endTime=" + moment(this.state.endDate).format("MM/DD/YYYY");
        // else if (this.state.valueSelect_SLTH === "WEEK")
        //     body = "&cycle=WEEK";
        // else if (this.state.valueSelect_SLTH === "MONTH")
        //     body = "&cycle=MONTH";
        // else if (this.state.valueSelect_SLTH === "YEAR")
        //     body = "&cycle=YEAR";

        if (this.state.valueSelect_SLTH && this.state.valueSelectObject) {
            response = await axios.get(LINK_API.GET_PRODUCT_TYPE_STATISTIC + "?uuidOfProductType=" + this.state.valueSelectObject + body, {
                headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
            });
            if (response.data.errorCode == 1) {
                if (this.state.valueSelect_SLTH === "YEAR") {
                    await this.handleDataSL(response.data.data.numberActivatedStamp, "YEAR", "sumSL");
                    await this.handleDataCrop(response.data.data.numberCrop, "YEAR", "sumCrop");
                }
                else if (this.state.valueSelect_SLTH === "WEEK") {
                    await this.handleDataSL(response.data.data.numberActivatedStamp, "WEEK", "sumSL");
                    await this.handleDataCrop(response.data.data.numberCrop, "WEEK", "sumCrop");
                }
                else if (this.state.valueSelect_SLTH === "MONTH") {
                    await this.handleDataSL(response.data.data.numberActivatedStamp, "MONTH", "sumSL");
                    await this.handleDataCrop(response.data.data.numberCrop, "WEEK", "sumCrop");
                }
                else {
                    await this.handleDataSL(response.data.data.numberActivatedStamp, "DAY", "sumSL");
                    await this.handleDataCrop(response.data.data.numberCrop, "DAY", "sumCrop");
                }


                // await this.handleDataMV(response.data.data.crop)
                this.setState({ sumHD: response.data.data.numberDiary })
            }
            else {

            }
        }

    }



    async handleDataSL(data, type, sumType) {
        let sum = 0;
        const renderData = await data.reduceRight((retList, item) => {
            let name = "";
            if (type === "DAY")
                name = item.day + "/" + item.month;
            else if (type === "WEEK")
                name = "Thứ " + item.day;
            else if (type === "MONTH")
                name = item.month;
            else if (type == "YEAR")
                name = "Năm " + item.year;

            sum = sum + item.count;
            return [
                ...retList,
                { name, sl: item.count }
            ]
        }, []);
        this.setState({
            data_SL_Stamp: renderData,
            [sumType]: sum
        });
        await this.handleMinMax("minSL", "maxSL", renderData, "sl")
    }



    async handleDataCrop(data, type, sumType) {
        let sum = 0;
        const renderData = await data.reduceRight((retList, item) => {
            let name = "";
            if (type === "DAY")
                name = item.day + "/" + item.month;
            else if (type === "WEEK")
                name = "Thứ " + item.day;
            else if (type === "MONTH")
                name = item.month;
            else if (type == "YEAR")
                name = item.year;

            sum = sum + item.count;
            return [
                ...retList,
                { name, dth: item.count }
            ]
        }, []);
        this.setState({
            data_DTH: renderData,
            [sumType]: sum
        });
        await this.handleMinMax("minDTH", "maxDTH", renderData, "dth")
    }



    handleChange_DTH = (name) => async event => {
        await this.setState({ [name]: event.value })
    };

    async componentDidMount() {
        await this.checkPermission();
        this.props.getProductTypeList();
    }

    componentWillReceiveProps(nextProp){
        if(nextProp.productList !== this.props.productList){
            // console.log(nextProp.productList)
            const ListProductType = nextProp.productList.map(item => {
                                    return { value: item.uuid, label: item.name }
                                })
            this.setState({ options: ListProductType })
        }
    }

    async checkPermission() {
        try {
            let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
            if (response.data.errorCode !== 1 || !response.data.data.valid) {
                await cookie.remove('token')
                return this.props.history.push("/");
            }
            else {
                axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
            }
        }
        catch (error) {
            console.log(error);
            await cookie.remove('token');
            return this.props.history.push("/");
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div style={{ marginTop: "22px" }}>
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <div style={{ boder: "1px solid black !important" }}>
                            <Select
                                onChange={this.handleChange_x("valueSelectObject")}
                                options={this.state.options}
                                className={"selectBoxStatistic"}
                                placeholder="Chọn loại sản phẩm"
                            />
                        </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                        <div style={{ boder: "1px solid black !important" }}>
                            <Select
                                onChange={this.handleChange_x("valueSelect_SLTH")}
                                options={this.options_ThuHoach}
                                className={"selectBoxStatistic"}
                                placeholder="Chu kỳ"
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div style={{ marginTop: "-17px" }}>
                                <div style={{ fontSize: 11, marginBottom: 13 }}>Ngày bắt đầu</div>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={this.state.startDate}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="stylePickTime"
                                    // style={{ backgroundColor: "white", marginTop: "-11px", paddingTop: 5 }}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div style={{ marginTop: "-17px" }}>
                                <div style={{ fontSize: 11, marginBottom: 13 }}>Ngày kết thúc</div>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={this.state.endDate}
                                    onChange={this.handleDateEndChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className="stylePickTime"
                                    // style={{ backgroundColor: "white", marginTop: "-11px", paddingTop: 5 }}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                        <Button color="success" size="sm" id="save" onClick={this.handleData}>
                            Xem
                        </Button>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <div style={{ zIndex: "0 !important" }}>
                                <CardHeader >
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} md={6}>
                                            <h4 className="cardtableLabel">{strings.cropYieldsheader}</h4>
                                            <p className={classes.cardCategory}>Tổng: {this.state.sumSL}</p>
                                        </GridItem>
                                        {/* <GridItem xs={6} sm={6} md={6}>
                                            <div style={{ boder: "1px solid black" }}>
                                                <Select
                                                    // value={this.state.selectedObject}
                                                    onChange={this.handleChange_SLTH("valueSelect_SLTH", "sumSL")}
                                                    options={this.options_ThuHoach}
                                                    className={classes.SelectDropx}
                                                    placeholder="Chu kỳ"
                                                />
                                            </div>
                                        </GridItem> */}
                                    </GridContainer>
                                </CardHeader>
                            </div>
                            <CardBody>
                                <ResponsiveContainer height={300}>
                                    <LineChart data={this.state.data_SL_Stamp} margin={{
                                        top: 0, right: 0, left: -30, bottom: 0,
                                    }}>
                                        <XAxis dataKey="name" />
                                        <YAxis orientation={'left'} domain={[this.state.minSL, this.state.maxSL]} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="sl" stroke="#4ce1b6" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <div style={{ zIndex: "0 !important" }}>
                                <CardHeader >
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} md={6}>
                                            <h4 className="cardtableLabel">Đợt thu hoạch</h4>
                                            <p className={classes.cardCategory}>Tổng: {this.state.sumCrop} đợt</p>
                                        </GridItem>
                                        {/* <GridItem xs={6} sm={6} md={6}>
                                            <div style={{ boder: "1px solid black" }}>
                                                <Select
                                                    // value={this.state.selectedObject}
                                                    onChange={this.handleChange_DTH("selected_DTH")}
                                                    options={this.options_ThuHoach}
                                                    className={classes.SelectDropx}
                                                    placeholder="Chu kỳ"
                                                />
                                            </div>
                                        </GridItem> */}
                                    </GridContainer>
                                </CardHeader>
                            </div>
                            <CardBody>
                                <ResponsiveContainer height={300}>
                                    <LineChart data={this.state.data_DTH} margin={{
                                        top: 0, right: 0, left: -30, bottom: 0,
                                    }}>
                                        <XAxis dataKey="name" />
                                        <YAxis orientation={'left'} domain={[this.state.minDTH, this.state.maxDTH]} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="dth" stroke="#4ce1b6" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <Card>
                            <div style={{ zIndex: "0 !important" }}>
                                <CardHeader >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h4 className="cardtableLabel">Mùa vụ</h4>
                                            <p className={classes.cardCategory}>Tổng: {this.state.sumMV} mùa vụ</p>
                                        </GridItem>
                                        {/* <GridItem xs={6} sm={6} md={6}>
                                            <div style={{ boder: "1px solid black" }}>
                                                <Select
                                                    // value={this.state.selectedObject}
                                                    onChange={this.handleChange_MVTH("valueSelect_MVTH", "sumMV")}
                                                    options={this.options_ThuHoach}
                                                    className={classes.SelectDropx}
                                                    placeholder="Chu kỳ"
                                                />
                                            </div>
                                        </GridItem> */}
                                    </GridContainer>
                                </CardHeader>
                            </div>
                            <CardBody>
                                <div style={{ marginLeft: 11 }}>
                                    {this.state.data_MVTH}
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <Card>
                            <div style={{ zIndex: "0 !important" }}>
                                <CardHeader>
                                    <GridContainer>
                                        <GridItem lg={12} md={12} sm={12} xs={12}>
                                            <h4 className="cardtableLabel">Hành động đã ghi nhận</h4>
                                            <p className={classes.cardCategory}>Số lượng: {this.state.sumHD} hành động</p>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                            </div>
                            <CardBody>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getProductTypeList: () => dispatch({ type: "GET_PRODUCT_LIST" }),
        getActiveStampProductType: () => dispatch({ type: "GET_ACTIVE_STAMP_PRODUCT_TYPE" }),
        getNumberCropProductType: () => dispatch({ type: "GET_NUMBER_CROP_PRODUCT_TYPE" }),
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        objectList: state.objectList,
        productList: state.productType.data
    }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Charts)));