import React from "react";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "../input/CustomInput.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import InputUploadImage from '../ImageUpload/InputUploadImage';

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};


class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      icon: "",
      attribute: "",
      description: "",
      authority: "",
      productNameHelp:"",
    };
    this.onImageSubmit = this.onImageSubmit.bind(this);
    
  }

  componentDidMount(){
    if(this.props.data.raw) this.setState({
      step: this.props.data.raw.step || '',
      step_desc: this.props.data.raw.step_desc || '',
      actions_image : this.props.data.raw.actions_image || '',
    })
  }

  componentDidUpdate(preProps){
    if(preProps.data !== this.props.data){
      this.setState({
        step: this.props.data.raw.step || '',
        step_desc: this.props.data.raw.step_desc || '',
        actions_image : this.props.data.raw.actions_image || '',
      })
    }
  }

  sendState() {
    return this.state;
  }

  handleChange = name => event => {
    //console.log(name);
    if(name==="productName"){
      this.setState({
        productNameHelp: "",
        productNameState:"success",
      })
    }
    if(name==="productExpired"){
      this.setState({
        productExpiredHelp: "",
        productExpiredState:"success",
      })
    }
    if(name==="productDesc"){
      this.setState({
        productDescHelp: "",
        productDescState:"success",
      })
    }
      this.setState({ [name]: event.target.value})
      this.props.data.func(name, event.target.value, this.props.id);
  };

  // function that returns true if value is email, false otherwise

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  handlenamechange = event => {
    if (event.target.value === "-1") {
      this.setState({
        //nameState: "error",
        name: "",
        //nameHelp: "Thiếu tên bước"
      });
    }
    else {
      this.setState({
        //nameState: "success",
        name: event.target.value
      });
      

    }
  };

  handleiconchange = event => {
    if (event.target.value === "-1") {
      this.setState({
        //iconState: "error",
        icon: "",
        //iconHelp: "Thiếu tên icon"
      });
    }
    else {
      this.setState({
        //iconState: "success",
        icon: event.target.value
      });
      
    }
  };
  handleattchange = event => {
    if (event.target.value === "-1") {
      this.setState({
        //attributesState: "error",
        attribute: "",
       // attributesHelp: "Thiếu thuộc tính"
      });
    }
    else {
      this.setState({
       // attributesState: "success",
       attribute: event.target.value
      });
      
    }
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onImageSubmit(file) {
    if (file != null) {
      this.setState({
        image: file
      })
      this.props.data.func('actions_image',file,this.props.id);
    }
  }

  render() {
    const { classes } = this.props;
    return (
        <div style={{marginTop: 20}}>
        <CustomInput
            labelText="Tên bước"
            success={this.state.productNameState === "success"}
            error={this.state.productNameState === "error"}
            id="step"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('step')
            }}
            inputProps={{
              value: this.state.step,
            }}
            helpText={this.state.productNameHelp}
        />
        <CustomInput
            success={this.state.productDescState === "success"}
            error={this.state.productDescState === "error"}
            labelText="Mô tả"
            id="step_desc"
            formControlProps={{
            fullWidth: true,
            onChange: this.handleChange('step_desc'),
            }}
            inputProps={{
            multiline: true,
            value: this.state.step_desc,
            }}
            helpText={this.state.productNameHelp}
        />
        <InputUploadImage
          // onImageSubmit={(file)=>this.props.onImageSubmit(file)}
          onImageSubmit={this.onImageSubmit.bind(this)}
          addButtonProps={{
            color: "success"
          }}
          changeButtonProps={{
            color: "success"
          }}
        />
    </div>
    );
  }
}

export default withStyles(style)(Step1);
