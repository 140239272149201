import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import readXlsxFile from 'read-excel-file'
// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Print from "@material-ui/icons/Print"
import GetApp from "@material-ui/icons/GetApp"
import Add from "@material-ui/icons/Add";
import FindInPage from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Close from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import {API,IMG_LOAD, ADDRESS} from 'constants/index.js';
import {LINK_API} from '../../../../constants/API'; 
import QRCode from 'qrcode.react';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import picture from "assets/img/pictureicon.png";
import {tooltip} from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import TreePrintTemplate from "./TreePrintTemplate"
import { Keccak } from 'sha3';
import htmlToImage from 'html-to-image';
import { connect } from 'react-redux';
import LogoVBC from "assets/img/logo_vbc_black.png"
import LogoAGD from "assets/img/bg-img/agd.png"
import Gallery from '../../components/Forms/Gallery/Gallery';
import Table from "components/Table/Table.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import ImageActions from "../ProcessPage/ImageActions.jsx"
import strings from "../../../../constants/strings";
import FileUpload from "./components/readfile.jsx";
import TimeLine from "./TimlineCattle"
const RLP = require('rlp');
const CryptoJS = require("crypto-js");

const EthereumTx = require('ethereumjs-tx').Transaction;
const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider(`http://206.189.82.191:8000`));

//ABI
const genieIJSON = require("../../../../build/contracts/GenieIdentity.json");
const genieIABI = genieIJSON['abi'];

const genieTJSON = require("../../../../build/contracts/GenieToken.json");
const genieTABI = genieTJSON['abi'];

const styles = {
  ...extendedTablesStyle,
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textTransform: "none",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  }
};

class TableFarmList extends React.Component {
   constructor(props) {
    super(props);
    this.activeStamp = this.activeStamp.bind(this);
    this.handleImportData = this.handleImportData.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.handleSelectProduct = this.handleSelectProduct.bind(this);
    this.handleEditProduct = this.handleEditProduct.bind(this);
    this.handleSelectProcess = this.handleSelectProcess.bind(this);
    this.handleEditProcess = this.handleEditProcess.bind(this);
    this.handleEditArea = this.handleEditArea.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showConfirmAlert = this.showConfirmAlert.bind(this);
    this.genTable = this.genTable.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.getArea = this.getArea.bind(this);
    this.getObjects = this.getObjects.bind(this)
    this.getProcess = this.getProcess.bind(this)
    this.getProductype = this.getProductype.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleContent = this.handleContent.bind(this);
    this.handleCloseedit = this.handleCloseedit.bind(this);
    this.submitData_crop = this.submitData_crop.bind(this);
    this.generateContractAddresswithNonce = this.generateContractAddresswithNonce.bind(this)
    this.downloadQR = this.downloadQR.bind(this)
    this.handleEditObject = this.handleEditObject.bind(this);
    this.hideAlert_update = this.hideAlert_update.bind(this);
    this.handleEditImage = this.handleEditImage.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmitImage = this.handleSubmitImage.bind(this);
    this.handleSelectIdType = this.handleSelectIdType.bind(this);
    this.createHarvest = this.createHarvest.bind(this);
    this.handleDataInput = this.handleDataInput.bind(this);
    this.creatRawActiveStamp = this.creatRawActiveStamp.bind(this);

    this.state = {
      alert:null,
      checked:false,
      value:{
        gln_extend:'',
        passphrase:'',
        data:'',
      },
      farmList: [],
      data: [],
      load:"",
      showTable:"none",
      activeState: true,
      data_detail: [],
      name: "",
      nameProduct: "",
      processcode: "",
      status: "",
      image: "",
      editImage: false,
      uuid: "...",
      statusList: ["Đang sản xuất", "Ngưng sản xuất"],
      typeImport: ["AGD", "GS1", "RFID"],
      productList: [],
      processList: [],
      areaList: [],
      typeProduct: "",
      typeProcess: "",
      crop_status: "",
      area: "",
      area_code: "",
      valueProduct: "",
      valueProcess: "",
      disable: true,
      startTime: new Date(),
      openCreateSeason: openCreateSeason,
      changeImageSrc: "",
      harvestId: "",
      timeLineList: []
      
    };
    const queryParams = new URLSearchParams(this.props.location.search);
    const openCreateSeason = (queryParams.get('showCreateDialog') === "true") ? true : false;
    this.objects_gln = cookie.load("IDCattle");
    this.statusCaltle = cookie.load("statusCalle");
    this.shipmentCattle = cookie.load("shipmentCattle");
    this.RFID = cookie.load("RFIDCattle");
    // var today = new Date();
    // console.log(today);
  }
  creatRawActiveStamp = async (
    code,
    from,
    to,
    uri = '',
    contractAddress,
    genieTokenAddress,
    nonce,
  ) => {
      const genieTokenDeployed = new web3.eth.Contract(
        genieTABI,
        genieTokenAddress,
      );
      const data = genieTokenDeployed.methods
        .Non_FungibleAsset_NewNon_FungibleProductBatchCreated(
          code,
          from,
          to,
          uri,
          contractAddress,
        )
        .encodeABI();

      const txParam = {
        from: this.props.userInfo.data.bcAddress,
        nonce,
        gasPrice: 0,
        gasLimit: 8000000,
        to: genieTokenAddress,
        data,
      };
      const tx = await new EthereumTx(txParam, {chain: 4});
      // const tx = {};
      await tx.sign(this.state.Buffer_privatekey);
      const rawTx = await '0x' + tx.serialize().toString('hex');
      return rawTx;
  
  };
 async  handleDataInput(list, name){
    await this.setState({
      listRFID: list,
      filename: name
    })
    document.getElementById("fileUpload").style="display: none;"
    document.getElementById("fileName").style="display: block;width:100%;"
  }
  async activeStamp () {
    const data = {
    "type": this.state.idType,
		"idOfObject": this.state.harvestId,
		"listId": this.state.listRFID
    }
    axios.post(LINK_API.GET_INFO_ACTIVE_STAMP, data)
    .then(async response=>{
      if(response.data.errorCode === 1){
        const {
          productTypeId,
          contractAddress,
          genieToken,
          nonce,
          uri,
        } = response.data.data;
        const {startId, endId} = response.data.data.stampUnitNonGs1;
        const raw = await this.creatRawActiveStamp(
          productTypeId,
          startId,
          endId,
          '',
          contractAddress,
          genieToken,
          nonce,
        );
        let data_send_stamp = {};
        const rawBox = await this.creatRawActiveStamp(
          productTypeId,
          response.data.data.boxId,
          response.data.data.boxId,
          uri,
          contractAddress,
          genieToken,
          nonce + 1,
        );
        console.log("raw", raw)
        console.log("rawBox",rawBox)
        data_send_stamp = {
          ...response.data.data,
          raw,
          rawBox,
          sign: 'a',
        };
        axios.post(LINK_API.ACTIVE_STAMP_COW, data_send_stamp)
        .then(result => {
          if(result.data.errorCode === 1){
              this.setState({
                alert: (
                  <SweetAlert
                    success
                    style={{ display: 'block' }}
                    title="Thành công"
                    onConfirm={() => this.hideAlert_update()}
                    showCancel={false}
                  />
                )
              })
          }else{
            this.setState({
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block"}}
                  title="Không thành công"
                  onConfirm={() => this.hideAlert_update()}
                  onCancel={() => this.hideAlert_update()}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                >
                </SweetAlert>
              )
            });
          }
        })
        .catch(err => {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block"}}
                title="Không thành công"
                onConfirm={() => this.hideAlert_update()}
                onCancel={() => this.hideAlert_update()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
              </SweetAlert>
            )
          });
        })
      }
    })
  }
  async createHarvest(){
    const data = {
      "processCode": this.state.valueProcess,
      "toId": this.objects_gln,
      "step": 0,
      "moreInfo": {},
      "detail": "",
      "images": []
    }
    axios.post(LINK_API.GET_INFO_HARVEST, data)
    .then(async res=> {
      if(res.data.errorCode === 1){
        var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privatekey = bytes.toString(CryptoJS.enc.Utf8);
    const privatekey_slice = privatekey.substring(2, privatekey.length)
    const to = res.data.data.identitierAddress
    const contractAddress = res.data.data.identifierAddress
    //blockchain verify account
    const hashedDataToSign = await web3.utils.soliditySha3(
      res.data.data.identitierAddress,
      res.data.data.productName ? res.data.data.productName : "",
      1,
      res.data.data.action,
      res.data.data.fromId,
      res.data.data.toId,
      res.data.data.detail,
      res.data.data.date,
    );
    console.log("hashData", hashedDataToSign)
    var signature = await web3.eth.accounts.sign(
      hashedDataToSign,
      privatekey, // privateKey of Employee
    );

    const GenieTokenDeployed = new web3.eth.Contract(genieTABI, res.data.data.genieToken);
    const data = await GenieTokenDeployed.methods.Non_FungibleAsset_ActionToDiary(
      res.data.data.tokenId,
      res.data.data.identitierAddress,
      res.data.data.productName? res.data.data.productName : "",
      1,
      signature.signature,
      hashedDataToSign,
      res.data.data.action,
      res.data.data.fromId,
      res.data.data.toId,
      res.data.data.detail,
      res.data.data.date,
      res.data.data.contractAddress,
    ).encodeABI();
    const txParam = {
      from: this.props.userInfo.data.bcAddress,
      nonce: res.data.data.nonce,
      gasPrice: 0, // web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
      gasLimit: 8000000,
      to: res.data.data.genieIdentity,
      data: data,
    };
    console.log("txParam", txParam)
    const Buffer_privatekey = await Buffer.from(privatekey_slice.toString(), 'hex');
    await this.setState({
      Buffer_privatekey: Buffer_privatekey
    })
    const tx = new EthereumTx(txParam, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    console.log("RawTX", rawTx);
    const data_send ={ 
      "tokenId": res.data.data.tokenId ,
      "action": res.data.data.action,
      "productName": res.data.data.productName,
      "fromId": res.data.data.fromId,
      "toId": res.data.data.toId,
      "processCode": res.data.data.processCode,
      "step": res.data.data.step,
      "detail": res.data.data.detail,
      "date": res.data.data.date,
      "nonce": res.data.data.nonce,
      "genieToken": res.data.data.genieToken,
      "genieIdentity": res.data.data.genieIdentity,
      "identitierAddress": res.data.data.identitierAddress,
      "accountAddress": res.data.data.accountAddress,
      "contractAddress": res.data.data.contractAddress,
      "_cetegories": 1,
  "raw": rawTx,
  "sign": "signedData"
    }
    axios.post(LINK_API.CREATE_HARVEST, data_send)
    .then(async result => {
      if(result.data.errorCode === 1){
        await this.setState(
          {
            harvestId: result.data.data.moreInfo.harvestId,
          }
        )
        this.activeStamp();
      }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert_update()}
              onCancel={() => this.hideAlert_update()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    })
    .catch(err => {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert_update()}
            onCancel={() => this.hideAlert_update()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
    })
      }
    })
  }
  async handleImportData(){
    if(!this.state.lnc){
      document.getElementById("noti_import").innerHTML = "Vui lòng nhập mã LNC";
      return;
    }
    if(!this.state.idType){
      document.getElementById("noti_import").innerHTML = "Vui lòng chọn loại id";
      return
    }
    if(!this.state.filename){
      document.getElementById("noti_import").innerHTML = "Vui lòng tải file lên hệ thống";
      return
    }
    this.setState({
      openCreateSeason:false,
      alert: (
        <SweetAlert
          warning
          style={{ display: "block"}}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{textAlign:"center"}}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{height:"100px"}}
            />
          </div>
        </SweetAlert>
      )
     })
    await this.onCrop(this.state.startTime,this.state.valueProduct,this.state.valueProcess);

  }
  handleSubmitImage(){
    const data ={
      uuid: this.state.uuid,
      image: this.state.changeImageSrc[0]
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block"}}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{textAlign:"center"}}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{height:"100px"}}
            />
          </div>
        </SweetAlert>
      )
     })
     axios.post(LINK_API.UPDATE_PICTURE_OBJECT, data)
     .then (res => {
       if(res.data.errorCode === 1){
        this.setState(
          {
          editImage: false,
           alert: (
             <SweetAlert
               success
               style={{ display: 'block' }}
               title="Thành công"
               onConfirm={() => this.hideAlert_update()}
               showCancel={false}
             />
           )
          }
        )
        document.getElementById("showImage").style= "display: block; height: 216px;";
        this.props.getObjectDetail(this.objects_gln);
       }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert_update()}
              onCancel={() => this.hideAlert_update()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
       }
     })
     .catch(err=> {
       console.log(err);
       this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert_update()}
            onCancel={() => this.hideAlert_update()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
     })
  }
  handleEditImage(){
    this.setState({
      editImage: true
    })
    document.getElementById("showImage").style="display: none;"
  }
  handleImageChange(file){
    this.setState({
      changeImageSrc: file
    })
  }
  showConfirmAlert(data) {
    this.handleClose();
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          // onConfirm={() => this.onCrop(tree,product,process)}
          onConfirm={() => {}}
          onCancel={() => {
            this.hideAlert();
            // this.setState({ openCreateFarm: true });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          {/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
        </SweetAlert>,
      dataSubmit: data
    })
  }
  handleClose() {
    this.setState({ openCreateSeason: false });
  }
  async  handleSelectProduct(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      valueProduct_temp: event.target.value,
      valueProduct: temp[temp.length - 1]
    })
   }

   async  handleEditProduct(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      product: event.target.value,
      valueProduct: temp[temp.length - 1]
    })
   }
   async handleSelectIdType(event){
     document.getElementById("noti_import").innerHTML = "";
    await this.setState({
      idType: event.target.value
    })
    console.log("Type of id", this.state.idType)
   }
   async  handleSelectProcess(event) {
    var temp = event.target.value.split(" ");
    await this.setState({
      valueProcess_temp: event.target.value,
      valueProcess: temp[temp.length - 1]
    })
   }

   async  handleEditProcess(event) {
    var temp = event.target.value.split(" ");
    //console.log(temp)
    await this.setState({
      // valueProcess_temp: event.target.value,
      valueProcess: temp[temp.length - 1],
      valueProcess_temp: event.target.value
    })
   }

   async  handleEditArea(event) {
    var temp = event.target.value.split(" ");
    //console.log(temp)
    await this.setState({
      area: event.target.value,
      valueArea: temp[temp.length - 1]
    })
   }
   handleEditObject() {
     this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block"}}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{textAlign:"center"}}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{height:"100px"}}
            />
          </div>
        </SweetAlert>
      )
     })
     let data_edit = {
      uuid: this.state.uuid, // id of production object/processing
      name: {
           vi: this.state.name // en: 'ten tieng anh'
      },
      processCode: this.state.valueProcess,
      productType: this.state.valueProduct,
     // gln: "string gi do",
      zoneId: this.state.valueArea,
     }
     axios.post(LINK_API.UPDATE_OBJECT_PRODUCTION, data_edit)
     .then(response => {
       if(response.data.errorCode === 1){
        // this.handleCloseedit();
         this.setState(
           {
            alert: (
              <SweetAlert
                success
                style={{ display: 'block' }}
                title="Thành công"
                onConfirm={() => this.hideAlert_update()}
                showCancel={false}
              />
            )
           }
         )
       }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert_update()}
              onCancel={() => this.hideAlert_update()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
       }
     })
     .catch(() => {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert_update()}
            onCancel={() => this.hideAlert_update()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
     })
   }

  handleChangetime = name => async moment => {

    await this.setState({
      [name]: moment._d,
    });
  };

  handleEdit = name => event => {
    this.setState({ [name]: event.target.value });
  }

  async handleContent() {
    // await this.setState({
    //   changePw: true
    // });
    if (document.getElementById("buttonedit").innerText === "CẬP NHẬT") {
      this.handleEditObject();
    } else {
      document.getElementById("info2").style = "display: none;"
      document.getElementById("infoedit2").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("buttonedit").innerHTML = "Cập nhật";
      document.getElementById("buttoncancle").style = "display: block; width: 100%;";
    }
  }

  handleCloseedit() {
    document.getElementById("info2").style = "display: block; width: 100%"
    document.getElementById("infoedit2").style = "display: none;"
    document.getElementById("buttonedit").innerHTML = "Chỉnh sửa";
    document.getElementById("buttoncancle").style = "display:none";
    this.updateinfo(this.props.objectDetail.data)
  }

  renderTrigger() {
    return <Button size="sm" color="success" onClick={() => {
      document.getElementById("printobject").style = "display: block"
    }}> <Print />{strings.printQR}</Button>;
  }

  renderContent = () => {
    return this.componentRef
  }
  setRef = (ref) => {
    this.componentRef = ref;
  }
  generateContractAddresswithNonce = async (creator, creator_nonce) => {
    const response = await '0x' + Keccak(256).update(RLP.encode([creator, web3.utils.toHex(creator_nonce)])).digest('hex').substring(24); 
    //console.log(creator_nonce, '-------------------response--------------', response);
    return response;
  };
  handleDisable(){
    this.setState({
      alert: (
        <SweetAlert
        warning
        style={{ display: "block", marginTop: "-200px" }}
        title="Có mùa vụ đang sản xuất. Không thể tạo mùa vụ mới!"
        onCancel={() => this.hideAlert()}
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        cancelBtnText="Đóng"
        showCancel
      >
      </SweetAlert>
      )
    })
  }
  async handleCrop(){
    this.setState({openCreateSeason: true})
    this.hideAlert();
  }
  handleInputDate(tree,product,process){
    const {classes} = this.props
    this.setState({
      alert: (
        <SweetAlert
        //className= {this.props.classes.UnsetOverflow}
        warning
        style={{ display: "block", overflow: "unset" }}
        title="Nhập thông tin mùa vụ"
        onConfirm={() => this.onCrop(tree,product,process)}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        confirmBtnText="Tạo mới"
        cancelBtnText="Đóng"
        showCancel
      >
        <Card className="noShadow" style={{marginTop: "-8px"}}>
            <CardBody>
              {/* <InputLabel className={classes.label}>
                Datetime Picker
              </InputLabel> */}
              <br />
              <FormControl fullWidth className={classes.selectFormControl}>
                <Datetime
                  value= {this.state.startTime}
                  className="InputDatetime"
                  formControlProps={{
                    onChange: this.handleChangetime('startTime'),
                  }}
                  inputProps={{ placeholder: "Ngày bắt đầu", title: "Ngày bắt đầu",className: "InputDatetime" }}
                  onChange= {this.handleChangetime('startTime')}
                />
                <hr className="LineBreakDesDate"/>
                  <InputLabel
                  style={{zIndex:5600}}
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          Loại sản phẩm
                              </InputLabel>
                        <Select
                        
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.valueProduct_temp}
                          onChange={this.handleSelectProduct}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem>
                            Chọn loại sản phẩm
                        </MenuItem>
                          {this.state.productList.map( (x,idx) => (
                            <MenuItem
                           
                              // classes={{
                              //   root: classes.selectMenuItem,
                              //   selected: classes.selectMenuItemSelected
                              // }}
                              value={x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                  
              </FormControl>
            </CardBody>
          </Card>
        
      </SweetAlert>
      )
    })
  }



    NewKindOfNon_FungibleAssetCreated = async (name,symbol,address0) =>{
      const body = {
        functionName: "NewKindOfNon_FungibleAssetCreated",
        Option: {
        "name"      : name,       
        "symbol"    : symbol, 
        "address"  : address0
        }
      }
    
      let response = "";
      try{
        response = await axios.post(API.LINK_API.GET_INFO_WEB3, body)
        const data = response.data.data.data;
        return data;
      } catch {
        console.log("ERROR");
        return null;
      }
    
      
      }


      Non_FungibleAsset_NewNon_FungibleProductCreated = async (address_creator) =>{
        const body = {
          functionName: "Non_FungibleAsset_NewNon_FungibleProductCreated",
          Option: {
          "address_creator"      : address_creator,       
          }
        }
      
        let response = "";
        try{
          response = await axios.post(API.LINK_API.GET_INFO_WEB3, body)
          const data_creator = response.data.data.data_creator;
          return data_creator;
        } catch {
          console.log("ERROR");
          return null
        }
      
        
        }

  async submitData_crop (process, product) {
    var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privateKey = bytes.toString(CryptoJS.enc.Utf8);
    const privatekey_slice = privateKey.substring(2,privateKey.length);
    const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
    const tx = new EthereumTx(this.state.rawtx1, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    const rawtosend  ={
      "uuidOfProductionObject": this.objects_gln,
      "uuid": this.state.uuid_response,
      "processCode":  process,
      "uuidOfProductType": product,
      "raw": []
    }
    rawtosend.raw.push(rawTx)
            const tx_creator = new EthereumTx(this.state.rawtx2, { chain: 4 });
            tx_creator.sign(Buffer_privatekey);
            const rawTx_creator = '0x' + tx_creator.serialize().toString('hex');
            rawtosend.raw.push(rawTx_creator)


    axios.post(LINK_API.SEND_RAW_CROP, rawtosend)
    .then(async response => {
      if(response.data.errorCode === 1){
        this.createHarvest();
      }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    })
    .catch(err=> {
      console.log(err)
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
    })
  }
  handleChange = (name) => async (event) => {
    document.getElementById("noti_import").innerHTML ="";
		await this.setState({
			[name]: event.target.value
    });
  }
 async  onCrop(tree,product,process){
   console.log(tree);
   console.log(product);
   console.log(process)
//    await this.setState({
//     openCreateSeason: false,
//     alert: (
//       <SweetAlert
//         warning
//         style={{ display: "block"}}
//         title="Đang xử lý!"
//         onConfirm={() => this.hideAlert()}
//         onCancel={() => this.hideAlert()}
//         confirmBtnCssClass={
//           this.props.classes.button + " " + this.props.classes.success
//         }
//         showConfirm={false}
//       >
//         <div style={{textAlign:"center"}}>
//           <img
//             alt="{name}"
//             src={IMG_LOAD}
//             style={{height:"100px"}}
//           />
//         </div>
//       </SweetAlert>
//     )
//   });
// ;
  
    const data = {
      //"startTime": tree,
      "uuidOfProductionObject": this.objects_gln,
      "processCode": process,
      "uuidOfProductType": product,
    }
    axios.post(LINK_API.ASSIGN_CROP, data)
    .then( async response => {
      if(response.data.errorCode === 1){
        await this.setState({
          rawtx1: response.data.data.rawNewKind ,
          rawtx2: response.data.data.rawFungibleAsset,
          nonce: response.data.data.nonce,
          creator_nonce: response.data.data.creator_nonce,
          uuid_response: response.data.data.uuid,
        });
        this.submitData_crop(process, product)
      }
     
    })
    .catch(() => {
      // console.log(err);
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block"}}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
        )
      });
    });
    
  }
  updateinfo = (object_result) => {
    if(object_result.image){
      this.setState({
        image: object_result.image.secure_url
      })
    }
    this.setState({data_detail: object_result})
    this.setState({
      name: object_result.name,
      uuid: object_result.uuid,
      datecreate: moment(object_result.createdAt).format('DD/MM/YYYY hh:mm:ss'),
      processcode:  object_result.nameOfProcess + " - " + object_result.processCode,
      product: object_result.nameOfProduct + " - " + object_result.productType,
      area: object_result.nameOfZone + " - " + object_result.zoneUuid,
      image: object_result.image !== undefined && object_result.image !== "" && object_result.image? object_result.image.secure_url : "",
      status: object_result.status === 1 ? "Đang sản xuất" : "Ngừng sản xuất",
      crop_status: object_result.statusCrop === 1 ? "Đang trong mùa vụ": "Không có mùa vụ",
      valueProcess: object_result.processCode,
      valueProduct: object_result.productType,
      valueArea: object_result.zoneUuid,
      valueProduct_temp: object_result.nameOfProduct + " - " + object_result.productType,
      valueProcess_temp: object_result.nameOfProcess + " - " + object_result.processCode,
      statusCheck: object_result.statusCurrentCrop,
      rfid: object_result.RFID? object_result.RFID : "Không có"
    })
  }
  genTable = async (farmList) => {
    await farmList.reverse();
      this.setState({data: farmList.map((prop, key) => {
       
        if(prop.status === 1){
          this.setState({disable: true})
        }
        return {
        id: key+1,
        time:  moment(prop.date).format("DD/MM/YYYY"),
        actions: prop.name,
        };
      }),
      load:"none",
      showTable:""
    });
  }
  async hideAlert() {
    this.setState({
      alert: null
    });
    let farms = await this.getFarms();
    await this.setState({farmList: farms});
    this.genTable(farms);
  }
  async hideAlert_update() {
    this.setState({
      alert: null
    });
    this.props.getObjectDetail(this.objects_gln);
    this.handleCloseedit();
  }
  getObjects = async () => {
    try {
      let response = await axios.get(LINK_API.GET_A_PRODUCTION + this.objects_gln);
      // console.log(response);
      if (response.data.errorCode == 1) {
        // this.context.log("here");
        // console.log(response.data.data)
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async getProductype(data){
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].name + " - " + data[i].uuid;
          result.push(temp)
        }
        this.setState({
          productList: result
        })
  }
  async getProcess(data){
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].processName + " - " + data[i].bizProcessCode;
          result.push(temp)
        }
        this.setState({
          processList: result
        })
  }

  async getArea (data) {
        var i = 0;
        var result = [];
        for( i = 0 ; i < data.length ; i++) {
          var temp = data[i].name + " - " + data[i].uuid;
          result.push(temp)
        }
        this.setState({
          areaList: result
        })
  }

  stopCrop(gln){
    cookie.save("idimporter", gln, { path: "/" });
    this.props.history.push("/admin/stamp")
  }
  onStop(gln){
    this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block"}}
            title="Đang xử lý!"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
          >
            <div style={{textAlign:"center"}}>
              <img
                alt="{name}"
                src={IMG_LOAD}
                style={{height:"100px"}}
              />
            </div>
          </SweetAlert>
        )
      });
      const data =   {
        "uuidOfProductionObject": gln
      }
      axios.post(LINK_API.STOP_CROP, data)
      .then( async response => {
        if(response.data.errorCode === 1){
          await this.setState({
            statusCheck: 0,
            alert: (
              <SweetAlert
                success
                style={{ display: "block"}}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                showCancel = {false}
              >
              </SweetAlert>
            )
          });
          this.setState({disable: false})
        }
       
      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block"}}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });
  }
  async checkPermission(){
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN,  { headers: {"Authorization" : `Bearer ${this.props.userInfo.data.token}`} });
      if (response.data.errorCode != 1 || !response.data.data.valid ){
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch(error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  getFarms = async ()  => {
    try {
      let response = await axios.get(LINK_API.GET_CROP_LIST + this.objects_gln);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      //   this.props.getProductTypeList();
      //   this.props.getProcessList();
      //   this.props.getArea();
      // this.props.getObjectDetail(this.objects_gln)
      // this.props.getObjectCrop(this.objects_gln)

      let body = 
      axios.get(LINK_API.TRACKING_A_CATTLE + "?gln=" + this.objects_gln + "&lg=vi&nestedgroup=true")
      .then(async res=>{
       if(res.data.data.diary.length > 0){
         if(res.data.data.diary[0].length > 0){
           this.setState({
             timeLineList: res.data.data.diary[0]
           })
         }
       }
      })
      .catch(err=>{
        console.log(err)
      })
    }
    catch (error) {
      console.log(error)
    }
  }
  componentWillReceiveProps(preProps){
    if(preProps.objectDetail !== this.props.objectDetail){
      if(preProps.objectDetail){
        this.updateinfo(preProps.objectDetail.data)
      }
    }
    if(preProps.infoActiveStamp !== this.props.infoActiveStamp){
      if(preProps.infoActiveStamp.data){
        this.genTable(Array.from(preProps.infoActiveStamp.data));
      }
    }
    if(preProps.processList !== this.props.processList){
      if(preProps.processList.data){
        this.getProcess(preProps.processList.data)
      }
    }
    if(preProps.productType !== this.props.productType){
      if(preProps.productType.data){
        this.getProductype(preProps.productType.data)
      }
    }
    if(preProps.productArea !== this.props.productArea){
      if(preProps.productArea.data){
        this.getArea(preProps.productArea.data)
      }
    }
  }
  



  handleNewClick() {
    this.props.history.push("/admin/crops/create")
  }

  makePlaceholderFilter(placeholder) {
    return ({filter, onChange}) => (
        <input type='text'
          placeholder={placeholder}
          style={{
            width: '100%'
          }}
          onChange={event => onChange(event.target.value)} 
          value={filter ? filter.value : ''}
        />
      )
  }

  handleDownload() {
    this.setState({
      alert: <SweetAlert
        style={{ display: "block" }}
        title=""
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        showConfirm={false}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
      >
        <div>
          <h4  className="cardtableLabelprint">In mã</h4>
          <div id="downloadimageobject" style={{display: "flex", justifyContent: "center", flexFlow: "column", backgroundColor: "white", borderRadius: "5px", border: "3px solid grey"}}>
          <div style={{display: "flex", flexFlow: "row", justifyContent: "center", marginTop: "20px"}}>
          <img src={LogoVBC} border="0" style={{width: "135px",}}alt="test" />
          <img src={LogoAGD} border="0" style={{width: "120px",  marginLeft: '10px'}} alt="test" />
          </div>
          <div style={{marginTop: "10px"}}>
          <div style={{ display: "flex", flexFlow: "column"}}>
            <div style={{width: "100%"}}>
            <p className="cardtableLabel_content">Tên đối tượng sản xuất: </p>
            </div>
            <div style={{width: "100%"}}>
           <p className="cardContentPrint">{this.state.name}</p>
           </div>
          </div>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <p className="cardtableLabel_content">Tên loại sản phẩm: </p>
            <p className="cardContentPrint">{this.state.product}</p>
          </div>
          </div>
          <div style={{ display: "flex", flexFlow: "column", justifyContent:"center", alignItems: "center" }}>
          <QRCode id="qrimage" value={"https://portal.agridential.vn/lookup/" + this.props.userInfo.data.uuid + this.state.uuid} size={150} />
          <p style={{marginTop: "10px"}} className="cardContentPrintcode">{this.props.userInfo.data.uuid + this.state.uuid}</p>

          </div>
          </div>
          <Button id="downloadiamge" onClick={this.downloadQR} color="success" size="sm">{strings.download}</Button>
        </div>

      </SweetAlert>
    })
  }
   downloadQR = async() => {
    document.getElementById("downloadiamge").innerHTML = "Đang tải...";
    htmlToImage.toBlob(document.getElementById('downloadimageobject'))
    .then(async (blob)=> {
        await window.saveAs(blob, 'qrstamp.png');
        this.setState({
          alert: null
        })
    });

  };

  render() {
    const { classes } = this.props;
    let dataDetail = this.state.data_detail;
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer >
        <GridItem xs={6} style={{marginTop: "38px"}}>
            <TimeLine dataTimeLine={this.state.timeLineList} />
        </GridItem>
        <GridItem xs={6}>
        <Card style={{height: 400}}>
            <CardHeader color="success" icon>
            <GridContainer>
                <GridItem lg = {12} md = {12} sm = {12} xs = {12}>
                  <h4 className="cardtableLabel">{strings.infoFarmingObject}</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className="paddingDetailObjects">
              <GridContainer>
              <GridItem xs={6}>
            <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.idObject + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info3">
                      <p className="contentProfile">{this.objects_gln}</p>
                    </div>
                  </GridItem>
                  {/* (GLN: {this.objects_gln}) */}
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.rfid + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <p className="contentProfile">{this.RFID}</p>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.shipmentNumber + ":"} </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <p className="contentProfile">{this.shipmentCattle}</p>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="flexRow">
                  <GridItem xs={12} sm={12} md={12} lg={12} className="tagprofile">
                    <p className="tagContentProfile">{strings.status + ":"} </p>
                    
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div id="info2">
                      <p className="contentProfile">{this.statusCaltle}</p>
                    </div>
                    <div id="infoedit2" style={{ marginTop: "-30px", display: "none" }}>
                        <CustomInput
                          labelText=""
                          id="user_name"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleChange('name'),
                            type: "text",
                            value: this.state.name,
                            // disabled: true
                          }}
                          value={this.state.name}
                        />
                      </div>
                  </GridItem>
                  {/* (GLN: {this.objects_gln}) */}
                </GridContainer>
            </GridItem>
            <GridItem xs={6}>
            <div style={{display: "flex", justifyContent:"center"}}>
            <QRCode id="qrimage" value={"https://portal.agridential.vn/lookup/"+ this.objects_gln} size ={180}/>
            </div>
            <div >
              <GridContainer style={{display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "row"}}>
                <p style={{marginTop: "20px", marginBottom: 10}}>{ this.objects_gln} </p>
                <Button style={{marginTop: 7}}href={"https://portal.agridential.vn/lookup/"+ this.objects_gln} target="_blank" justIcon color="success" className="like" simple round onClick={() => {}}>
                  <FindInPage />
                </Button>
              </GridContainer>
            </div>
            <GridItem xs={12} md={12} lg={12} sm={12} style={{display: "flex", flexFlow: "row", justifyContent: "center"}}>
            <ReactToPrint
                                trigger={this.renderTrigger}
                                content={this.renderContent}
                              />
                               <div id ="printobject" style={{ display: "none" }}>
                                {/* {console.log(this.state.data)} */}
                                <TreePrintTemplate data={dataDetail} uuid = {this.props.userInfo.data.uuid + this.state.uuid} ref={this.setRef} />
                              </div>
                <Button
                  onClick={() => this.handleDownload()}
                  color="success"
                  className="like"
                  size="sm"
                >
                  <GetApp /> <a id = "downloadQRCODE">{strings.download}</a>
                </Button>
            </GridItem>
            </GridItem>
              </GridContainer>
            </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      <Dialog
          open={this.state.openCreateSeason}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <Card style={{width: 500}}>
            <CardHeader color="success" icon>
              <h4 className="cardtableLabel">{strings.import}</h4>
            </CardHeader>
            <CardBody> 
            <GridItem xs={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
            <p className="TitleBoldChoosefile" style={{marginTop: "5px"}}>LNC:</p>
            <div style={{width: "100%", marginTop: "-40px"}}>
            <CustomInput
                          labelText=""
                          id="user_name"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleChange('lnc'),
                            type: "text",
                            value: this.state.lnc,
                            // disabled: true
                          }}
                          value={this.state.lnc}
                        />
            </div>
                         <p className="TitleBoldChoosefile" style={{marginTop: "5px"}}>{strings.idType}:</p>
                        <Select
                        
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.idType}
                          onChange={this.handleSelectIdType}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem>
                            {strings.chooseidType}
                        </MenuItem>
                          {this.state.typeImport.map( (x,idx) => (
                            <MenuItem
                           
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                              key={idx}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                        <p className="TitleBoldChoosefile" style={{marginTop: "5px"}}>{strings.noteFile}:</p>
                      <div id="fileName" style={{display:"none"}}>
                      <p className="TitleBoldChoosefile" style={{marginTop: "5px"}}>{this.state.filename}</p>
                      </div>
                      <div id="fileUpload" style={{width: "100%"}}>
                      <FileUpload count={1} onImageSubmit={this.handleDataInput}/>
                      </div>
          
              </FormControl>
            </GridItem>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <p
                  id="noti_import"
                  style={{
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    color: 'red',
                    fontFamily: '"Muli',
                    marginTop: 5,
                    marginBottom: 5,
                  }}>
                </p>
              </div>
            <GridItem xs={12} style={{display: "flex", justifyContent:"center"}}>
              <div>
              {/* <Button  color="success" size="sm" onClick={() => this.onCrop(this.state.startTime,this.state.valueProduct,this.state.valueProcess)}>{strings.inputData}</Button> */}
              <Button  color="success" size="sm" onClick={() => this.handleImportData()}>{strings.inputData}</Button>
              </div>
            </GridItem> 
            </CardBody>
          </Card>
        </Dialog>
      {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getObjectDetail: (data) => dispatch({ type: "GET_OBJECT_DETAIL", uuid: data}),
    getObjectCrop: () => dispatch({type:"GET_STAMP_LIST"}),
    getProcessList: () => dispatch({ type: "GET_PROCESS_LIST"}),
    getProductTypeList: () => dispatch({type: "GET_PRODUCT_LIST"}),
    getArea: () => dispatch({type: "GET_AREA_LIST"}),
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
    objectDetail: state.objectDetail,
    cropObject: state.cropObject,
    infoActiveStamp: state.infoActiveStamp,
    productType: state.productType,
    productArea: state.productArea,
    processList: state.processList}
}
export default withStyles(styles)(withRouter(connect(mapStateToProps,mapDispatchToProps)(TableFarmList)));
