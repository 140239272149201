import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import Header from "./components/Header/Header.jsx";

import dashboardRoutes from "./routes/index.jsx";

import appStyle from "./dashboardStyle.jsx";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import image from "assets/img/bg-2.jpg";
import logo from "assets/icons/logo_agridential_white.png";
import { connect } from 'react-redux';
import { ROLE } from "constants/index.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import Footer from "components/Footer/Footer.jsx";
const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        });
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

var ps;

class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      collapseActive: false,
      role: null
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  async componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);

    //authen
    const role = this.props.userInfo.data.role;
    this.setState({ role: role });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }
  async sidebarMinimize() {
    await this.setState({ miniActive: !this.state.miniActive });
    this.props.checkSidebar(this.state.miniActive)
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  async handleCollapse(){
    await this.setState({
      collapseActive: !this.state.collapseActive
    })
  }
  render() {
    const dashboardRoutesFilter = dashboardRoutes.filter(
      route =>
        route.role == null ||
        (route.role != null && route.role == this.state.role)
    );
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <div style={{height: "60px", position: "fixed", width: "100%", zIndex: "100"}}>
        <Header
            expandAble ={this.state.collapseActive}
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
            style={{marginBottom: 10}}
          />
        </div>
        <div>
        <Sidebar
          collapseFunc ={this.handleCollapse.bind(this)}
          sidebarMinimize={this.sidebarMinimize.bind(this)}
          routes={dashboardRoutesFilter}
          logoText= "Trang quản lý"
          logo={logo}
          //image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="green"
          bgColor="white"
          miniActive={this.state.miniActive}
          {...rest}
        />
        </div>
        
        <div className={mainPanel} ref="mainPanel" >
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content} style={{marginTop: "60px"}}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo}
}
const mapDispatchToProps = (dispatch) => {
  return {
    checkSidebar: (data) => dispatch({ type: "CHECK_STATUS_EXPAND", data: data}),
  }
}
DashBoard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(connect(mapStateToProps, mapDispatchToProps)(DashBoard));
