import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Wizard from "./Wizard.jsx";
import StepProcess from './stepProcess';

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";


const style = {
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    marginLeft: "15px"
  },
  alignLeft: {
    textAlign: "left"
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: "14px",
    fontFamily: "Muli",
    fontWeight: 400,
    lineHeight: 1.42857,
    color: "#AAAAAA !important",
    textTransform: "none",
    marginBottom: "-14px",
    position: "relative",
    textAlign: "left !important",
  },
  select: {
    ...customSelectStyle.select,
    fontSize: "14px",
    textTransform: "none",
    textAlign: "left !important",
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: "none !important"
  }
}

class InfoProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      image: null,
      productName: "",
      productNameState:"",
      productNameHelp:"",
      productDesc: "",
      productDescState:"",
      productDescHelp:"",
      productExpired: "",
      productExpiredState:"",
      productExpiredHelp:"",
      productExpiredType: "",
      steps: [{ stepName: "Bước 1", stepComponent: StepProcess, stepId: 0, data:{func: (x,y,z)=>this.props.getData(x,y,z), raw:''} }],
      destime:["sau khi đóng gói", "sau khi thu hoạch"]
    };
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  async componentDidMount(){
    let data = this.props.processData;
    let f;
    if(data.length){
      f = await Promise.all(data.map((x,i)=>{
        return { 
          stepName: `Bước ${i+1}`,
          stepComponent: StepProcess,
          stepId: i,
          data:{func: (x,y,z)=>this.props.getData(x,y,z), raw: x}
        }
      }));
      this.setState({steps: f});
    } 
  }

  handleSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChange = name => event => {
    //console.log(name);
    if(name==="productName"){
      this.setState({
        productNameHelp: "",
        productNameState:"success",
      })
    }
    if(name==="productExpired"){
      this.setState({
        productExpiredHelp: "",
        productExpiredState:"success",
      })
    }
    if(name==="productDesc"){
      this.setState({
        productDescHelp: "",
        productDescState:"success",
      })
    }
      this.setState({ [name]: event.target.value})
  };

  onImageSubmit(file) {
    if (file != null) {
      this.setState({
        image: file
      })
    }
  }

  handleClick() {
    let k = this.state.steps.length;
    this.state.steps.push({ stepName: `Bước ${k+1}`, stepComponent: StepProcess, stepId: k, data:{func: (x,y,z)=>this.props.getData(x,y,z),} });
    this.setState({steps: this.state.steps});
  }

  render() {
    return (
        <div>
            <Wizard
                steps={this.state.steps}
                title=""
                subtitle=""
                finishButtonClick={e => console.log(e)}
                addButtonText="Thêm bước"
                addButtonClick = {() => this.handleClick()}
                color="success"
                previousButtonText='Quay lại'
                nextButtonText='Tiếp'
            />
        </div>
        
    );
  }
}

export default withStyles(style)(InfoProduct);
