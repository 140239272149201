import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import {withStyles,MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import {FormControl,InputLabel,InputBase,Typography} from "@material-ui/core";

// core components
import Button from "components/CustomButtons/Button.jsx";
import green from '@material-ui/core/colors/green';

import {API,IMG_LOAD} from 'constants/index.js';
import axios from 'axios';

import loginFormStyle from "./loginFormStyle.jsx";
import logo from 'assets/img/bg-img/Logo_with_text_square_green.png';

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: { useNextVariants: true },
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      checkEmail: false,
      mail:'',
      load:false,
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      100
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange = name => async event => {
    await this.setState({
      [name]: event.target.value,
    });
    switch(name){
      case 'mail':
        let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(this.state.mail)) { 
          document.getElementById("mail").style="border-color:red"
          this.setState({checkEmail:false});
        } else {
          document.getElementById("mail").style=""
          this.setState({checkEmail:true});
        }
      break;
    }
  };

  handleSubscribe = () => {
    //check Tên đăng nhập và mật khẩu có hợp lệ trước khi gửi
    if(!this.state.checkEmail) {
      document.getElementById("mail").style="border-color:red";
      return;
    }

    const data = {
      email: this.state.mail
    }
    this.setState({ load: true });

    axios.post(API.LINK_API.SUBSCRIBE, data)
    .then( async response => {
      if(response.data.errorCode===1){
        await this.setState({load:false});
        document.getElementById("dk").innerHTML=`Thành công!`;
        document.getElementById("dk").style="color:green; padding-top:30px;font-size:17px;";
      } else {
        await this.setState({load:false});
        switch(response.data.errorCode){
          default:
           document.getElementById("noti").innerHTML=`Email đã đăng ký! ${response.data.errorCode}`;
        }
      }
    }).catch(async (error) => {
      await this.setState({load:false});
      document.getElementById("dk").innerHTML=`Không thành công!`;
      document.getElementById("dk").style="color:red;padding-top:30px;font-size:17px;";
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {(!this.state.load)?(
        <div id="dk" style={{
          background: 'white',
          padding: '20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        <img src={logo} style={{width:150}} />
        <MuiThemeProvider theme={theme}>
        <Typography variant="subtitle2" gutterBottom style={{marginTop:20, marginBottom:5, textAlign: 'justify'}}>
          Giải pháp Blockchain truy xuất nguồn gốc đang được triển khai cho các doanh nghiệp phù hợp và đã đăng ký trước. Vui lòng để lại email bên dưới và bắt đầu dùng thử giải pháp:
        </Typography>
        <p 
        id="noti"
        style={{
          fontSize:'0.875rem',
          fontWeight:'400',
          color:'green',
          fontFamily:'Muli',
          marginTop:5, 
          marginBottom:5,
        }}>
          
        </p>
        <FormControl style={{width:'100%',marginTop:5, marginBottom:10}}>
          <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
            Địa Chỉ Email
          </InputLabel>
          <InputBase
            id="mail"
            defaultValue=""
            value={this.state.mail}
            placeholder="Nhập địa chỉ Email"
            classes={{
              root: classes.bootstrapRoot,
              input: classes.bootstrapInput,
            }}
            onChange = {this.handleChange('mail')}
          />
        </FormControl>
          
        </MuiThemeProvider>

        
        <Button fullWidth variant="contained" color="success" className={classes.button} onClick={this.handleSubscribe} >
        Dùng thử
      </Button>
      </div>):(<div>
        <img src={IMG_LOAD} style={{height:'100px', marginTop:'40px'}} />
      </div>)}
    </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginFormStyle)(LoginPage);
