import { CHECK_STATUS_STAMP, CHECK_STATUS_STAMP_SUCCESS, CHECK_STATUS_STAMP_FAILURE } from "../actions/types";
const initialState = { data: false};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_STATUS_STAMP:
            return {
                ...state,
            }
        case CHECK_STATUS_STAMP_SUCCESS:
            return {
                ...state,
                data: action.data
            }
        case CHECK_STATUS_STAMP_FAILURE:
            return {
                ...state,
            }
        default:
            return state;
    }
}
export default reducer;