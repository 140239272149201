/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { connect } from 'react-redux';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import PictureUpload from "../DashBoard/components/Forms/PictureUpload.jsx"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import ImageUpload from "../DashBoard/components/ImageUpload/ImagCountNotDelete.jsx"
import Gallery from "../DashBoard/components/Forms/Gallery/Gallery"
import axios from "axios";
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API} from 'constants/API';
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx').Transaction;
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

const stylesheet = {
   // ...registerPageStyle,
    ...customSelectStyle,
    ...sweetAlertStyle,
    selectLabel: {
        ...customSelectStyle.selectLabel,
        fontSize: "14px",
        fontFamily: "Muli",
        fontWeight: 400,
        lineHeight: 1.42857,
        color: "#AAAAAA !important",
        textTransform: "none",
        marginBottom: "-14px",
        position: "relative",
        textTransform: "none",
        textAlign: "left !important",
      },
      select: {
        ...customSelectStyle.select,
        fontSize: "14px",
        textTransform: "none",
        textAlign: "left !important",
      },
      selectFormControl: {
        ...customSelectStyle.selectFormControl,
        margin: "none !important"
      },
}
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      avatar: "",
      list: [],
      website: "",
      list_length: 0,
      countImage: 0,
      areaList:['1 vùng sản xuất (vườn/nhà kính/nông hộ)', "10 vùng sản xuất  (vườn/nhà kính/nông hộ)", "11 - 30 vùng sản xuất  (vườn/nhà kính/nông hộ)", "31 - 50 vùng sản xuất  (vườn/nhà kính/nông hộ)", "50 - 100 vùng sản xuất  (vườn/nhà kính/nông hộ)", "> 100 vùng sản xuất  (vườn/nhà kính/nông hộ)"],
      choice: [
          "Dán tem QR chứa thông tin xuất xứ sản phẩm",
          "Quản lý chất lượng và ghi nhận thông tin sản xuất thông qua tem QR cho 1 loại sản phẩm",
          "Quản lý chất lượng và ghi nhận thông tin sản xuất thông qua tem QR cho nhiều loại sản phẩm",
          "Truy xuất theo chuỗi giá trị từ sản xuất tới phân phối & bán lẻ"
      ]
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageSubmit = this.onImageSubmit.bind(this);
    this.handleSelectSuggest = this.handleSelectSuggest.bind(this);
    this.updateInfo = this.updateInfo.bind(this);
    this.handleSelectChoice = this.handleSelectChoice.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSucscribe = this.handleSucscribe.bind(this);
    this.hideAlert = this.hideAlert.bind(this)
    this.activeAccount = this.activeAccount.bind(this);
    this.handleSendSubsribe = this.handleSendSubsribe.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
  }
  componentDidMount(){
      this.props.getUserProfile();
  }
  componentWillReceiveProps(preProps){
      if(preProps.userProfile !== this.props.userProfile){
          if(preProps.userProfile.data){
              this.updateInfo(preProps.userProfile.data)
          }
      }
  }
  onCurrentImageChange(index) {
    if (this.state.list.length === 1) {
      this.setState({ currentImage: 0 })
    } else {
      this.setState({ currentImage: index });
    }
  }
  async deleteImage() {
    if (this.state.list.length === 1) {
      await this.setState({
        currentImage: 0,
      })
    }
    if (!this.state.list[this.state.currentImage]) {
      await this.setState({
        currentImage: this.state.currentImage - 1,
      })
    }
    if (window.confirm(`Bạn chắc chắn xóa ảnh này chứ ${this.state.currentImage}?`)) {
      this.setState({
        countImage: this.state.countImage - 1,
      })
     // this.state.deleteImage.certificates.push(this.state.list[this.state.currentImage])
      var images = this.state.list.slice();
      images.splice(this.state.currentImage, 1)
      this.setState({
        list: images,
        //deleteState: true
      });
    }
  }
  handleSignOut(){
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    axios
      .get(LINK_API.SIGN_OUT, {})
      .then(async response => {
        this.setState({
          alert: null
        });

        if (response.data.errorCode == 1) {
          await this.props.cleanStore();
          this.props.history.push("/");
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                Đăng xuất thành công
              </SweetAlert>
            )
          });
        }
      })
      .catch(function(error) {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              Vui lòng đăng xuất lại
            </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  }
  activeAccount = async () => {

    var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
    const privatekey = bytes.toString(CryptoJS.enc.Utf8);

    const privatekey_slice = privatekey.substring(2, privatekey.length)
    const Buffer_privatekey = await Buffer.from(privatekey_slice.toString(), 'hex');
    await this.setState({
      Buffer_privatekey: Buffer_privatekey
    })
    const tx = new EthereumTx(this.state.tx, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    const raw_to_send = {
      "raw": [],
      "sign": "none",
      "date": "none",
      "bcAddress": this.props.userInfo.data.bcAddress,
      "uuid": this.props.userInfo.data.uuid,
      "nonce": "none"
    }
    await raw_to_send.raw.push(rawTx);
    axios.post(LINK_API.SEND_RAW_VERIFY, raw_to_send)
      .then(async response => {
        if (response.data.errorCode === 1) {
          const data_x = {
            "companyName": this.state.name,
            "companyAddress": this.state.address,
            "primatyproduct": this.state.product,
            "companyScale":  this.state.area,
            "representativeName": this.state.username,
            "companyEmail": this.state.email,
            "companyPhone": this.state.phone,
            "demand": this.state.choiceselect,
            "website": "",
              }
              this.handleSendSubsribe(data_x)
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: 'block' }}
                title="Không thành công"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
              />
            )
          });
        }
      })

  }
  handleSendSubsribe(data){
    axios.post(LINK_API.SUBSCRIBE, data)
    .then(response => {
      if(response.data.errorCode === 1){
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-200px" }}
              title="Yêu cầu cầu của bạn đã được ghi nhận"
              onConfirm={() => this.handleSignOut()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Đăng xuất"
              cancelBtnText="Đóng"
              showCancel
              showConfirm
            >Vui lòng thoát tài khoản và đăng nhập lại</SweetAlert>
          )
        });
      }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-200px" }}
              title="Không thành công!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Đăng nhập"
              cancelBtnText="Đóng"
              showConfirm={false}
              showCancel
            ></SweetAlert>
          )
        });
      }
    })
    .catch(err => {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-200px" }}
            title="Không thành công!"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Đăng nhập"
            cancelBtnText="Đóng"
            showConfirm={false}
            showCancel
          ></SweetAlert>
        )
      });
    })
  }
  handleSucscribe(){
    if(!this.state.product){
      document.getElementById("noti").innerHTML = "Vui lòng nhập sản phẩm chủ lực";
      return;
    }
    if(!this.state.username){
      document.getElementById("noti").innerHTML = "Vui lòng nhập người đại diện";
      return;
    }
    if(!this.state.area){
      document.getElementById("noti").innerHTML = "Vui lòng chọn quy mô doanh nghiệp";
      return;
    }
    if(!this.state.choiceselect){
      document.getElementById("noti").innerHTML = "Vui lòng chọn nhu cầu sử dụng";
      return;
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>)
    })
    
 
    const data = {
      "email": this.props.userInfo.data.email,
      "bcAddress": this.props.userInfo.data.bcAddress,
      "uuid": this.props.userInfo.data.uuid
    }
    axios.post(LINK_API.VERIFY_ACCOUNT, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          await this.setState({
            tx: response.data.data.raw
          })
          await this.activeAccount()
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block' }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
            />
          )
        });
      })
  }
  hideAlert() {
    this.setState({
      alert: null
    })
  }
  handleSelectChoice(event){
      this.setState({
          choiceselect: event.target.value
      })
      document.getElementById("noti").innerHTML = "";
  }
  handleSelect(event){
      this.setState({
          area: event.target.value
      })
      document.getElementById("noti").innerHTML = "";
  }
  updateInfo(data) {
    this.setState({
        name: data.name,
        email: data.email,
        website: data.website,
        address: data.address,
        phone: data.phone
    })
  }
  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      address: geocodedPrediction.formatted_address,
      search: "",
    })
    //this.setState({ search: "", value: geocodedPrediction.formatted_address })
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }
  handleChange = name => async event => {
    if(name === "address"){
        this.setState({
            search: event.target.value
        })
    }
    await this.setState({ [name]: event.target.value })
    document.getElementById("noti").innerHTML = "";
  };
  async onImageSubmit(file) {
    this.setState({
      image: file
    })
//    await this.clickChild();
  }
handleNoResult = () => {
    console.log('Không có kết quả', this.state.search)
  }

  handleStatusUpdate = (status) => {
    console.log(status)
  }
  render() {
    const { classes } = this.props;
    return (
        <GridContainer justify="center"  style={{display: 'flex', alignItems: 'center'}}>
          <GridItem xs={8} sm={8} md={8}>
            <Card className={classes.cardSignup}>
            <GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent: "center"}}>
              <h2 className={classes.cardTitle} style={{fontWeight: 700, marginTop: "17px", fontSize: "20px"}}>XÁC MINH THÔNG TIN DOANH NGHIỆP</h2>
              </GridItem>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6} md={6}>
                    <form className={classes.form}>
                    <CustomInput
                        labelText="Tên doanh nghiệp"
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('name'),
                        }}
                        inputProps={{
                          value: this.state.name,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                home
                              </Icon>
                            </InputAdornment>
                          ),
                          //placeholder: "Tên loại sản phẩm"
                        }}
                      />
                      <CustomInput
                        labelText="Sản phẩm chủ lực"
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('product'),
                        }}
                        inputProps={{
                          value: this.state.product,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                local_florist
                              </Icon>
                            </InputAdornment>
                          ),
                          //placeholder: "Tên loại sản phẩm"
                        }}
                      />
                      <CustomInput
                        labelText="Tên người đại diện"
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('username'),
                        }}
                        inputProps={{
                          value: this.state.username,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                supervised_user_circle
                              </Icon>
                            </InputAdornment>
                          ),
                          //placeholder: "Tên loại sản phẩm"
                        }}
                      />
                      <CustomInput
                        labelText="Email"
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('email'),
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.email,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                contact_mail
                              </Icon>
                            </InputAdornment>
                          ),
                          //placeholder: "Tên loại sản phẩm"
                        }}
                      />
                      <CustomInput
                        labelText="Số điện thoại"
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('phone'),
                        }}
                        inputProps={{
                          value: this.state.phone,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                contact_phone
                              </Icon>
                            </InputAdornment>
                          ),
                          //placeholder: "Tên loại sản phẩm"
                        }}
                      />
                      <CustomInput
                        labelText="Website"
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('website'),
                        }}
                        inputProps={{
                          value: this.state.website,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                code
                              </Icon>
                            </InputAdornment>
                          ),
                          //placeholder: "Tên loại sản phẩm"
                        }}
                      />
                     
                      <div className={classes.center}>
                        <Button color="success" size ="sm" onClick={this.handleSucscribe}>
                          Gửi thông tin
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <GridItem xs={12} sm={12} md={12} sm={12} lg={12}>
                    <p className="registerX">Giấy phép đăng ký kinh doanh</p> 
                    <div style={{marginTop: "-10px"}}>
                  
                            <ImageUpload
                            onImageSubmit={this.onImageSubmit.bind(this)}
                            icon={false}
                            portal={false}
                            count={3} />
                    </div>
                      
                    </GridItem>
                    <GridItem sm={12} sm={12}  md={12} lg={12} style={{marginTop: "9px"}}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                      
                    >
                      <InputLabel
                        htmlFor="product-expired-type"
                        className={classes.selectLabel}
                      >
                        Quy mô doanh nghiệp
                              </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        id='select'
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.area}
                        onChange={this.handleSelect}
                        inputProps={{
                          name: "type",
                          id: "type"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Chọn quy mô
                        </MenuItem>
                        {this.state.areaList.map((x, idx) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={x}
                            key={idx}
                          >
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="product-expired-type"
                        className={classes.selectLabel}
                      >
                        Nhu cầu sử dụng
                              </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        id='select'
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.choiceselect}
                        onChange={this.handleSelectChoice}
                        inputProps={{
                          name: "type",
                          id: "type"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Chọn nhu cầu sử dụng
                        </MenuItem>
                        {this.state.choice.map((x, idx) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={x}
                            key={idx}
                          >
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div style={{width: "100%"}}>
                    <ReactGoogleMapLoader
                    params={{
                      key: MY_API_KEY,
                      libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                      googleMaps && (
                        <div>
                          <ReactGooglePlacesSuggest
                            autocompletionRequest={{ input: this.state.search }}
                            googleMaps={googleMaps}
                            onSelectSuggest={this.handleSelectSuggest.bind(this)}
                            displayPoweredByGoogle={false}
                          >
                            <CustomInput
                              labelText="Địa chỉ"
                              success={this.state.cityState === "success"}
                              error={this.state.cityState === "error"}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handleChange('address'),
                              }}
                              inputProps={{
                                value: this.state.address,
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon className={classes.inputAdornmentIcon}>
                                    location_on
                              </Icon>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </ReactGooglePlacesSuggest>
                          </div>
                      )
                    }
                  />
                    </div>
                    </GridItem>
                  </GridItem>
                </GridContainer>
                <p
              id="noti"
              style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: 'red',
                fontFamily: '"Muli',
                marginTop: 5,
                marginBottom: 5,
              }}>

            </p>
              </CardBody>
            </Card>
          </GridItem>
          {this.state.alert}
        </GridContainer>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapDispatchToProps = (dispatch) => {
    return {
      getUserProfile: () => dispatch({ type: "GET_USER_PROFILE" }),
      cleanStore: () => dispatch({type: "CLEAN_STORE"}),
    }
  }
const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile,
        userInfo: state.userInfo
    }
}
export default withStyles(stylesheet)(withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPage)));
