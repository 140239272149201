/*eslint-disable*/
import React from "react";
import cookie from 'react-cookies';
import axios from 'axios';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { withRouter } from 'react-router-dom';
// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import { withStyles } from "@material-ui/styles";
import { connect } from 'react-redux';

import UnixStamp from "@material-ui/icons/Receipt";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import Assessment from "@material-ui/icons/Assessment";
import Dashboard from "@material-ui/icons/Dashboard";
import FindInPage from "@material-ui/icons/Search";
import CalendarViewDay from "@material-ui/icons/CalendarViewDay";
import ViewModule from "@material-ui/icons/ViewModule";

import { LINK_API } from 'constants/API';
import strings from "../../../../constants/strings";


class GeneralStatistic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sumObject: 0,
            sumSL: 0,
            sumHD: 0,
            sumSP: 0,
            sumLH: 0,
            sumSLQT: 0,
            sumLSP: 0,
            sumVSX: 0,
            sumShipment: 0,
            sumCattle: 0,
            sumAlive: 0,
            sumSlaughter: 0,
            responsePartner: 0,
            sumDepot: 0,
            sumAbattoir: 0,

        }
        this.handleClickSlaughter = this.handleClickSlaughter.bind(this);
        this.handleClickAlive = this.handleClickAlive.bind(this);
        this.handleClickTotal = this.handleClickTotal.bind(this);
    }

    async componentDidMount() {
        this.props.getArea();
        try {
            let response = await axios.get(LINK_API.GET_GENERAL_STATISTIC);
            let responseCattle = await axios.get(LINK_API.GET_NUM_CATTLE);
            let responsePartner = await axios.get(LINK_API.GET_NUM_PARTNER);

            if (responsePartner.data.errorCode == 1) {
                await this.setState({
                    sumFeedlot: responsePartner.data.data.FeedlotCount,
                    sumDepot: responsePartner.data.data.DepotCount,
                    sumAbattoir: responsePartner.data.data.AbattoirsCount
                })
            }

            if (responseCattle.data.errorCode == 1) {
                await this.setState({
                    sumCattle: responseCattle.data.data.total,
                    sumAlive: responseCattle.data.data.alive,
                    sumSlaughter: responseCattle.data.data.slaughter
                })
            }


            if (response.data.errorCode == 1) {
                this.setState({
                    sumShipment: response.data.data.activedCaseCount,
                })
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

    handleClickSlaughter(){
        cookie.save("slaughter", strings.slaughter,{ path: "/" });
        this.props.history.push("/admin/cattle/list");
    }

    handleClickAlive(){
        cookie.save("slaughter", strings.alive,{ path: "/" });
        this.props.history.push("/admin/cattle/list");
    }

    handleClickTotal(){
        cookie.remove("slaughter", { path: "/" });
        this.props.history.push("/admin/cattle/list");
    }

    componentWillReceiveProps(preProps) {
        if (this.props.productArea !== preProps.productArea) {
          if (preProps.productArea) {
            this.setState({
                sumVSX: preProps.productArea.length,
            })
          }
        }
      }

    render() {
        const { classes } = this.props;
        return (
            <div>

                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                        <GridContainer>
                            <GridItem xs={12} sm={1} md={1}>
                                <Dashboard style={{ color: "#0f7343", marginTop: 20, width: 47 }} />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <h6 className="cardtableLabel1" style={{ fontSize: "13px !important" }}>{strings.numOfCattles}</h6>
                                <p className="styleP" style={{ marginBottom: "-10px !important" }}>
                                    {strings.total}: {this.state.sumCattle}
                                    <Button justIcon color="transparent" onClick={this.handleClickTotal}>
                                        <FindInPage style={{ color: "#0f7343", width: 47, marginLeft: 10, marginTop: "-5px" }} />
                                    </Button>
                                </p>
                                <p className="styleP">
                                    {strings.alive}: {this.state.sumAlive}
                                    <Button justIcon color="transparent" onClick={this.handleClickAlive}>
                                        <FindInPage style={{ color: "#0f7343", width: 47, marginLeft: 10, marginTop: "-5px" }} />
                                    </Button>
                                </p>
                                <p className="styleP">
                                    {strings.slaughter}: {this.state.sumSlaughter}
                                    <Button justIcon color="transparent" onClick={this.handleClickSlaughter}>
                                        <FindInPage style={{ color: "#0f7343", width: 47, marginLeft: 10, marginTop: "-5px" }} />
                                    </Button>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                        <GridContainer>
                            <GridItem xs={12} sm={1} md={1}>
                                <ViewModule style={{ color: "#0f7343", marginTop: 20, width: 47 }} />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <h6 className="cardtableLabel1" style={{ fontSize: "13px !important" }}>{strings.numOfPartner}</h6>
                                <p  className="styleP" >
                                    {strings.feedlot}: {this.state.sumFeedlot}
                                    <Button justIcon color="transparent">
                                        <FindInPage style={{ color: "#0f7343", width: 47, marginLeft: 10, marginTop: "-5px" }} />
                                    </Button>
                                </p>
                                <p  className="styleP" >
                                    {strings.depot}: {this.state.sumDepot}
                                    <Button justIcon color="transparent">
                                        <FindInPage style={{ color: "#0f7343", width: 47, marginLeft: 10, marginTop: "-5px" }} />
                                    </Button>
                                </p>
                                <p  className="styleP" >
                                    {strings.abattoirs}: {this.state.sumAbattoir}
                                    <Button justIcon color="transparent">
                                        <FindInPage style={{ color: "#0f7343", width: 47, marginLeft: 10, marginTop: "-5px" }} />
                                    </Button>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <GridContainer>
                            <GridItem xs={12} sm={1} md={1}>
                                <CalendarViewDay style={{ color: "#0f7343", marginTop: 20, width: 47 }} />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <h6 className="cardtableLabel1" style={{ fontSize: "13px !important" }}>{strings.numOfShipment}</h6>
                                <p className="styleP">
                                    {strings.total}: {this.state.sumShipment}
                                    <Button justIcon color="transparent" onClick={()=>{this.props.history.push("/admin/stamp")}}>
                                        <FindInPage style={{ color: "#0f7343", width: 47, marginLeft: 10, marginTop: "-5px" }} />
                                    </Button>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </GridItem>


                    
                </GridContainer>

            </div>
        )
    }
}

// export default GeneralStatistic;
const mapDispatchToProps = (dispatch) => {
    return {
        getProductTypeList: () => dispatch({ type: "GET_PRODUCT_LIST" }),
        getArea: () => dispatch({type: "GET_AREA_LIST"}),
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        productList: state.productType,
        productArea: state.productArea.data,
    }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralStatistic)));