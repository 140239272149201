import React from "react";

import defaultImage from "assets/icons/upload.svg";
class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: defaultImage,
      status: false,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
      this.setState({
        status: this.props.status
      })
  }
  componentDidUpdate(preProps){
      if(preProps.status !== this.props.status){
          this.setState({
              status: this.props.status
          })
      }
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = async () => {
      await this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      await this.props.onAvatarSubmit(this.state.imagePreviewUrl);
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
        e.preventDefault();
   
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  render() {
    return (
      <div className="picture-container" style={{width: "100%",display: "flex", justifyContent: "start", border: "2px solid #ddd", borderRadius: "5px"}}>
        <div className="picture">
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          {(this.state.status ===  true) ? <input type="file" onChange={e => this.handleImageChange(e)} /> : null}
         
        </div>
        {/* <h6 className="description">Choose Picture</h6> */}
      </div>
    );
  }
}

export default PictureUpload;
