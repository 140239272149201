const initialState = { data: "", errorCode: "" };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LIST_BOX_OWN_':
            return {
                ...state,
            };
        case 'GET_LIST_BOX_OWN_SUCCESS':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        case 'GET_LIST_BOX_OWN_FALURE':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        default:
            return state;
    }
}
export default reducer;