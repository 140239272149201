import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import cookie from "react-cookies";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from '@material-ui/core/Icon';

import { IMG_LOAD } from "constants/index.js";
import { LINK_API } from "constants/API";

import sidebarStyle from "./sidebarStyle.jsx";

import {store} from "store/store.js"
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { connect } from 'react-redux';
import {
  tooltip
} from "assets/jss/material-dashboard-pro-react.jsx"
var ps;

class SidebarWrapper extends React.Component {
  constructor(props){
    super(props)
    this.state= {
      hover: [],
      parent: "",
      child: ""
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true,
      openChangePwdDialog: false,
      openEditProfileDialog: false
    };
    this.activeRoute.bind(this);
    this.user_name = cookie.load("user_name");
    this.avatar = cookie.load("avatar");
    this.routeToAuth.bind(this);
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  routeToAuth = () => {
    this.props.history.push("/auth");
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  openEditProfileDialog = () => {
    this.props.history.push("/admin/profile");
  };
  closeEditProfileDialog = () => {
    this.setState({
      openEditProfileDialog: false
    });
  };
  openChangePwdDialog = () => {
    this.props.history.push("/admin/changepassword");
  };
  closeChangePwdDialog = () => {
    this.setState({
      openChangePwdDialog: false
    });
  };
  async openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    await this.setState(st);
    this.props.collapseFunc();
  }

  handleEditProfile = data => {
    // this.closeEditProfileDialog();

    if (!data) return; // <=> Close dialog

    //loading
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });



    let that = this;
    axios
      .post(LINK_API.EDIT_PROFILE, {
        user_name: data.user_name,
        address: data.address,
        phone: data.phone,
        website: data.website
      })
      .then(async function (response) {
        that.setState({
          alert: null
        });

        if (response.data.errorCode === 1) {
          await cookie.save("user_name", data.user_name, { path: "/" });
          await cookie.save("address", data.address, { path: "/" });
          await cookie.save("phone", data.phone, { path: "/" });
          await cookie.save("website", data.website, { path: "/" });

          that.user_name = data.user_name;

          that.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                closeOnClickOutside={false}
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Cập nhật thông tin thành công
              </SweetAlert>
            )
          });
        } else if (response.data.errorCode === 11) {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
              </SweetAlert>
            )
          });
        } else {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
              </SweetAlert>
            )
          });
        }
      })
      .catch(function (error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng thử lại
            </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  handleChangePwd = data => {
    this.closeChangePwdDialog();

    if (!data) return; // <=> Close dialog

    //loading
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    let that = this;
    axios
      .post(LINK_API.CHANGE_PWD, {
        currentPassword: data.currentPwd,
        newPassword: data.newPwd
      })
      .then(function (response) {
        that.setState({
          alert: null
        });

        if (response.data.errorCode === 1) {
          cookie.remove("gln");
          cookie.remove("token");
          cookie.remove("role");
          cookie.remove("user_name");
          that.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => that.routeToAuth()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đăng nhập lại"
                closeOnClickOutside={false}
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Bạn cần phải đăng nhập lại
              </SweetAlert>
            )
          });
        } else if (response.data.errorCode === 11) {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Mật khẩu không chính xác
              </SweetAlert>
            )
          });
        } else {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
              </SweetAlert>
            )
          });
        }
      })
      .catch(function (error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng thử lại
            </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  handleSignOut = () => {
    //loading
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    let that = this;
    axios
      .get(LINK_API.SIGN_OUT, {})
      .then(async function (response) {
        that.setState({
          alert: null
        });

        if (response.data.errorCode === 1) {
          await cookie.remove("gln");
          await cookie.remove("token", { path: "/" });
          await cookie.remove("role");
          await cookie.remove("user_name");
          that.props.history.push("/");
        } else {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Đăng xuất thành công
              </SweetAlert>
            )
          });
        }
      })
      .catch(function (error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng đăng xuất lại
            </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  openConfirmDialog = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn muốn đăng xuất?"
          onConfirm={() => this.handleSignOut()}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Đăng xuất"
          cancelBtnText="Hủy"
          showCancel
        />
      )
    });
  };
  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive    } = this.props;
    // var user;

    var links = (
      <List className={classes.list} >
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes.collapseActive]: this.activeRoute(prop.path)
              });
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
              });
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive
              });
            const itemIcon =
              classes.itemIcon +
              " " +
              cx({
                [classes.itemIconRTL]: rtlActive
              });
            const caret =
              classes.caret +
              " " +
              cx({
                [classes.caretRTL]: rtlActive
              });
            if (prop.icon === "") return "";
            return (
              <Tooltip
                key={key}
                id="tooltip-right"
                TransitionComponent={Zoom}
                title={prop.name}
                placement="right"
                classes={{ tooltip: classes.tooltip_sidebar }}
                disableHoverListener={store.getState().tooltip.status_parent}
              >
                <ListItem  className={classes.item}>
                  <NavLink
                    to={"#"}
                    className={navLinkClasses}
                    onClick={() => this.openCollapse(prop.state)}
                  >
                    <ListItemIcon className={itemIcon} style={{ display: "block", minWidth: 39 }}>
                      {typeof prop.icon === "string" ? (
                        <Icon classes={{ root: classes.root }} fontSize="small">{prop.icon}</Icon>
                      ) : (
                          <prop.icon />
                        )}
                    </ListItemIcon>
                    <ListItemText
                   
                      primary={prop.name}
                      secondary={
                        <b
                          className={
                            caret +
                            " " +
                            (this.state[prop.state] ? classes.caretActive : "")
                          }
                        />
                      }
                      disableTypography={true}
                      className={itemText}
                    />
                  </NavLink>
                  <Collapse in={this.state[prop.state]} unmountOnExit>
                    <List className={classes.list + " " + classes.collapseList}>
                      {prop.views.map((prop, key) => {
                        if (prop.redirect) {
                          return null;
                        }
                        // this.setState({
                        //   hover: true
                        // })
                        const navLinkClasses =
                          classes.collapseItemLinkMini +
                          " " +
                          cx({
                            [" " + classes[color]]: this.activeRoute(prop.path)
                          });
                        const collapseItemMini =
                          classes.collapseItemMini +
                          " " +
                          cx({
                            [classes.collapseItemMiniRTL]: rtlActive
                          });
                        if (!prop.mini) return "";
                        return (
                          <Tooltip
                            key={key}
                            id="tooltip-mini"
                            TransitionComponent={Zoom}
                            title={prop.name}
                            placement="right"
                            classes={{ tooltip: classes.tooltip_sidebar }}
                            disableHoverListener={store.getState().tooltip.status_child}
                          >
                            <ListItem  className={classes.collapseItem}>
                              <NavLink to={prop.path} className={navLinkClasses}>
                                <span className={collapseItemMini}>
                                  {prop.mini}
                                </span>
                                <ListItemText
                                  primary={prop.name}
                                  disableTypography={true}
                                  className={collapseItemText}
                                />
                              </NavLink>
                            </ListItem>
                          </Tooltip>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              </Tooltip>
            );
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes[color]]: this.activeRoute(prop.path)
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive
            });
          const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive
            });
          if (prop.icon === "") return "";
        
          return (
            
            <div key={key}>
              <Tooltip
                id="tooltip-right"
                TransitionComponent={Zoom}
                title={prop.name}
                placement="right"
                classes={{ tooltip: classes.tooltip_sidebar }}
                disableHoverListener={store.getState().tooltip.status_top}
              >
                <ListItem key={key} className={classes.item}>
                  <NavLink to={prop.path} className={navLinkClasses}>
                    <ListItemIcon className={itemIcon} style={{ display: "block", minWidth: 39 }}>
                      {typeof prop.icon === "string" ? (
                        <Icon classes={{ root: classes.root }} fontSize="small">{prop.icon}</Icon>
                      ) : (
                          <prop.icon />
                        )}
                    </ListItemIcon>
                    <ListItemText
                      primary={prop.name}
                      disableTypography={true}
                      className={itemText}
                    />
                  </NavLink>
                </ListItem>
              </Tooltip>
            </div>
          );
        })}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,

        [classes.logoMiniRTL]: rtlActive
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {/* {brand} */}
            <SidebarWrapper
              className={sidebarWrapper}
              // headerLinks={<HeaderLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            // onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {/* {brand} */}
            <SidebarWrapper
              className={sidebarWrapper}
              // user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};
const mapDispatchToProps = (dispatch) => {
  return {
    activeTooltip: (data) => dispatch({ type: "ACTIVE_TOOLTIP", data: data}),
    deactiveTooltip: () => dispatch({type: "DEACTIVE_TOOLTIP"})
  }
}
const mapStateToProps = (state) => {
  return { tooltip: state.tooltip,
          userInfo: state.userInfo}
}
export default withStyles(sidebarStyle)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar)));
