// ##############################
// // // Timeline component styles
// #############################

import {
  infoColor,
  primaryColor,
  successColor,
  dangerColor,
  warningColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import { FONT_SIZES, COLORS, FONTS } from '../../../../../constants'

const timelineStyle = theme => ({
  timeline: {
    [theme.breakpoints.down("sm")]: {
      "&:before": {
        left: "5% !important"
      }
    },
    listStyle: "none",
    position: "relative",
    marginTop: "30px",
    "&:before": {
      top: "110px",
      bottom: "0",
      position: "absolute",
      content: '" "',
      width: "3px",
      backgroundColor: "#b4b4b4",
      left: "0%",
      marginLeft: "32px",
      opacity: "0.2"
    }
  },
  timelineSimple: {
    marginTop: "30px",
    padding: "0px 0px !important",
    "&:before": {
      left: "5%"
    }
  },
  item: {
    marginBottom: "20px",
    position: "relative",
    display: "flex",
    justifyContent:"center",
 
  },
  timelineBadge: {
    [theme.breakpoints.down("sm")]: {
      left: "5% !important"
    },
    color: "#FFFFFF",
    width: "50px",
    height: "50px",
    lineHeight: "51px",
    fontSize: "1.4em",
    textAlign: "center",
    position: "absolute",
    top: "2px",
    left: "0%",
    marginLeft: "10px",
    zIndex: "100",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    borderBottomLeftRadius: "50%"
  },
  timelineSimpleBadge: {
    left: "5%"
  },
  info: {
    backgroundColor: infoColor,
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4)"
  },
  success: {
    backgroundColor: successColor,
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)"
  },
  danger: {
    backgroundColor: dangerColor,
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)"
  },
  warning: {
    backgroundColor: warningColor,
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
  },
  primary: {
    backgroundColor: primaryColor,
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)"
  },
  badgeIcon: {
    width: "24px",
    marginTop: "-4px",
  },
  timelinePanel: {
    paddingTop:0,
    [theme.breakpoints.down("sm")]: {
      float: "right !important",
      width: "86% !important",
      paddingRight:"0px"
      // "&:before": {
      //   borderLeftWidth: "0 !important",
      //   borderRightWidth: "15px !important",
      //   left: "-15px !important",
      //   right: "auto !important"
      // },
      // "&:after": {
      //   borderLeftWidth: "0 !important",
      //   borderRightWidth: "14px !important",
      //   left: "-14px !important",
      //   right: "auto !important"
      // }
    },
    width: "45%",
    float: "left",
    padding: "0px",
    marginBottom: "20px",
    position: "relative",
    borderRadius: "6px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#fff",
    // "&:before": {
    //   position: "absolute",
    //   top: "26px",
    //   right: "-15px",
    //   display: "inline-block",
    //   borderTop: "15px solid transparent",
    //   borderLeft: "15px solid #e4e4e4",
    //   borderRight: "0 solid #e4e4e4",
    //   borderBottom: "15px solid transparent",
    //   content: '" "'
    // },
    // "&:after": {
    //   position: "absolute",
    //   top: "27px",
    //   right: "-14px",
    //   display: "inline-block",
    //   borderTop: "14px solid transparent",
    //   borderLeft: "14px solid #FFFFFF",
    //   borderRight: "0 solid #FFFFFF",
    //   borderBottom: "14px solid transparent",
    //   content: '" "'
    // }
  },
  timelineSimplePanel: {
    width: "86%"
  },
  timelinePanelInverted: {
    [theme.breakpoints.up("sm")]: {
      float: "right",
      backgroundColor: "#FFFFFF",
      // "&:before": {
      //   borderLeftWidth: "0",
      //   borderRightWidth: "15px",
      //   left: "-15px",
      //   right: "auto"
      // },
      // "&:after": {
      //   borderLeftWidth: "0",
      //   borderRightWidth: "14px",
      //   left: "-14px",
      //   right: "auto"
      // }
    }
  },
  timelineHeading: {
    marginBottom: "15px"
  },
  timelineBody: {
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.SMALL,
      fontStyle: "normal",
      letterSpacing: 0,
      textAlign: "justify",
      color: "#000000",
      marginBottom: 15,
      marginTop: 15,
  },
  timelineFooter: {
    zIndex: "1000",
    position: "relative",
    float: "left"
  },
  footerTitle: {
    color: "#333333",
    fontWeight: "400",
    margin: "10px 0px 0px"
  },
  footerLine: {
    marginBottom: "5px",
    borderTop: "1px solid #b7b7b7",
  },
  tag: {
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.LARGE,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#5a5a5a",
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  barLight: {
    width: '30%',
    borderTop: "2px solid #b7b7b7",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  center: {
    textAlign: 'center',
  },
  textAction: {
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.LARGE,
      fontStyle: "normal",
      letterSpacing: 0,
      textAlign: "left",
      color: "#212B36",
      fontWeight: 'bold',
      textTransform: "capitalize"
  },
  textWho: {
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    fontWeight: '600',
    color: "#4FB779"
  },
  textTime: {
    fontFamily: "Muli",
    fontSize: FONT_SIZES.SMALL,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    color: "#a2a2a2",
    fontWeight: 'bold',
    paddingTop:'3px',
    width:'100%',
    textAlign: 'left',
  },
  btChitiet: {
    backgroundColor: "#e2ffdb",
    textTransform:'none',
    fontFamily: "Muli",
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    color: "#4ca750",
    height:'25px',
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:5,
    paddingRight:5,
    "&:hover,&:focus": {
        outline: 0,
        boxShadow: "none!important",
    },
  },
  row2: {
    display: 'inline-flex',
    width:'100%',
    justifyContent: 'flex-end',
  },
  textBlock: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "600",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#a2a2a2",
  },
  textTx: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#007bff",
  }
});

export default timelineStyle;
