import Dashboard from "../views/Dashboard";


import { ROLE } from "constants/index.js";
import ProductTypeList from "../views/ProductTypeList/index.jsx";
import ProductDetail from "../views/ObjectProductionPage/ObjectDetail";
import CattleDetail from "../views/ObjectProductionPage/CattleDetail";
import CreateProductTypeList from "../views/ProductTypeList/CreateProduct";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person"
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import Page from "@material-ui/icons/Pages";
import Theaters from "@material-ui/icons/LocalFlorist";
import AccountBox from "@material-ui/icons/AccountBox";
import Receipt from "@material-ui/icons/Receipt";
import Basket from "@material-ui/icons/ShoppingBasket";
import Storage from "@material-ui/icons/Spa";
import FarmList from "../views/EmployeePage/FarmList";
import ProductionArea from "../views/ProductionAreaPage/ProductionArea";
import ImportProduct from "@material-ui/icons/Store";
import cookie from 'react-cookies';
import CreateProductionArea from "../views/ProductionAreaPage/CreateProductionArea";
import ProcessList from "../views/ProcessPage/ProcessList";
import TreeList from "../views/ObjectProductionPage/TreeList";
import CattleImport from "../views/ObjectProductionPage/CattleImport";
import CreateTree from "../views/ObjectProductionPage/CreateTree.jsx";
import StampList from "../views/StampPage/StampList";
import ActiveStamp from "../views/AdminPage/ActiveStamp";
import AccountManager from "../views/AdminPage/AccountManager";
import RetailPage from "../views/RetailPage/RetailPage.jsx";
import ImportPage from "../views/RetailPage/ImportPage.jsx";
import ExportPage from "../views/RetailPage/ExportPage.jsx";
import ProfilePage from "../components/Forms/EditProfileForm.jsx";
import ChangePw from "../components/Forms/ChangePwdForm.jsx";
import CreateEmployee from "../views/EmployeePage/CreateFarm";
import EditEmployee from "../views/EmployeePage/EditEmployee";
import CreateProcess from "../views/ProcessPage/WizardForm/Process.jsx"
import ObjectProcessList from "../views/ObjectsProcessPage/TreeList.jsx"
import CreateObjectProcess from "../views/ObjectsProcessPage/CreateTree.jsx"
import ObjectProcessDetail from "../views/ObjectsProcessPage/ObjectDetail.jsx"
import BranchPage from "../views/BranchPage/index.jsx"
import CreateBranch from "../views/BranchPage/CreateBranch.jsx"
import CreateBranchEmp from "../views/BranchPage/CreateEmp.jsx"
import BranchEmpList from "../views/BranchPage/EmpList.jsx"
import CustomerList from "../views/Customer/index.jsx"
import CustomerFeedBack from "../views/Customer/CustomerFeedBack.jsx"
import CreateCustomer from "../views/Customer/CreateCustomer"
import RegisterCompany from "../../ActivePage/index.jsx"
import Subscription from "../views/Subscription/SubscriptionList.jsx";
import planList from "../views/PlanPage/planList.jsx"
import createPlan from "../views/PlanPage/createPlan.jsx"
import processTimeLine from "../views/ProcessPage/ProcessTimeLine.jsx"
import ObjectCBStatistic from "../views/Statistic/ObjectCBStatistic.jsx"
import ProductTypeStatistic from "../views/Statistic/ProductTypeStatistic.jsx"
import Branch from "../views/Dashboard/branchStatistic.jsx"
import editProcess from "../views/ProcessPage/EditProcess"
import ObjectNTStatistic from "../views/Statistic/ObjectNTStatistic.jsx"
import ObjectCB from "../views/Statistic/ObjectCB.jsx"
import ObjectDetailStatistic from "../views/Statistic/ObjectDetailStatistic.jsx"
import ProductAreaStatistic from "../views/Statistic/ProductAreaStatistic.jsx"
import ObjectAll from "../views/Statistic/ObjectAll.jsx"
import editArea from "../views/ProductionAreaPage/EditArea"
import EditProduct from "../views/ProductTypeList/EditProducts";
import strings from "constants/strings.jsx"
import ObjectArea from "../views/ObjectProductionPage/ObjectArea";
import cow from "@material-ui/icons/Pets";
import CattlePartner from "../views/ObjectProductionPage/CattePartner";
const lan = cookie.load("language");
if (lan) {
   strings.setLanguage(lan);
}
else {
  strings.setLanguage("vi");
}
var dashRoutes = [
  {
    path: "/admin/dashboard",
    name: strings.statistical,
    icon: DashboardIcon,
    component: Dashboard,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/distributorStatistic/:id",
    name: "",
    icon: "",
    component: Branch,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/objectstatistic",
    name: "",
    icon: "",
    component: ObjectNTStatistic,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/productarea/statistic",
    name: "",
    icon: "",
    component: ProductAreaStatistic,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/productareas/object/statistic",
    name: "",
    icon: "",
    component: ObjectAll,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/objectcbstatistic",
    name: "",
    icon: "",
    component: ObjectCB,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/objectdetailstatistic",
    name: "",
    icon: "",
    component: ObjectDetailStatistic,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/productStatistic",
    name: "",
    icon: "",
    component: ProductTypeStatistic,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/objectchebienstatistic",
    name: "",
    icon: "",
    component: ObjectCBStatistic,
    role: ROLE.MANUFACTURER,
  },
  {
    path: "/admin/register/plan",
    name: "Xác minh doanh nghiệp",
    icon: "",
    component: RegisterCompany,
    role: ROLE.MANUFACTURER,
  },
  {
    collapse: false,
    path: "/admin/areas/objects/list/create",
    name: "Tạo đối tượng nuôi trồng",
    icon: "",
    component: CreateTree
  },
  {
    collapse: false,
    path: "/admin/cattle/import/detail",
    name: "Thông tin chi tiết đối tượng nuôi trồng",
    icon: "",
    component: ProductDetail
  },
  {
    collapse: false,
    path: "/admin/cattle/list/detail",
    name: "",
    icon: "",
    component: CattleDetail
  },
  {
    collapse: false,
    path: "/admin/cattle/list/partner",
    name: "",
    icon: "",
    component: CattlePartner
  },
  {
    collapse: false,
    path: "/admin/areas/objects/listinarea",
    name: "Danh sách đối tượng trong vùng sản xuất",
    icon: "",
    component: ObjectArea
  },
  {
    collapse: true,
    path: "/admin/cattle/list",
    name: strings.cattleManagement,
    state: "openCattle",
    icon: cow,
    views: [
      {
        path: "/admin/cattle/import",
        name: strings.cattleImport,
        mini: "CI",
        component: TreeList,
      },
      {
        path: "/admin/cattle/list",
        name: strings.farmingObject,
        mini: "CL",
        component: CattleImport
      },
    ],
    role: ROLE.MANUFACTURER
  },
  {
    collapse: false,
    path: "/admin/farms/create",
    name: "Tạo tài khoản nhân viên",
    state: "openFarmsCreate",
    icon: "",
    component: CreateEmployee,
  },
  {
    collapse: false,
    path: "/admin/farms/edit",
    name: "Chỉnh sửa nhân viên",
    component: EditEmployee,
    state: "openEditEmployee",
    icon: "",
    role: ROLE.MANUFACTURER
  },
  {
    collapse: false,
    path: "/admin/farms/list",
    name: strings.employee,
    state: "openFarms",
    icon: PermContactCalendar,
    component: FarmList,
    role: ROLE.MANUFACTURER
  },
  // {
  //   collapse: false,
  //   path: "/admin/process/create",
  //   name: "Tạo quy trình",
  //   state: "openProcessCreate",
  //   icon: "",
  //   component: CreateProcess,
  // },
  // {
  //   collapse: false,
  //   path: "/admin/process/detail",
  //   name: "Xem chi tiết quy trình",
  //   state: "openDetailProcess",
  //   icon: "",
  //   component: processTimeLine,
  // },
  // {
  //   collapse: false,
  //   path: "/admin/process/edit",
  //   name: "Chỉnh sửa quy trình",
  //   state: "openEditProcess",
  //   icon: "",
  //   component: editProcess,
  // },
  // {
  //   collapse: false,
  //   path: "/admin/process/list",
  //   name: strings.procedure,
  //   state: "openProcess",
  //   icon: Theaters,
  //   component: ProcessList,
  //   role: ROLE.MANUFACTURER
  // },
  {
    collapse: false,
    path: "/admin/customer",
    name: strings.customer,
    state: "openCustomer",
    icon: Person,
    component: CustomerList,
    views:[
      {
        path: "/admin/customer/list",
        name: strings.distributor,
        mini: "PP",
        component: CustomerList,
      },
      // {
      //   path: "/admin/customer/feedback",
      //   name: strings.feedback,
      //   mini: "GY",
      //   component: CustomerFeedBack,
      // }
    ],
    // role: ROLE.MANUFACTURER
  },
  {
    collapse: false,
    path: "/admin/customers/create",
    name: "Tạo đối tác",
    state: "openCustomer",
    icon: "",
    component: CreateCustomer,
    role: ROLE.MANUFACTURER
  },
  {
    collapse: false,
    path: "/admin/retailer/sale",
    name: strings.sell,
    component: RetailPage,
    state: "openSalePage",
    icon: Basket,
    role: ROLE.RETAILER
  },
  {
    collapse: false,
    path: "/admin/retailer/import",
    name: strings.import,
    component: ImportPage,
    state: "openImportPage",
    icon: ImportProduct,
    role: ROLE.RETAILER,
   // role: ROLE.MANUFACTURER
  },
  {
    collapse: false,
    path: "/admin/retailer/import",
    name: strings.import,
    component: ImportPage,
    state: "openImportPage",
    icon: ImportProduct,
    role: ROLE.DISTRIBUTOR,
   // role: ROLE.MANUFACTURER
  },
  // {
  //   collapse: false,
  //   path: "/admin/distributor/list",
  //   name: strings.branch,
  //   component: BranchPage,
  //   state: "openBranchList",
  //   icon: Receipt,
  //   role: ROLE.DISTRIBUTOR
  // },
  // {
  //   collapse: false,
  //   path: "/admin/distributor/export",
  //   name: strings.export,
  //   component: ExportPage,
  //   state: "openExportPage",
  //   icon: Storage,
  //   role: ROLE.DISTRIBUTOR
  // },
  {
    collapse: false,
    path: "/admin/distributor/branchCreate",
    name: "Tạo chi nhánh",
    component: CreateBranch,
    state: "openCreateBranch",
    icon: "",
    role: ROLE.DISTRIBUTOR
  },
  {
    collapse: false,
    path: "/admin/distributor/employee",
    name: strings.employee,
    component: BranchEmpList,
    state: "openDisEmp",
    icon: AccountBox,
    role: ROLE.DISTRIBUTOR
  },
  {
    collapse: false,
    path: "/admin/distributor/createemp",
    name: strings.employee,
    component: CreateBranchEmp,
    state: "openCreateBranchEmp",
    icon: "",
    role: ROLE.DISTRIBUTOR
  },
  {
    collapse: false,
    path: "/admin/distributor/editemp",
    name: "Chỉnh sửa thông tin nhân viên",
    component: EditEmployee,
    state: "openEditEmployeeDis",
    icon: "",
    role: ROLE.DISTRIBUTOR
  },
  // {
  //   collapse: false,
  //   path: "/admin/distributor/employee",
  //   name: "Nhân viên",
  //   state: "openEmpDis",
  //   icon: Store,
  //   component: FarmList,
  //   role: ROLE.DISTRIBUTOR,
  // }
  {
    path: "/admin/retailer/profile",
    name: "",
    icon: "",
    component: ProfilePage,
  },
  {
    path: "/admin/products/profile",
    name: "",
    icon: "",
    component: ProfilePage,
  },
  {
    path: "/admin/diaries/profile",
    name: "",
    icon: "",
    component: ProfilePage,
  },
  {
    path: "/admin/dashboard/profile",
    name: "",
    icon: "",
    component: ProfilePage,
  },
  {
    path: "/admin/profile",
    name: "",
    icon: "",
    state: "openprofiles",
    component: ProfilePage,
  },
  {
    path: "/admin/retailer/changepassword",
    name: "",
    icon: "",
    component: ChangePw,
  },
  {
    path: "/admin/provider/profile",
    name: "",
    icon: "",
    component: ProfilePage,
  },
  {
    path: "/admin/provider/changepassword",
    name: "",
    icon: "",
    component: ChangePw,
  },
  {
    path: "/admin/changepassword",
    name: "",
    icon: "",
    component: ChangePw,
  },
  {
    path: "/admin/farms/profile",
    name: "",
    icon: "",
    component: ProfilePage,
  },
  {
    path: "/admin/farms/changepassword",
    name: "",
    icon: "",
    component: ChangePw,
  },

  {
    collapse: true,
    path: "/admin/adminonly",
    name: "Admin",
    state: "openAdmins",
    icon: AccountBox,
    views: [
      {
        path: "/admin/adminonly/process",
        name: "Quy trình sản xuất",
        mini: "QT",
        component: ProcessList
      },
      {
        path: "/admin/adminonly/stampsactive",
        name: "Kích hoạt tem",
        mini: "KH",
        component: ActiveStamp
      },
      {
        path: "/admin/adminonly/manage",
        name: "Quản lý tài khoản",
        mini: "QL",
        component: AccountManager
      },
      {
        path: "/admin/adminonly/plan",
        name: "Danh sách gói",
        mini: "PL",
        component: planList
      },
      {
        path: "/admin/adminonly/createplan",
        name: "Tạo gói",
        mini: "",
        component: createPlan
      },
      {
        path: "/admin/adminonly/subscription",
        name: "Quản lý đăng ký",
        mini: "SS",
        component: Subscription
      },
    ],
    role: ROLE.ADMIN
  },
  {
    collapse: false,
    path: "/admin/stamp",
    name: strings.stampManage,
    component: StampList,
    state: "openstamp",
    icon: Receipt,
    role: ROLE.MANUFACTURER
  },
  // {
  //   collapse: false,
  //   path: "/admin/approve",
  //   name: "Quản lý hành động",
  //   component: ApproveList,
  //   state: "openActions",
  //   icon: ListActions,
  //   role: ROLE.MANUFACTURER
  // },
  // {
  //   collapse: false,
  //   path: "/admin/scheme",
  //   name: "Kế hoạch sản xuất",
  //   component: SchemeList,
  //   state: "openScheme",
  //   icon: Event,
  //   role: ROLE.MANUFACTURER
  // },
  //  {
  //   collapse: true,
  //   path: "/admin/adminonly",
  //   name: "Admin",
  //   state: "openAdmins",
  //   icon: AccountBox,
  //   views: [
  //     {
  //       path: "/admin/adminonly/process",
  //       name: "Quy trình sản xuất",
  //       mini: "QT",
  //       component: ProcessList
  //     },
  //     {
  //       path: "/admin/adminonly/stampsactive",
  //       name: "Kích hoạt tem",
  //       mini: "KH",
  //       component: ActiveStamp
  //     },
  //     {
  //       path: "/admin/adminonly/manage",
  //       name: "Quản lý tài khoản",
  //       mini: "QL",
  //       component: AccountManager
  //     }
  //   ],
  //   role: ROLE.ADMIN
  // },
  // {
  //   collapse: true,
  //   path: "/admin/provider",
  //   name: "Nhà cung cấp",
  //   state: "openProvider",
  //   icon: Store,
  //   views: [
  //     {
  //       path: "/admin/provider/list",
  //       name: "Nguyên vật liệu",
  //       mini: "NL",
  //       component: MaterialPage
  //     }
  //   ],
  //   role: ROLE.PROVIDER
  // },

 
];
export default dashRoutes;
