// ##############################
// // // Timeline component styles
// #############################

import {
    infoColor,
    primaryColor,
    successColor,
    dangerColor,
    warningColor,
    roseColor,
    grayColor,
    primaryBoxShadow
} from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
const timelineStyle = theme => ({
    timeline: {
        [theme.breakpoints.down("lg")]: {
            "&:before": {
                left: "5% !important"
            }
        },
        listStyle: "none",
        padding: "20px 0 20px",
        position: "relative",
        marginTop: "30px",
        "&:before": {
            top: "50px",
            bottom: "0",
            position: "absolute",
            content: '" "',
            width: "3px",
            backgroundColor: "#E5E5E5",
            //left: "50%",
            marginLeft: "-1px"
        }
    },
    timelineSimple: {
        marginTop: "30px",
        padding: "0 0 20px",
        "&:before": {
            left: "5%"
        }
    },
    item: {
        marginBottom: "20px",
        position: "relative",
        "&:before,&:after": {
            content: '" "',
            display: "table"
        },
        "&:after": {
            clear: "both"
        }
    },
    timelineBadge: {
        [theme.breakpoints.down("lg")]: {
            left: "5% !important"
        },
        color: "#FFFFFF",
        width: "50px",
        height: "50px",
        lineHeight: "51px",
        fontSize: "1.4em",
        textAlign: "center",
        position: "absolute",
        top: "16px",
        //left: "50%",
        marginLeft: "-24px",
        //zIndex: "100",
        borderTopRightRadius: "50%",
        borderTopLeftRadius: "50%",
        borderBottomRightRadius: "50%",
        borderBottomLeftRadius: "50%"
    },
    timelineSimpleBadge: {
        left: "5%"
    },
    info: {
        backgroundColor: infoColor,
        boxShadow:
            "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4)"
    },
    success: {
        backgroundColor: successColor,
        boxShadow:
            "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)"
    },
    danger: {
        backgroundColor: dangerColor,
        boxShadow:
            "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)"
    },
    warning: {
        backgroundColor: warningColor,
        boxShadow:
            "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
    },
    primary: {
        backgroundColor: primaryColor,
        boxShadow:
            "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)"
    },
    badgeIcon: {
        width: "24px",
        height: "51px"
    },
    timelinePanel: {
        [theme.breakpoints.down("lg")]: {
            float: "right !important",
            width: "86% !important",
            "&:before": {
                borderLeftWidth: "0 !important",
                borderRightWidth: "15px !important",
                left: "-15px !important",
                right: "auto !important"
            },
            "&:after": {
                borderLeftWidth: "0 !important",
                borderRightWidth: "14px !important",
                left: "-14px !important",
                right: "auto !important"
            }
        },
        width: "96%",
        float: "left",
        padding: "20px",
        marginBottom: "20px",
        position: "relative",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        borderRadius: "6px",
        color: "rgba(0, 0, 0, 0.87)",
        background: "#fff",
        "&:before": {
            position: "absolute",
            top: "26px",
            right: "-15px",
            display: "inline-block",
            borderTop: "15px solid transparent",
            borderLeft: "15px solid #e4e4e4",
            borderRight: "0 solid #e4e4e4",
            borderBottom: "15px solid transparent",
            content: '" "'
        },
        "&:after": {
            position: "absolute",
            top: "27px",
            right: "-14px",
            display: "inline-block",
            borderTop: "14px solid transparent",
            borderLeft: "14px solid #FFFFFF",
            borderRight: "0 solid #FFFFFF",
            borderBottom: "14px solid transparent",
            content: '" "'
        }
    },
    timelineSimplePanel: {
        width: "86%"
    },
    timelinePanelInverted: {
        [theme.breakpoints.up("lg")]: {
            float: "right",
            backgroundColor: "#FFFFFF",
            "&:before": {
                borderLeftWidth: "0",
                borderRightWidth: "15px",
                left: "-15px",
                right: "auto"
            },
            "&:after": {
                borderLeftWidth: "0",
                borderRightWidth: "14px",
                left: "-14px",
                right: "auto"
            }
        }
    },
    timelineHeading: {
        marginBottom: "15px"
    },
    timelineBody: {
        fontSize: "14px",
        lineHeight: "21px"
    },
    timelineFooter: {
        zIndex: "1000",
        position: "relative",
        float: "left"
    },
    footerTitle: {
        color: "#333333",
        fontWeight: "400",
        margin: "10px 0px 0px"
    },
    footerLine: {
        marginTop: "10px",
        marginBottom: "5px"
    },
    checkRoot: {
        padding: "14px"
    },
    radioRoot: {
        padding: "16px"
    },
    checkboxAndRadio: {
        position: "relative",
        display: "block",
        // marginTop: "10px",
        // marginBottom: "10px"
    },
    checkboxAndRadioHorizontal: {
        position: "relative",
        display: "block",
        "&:first-child": {
            marginTop: "10px"
        },
        "&:not(:first-child)": {
            marginTop: "-14px"
        },
        marginTop: "0",
        marginBottom: "0"
    },
    checked: {
        color: primaryColor + "!important"
    },
    checkedIcon: {
        width: "20px",
        height: "20px",
        border: "1px solid rgba(0, 0, 0, .54)",
        borderRadius: "3px"
    },
    uncheckedIcon: {
        width: "0px",
        height: "0px",
        padding: "9px",
        border: "1px solid rgba(0, 0, 0, .54)",
        borderRadius: "3px"
    },
    disabledCheckboxAndRadio: {
        "& $checkedIcon,& $uncheckedIcon,& $radioChecked,& $radioUnchecked": {
            borderColor: "#000000",
            opacity: "0.26",
            color: "#000000"
        }
    },
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex",
        transition: "0.3s ease all"
    },
    labelHorizontal: {
        color: "rgba(0, 0, 0, 0.26)",
        cursor: "pointer",
        display: "inline-flex",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        paddingTop: "39px",
        marginRight: "0",
        "@media (min-width: 992px)": {
            float: "right"
        }
    },
    labelHorizontalRadioCheckbox: {
        paddingTop: "22px"
    },
    labelLeftHorizontal: {
        color: "rgba(0, 0, 0, 0.26)",
        cursor: "pointer",
        display: "inline-flex",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        paddingTop: "22px",
        marginRight: "0"
    },
    labelError: {
        color: dangerColor
    },
    radio: {
        color: primaryColor + "!important"
    },
    radioChecked: {
        width: "16px",
        height: "16px",
        border: "1px solid " + primaryColor,
        borderRadius: "50%"
    },
    radioUnchecked: {
        width: "0px",
        height: "0px",
        padding: "7px",
        border: "1px solid rgba(0, 0, 0, .54)",
        borderRadius: "50%"
    },
    inlineChecks: {
        marginTop: "8px"
    },
    iconCheckbox: {
        height: "116px",
        width: "116px",
        color: grayColor,
        padding: "0",
        margin: "0 auto 20px",
        "& > span:first-child": {
            borderWidth: "4px",
            borderStyle: "solid",
            borderColor: "#CCCCCC",
            textAlign: "center",
            verticalAlign: "middle",
            borderRadius: "50%",
            color: "inherit",
            transition: "all 0.2s"
        },
        "&:hover": {
            color: roseColor,
            "& > span:first-child": {
                borderColor: roseColor
            }
        }
    },
    iconCheckboxChecked: {
        color: roseColor,
        "& > span:first-child": {
            borderColor: roseColor
        }
    },
    iconCheckboxIcon: {
        fontSize: "40px",
        lineHeight: "111px"
    },
    switchBase: {
        color: primaryColor + "!important"
    },
    switchIcon: {
        boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.4)",
        color: "#FFFFFF !important",
        border: "1px solid rgba(0, 0, 0, .54)",
        transform: "translateX(-4px)!important"
    },
    switchIconChecked: {
        borderColor: "#9c27b0",
        transform: "translateX(0px)!important"
    },
    switchBar: {
        width: "30px",
        height: "15px",
        backgroundColor: "rgb(80, 80, 80)",
        borderRadius: "15px",
        opacity: "0.7!important"
    },
    switchChecked: {
        "& + $switchBar": {
            backgroundColor: "rgba(156, 39, 176, 1) !important"
        }
    },
    select: {
        padding: "12px 0 7px",
        fontSize: ".75rem",
        fontWeight: "400",
        lineHeight: "1.42857",
        textDecoration: "none",
        textTransform: "uppercase",
        color: "#3C4858",
        letterSpacing: "0",
        "&:focus": {
            backgroundColor: "transparent"
        },
        "&[aria-owns] + input + svg": {
            transform: "rotate(180deg)"
        },
        "& + input + svg": {
            transition: "all 300ms linear"
        }
    },
    selectFormControl: {
        margin: "10px 1px 10px 0px !important",
        "& > div": {
            "&:before": {
                borderBottomWidth: "1px !important",
                borderBottomColor: "#D2D2D2 !important"
            },
            "&:after": {
                borderBottomColor: successColor + "!important"
            }
        }
    },
    selectLabel: {
        fontSize: "12px",
        textTransform: "uppercase",
        color: "#3C4858 !important",
        top: "8px"
    },
    selectMenu: {
        "& > div > ul": {
            border: "0",
            padding: "5px 0",
            margin: "0",
            boxShadow: "none",
            minWidth: "100%",
            borderRadius: "4px",
            boxSizing: "border-box",
            display: "block",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "#fff",
            backgroundClip: "padding-box"
        },
        "& $selectPaper $selectMenuItemSelectedMultiple": {
            backgroundColor: "inherit"
        },
        "& > div + div": {
            maxHeight: "266px !important"
        }
    },
    selectMenuItem: {
        fontSize: "13px",
        padding: "10px 20px",
        margin: "0 5px",
        borderRadius: "2px",
        transition: "all 150ms linear",
        display: "block",
        clear: "both",
        fontWeight: "400",
        lineHeight: "2",
        whiteSpace: "nowrap",
        color: "#333",
        paddingRight: "30px",
        "&:hover": {
            backgroundColor: primaryColor,
            color: "#FFFFFF",
            ...primaryBoxShadow
        }
    },
    selectMenuItemSelected: {
        backgroundColor: primaryColor + "!important",
        color: "#FFFFFF"
    },
    selectMenuItemSelectedMultiple: {
        backgroundColor: "transparent !important",
        "&:hover": {
            backgroundColor: primaryColor + "!important",
            color: "#FFFFFF",
            ...primaryBoxShadow,
            "&:after": {
                color: "#FFFFFF"
            }
        },
        "&:after": {
            top: "16px",
            right: "12px",
            width: "12px",
            height: "5px",
            borderLeft: "2px solid currentColor",
            transform: "rotate(-45deg)",
            opacity: "1",
            color: "#3c4858",
            position: "absolute",
            content: "''",
            borderBottom: "2px solid currentColor",
            transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
        }
    },
    selectPaper: {
        boxSizing: "borderBox",
        borderRadius: "4px",
        padding: "0",
        minWidth: "100%",
        display: "block",
        border: "0",
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
        backgroundClip: "padding-box",
        margin: "2px 0 0",
        fontSize: "14px",
        textAlign: "left",
        listStyle: "none",
        backgroundColor: "transparent",
        maxHeight: "266px"
    },
    selectLabel: {
        fontSize: "14px",
        fontFamily: "Muli",
        fontWeight: 400,
        lineHeight: 1.42857,
        color: "#AAAAAA !important",
        textTransform: "none",
        marginBottom: "-14px",
        position: "relative",
        textTransform: "none",
        textAlign: "left !important",
    },
    select: {
        ...customSelectStyle.select,
        fontSize: "14px",
        textTransform: "none",
        textAlign: "left !important",
    },
    selectFormControl: {
        ...customSelectStyle.selectFormControl,
        margin: "none !important"
    },
});

export default timelineStyle;
