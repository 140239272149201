const initialState = { data: "", errorCode: "" };
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CROP_STATISTIC':
            return {
                ...state
            };
        case 'GET_CROP_STATISTIC_SUCCESS':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        case 'GET_CROP_STATISTIC_FAILURE':
            return {
                ...state,
                data: action.data,
                errorCode: action.errorCode
            };
        default:
            return state;
    }
}
export default reducer;