/*eslint-disable*/
import React from "react";
import moment from 'moment';
import { withRouter } from 'react-router-dom';
// core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import { withStyles } from "@material-ui/styles";
import { connect } from 'react-redux';
import {
    XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend
} from 'recharts';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from "axios";
import { LINK_API } from "../../../../constants/API";
import strings from "../../../../constants/strings";


class Charts extends React.Component {
    constructor(props) {
        super(props)
        this.year = new Date().getFullYear();
        this.state = {
            startDate: new Date(this.year - 1, 12, 1),
            endDate: new Date(),
            data_SLTH: [],
            options: [],
            listData: [],
            listDataTH: [],
            maxSL: 0,
            minSL: 0,
            maxTH: 0,
            minTH: 0,
            data: [],
        }
        this.options = [
        ];
        this.options_ThuHoach = [{ value: "DAY", label: "Ngày" }, { value: "WEEK", label: "Tuần" }, { value: "MONTH", label: "Tháng" }, { value: "YEAR", label: "Năm" }]
        this.handleMinMax = this.handleMinMax.bind(this);
        this.handleData = this.handleData.bind(this);
        this.handleClickPoint = this.handleClickPoint.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);
        this.handleDataView = this.handleDataView.bind(this);
    }


    handleDateChange = async (date) => {
        await this.setState({ startDate: date });
        await this.handleDataView(this.state.data);
    }

    handleDateEndChange = async (date) => {
        await this.setState({ endDate: date });
        await this.handleDataView(this.state.data);
    }

    handleDataView(data){
        let start = this.state.startDate;
        let end = this.state.endDate;
        let month_start = start.getMonth();
        let year_start = start.getFullYear();
        let month_end = end.getMonth();
        let year_end = end.getFullYear();

        if(month_start === 12){
            month_start = 1;
            year_start = year_start + 1;
        }
        else{
            month_start = month_start + 1;
        }

        if(month_end === 12){
            month_end = 1;
            year_end = year_start + 1;
        }
        else{
            month_end = month_end + 1;
        }

        let listData = [];
        let number = [];

        let data_filter = data.filter(x=>{
            if(x.year > year_start){
                if(x.year < year_end) {
                    return x;
                }
                else if(x.year == year_end && x.month <= month_end) {
                    return x;
                }
            }
            else if(x.year == year_start && x.month >= month_start) {
                if(x.year < year_end){
                    return x;
                }
                else if(x.year == year_end && x.month <= month_end){
                    return x;
                }
            }
        })


        for (let i = 0; i < data_filter.length; i++) {
            number.push(data_filter[i].count);
            number.push(data_filter[i].quantity);
            listData.push({
                name: data_filter[i].month + "/" + data_filter[i].year,
                count: data_filter[i].count,
                quantity: data_filter[i].quantity
            })
        }

        this.handleMinMax(number, "maxTH", "minTH");
        this.setState({ listDataTH: listData })

    }

    async handleMinMax(data, maxSL, minSL) {

        let max = 0;
        let min = 0;
        for (var i = 0; i < data.length; i++) {
            if (max < data[i]) max = data[i];
            if (min > data[i]) min = data[i];
        }

        await this.setState({
            [maxSL]: max + 50,
            [minSL]: min == 0 ? 0 : min - 1
        })
    }

    async componentDidMount() {

        this.props.getGeneralStampActive();
        if (this.props.generalStampActive) {
            await this.handleData(this.props.generalStampActive);
        }
        await axios.get(LINK_API.GET_HARVEST_NUM)
            .then(res => {
                res.data.data.sort(function (a, b) {
                    return a.year - b.year || (a.month - b.month);
                });

                this.setState({data: res.data.data});
                this.handleDataView(res.data.data);
            })
            .catch(err => {
                console.log(err)
            })

    }
    componentWillReceiveProps(preProps) {
        if (preProps.generalStampActive !== this.props.generalStampActive) {
            if (preProps.generalStampActive) {
                this.handleData(preProps.generalStampActive)
            }
        }
    }
    async handleClickPoint(evt) {
        await this.props.checkStamp(true)
        let enddate = "";
        let month = evt.payload.name.substring(1, 3);
        let year = moment().format('YYYY');
        let startdate = `${month}/01/${year}`
        if (month === "1" ||
            month === "3" ||
            month === "5" ||
            month === "7" ||
            month === "8" ||
            month === "10" ||
            month === "12"
        ) {
            enddate = `${month}/31/${year}`
        }
        else {
            enddate = `${month}/30/${year}`
        }
        this.props.getInfoActiveStamp(startdate, enddate);
        this.props.history.push("/admin/stamp")
    }
    handleData(data) {
        let listData = [];
        let number = [];
        if (data) {
            for (var i = 1; i < 13; i++) {
                let dataBox = data.stampBox.filter((item) => {
                    return item.month === i
                })

                let dataUnit = data.stampUnit.filter((item) => {
                    return item.month === i
                })

                let numUnit = dataUnit[0] ? dataUnit[0].count : 0;
                let numBox = dataBox[0] ? dataBox[0].count : 0;

                number.push(numUnit);
                number.push(numBox);


                listData.push({
                    name: "T" + i,
                    unit: numUnit,
                    box: numBox
                })
            }

            this.handleMinMax(number, "maxSL", "minSL");
            this.setState({ listData })
        }
    }
    render() {
        const { classes } = this.props;
        // console.log(this.props);
        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <div style={{ zIndex: "0 !important" }}>
                            <CardHeader >
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h4 className="cardtableLabel">{strings.activatingStampsProduct}</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                        </div>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div style={{ marginLeft: 14, marginTop: "-10px", marginBottom: 12 }}>
                                            {/* <div style={{ fontSize: 11, marginBottom: 13 }}>Ngày bắt đầu</div> */}
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                value={this.state.startDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className="stylePickTime"
                                            // style={{ backgroundColor: "white", marginTop: "-11px", paddingTop: 5 }}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                                <div style={{ marginTop: "-13px", marginLeft: 10 }}>{" - "}</div>
                                <GridItem xs={12} sm={12} md={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div style={{ marginLeft: 14, marginTop: "-10px", marginBottom: 12 }}>
                                            {/* <div style={{ fontSize: 11, marginBottom: 13 }}>Ngày kết thúc</div> */}
                                            <KeyboardDatePicker
                                                style={{ border: "1 solid black" }}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                value={this.state.endDate}
                                                onChange={this.handleDateEndChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className="stylePickTime"
                                            // style={{ backgroundColor: "white", marginTop: "-11px", paddingTop: 5 }}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                            </GridContainer>
                            <ResponsiveContainer height={300}>
                                <LineChart data={this.state.listData} margin={{
                                    top: 0, right: 0, left: -30, bottom: 0,
                                }}>
                                    {/* <div style={{overflowX: "scroll"}}> */}
                                    <XAxis dataKey="name" />
                                    <YAxis orientation={'left'} domain={[this.state.minSL, this.state.maxSL]} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Legend />

                                    <Line type="monotone" name={strings.totalActiveStampBox} dataKey="box" stroke="#8884d8" activeDot={{ onClick: (evt) => { this.handleClickPoint(evt) } }} />
                                    <Line type="monotone" name={strings.totalActiveStampProduct} dataKey="unit" stroke="#82ca9d" activeDot={{ onClick: (evt) => { this.handleClickPoint(evt) } }} />
                                    {/* </div> */}
                                </LineChart>
                            </ResponsiveContainer>
                        </CardBody>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <div style={{ zIndex: "0 !important" }}>
                            <CardHeader >
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h4 className="cardtableLabel">{strings.cropYieldsheader}</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                        </div>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div style={{ marginLeft: 14, marginTop: "-10px", marginBottom: 12 }}>
                                            {/* <div style={{ fontSize: 11, marginBottom: 13 }}>Ngày bắt đầu</div> */}
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                value={this.state.startDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className="stylePickTime"
                                            // style={{ backgroundColor: "white", marginTop: "-11px", paddingTop: 5 }}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                                <div style={{ marginTop: "-13px", marginLeft: 10 }}>{" - "}</div>
                                <GridItem xs={12} sm={12} md={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div style={{ marginLeft: 14, marginTop: "-10px", marginBottom: 12 }}>
                                            {/* <div style={{ fontSize: 11, marginBottom: 13 }}>Ngày kết thúc</div> */}
                                            <KeyboardDatePicker
                                                style={{ border: "1 solid black" }}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                value={this.state.endDate}
                                                onChange={this.handleDateEndChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className="stylePickTime"
                                            // style={{ backgroundColor: "white", marginTop: "-11px", paddingTop: 5 }}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                            </GridContainer>
                            <ResponsiveContainer height={300}>
                                <LineChart data={this.state.listDataTH} margin={{
                                    top: 0, right: 0, left: -30, bottom: 0,
                                }}>
                                    <XAxis dataKey="name" />
                                    <YAxis orientation={'left'} domain={[this.state.minTH, this.state.maxTH]} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" name={strings.totalHarvest} dataKey="count" stroke="#8884d8" />
                                    <Line type="monotone" name={strings.totalcropYields} dataKey="quantity" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getObject: () => dispatch({ type: "GET_OBJECT_LIST" }),
        getGeneralStampActive: () => dispatch({ type: "GET_STAMP_ACTIVE_GENERAL" }),
        getInfoActiveStamp: (startdate, enddate) => dispatch({ type: "GET_INFO_ACTIVE_STAMP", startdate: startdate, enddate: enddate }),
        checkStamp: (data) => dispatch({ type: "CHECK_STATUS_STAMP", data: data })
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        objectList: state.objectList,
        generalStampActive: state.generalStampActive.data,
        infoActiveStamp: state.infoActiveStamp.data
    }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Charts)));