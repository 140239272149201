import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import Gallery from '../../components/Forms/Gallery/Gallery';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Add from "@material-ui/icons/Add";
import Key from "@material-ui/icons/VpnKey";
import Clear from "@material-ui/icons/Clear";
import GetApp from "@material-ui/icons/GetApp"

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CreateFarm from "./CreateFarm.jsx";

import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Tooltip from '@material-ui/core/Tooltip';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import defaultAvatar from "assets/img/default-avatar.png"
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Icon from "@material-ui/core/Icon";
import Done from "@material-ui/icons/DoneAll";
import Edit from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";

import ReactExport from "react-export-excel";
import strings from "../../../../constants/strings";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ethers = require('ethers');
const CryptoJS = require("crypto-js");
const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  tooltip:{
    padding: "10px 15px",
    minWidth: "130px",
    color: "#FFFFFF",
    lineHeight: "1.7em",
    background: "rgba(85,85,85,0.9)",
    border: "none",
    borderRadius: "3px",
    opacity: "1!important",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: "200px",
    textAlign: "center",
    fontFamily: 'Muli',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto"
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     // openCreateFarm: openCreateFarm,
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
    };
    this.token = cookie.load('token');
    this.handleStatusEmployee = this.handleStatusEmployee.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.getFarms = this.getFarms.bind(this)
    this.checkPermission = this.checkPermission.bind(this);
    this.genTable = this.genTable.bind(this)
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleResetPassEmployee = this.handleResetPassEmployee.bind(this)
    this.handleinput = this.handleinput.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.onConfirmDataEmp = this.onConfirmDataEmp.bind(this);
    this.changePassEmp = this.changePassEmp.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  handleConfirm(name, uuid, check){
    this.setState({
      alert:
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title= {check === false ? "Vô hiệu hóa tài khoản" : "Kích hoạt tài khoản"}
            onConfirm={() => this.handleStatusEmployee(uuid)}
            onCancel={() => {
              this.hideAlert();
              this.setState({openCreateAcount:true});
            }}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Xác nhận"
            cancelBtnText="Hủy bỏ"
            showCancel
          >
            {name + " - " + uuid}
        </SweetAlert>,
    })
  }
  handleEdit(uuid){
    cookie.save("uuidEmp", uuid, {path: "/"});
    this.props.history.push("/admin/farms/edit")
  }
  handleStatusEmployee(uuid){
    let body = {"uuid": uuid};
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });
    axios.post(LINK_API.CHANGE_STATUS_EMPLOYEE, body)
    .then(res=>{
      if(res.data.errorCode === 1){
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-200px" }}
              title="Thành công!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              //confirmBtnText="Đăng nhập"
              showConfirm={false}
              cancelBtnText="Đóng"
              showCancel
            ></SweetAlert>
          )
        });
        this.props.getFarms();
      }else{
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block' }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
            />
          )
        });
      }  
    })
    .catch(err=>{
      console.log(err);
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block' }}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          />
        )
      });
    })
  }
  handleinput = (name) => async (event) => {
    await this.setState({
      [name]: event.target.value
    });
  document.getElementById('noti_active').innerHTML = '';
  }

  handleRole(type){
    if (type === "SM") return "Nhân viên nuôi trồng";
    if (type === "SP") return "Nhân viên chế biến";
    if (type === "SF") return "Nhân viên phân phối";
    if (type === "SD") return "Nhân viên vận chuyển";
    if (type === "SR") return "Nhân viên bán lẻ";
    return "Quản lý";
  }
  
  genTable = (farmList) => {
    //-----------------------
    let exportData = [];
    exportData = farmList.map(item => {
      return ({
        uuid: item.uuid,
        name: item.name,
        time: moment(item.createdAt).format("DD/MM/YYYY"),
        username: item.username,
        role: this.handleRole(item.role),
      })
    })

    this.setState({ export: exportData })
    //-----------------------

    this.setState({
      data: farmList.map((prop, key) => {
        return {
          id: key + 1,
          name: 
          <div style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              {
                prop.avatar !== undefined && prop.avatar !== "" && prop.avatar?<Gallery
                images={[{
                  src: prop.avatar.secure_url,
                  thumbnail: prop.avatar.secure_url,
                  caption: ""
                }]}
                rowHeight= {37}
                enableLightbox={true}
                enableImageSelection={false}
              />: 
              <Gallery
                images={[{
                  src: defaultAvatar,
                  thumbnail: defaultAvatar,
                  caption: ""
                }]}
                rowHeight= {37}
                enableLightbox={true}
                enableImageSelection={false}
              />
              }
            </GridItem>
            <GridItem xs={12} sm={12} md={7} style={{display: "flex", alignItems: "center"}}>
              <p style={{ marginLeft: "-24px", marginTop: 12, textAlign: "left" }}>{prop.name}</p>
            </GridItem>
          </GridContainer>
        </div>,
          createAt: moment(prop.date).format("DD/MM/YYYY"),
          // objects: prop.management.length > 1 ? "Tất cả" : prop.management.length === 1 ? prop.management[0] : "",
          empid: prop.uuid,
          username: prop.username,
          role: this.handleRole(prop.role),
          actions:
          (
            <div className="actions-center">
                <Button
                  justIcon
                  round
                  //title="Xem chi tiết"
                  simple
                  onClick={() => this.handleEdit(prop.uuid)}
                  color="warning"
                  className="like"
                >
                  <Tooltip
                    id="tooltip-search"
                    title= {strings.edit}
                    placement="top"
                    disableHoverListener={false}
                    classes={{ tooltip: this.props.classes.tooltip }}>
                    <Edit />
                  </Tooltip>
                </Button>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => this.handleResetPassEmployee(prop.path, prop.uuid)}
                  color="warning"
                  className="like"
                >
                  <Tooltip
                    id="tooltip-search"
                    title= {strings.rePass}
                    placement="top"
                    disableHoverListener={false}
                    classes={{ tooltip: this.props.classes.tooltip }}>
                    <Key />
                  </Tooltip>
                </Button>
                {!prop.lock ? (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => this.handleConfirm(prop.name, prop.uuid, false)}
                  color="danger"
                  className="like"
                >
                  <Tooltip
                    id="tooltip-search"
                    title={strings.deActive}
                    placement="top"
                    disableHoverListener={false}
                    classes={{ tooltip: this.props.classes.tooltip }}>
                  <Clear />
                    </Tooltip>
                </Button>
              ):(
                <Button
                      justIcon
                      round
                      simple
                      onClick={() => this.handleConfirm(prop.name, prop.uuid, true)}
                      color="success"
                      className="like"
                    >
                      <Tooltip
                        id="tooltip-search"
                        title={strings.active}
                        placement="top"
                        disableHoverListener={false}
                        classes={{ tooltip: this.props.classes.tooltip }}>
                        <Done />
                      </Tooltip>
                    </Button>
              )}
            </div>
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  }
  hideAlert(){
    this.setState({
      alert: null
    })
  }
 async  changePassEmp(path,idEmp){
   if(!this.state.pass_user){
     document.getElementById("noti_active").innerHTML = "VUi lòng nhập mật khẩu tài khoản"
     return;
   }
   if(!this.state.newPass){
    document.getElementById("noti_active").innerHTML = "VUi lòng nhập mật khẩu mới cho tài khoản nhân viên"
    return;
  }
  if(this.state.pass_user !== this.props.userInfo.password){
    document.getElementById("noti_active").innerHTML = "Mật khẩu tài khoản không chính xác"
    return;
  }
  this.setState({
    alert: (
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Đang xử lý!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        showConfirm={false}
      >
        <div style={{ textAlign: "center" }}>
          <img
            alt="{name}"
            src={IMG_LOAD}
            style={{ height: "100px" }}
          />
        </div>
      </SweetAlert>
    )
  });
 
    var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.seedEncrypted.toString(), this.props.userInfo.password);
		const randomMnemonic = bytes.toString(CryptoJS.enc.Utf8);
		let walletPath = {
			standard: "m/44'/60'/0'/" + this.props.userInfo.data.currentIndexPath + '/' + path
		};
		let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
		let node = hdnode.derivePath(walletPath.standard);
		const privatekey = node.privateKey;
    const enPrivateKey_emp = await CryptoJS.AES.encrypt(privatekey, this.state.newPass.toString());
   // console.log("String2",privatekey)
		var temp_sub = new Buffer(this.state.newPass).toString('hex');
    const pass_en = await ethers.utils.keccak256('0x' + temp_sub);
    //console.log("passNEw",pass_en)
		var data = {
      idOfEmployee: idEmp,
      newPass: pass_en,
      newPrivatedEncrypted: enPrivateKey_emp.toString(),
      pathOfEmployee: path
    };
   this.onConfirmDataEmp(data);
  }
  async onConfirmDataEmp(data){
    axios.post(LINK_API.RESET_PASSWORD_EMPLOYEE, data)
    .then(async (response)=> {
      if(response.data.errorCode === 1){
        await this.setState({
					alert: (
						<SweetAlert
							success
							style={{ display: 'block' }}
							title="Thành công"
							onConfirm={() => this.hideAlert()}
							showCancel={false}
						/>
					)
				});
      }
    })
    .catch(async err=>{
      await this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block' }}
            title="Không thành công"
            onConfirm={() => this.hideAlert()}
            showCancel={false}
          />
        )
      });
    })
  }
  handleResetPassEmployee(path, id){
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ top:"40%"}}
          title="Nhập thông tin"
          onConfirm={() => this.changePassEmp(path, id)}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
          confirmBtnText="Xác nhận"
          cancelBtnText="Hủy"
          showCancel
        >
          <CustomInput
            success={this.state.pass_userState === 'success'}
            error={this.state.pass_userState === 'error'}
            helpText={this.state.pass_userHelp}
            variant="outlined"
            labelProps={{
              variant: 'outlined'
            }}
            formControlProps={{
              variant: 'outlined',
              fullWidth: true,
              className: classes.customFormControlClasses,
              onChange: this.handleinput('pass_user')
            }}
            value={this.state.pass_user}
            inputProps={{
              //value: this.state.pass_user,
              type: 'password',
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start" className={classes.inputAdornment}>
                  <Icon className={classes.inputAdornmentIcon}>lock</Icon>
                </InputAdornment>
              ),
              placeholder: 'Mật khẩu'
            }}
          />
          <CustomInput
            success={this.state.pass_userState === 'success'}
            error={this.state.pass_userState === 'error'}
            helpText={this.state.pass_userHelp}
            variant="outlined"
            labelProps={{
              variant: 'outlined'
            }}
            formControlProps={{
              variant: 'outlined',
              fullWidth: true,
              className: classes.customFormControlClasses,
              onChange: this.handleinput('newPass')
            }}
            value={this.state.newPass}
            inputProps={{
              //value: this.state.pass_user,
              type: 'password',
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start" className={classes.inputAdornment}>
                  <Icon className={classes.inputAdornmentIcon}>vpn_key</Icon>
                </InputAdornment>
              ),
              placeholder: 'Mật khẩu mới cho nhân viên'
            }}
          />
          <div>
            <p
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              id="noti_active"
              style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: 'red',
                fontFamily: 'Muli',
                marginTop: 5,
                marginBottom: 5
              }}
            />
          </div>
        </SweetAlert>
      )
    });
  }
  async checkPermission() {
      await axios.get(LINK_API.VERIFY_TOKEN,  { headers: {"Authorization" : `Bearer ${this.props.userInfo.data.token}`} })
      .then( async response => {
        if (response.data.errorCode != 1 || !response.data.data.valid ){
          return this.props.history.push("/");
        }
        else {
          axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
        }
      })
    .catch(async error => {
      console.log(error);
      return this.props.history.push("/");
    }) 
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.GET_EMPLOYEE_LIST);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      this.props.getFarms();
      if (this.role === "INACTIVE") {
        await this.setState({
          activeState: false,
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  async componentWillReceiveProps(preProps){
    if(preProps.employeeList !== this.props.employeeList){
      if(preProps.employeeList.data){
        if(preProps.employeeList.data.length > 0){
        this.genTable(Array.from(preProps.employeeList.data));
        }else{
          this.genTable([])
        }
      }
    }
  }

  handleNewClick(){
    this.props.history.push("/admin/farms/create")
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer style={{display: "flex", flexFlow: "center"}}>
                    <h4 style={{marginRight: 19}}className="cardtableLabel">{strings.empList}</h4>
                     <div>
                      <ExcelFile element={<Button justIcon color="success" className="like" simple round > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> </Button>}>
                        <ExcelSheet data={this.state.export} name="Nhân viên">
                          <ExcelColumn label="Mã nhân viên" value="uuid" />
                          <ExcelColumn label="Tên nhân viên" value="name" />
                          <ExcelColumn label="Ngày đăng ký" value="time" />
                          <ExcelColumn label="Tên đăng nhập" value="username" />
                          <ExcelColumn label="Vai trò" value="role" />
                          {/* <ExcelColumn label="Status" value="lastAction" /> */}
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                    </GridContainer>
                  </GridItem>
                 
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      {(this.props.userInfo.data.active  === true) ? <Button color="success" size="sm" onClick={this.handleNewClick}> <Add style={{ margin: "0px", marginRight: "5px" }} /> {strings.add} </Button> : null}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                 ref={(r) => {
                  this.selectTable = r;
                }}
                data={this.state.data}
                filterable
                // defaultPageSize={5}
                defaultFiltered={[{ id: "area", value: this.state.valueFilter }]}
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                onFilteredChange={(filtered) => {
                  const currentRecords = this.selectTable.getResolvedState().sortedData;

                  let expData = currentRecords.map(item => {
                    return ({
                      uuid: item._original.empid,
                      name: item._original.name.props.children.props.children[1].props.children.props.children.props.children,
                      time: item._original.createAt,
                      username: item._original.username,
                      role: item._original.role
                    })
                  })
                  this.setState({ export: expData });
                }}
                  // defaultPageSize={5}
                  nextText={strings.nextPage}
                  rowsText={strings.row}
                  ofText="/"
                  pageText={strings.page}
                  noDataText={strings.noData}
                  columns={[
                    {
                      Header: "#",
                      accessor: "id",
                      filterable: false,
                      maxWidth: 70,
                      className: "center",
                      Header: () => (
                        <div style={{
                          textAlign: "center"
                        }}>
                          #
                        </div>
                      )
                    },
                    {
                      Header: strings.nameEmp,
                      accessor: "name",
                      filterable: false
                    },
                    {
                      Header: strings.dateCreated,
                      accessor: "createAt",
                      filterable: false
                    },
                    {
                      Header: strings.username,
                      accessor: "username",
                      filterable: false
                    },
                    
                    {
                      Header: strings.empID,
                      accessor: "empid",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: strings.role,
                      accessor: "role",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: strings.actionHeader,
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* <Dialog
          open={this.state.openCreateFarm}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <CreateFarm onConfirm={this.showConfirmAlert} />
        </Dialog> */}
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFarms: () => dispatch({ type: "GET_EMPLOYEE_LIST"}),
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
           employeeList: state.employeeList}
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
