import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/icons/selectImage.png"
import defaultAvatar from "assets/img/pictureicon.png";
import removeIcon from "assets/icons/deleteButton.png"
const LZUTF8 = require('lzutf8');
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: false,
      portal: false,
      file: null,
      list:[],
      listview:[],
      listDelete:[],
      //listAdd: [],
      count:0,
      checkCount: 0,
      editAble: false,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  async componentDidMount(){
      await this.setState({
        checkCount: this.props.count,
        editAble: this.props.edit,
        listview: this.props.imageList,
        count: this.props.imageList.length,
        lengthCheck: this.props.imageList.length
      })
  }
  componentWillReceiveProps(preProps){
      if(preProps.count !== this.props.count){
          this.setState({
            checkCount: preProps.count
          })
      }
      if(preProps.imageList !== this.props.imageList){
        this.setState({
          imageList: preProps.imageList,
          lengthCheck: preProps.imageList.length,
          count: preProps.imageList.length,
        })
      }

  }
  async handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
     let file = e.target.files[0];
    let temp = this.state.listview;
    let compress = this.state.list;
    let temp_count = this.state.count;
    reader.onloadend =async () => {
        temp.push(reader.result);
        compress.push(LZUTF8.compress(reader.result, { outputEncoding: 'Base64' }));
        temp_count = temp_count + 1;
      await this.setState({
        list: compress,
        listview: temp,
        count: temp_count
      });
      this.props.onImageSubmit(this.state.list);
    };
    reader.readAsDataURL(file);
    this.refs.fileInput.value = null;
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
 async  handleRemove(idx) {
    let temp = this.state.count - 1;
    if(idx<this.state.lengthCheck){
      await this.state.listDelete.push(this.state.listview[idx])
      this.setState({
        lengthCheck: this.state.lengthCheck - 1
      })
    }
    await this.setState({
      count: temp,
      list: this.state.list.filter((s, sidx) => idx-this.state.lengthCheck !== sidx),
      listview: this.state.listview.filter((s, sidx) => idx !== sidx)
    });
    this.refs.fileInput.value = null;
    this.props.onDeleteImage(this.state.listDelete);
    this.props.onImageSubmit(this.state.list);
  }
  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div style={{display: "flex", flexWrap: "wrap"}}>          
          {
            this.state.listview.map((prop, key) => (
            key < 1?
            <div style={{padding:"10px 10px"}}>
          <div key={key} className="addImageProcess" style={{backgroundImage:`URL(${prop})`}}>
            <img src={removeIcon} onClick={() => this.handleRemove(key)} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
          </div> </div>:
           <div style={{padding:"10px 10px"}}>
          <div key={key} className="addImageProcess" style={{backgroundImage:`URL(${prop})`}}>
          <img src={removeIcon} onClick={() => this.handleRemove(key)} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
        </div>
         </div>
            ))
          }
          {this.state.count < 1 ? <img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." /> : this.state.count < this.state.checkCount ? 
         <div style={{padding:"10px 10px"}}>
         <img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." />  </div>: null}
        </div>
        <div>
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
