import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { ERROR_CODE } from 'constants/index.js';

import { API, IMG_LOAD, ADDRESS } from 'constants/index.js';
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
// import LockOutline from "@material-ui/icons/LockOutline";
import responseCode from "constants/errorCode"
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import LoginForm from "../AuthPage/index.jsx"
import RegisterForm from "../AuthPage/Register.jsx"
import loginFormStyle from "../AuthPage/loginFormStyle.jsx";
import logoVBC from "../../assets/img/logo_vbc.png"

import "assets/css/style.css"
import cookie from "react-cookies";
const ethers = require('ethers');
const Cryptr = require('cryptr');
const CryptoJS = require("crypto-js");

//BlockChain
// eslint-disable-next-line no-undef
const EthereumTx = require('ethereumjs-tx').Transaction;
// eslint-disable-next-line no-undef
const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider(`http://206.189.82.191:8000`));
//ABI
const genieIJSON = require("../../build/contracts/GenieIdentity.json");
const genieIABI = genieIJSON['abi'];
const genieIDeployed = new web3.eth.Contract(genieIABI, ADDRESS.GENIEIDENTITY_CONTRACT_ADDRESS);

const genieTJSON = require("../../build/contracts/GenieToken.json");
const genieTABI = genieTJSON['abi'];
const genieTDeployed = new web3.eth.Contract(genieTABI, ADDRESS.GENIETOKEN_CONTRACT_ADDRESS);
//Start render Component


const styles = {
  ...sweetAlertStyle,
  ...loginFormStyle
}
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      alert: null,
      cardAnimaton: "cardHidden",
      pass_save: "",
      datalogin: {
        email: "",
        pw_hash: "",
      },
      pathCount: "",
    };
    this.bcAddress = cookie.load("bcAddress")
    this.processContent = this.processContent.bind(this);
    this.processContent_sub = this.processContent_sub.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handleprocess = this.handleprocess.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }
  async componentDidMount() {
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    
    //console.log(this.state.pathCount)
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  processContent() {
    document.getElementById("login").style = "display: none";
    document.getElementById("register").style = "display: block; width: 100%; display: flex; justify-content: center;"
  }
  processContent_sub() {
    this.setState({
      alert: null
    });
    document.getElementById("login").style = "display: block; width: 100%; display: flex; justify-content: center;";
    document.getElementById("register").style = "display: none"
  }
  // estimateGas = async (from, nonce, to, data) =>{
  //   const gasLimit = await web3.eth.estimateGas({
  //     "from"      : from,       
  //     "nonce"     : nonce, 
  //     "to"        : to,     
  //     "data"      : data
  //   })
  //   return gasLimit;
  //   }

  estimateGas = async (from, nonce, to, data) =>{
    const body = {
      functionName: "estimateGas",
      Option: {
        "from"      : from,       
        "nonce"     : nonce, 
        "to"        : to,     
        "data"      : data
      }
    }

    let response = "";
    try{
      response = await axios.post(API.LINK_API.GET_INFO_WEB3, body)
      const gasLimit = response.data.data.gasLimit;
    return gasLimit;
    } catch {
      console.log("ERROR");
      return null;
    }

    
  }

  RegisterIdentifier = async (uuid) =>{
    const body = {
      functionName: "RegisterIdentifier",
      Option: {
        "uuid"      : uuid
      }
    }

    let response = "";
    try{
      response = await axios.post(API.LINK_API.GET_INFO_WEB3, body)
      const data = response.data.data.data;
    return data;
    } catch {
      console.log("ERROR");
      return null;
    }

    
    }
  
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  async handleSubscribe(data) {
    await this.setState({
      pass_save : data.pw_hash
    })
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    const path_get = await axios.get(API.LINK_API.GET_PATH_USER);
    await this.setState({
      pathCount: path_get.data.data.countMasterAccount.toString()
    })
    //console.log(this.state.pathCount)
    let wallet = ethers.Wallet.createRandom();
    let randomMnemonic = wallet.mnemonic;
    let walletPath = {
      "standard": "m/44'/60'/0'/" + this.state.pathCount +"/0",
    };
    let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
    let node = hdnode.derivePath(walletPath.standard);

    const accountAddress = node.address;
    // console.log("address of account", accountAddress)
    const privatekey = node.privateKey;
    // console.log("PrivateKey: ", privatekey)

    const privatekey_slice = privatekey.substring(2,privatekey.length)
    // console.log("privatekey_slice", privatekey_slice)
    const encryptedSeed = await CryptoJS.AES.encrypt(randomMnemonic, data.pw_hash);
    const encryptedPrivateKey = await CryptoJS.AES.encrypt(privatekey, data.pw_hash);
    // console.log("encrypted PrivateKey: ",encryptedPrivateKey )
    var temp = new Buffer(data.pw_hash).toString('hex')
    const encryptedPass = await ethers.utils.keccak256('0x' + temp);
    // console.log("en Pass",encryptedPass)
    //input data to register 
    data.pw_hash = encryptedPass; //pass hash
    data.privateEncrypted = encryptedPrivateKey.toString(); // encrypted privatekey
    data.seedEncrypted = encryptedSeed.toString(); // encrypted seed
    data.bcAddress = accountAddress; // address of account on bc
    const login_data = data;
    data.currentIndexPath = this.state.pathCount; // index path of account
    axios.post(API.LINK_API.REGISTER, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-200px" }}
                title="Thành công"
                onConfirm={() => this.handleSignIn(login_data)}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Đăng nhập"
                cancelBtnText="Đóng"
                showCancel
              ></SweetAlert>
            )
          });
           const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
          // console.log("Buffer key", Buffer_privatekey)
          const tx = new EthereumTx(response.data.data.raw, { chain: 4 });
          // console.log("raw data received", response.data.data.raw)
          // console.log("tx", tx.serialize().toString('hex'))
        await tx.sign(Buffer_privatekey);
        // console.log("tx signed", tx.serialize().toString('hex'))
         const rawTx = '0x' + tx.serialize().toString('hex');
         const raw_to_send = {
          "raw": rawTx,
          "sign": "none",
          "date": "none",
          "bcAddress": accountAddress,
          "uuid": response.data.data.uuid,
          "nonce": "none"
         }
        //  console.log("Raw to send: ",raw_to_send)
         axios.post(API.LINK_API.SEND_RAW, raw_to_send)
         .then(response => {
          //console.log(response.data.data)
         })
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block", marginTop: "-200px" }}
                title="Không thành công!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Đăng nhập"
                cancelBtnText="Đóng"
                showConfirm={false}
                showCancel
              ></SweetAlert>
            )
          });
        }
      }).catch(async (error) => {
        console.log(error)
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-200px" }}
              title={`${responseCode[error.response.data.name].vi}`}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Đăng nhập"
              cancelBtnText="Đóng"
              showConfirm={false}
              showCancel
            ></SweetAlert>
          )
        });
      });
  }
  async handleSignIn(data) {
    const datatosend = await {
      email: data.email,
      pw_hash: this.state.pass_save
    }
    await this.setState({
      datalogin: datatosend
    })
   this.processContent_sub();
  }
  handleprocess = (data) => {
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          onConfirm={() => this.handleSubscribe(data)}
          onCancel={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
          {/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
        </SweetAlert>,
    })


  }
  
  render() {
    const { classes } = this.props;
    return (
      <div className="backgroundLogin">
        <GridContainer justify="center" style={{display: 'flex', alignItems: 'center'}}>
          <div id="login" style={{width: "100%", display: "flex", justifyContent: "center"}}>
          <GridItem xs={12} sm={6} md={4} className="vertical-center">
            <LoginForm onProcess = {this.processContent} datalogin ={this.state.datalogin}/>
          </GridItem>
          <div className="vertical-logo">
            <img src={logoVBC}/>
          </div>
          </div>
          <div id="register" style={{ width: "100%", display: "flex", justifyContent: "center", display: "none" }}>
            <GridItem className="vertical-center">
              <RegisterForm onRegister={this.handleprocess} onProcess={this.processContent_sub} />
            </GridItem>
          </div>
        </GridContainer>  
        {this.state.alert}
      </div>
    );
  }
}
LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginPage);
