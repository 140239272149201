const portalIcon =[
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570529251/Icons/Portal/agri_w84mgf.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570529251/Icons/Portal/agri_w84mgf.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Mặc định",
        name: "agri.png",
        vi: "Mặc định",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437364/Icons/Portal/box_tfs5fi.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437364/Icons/Portal/box_tfs5fi.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Đóng gói",
        name: "box.svg",
        vi: "Đóng gói",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437396/Icons/Portal/water-drop_ch46ek.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437396/Icons/Portal/water-drop_ch46ek.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Tưới nước",
        name: "water-drop.svg",
        vi: "Tưới nước",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437392/Icons/Portal/tomato_kminpm.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437392/Icons/Portal/tomato_kminpm.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Cà chua",
        name: "tomato.svg",
        vi: "Cà chua",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437380/Icons/Portal/process_xyzx2h.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437380/Icons/Portal/process_xyzx2h.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Chế biến",
        name: "process.svg",
        vi: "Chế biến",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437379/Icons/Portal/picking_qhcaoj.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437379/Icons/Portal/picking_qhcaoj.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Chăm sóc",
        name: "picking.svg",
        vi: "Chăm sóc",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437378/Icons/Portal/pesticide_uyh58g.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437378/Icons/Portal/pesticide_uyh58g.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Phun thuốc",
        name: "pesticide.svg",
        vi: "Phun thuốc",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437377/Icons/Portal/mango_nukbxm.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437377/Icons/Portal/mango_nukbxm.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Xoài",
        name: "mango.svg",
        vi: "Xoài",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437382/Icons/Portal/rang_v6l6us.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437382/Icons/Portal/rang_v6l6us.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Rang",
        name: "rang.svg",
        vi: "Rang",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437372/Icons/Portal/harvest_s6z86m.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437372/Icons/Portal/harvest_s6z86m.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Thu hoạch",
        name: "harvest.svg",
        vi: "Thu hoạch",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437367/Icons/Portal/delivery-truck_vmwmvm.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437367/Icons/Portal/delivery-truck_vmwmvm.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Vận chuyển",
        name: "delivery-truck.svg",
        vi: "Vận chuyển",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437368/Icons/Portal/fertilizer_majvw6.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437368/Icons/Portal/fertilizer_majvw6.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Bón phân",
        name: "fertilizer.svg",
        vi: "Bón phân",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437364/Icons/Portal/bao_trai_jryx2s.png",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437364/Icons/Portal/bao_trai_jryx2s.png",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Bao trái",
        name: "bao_trai.svg",
        vi: "Bao trái",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437366/Icons/Portal/coldtomato_bj4vfv.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437366/Icons/Portal/coldtomato_bj4vfv.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Làm lạnh",
        name: "coldtomato.svg",
        vi: "Làm lạnh",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437374/Icons/Portal/incubator_l0lndo.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437374/Icons/Portal/incubator_l0lndo.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Lên giàng",
        name: "incubator.svg",
        vi: "Lên giàng",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437377/Icons/Portal/mixer_gu2ved.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437377/Icons/Portal/mixer_gu2ved.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Trộn",
        name: "mixer.svg",
        vi: "Trộn",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437374/Icons/Portal/lenmen_rvxed3.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437374/Icons/Portal/lenmen_rvxed3.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption:"Lên men",
        name: "lenmen.svg",
        vi: "Lên men",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437370/Icons/Portal/garlic_iy7qfh.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437370/Icons/Portal/garlic_iy7qfh.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Tỏi",
        name: "garlic.svg",
        vi: "Tỏi",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437394/Icons/Portal/vegetable_j6ngtd.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437394/Icons/Portal/vegetable_j6ngtd.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Rau",
        name: "vegetable.svg",
        vi: "Rau",
    },
    {
        src:"https://res.cloudinary.com/agridential/image/upload/v1570437387/Icons/Portal/sprout_cxkqzj.svg",
        thumbnail:"https://res.cloudinary.com/agridential/image/upload/v1570437387/Icons/Portal/sprout_cxkqzj.svg",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        caption: "Gieo hạt",
        name: "sprout.svg",
        vi: "Gieo hạt",
    },
]
export default portalIcon