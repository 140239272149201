import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Tabs from '../../components/CustomTabs/LookupTabs';

import Journey from './journey';
import Manufacturer from './manufacturer';
import Overview from './overview';
import Certificates from './certificates';
import strings from 'constants/strings';

import styles from './infoStyles';
import Button from "components/CustomButtons/Button.jsx";
import data from '../../../data';
import iphone0 from "assets/img/iphone_frame0.png";
import iphone1 from "assets/img/iphone_frame1.png";
import Footer from "./Footer.jsx"
import './css/sample.css';
import $ from 'jquery';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
class LookupFruit extends React.Component {
  constructor(props) {
    super(props);
    const { productInfo, cooperativeInfo, diary, certificates } = this.props.lookup_data;
    this.state = {
      lookup_data: { ...data, productInfo, cooperativeInfo, diary, certificates },
      valuetab: this.props.valuetab,
    };
    this.scrollToTop = this.scrollToTop.bind(this);
    this.lookup_gln = this.props.lookup_gln;
    this.isSampleGLN = this.props.sample;
  }

  componentDidUpdate(preProps) {
    if (this.props.lookup_data !== preProps.lookup_data) {
      //console.log('props',this.props.lookup_data);
      const { productInfo, cooperativeInfo, diary, certificates } = this.props.lookup_data;
      this.setState({
        lookup_data: { ...this.state.lookup_data, productInfo, cooperativeInfo, diary, certificates }
      });
      //console.log('state',this.state.lookup_data);
    }
    if(this.props.valuetab !== preProps.valuetab){
      this.setState({
        valuetab: this.props.valuetab
      })
    }
  }
  componentDidMount(){
    if(this.props.setClick){
      //console.log("Hello")
      this.props.setClick(this.scrollToWithContainer);
      this.props.setClick_start(this.scrollToWithContainer_start);
    }
    Events.scrollEvent.register('begin', function () {
     // console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function () {
      //console.log("end", arguments);
    });}
    scrollToTop() {
      scroll.scrollToTop();
    }
    scrollTo() {
      scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'iphonemockup',
      })
    }
    scrollToWithContainer() {

      let goToContainer = new Promise((resolve, reject) => {
  
        Events.scrollEvent.register('end', () => {
          resolve();
          Events.scrollEvent.remove('end');
        });
  
        scroller.scrollTo('mockupcontent_end', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
  
      });
  
      goToContainer.then(() =>
        scroller.scrollTo('mockupcontent2_end', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          containerId: 'mockupcontent_end'
        }));
    }
    scrollToWithContainer_start() {

      let goToContainer = new Promise((resolve, reject) => {
  
        Events.scrollEvent.register('end', () => {
          resolve();
          Events.scrollEvent.remove('end');
        });
  
        scroller.scrollTo('mockupcontent_end', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
  
      });
  
      goToContainer.then(() =>
        scroller.scrollTo('mockupcontent2_start', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          containerId: 'mockupcontent_end'
        }));
    }
    componentWillUnmount() {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    }
  render() {
    const { classes } = this.props;

    strings.setLanguage('vi');

    return (
      <div id="mobilescroll" className="parrentMockup">
        <img src={iphone0} style={{ position: 'absolute', zIndex: 5000, top: 10, }} />
        <img src={iphone1} style={{ position: 'absolute', zIndex: 5000, bottom: 2, }} />
        <div className="iphonemockup" >
          <GridContainer id="mockupcontent_end" className="mockupcontent" style={{ width: "100%", margin: "0px", padding: "0px 0px !important" }}>
            {/* <CustomerFeedback/> */}
            {/* <GridItem xs={12} sm={12} className = {classes.noPadding} style={{marginTop:"-50px"}}>
            
              </GridItem> */}
              <div id="mobilescroll2">
              <GridItem xs={12} sm={12} style={{ marginTop: "-70px" }} className={classes.noPaddingCard}>
              <Card style={{ padding: "0px 0px !important" }}>
                <div name="mockupcontent2_start">
                <Overview ref={"1111"} data={{ productInfo: this.state.lookup_data.productInfo, cooperativeInfo: this.state.lookup_data.cooperativeInfo, isSampleGLN: this.isSampleGLN, lookup_gln: this.lookup_gln }} />
                </div>
                <div name="mockupcontent2_end">
                <Tabs
                  valuetab={this.state.valuetab}
                  title=""
                  tabs={[{
                    tabName: strings.origin,
                    tabContent: (
                      <Manufacturer data={{ farmInfo: this.state.lookup_data.farmInfo, cooperativeInfo: this.state.lookup_data.cooperativeInfo }} />
                    )
                  },
                  {
                    tabName: "Quy trình",
                    tabContent: (
                      <Journey data={this.state.lookup_data.diary} />
                    )
                  },
                  {
                    tabName: strings.certificate,
                    tabContent: (
                      <Certificates data={{ certificates: this.state.lookup_data.certificates }} />
                    )
                  },
                  ]}
                />
                </div>
                
              </Card>
            </GridItem>
              </div>
            <div style={{ marginTop: "-18px" }}>
              <Footer name="scroll-to-element" />
            </div>
          </GridContainer>
        </div>
        {/* <a className="test1" to="test1" onClick={() => this.scrollToWithContainer()} >Scroll to element within container</a> */}
        {/* <Button id="btCt" size="sm" color="success" onClick={() => this.scrollToWithContainer()} className="btnfb">Chi tiết</Button> */}
      </div>
    );
  }
}

LookupFruit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LookupFruit);