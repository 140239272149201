import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import withStyles from "@material-ui/core/styles/withStyles";

import Login from './loginForm.jsx';

import Tabs from "./components/CustomTabs/LookupTabs.jsx";
import imgRight from 'assets/img/bg-2.jpg';

import logo from 'assets/img/bg-img/agdnew.png';

import loginFormStyle from "./loginFormStyle.jsx";
import Register from "./registerForm.jsx";
class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    }
  }
  componentDidMount(){
    this.setState({
      data: this.props.datalogin
    })
  }
  componentDidUpdate(preProps){
    if(preProps.datalogin !== this.props.datalogin){
      this.setState({
        data: this.props.datalogin
      })
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    return (
      <div style={{display:'flex', flexDirection:'row'}}>
        <div xs={12} sm={12} md={12} style = {{paddingTop:30, flex:1, backgroundColor:'white', textAlign:'center',width:"470px", borderRadius:"5px"}}>
          <img src={logo} style={{width:150}} />
          {/* <Tabs 
            tabs={[
              {
                tabName: "Đăng ký",
                tabContent: (
                  <Register/>        
                )
              },
              {
                tabName: "Đăng nhập",
                tabContent: (
                  <Login/>
                )
              },
            ]}
          /> */}
          <Login datalogin = {this.state.data} onProcess ={this.props.onProcess} />
        </div>
        {/*<div xs={12} sm={12} md={12} style = {{padding:'0px !important', flex:1, backgroundImage:`url(${imgRight})`, backgroundSize:'cover', backgroundPosition:'center', height:525}}>
            
        </div>*/}
         
      </div>
    );
  }
}

AuthPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginFormStyle)(withRouter(AuthPage));
