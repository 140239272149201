//const HOST = process.env.REACT_APP_ENV === 'dev' ?  'http://www.agridential.vn:8000' : 'https://www.agridential.vn' ;

const HOST = process.env.REACT_APP_ENV === 'dev' ?  'https://staging.agridential.vn' : 'https://account.agridential.vn' ;
const axios = require('axios');

const LINK_API = {
    REGISTER: HOST+'/api/user/register/',
    SIGN_IN: HOST+'/api/user/authen/',
    SIGN_OUT: HOST+'/api/user/logout',
    CHANGE_PWD: HOST+'/api/v1/users/changepassword',
    EDIT_PROFILE:  HOST+'/api/user/editprofile',
    GET_PROFILE: HOST + '/api/user/profile',
    CREATE_PRODUCT: HOST+'/api/v1/producttypes',
    GET_ALL_PRODUCTS: HOST+'/api/v1/producttypes',
    STOP_PRODUCTION: HOST+'/api/products/stopProduction',
    GET_ONE_PRODUCT_BY_INDEX: HOST+'/api/products',
    GET_PRODUCE_DIARY: HOST+'/api/cooperatives/diary',
    ADD_NEW_FARM: HOST+'/api/cooperatives/farms',
    GET_FARM_LIST: HOST+'/api/v1/productionareas',
    GET_FARM_INFO: HOST+'/api/farms',
    ADD_DIARY: HOST+'/api/farms/diary',
    CREATE_TREE: HOST+'/api/trees',
    GET_TREE_LIST: HOST+'/api/trees',
    GET_TREE: HOST+'/api/trees/info',
    CREATE_STAMP: HOST+'/api/stamps',
    GET_STAMP_HISTORY: HOST + '/api/v1/stamps',
    REGISTER: HOST+'/api/trial/register',
    VERIFY_TOKEN: HOST + '/api/token',
    GET_PROCESS_LIST: HOST + '/api/businessprocess/getall',
    GET_PROCESS_LIST_ALL: HOST + '/api/businessprocess?lg=en',
    REGISTER_PROCESS: HOST + '/api/businessprocess',
    STAMP_AUTHORIZE: HOST + '/api/stamp/authorize',
    GET_HISTORY_STAMP_AUTHORIZE: HOST + '/api/admin/stamp',
    GET_COOPERATIVES: HOST + '/api/admin/cooperatives',
    GET_COMPANY: HOST + '/api/v1/admin/companies',
    GET_STATICTIS: HOST + '/api/v1/statistics',
    GET_TREE_OF_NH: HOST + '/api/farms/trees?gln=',
    TRIAL: HOST + '/api/trial',
    SEND_MAIL: HOST + '/api/trial/sendemail',
    GET_IMPORT_LIST: HOST + '/api/retailer/diary',
    GET_PAID_PRODUCT: HOST + '/api/retailer/soldproduct?gln=',
    SELL_PRODUCT: HOST + '/api/v1/products/sell',
    GET_PRODUCT_STAMP: HOST + '/api/v1/diaries/baseinfo?gln=',
    CREATE_MATERIAL: HOST + '/api/materials',
    SELL_MUL_PRODUCT: HOST + 'api/retailer/sellmultiproduct',
    GET_PROCESS_LIST_PRODUCT: HOST + '/api/businessprocess/getall',
    GET_STAMP_LIST_RETAILER: HOST + '/api/retailer/diary?gln=',
    ADD_CERTIFICATES: HOST + '/api/user/addcertificates',
    DELETE_CERTIFICATES: HOST + '/api/user/deletecertificates',
    GET_PATH_USER: HOST + '/api/v1/users/path',
    GET_PATH_EMPLOY: HOST + '/api/v1/users/employees/path',
    CREATE_EMPLOYEE: HOST + '/api/v1/employees',
    GET_EMPLOYEE_LIST: HOST + '/api/v1/employees',
    CREATE_PRODUCTION_AREA: HOST + '/api/v1/productionareas',
    GET_AREA_LIST: HOST + '/api/v1/productionareas',
    CREATE_OBJECT_PRODUCTION: HOST + '/api/v1/productionobjects?page=',
    GET_A_PRODUCTION: HOST + '/api/v1/productionobjects/detail?uuidOfProductionObject=',
    GET_CROP_LIST: HOST + '/api/v1/productionobjects/crops?uuid=',
    ASSIGN_CROP: HOST + "/api/v1/productionobjects/crops/assign",
    STOP_CROP: HOST + "/api/v1/productionobjects/crops/finish",
    VERIFY_ACCOUNT: HOST + "/api/users/verify",
    SEND_RAW: HOST + "/api/v1/users/register/rawtx",
    SEND_RAW_VERIFY: HOST + "/api/v1/users/verify/rawtx",
    SEND_RAW_EMPLOYEE: HOST + "/api/v1/employees/rawtx",
    SEND_RAW_CLAIM: HOST + "/api/v1/users/checkclaim",
    SEND_RAW_CROP: HOST + "/api/v1/productionobjects/crops/assign/rawtx",
    CURRENT_CROP: HOST + "/api/v1/productionobjects/currentcrops",
    EDIT_PROFILE_ADD_AVATAR: HOST + "/api/v1/users/addavatar",
    EDIT_PROFILE_DELETE_AVATAR: HOST + "/api/v1/users/deleteavatar",
    GET_ALL_CROPS: HOST + "/api/v1/productionobjects/croplist",
    GET_INFO_WEB3: HOST + "/api/v1/blockchain/web3",
    CREATE_OBJECT_PROCESS: HOST + "/api/v1/processingobjects",
    GET_OBJECT_PROCESS_DETAIL: HOST + "/api/v1/processingobjects/detail?uuidOfProductionObject=",
    GET_CROP_OBJECT_PROCESS: HOST + "/api/v1/processingobjects/batchs",
    CREATE_BATCH: HOST + '/api/v1/processingobjects/batchs',
    GET_RAW_BATCH: HOST + '/api/v1/processingobjects/batchs/pretx',
    EDIT_PROFILE_BANNER: HOST + "/api/v1/users/updatebanner",
    EDIT_PROFILE_LOGO: HOST + "/api/v1/users/updatelogo",
    CREATE_BRANCH: HOST + "/api/v1/companys/branchs",
    GET_BRANCH_LIST: HOST + "/api/v1/companys/branchs",
    GET_HARVEST_LIST: HOST + "/api/v1/productionobjects/harvests?flat=1",
    GET_DISTRIBUTOR_LIST: HOST + "/api/v1/companys/distributors",
    CREATE_DISTRIBUTOR: HOST + "/api/v1/companys/distributors",
    UPDATE_BANNER: HOST + "/api/v1/users/updatebanner",
    UPDATE_LOGO: HOST + "/api/v1/users/updatelogo",
    GET_INFO_DISTRIBUTOR: HOST + "/api/v1/users/getprofilebyid?uuid=",
    GET_RAW: HOST + "/api/v1/users/checkclaim/pretx",
    UPDATE_OBJECT_PRODUCTION: HOST + "/api/v1/productionobjects/updatebaseinfo",
    UPDATE_OBJECT_PROCESSING: HOST + "/api/v1/processingobjects/updatebaseinfo",
    SUBSCRIBE: HOST + "/api/v1/users/activation",
    GET_QUANTITIY: HOST + "/api/v1/statistics/stampWithDistributor?lg=vi",
    HARVEST_STATISTIC: HOST + "/api/v1/statistics/quantityHarvest",
    BRANCH_STATISTIC: HOST + "/api/v1/statistics/stampWithBranch?lg=vi",
    CUSTOMER_FEEDBACK: HOST + "/api/v1/feedback/?lg=vi",
    GET_SUBSCRIPTION: HOST + "/api/v1/subscription",
    RESET_PASSWORD_EMPLOYEE: HOST + "/api/v1/users/resetemployeepassword",
    GET_PLAN_LIST: HOST + "/api/v1/subscription/plan",
    CREATE_PLAN: HOST + "/api/v1/subscription/plan",
    POST_THIETLAP: HOST + "/api/v1/users/settings",
    CHECK_USERNAME: HOST + "/api/v1/users/username",
    GET_BOX_UNIT: HOST + "/api/v1/cases/distributor",
    GET_INFO_SELL_PRODUCT: HOST + "/api/v1/products/sell/pretx",
    GET_CROP_STATISTIC: HOST + "/api/v1/statistics/cropOfProductObjectStatistic?productObjectId=",
    GET_QUANTITIY_HARVEST: HOST + "/api/v1/statistics/harvestQuantityOfPOStatistic",
    GET_NUM_HARVEST: HOST + "/api/v1/statistics/harvestNumberOfPOStatistic",
    GET_GENERAL_STATISTIC: HOST + "/api/v1/statistics/general",
    GET_PRODUCT_OBJECT: HOST + "/api/v1/statistics/productionObjectStatistic",
    GET_RAW_APPROVE: HOST + "/api/v1/users/settings/approve",
    SEND_APPROVE: HOST + "/api/v1/users/settings/approve/rawtx",
    GET_SETTING: HOST + "/api/v1/users/settings",
    IMPORT_BOX: HOST + "/api/v1/cases",
    GET_INFO_BOX: HOST + "/api/v1/cases/receive/pretx",
    RECEIVE_BOX: HOST + "/api/v1/cases/receive",
    GET_STATISTIC_NUM_OBJECT: HOST + "/api/v1/statistics/countPO",
    GET_NUMBER_ACCESSES: HOST + "/api/v1/statistics/accessInfo",
    GET_PRODUCT_TO_BRANCH: HOST + "/api/v1/statistics/stampWithBranch?lg=vi",
    GET_PRODUCT_TO_DISTRIBUTOR: HOST + "/api/v1/statistics/stampWithDistributor?lg=vi",
    GET_APPORVE_LIST: HOST + "/api/v1/companys/approves",
    GET_INFO_APPROVE: HOST + "/api/v1/companys/approves/pretx",
    GET_NUMBER_CROP_PRODUCT_TYPE: HOST + "/api/v1/statistics/numberCropOfPTStatistic?",
    GET_ACTIVE_STAMP_PRODUCT_TYPE: HOST + "/api/v1/statistics/producTypeStatistic",
    GET_STAMP_ACTIVE_GENERAL: HOST + "/api/v1/statistics/getActivatedStamp",
    APPROVE_ACTIONS: HOST + "/api/v1/companys/approves",
    GET_PRODUCT_TYPE_STATISTIC: HOST + "/api/v1/statistics/producTypeStatistic",
    UPDATE_PRODUCT: HOST + "/api/v1/products/updatebaseinfo",
    GET_INFO_PROCESS: HOST + "/api/businessprocess?bizProcessCode=",
    GET_HARVEST_NUM: HOST + "/api/v1/statistics/getHarvestInfo",
    CHANGE_STATUS_EMPLOYEE: HOST + "/api/v1/employees/activation",
    LIST_OBJECT_PRODUCT: HOST + "/api/v1/productionobjects?zoneId=",
    UPDATE_PICTURE_OBJECT: HOST + "/api/v1/productionobjects/updateImage",
    UPDATE_PICTURE_OBJECT_PROCESSING: HOST + "/api/v1/processingobjects/updateImage",
    UPDATE_INFO_AREA: HOST + "/api/v1/productionareas",
    UPDATE_IMAGE_PRODUCT_ADD: HOST + "/api/v1/products/updateimage",
    UPDATE_IMAGE_PRODUCT_DELETE: HOST + "/api/v1/products/deleteimage",
    UPDATE_IMAGE_PRODUCT_ADD_CERTIFICATES: HOST + "/api/v1/products/updatecertificates",
    UPDATE_IMAGE_PRODUCT_DELETE_CERTIFICATES: HOST + "/api/v1/products/deletecertificates",
    UPDATE_INFO_EMPLOYEE: HOST + "/api/v1/employees/updatebaseinfo",
    GET_STAMP_WITH_DATE: HOST + '/api/v1/stamps/getWithDate',
    EDIT_PROCESS: HOST + "/api/businessprocess",
    GET_OBJECT_IN_AREA: HOST + "/api/v1/productionobjects?zoneId=",
    GET_INFO_HARVEST: HOST + '/api/v1/diaries/pretx',
    CREATE_HARVEST: HOST + "/api/v1/diaries",
    GET_INFO_ACTIVE_STAMP: HOST + "/api/v1/stamps/activatelists",
    ACTIVE_STAMP_COW: HOST + "/api/v1/stamps/activatelists/rawtx",
    GET_INFO_SHIPMENT: HOST + "/api/v1/cases/diaries/pretx",
    SHIPMENT_BOX: HOST + "/api/v1/cases/diaries",
    TRACKING_A_CATTLE: HOST + "/api/v1/diaries",
    SLAUGHTER_MONTH: HOST + "/api/v1/statistics/soldproductmonthly",
    GET_CATTLE: HOST + "/api/v1/stamps/created",
    GET_NUM_CATTLE: HOST + "/api/v1/statistics/cattleInfo?lg=vi",
    GET_NUM_PARTNER: HOST + "/api/v1/companys/distributorByName",
    GET_STAMP_IMPORT: HOST + "/api/v1/cases/stampInCase",
    GET_SHIPMENT_STATISTIC: HOST + "/api/v1/statistics/getdistributorfromstamp?idOfBox=",
    GET_LIST_BOX_OWN: HOST + "/api/v1/cases/owning",
    COMBINE_BOX: HOST + "/api/v1/cases/merges",
    COMBINE_BOX_RAW: HOST + "/api/v1/cases/merges/rawtx",
    GET_LIST_COW: HOST + "/api/v1/cases/distributor",
    FILTER_LIST_CATTLE: HOST + "/api/v1/stamps/created"
}


const ERR_CODE = {
    SUCCESS:1,
    ERROR:0,
}

const register = (data) => {
    axios.post(HOST + LINK_API.REGISTER, data)
    .then(response => {
        return {
            code:response.errorCode,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const signIn = (data) => {
    axios.post(HOST + LINK_API.SIGN_IN, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const createProduct = (data) => {
    axios.post(HOST + LINK_API.CREATE_PRODUCT, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getAllProduct = (gln) => {
    axios.get(HOST + LINK_API.GET_ALL_PRODUCTS, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getOneProductByIndex = (index) => {
    axios.get(HOST + LINK_API.GET_ONE_PRODUCT_BY_INDEX, {
        params: {
            index
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}


const getDiary = (gln) => {
    axios.get(HOST + LINK_API.GET_DIARY, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const addNewFarm = (data) => {
    axios.post(HOST + LINK_API.ADD_NEW_FARM, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getFarmList = (gln) => {
    axios.get(HOST + LINK_API.GET_FARM_LIST, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getFarmInfo = (gln) => {
    axios.get(HOST + LINK_API.GET_FARM_INFO, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const createTree = (data) => {
    axios.post(HOST + LINK_API.CREAT_TREE, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getTreeList = (gln) => {
    axios.get(HOST + LINK_API.GET_TREE_LIST, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const createStamp = (data) => {
    axios.post(HOST + LINK_API.CREATE_STAMP, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getStampHistory = (gln) => {
    axios.get(HOST + LINK_API.GET_STAMP_HISTORY, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const createStampTrial = (data) => {
    axios.post(LINK_API.TRIAL, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

export {
    register,
    signIn,
    createProduct,
    getAllProduct,
    getOneProductByIndex,
    getDiary,
    addNewFarm,
    getFarmList,
    getFarmInfo,
    createTree,
    getTreeList,
    createStamp,
    getStampHistory,
    LINK_API,
    createStampTrial,
};

export default {
    register,
    signIn,
    createProduct,
    getAllProduct,
    getOneProductByIndex,
    getDiary,
    addNewFarm,
    getFarmList,
    getFarmInfo,
    createTree,
    getTreeList,
    createStamp,
    getStampHistory,
    LINK_API,
    createStampTrial,

};