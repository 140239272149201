import React from "react";
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';
import { connect } from 'react-redux';
// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

// @material-ui/icons
// import MailOutline from "@material-ui/icons/MailOutline";
import People from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";
import FindInPage from "@material-ui/icons/Search";
import GetApp from "@material-ui/icons/GetApp";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CreateProduction from "./CreateProductionArea"
import Gallery from '../../components/Forms/Gallery/Gallery';
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Tooltip from '@material-ui/core/Tooltip';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css";
import profileagd from "assets/img/agdavatar.gif"
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props)

    this.hideAlert = this.hideAlert.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this)
    this.onSuccess = this.onSuccess.bind(this);

    this.user_gln = cookie.load("gln");
    this.role = cookie.load("checkrole");

    this.state = {
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
      export: [],
    };
    this.token = cookie.load('token');

  }
  isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }

  async handleClick(gln, name) {
    await cookie.save("glnobjectArea", gln, { path: "/" });
    await cookie.save("nameobjectArea", name, { path: "/" });
    this.props.history.push("/admin/productareas/object/statistic")
  }

  genTable = async (farmList) => {

    //------------------------

    let exportData = [];
    exportData = farmList.map(item => {
      return ({
        uuid: item.uuid,
        name: item.name,
        time: moment(item.createdAt).format("DD/MM/YYYY"),
        type: (item.type === 0) ? "Vườn" : (item.type === 1) ? "Nhà kính" : (item.type === 2) ? "Khu chế biến" : (item.type === 3) ? "Trang trại" : (item.type === 4) ? "Lò mổ" : (item.type === 5) ? "Depot" : "",
        address: item.address,
        amount: item.objectRegistered
      })
    })

    await this.setState({ export: exportData })

    //------------------------
    await farmList.reverse();
    this.setState({
      data: farmList.map((prop, key) => {
        return {
          id: prop.uuid,
          name:
            <div style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                  {
                    this.isEmptyObject(prop.image) ? <img src={profileagd} style={{ height: "37px", width: "37px" }} /> :
                      <Gallery
                        images={[{
                          src: prop.image.secure_url,
                          thumbnail: prop.image.secure_url,
                          caption: ""
                        }]}
                        rowHeight={37}
                        enableLightbox={true}
                        enableImageSelection={false}
                      />
                  }

                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <div><p style={{ marginLeft: "-24px", marginTop: 12, textAlign: "left" }}>{prop.name}</p></div>
                </GridItem>
              </GridContainer>
            </div>,
          time: moment(prop.createdAt).format("DD/MM/YYYY"),
          description: prop.description,
          city: prop.address.city,
          // district: prop.address.district,
          password: prop.password,
          moreinfo: prop.uuid,
          // number: prop.objectRegistered,
          type: (prop.type === 0) ? "Vườn" : (prop.type === 1) ? "Nhà kính" : (prop.type === 2) ? "Khu chế biến" : (prop.type === 3) ? "Feedlot" : (prop.type === 4) ? "Abattoir" : (prop.type === 5) ? "Depot" : "",
          view_detail: <div><Button justIcon color="transparent" onClick={() => this.handleClick(prop.uuid, prop.name)}>
            <FindInPage style={{ color: "#0f7343", width: 47 }} />
          </Button>
          </div>
        };
      }),
      load: "none",
      showTable: ""
    });
  }

  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }

  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.GET_AREA_LIST);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    try {
      await this.checkPermission();
      await this.props.getArea();
    }
    catch (error) {
      console.log(error)
    }
  }
  async componentWillReceiveProps(preProps) {
    if (this.props.productArea !== preProps.productArea) {
      await this.setState({ farmList: preProps.productArea, showTable: "none" });
      if (preProps.productArea) {
        await this.genTable(Array.from([]))
        await this.genTable(Array.from(preProps.productArea))
      }
    }

  }
  async onSuccess(x) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });

    // let that=this;

    axios.post(LINK_API.CREATE_PRODUCTION_AREA, x)
      .then(async response => {
        const farmList = await this.getFarms();
        this.genTable(farmList);

        await this.setState({
          farmList: farmList,
          alert: (
            <SweetAlert
              success
              style={{ display: "block" }}
              title="Thành công"
              onConfirm={() => this.hideAlert_success()}
              showCancel={false}
            >
            </SweetAlert>
          )
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      });

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  handleNewClick() {
    // document.getElementById("info1").style = "display: none";
    // document.getElementById("info2").style = "display: block; width: 100%;"
    // document.getElementById("info3").style = "display: block; width: 100%"
    this.props.history.push("/admin/areas/create")
  }
  handleNewClick_Sub() {
    document.getElementById("info1").style = "display: block; width: 100%";
    document.getElementById("info2").style = "display: none";
    document.getElementById("info3").style = "display: none";
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        <div id="info1">
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                    <GridItem lg={6} md={6} sm={6} xs={12}>
                      <h4 className="cardtableLabel">Danh sách vùng sản xuất</h4>
                    </GridItem>
                    <GridItem lg={6} md={6} sm={6} xs={12}>
                      <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                        <ExcelFile element={<Button color="success" style={{ color: "white" }} size="sm" > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> <a style={{ fontSize: "12px", textTransform: "none" }}>Dowload</a> </Button>}>
                          <ExcelSheet data={this.state.export} name="Employees">
                            <ExcelColumn label="UUID" value="uuid" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Created Time" value="time" />
                            <ExcelColumn label="Type" value="type" />
                            <ExcelColumn label="Total object" value="amount" />
                          </ExcelSheet>
                        </ExcelFile>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <div style={{ textAlign: "center", display: this.state.load }}>
                    <img
                      alt="{name}"
                      src={IMG_LOAD}
                      style={{ height: "100px" }}
                    />
                  </div>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    // defaultPageSize={5}
                    previousText="Trang Trước"
                    nextText="Trang Sau"
                    rowsText="hàng"
                    ofText="/"
                    pageText="Trang"
                    noDataText="Nhấn nút TẠO MỚI để đăng ký vùng sản xuất!"
                    columns={[
                      {
                        Header: "#",
                        accessor: "id",
                        filterable: false,
                        className: "center",
                        Header: () => (
                          <div style={{
                            textAlign: "center"
                          }}>
                            Mã vùng
                        </div>
                        )
                      },
                      {
                        Header: "Tên gợi nhớ",
                        accessor: "name",
                        filterable: false
                      },
                      {
                        Header: "Thời gian tạo",
                        accessor: "time",
                        sortable: false
                      },
                      // {
                      //   Header: "Mô tả",
                      //   accessor: "description",
                      //   filterable: false
                      // },
                      {
                        Header: "Loại",
                        accessor: "type",
                        sortable: false
                      },
                      {
                        Header: "Địa chỉ",
                        accessor: "city",
                        filterable: false
                      },
                      // {
                      //   Header: "Tổng đối tượng",
                      //   accessor: "number",
                      //   filterable: false
                      // },
                      {
                        Header: "Xem chi tiết",
                        accessor: "view_detail",
                        filterable: false
                      },

                    ]}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    sortable={false}
                    resizable={false}
                    className="-striped -highlight"
                    style={{ display: this.state.showTable }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <div id="info3" style={{ display: "none" }}>
          <GridItem lg={12} md={12} sm={12} xs={12} style={{ display: "flex", flexFlow: "row" }}>
            <ArrowLeft style={{ margin: "0px", marginRight: "5px" }} onClick={this.handleNewClick_Sub} />
            <p className="HeaderBack" onClick={this.handleNewClick_Sub}>Quay lại danh sách Vùng Sản Xuất</p>
          </GridItem>
        </div>
        {/* <div id="info2" style={{display: "none"}}>
        <CreateProduction onConfirm ={this.onSuccess}/>
     </div> */}
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getArea: () => dispatch({ type: "GET_AREA_LIST" }),
  }
}
const mapStateToProps = (state) => {
  return {
    productArea: state.productArea.data,
    userInfo: state.userInfo
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
