import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import Gallery from '../../components/Forms/Gallery/Gallery';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Add from "@material-ui/icons/Add";
import Key from "@material-ui/icons/VpnKey";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CreateFarm from "./CreateEmp.jsx";

import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Tooltip from '@material-ui/core/Tooltip';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import defaultAvatar from "assets/img/default-avatar.png"
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';

const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  }
};

class TableFarmList extends React.Component {
  constructor(props) {
    super(props);

   
    this.checkPermission = this.checkPermission.bind(this);
    this.genTable = this.genTable.bind(this)
    this.handleNewClick = this.handleNewClick.bind(this);
    this.state = {
     // openCreateFarm: openCreateFarm,
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      farmList: [],
      data: [],
      load: "",
      showTable: "none",
      activeState: true,
    };
    this.token = cookie.load('token');

  }


  genTable = async (farmList) => {
    await farmList.reverse();
    this.setState({
      data: farmList.map((prop, key) => {
        return {
          id: key + 1,
          name: 
          <div style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              {
                prop.avatar !== undefined && prop.avatar !== "" && prop.avatar?<Gallery
                images={[{
                  src: prop.avatar.secure_url,
                  thumbnail: prop.avatar.secure_url,
                  caption: ""
                }]}
                rowHeight= {37}
                enableLightbox={true}
                enableImageSelection={false}
              />: 
              <Gallery
                images={[{
                  src: defaultAvatar,
                  thumbnail: defaultAvatar,
                  caption: ""
                }]}
                rowHeight= {37}
                enableLightbox={true}
                enableImageSelection={false}
              />
              }
              
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <div><p style={{ marginLeft: "-24px", marginTop: 12, textAlign: "left" }}>{prop.name}</p></div>
            </GridItem>
          </GridContainer>
        </div>,
          createAt: moment(prop.createAt).format("DD/MM/YYYY"),
          objects: prop.management.length > 1 ? "Tất cả" : prop.management.length === 1 ? prop.management[0] : "",
          empid: prop.uuid,
          username: prop.username,
          role: prop.role === "SM" ? "Nhân viên" : (prop.role === "M") ? "Quản lý" : "",
          actions:
          (
            <div className="actions-center">
              <Tooltip
                id="tooltip-top"
                title="Khôi phục mật khẩu"
                placement="top"
                classes={{ tooltip: this.props.classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => console.log("Path")}
                  color="warning"
                  className="like"
                >
                  <Key />
                </Button>
              </Tooltip>
            </div>
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  }
 
  async checkPermission() {
      await axios.get(LINK_API.VERIFY_TOKEN,  { headers: {"Authorization" : `Bearer ${this.props.userInfo.data.token}`} })
      .then( async response => {
        if (response.data.errorCode != 1 || !response.data.data.valid ){
          return this.props.history.push("/");
        }
        else {
          axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
        }
      })
    .catch(async error => {
      console.log(error);
      return this.props.history.push("/");
    }) 
  }



  async componentDidMount() {
    try {
      await this.checkPermission();
      this.props.getFarms();
    }
    catch (error) {
      console.log(error)
    }
  }
  componentWillReceiveProps(preProps){
    if(preProps.employeeList !== this.props.employeeList){
      if(preProps.employeeList.data !== undefined && preProps.employeeList.data !== null){
        this.genTable(Array.from(preProps.employeeList.data));
      }
    }
  }


  handleNewClick(){
    this.props.history.push("/admin/distributor/createemp")
  }

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderPrintTemplate}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <GridContainer>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    {/* <CardIcon color="success">
                      <People />
                    </CardIcon> */}
                     <h4 className="cardtableLabel">Danh sách nhân viên</h4>
                  </GridItem>
                  <GridItem lg={6} md={6} sm={6} xs={12}>
                    <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      {(this.props.userInfo.data.active  === true) ? <Button color="success" size="sm" onClick={this.handleNewClick}> <Add style={{ margin: "0px", marginRight: "5px" }} /> Thêm Mới </Button> : null}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center", display: this.state.load }}>
                  <img
                    alt="{name}"
                    src={IMG_LOAD}
                    style={{ height: "100px" }}
                  />
                </div>
                <ReactTable
                  data={this.state.data}
                  filterable
                  // defaultPageSize={5}
                  previousText="Trang Trước"
                  nextText="Trang Sau"
                  rowsText="hàng"
                  ofText="/"
                  pageText="Trang"
                  noDataText="Nhấn nút TẠO MỚI để đăng ký tài khoản nhân viên!"
                  columns={[
                    {
                      Header: "#",
                      accessor: "id",
                      filterable: false,
                      maxWidth: 70,
                      className: "center",
                      Header: () => (
                        <div style={{
                          textAlign: "center"
                        }}>
                          #
                        </div>
                      )
                    },
                    {
                      Header: "Nhân viên",
                      accessor: "name",
                      filterable: false
                    },
                    {
                      Header: "Ngày đăng ký",
                      accessor: "createAt",
                      filterable: false
                    },
                    // {
                    //   Header: "Email",
                    //   accessor: "email",
                    //   filterable: false
                    // },
                    // {
                    //   Header: "Số điện thoại",
                    //   accessor: "phone",
                    //   filterable: false
                    // },
                    {
                      Header: "Tên đăng nhập",
                      accessor: "username",
                      filterable: false
                    },
                    
                    {
                      Header: "Mã nhân viên",
                      accessor: "empid",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Vai trò",
                      accessor: "role",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Hành động",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={false}
                  className="-striped -highlight"
                  style={{ display: this.state.showTable }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          open={this.state.openCreateFarm}
          onClose={this.handleClose}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <CreateFarm onConfirm={this.showConfirmAlert} />
        </Dialog>

        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFarms: () => dispatch({ type: "GET_EMPLOYEE_LIST"}),
  }
}
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo,
           employeeList: state.employeeList}
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
