import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';
// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/Add";
import Gallery from '../../components/Forms/Gallery/Gallery';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from '../../../../constants/API';
import Detail from "@material-ui/icons/Info";
import GetApp from "@material-ui/icons/GetApp";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
    ...extendedFormsStyle,
    ...tooltip,
    ...regularFormsStyle,
    ...sweetAlertStyle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    contentText: {
        fontSize: "14px",
        marginTop: "-20px",
        marginBottom: "20px"
    },
    boldContentText: {
        fontSize: "14px",
        marginBottom: "20px",
        fontWeight: "700"
    },
    UnsetOverflow: {
        overflow: "unset"
    }
};

class TableFarmList extends React.Component {
    constructor(props) {
        super(props);

        this.handleNewClick = this.handleNewClick.bind(this);
        this.genTable = this.genTable.bind(this);
        this.getFarms = this.getFarms.bind(this);
        this.handleCrop = this.handleCrop.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.role = cookie.load("checkrole");

        this.state = {
            alert: null,
            checked: false,
            value: {
                gln_extend: '',
                passphrase: '',
                data: '',
            },
            farmList: [],
            data: [],
            load: "",
            showTable: "none",
            activeState: true,
            startTime: "",
            endTime: "",
            export: [],
        };
        this.filter = this.props.filter;
        this.uuidobject = cookie.load("glnobjectArea");
        this.nameobject = cookie.load("nameobjectArea");
        this.token = cookie.load('token');

    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    renderTrigger() {
        return <Button color="success" onClick={() => {
            document.getElementById("printobject").style = "display: block"
        }}>In Mã</Button>;
    }

    renderContent = () => { // eslint-disable-line arrow-body-style
        return this.componentRef
    }
    setRef = (ref) => {
        this.componentRef = ref;
    }
    isEmptyObject = (obj) => {
        if (!obj) return true;
        if (obj.length === 0) return true;
        return Object.entries(obj).length === 0 && obj.constructor === Object
    }
    genTable = async (farmList) => {

        //-----------------------
        let exportData = [];
        exportData = farmList.map(item => {
          return ({
            uuid: item.uuid,
            name: item.name,
            time: moment(item.createdAt).format("DD/MM/YYYY"),
            nameOfZone: item.nameOfZone,
            nameOfProcess: item.nameOfProcess,
            lastAction: item.lastAction.nameOfLastAction
          })
        })
    
        await this.setState({ export: exportData })
        //-----------------------
        await farmList.reverse();
        this.setState({
            data: farmList.map((prop, key) => {
                return {
                    id: prop.uuid,
                    name: <div style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}>


                        <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                                {/* <img src={prop.image[0].url} style={{ height: 45 }} /> */}
                                {/* <ModalImage
                small={prop.image[0].url}
                large={prop.image[0].url}
                alt=""
              /> */}
                                {this.isEmptyObject(prop.image) ? "" : <Gallery
                                    // {(!prop.image) ? "":<Gallery
                                    images={[{
                                        src: prop.image.secure_url, thumbnail: prop.image.secure_url,
                                        caption: ""
                                    }]}
                                    rowHeight={50}
                                    enableLightbox={true}
                                    enableImageSelection={false}
                                />}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={7}>
                                <div><p style={{ marginLeft: "-24px", marginTop: 17, textAlign: "left" }}>{prop.name}</p></div>
                            </GridItem>
                        </GridContainer>
                    </div>,
                    createAt: moment(prop.createdAt).format("DD/MM/YYYY"),
                    area: prop.nameOfZone,
                    type: (prop.type === 0) ? "Luống" : (prop.type === 1) ? "Cây" : (prop.type === 2) ? "Toàn bộ vùng sản xuất" : (prop.type === 3) ? "Lô nguyên liệu thô" : (prop.type === 4) ? "Mẻ" : "",
                    actions:
                        <Button style={{ backgroundColor: "white", color: "#212b36" }} size="sm" onClick={() => this.handleCrop_List(prop.uuid, key)}> <Detail style={{ margin: "0px", marginRight: "5px" }} /> <a style={{ fontSize: "12px", textTransform: "none" }}>Xem chi tiết</a> </Button>

                };
            }),
            load: "none",
            showTable: ""
        });
    }
    stopCrop(gln) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Kết thúc mùa vụ"
                    onConfirm={() => this.onStop(gln)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Kết thúc"
                    cancelBtnText="Hủy bỏ"
                    showCancel
                >
                </SweetAlert>
            )
        })
    }
    onStop(gln) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title="Đang xử lý!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showConfirm={false}
                >
                    <div style={{ textAlign: "center" }}>
                        <img
                            alt="{name}"
                            src={IMG_LOAD}
                            style={{ height: "100px" }}
                        />
                    </div>
                </SweetAlert>
            )
        });
        const data = {
            "uuidOfProductionObject": gln
        }
        axios.post(LINK_API.STOP_CROP, data)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    await this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="Thành công"
                                onConfirm={() => this.hideAlert()}
                                showCancel={false}
                            >
                            </SweetAlert>
                        )
                    });
                    let farms = await this.getFarms();
                    this.setState({ farmList: farms });
                    this.genTable(farms);
                }

            })
            .catch(err => {
                console.log(err);
                this.setState({
                    alert: (
                        <SweetAlert
                            error
                            style={{ display: "block" }}
                            title="Không thành công"
                            onConfirm={() => this.hideAlert()}
                            onCancel={() => this.hideAlert()}
                            confirmBtnCssClass={
                                this.props.classes.button + " " + this.props.classes.success
                            }
                        >
                        </SweetAlert>
                    )
                });
            });
    }
    async handleCrop_List(gln, key) {
        await cookie.save("glnobjects", gln, { path: "/" });
        await cookie.save("indexobjects", key, { path: "/" });
        this.props.history.push("/admin/objectdetailstatistic")
    }
    async checkPermission() {
        try {
            let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
            if (response.data.errorCode !== 1 || !response.data.data.valid) {
                await cookie.remove('token')
                return this.props.history.push("/");
            }
            else {
                axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
            }
        }
        catch (error) {
            console.log(error);
            await cookie.remove('token');
            return this.props.history.push("/");
        }
    }

    getFarms = async () => {
        try {
            let response = await axios.get(LINK_API.CREATE_OBJECT_PRODUCTION);
            if (response.data.errorCode == 1) {
                return response.data.data;
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

    async componentDidMount() {
        try {
            await this.checkPermission();
            if (this.role === "INACTIVE") {
                await this.setState({
                    activeState: false,
                })
            }
            //   this.props.getObject();
            axios.get(LINK_API.LIST_OBJECT_PRODUCT + this.uuidobject)
                .then(res => {
                    if(res.data.errorCode === 1){
                        if(res.data.data.length === 0){
                            this.setState({load: "none", showTable: ""})
                        }
                        else{
                            this.genTable(res.data.data);
                        }
                    }
                    
                })
                .catch(err => {
                    console.log(err);
                })
        }
        catch (error) {
            console.log(error)
        }
    }
    componentWillReceiveProps(preProps) {
        if (preProps.objectList !== this.props.objectList) {
            if (preProps.objectList.data) {
                this.genTable(Array.from(preProps.objectList.data));
            }
        }
    }
    handleChange = name => async moment => {

        await this.setState({
            [name]: moment._d,
        });
    };
    handleCrop(tree, product, process) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Tạo mùa vụ cho đối tượng:"
                    onConfirm={() => this.handleInputDate(tree, product, process)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Tạo mới"
                    cancelBtnText="Đóng"
                    showCancel
                >
                    {tree}
                </SweetAlert>
            )
        })
    }
    handleInputDate(tree, product, process) {
        //const {classes} = this.props
        this.setState({
            alert: (
                <SweetAlert
                    //className= {this.props.classes.UnsetOverflow}
                    warning
                    style={{ display: "block", overflow: "unset" }}
                    title="Nhập thông tin mùa vụ"
                    onConfirm={() => this.onCrop(tree, product, process)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Tạo mới"
                    cancelBtnText="Đóng"
                    showCancel
                >
                    <Card className="noShadow" style={{ marginTop: "-8px" }}>
                        <CardBody>
                            {/* <InputLabel className={classes.label}>
                Datetime Picker
              </InputLabel> */}
                            <br />
                            <FormControl fullWidth>
                                <Datetime
                                    value={this.state.startTime}
                                    className="InputDatetime"
                                    formControlProps={{
                                        onChange: this.handleChange('startTime'),
                                    }}
                                    inputProps={{ placeholder: "Ngày bắt đầu", className: "InputDatetime" }}
                                    onChange={this.handleChange('startTime')}
                                />
                                <hr className="LineBreakDesDate" />
                            </FormControl>
                        </CardBody>
                    </Card>
                    <Card className="noShadow" style={{ marginTop: "-60px" }}>
                        <CardBody>
                            {/* <InputLabel className={classes.label}>
                Datetime Picker
              </InputLabel> */}
                            <br />
                            <FormControl fullWidth>
                                <Datetime
                                    className="InputDatetime"
                                    inputProps={{
                                        placeholder: "Ngày kết thúc",
                                        className: "InputDatetime",
                                    }}
                                />
                                <hr className="LineBreakDesDate" />
                            </FormControl>
                        </CardBody>
                    </Card>
                </SweetAlert>
            )
        })
    }
    onCrop(tree, product, process) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title="Đang xử lý!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showConfirm={false}
                >
                    <div style={{ textAlign: "center" }}>
                        <img
                            alt="{name}"
                            src={IMG_LOAD}
                            style={{ height: "100px" }}
                        />
                    </div>
                </SweetAlert>
            )
        });
        const data = {
            "uuidOfProductionObject": tree,
            "processCode": process,
            "uuidOfProductType": product,
        }
        axios.post(LINK_API.ASSIGN_CROP, data)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    await this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="Thành công"
                                onConfirm={() => this.hideAlert()}
                                showCancel={false}
                            >
                            </SweetAlert>
                        )
                    });
                }

            })
            .catch(err => {
                console.log(err);
                this.setState({
                    alert: (
                        <SweetAlert
                            error
                            style={{ display: "block" }}
                            title="Không thành công"
                            onConfirm={() => this.hideAlert()}
                            onCancel={() => this.hideAlert()}
                            confirmBtnCssClass={
                                this.props.classes.button + " " + this.props.classes.success
                            }
                        >
                        </SweetAlert>
                    )
                });
            });

    }
    handleNewClick() {
        this.props.history.push("/admin/areas/objectsp/list/create")
    }

    makePlaceholderFilter(placeholder) {
        return ({ filter, onChange }) => (
            <input type='text'
                placeholder={placeholder}
                style={{
                    width: '100%'
                }}
                onChange={event => onChange(event.target.value)}
                value={filter ? filter.value : ''}
            />
        )
    }

    render() {
        return (
            <div>
                {this.renderPrintTemplate}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="success" icon>
                                <GridContainer>
                                    <GridItem lg={8} md={8} sm={8} xs={8}>
                                        <h4 className="cardtableLabel">Danh sách đối tượng thuộc vùng sản xuất " {this.nameobject} "</h4>
                                    </GridItem>
                                    <GridItem lg={4} md={4} sm={4} xs={4}>
                                        <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                                            <ExcelFile element={<Button color="success" style={{ color: "white" }} size="sm" > <GetApp style={{ margin: "0px", marginRight: "5px" }} /> <a style={{ fontSize: "12px", textTransform: "none" }}>Dowload</a> </Button>}>
                                            <ExcelSheet data={this.state.export} name="Employees">
                                                <ExcelColumn label="UUID" value="uuid" />
                                                <ExcelColumn label="Name" value="name" />
                                                <ExcelColumn label="Created Time" value="time" />
                                                <ExcelColumn label="Zone" value="nameOfZone" />
                                                <ExcelColumn label="Process" value="nameOfProcess" />
                                                <ExcelColumn label="Status" value="lastAction" />
                                            </ExcelSheet>
                                            </ExcelFile>
                                        </GridContainer>
                                        </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <div style={{ textAlign: "center", display: this.state.load }}>
                                    <img
                                        alt="{name}"
                                        src={IMG_LOAD}
                                        style={{ height: "100px" }}
                                    />
                                </div>
                                <ReactTable
                                    data={this.state.data}
                                    filterable
                                    // defaultPageSize={5}
                                    previousText="Trang Trước"
                                    nextText="Trang Sau"
                                    rowsText="hàng"
                                    ofText="/"
                                    pageText="Trang"
                                    noDataText="Hiện chưa có đối tượng nào cho vùng sản xuất này!"
                                    columns={[
                                        {
                                            Header: "#",
                                            accessor: "id",
                                            filterable: false,
                                            className: "center",
                                            Header: () => (
                                                <div style={{
                                                    textAlign: "center"
                                                }}>
                                                    Mã đối tượng
                        </div>
                                            )
                                        },
                                        {
                                            Header: "Tên",
                                            accessor: "name",
                                            filterable: false,
                                            style: { 'whiteSpace': 'unset' }
                                        },
                                        // {
                                        //   Header: "Hình ảnh",
                                        //   accessor: "image",
                                        //   filterable: false
                                        // },
                                        {
                                            Header: "Ngày đăng ký",
                                            accessor: "createAt",
                                            sortable: false
                                        },
                                        {
                                            Header: "Vùng sản xuất",
                                            accessor: "area",
                                            sortable: false
                                        },
                                        {
                                            Header: "Loại",
                                            accessor: "type",
                                            sortable: false
                                        },
                                        {
                                            Header: "Hành động",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false
                                        },
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationBottom={true}
                                    sortable={false}
                                    resizable={true}
                                    className="-striped -highlight"
                                    style={{ display: this.state.showTable }}
                                />
                            </CardBody>
                        </Card>

                    </GridItem>
                </GridContainer>
                {this.state.alert}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getObject: () => dispatch({ type: "GET_OBJECT_PROCESSING_LIST" }),
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        objectList: state.objectProcessList
    }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
