import React from 'react';
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import axios from 'axios';
import { LINK_API,} from '../../../../constants/API';
import {ADDRESS } from  'constants/index.js'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Face from '@material-ui/icons/Face';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Accordion from 'components/Accordion/Accordion.jsx';
import ImageUpload from '../RetailPage/ImageCustorm/ImageUpload.jsx';
import {API } from 'constants/index.js';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
//styles
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import SelectForm from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
const LZUTF8 = require('lzutf8');
const ethers = require('ethers');
const CryptoJS = require("crypto-js");

const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider(`http://206.189.82.191:8000`));
//ABI
const genieIJSON = require("../../../../build/contracts/GenieIdentity.json");
const genieIABI = genieIJSON['abi'];

const genieTJSON = require("../../../../build/contracts/GenieToken.json");
const genieTABI = genieTJSON['abi'];

const style = {
	...regularFormsStyle,
	...customSelectStyle,
	...sweetAlertStyle,
	selectLabel: {
		...customSelectStyle.selectLabel,
		fontSize: '14px',
		fontFamily: 'Muli',
		fontWeight: 400,
		lineHeight: 1.42857,
		color: '#AAAAAA !important',
		textTransform: 'none',
		marginBottom: '-14px',
		position: 'relative',
		textTransform: 'none',
		textAlign: 'left !important'
	},
	select: {
		...customSelectStyle.select,
		fontSize: '14px',
		textTransform: 'none',
		textAlign: 'left !important'
	},
	selectFormControl: {
		...customSelectStyle.selectFormControl,
		margin: 'none !important'
	}
};
class CreateFarm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pass_userHelp: '',
			pass_userState: '',
			name: '',
			nameState: '',
			phone: '',
			phoneState: '',
			email: '',
			emailState: '',
			password: '',
			passwordState: '',
			username: '',
			usernameState: '',
			backState: false,
			pathCount: 0,
			role: "Quản lý",
			role_temp: "M",
			destime: ['Nhân viên nuôi trồng',  'Nhân viên chế biến', 'Quản lý',],
			objects: [],
			objects_main: 'Tất cả',
			objects_temp: '',
			image: '',
			allProducts: [],
		};


		this.handleChange = this.handleChange.bind(this);
		this.getPathemploy = this.getPathemploy.bind(this);
		this.handleChangeSelect = this.handleChangeSelect.bind(this);
		this.onSuccess = this.onSuccess.bind(this);
		this.showConfirmAlert = this.showConfirmAlert.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.checkPermission = this.checkPermission.bind(this);
		this.token = cookie.load('token');
		this.handleSelect = this.handleSelect.bind(this);
		this.handleSelect_objects = this.handleSelect_objects.bind(this);
		this.getObjects = this.getObjects.bind(this);
		this.onImageSubmit = this.onImageSubmit.bind(this);
		this.hideAlert_success = this.hideAlert_success.bind(this);
		this.sendAccount = this.sendAccount.bind(this);
	}

	async componentDidMount() {
		await this.checkPermission();
		this.setState({
			pass_user: this.props.userInfo.password
		})
		this.getPathemploy();
		this.getObjects();
	}
	componentWillReceiveProps(preProps){
		if(this.props.userInfo !== preProps.userInfo){
			this.setState({
				pass_user: preProps.userInfo.password
			})
		}
	}
	async onImageSubmit(file) {
		if (file != null) {
			await this.setState({
				image: file
			});
		}
	}
	async getObjects() {
		axios
			.get(LINK_API.CREATE_OBJECT_PRODUCTION)
			.then(async (response) => {
				if (response.data.errorCode === 1) {
					const data = response.data.data;
					var i = 0;
					var result = ['Tất cả'];
					for (i = 0; i < data.length; i++) {
						var temp = data[i].name + ' - ' + data[i].uuid;
						this.state.allProducts.push(data[i].uuid)
						result.push(temp);
					}
					this.setState({
						objects: result
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}
	getPathemploy() {
		axios
			.get(LINK_API.GET_PATH_EMPLOY)
			.then(async (response) => {
				if (response.data.errorCode === 1) {
					this.setState({
						pathCount_employ: response.data.data.pathEmployee.toString(),
						pathCount_user: response.data.data.pathUser.toString()
					});
				}
			})
			.catch(async (error) => {
				console.log(error);
			});
	}
	async checkPermission() {
		try {
			let response = await axios.get(LINK_API.VERIFY_TOKEN, {
				headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
			});
			if (response.data.errorCode !== 1 || !response.data.data.valid) {
				await cookie.remove('token');
				return this.props.history.push('/');
			} else {
				axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
			}
		} catch (error) {
			console.log(error);
			await cookie.remove('token');
			return this.props.history.push('/');
		}
	}

	async handleSelect(event) {
		if (event.target.value === 'Nhân viên nuôi trồng') {
			await this.setState({ role: 'Nhân viên nuôi trồng', role_temp: 'SM' });
		}
		if (event.target.value === 'Nhân viên chế biến') {
			await this.setState({ role: 'Nhân viên chế biến', role_temp: 'SP' });
		}
		if (event.target.value === 'Quản lý') {
			await this.setState({ role: 'Quản lý', role_temp: 'M' });
		}
		document.getElementById('noti').innerHTML = '';
	}

	async handleSelect_objects(event) {
		var temp = event.target.value.split(' ');
		//console.log(temp)
		await this.setState({
			objects_main: event.target.value,
			objects_temp: temp[temp.length -1],
		});
		document.getElementById('noti').innerHTML = '';
	}
	estimateGas = async (from, nonce, to, data) =>{
		const body = {
		  functionName: "estimateGas",
		  Option: {
			"from"      : from,       
			"nonce"     : nonce, 
			"to"        : to,     
			"data"      : data
		  }
		}
	
		let response = "";
		try{
		  response = await axios.post(API.LINK_API.GET_INFO_WEB3, body)
		  const gasLimit = response.data.data.gasLimit;
			return gasLimit;
		} catch {
		  console.log("ERROR");
		  return null;
		}
	
		
	  }

	AddKey = async (address_en,identifierAddress) =>{
		const body = {
			functionName: "AddKey",
			Option: {
			  "address_en"      : address_en,       
			  "identifierAddress"     : identifierAddress,
			}
		  }
	  
		  let response = "";
		  try{
			response = await axios.post(API.LINK_API.GET_INFO_WEB3, body);
			const data = response.data.data.data;
		  	return data;
		  } catch {
			console.log("ERROR");
			return null;
		  }
	  
		  
	}
	validateUsername(x){
		let usernameRegex = /^[a-zA-Z0-9]+$/;
		if(usernameRegex.test(x)){
			return true;
		}else{
			return false
		}
	}
	handleChange = (name) => async (event) => {
		await this.setState({
			[name]: event.target.value
		});
		if (name === 'name') {
			if (this.state.name) {
				let newStr = this.state.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				;//let no_space = newStr.replace(/\s+/g, '');
				var temp = newStr.split(" ");
				var no_space = "";
				for(var i = 1; i<temp.length; i++){
					no_space = no_space + temp[i]
				}
				let low_case = no_space.toLowerCase();
				let low_case1 = this.state.name[0].toLowerCase();
				//var rand = Math.floor(Math.random() * 10001);
				//var username = low_case + rand;
				var username = low_case1 + low_case;
				var password = Math.floor(100000 + Math.random() * 900000);
				this.setState({
					username: username,
					password: password,
					pass_userState: 'success'
				});
				document.getElementById('noti').innerHTML = '';
				this.setState({
					nameState: 'success'
				});
			} else {
				this.setState({
					nameState: 'error'
				});
			}
		}
		if (name === 'id') {
			if (this.state.id) {
				document.getElementById('noti').innerHTML = '';
				this.setState({
					idState: 'success'
				});
			} else {
				this.setState({
					idState: 'error'
				});
			}
		}
		// if (name === "phone") {
		//   if (this.state.phone) {
		//     document.getElementById("noti").innerHTML = "";
		//     this.setState({
		//       phoneState: "success"
		//     })
		//   } else {
		//     this.setState({
		//       phoneState: "error"
		//     })
		//   }
		// }
		// if (name === "email") {
		//   if (this.state.email) {
		//     document.getElementById("noti").innerHTML = "";
		//     this.setState({
		//       emailState: "success"
		//     })
		//   } else {
		//     this.setState({
		//       emailState: "error"
		//     })
		//   }
		//   if (name === "password") {
		//     if (this.state.password) {
		//       document.getElementById("noti").innerHTML = "";
		//       this.setState({
		//         passwordState: "success"
		//       })
		//     } else {
		//       this.setState({
		//         passwordState: "error"
		//       })
		//     }
		//   }

		  if (name === 'username') {
		    if (this.state.username) {
			 let length = this.state.username.length;
			 let result = this.state.username.substring(length-1, length);
			 let check = this.validateUsername(result);
			 if(check === false){
				 let i = this.state.username.substring(0, length-1);
				 this.setState({
					 username: i
				 })
			 }
		  }
		}
		if (name === 'pass_user') {
			if (this.state.username) {
				document.getElementById('noti_pass').innerHTML = '';
				this.setState({
					pass_userState: 'success',
					pass_userHelp: ''
				});
			} else {
				this.setState({
					pass_userState: 'error',
					pass_userHelp: ''
				});
			}
		}
		//}
	};
 
	async submitData() {

		var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.seedEncrypted.toString(), this.props.userInfo.password);
		const randomMnemonic = bytes.toString(CryptoJS.enc.Utf8);

		let walletPath = {
			standard: "m/44'/60'/0'/" + this.state.pathCount_user + '/' + this.state.pathCount_employ
		};
		let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
		let node = hdnode.derivePath(walletPath.standard);
		const privatekey = node.privateKey;
		const enPrivateKey_emp = await CryptoJS.AES.encrypt(privatekey, this.state.password.toString());
		var temp_sub = new Buffer(this.state.password).toString('hex');
		const pass_en = await ethers.utils.keccak256('0x' + temp_sub);
		// const address_en = await  ethers.utils.keccak256(emp_address);
		//console.log(address_en)
		var data = {
			name: this.state.name,
			phone: '',
			email: '',
			username: this.state.username,
			password: pass_en,
			address: node.address,
			publicKey: node.publicKey,
			privateEncrypted: enPrivateKey_emp.toString(),
			management: this.state.objects_main === "Tất cả" ? this.state.allProducts : [`${this.state.objects_temp}`],
			gsrn: '',
			role: this.state.role_temp,
			avatar: "",
			path: this.state.pathCount_employ,
		};
			//avatar: LZUTF8.compress(this.state.image, { outputEncoding: 'Base64' })
		// this.setState({
		// 	profile_emp: data,
		// })
		document.getElementById('noti').innerHTML = '';
		this.onSuccess(data);
	}
	async onSuccess(x) {
		if(this.state.image){
			const temp = await LZUTF8.compress(this.state.image, { outputEncoding: 'Base64' })
			await this.setState({
				image: temp
			}
			)
		}else{
			await this.setState(
				{
					image: ""
				}
			)
		}
		await this.props.createEmployee(x, this.state.image);
		this.props.history.push('/admin/distributor/employee');
	}
	sendAccount(data) {
		axios.post(LINK_API.SEND_RAW_EMPLOYEE, data)
		.then( async response => {
			if(response.data.errorCode === 1){
				await this.setState({
					alert: (
						<SweetAlert
							success
							style={{ display: 'block' }}
							title="Thành công"
							onConfirm={() => this.hideAlert_success()}
							showCancel={false}
						/>
					)
				});
			}else{
				this.setState({
					alert: (
						<SweetAlert
							error
							style={{ display: 'block' }}
							title="Không thành công"
							onConfirm={() => this.hideAlert()}
							onCancel={() => this.hideAlert()}
							confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
						/>
					)
				});	
			}
		})
		.catch(err => {
			console.log(err)
			this.setState({
				alert: (
					<SweetAlert
						error
						style={{ display: 'block' }}
						title="Không thành công"
						onConfirm={() => this.hideAlert()}
						onCancel={() => this.hideAlert()}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
					/>
				)
			});
		})
	}
	hideAlert_success() {
		this.setState({
			alert: null
		});
		this.props.history.push('/admin/farms/list');
	}
	verifyLength(value, length) {
		if (value.length >= length) {
			return true;
		}
		return false;
	}

	verifyNumber(value) {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	}
	handleNext() {
		if (!this.state.name) {
			document.getElementById('noti').innerHTML = 'Thiếu tên nhân viên';
			return;
		}
		if (!this.state.username) {
			document.getElementById('noti').innerHTML = 'Thiếu tên đăng nhập';
			return;
		}
		if (!this.state.password) {
			document.getElementById('noti').innerHTML = 'Thiếu mật khẩu';
			return;
		}
		if (this.state.role_temp === '') {
			document.getElementById('noti').innerHTML = 'Vui lòng chọn vai trò';
			return;
		}
		// if (this.state.objects_temp === '') {
		// 	document.getElementById('noti').innerHTML = 'Vui lòng chọn đối tượng sản xuất';
		// 	return;
		// }
		this.submitData();
	}
	handleprev() {
		document.getElementById('info2').style = 'display: none';
		document.getElementById('info1').style = 'display: block; width: 100%';
		document.getElementById('buttonnext').innerHTML = 'Tiếp theo';
	}
	handleChangeSelect = (selectedOption) => {
		this.setState({ selectedOption });
		//console.log(`Option selected:`, selectedOption);
	};
	hideAlert() {
		this.setState({
			alert: null
		});
	}
	showConfirmAlert() {
		if (!this.state.name) {
			document.getElementById('noti').innerHTML = 'Thiếu tên nhân viên';
			return;
		}
		if (!this.state.username) {
			document.getElementById('noti').innerHTML = 'Thiếu tên đăng nhập';
			return;
		}
		if (!this.state.password) {
			document.getElementById('noti').innerHTML = 'Thiếu mật khẩu';
			return;
		}
		if (this.state.role_temp === '') {
			document.getElementById('noti').innerHTML = 'Vui lòng chọn vai trò';
			return;
		}
		// if (this.state.objects_temp === '') {
		// 	document.getElementById('noti').innerHTML = 'Vui lòng chọn đối tượng sản xuất';
		// 	return;
		// }
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-200px' }}
					title="Bạn đã kiểm tra kỹ các thông tin?"
					onConfirm={() => this.submitData()}
					onCancel={() => {
						this.hideAlert();
						this.setState({ openCreateFarm: true });
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
					confirmBtnText="Tạo mới"
					cancelBtnText="Làm lại"
					showCancel
				>
					{/* Các thông tin trên sẽ được lưu vết trên Blockchain */}
				</SweetAlert>
			)
		});
	}
	inputPass() {
		const { classes } = this.props;
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-200px' }}
					title="Vui lòng nhập mật khẩu tài khoản"
					onConfirm={() => this.submitData()}
					onCancel={() => {
						this.hideAlert();
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
					confirmBtnText="Tạo mới"
					cancelBtnText="Làm lại"
					showCancel
				>
					<CustomInput
						success={this.state.pass_userState === 'success'}
						error={this.state.pass_userState === 'error'}
						helpText={this.state.pass_userHelp}
						variant="outlined"
						labelProps={{
							variant: 'outlined'
						}}
						formControlProps={{
							variant: 'outlined',
							fullWidth: true,
							className: classes.customFormControlClasses,
							onChange: this.handleChange('pass_user')
						}}
						inputProps={{
							value: this.state.pass_user,
							type: 'password',
							autoComplete: 'off',
							variant: 'outlined',
							startAdornment: (
								<InputAdornment position="start" className={classes.inputAdornment}>
									<Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
								</InputAdornment>
							),
							placeholder: 'Mật khẩu'
						}}
					/>
					<div>
						<p
							style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
							id="noti_pass"
							style={{
								fontSize: '0.875rem',
								fontWeight: '400',
								color: 'red',
								fontFamily: 'Muli',
								marginTop: 5,
								marginBottom: 5
							}}
						/>
					</div>
				</SweetAlert>
			)
		});
	}

	render() {
		const { classes } = this.props;
		return (
			<div>
				<div>{this.state.alert}</div>

				<GridContainer>
					<GridItem xs={8} sm={8} md={8} lg={8}>
						<Card>
							<CardHeader color="success" icon>
								{/* <CardIcon color="success">
									<MailOutline />
								</CardIcon> */}
								 <h4 className="cardtableLabel">Thêm Nhân Viên Mới</h4>
							</CardHeader>
							<CardBody style={{ textAlign: 'center' }}>
								{/* <form> */}
								<div id="info1">
									<CustomInput
										labelText="Tên nhân viên"
										success={this.state.nameState === 'success'}
										error={this.state.nameState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: classes.customFormControlClasses,
											onChange: this.handleChange('name')
										}}
										inputProps={{
											value: this.state.name,
											autoComplete: 'off',
											startAdornment: (
												<InputAdornment position="start" className={classes.inputAdornment}>
													<Face className={classes.inputAdornmentIcon} />
												</InputAdornment>
											)
										}}
									/>
									{/* <CustomInput
										labelText="Mã nhân viên"
										success={this.state.idState === 'success'}
										error={this.state.idState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: classes.customFormControlClasses,
											onChange: this.handleChange('id')
										}}
										inputProps={{
											value: this.state.id,
											autoComplete: 'off',
											startAdornment: (
												<InputAdornment position="start" className={classes.inputAdornment}>
													<Icon className={classes.inputAdornmentIcon}>perm_identity</Icon>
												</InputAdornment>
											)
											// placeholder: ""
										}}
									/> */}
									<CustomInput
										labelText="Tên đăng nhập"
										success={this.state.usernameState === 'success'}
										error={this.state.usernameState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: classes.customFormControlClasses,
											onChange: this.handleChange('username')
										}}
										inputProps={{
											value: this.state.username,
											autoComplete: 'off',
											startAdornment: (
												<InputAdornment position="start" className={classes.inputAdornment}>
													<Icon className={classes.inputAdornmentIcon}>credit_card</Icon>
												</InputAdornment>
											)
											//placeholder: "Tên đăng nhập"
										}}
									/>
									<CustomInput
										labelText="Mật khẩu tài khoản"
										success={this.state.passwordState === 'success'}
										error={this.state.passwordState === 'error'}
										formControlProps={{
											fullWidth: true,
											className: classes.customFormControlClasses,
											onChange: this.handleChange('password')
										}}
										inputProps={{
											defaultValue: '',
											value: this.state.password,
											// type: "password",
											form: {
												autocomplete: 'off'
											},
											variant: 'outlined',
											startAdornment: (
												<InputAdornment position="start" className={classes.inputAdornment}>
													<Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
												</InputAdornment>
											)
											// placeholder: "Mật khẩu"
										}}
									/>
									<div style={{ width: '100%', marginTop: '-17px' }}>
										<Accordion
											// active={1}
											collapses={[
												{
													title: 'Thông tin khác',
													content: (
														<div style={{ width: '100%' }}>
															<FormControl
																fullWidth
																className={classes.selectFormControl}
															>
																<InputLabel
																	htmlFor="product-expired-type"
																	className={classes.selectLabel}
																>
																	Vai trò
																</InputLabel>
																<SelectForm
																	MenuProps={{
																		className: classes.selectMenu
																	}}
																	id="select"
																	classes={{
																		select: classes.select
																	}}
																	value={this.state.role}
																	onChange={this.handleSelect}
																	inputProps={{
																		name: 'type',
																		id: 'type'
																	}}
																>
																	<MenuItem
																		disabled
																		classes={{
																			root: classes.selectMenuItem
																		}}
																	>
																		Chọn vai trò
																	</MenuItem>
																	{this.state.destime.map((x) => (
																		<MenuItem
																			classes={{
																				root: classes.selectMenuItem,
																				selected: classes.selectMenuItemSelected
																			}}
																			value={x}
																		>
																			{x}
																		</MenuItem>
																	))}
																</SelectForm>
															</FormControl>
															<FormControl
																fullWidth
																className={classes.selectFormControl}
															>
																<InputLabel
																	htmlFor="product-expired-type"
																	className={classes.selectLabel}
																>
																	Đối tượng sản xuất phụ trách
																</InputLabel>
																<SelectForm
																	MenuProps={{
																		className: classes.selectMenu
																	}}
																	id="select"
																	classes={{
																		select: classes.select
																	}}
																	value={this.state.objects_main}
																	onChange={this.handleSelect_objects}
																	inputProps={{
																		name: 'type',
																		id: 'type'
																	}}
																>
																	<MenuItem
																		disabled
																		classes={{
																			root: classes.selectMenuItem
																		}}
																	>
																		Đối tượng sản xuất phụ trách
																	</MenuItem>
																	{this.state.objects.map((x) => (
																		<MenuItem
																			classes={{
																				root: classes.selectMenuItem,
																				selected: classes.selectMenuItemSelected
																			}}
																			value={x}
																		>
																			{x}
																		</MenuItem>
																	))}
																</SelectForm>
															</FormControl>
														</div>
													)
												}
											]}
										/>
									</div>
									{/* <div style={{width: "100%", marginTop: "15px", textAlign: "start"}}>
                  <p className="tagemployee">Đối tượng sản xuất</p>
                  <Select
                    closeMenuOnSelect={true}
                    isMulti
                    options={options}
                    styles={colourStyles}
                    onChange={this.handleChangeSelect}
                    placeholder="Chọn đối tượng sản xuất"
                  />
                  </div>
                   <hr className="linebreakEmployee" /> */}
								</div>
								<p
									id="noti"
									style={{
										fontSize: '0.875rem',
										fontWeight: '400',
										color: 'red',
										fontFamily: 'Muli',
										marginTop: 5,
										marginBottom: 5
									}}
								/>
								{this.state.backState === true ? (
									<Button
										color="danger"
										onClick={this.handleprev.bind(this)}
										style={{ marginTop: '15px' }}
									>
										Quay lại
									</Button>
								) : null}
								<Button
									id="buttonnext"
									color="success"
									size="sm"
									onClick={this.showConfirmAlert}
									style={{ marginTop: '15px' }}
								>
									Thêm mới
								</Button>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={4} lg={4} md={4} sm={4}>
						<Card>
							<CardHeader color="success" icon>
								<GridContainer>
									<GridItem lg={12} md={12} sm={12} xs={12}>
										{/* <CardIcon color="success">
											<MailOutline />
										</CardIcon> */}
										 <h4 className="cardtableLabel">Hình ảnh</h4>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody style={{ display: 'flex', justifyContent: 'center' }}>
								<ImageUpload onImageSubmit={this.onImageSubmit} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return { userInfo: state.userInfo,
			 userProfile: state.userProfile}
  }
const  mapDispatchToProps = (dispatch) => {
	return {
	  createEmployee: (data, image) => dispatch({type: "CREATE_EMPLOYEE", data: data, avatar: image})
	}
  }
export default withStyles(style)(withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateFarm)));
