import React from "react";
import Resizer from 'react-image-file-resizer';
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/icons/selectImage.png"
import defaultAvatar from "assets/img/pictureicon.png";
import removeIcon from "assets/icons/deleteButton.png"
import { timingSafeEqual } from "crypto";
const LZUTF8 = require('lzutf8');
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: false,
      portal: false,
      file: null,
      list:[],
      listview:[],
      count:0,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  componentDidMount(){
      this.setState({
          listview: this.props.src ? this.props.src : [],
          count: this.props.src ? this.props.src.length : 0,
      })
  }
  componentWillReceiveProps(preProps){
      if(this.props.src !== preProps.src){
          if(preProps.src){
              this.setState({
                  listview: preProps.src ,
                  count: preProps.src.length
              })
          }else{
              this.setState({
                  listview: [],
                  count: 0
              })
          }
      }
  }
  async handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
     let file = e.target.files[0];
    let temp = this.state.listview;
    let compress = this.state.list;
    let temp_count = this.state.count;
    reader.onloadend =async () => {
        temp.push(reader.result)
        compress.push(LZUTF8.compress(reader.result, { outputEncoding: 'Base64' }))
        temp_count = temp_count + 1;
      await this.setState({
        list: compress,
        listview: temp,
        count: temp_count
      });
      if(typeof(this.props.idx) === "number"){
        this.props.onImageSubmit(this.props.idx,this.state.list, this.state.listview);
      }else{
        this.props.onImageSubmit(this.state.list);
      }
     
    };
    reader.readAsDataURL(file);
    this.refs.fileInput.value = null;
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
 async handleRemove(idx) {
    let temp = this.state.count - 1;
   await this.setState({
      count: temp,
      list: this.state.list.filter((s, sidx) => idx !== sidx),
      listview: this.state.listview.filter((s, sidx) => idx !== sidx)
    });
    await this.props.onImageSubmit(this.props.idx,this.state.list, this.state.listview);
    this.refs.fileInput.value = null;
  }
  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div style={{display: "flex", flexFlow: "row"}}>          
          {
            this.state.listview.map((prop, key) => (
            key < 1?
          <div key={key} className="addImageProcess" style={{backgroundImage:`URL(${prop})`}}>
            <img src={removeIcon} onClick={() => this.handleRemove(key)} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
          </div>:
          <div key={key} className="addImageProcess" style={{backgroundImage:`URL(${prop})`, marginLeft: "20px"}}>
          <img src={removeIcon} onClick={() => this.handleRemove(key)} style={{width: "20px", height:"20px", marginLeft: "86px", marginTop: "-20px", cursor: "pointer"}}/>
        </div>
            ))
          }
          {this.state.count < 1 ? <img style={{ height: "100px" , cursor: "pointer"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." /> : this.state.count < 2 ? 
          <img style={{ height: "100px" , cursor: "pointer", marginLeft: "20px"}} src={this.state.imagePreviewUrl}  onClick={() => this.handleClick()} alt="..." /> : null}
        </div>
        <div>
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
