import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";



import strings from 'constants/strings';


import AGR from "assets/img/bg-img/logo_original_text_white.png";

import styles from './infoStyles';
import VBC from "assets/img/bg-img/logo_vbc.png";
class LookupRice extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    strings.setLanguage(strings.getLanguage());

    return (
      <div>
        <div className="footer-distributed">
          <GridItem xs={12} sm={12}>

            <img className="logoAgri" src={VBC} />
            <p> </p>
            <p className="introduce">Công ty Cổ phần Vietnam Blockchain (Vietnam Blockchain Corporation - VBC) là công ty cung cấp các giải pháp và dịch vụ về công nghệ blockchain phục vụ các lĩnh vực chủ chốt như Nông nghiệp, Sản xuất, Chuỗi cung ứng, Hậu cần, Thương mại Điện tử, Kinh tế chia sẻ, Công Nghệ Tài Chính, Dịch Vụ Công và Đô Thị Thông Minh.</p>
            <p className="phonenumber">0377 887 403</p>
            <div style={{ marginTop: "0px" }}>
              <p><a href="mailto:contact@vietnamblockchain.asia" className="addressvbc">contact@vietnamblockchain.asia</a></p>
            </div>
            <div style={{ marginTop: "14px" }}>
              <p className="addressvbc">Tầng 5, tòa nhà Flemington, 182 Lê Đại Hành, Phường 15, Quận 11, Tp.HCM</p>
            </div>

          </GridItem>
          <GridItem xs={12} sm={12} style = {{marginTop:"20px"}}>

          <img className="logoAgri" src={AGR} />
          <p> </p>
          <p className="introduce">Agridential là giải pháp truy xuất nguồn gốc áp dụng công nghệ blockchain phù hợp tiêu chuẩn toàn cầu GS1*.</p>
          <p className="weblink">Website: <a className="introduce" onClick={() => {
                  window.location.href = 'https://agridential.vn/contact-us/';
                  return null;
                }}>agridential.vn</a></p>
          {/* <p className="phonenumber">0377 887 403</p>
          <div style={{ marginTop: "0px" }}>
            <p><a href="mailto:contact@vietnamblockchain.asia" style={{ color: "#fff" }}>contact@vietnamblockchain.asia</a></p>
          </div>
          <div style={{ marginTop: "14px" }}>
            <p className="addressvbc">Tầng 5, tòa nhà Flemington, 182 Lê Đại Hành, Phường 14, Quận 11, Tp.HCM</p>
          </div> */}

          </GridItem>
          {/* <GridItem xs={12} sm={12} md={5}>

            <p className="Company">COMPANY</p>
            <hr className="LineBreak" />
            <GridContainer style={{ marginTop: "31px" }}>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">Trang chủ</p>
              </GridItem>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">BlockChain</p>
              </GridItem>
            </GridContainer >
            <GridContainer style={{ marginTop: "20px" }}>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">Sản phẩm</p>
              </GridItem>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">Dịch vụ</p>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginTop: "20px" }}>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink" onClick={() => {
                  window.location.href = 'https://agridential.vn/contact-us/';
                  return null;
                }}>Giải pháp</p>
              </GridItem>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink" onClick={() => {
                  window.location.href = 'https://agridential.vn/contact-us/';
                  return null;
                }}>Giới thiệu</p>
              </GridItem>
            </GridContainer>


          </GridItem> */}
          {/* <GridItem xs={3} sm={3} md={3}>
    
    <p className="oursettler">OUR NEWSLETTER</p>
    <hr className="LineBreak"/>
    <GridContainer style={{marginTop: "30px"}}>
    <GridItem xs={12} sm={12} md={12} >
        <p className="textleft">Get in your inbox the latest News and Offers from Agridental</p>  
    </GridItem>
    </GridContainer >
    <GridContainer style={{marginTop: "20px"}}>
    <GridItem xs={12} sm={12} md={12} >
    <TextField
      id="outlined-dense"
      margin="dense"
      variant="outlined"
      placeholder="Your Email Address"
      className="EmailInput"
      label=""
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline,
          input: classes.multilineColor
        }
      }}
      
    />
    </GridItem>
    </GridContainer>
   </GridItem> */}
        </div>
         <div className="breakarea">
         <GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"center"}}>
          <hr className="LineBreakDown"/>
          </GridItem>
          <div style={{marginTop:"20px",marginBottom:"20px !important", textAlign:"center",display:"flex", justifyContent:"center"}}>
            <p className="Copyright">Copyright © 2019 All Rights Reserved.</p>
          </div>
         </div>
         </div>
    );
  }
}

LookupRice.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(LookupRice);