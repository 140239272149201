/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import logo from 'assets/img/bg-img/agdnew.png';
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { API, IMG_LOAD } from 'constants/index.js';
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";
const styles = {
  ...sweetAlertStyle,
  ...registerPageStyle,
  ...customSelectStyle,
  noPadding: {
    padding: "0px 0px !important"
  },
  paddingAddress: {
    marginLeft: "-33px",
    padding: "0px 10px !important",
    marginTop: "-6px"
  }
}
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],

      user_name: "",
      usernameState: "",
      usernameHelp: "",

      email: "",
      emailState: "",
      emailHelp: "",

      addressState: "",
      addressHelp: "",
      address: "",

      phoneState: "",
      phoneHelp: "",
      phone: "",

      pwhashState: "",
      pwhashHelp: "",
      pw_hash: "",

      passre: "",
      passreState: "",
      passreHelp: "",

      username: "",
      loginnameState: "",
      loginnameHelp: "",

      destime: ["Nhà sản xuất", "Nhà phân phối", "Nhà bán lẻ", "Nhà vận chuyển"],
      networkType: ["Mainnet", "Testnet"],
      alert: null,
      type: "",
      dntype:"",
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectNet = this.handleSelectNet.bind(this)
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    //console.log(geocodedPrediction.formatted_address, originalPrediction) // eslint-disable-line
    this.setState({
      address: geocodedPrediction.formatted_address,
      search: "",
    })
    //this.setState({ search: "", value: geocodedPrediction.formatted_address })
  }
  handleNoResult = () => {
    console.log('Không có kết quả', this.state.search)
  }

  handleStatusUpdate = (status) => {
   // console.log(status)
  }
  handleSubscribe() {
    const data = {
      "email": this.state.email,
      "username": this.state.user_name,
      "pw_hash": this.state.pw_hash,
      "address": this.state.address,
      "phone": this.state.phone
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    axios.post(API.LINK_API.REGISTER, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-200px" }}
                title="Thành công!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Đăng nhập"
                cancelBtnText="Đóng"
                showCancel
              ></SweetAlert>
            )
          });
        
        } else {
          await this.setState({ load: false });
        }
      }).catch(async () => {

      });
  }
 async  handleSelect(event) {
   if(event.target.value === "Nhà sản xuất"){
    await this.setState({ type: "M", dntype: event.target.value });
   }
   if(event.target.value === "Nhà phân phối"){
    await this.setState({ type: "F", dntype: event.target.value });
   }
   if(event.target.value === "Nhà bán lẻ"){
    await this.setState({ type: "R", dntype: event.target.value }); 
   }
   if(event.target.value === "Nhà vận chuyển"){
    await this.setState({ type: "D", dntype: event.target.value }); 
   }
    //await console.log(this.state.type)
    document.getElementById("noti_sub").innerHTML = "";
  }
  async  handleSelectNet(event) {
    if(event.target.value === "Mainnet"){
     await this.setState({ network: 88888,
                           netType: event.target.value });
    }
    if(event.target.value === "Testnet"){
     await this.setState({ network: 48700 , netType: event.target.value });
    }
     document.getElementById("noti_sub").innerHTML = "";
   }
  validate = async () => {
    if (!this.state.user_name) {
      this.setState({
        usernameState: "error",
        usernameHelp: "Vui lòng nhập tên doanh nghiệp"
      });
      return;
    }
    if (!this.state.username) {
      this.setState({
        loginnameState: "error",
        loginnameHelp: "Vui lòng nhập tên đăng nhập"
      });
      return;
    }else{
      this.setState({
        loginnameState: "success",
        loginnameHelp: ""
      });
    }
    if (!this.state.email) {
      this.setState({
        emailState: "error",
        emailHelp: "Vui lòng nhập email"
      });
      return;
    } else {
      var checkmail = this.verifyEmail(this.state.email);
      if (checkmail === false) {
        this.setState({
          emailState: "error",
          emailHelp: "Email không hợp lệ"
        });
      }
    }
    if (this.state.pw_hash !== this.state.passre) {
      this.setState({
        passreState: "error",
        passreHelp: "Không khớp với mật khẩu đã nhập"
      });
      return;
    } else {
      this.setState({
        passreState: "success",
        passreHelp: ""
      });
    }
    if (!this.state.pw_hash) {
      this.setState({
        pwhashState: "error",
        pwhashHelp: "Vui lòng nhập mật khẩu"
      });
      return;
    }else{
      this.setState({
        pwhashState: "success",
        pwhashHelp: ""
      });
    }
    if (!this.state.passre) {
      this.setState({
        passreState: "error",
        passreHelp: "Vui lòng nhập lại mật khẩu"
      });
      return;
    }else{
      this.setState({
        passreState: "success",
        passreHelp: ""
      });
    }
    if (!this.state.address) {
      this.setState({
        addressState: "error",
        addressHelp: "Vui lòng nhập địa chỉ doanh nghiệp"
      });
      return;
    }else{
      this.setState({
        addressState: "success",
        addressHelp: ""
      });
    }
    if (!this.state.phone) {
      this.setState({
        phoneState: "error",
        phoneHelp: "Vui lòng số điện thoại doanh nghiệp"
      });
      return;
    }else{
      this.setState({
        phoneState: "success",
        phoneHelp: ""
      });
    }
    if (!this.state.type) {
      document.getElementById("noti_sub").innerHTML = "Vui lòng chọn loại doanh nghiệp";
      return;
    }
    // if(this.state.network === undefined || this.state.network ===""){
    //   document.getElementById("noti_sub").innerHTML = "Vui lòng chọn loại mạng";
    //   return;
    // }
    if(this.state.checked[0] !== 1){
      document.getElementById("noti_sub").innerHTML = "Vui lòng đồng ý điều kiện";
      return;
    }
    
    const data = await {
      "email": this.state.email,
      "name": this.state.user_name,
      "pw_hash": this.state.pw_hash,
      "address": this.state.address,
      "phone": this.state.phone,
      "username": this.state.username,
      "type": this.state.type,
      "network": this.state.network
      //"network": 48700
    }
    
    await this.props.onRegister(data);
    
  }
  async handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    await this.setState({
      checked: newChecked
    });
    if (this.state.checked[0] === 1) {
      document.getElementById("noti_sub").innerHTML = "";
    }
    //console.log(this.state.checked)
  }
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  handleChange = name => async event => {
    if(name==="address"){
      this.setState({ search: event.target.value, })
    }
    await this.setState({
      [name]: event.target.value,
    });
    if (name === "user_name") {
      if (this.state.user_name) {
        this.setState({
          usernameHelp: "",
          usernameState: "success"
        })
      } else {
        this.setState({
          usernameHelp: "",
          usernameState: "error"
        })
      }
    }
    if (name === "email") {
      if (this.state.email) {
        this.setState({
          emailHelp: "",
          emailState: "success"
        })
      } else {
        this.setState({
          emailHelp: "",
          emailState: "error"
        })
      }
      if (name === "pw_hash") {
        if (this.state.pw_hash) {
          this.setState({
            pwhashHelp: "",
            pwhashState: "success"
          })
        } else {
          this.setState({
            pwhashHelp: "",
            pwhashState: "error"
          })
        }
      }
      if (name === "address") {
        if (this.state.address) {
          this.setState({
            addressHelp: "",
            addressState: "success"
          })
        } else {
          this.setState({
            addressHelp: "",
            addressState: "error"
          })
        }
      }
      if (name === "phone") {
        if (this.state.phone) {
          this.setState({
            phoneHelp: "",
            phoneState: "success"
          })
        } else {
          this.setState({
            phoneHelp: "",
            phoneState: "error"
          })
        }
      }
      if (name === "passre") {
        if (this.state.passre) {
          this.setState({
            passreHelp: "",
            passreState: "success"
          })
        } else {
          this.setState({
            passreHelp: "",
            passreState: "error"
          })
        }
      }
      if(name === 'username'){
        if (this.state.login_name) {
          this.setState({
            loginnameHelp: "",
            loginnameState: "success"
          })
        } else {
          this.setState({
            loginnameHelp: "",
            loginnameState: "error"
          })
        }
      }
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <div className={classes.center}>
                <img src={logo} style={{ width: 150 }} />
              </div>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={11} sm={11} md={5} className= {classes.noPadding}>
                    <form className={classes.form}>
                      <CustomInput
                        success={this.state.loginnameState === "success"}
                        error={this.state.loginnameState === "error"}
                        helpText={this.state.loginnameHelp}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('username'),
                        }}
                        inputProps={{
                          value: this.state.username,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                credit_card
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Tên đăng nhập"
                        }}
                      />
                      <CustomInput
                        success={this.state.emailState === "success"}
                        error={this.state.emailState === "error"}
                        helpText={this.state.emailHelp}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('email'),
                        }}
                        inputProps={{
                          value: this.state.email,
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Email"
                        }}
                      />
                      <CustomInput
                        success={this.state.pwhashState === "success"}
                        error={this.state.pwhashState === "error"}
                        helpText={this.state.pwhashHelp}
                        variant="outlined"
                        labelProps={{
                          variant: "outlined",
                        }}
                        formControlProps={{
                          variant: 'outlined',
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('pw_hash'),
                        }}
                        inputProps={{
                          value: this.state.pw_hash,
                          type: "password",
                          autoComplete: "off",
                          variant: "outlined",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Mật khẩu"
                        }}
                      />
                      <CustomInput
                        success={this.state.passreState === "success"}
                        error={this.state.passreState === "error"}
                        helpText={this.state.passreHelp}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('passre'),
                        }}
                        inputProps={{
                          value: this.state.passre,
                          type: "password",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Nhập lại mật khẩu"
                        }}
                      />
                    </form>
                  </GridItem>
                  {/* <GridItem xs={1} sm={1} md={1} style={{ display: "flex", justifyContent: "center" }}>
                    <div className="dividearea"> </div>
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={5}>
                    <div className={classes.center}>
                    </div>
                    <form className={classes.form}>
                      <CustomInput
                        success={this.state.usernameState === "success"}
                        error={this.state.usernameState === "error"}
                        helpText={this.state.usernameHelp}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('user_name'),
                        }}
                        inputProps={{
                          value: this.state.user_name,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Tên doanh nghiệp"
                        }}
                      />
                      {/* <CustomInput
                        success={this.state.addressState === "success"}
                        error={this.state.addressState === "error"}
                        helpText={this.state.addressHelp}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('address'),
                        }}
                        inputProps={{
                          value: this.state.address,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                location_on
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Địa chỉ"
                        }}
                      /> */}
                      <CustomInput
                        success={this.state.phoneState === "success"}
                        error={this.state.phoneState === "error"}
                        helpText={this.state.phoneHelp}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                          onChange: this.handleChange('phone'),
                        }}
                        inputProps={{
                          value: this.state.phone,
                          type: "number",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                phone
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Số điện thoại"
                        }}
                      />
                          <ReactGoogleMapLoader
                    params={{
                      key: MY_API_KEY,
                      libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                      googleMaps && (
                        <div>
                          <ReactGooglePlacesSuggest
                            autocompletionRequest={{ input: this.state.search }}
                            googleMaps={googleMaps}
                            onSelectSuggest={this.handleSelectSuggest.bind(this)}
                            displayPoweredByGoogle={false}
                          >
                            <CustomInput
                              labelText="Địa chỉ"
                              success={this.state.addressState === "success"}
                              error={this.state.addressState === "error"}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                                onChange: this.handleChange('address'),
                              }}
                              inputProps={{
                                value: this.state.address,
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon className={classes.inputAdornmentIcon}>
                                    location_on
                              </Icon>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </ReactGooglePlacesSuggest>
                        </div>
                      )
                    }
                  />
                    </form>
                    <div style={{marginTop: "13px"}}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          Loại doanh nghiệp
                              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          
                          value={this.state.dntype}
                          onChange={this.handleSelect}
                          inputProps={{
                            name: "dntype",
                            id: "dn_type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Chọn loại
                        </MenuItem>
                          {this.state.destime.map((x,idx) => (
                            <MenuItem
                            key={idx}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{marginTop: "4px"}}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="product-expired-type"
                          className={classes.selectLabel}
                        >
                          Loại mạng
                              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          id='select'
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.netType}
                          onChange={this.handleSelectNet}
                          inputProps={{
                            name: "dntype",
                            id: "dn_type"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Chọn loại
                        </MenuItem>
                          {this.state.networkType.map((x,idx) => (
                            <MenuItem
                            key={idx}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                 
                  </GridItem>
                </GridContainer>
                <GridContainer style={{display: "flex", justifyContent: 'center'}}>
                  <GridItem xs={12} sm={12} md={10} lg={10} className={classes.paddingAddress}>
                 
                  </GridItem>
                </GridContainer>
                <div className={classes.center}>
                  <FormControlLabel
                    classes={{
                      root: classes.checkboxLabelControl,
                      label: classes.checkboxLabel
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle(1)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label={
                      <span style={{fontFamily: "Muli"}}>
                        Bạn đồng ý với {" "}
                        <a href="https://agridential.vn/vi/privacy-policy/" target="_blank" style={{ color: "#679847" }}>Điều khoản và Điều kiện Sử dụng của Agridential</a>.
                          </span>
                    }
                  />
                </div>
                <div className={classes.center} style={{display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center"}}>
                <p
                    id="noti_sub"
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      color: 'red',
                      fontFamily: '"Muli',
                      marginTop: 5,
                      marginBottom: 5,
                    }}>
                  </p>
                  <Button color="success" onClick={this.validate} style={{width: "300px"}}>
                    Đăng ký
                    </Button>
                    {/* <span style={{fontSize: "15px", fontWeight: "700", marginTop: "5px", color: "#3C4858", fontFamily: "Muli"}}>Đã có tài khoản ? <a onClick={this.props.onProcess } className="registertext">Đăng nhập</a></span> */}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.alert}
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RegisterPage);
