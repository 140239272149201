/*eslint-disable*/
import React from "react";
import cookie from 'react-cookies';
import axios from 'axios';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { withRouter } from 'react-router-dom';
// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import { withStyles } from "@material-ui/styles";
import Detail from "@material-ui/icons/Info";
import { style } from "@material-ui/system";
import { connect } from 'react-redux';
import {
  AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend
} from 'recharts';
import { LINK_API } from 'constants/API';

import GeneralStatistic from "./generalStatistic.jsx";
import BranchDistributor from "./branchAndDistributor.jsx";
import GeneralActiveStamp from "./generalActiveStamp.jsx";
import ShipmentStatistic from "./shipmentStatistic.jsx";
import SlaughterStatistic from "./slaughterStatistic.jsx";

import ReactExport from "react-data-export";
import strings from "constants/strings.jsx"
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: 'M',
    is_married: true
  },
  {
    name: "Monika",
    amount: 355000,
    sex: 'F',
    is_married: false
  },
  {
    name: "John",
    amount: 250000,
    sex: 'M',
    is_married: false
  },
  {
    name: "Josef",
    amount: 450500,
    sex: 'M',
    is_married: true
  }
];

var dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7
  }
];


var delays = 80;
const data_zone = [
  {
    name: 'T7', vsx: 15720,
  },
  {
    name: 'T8', vsx: 14379,
  },
  {
    name: 'T9', vsx: 16179,
  },
];
const data_employee = [
  {
    name: 'T7', nv: 15,
  },
  {
    name: 'T8', nv: 18,
  },
  {
    name: 'T9', nv: 14,
  },
];
const data_productType = [
  {
    name: 'T7', sp: 2,
  },
  {
    name: 'T8', sp: 4,
  },
  {
    name: 'T9', sp: 6,
  },
];
const data_productObject = [
  {
    name: 'T7', dt: 64,
  },
  {
    name: 'T8', dt: 61,
  },
  {
    name: 'T9', dt: 75,
  },
];
const data_crop = [
  {
    name: 'T7', mv: 249,
  },
  {
    name: 'T8', mv: 378,
  },
  {
    name: 'T9', mv: 373,
  },
]

const branchs = [
  {
    "name": "CN1",
    "slsp": 4000,
  },
  {
    "name": "CN2",
    "slsp": 3000,
  },
  {
    "name": "CN3",
    "slsp": 2000,
  },
  {
    "name": "CN4",
    "slsp": 2780,
  },
  {
    "name": "CN5",
    "slsp": 1890,
  },
]

var delays2 = 80,
  durations2 = 500;
const simpleBarChart = {
  options: {
    seriesBarDistance: 10,
    axisX: {
      showGrid: true
    }
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};
const data_sample = {
  "productTypeCount": 12,
  "employeeCount": 47,
  "productObjectCount": 200,
  "cropCount": 1000,
  "productZoneCount": 46278,
  "productType": [
    {
      "count": 2,
      "month": 7,
      "year": 2019
    },
    {
      "count": 4,
      "month": 8,
      "year": 2019
    },
    {
      "count": 6,
      "month": 9,
      "year": 2019
    }
  ],
  "employee": [
    {
      "count": 15,
      "month": 7,
      "year": 2019
    },
    {
      "count": 18,
      "month": 8,
      "year": 2019
    },
    {
      "count": 14,
      "month": 9,
      "year": 2019
    }
  ],
  "productObject": [
    {
      "count": 64,
      "month": 7,
      "year": 2019
    },
    {
      "count": 61,
      "month": 8,
      "year": 2019
    },
    {
      "count": 75,
      "month": 9,
      "year": 2019
    }
  ],
  "productZone": [
    {
      "count": 15720,
      "month": 7,
      "year": 2019
    },
    {
      "count": 14379,
      "month": 8,
      "year": 2019
    },
    {
      "count": 16179,
      "month": 9,
      "year": 2019
    }
  ],
  "crop": [
    {
      "count": 249,
      "month": 6,
      "year": 2019
    },
    {
      "count": 378,
      "month": 7,
      "year": 2019
    },
    {
      "count": 373,
      "month": 8,
      "year": 2019
    }
  ],
  "activedStamp": []
}
class Charts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      harvestStatistic: "",
      generalStampActives: [],
      branchStatistic: [],
      numPOProcessing: 0,
      numPORaising: 0,
      productType: {
        data: {
          labels: [
          ],
          series: [[]]
        },
      },
      productObjects: {
        data: {
          labels: [
          ],
          series: [[]]
        },
      },
      productZone: {
        data: {
          labels: [
          ],
          series: [[]]
        },
      },

      employee: {
        data: {
          labels: [
          ],
          series: [[]]
        },
      },

      crop: {
        data: {
          labels: [
          ],
          series: [[]]
        },
      }
    }
    this.getData = this.getData.bind(this);
    this.getQuantity = this.getQuantity.bind(this)
    this.checkPermission = this.checkPermission.bind(this);
    this.analize = this.analize.bind(this);
    this.token = cookie.load('token');
  }
  async componentDidMount() {
    try {
      await this.checkPermission();

      await axios.get(LINK_API.GET_CATTLE)
      .then(async res=>{
        let data = res.data.data;
        let datamap = await data.map(item=>{
            return {
                value: item.id,
                label: item.id
            }
        })
        
      

        await this.setState({options: datamap});
      })

      await axios.get(LINK_API.GET_STATISTIC_NUM_OBJECT)
        .then(response => {
          if (response.data.errorCode === 1) {
            this.setState({
              numPOProcessing: response.data.data.numPOProcessing,
              numPORaising: response.data.data.numPORaising
            })
          }
        })
        .catch(err => {
          console.log(err);
        })

      this.getQuantity();
      // this.props.getGeneralStampActive();
      this.props.getHarvestStatistic();
      this.props.getBranchStatistic();
      await this.props.getProductTypeList();
      await this.props.getNumberAccesses();
      await this.analize(data_sample)
    }
    catch (error) {
      console.log(error)
    }
  }



  async componentWillReceiveProps(preProps) {
    if (this.props.generalStampActive !== preProps.generalStampActive) {
      // console .log("generalStampActives",this.state.generalStampActives)
    }
  }

  async getData() {
    try {
      let response = await axios.get(LINK_API.GET_STATICTIS);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }
  async getQuantity() {
    try {
      let response = await axios.get(LINK_API.GET_QUANTITIY);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }
  async analize(data) {
    const productType = this.state.productType;
    const productObjects = this.state.productObjects;
    const productZone = this.state.productZone;
    const employee = this.state.employee;
    const crop = this.state.crop;

    this.setState({
      productZone: productZone,
      productType_Count: data.productTypeCount,
      productOject_Count: data.productObjectCount,
      productZone_Count: data.productZoneCount,
      employee_Count: data.employeeCount,
      crop_Count: data.cropCount
    })


  }
  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove('token')
        return this.props.history.push("/");
      }
      else {
        axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
      }
    }
    catch (error) {
      console.log(error);
      await cookie.remove('token');
      return this.props.history.push("/");
    }
  }
  render() {
    const { classes } = this.props;
    let history = this.props.history;
    return (
      <div>
        <h2 className="cardtableLabel">{strings.statistical}</h2>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GeneralStatistic generalStampActives={this.state.generalStampActives} classes={classes} history={history} numPOProcessing={this.state.numPOProcessing} numPORaising={this.state.numPORaising} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <ShipmentStatistic classes={classes} history={history} options={this.state.options}/>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <SlaughterStatistic classes={classes} history={history} />
          </GridItem>
        </GridContainer>

        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <BranchDistributor history={history} classes={classes} />
          </GridItem>
        </GridContainer> */}
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getHarvestStatistic: () => dispatch({ type: "GET_HARVEST_STATISTIC" }),
    getBranchStatistic: () => dispatch({ type: "GET_BRANCH_STATISTIC" }),
    getNumberAccesses: () => dispatch({ type: "GET_NUMBER_ACCESSES" }),
    getGeneralStampActive: () => dispatch({ type: "GET_STAMP_ACTIVE_GENERAL" }),
    getProductTypeList: () => dispatch({ type: "GET_PRODUCT_LIST" }),
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    harvestStatistic: state.harvestStatistic,
    branchStatistic: state.branchStatistic,
    numberAccesses: state.numberAccesses,
    numProductToBranch: state.numProductToBranch,
    generalStampActive: state.generalStampActive,
    productList: state.productType.data
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Charts)));