import React from "react";
import cookie from 'react-cookies';
import axios from 'axios';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Gallery from './Gallery/Gallery.js';

import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PictureUpload from './PictureUpload.jsx';
import { LINK_API } from 'constants/API';
import { IMG_LOAD } from "constants/index.js";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import ImageUpload from "./ImageUpload.jsx";
import ImageCount from "../ImageUpload/ImageCount.jsx"
import ChangePwdForm from "./ChangePwdForm.jsx";
import { connect } from "react-redux"
import strings from "../../../../constants/strings.jsx";
const LZUTF8 = require('lzutf8');
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx').Transaction;
const style = {
  ...sweetAlertStyle,
  ...regularFormsStyle,
  ...customSelectStyle,
  checkboxAndRadio: {
    ...regularFormsStyle.checkboxAndRadio,
    textAlign: "left"
  },
  noPadAvatar: {
    padding: "0px 0px !important"
  }
}

class EditProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gln: null,
      user_name: "",
      address: "",
      phone: "",
      email: "",
      website: "",
      description: "",
      certificates: {
        "certificates": []
      },
      currentImage: 0,
      showPassword: false,
      alert: null,
      list: [],
      list_length: 0,
      avatar: "",
      listAvatar: [],
      avartarEditable: false,
      temp: "",
      changeavatar: false,
      changePw: false,
      countImage: 0,
      deleteImage: {
        "certificates": []
      },
      addImage: {
        "certificates": []
      },
      deleteAvatar: {
        "avatars": []
      },
      addAvatar: {
        "avatars": []
      },
      deleteState: false,
      checked: false,
      checked1: false,
      checkedTrue: false,
      changeContent: false,
      altName: false,
      altName_input: "",
      name_check: false,
      time_check: false,
      process_check: false,
      blockchain_check: "",
      display: "none"
    };
    this.token = cookie.load('token');
    this.handleToggle = this.handleToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitData = this.submitData.bind(this);
    this.handleEditProfile = this.handleEditProfile.bind(this);
    this.handleContent = this.handleContent.bind(this)
    this.handleContentContact = this.handleContentContact.bind(this)
    this.handlePassword = this.handlePassword.bind(this)
    this.cancelPw = this.cancelPw.bind(this)
    this.initMap = this.initMap.bind(this)
    this.onImageSubmit = this.onImageSubmit.bind(this)
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this)
    this.getProfile = this.getProfile.bind(this);
    this.handleCloseedit = this.handleCloseedit.bind(this);
    this.handleCloseeditContact = this.handleCloseeditContact.bind(this);
    this.handleSaveImage = this.handleSaveImage.bind(this);
    this.handleEditAll = this.handleEditAll.bind(this);
    this.logoutToChange = this.logoutToChange.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleCheckedAltName = this.handleCheckedAltName.bind(this);
    this.CreateRaw = this.CreateRaw.bind(this);
    this.SendApprove = this.SendApprove.bind(this);
  }

  hideAlert1 = () => {
    this.setState({
      alert: null
    })
  }

  async CreateRaw(){
    try{
      let response = await axios.post(LINK_API.GET_RAW_APPROVE);
      if (response.data.errorCode === 1) {
        var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.password);
        const privatekey = bytes.toString(CryptoJS.enc.Utf8);

        const privatekey_slice = privatekey.substring(2, privatekey.length)
        const Buffer_privatekey = await Buffer.from(privatekey_slice.toString(), 'hex');
        const tx = new EthereumTx(response.data.data.raw, { chain: 4 });
        tx.sign(Buffer_privatekey);
        const rawTx = '0x' + tx.serialize().toString('hex');
        await this.SendApprove(rawTx);
      }
      else{
        await this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block' }}
              title="Không thành công"
              onConfirm={() => this.hideAlert1()}
              // onCancel={() => this.hideAlert()}
              confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
            />
          )
        });
      }
    }
    
    catch(err){
      await this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block' }}
            title="Không thành công"
            onConfirm={() => this.hideAlert1()}
            // onCancel={() => this.hideAlert()}
            confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          />
        )
      });
    }
  }

  async SendApprove(rawTx){
    try{
      let data = {"raw": rawTx}
      let response = await axios.post(LINK_API.SEND_APPROVE, data);
      if (response.data.errorCode === 1) {
        await this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block' }}
              title="Thành công"
              closeOnClickOutside={false}
              // onCancel={() => this.hideAlert_claimed(response.data.data.raw)}
              onConfirm={() => this.hideAlert1()}
              showCancel={false}
            />
          )
        });
      }else{
        await this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block' }}
              title="Không thành công"
              onConfirm={() => this.hideAlert1()}
              // onCancel={() => this.hideAlert()}
              confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
            />
          )
        });
      }
    }
    catch(err){
      await this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block' }}
            title="Không thành công"
            onConfirm={() => this.hideAlert1()}
            // onCancel={() => this.hideAlert()}
            confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          />
        )
      });
    }
    
  }

  handleSave = async () => {
    try {
      const data = {
        "alternativeName": this.state.altName_input,
        "showAlterName": this.state.altName,
        "showTimeDiary": this.state.time_check,
        "showDetailBizProcess": this.state.process_check
      }
      let response = await axios.post(LINK_API.POST_THIETLAP,  data);
      if (response.data.errorCode === 1) {
        if(this.state.blockchain_check){
          await this.CreateRaw();
        }
        else{
          await this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: 'block' }}
                title="Thành công"
                closeOnClickOutside={false}
                // onCancel={() => this.hideAlert_claimed(response.data.data.raw)}
                onConfirm={() => this.hideAlert1()}
                showCancel={false}
              />
            )
          });
        }
        
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block' }}
              title="Không thành công"
              onConfirm={() => this.hideAlert1()}
              // onCancel={() => this.hideAlert()}
              confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
            />
          )
        });
      }
    }
    catch (error) {
      await this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block' }}
            title="Không thành công"
            onConfirm={() => this.hideAlert1()}
            // onCancel={() => this.hideAlert()}
            confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
          />
        )
      });
    }
  }

  isEmptyObject = (obj) => {
    if (!obj) return true
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  handleChecked = name => event => {
    document.getElementById("noti").innerHTML = "";
    this.setState({ [name]: event.target.checked })
  };

  handleChecked1 = name => event => {
    // console.log(event.target.checked);
    document.getElementById("noti1").innerHTML = "";
    this.setState({ [name]: event.target.checked })
  };

  handleCheckedAltName = name => event => {
    if(event.target.checked){
      document.getElementById("altNameInput").style = "margin-top: -28px !important; display: block";
      this.setState({ [name]: event.target.checked })
    }
    else{
      document.getElementById("altNameInput").style = "margin-top: -28px !important; display: none";
      this.setState({ [name]: event.target.checked })
    }
    
  };

  logoutToChange= () => {
    this.setState({
      alert: null,
    })
    this.handleSignOut();
  }
  handleSignOut = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    axios
      .get(LINK_API.SIGN_OUT, {})
      .then(async response => {
        this.setState({
          alert: null
        });

        if (response.data.errorCode == 1) {
          await this.props.cleanStore();
          this.props.history.push("/");
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                Đăng xuất thành công
              </SweetAlert>
            )
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  }
  async componentDidMount() {
    try{
      let response = await axios.get(LINK_API.GET_SETTING);
      if(response.data.errorCode == 1){
        // console.log(response);
        await this.setState({
          altName_input: response.data.data[0].alternativeName,
          altName: response.data.data[0].showAlterName,
          time_check: response.data.data[0].showTimeDiary,
          process_check: response.data.data[0].showDetailBizProcess,
          // name_check: response.data.data[0].
        })

        if(response.data.data[0].showAlterName){
          // document.getElementById("altNameInput").style = "margin-top: -28px !important; display: block";
          await this.setState({display: "block", marginTop: "-28px !important"})
        }
        else{
          await this.setState({display: "none", marginTop: "-28px !important"})
        }
      }

    }catch(err){
      console.log(err)
    }
    this.setState({
      list_length: this.state.list.length
    })
    await this.checkPermission();
    await this.props.getUserProfile()
  }
  componentWillReceiveProps(preProps) {
    if (this.props.userProfile !== preProps.userProfile) {
      if (preProps.userProfile) {
        this.updateProfile(preProps.userProfile)
      }
    }
  }
  updateProfile = async (data) => {
    await this.setState({
      gln: this.gln,
      username: data.name,
      address: data.address,
      phone: data.phone,
      email: data.email,
      website: data.website,
      description: data.description,
      avatar: data.avatar ? (data.avatar.length > 0 ? data.avatar[0].secure_url: "") : "",
      certificates: data.certificates,
      banner: data.banner ? data.banner.secure_url: "" ,
      logo: data.logo? data.logo.secure_url: "",
      changeavatar: false,
      changeContent: false,
      deleteImage: {
        "certificates": []
      },
      addImage: {
        "certificates": []
      },
      deleteAvatar: {
        "avatars": []
      },
      addAvatar: {
        "avatars": []
      },
    })
    await this.setState({
      list: [],
    })
    var x = data.certificates.length;
    this.setState({
      countImage: data.certificates.length,
      deleteState: false,
    })
    var i = 0;
    var temp = [];
    for (i = 0; i < x; i++) {
      if (data.certificates[i]) {
        temp.push({
          src: data.certificates[i],
          thumbnail: data.certificates[i],
         // thumbnailWidth: 160,
          //thumbnailHeight: 200,
          isSelected: false,
          caption: "Chứng nhận",
          url: data.certificates[i],
        })
      }
    }
    var y = data.avatar ? data.avatar.length : 0;
    var j = 0;
    var listTemp = [];
    for(j=0;j<y;j++){
      listTemp.push(data.avatar[j].secure_url)
    }
    await this.setState({
      list: temp,
      listAvatar: listTemp
    })
    await this.clickChild();
  }
  getProfile = async () => {
    try {
      let response = await axios.get(LINK_API.GET_PROFILE);
      if (response.data.errorCode == 1) {
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }
  onCurrentImageChange(index) {
    if (this.state.list.length === 1) {
      this.setState({ currentImage: 0 })
    } else {
      this.setState({ currentImage: index });
    }
  }
  async checkPermission() {
    try {
      let response = await axios.get(LINK_API.VERIFY_TOKEN, {
        headers: { Authorization: `Bearer ${this.props.userInfo.data.token}` }
      });
      if (response.data.errorCode !== 1 || !response.data.data.valid) {
        await cookie.remove("token");
        return this.props.history.push("/");
      } else {
        axios.defaults.headers.common["Authorization"] = this.props.userInfo.data.token;
      }
    } catch (error) {
      console.log(error);
      await cookie.remove("token");
      return this.props.history.push("/");
    }
  }

  hideAlert = async () => {
    this.handleCloseeditContact();
    this.handleCloseedit();
    await this.setState({
      alert: null,
      checked: false,
      checked1: false
    });
    this.props.getUserProfile();
  };

  handleToggle() {
    const { showPassword } = this.state;

    if (showPassword) {
      this.setState({
        showPassword: false
      });
    }
    else {
      this.setState({
        showPassword: true
      });
    }

  }


  handleChangePwd = (data) => {
    //this.closeChangePwdDialog();
    if (!data) return; // <=> Close dialog

    //loading
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    let that = this;
    axios
      .post(LINK_API.CHANGE_PWD, data)
      .then(function (response) {
        that.setState({
          alert: null
        });

        if (response.data.errorCode == 1) {
          that.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => that.logoutToChange()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đăng nhập lại"
                closeOnClickOutside={false}
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Bạn cần phải đăng nhập lại
              </SweetAlert>
            )
          });
        } else if (response.data.errorCode == 11) {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Mật khẩu không chính xác
              </SweetAlert>
            )
          });
        } else {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
              </SweetAlert>
            )
          });
        }
      })
      .catch(function (error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng thử lại
            </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };


  handleChange = name => event => {
    this.setState({ [name]: event.target.value, changeContent: true });
  }

  handleClose = () => {
    this.props.onConfirm(null);
    // this.props.history.push("profile");
  }
  async handleSaveImage() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });
    const x = this.state.list.length;
    var i = 0;
    for (i = 0; i < x; i++) {
      if (!this.state.list[i].url) {
        this.state.addImage.certificates.push(LZUTF8.compress(this.state.list[i].src, { outputEncoding: 'Base64' }))
      }
    }
    if (this.state.addImage.certificates) {
      await this.addCertificates(this.state.addImage);
    }
    if (this.state.deleteImage.certificates) {
      await this.deleteCertificates(this.state.deleteImage)
    }

  }
  deleteCertificates(data) {
    var x = data.certificates.length;
    var i = 0;
    var temp = {
      "certificates": [],
    }
    for (i; i < x; i++) {
      if (data.certificates[i].url) {
        temp.certificates.push(data.certificates[i].src)
      }
    }
    if (temp.certificates) {
      let that = this
      axios
        .post(LINK_API.DELETE_CERTIFICATES, temp)
        .then(async function () {
          //console.log("success");
          that.setState({
            deleteImage: {
              "certificates": []
            },
            deleteState: false
          })
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }

  }
  addCertificates(data) {
    let that = this;
    axios
      .post(LINK_API.ADD_CERTIFICATES, data)
      .then(async function (response) {
        if (response.data.errorCode == 1) {
          await that.setState({
            addImage: {
              "certificates": []
            },
            deleteState: true,
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                closeOnClickOutside={false}
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Cập nhật chứng nhận thành công
            </SweetAlert>
            )
          });
          await that.setState({
            deleteState: false
          })
        } else if (response.data.errorCode == 11) {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
            </SweetAlert>
            )
          });
        } else {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
            </SweetAlert>
            )
          });
        }
      })
      .catch(function (error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng thử lại
          </SweetAlert>
          )
        });
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }
  submitData = async () => {
    if ((this.state.user_name == this.user_name) &&
      (this.state.phone == this.phone) &&
      (this.state.address == this.address) &&
      (this.state.website == this.website)) this.handleEditProfile(null);

    else {
      if (this.state.changeavatar === false) {
        await this.setState({
          avatar: "",
        })
      }
      const data = {
        "name": this.state.username,
        "address": this.state.address,
        "standard": "GS1",
        "phone": this.state.phone,
        "website": this.state.website,
        "localtion_gps": "",
        "description": this.state.description
      }
      if (this.state.changeContent === true && this.state.changeavatar === true) {
        this.handleEditAll(data)
      } else {
          this.handleEditProfile(data)
      }
    }
  }
  handleEditAll = async (data1) => {
    if (!data1) return;
    let that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });
    if(this.state.deleteAvatar.avatars.length > 0) {
     await axios.post(LINK_API.EDIT_PROFILE_DELETE_AVATAR, this.state.deleteAvatar)
      .then( async function (response) {
        if (response.data.errorCode !== 1) {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
            </SweetAlert>
            )
          });
          return;
        }
      }
      )
      .catch(function (error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng thử lại
          </SweetAlert>
          )
        });
        return;
        console.log(error);
      })
    }

    if(this.state.addAvatar.avatars.length > 0) {
     await axios.post(LINK_API.EDIT_PROFILE_ADD_AVATAR, this.state.addAvatar)
      .then( async function (response) {
        if (response.data.errorCode !== 1) {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
            </SweetAlert>
            )
          });
          return;
        }
      }
      )
      .catch(function (error) {
        that.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Không thành công"
              onConfirm={() => that.hideAlert()}
              onCancel={() => that.hideAlert()}
              showCancel={false}
              confirmBtnText="Đóng"
              confirmBtnCssClass={
                that.props.classes.button + " " + that.props.classes.success
              }
            >
              Vui lòng thử lại
          </SweetAlert>
          )
        });
        console.log(error);
        return;
      })
    }
    // axios
    //   .post(LINK_API.EDIT_PROFILE_ADD_AVATAR, this.state.addAvatar)
    //   .then(async function (response) {
    //     if (response.data.errorCode === 1) {
          await axios
            .post(LINK_API.EDIT_PROFILE, data1)
            .then(async function (response) {
              that.setState({
                alert: null
              });
              // if (response.data.errorCode == 1) {
              //   that.user_name = data1.name;
              //   // that.setState({
              //   //   alert: (
              //   //     <SweetAlert
              //   //       success
              //   //       style={{ display: "block" }}
              //   //       title="Thành công"
              //   //       onConfirm={() => that.hideAlert()}
              //   //       onCancel={() => that.hideAlert()}
              //   //       showCancel={false}
              //   //       confirmBtnText="Đóng"
              //   //       closeOnClickOutside={false}
              //   //       confirmBtnCssClass={
              //   //         that.props.classes.button + " " + that.props.classes.success
              //   //       }
              //   //     >
              //   //       Cập nhật thông tin thành công
              //   //   </SweetAlert>
              //   //   )
              //   // });
              // } else 
              if (response.data.errorCode == 11) {
                that.setState({
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: "block" }}
                      title="Không thành công"
                      onConfirm={() => that.hideAlert()}
                      onCancel={() => that.hideAlert()}
                      showCancel={false}
                      confirmBtnText="Đóng"
                      confirmBtnCssClass={
                        that.props.classes.button + " " + that.props.classes.success
                      }
                    >
                      Vui lòng thử lại
                  </SweetAlert>
                  )
                });
              } else {
                that.setState({
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: "block" }}
                      title="Không thành công"
                      onConfirm={() => that.hideAlert()}
                      onCancel={() => that.hideAlert()}
                      showCancel={false}
                      confirmBtnText="Đóng"
                      confirmBtnCssClass={
                        that.props.classes.button + " " + that.props.classes.success
                      }
                    >
                      Vui lòng thử lại
                  </SweetAlert>
                  )
                });
              }
            })
            .catch(function (error) {
              that.setState({
                alert: (
                  <SweetAlert
                    error
                    style={{ display: "block" }}
                    title="Không thành công"
                    onConfirm={() => that.hideAlert()}
                    onCancel={() => that.hideAlert()}
                    showCancel={false}
                    confirmBtnText="Đóng"
                    confirmBtnCssClass={
                      that.props.classes.button + " " + that.props.classes.success
                    }
                  >
                    Vui lòng thử lại
                </SweetAlert>
                )
              });
              console.log(error);
            })
           that.setState({
                  alert: (
                    <SweetAlert
                      success
                      style={{ display: "block" }}
                      title="Thành công"
                      onConfirm={() => that.hideAlert()}
                      onCancel={() => that.hideAlert()}
                      showCancel={false}
                      confirmBtnText="Đóng"
                      closeOnClickOutside={false}
                      confirmBtnCssClass={
                        that.props.classes.button + " " + that.props.classes.success
                      }
                    >
                      Cập nhật thông tin thành công
                  </SweetAlert>
                  )
                });          
} 
      //   else {
      //     that.setState({
      //       alert: (
      //         <SweetAlert
      //           error
      //           style={{ display: "block" }}
      //           title="Không thành công"
      //           onConfirm={() => that.hideAlert()}
      //           onCancel={() => that.hideAlert()}
      //           showCancel={false}
      //           confirmBtnText="Đóng"
      //           confirmBtnCssClass={
      //             that.props.classes.button + " " + that.props.classes.success
      //           }
      //         >
      //           Vui lòng thử lại
      //         </SweetAlert>
      //       )
      //     });
      //   }
      // })
      // .catch(() => {
      //   that.setState({
      //     alert: (
      //       <SweetAlert
      //         error
      //         style={{ display: "block" }}
      //         title="Không thành công"
      //         onConfirm={() => that.hideAlert()}
      //         onCancel={() => that.hideAlert()}
      //         showCancel={false}
      //         confirmBtnText="Đóng"
      //         confirmBtnCssClass={
      //           that.props.classes.button + " " + that.props.classes.success
      //         }
      //       >
      //         Vui lòng thử lại
      //       </SweetAlert>
      //     )
      //   });
      // })

  // }
  handleEditProfile = async data => {
    // this.closeEditProfileDialog();
    if (!data) return; // <=> Close dialog
    //console.log("data",data.image);
    //loading
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });

    let that = this;
    if (this.state.changeavatar) {
      if(this.state.deleteAvatar.avatars.length > 0){

        await axios .post(LINK_API.EDIT_PROFILE_DELETE_AVATAR, this.state.deleteAvatar)
        .then(async function (response) {
          if(response.data.errorCode !==1){
            that.setState({
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block" }}
                  title="Không thành công"
                  onConfirm={() => that.hideAlert()}
                  onCancel={() => that.hideAlert()}
                  showCancel={false}
                  confirmBtnText="Đóng"
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                >
                  Vui lòng thử lại
              </SweetAlert>
              )
            });
           return;
          }
        }
        )
      }
      if(this.state.addAvatar.avatars.length > 0){
        await axios
        .post(LINK_API.EDIT_PROFILE_ADD_AVATAR, this.state.addAvatar)
        .then(async function (response) {
          that.setState({
            alert: null
          });
          // if (response.data.errorCode == 1) {
          //   that.setState({
          //     alert: (
          //       <SweetAlert
          //         success
          //         style={{ display: "block" }}
          //         title="Thành công"
          //         onConfirm={() => that.hideAlert()}
          //         onCancel={() => that.hideAlert()}
          //         showCancel={false}
          //         confirmBtnText="Đóng"
          //         closeOnClickOutside={false}
          //         confirmBtnCssClass={
          //           that.props.classes.button + " " + that.props.classes.success
          //         }
          //       >
          //         Cập nhật thông tin thành công
          //     </SweetAlert>
          //     )
          //   });
          // } else 
          if (response.data.errorCode == 11) {
            that.setState({
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block" }}
                  title="Không thành công"
                  onConfirm={() => that.hideAlert()}
                  onCancel={() => that.hideAlert()}
                  showCancel={false}
                  confirmBtnText="Đóng"
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                >
                  Vui lòng thử lại
              </SweetAlert>
              )
            });
            return;
          } else {
            that.setState({
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block" }}
                  title="Không thành công"
                  onConfirm={() => that.hideAlert()}
                  onCancel={() => that.hideAlert()}
                  showCancel={false}
                  confirmBtnText="Đóng"
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                >
                  Vui lòng thử lại
              </SweetAlert>
              )
            });
          }
          return;
        })
        .catch(function (error) {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
            </SweetAlert>
            )
          });
          console.log(error);
        })
      }
      that.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block" }}
            title="Thành công"
            onConfirm={() => that.hideAlert()}
            onCancel={() => that.hideAlert()}
            showCancel={false}
            confirmBtnText="Đóng"
            closeOnClickOutside={false}
            confirmBtnCssClass={
              that.props.classes.button + " " + that.props.classes.success
            }
          >
            Cập nhật thông tin thành công
        </SweetAlert>
        )
      });
    } else {
      axios
        .post(LINK_API.EDIT_PROFILE, data)
        .then(async function (response) {
          that.setState({
            alert: null
          });


          if (response.data.errorCode == 1) {
            that.user_name = data.name;
            that.setState({
              alert: (
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title="Thành công"
                  onConfirm={() => that.hideAlert()}
                  onCancel={() => that.hideAlert()}
                  showCancel={false}
                  confirmBtnText="Đóng"
                  closeOnClickOutside={false}
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                >
                  Cập nhật thông tin thành công
              </SweetAlert>
              )
            });
          } else if (response.data.errorCode == 11) {
            that.setState({
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block" }}
                  title="Không thành công"
                  onConfirm={() => that.hideAlert()}
                  onCancel={() => that.hideAlert()}
                  showCancel={false}
                  confirmBtnText="Đóng"
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                >
                  Vui lòng thử lại
              </SweetAlert>
              )
            });
          } else {
            that.setState({
              alert: (
                <SweetAlert
                  error
                  style={{ display: "block" }}
                  title="Không thành công"
                  onConfirm={() => that.hideAlert()}
                  onCancel={() => that.hideAlert()}
                  showCancel={false}
                  confirmBtnText="Đóng"
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                >
                  Vui lòng thử lại
              </SweetAlert>
              )
            });
          }
        })
        .catch(function (error) {
          that.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Không thành công"
                onConfirm={() => that.hideAlert()}
                onCancel={() => that.hideAlert()}
                showCancel={false}
                confirmBtnText="Đóng"
                confirmBtnCssClass={
                  that.props.classes.button + " " + that.props.classes.success
                }
              >
                Vui lòng thử lại
            </SweetAlert>
            )
          });
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }

  };
  async  onAvatarSubmit(file) {
    if (file != null) {
      await this.setState({
        addAvatar: {
          "avatars": file
        },
        changeavatar: true
      })
    }
  }
  async onDeleteAvatar(data){
    this.setState({
      deleteAvatar:{
        "avatars": data
      },
      changeavatar: true
    })
  }
  async onBannerSubmit(file) {
    if (file != null) {
      //const temp = await LZUTF8.compress(file, { outputEncoding: 'Base64' })
      await this.setState({
        banner: file,
      })
    }
  }
  async onLogoSubmit(file) {
    if (file != null) {
      //const temp = await LZUTF8.compress(file, { outputEncoding: 'Base64' })
      await this.setState({
        logo: file,
      })
    }
  }
  async onImageSubmit(file) {
    // console.log(file)
    if (file != null) {
      await this.setState({
        image: file
      })

      var data = this.state.list;
      var temp = {
        src: this.state.image,
        thumbnail: this.state.image,
        thumbnailWidth: 160,
        thumbnailHeight: 200,
        isSelected: false,
        caption: "Chứng nhận",
        url: "",
      }
      await data.push(temp)

      await this.setState({
        countImage: this.state.countImage + 1,
        list: data
      })
    }
    this.clickChild();

  }
  async deleteImage() {
    if (this.state.list.length === 1) {
      await this.setState({
        currentImage: 0,
      })
    }
    if (!this.state.list[this.state.currentImage]) {
      await this.setState({
        currentImage: this.state.currentImage - 1,
      })
    }
    if (window.confirm(`Bạn chắc chắn xóa ảnh này chứ ${this.state.currentImage}?`)) {
      this.setState({
        countImage: this.state.countImage - 1,
      })
      this.state.deleteImage.certificates.push(this.state.list[this.state.currentImage])
      var images = this.state.list.slice();
      images.splice(this.state.currentImage, 1)
      this.setState({
        list: images,
        deleteState: true
      });
    }
  }
  async handleEditBanner() {
    this.setState({
      banner: "",
    })
    if (document.getElementById("banneredit").innerText === strings.update.toUpperCase()) {
      const data = {
        banner: this.state.banner ? LZUTF8.compress(this.state.banner, { outputEncoding: 'Base64' }) : "",
      }
      if (data.banner) {
        this.props.updateBanner(data);
      }
      this.handleCancleBanner();
    } else {
      document.getElementById("banneredit").innerHTML = strings.update
      this.setState({
        bannerEditable: true
      })
    }
  }
  handleCancleBanner() {
    document.getElementById("banneredit").innerHTML = strings.edit
    this.setState({
      banner: "",
      bannerEditable: false,
      banner: this.props.userProfile.banner? this.props.userProfile.banner.secure_url: ""
    })
  }
  async handleEditLogo() {
    this.setState({
      logo: ""
    })
    if (document.getElementById("logoedit").innerText === strings.update.toUpperCase()) {
      const data = {
        logo: this.state.logo ? LZUTF8.compress(this.state.logo, { outputEncoding: 'Base64' }) : "",
      }
      if (data.logo) {
        this.props.updateLogo(data);
      }
      this.handleCancleLogo();
    } else {
      document.getElementById("logoedit").innerHTML = strings.update
      this.setState({
        logo: "",
        logoEditable: true
      })
    }
  }
  handleCancleLogo() {
    document.getElementById("logoedit").innerHTML = strings.edit
    this.setState({
      logoEditable: false,
      logo: this.props.userProfile.logo ? this.props.userProfile.logo.secure_url:"",
    })
  }
  async handleContent() {
    if (document.getElementById("buttonedit").innerText === strings.update.toUpperCase()) {
      if (this.state.checked === false) {
        document.getElementById("noti").innerHTML = "Chưa đồng ý điều kiện!";
      } else {
        this.submitData();
      }

    } else {
      await this.setState({
        temp: this.state.avatar
      })
      await this.setState({
        avartarEditable: true,
        avatar: null,
      })
      document.getElementById("info1").style = "display: none;"
      document.getElementById("info6").style = "display: none;"
      document.getElementById("infoedit1").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("infoedit6").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("checkbox").style = "display: block; width: 100%;";
      document.getElementById("buttonerror").style = "display: block; width: 100%;";
      document.getElementById("buttonedit").innerHTML = strings.update;
      document.getElementById("buttonchangepw").style = "display: none;"
    }
  }

  async handleContentContact() {
    if (document.getElementById("buttonedit1").innerText === strings.update.toUpperCase()) {
      if (this.state.checked1 === false) {
        document.getElementById("noti1").innerHTML = "Chưa đồng ý điều kiện!";
      } else {
        this.submitData();
      }
    } else {
      document.getElementById("info2").style = "display: none;"
      document.getElementById("info3").style = "display: none;"
      document.getElementById("info4").style = "display: none;"
      document.getElementById("info5").style = "display: none;"
      document.getElementById("infoedit2").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("infoedit3").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("infoedit4").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("infoedit5").style = "display: block; width: 100%; margin-top: -30px";
      document.getElementById("checkbox1").style = "display: block; width: 100%;";
      document.getElementById("buttonerror1").style = "display: block; width: 100%;";
      document.getElementById("buttonedit1").innerHTML = strings.update;
    }
  }

  async handlePassword() {
    await this.setState({
      changePw: true
    });
    document.getElementById("buttonchangepw").style = "display: none;"
  }

  async cancelPw() {
    await this.setState({
      changePw: false
    });
    document.getElementById("buttonchangepw").style = "display: block; width: 100%;";
  }

  handleCloseedit() {
    this.setState({
      avatar: this.state.temp,
      avartarEditable: false,
      changeavatar: false,
      checked: false,
      addAvatar:{
        "avatars":[]
      },
      deleteAvatar:{
        "avatars":[]
      }
    })
    document.getElementById("info1").style = "display: block; width: 100%"
    document.getElementById("info6").style = "display: block; width: 100%"
    document.getElementById("infoedit1").style = "display: none;"
    document.getElementById("infoedit6").style = "display: none;"
    document.getElementById("checkbox").style = "display: none;"
    document.getElementById("buttonerror").style = "display: none;"
    document.getElementById("buttonedit").innerHTML = strings.edit;
    document.getElementById("noti").innerHTML = "";
    document.getElementById("buttonchangepw").style = "display: block; width: 100%"
  }

  handleCloseeditContact() {
    this.setState({
      avatar: this.state.temp,
      avartarEditable: false,
      changeavatar: false,
      checked1: false
    })
    document.getElementById("info2").style = "display: block; width: 100%"
    document.getElementById("info3").style = "display: block; width: 100%"
    document.getElementById("info4").style = "display: block; width: 100%"
    document.getElementById("info5").style = "display: block; width: 100%"
    document.getElementById("infoedit2").style = "display: none;"
    document.getElementById("infoedit3").style = "display: none;"
    document.getElementById("infoedit4").style = "display: none;"
    document.getElementById("infoedit5").style = "display: none;";
    document.getElementById("checkbox1").style = "display: none;"
    document.getElementById("buttonerror1").style = "display: none;"
    document.getElementById("buttonedit1").innerHTML = strings.edit;
  }
  initMap = () => {
    // The location of Uluru
    // 10.767382, 106.660296
    //  const google = window.google;
    //   var uluru = {lat: 10.767382, lng: 106.660296};
    //   // The map, centered at Uluru
    //   var map = new google.maps.Map(
    //       document.getElementById('map'), {zoom: 4, center: uluru});
    //   // The marker, positioned at Uluru
    //   var marker = new google.maps.Marker({position: uluru, map: map});
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer style={{ display: "flex", flexFlow: "row", justifyContent: "flex-start" }}>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardBody className="PaddingContentProfile">
                <GridContainer style={{ display: "flex", flexFlow: "column" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={4} className="tagprofile">
                        <p className="tagContentProfile">{strings.nameCompany}: </p>
                      </GridItem>
                      <GridItem xs={8} sm={8} md={8} lg={8} >
                        <div id="info1">
                          <p className="contentProfile">{this.state.username}</p>
                        </div>
                        <div id="infoedit1" style={{ marginTop: "-30px", display: "none" }}>
                          <CustomInput
                            labelText=""
                            id="user_name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: this.handleChange('username'),
                              type: "text",
                              value: this.state.username,
                            }}
                            value={this.state.username}
                          />
                        </div>
                      </GridItem>
                      </GridContainer>
                      <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={4} className="tagprofile">
                        <p className="tagContentProfile" style={{ display: "block" }}>{strings.idCompany}: </p>
                      </GridItem>
                      <GridItem xs={8} sm={8} md={8} lg={8} >
                        <div>
                          <p className="contentProfile">{this.props.userInfo.data.uuid}</p>
                        </div>
                      </GridItem>
                      </GridContainer>
                      <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={4} className="tagprofile">
                        <p className="tagContentProfile" style={{ display: "block" }}>{strings.introCompany}: </p>
                      </GridItem>
                      <GridItem xs={8} sm={8} md={8} lg={8} >
                        <div id="info6">
                          <p className="contentProfile">{this.state.description}</p>
                        </div>
                        <div id="infoedit6" style={{ marginTop: "-30px", display: "none" }}>
                          <CustomInput
                            labelText=""
                            id="user_name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              multiline: true,
                              rowsMax: "3",
                              rows: 3,
                              onChange: this.handleChange('description'),
                              type: "text",
                              value: this.state.description,
                            }}
                            value={this.state.description}
                          />
                        </div>
                      </GridItem>
                      </GridContainer>
                      <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12} >
                        <p className="tagContentProfile">{strings.image}: </p>
                      <GridItem xs={12} sm={12} md={12} lg={12} className={classes.noPadAvatar}>
                        {this.state.avartarEditable === true ? 
                         <ImageCount count={3} 
                         onImageSubmit={this.onAvatarSubmit.bind(this)} 
                         onDeleteImage={this.onDeleteAvatar.bind(this)}
                         edit={true}
                         imageList={this.state.listAvatar}
                         /> :
                      <div>
                        {this.state.listAvatar.map((prop, key)=>(
                          key===0?
                          <img key={key} className="addImageProcess" src={prop}></img>:<img key={key} style={{marginLeft: "5px"}} className="addImageProcess" src={prop}></img>
                        ))}
                      </div> }
                      </GridItem>
                      </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                  </GridContainer>
                  <GridContainer>
                  </GridContainer>
                  <GridContainer>
                    <div id="checkbox" style={{ display: "none" }}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.checkboxAndRadio}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={this.handleToggle}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                                value="checked"
                                checked={this.state.checked}
                                onChange={this.handleChecked('checked')}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={strings.agree}
                          />
                        </div>
                      </GridItem>
                    </div>

                    <p
                      id="noti"
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '400',
                        color: 'red',
                        fontFamily: 'Muli',
                        marginTop: 5,
                        marginBottom: 5,
                        marginLeft: 16
                      }}>
                    </p>
                  </GridContainer>

                </GridContainer>
                <GridContainer style={{ display: "flex", flexFlow: "row" }}>
                  <div>
                    <Button color="success" size="sm" id="buttonedit" onClick={this.handleContent}>
                     {strings.edit}
                    </Button>
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <Button size="sm" color="success" id="buttonchangepw" onClick={this.handlePassword}>
                      {strings.changePass}
                    </Button>
                  </div>
                  <div id="buttonerror" style={{ display: "none" }}>
                    <Button color="danger" size="sm" id="buttonedit" onClick={this.handleCloseedit}>
                      {strings.cancle}
                    </Button>
                  </div>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="PaddingContentProfile">
                <p className="profileTitle">{strings.contactInfo}</p>
                <hr className="linebreakDesEdit" />
                <GridContainer style={{ display: "flex", flexFlow: "column" }}>
                  <GridContainer className="flexRow">
                    <GridItem xs={4} sm={4} md={4} lg={4} className="tagprofile">
                      <p className="tagContentProfile">Email: </p>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8} lg={8}>
                      <div id="info2">
                        <p className="contentProfile">{this.state.email}</p>
                      </div>
                      <div id="infoedit2" style={{ marginTop: "-30px", display: "none" }}>
                        <CustomInput
                          labelText=""
                          id="user_email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          style={{ marginTop: "-30px" }}
                          inputProps={{
                            onChange: this.handleChange('email'),
                            type: "text",
                            value: this.state.email,
                            disabled: true
                          }}
                          value={this.state.email}

                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={4} className="tagprofile">
                      <p className="tagContentProfile">Website: </p>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8} lg={8} >
                      <div id="info3">
                        <p className="contentProfile">{this.state.website}</p>
                      </div>
                      <div id="infoedit3" style={{ marginTop: "-30px", display: "none" }}>
                        <CustomInput
                          labelText=""
                          id="website"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: this.handleChange('website'),
                            type: "text",
                            value: this.state.website
                          }}
                          value={this.state.website}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={4} className="tagprofile">
                      <p className="tagContentProfile">{strings.address}: </p>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8} lg={8} >
                      <div id="info4">
                        <p className="contentProfile">{this.state.address}</p>
                      </div>
                      <div id="infoedit4" style={{ marginTop: "-30px", display: "none" }}>
                        <CustomInput
                          labelText=""
                          id="address"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: this.handleChange('address'),
                            type: "text",
                            value: this.state.address
                          }}
                          value={this.state.address}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={4} className="tagprofile">
                      <p className="tagContentProfile">{strings.phone}: </p>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8} lg={8}>
                      <div id="info5">
                        <p className="contentProfile">{this.state.phone}</p>
                      </div>
                      <div id="infoedit5" style={{ marginTop: "-30px", display: "none" }}>
                        <CustomInput
                          labelText=""
                          id="phone"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: this.handleChange('phone'),
                            type: "text",
                            value: this.state.phone
                          }}
                          value={this.state.phone}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <div id="checkbox1" style={{ display: "none" }}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.checkboxAndRadio}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={this.handleToggle}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                                value="checked1"
                                checked={this.state.checked1}
                                onChange={this.handleChecked1('checked1')}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={strings.agree}
                          />
                        </div>
                        <p
                          id="noti1"
                          style={{
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            color: 'red',
                            fontFamily: 'Muli',
                            marginTop: 5,
                            marginBottom: 5,
                          }}>
                        </p>
                      </GridItem>
                    </div>
                  </GridContainer>

                </GridContainer>
                <GridContainer style={{ display: "flex", flexFlow: "row" }}>
                  <div>
                    <Button color="success" size="sm" id="buttonedit1" onClick={this.handleContentContact}>
                      {strings.edit}
                    </Button>
                  </div>
                  <div id="buttonerror1" style={{ display: "none" }}>
                    <Button color="danger" size="sm" id="buttonedit1" onClick={this.handleCloseeditContact}>
                     {strings.cancle}
                    </Button>
                  </div>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>

            {this.state.changePw ? (
              <ChangePwdForm id="changePw" style={{ display: "none", marginTop: 38 }} onConfirm={this.handleChangePwd} onCancelPw={this.cancelPw} />
            ) : null}
            <Card>

              <CardBody className="PaddingContentProfile">
                <div style={{ marginTop: "-55px", marginLeft: "-21px", width: "108%" }}>
                  <CustomTabs
                    className=""
                    tabs={[
                      {
                        tabName: strings.certificate,
                        tabContent: (
                          <GridContainer style={{width: "100%"}}>
                             <GridItem xs={12} md={12} sm={12} lg={12} style={{ marginLeft: "3%"}}>
                            <Gallery
                              setClick={click => this.clickChild = click}
                              images={this.state.list}
                              rowHeight={150}
                              enableLightbox={true}
                              enableImageSelection={false}
                              currentImageWillChange={this.onCurrentImageChange}
                              customControls={[
                                <button onClick={this.deleteImage}> Xóa ảnh</button>
                              ]}
                            />
                            </GridItem>
                            <GridItem xs={12} md={12} sm={12} lg={12} style={{ marginLeft: "3%"}}>
                              <ImageUpload
                                onImageSubmit={this.onImageSubmit.bind(this)}
                                SubmitData={this.handleSaveImage}
                                Count={this.state.countImage}
                                delete={this.state.deleteState} />
                            </GridItem>
                          </GridContainer>
                        )
                      },
                      {
                        tabName: strings.setting,

                        tabContent: (
                          <div style={{ marginTop: "-24px" }}>
                            {/* <div className={classes.checkboxAndRadio}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={this.handleToggle}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                    value="blockchain_check"
                                    checked={this.state.blockchain_check}
                                    onChange={this.handleChecked('blockchain_check')}
                                  />
                                }
                                classes={{
                                  label: "labelcheckbox"
                                }}
                                label="Những thông tin cập nhật sẽ được kiểm tra trước khi đưa lên Blockchain"
                              />
                            </div>
                            <hr className="breakSetting" /> */}
                            
                            <div className={classes.checkboxAndRadio}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={this.handleToggle}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                    value="time_check"
                                    checked={this.state.time_check}
                                    onChange={this.handleChecked('time_check')}
                                  />
                                }
                                classes={{
                                  label: "labelcheckbox"
                                }}
                                label= {strings.showTime}
                              />
                            </div>
                            <hr className="breakSetting" />
                            <div className={classes.checkboxAndRadio}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={this.handleToggle}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                    value="process_check"
                                    checked={this.state.process_check}
                                    onChange={this.handleChecked('process_check')}
                                  />
                                }
                                classes={{
                                  label: "labelcheckbox"
                                }}
                                label={strings.showDetailProcess}
                              />
                            </div>
                            <hr className="breakSetting" />
                            <div className={classes.checkboxAndRadio}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={this.handleToggle}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                    value="altName"
                                    checked={this.state.altName}
                                    onChange={this.handleCheckedAltName('altName')}
                                  />
                                }
                                classes={{
                                  label: "labelcheckbox"
                                }}
                                label= {strings.showName}
                              />
                            </div>
                            <div className="styleInputError" id="altNameInput" style={ {marginTop: this.state.marginTop, display: this.state.display}}>
                              
                              <CustomInput
                                  
                                  success={this.state.usernameState === "success"}
                                  error={this.state.usernameState === "error"}
                                  style={{ paddingTop: "0px !important", display: "none" }}
                                  //  helpText={this.state.usernameHelp}
                                  formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses,
                                    onChange: this.handleChange('altName_input'),
                                  }}
                                  value={this.state.altName_input}
                                  inputProps={{
                                    value: this.state.altName_input,
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                      >
                                        <Face className={classes.inputAdornmentIcon} />
                                      </InputAdornment>
                                    ),
                                    placeholder: strings.inputName
                                  }}
                                />
                            </div>
                            <div style={{ display: "flex", justifyContent: "left" }}>
                                    <Button color="success" size="sm" id="save" onClick={this.handleSave.bind(this)}>
                                     {strings.save}
                                    </Button>
                                  </div>
                          </div>
                        )
                      },
                      {
                        tabName: strings.brand,
                        tabContent: (
                          <GridContainer style={{ marginTop: "-24px" }} >
                            <GridItem xs={12} lg={12} sm={12} md={12}>
                              <GridItem xs={12} lg={12} sm={12} md={12}>
                                <p className="titleBrand">{strings.banner}:</p>
                              </GridItem>
                              <GridItem xs={12} lg={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center", flexFlow: "column" }}>
                                {(this.state.banner ? <img src={this.state.banner} className="bannerimage" /> : <PictureUpload onAvatarSubmit={this.onBannerSubmit.bind(this)} status={this.state.bannerEditable} />)}
                                <GridContainer style={{ display: "flex", justifyContent: "center" }}>
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button color="success" size="sm" id="banneredit" onClick={this.handleEditBanner.bind(this)}>
                                     {strings.edit}
                                    </Button>
                                  </div>
                                  <div>
                                    {this.state.bannerEditable ? <Button color="danger" size="sm" id="bannercancle" onClick={this.handleCancleBanner.bind(this)}>
                                      {strings.cancle}
                                    </Button> : null}
                                  </div>
                                </GridContainer>

                              </GridItem>
                            </GridItem>
                            <GridItem xs={12} lg={12} sm={12} md={12}>
                              <GridItem xs={12} lg={12} sm={12} md={12}>
                                <p className="titleBrand">{strings.logo}:</p>
                              </GridItem>
                              <GridItem xs={12} lg={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center", flexFlow: "column" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                  {(this.state.logo ? <img src={this.state.logo} className="logoimage" /> : <PictureUpload onAvatarSubmit={this.onLogoSubmit.bind(this)} status={this.state.logoEditable} />)}
                                </div>
                                <GridContainer style={{ display: "flex", justifyContent: "center" }}>
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button color="success" size="sm" id="logoedit" onClick={this.handleEditLogo.bind(this)}>
                                      {strings.edit}
                                    </Button>
                                  </div>
                                  <div>
                                    {this.state.logoEditable ? <Button color="danger" size="sm" id="logoecancle" onClick={this.handleCancleLogo.bind(this)}>
                                      {strings.cancle}
                                 </Button> : null}
                                  </div>
                                </GridContainer>

                              </GridItem>
                            </GridItem>
                          </GridContainer>
                        )
                      }
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.alert}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch({ type: "GET_USER_PROFILE" }),
    updateBanner: (data) => dispatch({ type: "UPDATE_BANNER", data: data }),
    updateLogo: (data) => dispatch({ type: "UPDATE_LOGO", data: data }),
    cleanStore: () => dispatch({type: "CLEAN_STORE"}),
  }
}
const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile.data,
    userInfo: state.userInfo
  }
}
export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(EditProfileForm));
