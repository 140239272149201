import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import moment from 'moment';

// react component for creating dynamic tables
import ReactTable from "react-table";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//import WizardForm from "../Forms/WizardForm.jsx";
import Process from "./WizardForm/Process.jsx"
import { IMG_LOAD } from 'constants/index.js';
import { LINK_API } from 'constants/API';
//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import "../css/CustomeReactTable.css";
import { connect } from 'react-redux';
import Detail from "@material-ui/icons/Info";
import Edit from "@material-ui/icons/Edit";
import strings from "../../../../constants/strings.jsx";
const styles = {
  ...tooltip,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  paperwizard: {
    backgroundColor: "transparent",
    boxShadow: "none",

    fullWidth: "true",
    maxWidth: '800px',
    //overflow:"scroll",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "-20px",
    marginBottom: "20px"
  },
  boldContentText: {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "700"
  },
  tooltip: {
    padding: "10px 15px",
    minWidth: "130px",
    color: "#FFFFFF",
    lineHeight: "1.7em",
    background: "rgba(85,85,85,0.9)",
    border: "none",
    borderRadius: "3px",
    opacity: "1!important",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: "200px",
    textAlign: "center",
    fontFamily: 'Muli',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto"
  },
};

class BizProcessList extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.showConfirmWizard = this.showConfirmWizard.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handleNewClick_Sub = this.handleNewClick_Sub.bind(this);
    this.handleEditProcess = this.handleEditProcess.bind(this);
    this.state = {
      activeState: true,
      alert: null,
      checked: false,
      value: {
        gln_extend: '',
        passphrase: '',
        data: '',
      },
      farmList: [],
      data: [],
      load: "",
      stepswizard: [],
      showTable: "none",
      processdata: "",
      processrawdata: "",
      processtitle: "",
      combineprocess: ""
    };
  }

  handleToggle() {
    const { checked } = this.state;

    if (checked) {
      this.setState({
        checked: false
      });
    }
    else {
      this.setState({
        checked: true
      });
    }

  }





  genTable = async (farmList) => {
    //await farmList.reverse();
    this.setState({
      data: farmList.map((prop, key) => {
        // {console.log(props)}
        return {
          id: key + 1,
          owner: prop.processName,
          time: moment(prop.createdAt).format("DD/MM/YYYY hh:ss"),
          scope: prop.businessProcess.length,
          productType: prop.bizProcessCode,
          actions: (
            <div className="actions-center">

              <Button
                justIcon
                round
                simple
                onClick={() => this.handleDetail(prop.bizProcessCode)}
                color="info"
                className="like"
                title="Xem chi tiết"
              >
                <Tooltip
                  id="tooltip-detail"
                  title="Xem chi tiết"
                  placement="top"
                  classes={{ tooltip: this.props.classes.tooltip }}
                >
                  <Detail />
                </Tooltip>
              </Button>


              <Button
                justIcon
                round
                simple
                onClick={() => this.handleEditProcess(prop.bizProcessCode)}
                color="warning"
                className="like"
              >
                <Tooltip
                  id="tooltip-edit"
                  title={strings.edit}
                  placement="top"
                  classes={{ tooltip: this.props.classes.tooltip }}
                >
                  <Edit />
                </Tooltip>
              </Button>
            </div>
          )
        };
      }),
      load: "none",
      showTable: ""
    });
  }

  async checkPermission() {
    try {
      if (this.props.userInfo) {
        let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
        if (response.data.errorCode !== 1 || !response.data.data.valid) {
          this.props.cleanStore();
          return this.props.history.push("/");
        }
        else {
          axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
        }
      } else {
        this.props.cleanStore();
        return this.props.history.push("/");
      }
    }
    catch (error) {
      console.log(error);
      this.props.cleanStore();
      return this.props.history.push("/");
    }
  }
  async handleDetail(id) {
    await cookie.save("idProcess", id, { path: "/" });
    this.props.history.push("/admin/process/detail")
  }
  async handleEditProcess(id) {
    await cookie.save("idProcess", id, { path: "/" });
    this.props.history.push("/admin/process/edit")
  }
  getFarms = async () => {
    try {
      let response = await axios.get(LINK_API.GET_PROCESS_LIST);
      if (response.data.errorCode == 1) {
        //console.log(response.data.data)
        return response.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      return null;
    }
  }

  async componentDidMount() {
    await this.checkPermission();
    await this.props.getProcessList();
  }
  componentWillReceiveProps(preProps) {
    if (preProps.processList !== this.props.processList) {
      if (preProps.processList.data !== undefined && preProps.processList.data !== null) {
        this.genTable(Array.from(preProps.processList.data));
      }
    }
  }

  async onSuccess(data) {

    //console.log(data);

    // const ethers = require('ethers');
    // let url = "http://206.189.82.191:8000";
    // let customHttpProvider = new ethers.providers.JsonRpcProvider(url);
    // const mnemonic = await ethers.utils.HDNode.entropyToMnemonic(ethers.utils.randomBytes(16));
    // const wallet = ethers.Wallet.fromMnemonic(mnemonic);
    // data.address = wallet.address;
    // data.public_key = await ethers.utils.computePublicKey(wallet.privateKey);

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title= {strings.processing}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="{name}"
              src={IMG_LOAD}
              style={{ height: "100px" }}
            />
          </div>
        </SweetAlert>
      )
    });
    axios.post(LINK_API.REGISTER_PROCESS, data)

      .then(async response => {

        let farmList = this.state.farmList;
        farmList.push(data)

        this.genTable(farmList);

        await this.setState({
          farmList: farmList,
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-270px" }}
              title="Thành Công!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              {strings.success}
          </SweetAlert>
          )
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title={strings.failed}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              {strings.failed}
          </SweetAlert>
          )
        });
      });

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }


  async showConfirmWizard(data) {
    this.handleCloseWizard();
    const dataArray = Object.values(data);
    var combineprocess = {
      "process_code": this.state.processrawdata.farmCode,
      "category": this.state.processrawdata.farmScope,
      "process_name": this.state.processrawdata.farmOwner,
      "max_step": this.state.processrawdata.farmStep,
      "step": dataArray,
      "step_if": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ]
    }
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Bạn đã kiểm tra kỹ các thông tin?"
          onConfirm={() => this.onSuccess(combineprocess)}
          onCancel={() => {
            this.hideAlert();
            this.setState({ openWizardForm: true });
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Tạo mới"
          cancelBtnText="Làm lại"
          showCancel
        >
        </SweetAlert>,
    })
  }


  handleNewClick() {
    // 
    this.props.history.push("/admin/process/create")
  }
  async handleNewClick_Sub() {
    //this.setState({openCreateFarm: true});
    document.getElementById("addProducts_header").style = "display: none";
    document.getElementById("processpage").style = "display: none";
    document.getElementById("tableprocess").style = "display: block; width: 100%";
    let farms = await this.getFarms();
    this.setState({ farmList: farms });
    this.genTable(farms);
  }
  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input type='text'
        placeholder={placeholder}
        style={{
          width: '100%'
        }}
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      />
    )
  }

  render() {
    return (
      <div>
        <div id="tableprocess">
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="success" icon>
                  <GridContainer>
                    <GridItem lg={6} md={6} sm={6} xs={12}>
                      {/* <CardIcon color="success">
                        <People />
                      </CardIcon> */}
                      <h4 className="cardtableLabel">{strings.processList}</h4>
                    </GridItem>
                    <GridItem lg={6} md={6} sm={6} xs={12}>
                      <GridContainer justify="flex-end" style={{ paddingRight: "25px", paddingTop: "10px" }}>
                      {(this.props.userInfo.data.active  ===  true)?<Button size="sm" color = "success" onClick = {this.handleNewClick}> <Add style = {{margin:"0px",marginRight:"5px"}}/>{strings.add}</Button>: null}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <div style={{ textAlign: "center", display: this.state.load }}>
                    <img
                      alt="{name}"
                      src={IMG_LOAD}
                      style={{ height: "100px" }}
                    />
                  </div>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    // defaultPageSize={5}
                    previousText={strings.prevPage}
                    nextText={strings.nextPage}
                    rowsText={strings.row}
                    ofText="/"
                    pageText={strings.page}
                    noDataText={strings.noData}
                    columns={[
                      {
                        
                        Header: "#",
                        accessor: "id",
                        filterable: false,
                        maxWidth: 70,
                        className: "center",
                        Header: () => (
                          <div style={{
                            textAlign: "center"
                          }}>
                            #
                        </div>
                        )
                      },
                      {
                        Header: strings.namePro,
                        accessor: "owner",
                        className: "center",
                        filterable: false
                      },
                      {
                        Header: strings.dateCreated,
                        accessor: "time",
                        filterable: false
                      },
                      {
                        Header: strings.numSteps,
                        accessor: "scope",
                        filterable: false
                      },
                      {
                        Header: strings.idPro,
                        accessor: "productType",
                        filterable: false,
                      },
                      {
                        Header: strings.actionHeader,
                        accessor: "actions",
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    sortable={false}
                    resizable={false}
                    className="-striped -highlight"
                    style={{ display: this.state.showTable }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <div id="processpage" style={{ display: "none" }}>
          <Process gln={this.user_gln} />
        </div>
        {this.state.alert}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    processList: state.processList
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getProcessList: () => dispatch({ type: "GET_PROCESS_LIST" }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(BizProcessList)));
