import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// react component for creating dynamic tables

import { withRouter } from 'react-router-dom';
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";



// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Accordion from 'components/Accordion/Accordion.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { LINK_API } from '../../../../constants/API';
import ImageUpload from "../RetailPage/ImageCustorm/ImageUpload.jsx"
import SweetAlert from "react-bootstrap-sweetalert";
import { IMG_LOAD } from 'constants/index.js';

//styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";
const LZUTF8 = require('lzutf8');
const styles = {
    ...tooltip,
    ...regularFormsStyle,
    ...sweetAlertStyle,
    ...customSelectStyle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
    },
    contentText: {
        fontSize: "14px",
        marginTop: "-20px",
        marginBottom: "20px"
    },
    boldContentText: {
        fontSize: "14px",
        marginBottom: "20px",
        fontWeight: "700"
    }
};

class TableFarmList extends React.Component {
    constructor(props) {
        super(props)
        this.user_gln = cookie.load("gln");
        this.role = cookie.load("checkrole");
        this.state = {
            alert: null,

            name: "",
            nameState: "",

            description: "",
            descriptionState: "",

            city: "",
            cityState: "",

            district: "",
            districtState: "",

            gln: "",
            glnState: "",

            image: "",

            checked: [],

            destime: ["VND", "USD", "Khu chế biến"],
            datetime: ["Năm", "Tháng", "Ngày"],
            typeforPlan: ["Sử dụng 1 lần","Plan chính"],
            type: "",
            type_temp: "",

            search: "",
            value: "",
        };
        this.token = cookie.load('token');
        this.handleChange = this.handleChange.bind(this)
        this.onImageSubmit = this.onImageSubmit.bind(this)
        this.handleToggle = this.handleToggle.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
        this.hideAlert_success = this.hideAlert_success.bind(this)
        this.hideAlert = this.hideAlert.bind(this)
        this.checkPermission = this.checkPermission.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
        this.handleNoResult = this.handleNoResult.bind(this);
        this.handleStatusUpdate = this.handleStatusUpdate.bind(this);
        this.handleSelectTime = this.handleSelectTime.bind(this)
        this.handleSelectType = this.handleSelectType.bind(this)
    }
    async componentDidMount() {
        await this.checkPermission()
    }
    async checkPermission() {
        try {
            let response = await axios.get(LINK_API.VERIFY_TOKEN, { headers: { "Authorization": `Bearer ${this.props.userInfo.data.token}` } });
            if (response.data.errorCode !== 1 || !response.data.data.valid) {
                await cookie.remove('token')
                return this.props.history.push("/");
            }
            else {
                axios.defaults.headers.common['Authorization'] = this.props.userInfo.data.token;
            }
        }
        catch (error) {
            console.log(error);
            await cookie.remove('token');
            return this.props.history.push("/");
        }
    }
    handleInputChange = e => {
        this.setState({ search: e.target.value, value: e.target.value })
    }

    handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        //console.log(geocodedPrediction.formatted_address, originalPrediction) // eslint-disable-line
        this.setState({
            city: geocodedPrediction.formatted_address,
            search: "",
        })
        //this.setState({ search: "", value: geocodedPrediction.formatted_address })
    }
    handleSelectSuggest_district = (geocodedPrediction, originalPrediction) => {
        //console.log(geocodedPrediction.formatted_address, originalPrediction) // eslint-disable-line
        this.setState({
            district: geocodedPrediction.formatted_address,
            search_district: ""
        })
        //this.setState({ search: "", value: geocodedPrediction.formatted_address })
    }
    handleSelectType(event){
        if(event.target.value==="Sử dụng 1 lần"){
            this.setState({
                planType: 1,
                typeforPlanTemp: event.target.value
            })
        }
        if(event.target.value === "Plan chính"){
            this.setState({
                planType: 0,
                typeforPlanTemp: event.target.value
            })
        }
    }
    handleNoResult = () => {
        console.log('Không có kết quả', this.state.search)
    }
    handleSelectTime(event){
        if(event.target.value === "Năm"){
            this.setState({
                typePeriodTime: "Y",
                typePeriodTimeTemp: event.target.value
            })
        }
        if(event.target.value === "Tháng"){
            this.setState({
                typePeriodTime: "M",
                typePeriodTimeTemp: event.target.value
            })
        }
        if(event.target.value === "Ngày"){
            this.setState({
                typePeriodTime: "D",
                typePeriodTimeTemp: event.target.value
            })
        }
    }
    handleStatusUpdate = (status) => {
        console.log(status)
    }
    async onImageSubmit(file) {
        // console.log(file);
        if (file != null) {
            await this.setState({
                image: file
            })
        }
    }
    async handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        await this.setState({
            checked: newChecked
        });
        if (this.state.checked[0] === 1) {
            //document.getElementById("noti").innerHTML = "";
            document.getElementById("glnchecked").style = "display: block; width: 100%; margin-top: -27px;"
        } else {
            document.getElementById("glnchecked").style = "display: none"
        }
    }
    handleChange = name =>  event => {
     this.setState({
            [name]: event.target.value,
        });
    };
    async  handleSelect(event) {
       this.setState({
        unit: event.target.value
       })
        document.getElementById("noti").innerHTML = "";
    }
    submitData() {
        if (typeof(this.state.planType) !== "number") {
            document.getElementById("noti").innerHTML = "Vui lòng chọn loại plan";
            return;
        }
        if (!this.state.name) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập tên gói";
            return;
        }
        if (!this.state.maxProductType) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập số lượng sản phẩm";
            return;
        }
        if (!this.state.maxProductionObject) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập số lượng đối tượng sản xuất";
            return;
        }
        if (!this.state.maxProcessingObject) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập số lượng đối tượng chế biến";
            return;
        }
        if (!this.state.maxCrop) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập số lượng mùa vụ";
            return;
        }
        if (!this.state.maxEmployee) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập số lượng nhân viên";
            return;
        }
        if (!this.state.maxAction) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập số hành động";
            return;
        }
        if (!this.state.maxBizProcess) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập số lượng quy trình";
            return;
        }
        if (!this.state.price) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập giá thành";
            return;
        }
        if (!this.state.unit) {
            document.getElementById("noti").innerHTML = "Vui lòng chọn đơn vị tiền tệ";
            return;
        }
        if (!this.state.periodTime) {
            document.getElementById("noti").innerHTML = "Vui lòng nhập thời hạn";
            return;
        }
        if (!this.state.typePeriodTime) {
            document.getElementById("noti").innerHTML = "Vui lòng chọn đơn vị thời gian cho thời hạn";
            return;
        }
        const data = {
            "maxProductType": this.state.maxProductType,
            "maxProductionObject": this.state.maxProductionObject,
            "maxProcessingObject": this.state.maxProcessingObject,
            "maxCrop": this.state.maxCrop,
            "maxEmployee": this.state.maxEmployee,
            "maxAction": this.state.maxAction,
            "maxBizProcess": this.state.maxBizProcess,
            "price": this.state.price,
            "unit": "VND",
            "periodTime": this.state.periodTime,
            "typePeriodTime": this.state.typePeriodTime,
            "name": this.state.name,
            "uuid": this.props.userInfo.data.uuid,
            "numberStampBox": this.state.numberStampBox,
            "numberStampUnit": this.state.numberStampUnit,
            "type": this.state.planType
        }
        this.props.createArea(data);
        this.props.history.push("/admin/adminonly/plan");
    }
    async onSuccess(x) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title="Đang xử lý!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showConfirm={false}
                >
                    <div style={{ textAlign: "center" }}>
                        <img
                            alt="{name}"
                            src={IMG_LOAD}
                            style={{ height: "100px" }}
                        />
                    </div>
                </SweetAlert>
            )
        });

        // let that=this;

        axios.post(LINK_API.CREATE_PRODUCTION_AREA, x)
            .then(async () => {
                // const farmList = await this.getFarms();
                // this.genTable(farmList);

                await this.setState({
                    //farmList: farmList,
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Thành công"
                            onConfirm={() => this.hideAlert_success()}
                            showCancel={false}
                        >
                        </SweetAlert>
                    )
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    alert: (
                        <SweetAlert
                            error
                            style={{ display: "block" }}
                            title="Không thành công"
                            onConfirm={() => this.hideAlert()}
                            onCancel={() => this.hideAlert()}
                            confirmBtnCssClass={
                                this.props.classes.button + " " + this.props.classes.success
                            }
                        >
                        </SweetAlert>
                    )
                });
            });

    }
    hideAlert_success() {
        this.setState({
            alert: null
        })
        this.props.history.push("/admin/areas/list")
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div id="info1">
                    <GridContainer>
                        <GridItem xs={12} lg={12} md={12} sm={12}>
                            <Card>
                                <CardHeader color="success" icon>
                                    <GridContainer>
                                        <GridItem lg={12} md={12} sm={12} xs={12}>
                                            <h4 className="cardtableLabel">Thông tin gói</h4>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                <div style={{ width: "100%", marginTop: "-5px" }}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="product-expired-type"
                                                        className={classes.selectLabel}
                                                    >
                                                        Loại plan
                                                 </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        id='select'
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={this.state.typeforPlanTemp}
                                                        onChange={this.handleSelectType}
                                                        inputProps={{
                                                            name: "type",
                                                            id: "type"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            Chọn loại plain
                        </MenuItem>
                                                        {this.state.typeforPlan.map((x, idx) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={x}
                                                                key={idx}
                                                            >
                                                                {x}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                <CustomInput
                                        labelText="Tên gói"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('name'),
                                        }}
                                        inputProps={{
                                            value: this.state.name,
                                            autoComplete: "off",
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Số sản phẩm"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('maxProductType'),
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.maxProductType,
                                            autoComplete: "off",
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Số đối tượng sản xuất"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('maxProductionObject'),
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.maxProductionObject,
                                            autoComplete: "off",
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Số đối tượng chế biến"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('maxProcessingObject'),
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.maxProcessingObject,
                                            autoComplete: "off",
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Số lượng mùa vụ"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('maxCrop'),
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.maxCrop,
                                            autoComplete: "off",
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Số lượng nhân viên"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('maxEmployee'),
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.maxEmployee,
                                            autoComplete: "off",
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Số lượng hành động"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('maxAction'),
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.maxAction,
                                            autoComplete: "off",
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Số lượng quy trình"
                                        success={this.state.nameState === "success"}
                                        error={this.state.nameState === "error"}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.customFormControlClasses,
                                            onChange: this.handleChange('maxBizProcess'),
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.maxBizProcess,
                                            autoComplete: "off",
                                        }}
                                    />
                                <GridContainer>
                                        <GridItem xs={6} md={6} sm={6} lg={6}>
                                            <CustomInput
                                                labelText="Số lượng tem sản phẩm"
                                                success={this.state.nameState === "success"}
                                                error={this.state.nameState === "error"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses,
                                                    onChange: this.handleChange('numberStampUnit'),
                                                }}
                                                inputProps={{
                                                    type: "number",
                                                    value: this.state.price,
                                                    autoComplete: "off",
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} md={6} sm={6} lg={6}>
                                        <CustomInput
                                                labelText="Số lượng tem thùng"
                                                success={this.state.nameState === "success"}
                                                error={this.state.nameState === "error"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses,
                                                    onChange: this.handleChange('numberStampBox'),
                                                }}
                                                inputProps={{
                                                    type: "number",
                                                    value: this.state.price,
                                                    autoComplete: "off",
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6} md={6} sm={6} lg={6}>
                                            <CustomInput
                                                labelText="Giá thành"
                                                success={this.state.nameState === "success"}
                                                error={this.state.nameState === "error"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses,
                                                    onChange: this.handleChange('price'),
                                                }}
                                                inputProps={{
                                                    type: "number",
                                                    value: this.state.price,
                                                    autoComplete: "off",
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} md={6} sm={6} lg={6}>
                                            <div style={{ width: "100%", marginTop: "-5px" }}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="product-expired-type"
                                                        className={classes.selectLabel}
                                                    >
                                                        Đơn vị tiền tệ
                                                 </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        id='select'
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={this.state.unit}
                                                        onChange={this.handleSelect}
                                                        inputProps={{
                                                            name: "type",
                                                            id: "type"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            Chọn đơn vị tiền tệ
                        </MenuItem>
                                                        {this.state.destime.map((x, idx) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={x}
                                                                key={idx}
                                                            >
                                                                {x}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6} md={6} sm={6} lg={6}>
                                            <CustomInput
                                                labelText="Thời hạn"
                                                success={this.state.nameState === "success"}
                                                error={this.state.nameState === "error"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses,
                                                    onChange: this.handleChange('periodTime'),
                                                }}
                                                inputProps={{
                                                    type: "number",
                                                    value: this.state.periodTime,
                                                    autoComplete: "off",
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} md={6} sm={6} lg={6}>
                                            <div style={{ width: "100%", marginTop: "-5px" }}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="product-expired-type"
                                                        className={classes.selectLabel}
                                                    >
                                                        Đơn vị thời gian
                                                 </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        id='select'
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={this.state.typePeriodTimeTemp}
                                                        onChange={this.handleSelectTime}
                                                        inputProps={{
                                                            name: "type",
                                                            id: "type"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            Chọn đơn vị thời gian
                        </MenuItem>
                                                        {this.state.datetime.map((x, idx) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={x}
                                                                key={idx}
                                                            >
                                                                {x}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </GridItem>
                                    </GridContainer>

                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <p
                                            id="noti"
                                            style={{
                                                fontSize: '0.875rem',
                                                fontWeight: '400',
                                                color: 'red',
                                                fontFamily: '"Muli',
                                                marginTop: 5,
                                                marginBottom: 5,
                                            }}>
                                        </p>
                                    </div>
                                    <Button color="success" size="sm" onClick={this.submitData.bind(this)}>Tạo mới</Button>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                {this.state.alert}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createArea: (data) => dispatch({ type: "CREATE_PLAN", data: data })
    }
}
const mapStateToProps = (state) => {
    return { userInfo: state.userInfo }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TableFarmList)));
